<template>
  <nav
    class="right_bg rightSideBar close-right-sidebar-16rem z-40 md:right-0 md:block md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl flex flex-wrap items-center justify-between relative md:w-w-2/12 z-10 mt-0.5"
    v-bind:style="{ right: toggle_right_Sidebar + 'rem' }"
  >
    <div class="">
      <div
        class="flex justify-between px-2 py-0 mneminics_mt"
        style="height: 32px; padding: 0px 7px"
      >
        <div class="righ_side_head_ flex items-center" style="gap: 6px">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="4"
            viewBox="0 0 12 4"
            fill="none"
          >
            <path
              d="M11.3333 0H0.666626V1.33333H11.3333V0ZM0.666626 4H11.3333V2.66667H0.666626V4Z"
              fill="#777777"
            ></path>
          </svg>
          <p class="sidebar-heading">Numeric</p>
        </div>
        <div>
          <svg
            v-on:click="closeNavigation"
            class="cursor-pointer ml-auto mt-0 w-2 left_close_btn"
            style="text-align: right"
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
          >
            <path
              id="close_2_"
              data-name="close (2)"
              d="M8.874,7.636,14.715,1.8A.972.972,0,1,0,13.341.421L7.5,6.262,1.659.421A.972.972,0,0,0,.285,1.8L6.126,7.636.285,13.477a.972.972,0,1,0,1.374,1.374L7.5,9.01l5.841,5.841a.972.972,0,0,0,1.374-1.374Zm0,0"
              transform="translate(0 -0.136)"
              fill="#b4b5ba"
            />
          </svg>
        </div>
      </div>
    </div>

    <div class="pt-0_5 pr-0_5 pl-0_5 pb-0_5 prop_mx_height">
      <div
        v-on:click="toggle_height_handler_one"
        class="flex bg-right_ justify-between pt-4 pl-5 pb-4 rightSideBarOptions bg-right_"
      >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/genaral_settings.png" alt="" />
            General Settings
          </p>
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureOne">
            <i class="dwnArrw fas fa-angle-down"></i>
          </p>
          <p v-else><i class="upArrw fas fa-angle-up"></i></p>
        </div>
      </div>

      <div
        v-bind:style="{ display: isExpandFeatureOne ? '' : 'none' }"
        class="expand-right-sidebar-feature-one"
      >
        <div>
          <div class="custom-radio-wrap mb-4">
            <div class="flex flex-col mt-3">
              <p class="label_heading">Display Name</p>
              <input
                :id="widgetId + '1'"
                type="text"
                class="fields border-0 block font-color"
                @input="changeTitleHandler($event)"
                :value="fullName"
              />
            </div>

            <div class="flex flex-col mt-3">
              <p class="label_heading">Mnemonic</p>

              <select
                :id="widgetId + '2'"
                class="fields focus:ring-indigo-500 border-0 block left-0 font-color leading-none"
                @change="mnemonicChangeHandler($event)"
                :value="title"
              >
                <option>{{ title }}</option>
                <option :key="index"  v-for="(tag, index) in tags">
                  <!--  -->
                  {{ tag }}
                </option>
                <!-- <option value="saab">Saab</option>
                  <option value="fiat">Fiat</option>
                  <option value="audi">Audi</option>-->
              </select>
            </div>
            <div class="flex flex-col mt-3">
              <p class="label_heading">Unit</p>
              <input
                :id="widgetId + '3'"
                disabled
                type="text"
                class="fields border-0 block font-color"
                :value="unit"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-on:click="toggle_height_handler_two"
        class="flex bg-black justify-between pt-5 pl-5 pb-4 rightSideBarOptions bg-right_"
      >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/note.png" alt="" />
            Appearance
          </p>
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureTwo">
            <i class="dwnArrw fas fa-angle-down"></i>
          </p>
          <p v-else><i class="upArrw fas fa-angle-up"></i></p>
        </div>
      </div>

      <div
        v-bind:style="{ display: isExpandFeatureTwo ? '' : 'none' }"
        class="expand-right-sidebar-feature-one"
      >
        <div class="pb-4">
          <div class="flex flex-col mt-3">
            <p class="label_heading">Decimals</p>
            <input
              type="number"
              min="0"
              max="13"
              class="fields focus:ring-indigo-500 label_heading border-0 block font-color"
              @input="decimalValueHandler($event)"
              :value="decimalValue"
            />
          </div>
          <div class="mt-3">
            <label class="w-100 flex font-thin">
              <span class="label_heading"> Text Autofit </span>
              <input
                class="ml-auto leading-tight"
                type="checkbox"
                @click="toggleAutoHandler()"
                :checked="autoFit"
              />
            </label>
            <!-- <input
                class="checkbox"
                type="checkbox"
                id="menu6"
                @click="toggleAutoHandler()"
                :checked="autoFit"
              />
              <label class="check-label label_heading" for="menu6"
                >Auto Fit Text</label
              > -->
          </div>
          <div v-if="!autoFit" class="flex items-center justify-between mt-3">
            <p class="label_heading">Title Font %</p>
            <input
              min="0"
              max="50"
              type="range"
              class="focus:ring-indigo-500 h-9 border-0 block w-5/12"
              @input="fontValueHandler($event)"
              :value="titleSize"
            />
          </div>
          <div class="flex items-center justify-between mt-3">
            <p class="label_heading">Title</p>
            <input
              type="color"
              min="0"
              max="13"
              id="titleColor"
              class="focus:ring-indigo-500 colorBorder label_heading h-9 border-0 input-bg block w-5/12 rounded-md"
              @input="colorValueHandler($event)"
              :value="titleColor"
            />
          </div>
          <div v-if="!autoFit" class="flex items-center justify-between mt-3">
            <p class="label_heading">Value Font %</p>
            <input
              min="0"
              max="50"
              type="range"
              class="focus:ring-indigo-500 h-9 border-0 block w-5/12"
              @input="valueFontValueHandler($event)"
              :value="valueSize"
            />
          </div>
          <div class="flex items-center justify-between mt-3">
            <p class="label_heading">Value</p>
            <input
              type="color"
              min="0"
              max="13"
              id="valueColor"
              class="focus:ring-indigo-500 colorBorder label_heading h-9 border-0 input-bg block w-5/12 rounded-md"
              @input="valueColorValueHandler($event)"
              :value="valueColor"
            />
          </div>
          <div v-if="!autoFit" class="flex items-center justify-between mt-3">
            <p class="label_heading">Unit Font %</p>
            <input
              min="0"
              max="50"
              type="range"
              class="focus:ring-indigo-500 h-9 border-0 input-bg block w-5/12 rounded-md"
              @input="unitFontValueHandler($event)"
              :value="unitSize"
            />
          </div>
          <div class="flex items-center justify-between mt-3">
            <p class="label_heading">Unit</p>
            <input
              type="color"
              min="0"
              max="13"
              id="unitColor"
              class="focus:ring-indigo-500 label_heading colorBorder h-9 border-0 input-bg block w-5/12 rounded-md"
              @input="unitColorValueHandler($event)"
              :value="unitColor"
            />
          </div>
          <div class="flex items-center justify-between mt-3">
            <p class="label_heading">Background Color</p>
            <input
              type="color"
              id="bgColor"
              class="focus:ring-indigo-500 label_heading colorBorder h-9 border-0 block w-5/12 rounded-md font-color"
              @input="changeBackgroundColor($event)"
              :value="backgroundColor"
            />
          </div>
        </div>
      </div>

      <div
        v-on:click="toggle_height_handler_three"
        class="flex bg-black justify-between pt-5 pl-5 pb-4 rightSideBarOptions bg-right_"
      >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/shared_vision.png" alt="" />
            Visibility
          </p>
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureThree">
            <i class="dwnArrw fas fa-angle-down"></i>
          </p>
          <p v-else><i class="upArrw fas fa-angle-up"></i></p>
        </div>
      </div>

      <div
        v-bind:style="{ display: isExpandFeatureThree ? '' : 'none' }"
        class="expand-right-sidebar-feature-one"
      >
        <div class="pb-4">
          <div>
            <div class="mt-3">
              <label class="w-100 flex font-thin">
                <span class="label_heading"> Value </span>
                <input
                  class="ml-auto leading-tight"
                  type="checkbox"
                  @click="toggleValueVisibiltyHandler()"
                  :checked="valueDisplay"
                />
              </label>
            </div>
            <div class="mt-3">
              <label class="w-100 flex font-thin">
                <span class="label_heading"> Unit </span>
                <input
                  class="ml-auto leading-tight"
                  type="checkbox"
                  @click="toggleUnitVisibiltyHandler()"
                  :checked="unitDisplay"
                />
              </label>
            </div>
            <div class="mt-3">
              <label class="w-100 flex font-thin">
                <span class="label_heading"> Border </span>
                <input
                  class="ml-auto leading-tight"
                  type="checkbox"
                  @click="toggleBorderVisibiltyHandler()"
                  :checked="borderDisplay"
                />
              </label>
            </div>
          </div>
        </div>
      </div>

      <div
        v-on:click="toggle_height_handler_four"
        class="flex bg-black justify-between pt-5 pl-5 pb-4 rightSideBarOptions bg-right_"
      >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/alert.png" alt="" />
            Alarm
          </p>
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureFour">
            <i class="dwnArrw fas fa-angle-down"></i>
          </p>
          <p v-else><i class="upArrw fas fa-angle-up"></i></p>
        </div>
      </div>
      <div
        v-bind:style="{ display: isExpandFeatureFour ? '' : 'none' }"
        class="expand-right-sidebar-feature-one"
      >
        <div class="pb-4">
          <div class="flex items-center justify-between mt-3">
            <label for="blink" class="label_heading">Blink Alert</label>
            <input
              id="blink"
              type="checkbox"
              class="focus:ring-indigo-500 label_heading border-0 block"
              @input="changeBlinkAlert($event)"
              :checked="blinkAlert"
            />
          </div>

          <div class="flex items-center justify-between mt-3">
            <p class="label_heading">Alert Color</p>
            <input
              type="color"
              id="alertColor"
              class="focus:ring-indigo-500 label_heading colorBorder h-9 border-0 block w-5/12 rounded-md font-color"
              @input="changeAlertColor($event)"
              :value="alertColor"
            />
          </div>

          <div class="flex flex-col mt-3">
            <p class="label_heading">Start Range</p>
            <input
              type="number"
              class="fields focus:ring-indigo-500 label_heading border-0 block font-color"
              @input="changeStartRange($event)"
              :value="alertStartRange"
            />
          </div>

          <div class="flex flex-col mt-3">
            <p class="label_heading">End Range</p>
            <input
              type="number"
              class="fields focus:ring-indigo-500 label_heading border-0 block font-color"
              @input="changeEndRange($event)"
              :value="alertEndRange"
            />
          </div>
        </div>
      </div>
      <UpDownButtons
        :isExpandFeatureSix="isExpandFeatureSix"
        :toggle_height_handler_six="toggle_height_handler_six"
        :id="id"
        :displayId="properties.displayId"
        :widgetId="properties.widgetId"
        :closePropertyFile="closeNavigation"
      />
    </div>
  </nav>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import UpDownButtons from "../UpDownButtons/UpDownButtons";
import { ChevronDownIcon } from "@heroicons/vue/solid";

export default {
  name: "Maindash",
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    UpDownButtons,
  },
  props: {
    // blinkAlert: Boolean,
    upDownHandler: Function,
    toggle_right_Sidebar: {
      type: String,
      default: "",
    },
    toggle_right_margin: {
      type: String,
      default: "",
    },
    id: [Number, String],
    widgetId: String,
    displayId: String,
    properties: {},
    closeNavigation: Function,
  },
  data() {
    return {
      isExpandFeatureOne: true,
      isExpandFeatureTwo: false,
      isExpandFeatureThree: false,
      isExpandFeatureFour: false,
      isExpandFeatureFive: false,
      isExpandFeatureSix: false,
      Dtags: [],
      idx: -1,
    };
  },
  beforeMount() {
    let rects = this.$store.state.rect.rects;
    if (typeof rects != "undefined" && rects.length > 0) {
      for (let i = 0; i < rects.length; i++) {
        if (
          rects[i].widgetId == this.widgetId &&
          rects[i].displayId == this.displayId
        )
          this.idx = i;
      }
    }
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type == "rect/DARK_MODE") {
        if (this.$store.state.rect.darkmode) {
          this.light();
        } else {
          this.dark();
        }
      }
    });
    let rects = this.$store.state.rect.rects;
    if (typeof rects != "undefined" && rects.length > 0) {
      for (let i = 0; i < rects.length; i++) {
        if (
          rects[i].widgetId == this.widgetId &&
          rects[i].displayId == this.displayId
        )
          this.idx = i;
      }
    }
  },
  computed: {
    isExpandFeatureOneCompute() {
      return this.isExpandFeatureOne;
    },
    tags() {
      // this.activeDisplay = this.$store.state.data.selectedDisplay;
      let display = this.$store.state.disp.selectedDisplay;
      //
      let displayobj = this.$store.state.disp.displays;
      //

      if (displayobj[display]) {
        this.Dtags = displayobj[display].tags;
        return displayobj[display].displayTags;
      } else {
        return [];
      }
      // return displayobj.tags;
    },
    fullName() {
      return this.$store.state.rect.rects[this.idx].fullName || "";
    },
    title() {
      return this.$store.state.rect.rects[this.idx].title || "";
    },
    unit() {
      return this.$store.state.rect.rects[this.idx].widgetUnitL;
    },
    decimalValue() {
      return this.$store.state.rect.rects[this.idx].decimalValue;
    },
    autoFit() {
      return this.$store.state.rect.rects[this.idx].autoFit;
    },

    titleSize() {
      return this.$store.state.rect.rects[this.idx].titleSize;
    },
    titleColor() {
      return (
        this.$store.state.rect.rects[this.idx].titleColor ||
        (this.darkDark !== 'white' ? "#ffffff" : "#000000")
      );
    },
    valueColor() {
      return (
        this.$store.state.rect.rects[this.idx].valueColor ||
        (this.darkDark !== 'white' ? "#ffffff" : "#000000")
      );
    },
    valueSize() {
      return this.$store.state.rect.rects[this.idx].valueSize;
    },
    unitSize() {
      return this.$store.state.rect.rects[this.idx].unitSize;
    },
    unitColor() {
      return (
        this.$store.state.rect.rects[this.idx].unitColor ||
        (this.darkDark !== 'white' ? "#FFFFF7" : "#28282f")
      );
    },
    valueDisplay() {
      return this.$store.state.rect.rects[this.idx].valueDisplay;
    },
    unitDisplay() {
      return this.$store.state.rect.rects[this.idx].unitDisplay;
    },
    borderDisplay() {
      return this.$store.state.rect.rects[this.idx].borderDisplay;
    },
    blinkAlert() {
      return this.$store.state.rect.rects[this.idx].blinkAlert;
    },
    alertColor() {
      return this.$store.state.rect.rects[this.idx].alertColor || "#ff0000";
    },
    backgroundColor() {
      return (
        this.$store.state.rect.rects[this.idx].cardBackground ||
        (this.darkDark !== 'white' ? "#1e293b" : "#FFFFF7")
      );
    },
    alertEndRange() {
      return this.$store.state.rect.rects[this.idx].alertEndRange;
    },
    alertStartRange() {
      return this.$store.state.rect.rects[this.idx].alertStartRange;
    },
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
  },
  methods: {
    changeTitleHandler(e) {
      this.$store.dispatch("rect/changeTitle", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        // value: e.target.value,
        fullName: e.target.value,
      });
    },
    colorValueHandler(e) {
      this.$store.dispatch("rect/changeTitleColor", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },
    toggleAutoHandler() {
      this.$store.dispatch("rect/toggleAutoFit", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
      });
    },
    valueColorValueHandler(e) {
      this.$store.dispatch("rect/changeValueColor", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },
    unitColorValueHandler(e) {
      this.$store.dispatch("rect/changeUnitColor", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },

    toggleBorderVisibiltyHandler() {
      this.$store.dispatch("rect/toggleBorderVisibility", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
      });
    },
    toggleValueVisibiltyHandler() {
      this.$store.dispatch("rect/toggleValueVisibility", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
      });
    },

    changeAlertColor(e) {
      this.$store.dispatch("rect/changeAlertColor", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },
    changeBackgroundColor(e) {
      this.$store.dispatch("rect/changeCardBackgroundColor", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },

    changeBlinkAlert(e) {
      this.$store.dispatch("rect/changeBlinkAlert", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.checked,
      });
    },
    toggleTitleVisibiltyHandler() {
      this.$store.dispatch("rect/toggleTitleVisibility", {
        displayId: this.properties.displayId,
      });
    },
    toggleUnitVisibiltyHandler() {
      this.$store.dispatch("rect/toggleUnitVisibility", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
      });
    },

    async mnemonicChangeHandler(e) {
      this.$store.dispatch("rect/numericMemonicChange", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: this.Dtags[this.tags.indexOf(e.target.value)],
        fullName: e.target.value,
      });

      // let tagValue = await api.WellServices.getDataForTags({
      //   fields: e.target.value,
      // });

      // // alert(JSON.stringify(tagValue.data.rows[0][e.target.value]))
      // this.$store.dispatch("rect/numericValueUpdate", {
      //   displayId: this.properties.displayId,
      //   widgetId: this.properties.widgetId,
      //   value: tagValue.data.rows[0][e.target.value],
      // });
    },
    decimalValueHandler(e) {
      if (e.target.value < 14 && e.target.value > -1) {
        this.$store.dispatch("rect/decimalValue", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });
      }
    },
    fontValueHandler(e) {
      this.$store.dispatch("rect/titleFontValue", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value ? parseInt(e.target.value) : 0,
      });
    },
    valueFontValueHandler(e) {
      this.$store.dispatch("rect/valueFontValue", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value ? parseInt(e.target.value) : 0,
      });
    },
    unitFontValueHandler(e) {
      this.$store.dispatch("rect/unitFontValue", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value ? parseInt(e.target.value) : 0,
      });
    },
    changeStartRange(e) {
      this.$store.dispatch("rect/numericAlarmStartRange", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },
    changeEndRange(e) {
      this.$store.dispatch("rect/numericAlarmEndRange", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },

    toggle_height_handler_one() {
      if (this.isExpandFeatureOne == true) {
        this.isExpandFeatureOne = false;
      } else {
        this.isExpandFeatureOne = true;
      }
    },
    toggle_height_handler_two() {
      if (this.isExpandFeatureTwo == true) {
        this.isExpandFeatureTwo = false;
      } else {
        this.isExpandFeatureTwo = true;
      }
    },
    toggle_height_handler_three() {
      if (this.isExpandFeatureThree == true) {
        this.isExpandFeatureThree = false;
      } else {
        this.isExpandFeatureThree = true;
      }
    },
    toggle_height_handler_four() {
      if (this.isExpandFeatureFour == true) {
        this.isExpandFeatureFour = false;
      } else {
        this.isExpandFeatureFour = true;
      }
    },
    toggle_height_handler_five() {
      if (this.isExpandFeatureFive == true) {
        this.isExpandFeatureFive = false;
      } else {
        this.isExpandFeatureFive = true;
      }
    },
    toggle_height_handler_six() {
      if (this.isExpandFeatureSix == true) {
        this.isExpandFeatureSix = false;
      } else {
        this.isExpandFeatureSix = true;
      }
    },
    async getTags() {
      // let tagsResponse = await api.WellServices.getColumnsOfDepthLog();
      // this.tags = tagsResponse.data;
    },
    dark() {
      this.darkMode = true;
      this.$emit("dark");
    },
    light() {
      this.darkMode = false;
      this.$emit("light");
    },
  },
  watch: {
    widgetId(newVal) {
      let rects = this.$store.state.rect.rects;
      if (typeof rects != "undefined" && rects.length > 0) {
        for (let i = 0; i < rects.length; i++) {
          if (
            rects[i].widgetId == this.widgetId &&
            rects[i].displayId == this.displayId
          )
            this.idx = i;
        }
      }
    },
  },
};
</script>

<style scoped src="../../assets/styles/propstyle.css"></style>
