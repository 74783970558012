<template>
  <div @contextmenu="this.setContextIdWidget" class="LwdMain">
    <input
      class="hidden"
      type="button"
      @click="printDiv('printableArea')"
      value="print a div!"
    />
    <section>
      <LwdManagement
        v-if="hide_management"
        @toggle_management="toggle_management"
        :wellDetails="wellDetails"
        :wellIdProp="wellId"
        :wellboreId="wellboreId"
        @fetching_data_event="fetching_data"
        :wellName="wellName"
      />
      <addWellPage
        v-if="addWellPopHide"
        :wellIdProp="wellId"
        @add_well_pop="add_well_pop"
        :wellboreId="wellboreId"
        :wellName="wellName"
      />
      <div>
        <LwdTopBar
          :onToggleSideBar="toggleLeftBar"
          :show_left_bar="show_left_bar"
          @hide_zoom_tooltip="hide_zoom_tooltip"
          :hide_tooltip_zoom="hide_tooltip_zoom"
          :isBackgroundLive="isBackgroundLive"
          @toggle_management="toggle_management"
          @add_well_pop="add_well_pop"
          @fileTypeToggler="fileTypeToggler"
          @make_it_live="getRealtimeData"
          :displayId="displayId"
          :widgetId="widgetId"
          :srcWellData='srcWellData'
          :srcWellLWDData ='srcWellLWDData'
          :wellDetails = 'wellDetails'
        />
      </div>
      <div class="flex">
        <Lwd_sidebar
          v-show="show_left_bar"
          :displayId="displayId"
          :widgetId="widgetId"
          :widgetIndex="widgetIndex"
          :displayIndex="displayIndex"
          :height="height - 20"
          :idx="idx"
          :mnemonics_data="mnemonics_data"
          :mnemonics_consolidated_data="mnemonics_consolidated_data"
          @selected_runs="selected_runs"
          :copy_of_data_points="copy_of_data_points"
          :selectedType="selectedType"
          @depthType_toggle_listener="depthType_toggle_listener"
          @show_file_save_popup="show_file_save_popup"
          @save_default_new_file="save_default_new_file"
          @changeScaleInTrend="changeScaleInTrend"
          :savedLwdList="savedLwdList"
          :select_default_saved_file="select_default_saved_file"
          @showSavedFileDeletePopup="showSavedFileDeletePopup"
          :newly_saved_or_updated_file_payload="newly_saved_or_updated_file_payload"
          @setting_type_of_consolidated="setting_type_of_consolidated"
          @apply_rerender="apply_rerender"
        />
        <div class="relative">
          <div
            id="printableArea"
            :style="{ left: show_left_bar ? '10rem' : '0' }"
            v-bind:class="{
              content: true,
              hidden:
                this.$store.state.rect.rects[this.idx]?.lwdLeftBarData
                  ?.tracksData?.length || this.default_tracks
                  ? false
                  : true,
            }"
          >
            <div class="container2">
              <Trend
                ref="trend"
                class="plot"
                :id="widgetId + 'trend'"
                :trendIndex="0"
                :height="height - 20"
                :width="show_left_bar ? width - 160 : parseInt(width)"
                :top="top"
                :style="{
                  width: show_left_bar
                    ? width - 160 + 'px'
                    : parseInt(width) + 'px',
                }"
                :left="left"
                :displayId="displayId"
                :widgetId="widgetId"
                :plotConfigProps="plotConfig"
                :displayIndex="displayIndex"
                :widgetIndex="widgetIndex"
                :requiredData="[]"
                :well_id="wellId"
                :wellDetails="wellDetails"
                :idx="idx"
                :scaleMaxFixed_Prop="scaleMaxFixed"
                :scaleMax_Prop="scaleMax"
                :scaleMaxProp="scaleMaxFixing"
                :total_leftbar_data="total_leftbar_data"
                :left_bar_tracks="left_bar_tracks"
                :default_tracks_prop="default_tracks"
                :number_of_tracks="number_of_tracks"
                :data_points="data_points"
                :hide_tooltip_zoom="hide_tooltip_zoom"
                :depthType="depthType"
                :lithology_icons_data="lithology_icons_data"
                :lithology_icons_list="lithology_icons_list"
                :selected_files="multiple_runs"
                :payload_to_get_file_litho_ranges="
                  payload_to_get_file_litho_ranges
                "
                @lithology_data="lithology_data"
                @getConsolidateLithologyIcons="getConsolidateLithologyIcons"
                @selected_runs="selected_runs"
                :files_data="mnemonics_data"
                @background_live="backgroundLive"
                :selectedType="selectedType"
                :consolidatedListData="consolidatedListData"
                :selectedSavedRun="selectedSavedRun"
                @getting_curveProps="getting_curveProps"
                :isBackgroundLive="isBackgroundLive"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- CONFLICT POPUP STARTS -->
    <div class="runInfo" v-if="show_conflict_popup">
      <div class="popup" style="width:25%;">
        <div class="p-5 text-xs flex flex-col items-center">
          <h4 class="" v-if="conflict_range['sameValues']">
            In the below files, start depth
            <span style="color: var(--activeTextColor)">{{
              conflict_range["start"]
            }}</span>
            , end depth
            <span style="color: var(--activeTextColor)">{{
              conflict_range["end"]
            }}</span>
            are same,
          </h4>
          <h4 class="" v-else>
            There is a conflict in below files from
            <span style="color: var(--activeTextColor)">{{
              conflict_range["start"]
            }}</span>
            to
            <span style="color: var(--activeTextColor)">{{
              conflict_range["end"]
            }}</span>
            depths,
          </h4>
          <h4 class="">Please choose the file below to get the data.</h4>
          <div class="pt-2" style="width: 90%">
            <div class="flex justify-around">
              <div class="flex items-center w-1/2 px-1">
                <input
                  type="radio"
                  :value="conflict_range['actualObject'].index"
                  v-model="choosedFileIndex"
                />
                <label class="truncate pl-2 font-semibold uppercase" :title="conflict_range['actualObject'].obj.name">{{
                  conflict_range["actualObject"].obj.name
                }}</label>
              </div>
              <div class="flex items-center w-1/2 px-1">
                <input
                  style="margin-left:0;"
                  type="radio"
                  :value="conflict_range['conflictObject'].index"
                  v-model="choosedFileIndex"
                />
                <label class="truncate pl-2 font-semibold uppercase" :title="conflict_range['conflictObject'].obj.name">{{
                  conflict_range["conflictObject"].obj.name
                }}</label>
              </div>
            </div>
            <div class="flex justify-center mt-4">
              <button
                v-if="choosedFileIndex !== null"
                type="button"
                @click="resolveConflict"
                class="text-xxs font-semibold border"
                style="
                  padding: 3px 8px;
                  outline: none;
                  color: var(--activeTextColor);
                  border-color: currentColor;
                "
              >
                Submit
              </button>
              <button
                v-on:click="
                  () => {
                    this.show_conflict_popup = false;
                  }
                "
                class="text-xxs font-semibold"
                style="
                  padding: 3px 8px;
                  border: 1px solid #f40303;
                  outline: none;
                  color: #f40303;
                  margin-left: 10px;
                "
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- CONFLICT POPUP ENDS -->


   <!-- FILE SAVE POPUP STARTS -->
    <div class="runInfo" v-if="fileSavePopup">
      <div class="popup">
        <div class="p-5 text-xs flex flex-col items-center">
          <div class="">
            <div class="">
              <div class="flex items-center">
                <label class="pl-2 font-semibold uppercase w-1/2"
                  >Start Range</label
                >
                <input
                  class="fieldHeight w-1/2 shadow appearance-none border rounded text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                  type="number"
                  v-model="fileStartRange"
                  style="
                    background: var(--searchBarBg);
                    color: var(--searchColor);
                  "
                />
              </div>
              <div class="flex items-center mt-2">
                <label class="pl-2 font-semibold uppercase w-1/2"
                  >End range</label
                >
                <input @change="newFileEndRangeChanged"
                  class="fieldHeight w-1/2 shadow appearance-none border rounded text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                  type="number"
                  v-model="fileEndRange"
                  style="
                    background: var(--searchBarBg);
                    color: var(--searchColor);
                  "
                />
              </div>
              <div
                class="flex items-center mt-2"
                v-if="
                  fileStartRange !== null &&
                  fileStartRange !== '' &&
                  fileEndRange !== null &&
                  fileEndRange !== ''
                "
              >
                <label class="pl-2 font-semibold uppercase w-1/2">Run Name</label>
                 <input
                  class="fieldHeight w-1/2 shadow appearance-none border rounded text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                  type="text"
                  v-model="fileNewName"
                  style="
                    background: var(--searchBarBg);
                    color: var(--searchColor);
                  "
                />
              </div>
            </div>
            <div class="flex justify-center mt-4">
              <button
                v-if="
                  fileStartRange !== null &&
                  fileStartRange !== '' &&
                  fileEndRange !== null &&
                  fileEndRange !== '' &&
                  fileNewName !== null &&
                  fileNewName !== ''
                "
                type="button"
                @click="add_new_run_file"
                class="text-xxs font-semibold border"
                style="
                  padding: 3px 8px;
                  outline: none;
                  color: var(--activeTextColor);
                  border-color: currentColor;
                "
              >
                Submit
              </button>
              <button
                @click="close_new_file_adding_popup"
                class="text-xxs font-semibold"
                style="
                  padding: 3px 8px;
                  border: 1px solid #f40303;
                  outline: none;
                  color: #f40303;
                  margin-left: 10px;
                "
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- FILE SAVE POPUP ENDS -->

    <!-- SAVED FILE DELETE POPUP STARTS -->
    <div class="runInfo" v-if="show_saved_file_delete_popup">
      <div class="popup">
        <div class="p-5 text-xs flex flex-col items-center">
          <div class="">
            <h4 class="uppercase">
              Do you want to delete the
              <span style="color: var(--activeTextColor)">{{
                delete_saved_file_obj?.name
              }}</span>
              saved file ?
            </h4>
            <div class="flex justify-center mt-4">
              <button
                type="button"
                @click="yes_delete_saved_file"
                class="text-xxs font-semibold border"
                style="
                  padding: 3px 8px;
                  outline: none;
                  color: var(--activeTextColor);
                  border-color: currentColor;
                "
              >
                Yes
              </button>
              <button
                @click="closeSavedFileDeletePopup"
                class="text-xxs font-semibold"
                style="
                  padding: 3px 8px;
                  border: 1px solid #f40303;
                  outline: none;
                  color: #f40303;
                  margin-left: 10px;
                "
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- SAVED FILE DELETE POPUP ENDS -->

  </div>
</template>

<script>
import Trend from "./LwdTrend.vue";
import { defineAsyncComponent } from "vue";
import LwdTopBar from "../../Navbars/LwdTopNavBar.vue";
import Lwd_sidebar from "../../Sidebar/Lwd_sidebar.vue";
import apiService from "../../../api/services";
import addWellPage from "../../Widgets/LwdManagement/addWell.vue";
export default {
  name: "LWD",
  data() {
    return {
      scaleMaxFixing: { scaleMax: this.scaleMax },
      isBackgroundLive: true,
      show_left_bar: false,
      idx: null,
      wellId: null,
      wellName: null,
      wellboreId: null,
      logId: null,
      status: null,
      logType: null,
      logTypeId: null,
      wellDetails: {},
      hide_tooltip_zoom: true,
      hide_management: false,
      addWellPopHide: false,
      correlation: [],
      wellCorrelationCompleteData: {},
      mnemonics: [
        "dmea",
        "rpm",
        "spp",
        "wob",
        "diff",
        "mfi",
        "rop",
        "tor",
        "simplifiedmse",
        "tvd",
        "inclination",
        "gamma_ray",
        "dls",
        "rop",
        "sl_res",
      ],
      default_tracks: [
        [
          {
            name: "wob",
            fullName: "wob",
            color: "#c530ab",
            trackNbr: 0,
            curveNbr: 0,
            trackId: "t0gihl",
            min: 51.41224,
            max: 52.6431,
            scale: [51.41224, 52.6431],
            scaleType: "auto",
            stroke: 2,
          },
          {
            name: "rpm",
            fullName: "rpm",
            color: "#ff0000",
            trackNbr: 0,
            curveNbr: 0,
            trackId: "t0gihl",
            min: 51.41224,
            max: 52.6431,
            scale: [51.41224, 52.6431],
            scaleType: "auto",
            stroke: 2,
          },
          {
            name: "mfi",
            fullName: "mfi",
            color: "#2abbbc",
            trackNbr: 0,
            curveNbr: 0,
            trackId: "t0gihl",
            min: 51.41224,
            max: 52.6431,
            scale: [51.41224, 52.6431],
            scaleType: "auto",
            stroke: 2,
          },
          {
            name: "rop",
            fullName: "rop",
            color: "#F08E05",
            trackNbr: 0,
            curveNbr: 0,
            trackId: "t0gihl",
            min: 51.41224,
            max: 52.6431,
            scale: [51.41224, 52.6431],
            scaleType: "auto",
            stroke: 2,
          },
          {
            name: "tor",
            fullName: "TORQUE",
            color: "#37b937",
            trackNbr: 0,
            curveNbr: 0,
            trackId: "t0gihl",
            min: 51.41224,
            max: 52.6431,
            scale: [51.41224, 52.6431],
            scaleType: "auto",
            stroke: 2,
          },
        ],
        [
          {
            name: "mfi",
            fullName: "mfi",
            color: "#2abbbc",
            trackNbr: 0,
            curveNbr: 0,
            trackId: "t0gihl",
            min: 51.41224,
            max: 52.6431,
            scale: [51.41224, 52.6431],
            scaleType: "auto",
            stroke: 2,
          },
          {
            name: "rop",
            fullName: "rop",
            color: "#F08E05",
            trackNbr: 0,
            curveNbr: 0,
            trackId: "t0gihl",
            min: 51.41224,
            max: 52.6431,
            scale: [51.41224, 52.6431],
            scaleType: "auto",
            stroke: 2,
          },
        ],
        [
          {
            name: "tor",
            fullName: "TORQUE",
            color: "#37b937",
            trackNbr: 0,
            curveNbr: 0,
            trackId: "t0gihl",
            min: 51.41224,
            max: 52.6431,
            scale: [51.41224, 52.6431],
            scaleType: "auto",
            stroke: 2,
          },
          {
            name: "spp",
            fullName: "spp",
            color: "#fe019a",
            trackNbr: 0,
            curveNbr: 0,
            trackId: "t0gihl",
            min: 51.41224,
            max: 52.6431,
            scale: [51.41224, 52.6431],
            scaleType: "auto",
            stroke: 2,
          },
        ],
        [
          {
            name: "tor",
            fullName: "TORQUE",
            color: "#37b937",
            trackNbr: 0,
            curveNbr: 0,
            trackId: "t0gihl",
            min: 51.41224,
            max: 52.6431,
            scale: [51.41224, 52.6431],
            scaleType: "auto",
            stroke: 2,
          },
          {
            name: "spp",
            fullName: "spp",
            color: "#fe019a",
            trackNbr: 0,
            curveNbr: 0,
            trackId: "t0gihl",
            min: 51.41224,
            max: 52.6431,
            scale: [51.41224, 52.6431],
            scaleType: "auto",
            stroke: 2,
          },
        ],
        [
          {
            name: "tor",
            fullName: "TORQUE",
            color: "#37b937",
            trackNbr: 0,
            curveNbr: 0,
            trackId: "t0gihl",
            min: 51.41224,
            max: 52.6431,
            scale: [51.41224, 52.6431],
            scaleType: "auto",
            stroke: 2,
          },
          {
            name: "spp",
            fullName: "spp",
            color: "#fe019a",
            trackNbr: 0,
            curveNbr: 0,
            trackId: "t0gihl",
            min: 51.41224,
            max: 52.6431,
            scale: [51.41224, 52.6431],
            scaleType: "auto",
            stroke: 2,
          },
        ],
      ],
      mnemonics_data: [],
      mnemonics_consolidated_data: [],
      data_points: {},
      copy_of_data_points: {},
      multiple_runs: [],
      show_conflict_popup: false,
      conflict_range: {},
      choosedFileIndex: null,
      lithology_icons_data: [],
      lithology_icons_list: [],
      payload_to_get_file_litho_ranges: {},
      conflict_check_from_trend: false,
      loggedInUser: null,
      selectedType: "witsml",
      fileSavePopup: false,
      fileStartRange: null,
      fileEndRange: null,
      fileNewName: null,
      consolidatedListData: [],
      lwdPropsOpened: false,
      savedLwdList: [],
      selectedSavedRun: {},
      srcWellData:[],
      srcWellLWDData:[],
      select_default_saved_file: false,
      trend_curve_props: null,
      show_saved_file_delete_popup: false,
      delete_saved_file_obj: null,
      newly_saved_or_updated_file_payload: {},
      type_of_consolidated: null,
      toDeleteExistedConsolidatedData: [],
    };
  },
  watch:{
    displayIndex(val){
      try{
        let rects = this.$store.state.rect.rects;
        if (typeof rects != "undefined" && rects.length > 0) {
          for (let i = 0; i < rects.length; i++) {
            if (
              rects[i].widgetId == this.widgetId &&
              rects[i].displayId == this.displayId
            ){
              this.idx = i;
              break;
            }
          }
        }
      }
      catch(err){
        console.error(err);
      }
    },
  },
  computed: {
    depthType() {
      return (
        this.$store.state.rect.rects[this.idx]?.lwdLeftBarData?.depth_type ||
        "MD"
      );
    },
    scaleMax() {
      let logScale =
        this.$store.state.rect.rects[this.idx]?.lwdLeftBarData?.logScale;
      if (logScale) {
        return parseInt(logScale.split(":")[1]);
      }
      return 100;
    },
    scaleMaxFixed() {
      let logScale =
        this.$store.state.rect.rects[this.idx]?.lwdLeftBarData?.logScale;
      if (logScale) {
        return parseInt(logScale.split(":")[1]);
      }
      return 100;
    },
    total_leftbar_data() {
      return this.$store.state.rect.rects[this.idx]?.lwdLeftBarData || {};
    },
    left_bar_tracks() {
      return (
        this.$store.state.rect.rects[this.idx]?.lwdLeftBarData?.tracksData || []
      );
    },
    number_of_tracks() {
      return (
        this.$store.state.rect.rects[this.idx]?.lwdLeftBarData
          ?.numberOfTracks || null
      );
    },
  },
  beforeUnmount() {
    this.takeOffSocket();
  },
  beforeMount() {
    try {
      let rects = this.$store.state.rect.rects;
      if (typeof rects != "undefined" && rects.length > 0) {
        for (let i = 0; i < rects.length; i++) {
          if (
            rects[i].widgetId == this.widgetId &&
            rects[i].displayId == this.displayId
          )
            this.idx = i;
        }
      }
    } catch (err) {
      console.error(err);
    }
  },
  async mounted() {
    let displayId = this.displayId;
    let displayDtls = this.$store.state.disp.displays[displayId];
    let apollodart = this.$serviceHelpers.getDetails("apollodart");
    if (apollodart?.user) {
      this.loggedInUser = apollodart.user?.user_name;
    }

    if (displayDtls) {
      this.wellId = displayDtls.wellId;
      this.wellName = displayDtls.wellName;
      this.wellboreId = displayDtls.wellboreId;
      this.logId = displayDtls.logId;
      this.status = displayDtls.status;
      this.logType = displayDtls.logType;
      this.logTypeId = displayDtls.logTypeId;
      await this.fetching_data(this.selectedType);
    }
    this.$store.subscribe(async (mutation, state) => {
      if (
        mutation.type == "disp/setDisplay" &&
        this.displayId == mutation.payload.display
      ) {
        console.log("mutation.payload ", mutation.payload);
        this.wellDetails = mutation.payload;
        this.wellId = mutation.payload.wellId;
        this.wellName = mutation.payload.wellName
        this.wellboreId = mutation.payload.wellboreId;
        this.logId = mutation.payload.logId;
        this.status = mutation.payload.wellStatus;
        this.logType = mutation.payload.logType;
        this.logTypeId = mutation.payload.logTypeId;
        await this.fetching_data(this.selectedType);
        // setTimeout(() => {
        //   let localData = JSON.parse(localStorage.getItem("chalktalk"));
        //   console.log("mounted local ", localData);
        //   if (localData) {
        //     this.$store.dispatch("rect/LwdData", {
        //       displayId: this.displayId,
        //       widgetId: this.widgetId,
        //       value: {
        //         ...this.$store.state.rect.rects[this.idx]?.lwdLeftBarData,
        //         tracksData: localData.tracks,
        //       },
        //       field: "lwdLeftBarData",
        //     });
        //   }
        // }, 200);
        // this.topic = mutation.payload.swab_surge_topicname;
      }
    });
  },
  props: {
    widgetId: String,
    height: [String, Number],
    width: [String, Number],
    top: [String, Number],
    left: [String, Number],
    displayId: [String, Number],
    key: [String, Number],
    widgetIndex: [String, Number],
    displayIndex: [String, Number],
    plotConfig: {
      type: [Object, Array],
    },
    parentW: [Number, String],
    parentH: [Number, String],
  },
  components: {
    Trend,
    LwdTopBar,
    LwdManagement: defineAsyncComponent(() =>
      import("../LwdManagement/LwdManagement.vue")
    ),
    Lwd_sidebar,
    addWellPage,
  },
  sockets: {
    connect: function () {},
    disconnect() {
      this.isConnected = false;
      try {
        let topic = this.topic;
        this.$socket.emit("unsubscribe", {
          logId: this.topic,
          topic: this.topic,
          id: this.widgetId,
          clientId: this.$clientId,
        });
      } catch (error) {
        console.error("disconnect unsubscribe error ", error);
      }
    },
    error() {},
    reconnect() {
      this.$socket.emit("susbcribe", {
        logId: this.topic,
        topic: this.topic,
        fields: ["dmea"],
        id: this.widgetId,
        clientId: this.$clientId,
      });
    },
    reconnect_failed() {},
    reconnect_error() {},
    connect_error() {},
    connecting() {},
    connect_timeout() {},
  },
  methods: {
    async apply_rerender(){
      setTimeout(async ()=>{
        await this.$refs.trend.rerender();
      },0)
    },
    async setting_type_of_consolidated(type_of_consolidated){
      if(type_of_consolidated){
        this.type_of_consolidated = type_of_consolidated;
      }
      else{
        this.type_of_consolidated = null;
      }
    },
    setting_payload_to_get_consolidated_data(){
      let obj = {
        lwd_id: null,
        well_id: this.wellId,
        wellbore_name: this.wellboreId
      }
      if(this.selectedType == 'witsml'){
        obj.lwd_id = `${this.wellId}_${this.wellboreId}_consolidated`
      }
      else if(this.selectedType == 'las'){
        obj.lwd_id = `${this.wellId}_${this.wellboreId}_${this.type_of_consolidated}`
      }
      return obj;
    },
    async showSavedFileDeletePopup(savedFileObj){
      this.delete_saved_file_obj = savedFileObj;
      this.show_saved_file_delete_popup = true;
    },
    async closeSavedFileDeletePopup(){
      this.delete_saved_file_obj = null;
      this.show_saved_file_delete_popup = false;
    },
    async yes_delete_saved_file(){
      try{
        // INTEGRATE DELETE API HERE
        let payload={
          lwd_id: this.delete_saved_file_obj?.lwd_id,
          name: this.delete_saved_file_obj?.name
        }
        let res = await apiService.lwdServices.deleteSavedRun(payload);
        await this.closeSavedFileDeletePopup();
        if(res.status == 200){
          if(this.multiple_runs.length == 1){
            let payloadToGetSavedList= {
              lwd_id: this.multiple_runs[0].id,
              well_id: this.wellId,
              wellbore_name: this.wellboreId
            }
            await this.getSavedLwdRunsList(payloadToGetSavedList, 'selectDefault')
          }
          else{
            let payloadToGetSavedList = this.setting_payload_to_get_consolidated_data();
            await this.getSavedLwdRunsList(payloadToGetSavedList, 'selectDefault')
          }
          this.$toast.clear();
          this.$toast.success(`Saved file deleted successfully`, {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });
          await this.apply_rerender();
        }

      }
      catch(err){
        console.error(err)
      }
    },
    getting_curveProps(obj){
      this.trend_curve_props = obj;
    },
    changeScaleInTrend(selectedSavedRun){
      let obj = JSON.parse(JSON.stringify(selectedSavedRun))
      this.selectedSavedRun = obj;
      this.newly_saved_or_updated_file_payload = {};
    },
    async getSavedLwdRunsList(payloadToGetSavedList, for_default_selection, from){
      try{
        let savedList = await apiService.lwdServices.getRunSavedList(payloadToGetSavedList);
        if(savedList.status == 200){
          if(for_default_selection && for_default_selection == 'selectDefault'){
            this.select_default_saved_file = true;
          }
          else{
            this.select_default_saved_file = false;
          }
          this.savedLwdList = savedList.data
        }
        else{
          this.savedLwdList = [];
        }
        if(this.selectedType == 'witsml' && from && from == 'from_selected_runs' && !this.savedLwdList.length){
          for(let track = 0; track < this.left_bar_tracks.length; track++){
            if(this.left_bar_tracks[track].subtracks.length){
              break;
            }
            else if(this.left_bar_tracks.length - 1 == track && !this.left_bar_tracks[track].subtracks.length){
              if (this.wellId && this.wellboreId) {
                this.show_left_bar = true;
              }
              this.$toast.clear();
              this.$toast.info("Please select the subtracks from the left side bar", {
                duration: "2000",
                position: "top",
                pauseOnHover: true,
              });
            }
          }
        }
      }
      catch(err){
        console.error(err)
      }
    },
    newFileEndRangeChanged(){
      if(this.fileStartRange && this.fileEndRange && this.fileStartRange >= this.fileEndRange){
        this.fileEndRange = null;
        this.$toast.clear();
        this.$toast.error("End range should be greater than start range", {
          duration: "2000",
          position: "top",
          pauseOnHover: true,
        });
      }
      else if(this.fileStartRange && this.fileEndRange && this.fileStartRange < this.fileEndRange && this.fileEndRange-this.fileStartRange < 1){
        this.fileEndRange = null;
        this.$toast.clear();
        this.$toast.error(`End range should be equal or more than ${this.fileStartRange + 1}`, {
          duration: "2000",
          position: "top",
          pauseOnHover: true,
        });
      }
    },
    async add_new_run_file(from){
      let leftBarData = this.total_leftbar_data

      // TO SAVE THE CURVE PROPS CODE STARTS
      if(this.trend_curve_props && Object.keys(this.trend_curve_props).length){
        let copyOfleftBarData = JSON.parse(JSON.stringify(leftBarData))
        copyOfleftBarData.tracksData.forEach((eachTrack, index)=>{
          eachTrack.subtracks.forEach((eachMnemonic)=>{
            for (let key in this.trend_curve_props){
              let curveTrackIndex = key.split('-')[0]
              if(index == curveTrackIndex && this.trend_curve_props[key]?.curve?.name == eachMnemonic.sourceMnemonic){
                eachMnemonic.scaleType =this.trend_curve_props[key]?.curve?.scaleType
                eachMnemonic.scale = this.trend_curve_props[key]?.curve?.scale
                eachMnemonic.color = this.trend_curve_props[key]?.curve?.color
                break;
              }
            }
            return eachMnemonic;
          })
          return eachTrack;
        })
        leftBarData = copyOfleftBarData;
      }
      // TO SAVE THE CURVE PROPS CODE ENDS

      let payload = {}
      this.newly_saved_or_updated_file_payload = {};
      try{
        if(from && from == 'default'){
          if(this.multiple_runs.length == 1){
            payload = {
              "lwd_id": this.multiple_runs[0].id,
              "content": {
                ...leftBarData
              },
              "end_depth": this.multiple_runs[0].end_depth,
              "is_default": true,
              "name": 'default',
              "start_depth": this.multiple_runs[0].start_depth,
              "well_id": this.wellId,
              "wellbore_name": this.wellboreId
            }
          }
          else{
            let consolidated_data = JSON.parse(JSON.stringify(this.consolidatedListData))
            let forStartDepth = consolidated_data.sort((a,b)=> a.start_depth - b.start_depth)[0]
            let forEndDepth = consolidated_data.sort((a,b)=> b.end_depth - a.end_depth)[0]

            payload = {
              "lwd_id": this.type_of_consolidated ? `${this.wellId}_${this.wellboreId}_${this.type_of_consolidated}` : `${this.wellId}_${this.wellboreId}_consolidated`,
              "content": {
                ...leftBarData
              },
              "end_depth": forEndDepth.end_depth,
              "is_default": true,
              "name": 'default',
              "start_depth": forStartDepth.start_depth,
              "well_id": this.wellId,
              "wellbore_name": this.wellboreId
            }
          }
        }
        else{
          if(this.multiple_runs.length == 1){
            payload = {
              "lwd_id": this.multiple_runs[0].id,
              "content": {
                ...leftBarData
              },
              "end_depth": this.fileEndRange,
              "is_default": false,
              "name": this.fileNewName,
              "start_depth": this.fileStartRange,
              "well_id": this.wellId,
              "wellbore_name": this.wellboreId
            }
          }
          else{
            payload = {
              "lwd_id": this.type_of_consolidated ? `${this.wellId}_${this.wellboreId}_${this.type_of_consolidated}` : `${this.wellId}_${this.wellboreId}_consolidated`,
              "content": {
                ...leftBarData
              },
              "end_depth": this.fileEndRange,
              "is_default": false,
              "name": this.fileNewName,
              "start_depth": this.fileStartRange,
              "well_id": this.wellId,
              "wellbore_name": this.wellboreId
            }
          }
        }
        this.newly_saved_or_updated_file_payload = JSON.parse(JSON.stringify(payload));
        let res = await apiService.lwdServices.save_file_with_selected_data(payload);
        if(res.status == 200){
          if(this.multiple_runs.length == 1){
            let payloadToGetSavedList= {
              lwd_id: this.multiple_runs[0].id,
              well_id: this.wellId,
              wellbore_name: this.wellboreId
            }
            await this.getSavedLwdRunsList(payloadToGetSavedList)
          }
          else{
            let payloadToGetSavedList = this.setting_payload_to_get_consolidated_data();
            await this.getSavedLwdRunsList(payloadToGetSavedList)
          }
          this.$toast.clear();
          this.$toast.success(`File saved successfully`, {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });
        }
      }
      catch(err){
        console.error(err);
      }
      await this.close_new_file_adding_popup()
      await this.apply_rerender();
    },
    async close_new_file_adding_popup(){
      this.fileSavePopup = false;
      this.fileNewName = null;
      this.fileStartRange = null;
      this.fileEndRange = null;
    },
    show_file_save_popup(){
      this.fileSavePopup = true;
    },
    async save_default_new_file(){
      await this.add_new_run_file('default')
    },
    backgroundLive() {
      this.isBackgroundLive = true;
    },
    makeItLive() {
      this.isBackgroundLive = false;
    },
    setContextIdWidget(e) {
      this.$store.dispatch("disp/setContextId", {
        contextId: this.displayId,
        contextWidgetId: this.widgetId,
        contextType: "widget",
      });
    },
    async getRealtimeData(logId, from = null) {
      this.liveDisabled = true;
      try {
        // let well_response = await apiService.WellServices.getWellDetails({
        //   well_id: this.wellId,
        //   customer: this.$store.state.data.customer,
        //   log: "depth",
        // });
        // // this.topic = 'PNRG_Main_W_35_CHALKTALKB3H_LWD_2';
        // this.topic = well_response.data.tnd_topicname;
        // console.log('____check__well__details____', well_response.data)
        // this.srcWellData = well_response.data
        // if(this.topic && (well_response?.well_status == 4 || well_response?.well_status == 104)){
        //   this.makeItLive();
        // }
        // console.log("🚀 ➽ file: LwdMain.vue:521  ➽ this.topic ⏩", this.topic);

        const getLWD_well_log_List = await apiService.WellServices.getWellDetails({
          well_id: this.wellId,
          customer: this.$store.state.data.customer,
          log: "lwd",
        });
        // getLWD_well_log_List.data.tnd_topicname = null;
        // if(!getLWD_well_log_List.data.tnd_topicname){
        // CHECKING FOR TOPIC NAME IF WELL IS RUNNING AND IF TOPIC NAME IS NOT FOUND CODE STARTS
        if((!from || (from && from != 'checking_topic_name')) && (getLWD_well_log_List?.data?.well_status == 4 || getLWD_well_log_List?.data?.well_status == 104) && !getLWD_well_log_List.data.tnd_topicname){
            let reading_count=0;
            let three_times_calling_fun = async ()=>{
              reading_count++
              let lwd_log_data = await apiService.WellServices.getWellDetails({
                well_id: this.wellId,
                customer: this.$store.state.data.customer,
                log: "lwd",
              });
              if((lwd_log_data?.data?.well_status == 4 || lwd_log_data?.data?.well_status == 104) && lwd_log_data.data.tnd_topicname){ // IF HAD TOPIC NAME THEN ASSIGNING
                await this.getRealtimeData('', 'checking_topic_name');
                return;
              }
              else{
                if(reading_count == 3){
                  return;
                }
                else{
                  setTimeout(async ()=>{ await three_times_calling_fun();},5000)
                  return;
                }
              }
            }
            setTimeout(async ()=>{ await three_times_calling_fun()},5000)
        }
        // CHECKING FOR TOPIC NAME IF WELL IS RUNNING AND IF TOPIC NAME IS NOT FOUND CODE ENDS

        this.srcWellLWDData = getLWD_well_log_List.data
        this.topic = getLWD_well_log_List.data.tnd_topicname;
        // console.log('____check__well__details____', getLWD_well_log_List.data)
        this.srcWellData = getLWD_well_log_List.data
        if(this.topic && (getLWD_well_log_List?.data?.well_status == 4 || getLWD_well_log_List?.data?.well_status == 104)){
          this.makeItLive();
        }
        else{
          this.backgroundLive();
        }
        // console.log("🚀 ➽ file: LwdMain.vue:521  ➽ this.topic ⏩", this.topic);
        console.log('____check__well__details____lwd', this.srcWellLWDData)
      } catch (error) {
        console.log("🚀 ➽ file: LwdMain.vue:523  ➽ error ⏩", error);
        return false;
      }
      // let test = this.id;
      // if (this.data === undefined || this.data === null) {
      //   this.data = [];
      //   this.mnemonic_groups = {};
      // }
      let topic = this.topic;
      // let wells = this.$store.state.data.wells;
      // let well = wells[this.wellId];
      // if (well) {
      //   let wellbore = well.wellbores[this.wellboreId];
      //   let log = wellbore["logs"][this.logType];
      //   this.logId = log.id;
      //   let fields = this.getOnScreenMnemonics();
      //   fields.push("time");
      //   fields.push("DEPTH");
      //   this.fields = fields;
      let topicExists = this.$store.state.live.isSubscribed[this.topic];
      //   // if (this.logId) {
      this.$socket.emit("susbcribe", {
        logId: this.topic,
        topic: this.topic,
        fields: ["depth"],
        id: this.widgetId,
        clientId: this.$clientId,
        logType: this.logType,
      });
      if (!topicExists) {
        this.$store.dispatch("live/setSubscriptionFlag", {
          topic,
          flag: true,
        });
      }
      let soket_timeout_variable = null;
      this.sockets.subscribe(this.$clientId, (sResult) => {
        let result = sResult[this.widgetId];
        let depth_index = -1;
        if ( Object.keys(this.data_points).length &&
          this.data_points["DEPTH"][this.data_points["DEPTH"].length - 1] >=
          result["DEPTH"][0]
        ) {
          depth_index = this.data_points["DEPTH"].indexOf(result["DEPTH"][0]);
        }
        for (let i in this.data_points) {
          try {
            if (depth_index != -1) {
              this.data_points[i].splice(depth_index);
            }
            // console.log("🚀 ➽ file: LwdMain.vue:599  ➽ this.sockets.subscribe  ➽ i ⏩", i)
            if (result[i]) {
              this.data_points[i] = [...this.data_points[i], ...result[i]];
            }
          } catch (error) {
            console.log(
              "🚀 ➽ file: LwdMain.vue:607  ➽ this.sockets.subscribe  ➽ error ⏩",
              error
            );
          }
        }
        if (!this.isBackgroundLive) {
          this.data_points = { ...this.data_points }
          this.apply_rerender();
        };
      });
      topicExists = this.$store.state.live.isSubscribed[topic];
    },

    async depthType_toggle_listener(type) {
      let type_of_depth = type || "MD";
      if (
        type_of_depth == "MD" &&
        Object.keys(this.copy_of_data_points).includes("DEPTH")
      ) {
        await this.sorting_data_points("DEPTH");
      }
      if (
        type_of_depth == "TVD" &&
        Object.keys(this.copy_of_data_points).includes("TVD")
      ) {
        await this.sorting_data_points("TVD");
      }

      if (
        type_of_depth == "MD" &&
        Object.keys(this.copy_of_data_points).includes("DEPTH")
      ) {
        this.data_points = {
          ...this.copy_of_data_points,
          DEPTH: this.copy_of_data_points["DEPTH"],
        };
        // this.data_points['DEPTH'] = this.copy_of_data_points['DEPTH']
      } else if (
        type_of_depth == "TVD" &&
        Object.keys(this.copy_of_data_points).includes("TVD")
      ) {
        this.data_points = {
          ...this.copy_of_data_points,
          DEPTH: this.copy_of_data_points["TVD"],
        };
        // this.data_points['DEPTH'] = this.copy_of_data_points['TVD']
      } else {
        this.data_points = {};
      }
    },
    async sorting_data_points(sortingKey) {
      // Create an array of indices to maintain the original order
      let indices = this.copy_of_data_points[sortingKey].map((_, i) => i);

      // Sort the indices based on the values in array 'DEPTH'
      indices.sort(
        (i, j) =>
          this.copy_of_data_points[sortingKey][i] -
          this.copy_of_data_points[sortingKey][j]
      );

      // Use the sorted indices to rearrange the arrays 'b', 'c', and 'd'
      for (let key in this.copy_of_data_points) {
        this.copy_of_data_points[key] = indices.map(
          (i) => this.copy_of_data_points[key][i]
        );
      }
    },
    async fileTypeToggler(val) {
      this.takeOffSocket();
      this.selectedType = val ? "witsml" : "las";
      if (this.wellId && this.wellboreId) {
        await this.fetching_data(this.selectedType);
      }
    },
    async getDataPoints(payload, scaleMax) {
      this.$store.dispatch("data/startLoading");
      let data_points_res = await apiService.lwdServices.data_points({
        lwdId: payload,
      });

      if (data_points_res.status == 200 && data_points_res.data != "") {
        this.data_points = data_points_res.data;
        this.copy_of_data_points = data_points_res.data;
        await this.depthType_toggle_listener(this.depthType);
      } else {
        this.data_points = {};
        this.copy_of_data_points = {};
      }
      await this.$store.dispatch("data/stopLoading");
      if (scaleMax && scaleMax != "trend")
        this.scaleMaxFixing = { ...this.scaleMaxFixing, scaleMax };
      this.takeOffSocket();
      if (payload[0] && payload[0].id && payload[0].id.includes("WITSML")) {
        console.log(
          "🚀 ➽ file: LwdMain.vue:613  ➽ getDataPoints  ➽ payload ⏩",
          payload[0].id.includes("WITSML")
        );
        this.getRealtimeData("PNRG_Main_W_35_CHALKTALKB3H_LWD_2");
      }
    },
    takeOffSocket() {
      this.liveDisabled = false;
      if (this.$socket && this.topic) {
        this.$socket.emit("unsubscribe", {
          logId: this.topic,
          topic: this.topic,
          id: this.widgetId,
          clientId: this.$clientId,
        });
        this.sockets.unsubscribe(this.$clientId);
      }
      this.stopUpdation = false;
    },
    async lithology_data(payload, enableRender) {
      try {
        this.payload_to_get_file_litho_ranges = payload;
        this.lithology_icons_list = [];
        // this.lithology_icons_data = [];
        let icons_res = await apiService.lwdServices.lwd_icons_list();
        if (icons_res.status == 200) {
          this.lithology_icons_list = icons_res.data;
        }
        let lithology_res = await apiService.lwdServices.lwd_icons_search(
          payload
        );
        if (lithology_res.status == 200) {
          this.lithology_icons_data = lithology_res.data;
          this.lithology_icons_data.forEach((each) => {
            let foundIcon = this.lithology_icons_list.find(
              (searchIcon) => searchIcon.displayIconId == each.lithologyData
            );
            if (foundIcon) {
              each.icon = foundIcon.icon;
            } else {
              each.icon = "";
            }
          });
        }
        else if(lithology_res.status == 204){
          this.lithology_icons_data = [];
        }
        if(enableRender && enableRender == 'enable_render'){
          await this.apply_rerender();
        }
      } catch (err) {
        console.error(err);
      }
    },
    async getConsolidateLithologyIcons(payload, enableRender) {
      try {
        this.payload_to_get_file_litho_ranges = payload;
        this.lithology_icons_list = [];
        // this.lithology_icons_data = [];
        let icons_res = await apiService.lwdServices.lwd_icons_list();
        if (icons_res.status == 200) {
          this.lithology_icons_list = icons_res.data;
        }
        let lithology_res =
          await apiService.lwdServices.consolidate_lithology_icons_search(
            payload
          );
        if (lithology_res.status == 200) {
          this.lithology_icons_data = lithology_res.data;
          this.lithology_icons_data.forEach((each) => {
            let foundIcon = this.lithology_icons_list.find(
              (searchIcon) => searchIcon.displayIconId == each.lithologyData
            );
            if (foundIcon) {
              each.icon = foundIcon.icon;
            } else {
              each.icon = "";
            }
          });
        }
        if(!this.lithology_icons_data.length){
          this.lithology_icons_data = [];
        }
        if(enableRender && enableRender == 'enable_render'){
          await this.apply_rerender();
        }
      } catch (err) {
        console.error(err);
      }
    },
    async resolveConflict() {
      if (this.conflict_range["actualObject"].index == this.choosedFileIndex) {
        if (
          this.conflict_range["actualObject"].obj.end_depth <
          this.conflict_range["conflictObject"].obj.end_depth
        ) {
          this.multiple_runs[
            this.conflict_range["conflictObject"].index
          ].start_depth = this.conflict_range["actualObject"].obj.end_depth;
        } else if (
          this.conflict_range["actualObject"].obj.end_depth >
          this.conflict_range["conflictObject"].obj.end_depth
        ) {
          this.multiple_runs[
            this.conflict_range["conflictObject"].index
          ].start_depth = 0;
          this.multiple_runs[
            this.conflict_range["conflictObject"].index
          ].end_depth = 0;
        } else if (
          this.conflict_range["actualObject"].obj.start_depth !=
            this.conflict_range["conflictObject"].obj.start_depth &&
          this.conflict_range["actualObject"].obj.end_depth ==
            this.conflict_range["conflictObject"].obj.end_depth
        ) {
          this.multiple_runs[
            this.conflict_range["conflictObject"].index
          ].start_depth = 0;
          this.multiple_runs[
            this.conflict_range["conflictObject"].index
          ].end_depth = 0;
        } else {
          this.multiple_runs[
            this.conflict_range["conflictObject"].index
          ].start_depth = 0;
          this.multiple_runs[
            this.conflict_range["conflictObject"].index
          ].end_depth = 0;
        }
      } else if (
        this.conflict_range["conflictObject"].index == this.choosedFileIndex
      ) {
        if (
          this.conflict_range["actualObject"].obj.end_depth >
            this.conflict_range["conflictObject"].obj.end_depth &&
          this.conflict_range["actualObject"].obj.start_depth ==
            this.conflict_range["conflictObject"].obj.start_depth
        ) {
          this.multiple_runs[
            this.conflict_range["actualObject"].index
          ].start_depth = this.conflict_range["conflictObject"].obj.end_depth;
        } else if (
          this.conflict_range["actualObject"].obj.end_depth <
            this.conflict_range["conflictObject"].obj.end_depth &&
          this.conflict_range["actualObject"].obj.start_depth ==
            this.conflict_range["conflictObject"].obj.start_depth
        ) {
          this.multiple_runs[
            this.conflict_range["actualObject"].index
          ].start_depth = 0;
          this.multiple_runs[
            this.conflict_range["actualObject"].index
          ].end_depth = 0;
        } else if (
          this.conflict_range["actualObject"].obj.end_depth >
          this.conflict_range["conflictObject"].obj.end_depth
        ) {
          this.multiple_runs.push({
            id: this.conflict_range["actualObject"].obj.id,
            start_depth: this.conflict_range["conflictObject"].obj.end_depth,
            end_depth: this.conflict_range["actualObject"].obj.end_depth,
            name: this.conflict_range["actualObject"].obj.name,
          });
          this.multiple_runs[
            this.conflict_range["actualObject"].index
          ].end_depth = this.conflict_range["conflictObject"].obj.start_depth;
        } else if (
          this.conflict_range["actualObject"].obj.end_depth <
          this.conflict_range["conflictObject"].obj.end_depth
        ) {
          this.multiple_runs[
            this.conflict_range["actualObject"].index
          ].end_depth = this.conflict_range["conflictObject"].obj.start_depth;
        } else if (
          this.conflict_range["actualObject"].obj.start_depth !=
            this.conflict_range["conflictObject"].obj.start_depth &&
          this.conflict_range["actualObject"].obj.end_depth ==
            this.conflict_range["conflictObject"].obj.end_depth
        ) {
          this.multiple_runs[
            this.conflict_range["actualObject"].index
          ].end_depth = this.conflict_range["conflictObject"].obj.start_depth;
        } else {
          this.multiple_runs[
            this.conflict_range["actualObject"].index
          ].start_depth = 0;
          this.multiple_runs[
            this.conflict_range["actualObject"].index
          ].end_depth = 0;
        }
      }
      this.choosedFileIndex = null;
      await this.conflict_check();
    },
    async selected_runs(lwdIds, from, for_default_selection, fromSidebar) {
      if (from == "trend") {
        this.conflict_check_from_trend = true;
      } else {
        this.conflict_check_from_trend = false;
      }
      this.newly_saved_or_updated_file_payload = {};
      try {
        this.multiple_runs = lwdIds;
        if(for_default_selection && for_default_selection != 'updating'){
          this.selectedSavedRun = {};
        }
        else if(!for_default_selection){
          this.selectedSavedRun = {};
        }
        if(fromSidebar && fromSidebar == 'fromSidebar'){
          await this.$refs.trend.rerender();
        }
        if (lwdIds != "") {
          if (lwdIds.length == 1) {
            await this.getDataPoints(lwdIds, from);
            if(for_default_selection && for_default_selection == 'updating'){
              await this.apply_rerender();
              return;
            }
            let payload = {
              lithologyRangeId: lwdIds[0].id,
              sourceType: "file",
              log: "individual",
            };
            await this.lithology_data(payload);
            let payloadToGetSavedList= {
              lwd_id: lwdIds[0].id,
              well_id: this.wellId,
              wellbore_name: this.wellboreId
            }
            await this.getSavedLwdRunsList(payloadToGetSavedList, for_default_selection, 'from_selected_runs')
            await this.apply_rerender();
          } else if (lwdIds.length > 1) {
            // this.lithology_icons_data = [];
            this.multiple_runs = JSON.parse(JSON.stringify(lwdIds));
            let hadEveryId = false;
            let checkConsolidatedList =
              await apiService.lwdServices.consolidatedList({
                wellId: this.wellId,
                wellBoreName: this.wellboreId,
              });
            this.toDeleteExistedConsolidatedData = [];
            if (checkConsolidatedList.status == 200) {
              checkConsolidatedList.data.map((each)=>{
                if(each.name == (this.type_of_consolidated == 'lwdlog_consolidated' ? 'lwdlog' : 'wirelinelog')){
                  this.toDeleteExistedConsolidatedData.push({
                    lwdConsolidatedRangeId: each.lwdConsolidatedRangeId,
                    startDepth: each.startDepth,
                    status: each.status
                  })
                }
              })
              hadEveryId = lwdIds.every((each) => {
                return checkConsolidatedList.data.some(
                  (recrd) => recrd.lwdConsolidatedRangeId == each.id && recrd.name == (this.type_of_consolidated == 'lwdlog_consolidated' ? 'lwdlog' : 'wirelinelog')
                );
              });
            }
            if (!hadEveryId) {
              await this.conflict_check();
            } else {
              let data = [];
              let consolidated_lithology_icons_payload = [];
              checkConsolidatedList.data.map((obj) => {
                if(obj.name == (this.type_of_consolidated == 'lwdlog_consolidated' ? 'lwdlog' : 'wirelinelog')){
                  data.push({
                    id: obj.lwdConsolidatedRangeId,
                    start_depth: obj.startDepth,
                    end_depth: obj.endDepth,
                    name: obj.name,
                  });
                  consolidated_lithology_icons_payload.push(obj);
                }
              });
              this.consolidatedListData = data;
              await this.getDataPoints(data, from);
              if(for_default_selection && for_default_selection == 'updating'){
                await this.apply_rerender();
                return;
              }
              await this.getConsolidateLithologyIcons(
                consolidated_lithology_icons_payload
              );
              let payloadToGetSavedList = this.setting_payload_to_get_consolidated_data()
              await this.getSavedLwdRunsList(payloadToGetSavedList, for_default_selection)
              await this.apply_rerender();
            }
          }
        } else {
          this.data_points = {};
          this.copy_of_data_points = {};
          this.lithology_icons_list = [];
          this.lithology_icons_data = [];
        }
      } catch (err) {
        console.error(err);
      }
    },
    async conflict_check() {
      this.conflict_range = {};
      this.show_conflict_popup = false;
      // this.multiple_runs.forEach(async (lwd_id, index)=>{
      if (this.multiple_runs.length) {
        this.multiple_runs.sort((a, b) => a.start_depth - b.start_depth);
      }
      let stop_looping = false;
      for (let i = 0; i < this.multiple_runs.length; i++) {
        let lwd_id = this.multiple_runs[i];
        // this.multiple_runs.find((each, subIndex)=>{
        for (
          let subIndex = 0;
          subIndex < this.multiple_runs.length;
          subIndex++
        ) {
          let each = this.multiple_runs[subIndex];
          if (i != subIndex && each.end_depth !== 0 && lwd_id.end_depth !== 0) {
            if (
              lwd_id.start_depth <= each.start_depth &&
              lwd_id.end_depth > each.start_depth &&
              lwd_id.end_depth <= each.end_depth
            ) {
              this.conflict_range["start"] = each.start_depth;
              this.conflict_range["end"] = lwd_id.end_depth;
              this.conflict_range["actualObject"] = { index: i, obj: lwd_id };
              this.conflict_range["conflictObject"] = {
                index: subIndex,
                obj: each,
              };
              this.conflict_range["sameValues"] = false;
              this.show_conflict_popup = true;
              stop_looping = true;
              break;
            } else if (
              lwd_id.start_depth <= each.start_depth &&
              lwd_id.end_depth > each.start_depth &&
              lwd_id.end_depth > each.end_depth
            ) {
              this.conflict_range["start"] = each.start_depth;
              this.conflict_range["end"] = each.end_depth;
              this.conflict_range["actualObject"] = { index: i, obj: lwd_id };
              this.conflict_range["conflictObject"] = {
                index: subIndex,
                obj: each,
              };
              this.conflict_range["sameValues"] = false;
              this.show_conflict_popup = true;
              stop_looping = true;
              break;
            } else if (
              lwd_id.start_depth == each.start_depth &&
              lwd_id.end_depth == each.end_depth &&
              lwd_id.start_depth != 0 &&
              lwd_id.end_depth != 0
            ) {
              this.conflict_range["start"] = each.start_depth;
              this.conflict_range["end"] = lwd_id.end_depth;
              this.conflict_range["actualObject"] = { index: i, obj: lwd_id };
              this.conflict_range["conflictObject"] = {
                index: subIndex,
                obj: each,
              };
              this.conflict_range["sameValues"] = true;
              this.show_conflict_popup = true;
              stop_looping = true;
              break;
            }
          }
        }
        if (stop_looping) {
          break;
        }
      }
      if (!Object.keys(this.conflict_range).length) {
        this.show_conflict_popup = false;
        // let ids = []
        // this.multiple_runs.map((run) => {
        //   ids.push(run.id);
        // })
        // ids = [...new Set(ids)];

        try {
          let arr_to_save = this.multiple_runs.filter((obj, mainidx) => {
            let duplicated = this.multiple_runs.some(
              (each, subidx) =>
                mainidx !== subidx &&
                each.id == obj.id &&
                each.name == obj.name &&
                each.start_depth == obj.start_depth &&
                each.end_depth == obj.end_depth
            );
            if (!duplicated) {
              return obj;
            }
          });
          let list_payload = [];
          // arr_to_save.map(async (eachRun) => {
          //   await apiService.lwdServices.consolidated_data_save({
          //     lwdConsolidatedRangeId: eachRun.id,
          //     startDepth: eachRun.start_depth,
          //     endDepth: eachRun.end_depth,
          //     name: this.type_of_consolidated == 'lwdlog_consolidated' ? 'lwdlog' : 'wirelinelog',
          //     status: "A",
          //     createdBy: this.loggedInUser,
          //     createdAt: new Date(),
          //   });
          // });
          arr_to_save.map(async (eachRun) => {
            list_payload.push({
              lwdConsolidatedRangeId: eachRun.id,
              startDepth: eachRun.start_depth,
              endDepth: eachRun.end_depth,
              name: this.type_of_consolidated == 'lwdlog_consolidated' ? 'lwdlog' : 'wirelinelog',
              status: "A",
              createdBy: this.loggedInUser,
              createdAt: new Date(),
            });
          });
          if(this.toDeleteExistedConsolidatedData.length){
            let deleted = await apiService.lwdServices.consolidated_data_list_delete({data : {list: this.toDeleteExistedConsolidatedData}})
            if(deleted.status == 200){
              this.toDeleteExistedConsolidatedData = [];
            }
          }
          await apiService.lwdServices.consolidated_data_list_save({list :list_payload})
          await this.selected_runs(arr_to_save,'', 'selectDefault');
        } catch (err) {
          console.error(err);
        }
      }
    },

    toggleLeftBar() {
      if (this.wellId && this.wellboreId) {
        this.show_left_bar = !this.show_left_bar;
      } else {
        this.$toast.clear();
        this.$toast.info(`Please select the well`, {
          duration: "2000",
          position: "top",
          pauseOnHover: true,
        });
      }
    },
    hide_zoom_tooltip() {
      this.hide_tooltip_zoom = !this.hide_tooltip_zoom;
    },
    toggle_management() {
      if (this.wellId && this.wellboreId) {
        this.hide_management = !this.hide_management;
        if(this.hide_management){
          this.lwdPropsOpened = true
          this.$store.dispatch('data/setBooleanValue', this.lwdPropsOpened);
          console.log('____lwdPropsOpened____', this.lwdPropsOpened)
        }if(!this.hide_management){
          this.lwdPropsOpened = false
          this.$store.dispatch('data/setBooleanValue', this.lwdPropsOpened);
        }
      } else
        this.$toast.info("Please select existing well", {
          duration: "2000",
          position: "top",
          pauseOnHover: true,
        });
    },
    add_well_pop() {
      this.addWellPopHide = !this.addWellPopHide;
      if(this.addWellPopHide){
      this.lwdPropsOpened = true
        this.$store.dispatch('data/setBooleanValue', this.lwdPropsOpened);
      }
      if(!this.addWellPopHide){
      this.lwdPropsOpened = false
        this.$store.dispatch('data/setBooleanValue', this.lwdPropsOpened);
      }
    },
    addwellPopHide() {
      this.addWellPopHide = !this.addWellPopHide;
      // this.lwdPropsOpened = false
      // this.$store.dispatch('data/setBooleanValue', this.lwdPropsOpened);
    },
    async fetching_data(type) {
      try {
        type = type || this.selectedType;
        this.mnemonics_data = [];
        this.mnemonics_consolidated_data = [];
        this.data_points = {};
        this.copy_of_data_points = {};
        this.multiple_runs = [];
        this.show_conflict_popup = false;
        this.conflict_range = {};
        this.choosedFileIndex = null;
        this.lithology_icons_data = [];
        this.lithology_icons_list = [];
        this.savedLwdList = [];
        this.selectedSavedRun = {};
        let res = await apiService.lwdServices.get_mnemonics({
          wellId: this.wellId,
          wellBoreName: this.wellboreId,
          type: type,
        });
        if (res.status == 200 && res.data != "") {
          this.mnemonics_data = res.data.runs;
          this.mnemonics_consolidated_data = res.data.consolidated;
        } else if (res.status == 204 || res.data == "") {
          this.$toast.clear();
          this.$toast.error(`No Mnemonics available`, {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });
        } else {
          this.$toast.clear();
          this.$toast.error(`Something went wrong`, {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });
        }
        await this.apply_rerender();
      } catch (err) {
        this.$toast.clear();
        this.$toast.error(`Something went wrong`, {
          duration: "2000",
          position: "top",
          pauseOnHover: true,
        });
      }
    },
    printDiv(divName) {
      var mywindow = window.open("", "PRINT");
      var printContents = document.getElementById(divName).innerHTML;
      var originalContents = document.body.innerHTML;
      mywindow.document.write(
        "<html><head>" + document.head.innerHTML + "</title>"
      );
      mywindow.document.write("</head><body >");
      mywindow.document.write("<h1>" + document.title + "</h1>");
      mywindow.document.write(printContents);
      mywindow.document.write("</body></html>");

      setTimeout(() => {
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();
      }, 500);

      return true;
    },
  },
};
</script>
<style scopped src="../../../assets/styles/leftbar_styles.css"></style>
