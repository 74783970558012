<template>
  <div>
    <!-- data gap analysis popup html starts  -->
    <div class="datagappopup absolute">
      <div class="Child_Of_datagappopup">
        <div class="p-4 py-2 flex justify-between items-center border-b">
          <p class="fontWeight-600 text-xs uppercase">Data Gaps Analysis</p>
          <button
            @click="$emit('close')"
            class="text-sm font-normal uppercase text-red_"
            style="color: #cf634b"
          >
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div
          ref="datagap_well_selections"
          class="datagap_well_selections flex flex-wrap px-3 pt-2 w-full"
        >
          <select
            class="fontWeight-600"
            v-model="customerSelected"
            @change="customerchanged"
            :title="customerSelected"
          >
            <option value="" class="capitalize">Select Customer</option>
            <option
              v-for="(customerName, index) in customerList"
              :value="customerName"
              :key="index"
            >
              {{ customerName }}
            </option>
          </select>
          <select
            class="fontWeight-600"
            v-model="wellnameSelected"
            @change="wellnamechanged"
            :title="wellnameSelected"
          >
            <option value="" class="capitalize">Select Wellname</option>
            <option
              v-for="(wellname, index) in wellNames"
              :value="wellname"
              :key="index"
            >
              {{ wellname }}
            </option>
          </select>
          <select
            class="fontWeight-600"
            v-model="wellbore_nameSelected"
            @change="wellborename_Changed"
            :title="wellbore_nameSelected"
          >
            <option value="" class="capitalize">Select Wellbore Name</option>
            <option
              v-for="(wellborename, index) in wellbore_name_Options"
              :value="wellborename"
              :key="index"
            >
              {{ wellborename }}
            </option>
          </select>
          <select
            class="fontWeight-600"
            v-model="log_Selected"
            @change="log_Changed"
            :title="log_Selected"
          >
            <option value="" class="capitalize">Select Log</option>
            <option value="Time">Time</option>
            <option value="Depth">Depth</option>
            <option value="Trajectory">Trajectory</option>
          </select>

          <select
            class="fontWeight-600"
            v-model="longitude_latitude"
            @change="longitude_latitude_Changed"
            :title="longitude_latitude"
            v-if="wellnameSelected && wellbore_nameSelected && log_Selected"
          >
            <option value="" class="capitalize">
              Select Longitude-Latitude
            </option>
            <option
              v-for="(long_lat, index) in longitude_Latitude_Data"
              :key="index"
              :value="long_lat"
            >
              {{ long_lat }}
            </option>
          </select>

          <!-- gap selection -->
          <select
            class="fontWeight-600"
            v-model="dataType"
            @change="gap_Changed"
            :title="dataType"
            v-if="
              wellnameSelected &&
              wellbore_nameSelected &&
              log_Selected &&
              longitude_latitude
            "
          >
            <option value="" class="capitalize">Select Gap</option>
            <option v-if="log_Selected !== 'Trajectory'" value="Data_Gaps">
              Data_Gaps
            </option>
            <option value="Nulls">Nulls</option>
          </select>

          <!-- log time -->
          <div
            class="log_time flex"
            style="gap: 8px"
            v-if="
              wellnameSelected &&
              wellbore_nameSelected &&
              log_Selected == 'Time' &&
              longitude_latitude &&
              dataType
            "
          >
            <div class="flex items-center" style="gap: 2px">
              <label class="text-xs fontWeight-600">From Time</label>
              <Datepicker
                class="dataGapsPopup"
                v-model="fromTime"
                :minDate="new Date(start_timeOf_selectedWell)"
                :maxDate="new Date(end_timeOf_selectedWell)"
                :dark="darkDark != 'white'"
                textInput
                placeholder="MM/DD/YYYY H:M:S"
                :enableTimePicker="true"
                :timezone="this.time_zone? this.time_zone:null"
                @update:modelValue="onFromDateChange"
              />
            </div>
            <div class="flex items-center" style="gap: 2px">
              <label class="text-xs fontWeight-600">To Time</label>
              <Datepicker
                class="dataGapsPopup"
                v-model="toTime"
                :minDate="fromTime"
                :maxDate="max_To_Time"
                :dark="darkDark != 'white'"
                textInput
                placeholder="MM/DD/YYYY H:M:S"
                :enableTimePicker="true"
                @update:modelValue="onToDateChange"
                :timezone="this.time_zone? this.time_zone:null"
              />
            </div>
            <div class="flex" v-if="allowReset">
              <button
                class="bgClr text-white text-xs font-semibold uppercase px-2 py-0.5 rounded shadow hover:shadow-lg outline-none active:bg-blueGray-600 focus:outline-none lg:mb-0 ease-linear transition-all duration-150"
                @click="resetTime()"
              >
                <svg
                  class="svg-inline--fa fa-undo-alt fa-w-16"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="undo-alt"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M255.545 8c-66.269.119-126.438 26.233-170.86 68.685L48.971 40.971C33.851 25.851 8 36.559 8 57.941V192c0 13.255 10.745 24 24 24h134.059c21.382 0 32.09-25.851 16.971-40.971l-41.75-41.75c30.864-28.899 70.801-44.907 113.23-45.273 92.398-.798 170.283 73.977 169.484 169.442C423.236 348.009 349.816 424 256 424c-41.127 0-79.997-14.678-110.63-41.556-4.743-4.161-11.906-3.908-16.368.553L89.34 422.659c-4.872 4.872-4.631 12.815.482 17.433C133.798 479.813 192.074 504 256 504c136.966 0 247.999-111.033 248-247.998C504.001 119.193 392.354 7.755 255.545 8z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>

          <!-- log depth -->
          <div
            class="log_depth flex"
            style="gap: 8px"
            v-if="
              wellnameSelected &&
              wellbore_nameSelected &&
              (log_Selected == 'Depth' || log_Selected == 'Trajectory') &&
              longitude_latitude &&
              dataType
            "
          >
            <div class="flex items-center w-full" style="gap: 2px">
              <label class="text-xs fontWeight-600">From Depth</label>
              <input
                class="fontWeight-600"
                type="number"
                :min="start_depthOf_selectedWell"
                :max="end_depthOf_selectedWell"
                v-model="fromDepth"
                @keyup="keyup_fromDepth_validationFun"
                @change="fromDepth_Fun"
                style="margin-right: 10px"
              />
              <label class="text-xs fontWeight-600">To Depth</label>
              <input
                class="fontWeight-600"
                type="number"
                :min="start_depthOf_selectedWell"
                :max="end_depthOf_selectedWell"
                @keyup="keyup_toDepth_validationFun"
                @change="toDepth_Fun"
                v-model="toDepth"
              />
            </div>
          </div>

          <!-- source selection only for dataType==>Nulls log_Selected=>Time -->
          <select
            class="fontWeight-600"
            style="margin-top: 1.5px;"
            @change="sourcetypeChanged"
            v-model="sourcetype"
            :title="sourcetype"
            v-if="
              wellnameSelected &&
              wellbore_nameSelected &&
              log_Selected == 'Time' &&
              longitude_latitude &&
              dataType == 'Nulls'
            "
          >
            <option value="" class="capitalize">Select Source Type</option>
            <option value="Timepreferred">Timepreferred</option>
            <option value="Timemisce">Timemisce</option>
          </select>

          <select
            class="fontWeight-600"
            style="margin-top: 1.5px;"
            v-model="data_gap_status"
            :title="data_gap_status"
            v-if="
              wellnameSelected &&
              wellbore_nameSelected &&
              (log_Selected == 'Time' || log_Selected == 'Depth') &&
              longitude_latitude &&
              dataType == 'Data_Gaps'
            "
          >
            <option value="" class="capitalize">Select Status</option>
            <option value="A">Active</option>
            <option value="I">Inactive</option>
          </select>

          <!-- submit button for Data Gaps and Nulls with time-->
          <button
            v-if="
              wellnameSelected &&
              wellbore_nameSelected &&
              log_Selected == 'Time' &&
              longitude_latitude &&
              dataType &&
              fromTime &&
              toTime
            "
            class="bgClr text-white text-xs font-semibold uppercase px-4 py-0.5 rounded shadow hover:shadow-lg outline-none active:bg-blueGray-600 focus:outline-none lg:mb-0 ml-3 ease-linear transition-all duration-150"
            @click="getData()"
          >
            Submit
          </button>

          <!-- submit button for Data Gaps and Nulls with Depth-->
          <button
            v-if="
              wellnameSelected &&
              wellbore_nameSelected &&
              log_Selected == 'Depth' &&
              longitude_latitude &&
              dataType &&
              fromDepth !== '' &&
              toDepth !== ''
            "
            class="bgClr text-white text-xs font-semibold uppercase px-4 py-0.5 rounded shadow hover:shadow-lg outline-none active:bg-blueGray-600 focus:outline-none lg:mb-0 ml-3 ease-linear transition-all duration-150"
            @click="getData()"
          >
            Submit
          </button>

          <!-- submit button for Nulls with Trajectory-->
          <button
            v-if="
              wellnameSelected &&
              wellbore_nameSelected &&
              log_Selected == 'Trajectory' &&
              longitude_latitude &&
              dataType &&
              fromDepth !== '' &&
              toDepth !== ''
            "
            class="bgClr text-white text-xs font-semibold uppercase px-4 py-0.5 rounded shadow hover:shadow-lg outline-none active:bg-blueGray-600 focus:outline-none lg:mb-0 ml-3 ease-linear transition-all duration-150"
            @click="getData()"
          >
            Submit
          </button>

          <!-- button to print data -->
          <button
            v-if="showTableDiv"
            class="bgClr text-white text-xs font-semibold uppercase px-4 py-0.5 rounded shadow hover:shadow-lg outline-none active:bg-blueGray-600 focus:outline-none lg:mb-0 ml-3 ease-linear transition-all duration-150"
            @click="printData()"
          >
            Print
          </button>
        </div>
        <div
          v-if="showTableDiv"
          ref="toShowTable"
          @scroll="scrollFuntion"
          class="toShowTable p-3 pt-0 mt-5 overflow-auto fontWeight-600"
          :style="`height:calc(100% - ${
            this.$refs.datagap_well_selections.clientHeight + 60
          }px)`"
        >
          <!-- Nulls with Depth starts -->
          <div v-if="log_Selected == 'Depth' && dataType == 'Nulls'">
            <div class="filterByMnemonicDiv text-right pb-2 flex justify-end">
              <div
                class="asideSearch flex items-center bgLight"
                style="margin-bottom: 0px; border-radius: 3px"
                
              >
                <!-- search icon -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  class="asideSearchIcon"
                >
                  <path
                    d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z"
                    fill="#37B6FE"
                  ></path>
                </svg>
                <input
                  type="text"
                  placeholder="Mnemonic..."
                  v-model="filter_mnemonic"
                  class="asideSearchIp border-0 search text-xs shadow outline-none focus:outline-none pl-3"
                  style="padding-top: 0px; padding-bottom: 0px"
                  @input="filter_mnemonic_fun_Nulls_Depth"
                />
              </div>
            </div>

            <div ref="dataDiv" class="flex flex-col" style="gap: 20px">
              <div
                class="relative"
                v-for="(data, index) in nulls_Depth_Data"
                :key="index"
              >
                <div>
                  <p class="applyFont">{{ data.depth }}</p>
                </div>
                <div
                  class="mnemonics_div p-3 shadow-lg flex flex-wrap overflow-hidden"
                  :ref="`mnemonics_div_${index}`"
                  style="gap: 10px"
                >
                  <span
                    class="mnemonic_Record"
                    v-for="(arr, subIndex) in data.nodataMnemonicsData"
                    :key="subIndex"
                  >
                    {{ arr }}
                    <!-- <span class="separator">, </span> -->
                  </span>
                </div>
                <div :ref="`more_less_btns_${index}`">
                  <button
                    v-if="more_less_btns"
                    class="MoreBtn text-xs font-semibold uppercase px-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mb-0 ml-3 ease-linear transition-all duration-150"
                    :ref="`MoreBtn_${index}`"
                    @click="moreBtn_Fun(index)"
                  >
                    More
                  </button>
                  <button
                    v-if="more_less_btns"
                    class="LessBtn hidden text-xs font-semibold uppercase px-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mb-0 ml-3 ease-linear transition-all duration-150"
                    :ref="`LessBtn_${index}`"
                    @click="lessBtn_Fun(index)"
                  >
                    Less
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- Nulls with Depth ends -->

          <!-- Nulls with Time starts -->
          <div v-if="log_Selected == 'Time' && dataType == 'Nulls'">
            <div class="filterByMnemonicDiv text-right pb-2 flex justify-end">
              <div
                class="asideSearch flex items-center bgLight"
                style="margin-bottom: 0px; border-radius: 3px"
                
              >
                <!-- search icon -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  class="asideSearchIcon"
                >
                  <path
                    d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z"
                    fill="#37B6FE"
                  ></path>
                </svg>
                <input
                  type="text"
                  placeholder="Mnemonic..."
                  v-model="filter_mnemonic"
                  class="asideSearchIp border-0 search text-xs shadow outline-none focus:outline-none pl-3"
                  style="padding-top: 0px; padding-bottom: 0px"
                  @input="filter_mnemonic_fun_Nulls_Time"
                />
              </div>
            </div>

            <div ref="dataDiv" class="flex flex-col" style="gap: 20px">
              <div
                class="relative"
                v-for="(data, index) in nulls_Time_Data"
                :key="index"
              >
                <div>
                  <p class="applyFont">
                    <span>{{ new Date(data.fromTime).toLocaleString() }}</span>
                    <span v-if="data.toTime"> - </span>
                    <span v-if="data.toTime">{{
                      new Date(data.toTime).toLocaleString()
                    }}</span>
                  </p>
                </div>
                <div
                  class="mnemonics_div p-3 shadow-lg flex flex-wrap overflow-hidden"
                  :ref="`mnemonics_div_${index}`"
                  style="gap: 10px"
                >
                  <span
                    class="mnemonic_Record"
                    v-for="(arr, subIndex) in data.nodataMnemonicsData"
                    :key="subIndex"
                  >
                    {{ arr }}
                    <!-- <span class="separator">, </span> -->
                  </span>
                </div>
                <div :ref="`more_less_btns_${index}`">
                  <button
                    v-if="more_less_btns"
                    class="MoreBtn text-xs font-semibold uppercase px-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mb-0 ml-3 ease-linear transition-all duration-150"
                    :ref="`MoreBtn_${index}`"
                    @click="moreBtn_Fun(index)"
                  >
                    More
                  </button>
                  <button
                    v-if="more_less_btns"
                    class="LessBtn hidden text-xs font-semibold uppercase px-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mb-0 ml-3 ease-linear transition-all duration-150"
                    :ref="`LessBtn_${index}`"
                    @click="lessBtn_Fun(index)"
                  >
                    Less
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- Nulls with Time ends -->

          <!-- DataGaps with Depth starts -->
          <div v-if="log_Selected == 'Depth' && dataType == 'Data_Gaps'">
            <!-- <div class="filterByMnemonicDiv text-right pb-2 flex justify-end">
                            <div class="bgLight asideSearch  flex items-center" style="margin-bottom: 0px; border-radius: 3px;">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"
                               class="asideSearchIcon">
                                <path
                                    d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z"
                                    fill="#37B6FE"></path>
                                </svg>
                                <input type="number" placeholder="Depth..." v-model="datagap_filter_mnemonic"
                                class="asideSearchIp border-0 search text-xs shadow outline-none focus:outline-none pl-3" 
                                style="padding-top: 0px;padding-bottom: 4px;"
                                @input="datagap_Depth_filter_mnemonic_fun"/>
                            </div>
                        </div> -->

            <!-- <div ref="dataDiv" style="gap: 10px; display: grid; grid-template-columns: repeat(4,1fr);"> -->
            <div ref="dataDiv" class="flex flex-wrap" style="gap: 10px">
              <div
                class="relative overflow-hidden shadow"
                v-for="(data, index) in dataGaps_Depth_Data"
                :key="index"
                style="background: var(--sidebarListBgColor)"
              >
                <div
                  class="mnemonics_div p-2 overflow-hidden"
                  :ref="`mnemonics_div_${index}`"
                >
                  <div>
                    <div class="flex items-center w-full">
                      <p class="text-xs">Start Depth:</p>
                      <p
                        style="margin-left: 12px;"
                        class="applyFont overflow-ellipsis overflow-hidden whitespace-nowrap"
                        :title="data.startDepth"
                      >
                        {{ data.startDepth }}
                      </p>
                    </div>
                  </div>
                  <div>
                    <div
                      class="flex items-center overflow-ellipsis overflow-hidden whitespace-nowrap w-full"
                    >
                      <p class="text-xs">End Depth:</p>
                      <p
                        style="margin-left: 18px;"
                        class="applyFont overflow-ellipsis overflow-hidden whitespace-nowrap"
                        :title="data.endDepth"
                      >
                        {{ data.endDepth }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- DataGaps with Depth ends -->

          <!-- DataGaps with Time starts -->
          <div v-if="log_Selected == 'Time' && dataType == 'Data_Gaps'">
            <!-- <div class="filterByMnemonicDiv text-right pb-2 flex justify-end">
                            <div class="asideSearch bgLight flex items-center" style="margin-bottom: 0px; border-radius: 3px;" >
                               
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"
                                class="asideSearchIcon">
                                <path
                                    d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z"
                                    fill="#37B6FE"></path>
                                </svg>
                                <input type="text" placeholder="Mnemonic..." v-model="datagap_filter_mnemonic"
                                class="asideSearchIp border-0 search text-xs shadow outline-none focus:outline-none pl-3" 
                                style="padding-top: 0px;padding-bottom: 4px;"
                                @input=""/>
                            </div>
                        </div> -->

            <!-- <div ref="dataDiv" style="gap: 10px; display: grid; grid-template-columns: repeat(4,1fr);"> -->
            <div ref="dataDiv" class="flex flex-wrap" style="gap: 10px">
              <div
                class="relative overflow-hidden"
                v-for="(data, index) in dataGaps_Time_Data"
                :key="index"
                style="background: var(--sidebarListBgColor)"
              >
                <div
                  class="mnemonics_div p-2 shadow-lg overflow-hidden"
                  :ref="`mnemonics_div_${index}`"
                >
                  <div>
                    <div class="flex items-center w-full">
                      <p class="text-xs">Start Time:</p>
                      <p
                        style="margin-left: 12px;"
                        class="applyFont overflow-ellipsis overflow-hidden whitespace-nowrap"
                        :title="data.startTime"
                      >
                        {{ new Date(data.startTime).toLocaleString() }}
                      </p>
                    </div>
                  </div>
                  <div class="pt-1">
                    <div
                      class="flex items-center overflow-ellipsis overflow-hidden whitespace-nowrap w-full"
                    >
                      <p class="text-xs">End Time:</p>
                      <p
                        style="margin-left: 18px;"
                        class="applyFont overflow-ellipsis overflow-hidden whitespace-nowrap"
                        :title="data.endTime"
                      >
                        {{ new Date(data.endTime).toLocaleString() }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- DataGaps with Time ends -->

          <!-- Nulls with Trajectory starts -->
          <div v-if="log_Selected == 'Trajectory' && dataType == 'Nulls'">
            <!-- <div class="filterByMnemonicDiv text-right pb-2 flex justify-end">
                            <div class="asideSearch bgLight flex items-center" style="margin-bottom: 0px; border-radius: 3px;" >
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"
                                class="asideSearchIcon">
                                <path
                                    d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z"
                                    fill="#37B6FE"></path>
                                </svg>
                                <input type="number" placeholder="Depth..." v-model="datagap_filter_mnemonic"
                                class="asideSearchIp border-0 search text-xs shadow outline-none focus:outline-none pl-3" 
                                style="padding-top: 0px;padding-bottom: 4px;"
                                @input="datagap_Depth_filter_mnemonic_fun"/>
                            </div>
                        </div> -->
            <div ref="dataDiv" class="flex flex-col" style="gap: 20px">
              <div
                class="relative"
                v-for="(data, index) in data_of_trajectory"
                :key="index"
              >
                <div>
                  <p class="applyFont">{{ data.depth }}</p>
                </div>
                <div
                  class="mnemonics_div p-3 shadow-lg flex flex-wrap overflow-hidden"
                  :ref="`mnemonics_div_${index}`"
                  style="gap: 10px"
                >
                  <span
                    class="mnemonic_Record"
                    v-for="(arr, subIndex) in data.nodataMnemonicsData"
                    :key="subIndex"
                  >
                    {{ arr }}
                    <!-- <span class="separator">, </span> -->
                  </span>
                </div>
                <div :ref="`more_less_btns_${index}`">
                  <button
                    v-if="more_less_btns"
                    class="MoreBtn text-xs font-semibold uppercase px-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mb-0 ml-3 ease-linear transition-all duration-150"
                    :ref="`MoreBtn_${index}`"
                    @click="moreBtn_Fun(index)"
                  >
                    More
                  </button>
                  <button
                    v-if="more_less_btns"
                    class="LessBtn hidden text-xs font-semibold uppercase px-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mb-0 ml-3 ease-linear transition-all duration-150"
                    :ref="`LessBtn_${index}`"
                    @click="lessBtn_Fun(index)"
                  >
                    Less
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- Nulls with Trajectory ends -->
        </div>
        <div
          v-if="!showTableDiv && !loader"
          class="toShowTable flex items-center justify-center p-3 pt-0 mt-5 overflow-auto fontWeight-600"
          style="height: 60%"
        >
          <p class="applyFont" style="opacity: 0.7;">Data Not Available</p>
        </div>
        <div
          v-if="loader"
          class="loaderWaitText relative flex items-center justify-center w-full p-5"
          style="height: 60%"
        >
          <p class="applyFont mt-5 font-semibold">
            Please Wait Processing Data...
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import api from "../../api/services"
import axios from "axios";
import DataServiceUrl from "../../config";

export default {
  emits: ["close"],
  components: {
    Datepicker,
  },
  data() {
    return {
      customerList: [],
      customerSelected: "",
      wellnameSelected: "",
      wellbore_nameSelected: "",
      wellbore_name_Options: [],
      log_Selected: "",
      dataType: "",
      fromTime: "",
      toTime: "",
      fromDepth: "",
      toDepth: "",
      wellId: "",
      showList: [],
      nulls_Depth_Data: [],
      data_of_trajectory: [],
      filtered_Nulls_Depth: [],
      showTableDiv: false,
      more_less_btns: true,
      start_timeOf_selectedWell: null,
      end_timeOf_selectedWell: null,
      start_depthOf_selectedWell: null,
      end_depthOf_selectedWell: null,
      max_To_Time: null,
      longitude_latitude: "",
      longitude_Latitude_Data: [],
      subEntity: "",
      customerName: "",
      customerData: [],
      wellNames: [], // wellnames
      filter_mnemonic: "",
      sourcetype: "",
      dataGaps_Depth_Data: [],
      dataGaps_depth_List: [],
      datagap_filter_mnemonic: "",
      dataGaps_time_List: [],
      dataGaps_Time_Data: [],
      show_Time_List: [],
      nulls_Time_List_For_Filter: [],
      nulls_Time_Data: [],
      sourceLog_Data: [],
      allowReset: false,
      logid_time_interval: "",
      sequenceData: [],
      filtered_nulls_time: [],
      byDefault_to_show: 200,
      sequence_with_Nulls_time: [],
      loader: false,
      data_gap_status: '',
    };
  },
  mounted() {
    this.customerList = this.$serviceHelpers.getDetails("apollodart").customers;
  },
  methods: {
    async customerchanged() {
      this.wellnameSelected = "";
      this.wellbore_nameSelected = "";
      this.wellbore_name_Options = [];
      this.wellId = "";
      this.showTableDiv = false;
      this.log_Selected = "";
      this.dataType = "";
      this.sourcetype = "";
      this.fromDepth = "";
      this.toDepth = "";
      this.fromTime = "";
      this.toTime = "";
      this.longitude_latitude = "";
      this.filter_mnemonic = "";
      this.datagap_filter_mnemonic = "";

      if (this.customerSelected) {
        this.customerName = `${this.customerSelected}`;

        // getting wellinfo
        let customerData = await api.datagapsServices.wellinfo_search({customerName: this.customerName,})
        // let wellInfo_path =
        //   DataServiceUrl.HOST_URL.DATASERVICES_URL + "wellinfo/search";
        
        this.customerData = customerData.data;

        let allWellNames = [];
        for (var eachWell of this.customerData) {
          allWellNames.push(eachWell["wellName"]);
        }
        this.wellNames = [...new Set(allWellNames)];
      }
    },
    wellnamechanged() {
      this.wellbore_nameSelected = "";
      this.wellbore_name_Options = [];
      this.wellId = "";
      this.showTableDiv = false;
      this.log_Selected = "";
      this.dataType = "";
      this.sourcetype = "";
      this.fromDepth = "";
      this.toDepth = "";
      this.fromTime = "";
      this.toTime = "";
      this.longitude_latitude = "";
      this.filter_mnemonic = "";
      this.datagap_filter_mnemonic = "";
      if (this.wellnameSelected.length) {
        this.customerData.filter((eachWell) => {
          if (eachWell["wellName"] === this.wellnameSelected) {
            this.wellbore_name_Options.push(eachWell["wellBoreName"]);
          }
        });
      }
    },
    async wellborename_Changed() {
      this.showTableDiv = false;
      this.log_Selected = "";
      this.dataType = "";
      this.sourcetype = "";
      this.fromDepth = "";
      this.toDepth = "";
      this.fromTime = "";
      this.toTime = "";
      this.longitude_latitude = "";
      this.filter_mnemonic = "";
      this.datagap_filter_mnemonic = "";
      this.logid_time_interval = "";

      this.customerData.filter((eachWell) => {
        if (
          eachWell["wellName"] === this.wellnameSelected &&
          eachWell["wellBoreName"] === this.wellbore_nameSelected
        ) {
          this.wellId = eachWell["wellId"];
        }
      });
      // to check start,end depth and time for selected well
      let sourceLog_body = {
        // customerName: "Diamondback Energy Inc_Main",
        customerName: this.customerName,
        wellId: this.wellId,
        wellBoreName: this.wellbore_nameSelected,
      };
      if (this.customerName && this.wellId && this.wellbore_nameSelected) {
          let res = await api.datagapsServices.sourcelog_search(sourceLog_body)
          if(res.status == 200){
            this.sourceLog_Data = res.data;
          }
          else if(res.status == 204){
            this.$toast.error(`No Data Available To Show`, {
              duration: "2000",
              position: "top",
              pauseOnHover: true,
            });
          }
          else{
            this.$toast.error(
              `Something went wrong`,
              { duration: "2000", position: "top", pauseOnHover: true }
            );
          }
      }
    },
    log_Changed() {
      this.showTableDiv = false;
      this.dataType = "";
      this.sourcetype = "";
      this.fromDepth = "";
      this.toDepth = "";
      this.fromTime = "";
      this.toTime = "";
      this.filter_mnemonic = "";
      this.datagap_filter_mnemonic = "";
      this.longitude_latitude = "";
      if (this.sourceLog_Data) {
        this.longitude_Latitude_Data = [];
        for (let sourcelogData of this.sourceLog_Data) {
          if (
            sourcelogData["log"].toLowerCase() ==
              this.log_Selected.toLowerCase() &&
            this.log_Selected == "Time"
          ) {
            this.longitude_Latitude_Data.push(
              `${sourcelogData["longitude"]} / ${sourcelogData["latitude"]}`
            );

            //       this.start_timeOf_selectedWell = sourcelogData["startTime"]
            //       this.end_timeOf_selectedWell = sourcelogData["endTime"]

            //  // assigning values to the from and to Time
            //       this.fromTime = new Date(sourcelogData["startTime"])

            //       let fromDate_Data = new Date(this.fromTime)
            //       let copy_FromDate = new Date(fromDate_Data.toString().slice());
            //       // setting to time value to from time + 4 hrs
            //       this.max_To_Time = new Date(copy_FromDate.setHours(copy_FromDate.getHours() + 4))
            //       this.toTime = this.max_To_Time
          } else if (
            sourcelogData["log"].toLowerCase() ==
              this.log_Selected.toLowerCase() &&
            this.log_Selected == "Depth"
          ) {
            this.longitude_Latitude_Data.push(
              `${sourcelogData["longitude"]} / ${sourcelogData["latitude"]}`
            );

            // this.start_depthOf_selectedWell = sourcelogData["startDepth"]
            // this.end_depthOf_selectedWell = sourcelogData["endDepth"]

            // //assigning values to the from and to depth
            // this.fromDepth = sourcelogData["startDepth"]
            // this.toDepth = sourcelogData["endDepth"]
          } else if (
            sourcelogData["log"].toLowerCase() ==
              this.log_Selected.toLowerCase() &&
            this.log_Selected == "Trajectory"
          ) {
            this.longitude_Latitude_Data.push(
              `${sourcelogData["longitude"]} / ${sourcelogData["latitude"]}`
            );

            // this.start_depthOf_selectedWell = sourcelogData["startDepth"]
            // this.end_depthOf_selectedWell = sourcelogData["endDepth"]

            // //assigning values to the from and to depth
            // this.fromDepth = sourcelogData["startDepth"]
            // this.toDepth = sourcelogData["endDepth"]
          }
        }
      } else {
        this.$toast.clear();
        this.$toast.error(`No Data Available`, {
          duration: "2000",
          position: "top",
          pauseOnHover: true,
        });
      }
    },
    longitude_latitude_Changed() {
      this.showTableDiv = false;
      this.dataType = "";
      this.sourcetype = "";
      this.fromDepth = "";
      this.toDepth = "";
      this.fromTime = "";
      this.toTime = "";
      this.filter_mnemonic = "";
      this.datagap_filter_mnemonic = "";
      if (this.sourceLog_Data && this.longitude_latitude) {
        let long_lati_tudes = this.longitude_latitude.split("/");
        let selected_longitude = long_lati_tudes[0];
        let selected_latitude = long_lati_tudes[1];

        for (let sourcelogData of this.sourceLog_Data) {
          if (
            sourcelogData["log"].toLowerCase() ==
              this.log_Selected.toLowerCase() &&
            this.log_Selected == "Time" &&
            parseFloat(sourcelogData["longitude"]) ==
              parseFloat(selected_longitude) &&
            parseFloat(sourcelogData["latitude"]) ==
              parseFloat(selected_latitude)
          ) {
            this.start_timeOf_selectedWell = sourcelogData["startTime"];
            this.end_timeOf_selectedWell = sourcelogData["endTime"];

            // assigning values to the from and to Time

            if (this.start_timeOf_selectedWell) {
              // from time
              this.fromTime = new Date(sourcelogData["startTime"]);

              let fromDate_Data = new Date(this.fromTime);
              let copy_FromDate = new Date(fromDate_Data.toString().slice());
              // setting to time value to from time + 4 hrs
              this.max_To_Time = new Date(
                copy_FromDate.setHours(copy_FromDate.getHours() + 4)
              );

              //to time
              this.toTime = this.max_To_Time;
            } else {
              this.$toast.error(`From_Time Not Found`, {
                duration: "2000",
                position: "top",
                pauseOnHover: true,
              });
            }

            // checking to time
            if (!this.end_timeOf_selectedWell) {
              this.$toast.error(`To_Time Not Found`, {
                duration: "2000",
                position: "top",
                pauseOnHover: true,
              });
            }
          } else if (
            sourcelogData["log"].toLowerCase() ==
              this.log_Selected.toLowerCase() &&
            this.log_Selected == "Depth" &&
            parseFloat(sourcelogData["longitude"]) ==
              parseFloat(selected_longitude) &&
            parseFloat(sourcelogData["latitude"]) ==
              parseFloat(selected_latitude)
          ) {
            this.start_depthOf_selectedWell = sourcelogData["startDepth"];
            this.end_depthOf_selectedWell = sourcelogData["endDepth"];

            //assigning values to the from and to depth
            // from depth
            if (this.start_depthOf_selectedWell) {
              this.fromDepth = sourcelogData["startDepth"];
            } else {
              this.$toast.error(`From_Depth Not Found`, {
                duration: "2000",
                position: "top",
                pauseOnHover: true,
              });
            }

            //to depth
            if (this.end_depthOf_selectedWell) {
              this.toDepth = sourcelogData["endDepth"];
            } else {
              this.$toast.error(`To_Depth Not Found`, {
                duration: "2000",
                position: "top",
                pauseOnHover: true,
              });
            }
          } else if (
            sourcelogData["log"].toLowerCase() ==
              this.log_Selected.toLowerCase() &&
            this.log_Selected == "Trajectory" &&
            parseFloat(sourcelogData["longitude"]) ==
              parseFloat(selected_longitude) &&
            parseFloat(sourcelogData["latitude"]) ==
              parseFloat(selected_latitude)
          ) {
            this.start_depthOf_selectedWell = sourcelogData["startDepth"];
            this.end_depthOf_selectedWell = sourcelogData["endDepth"];

            //assigning values to the from and to depth
            // from depth
            if (this.start_depthOf_selectedWell) {
              this.fromDepth = sourcelogData["startDepth"];
            } else {
              this.$toast.error(`From_Depth Not Found`, {
                duration: "2000",
                position: "top",
                pauseOnHover: true,
              });
            }

            //to depth
            if (this.end_depthOf_selectedWell) {
              this.toDepth = sourcelogData["endDepth"];
            } else {
              this.$toast.error(`To_Depth Not Found`, {
                duration: "2000",
                position: "top",
                pauseOnHover: true,
              });
            }
          }
        }
      } else {
        this.$toast.error(`No Data Available`, {
          duration: "2000",
          position: "top",
          pauseOnHover: true,
        });
      }
    },
    gap_Changed() {
      this.showTableDiv = false;
      this.sourcetype = "";
      this.filter_mnemonic = "";
      this.datagap_filter_mnemonic = "";
      this.allowReset = false;

      //assigning to and from time
      if (this.sourceLog_Data && this.longitude_latitude) {
        let long_lati_tudes = this.longitude_latitude.split("/");
        let selected_longitude = long_lati_tudes[0];
        let selected_latitude = long_lati_tudes[1];
        for (let sourcelogData of this.sourceLog_Data) {
          if (
            sourcelogData["log"].toLowerCase() ==
              this.log_Selected.toLowerCase() && // if datagaps and ,Time is seleceted assigning toTime value to fromTime + 1 day
            this.log_Selected == "Time" &&
            this.dataType == "Data_Gaps" &&
            parseFloat(sourcelogData["longitude"]) ==
              parseFloat(selected_longitude) &&
            parseFloat(sourcelogData["latitude"]) ==
              parseFloat(selected_latitude)
          ) {
            this.start_timeOf_selectedWell = sourcelogData["startTime"];
            this.end_timeOf_selectedWell = sourcelogData["endTime"];
            // assigning values to the from and to Time
            if (this.start_timeOf_selectedWell) {
              // from time
              this.fromTime = new Date(sourcelogData["startTime"]);
              let fromDate_Data = new Date(this.fromTime);
              let copy_FromDate = new Date(fromDate_Data.toString().slice());
              // setting to time value to from time + 1 day
              this.max_To_Time = new Date(
                copy_FromDate.setDate(copy_FromDate.getDate() + 1)
              );
              if (
                new Date(this.max_To_Time) >
                new Date(this.end_timeOf_selectedWell)
              ) {
                this.max_To_Time = new Date(this.end_timeOf_selectedWell);
              }
              //to time
              this.toTime = this.max_To_Time;
            } else {
              this.$toast.error(`From_Time Not Found`, {
                duration: "2000",
                position: "top",
                pauseOnHover: true,
              });
            }
            // checking to time
            if (!this.end_timeOf_selectedWell) {
              this.$toast.error(`To_Time Not Found`, {
                duration: "2000",
                position: "top",
                pauseOnHover: true,
              });
            }
          } else if (
            sourcelogData["log"].toLowerCase() ==
              this.log_Selected.toLowerCase() &&
            this.log_Selected == "Time" &&
            parseFloat(sourcelogData["longitude"]) ==
              parseFloat(selected_longitude) &&
            parseFloat(sourcelogData["latitude"]) ==
              parseFloat(selected_latitude)
          ) {
            this.start_timeOf_selectedWell = sourcelogData["startTime"];
            this.end_timeOf_selectedWell = sourcelogData["endTime"];

            let logid = sourcelogData["logInterval"];

            // time interval between records
            if (logid) {
              let logid_split = logid.split("_");
              if (logid_split[logid_split.length - 1] == "sec") {
                this.logid_time_interval = logid_split[0];
              }
            }

            // assigning values to the from and to Time
            if (this.start_timeOf_selectedWell) {
              // from time
              this.fromTime = new Date(sourcelogData["startTime"]);
              let fromDate_Data = new Date(this.fromTime);
              let copy_FromDate = new Date(fromDate_Data.toString().slice());
              // setting to time value to from time + 4 hrs
              this.max_To_Time = new Date(
                copy_FromDate.setHours(copy_FromDate.getHours() + 4)
              );
              // if toTime is more than endTime then assigning endTime to toTime
              if (
                new Date(this.max_To_Time) >
                new Date(this.end_timeOf_selectedWell)
              ) {
                this.max_To_Time = new Date(this.end_timeOf_selectedWell);
              }
              //to time
              this.toTime = this.max_To_Time;
            } else {
              this.$toast.error(`From_Time Not Found`, {
                duration: "2000",
                position: "top",
                pauseOnHover: true,
              });
            }
            // checking to time
            if (!this.end_timeOf_selectedWell) {
              this.$toast.error(`To_Time Not Found`, {
                duration: "2000",
                position: "top",
                pauseOnHover: true,
              });
            }
          }
        }
      }
    },
    resetTime() {
      if (this.allowReset) {
        //re assigning to and from time
        this.showTableDiv = false;
        if (this.sourceLog_Data && this.longitude_latitude) {
          let long_lati_tudes = this.longitude_latitude.split("/");
          let selected_longitude = long_lati_tudes[0];
          let selected_latitude = long_lati_tudes[1];
          for (let sourcelogData of this.sourceLog_Data) {
            if (
              sourcelogData["log"].toLowerCase() ==
                this.log_Selected.toLowerCase() && // if datagaps and ,Time is seleceted assigning toTime value to fromTime + 1 day
              this.log_Selected == "Time" &&
              this.dataType == "Data_Gaps" &&
              parseFloat(sourcelogData["longitude"]) ==
                parseFloat(selected_longitude) &&
              parseFloat(sourcelogData["latitude"]) ==
                parseFloat(selected_latitude)
            ) {
              this.start_timeOf_selectedWell = sourcelogData["startTime"];
              this.end_timeOf_selectedWell = sourcelogData["endTime"];
              // assigning values to the from and to Time
              if (this.start_timeOf_selectedWell) {
                // from time
                this.fromTime = new Date(sourcelogData["startTime"]);
                let fromDate_Data = new Date(this.fromTime);
                let copy_FromDate = new Date(fromDate_Data.toString().slice());
                // setting to time value to from time + 1 day
                this.max_To_Time = new Date(
                  copy_FromDate.setDate(copy_FromDate.getDate() + 1)
                );
                // if toTime is more than endTime then assigning endTime to toTime
                if (
                  new Date(this.max_To_Time) >
                  new Date(this.end_timeOf_selectedWell)
                ) {
                  this.max_To_Time = new Date(this.end_timeOf_selectedWell);
                }
                //to time
                this.toTime = this.max_To_Time;
              } else {
                this.$toast.error(`From_Time Not Found`, {
                  duration: "2000",
                  position: "top",
                  pauseOnHover: true,
                });
              }
              // checking to time
              if (!this.end_timeOf_selectedWell) {
                this.$toast.error(`To_Time Not Found`, {
                  duration: "2000",
                  position: "top",
                  pauseOnHover: true,
                });
              }
            } else if (
              sourcelogData["log"].toLowerCase() ==
                this.log_Selected.toLowerCase() &&
              this.log_Selected == "Time" &&
              parseFloat(sourcelogData["longitude"]) ==
                parseFloat(selected_longitude) &&
              parseFloat(sourcelogData["latitude"]) ==
                parseFloat(selected_latitude)
            ) {
              this.start_timeOf_selectedWell = sourcelogData["startTime"];
              this.end_timeOf_selectedWell = sourcelogData["endTime"];
              // assigning values to the from and to Time
              if (this.start_timeOf_selectedWell) {
                // from time
                this.fromTime = new Date(sourcelogData["startTime"]);
                let fromDate_Data = new Date(this.fromTime);
                let copy_FromDate = new Date(fromDate_Data.toString().slice());
                // setting to time value to from time + 4 hrs
                this.max_To_Time = new Date(
                  copy_FromDate.setHours(copy_FromDate.getHours() + 4)
                );
                // if toTime is more than endTime then assigning endTime to toTime
                if (
                  new Date(this.max_To_Time) >
                  new Date(this.end_timeOf_selectedWell)
                ) {
                  this.max_To_Time = new Date(this.end_timeOf_selectedWell);
                }
                //to time
                this.toTime = this.max_To_Time;
              } else {
                this.$toast.error(`From_Time Not Found`, {
                  duration: "2000",
                  position: "top",
                  pauseOnHover: true,
                });
              }
              // checking to time
              if (!this.end_timeOf_selectedWell) {
                this.$toast.error(`To_Time Not Found`, {
                  duration: "2000",
                  position: "top",
                  pauseOnHover: true,
                });
              }
            }
          }
          this.allowReset = false;
        }
      }
    },
    sourcetypeChanged() {
      this.showTableDiv = false;
      this.filter_mnemonic = "";
      this.datagap_filter_mnemonic = "";
    },
    onFromDateChange(e) {
      this.filter_mnemonic = "";
      this.datagap_filter_mnemonic = "";
      this.showTableDiv = false;
      this.allowReset = true;
      if (e) {
        this.fromTime = e;
      }

      if (this.fromTime) {
        if (this.dataType == "Data_Gaps" && this.log_Selected == "Time") {
          // if datagaps and time is selected, setting to time value to from_time + 1 day
          let fromDate_Data = new Date(this.fromTime);
          let copy_FromDate = new Date(fromDate_Data.toString().slice());
          this.max_To_Time = new Date(
            copy_FromDate.setDate(copy_FromDate.getDate() + 1)
          );
          if (
            new Date(this.max_To_Time) > new Date(this.end_timeOf_selectedWell)
          ) {
            this.max_To_Time = new Date(this.end_timeOf_selectedWell);
          }
          this.toTime = this.max_To_Time;
        } else {
          // setting to time value to from_time + 4_hrs
          let fromDate_Data = new Date(this.fromTime);
          let copy_FromDate = new Date(fromDate_Data.toString().slice());
          this.max_To_Time = new Date(
            copy_FromDate.setHours(copy_FromDate.getHours() + 4)
          );
          if (
            new Date(this.max_To_Time) > new Date(this.end_timeOf_selectedWell)
          ) {
            this.max_To_Time = new Date(this.end_timeOf_selectedWell);
          }
          this.toTime = this.max_To_Time;
        }
      } else {
        this.$toast.error(`From_Time Not Found`, {
          duration: "2000",
          position: "top",
          pauseOnHover: true,
        });
      }
    },
    onToDateChange(e) {
      this.filter_mnemonic = "";
      this.datagap_filter_mnemonic = "";
      this.showTableDiv = false;
      this.allowReset = true;
      if (e) {
        this.toTime = e;
      }
    },
    fromDepth_Fun() {
      this.filter_mnemonic = "";
      this.datagap_filter_mnemonic = "";
      this.showTableDiv = false;
    },
    toDepth_Fun() {
      this.filter_mnemonic = "";
      this.datagap_filter_mnemonic = "";
      this.showTableDiv = false;
    },
    keyup_fromDepth_validationFun() {
      if (
        this.fromDepth &&
        this.start_depthOf_selectedWell &&
        this.end_depthOf_selectedWell
      ) {
        if (
          parseFloat(this.start_depthOf_selectedWell) >
            parseFloat(this.fromDepth) ||
          parseFloat(this.end_depthOf_selectedWell) < parseFloat(this.fromDepth)
        ) {
          this.fromDepth = parseFloat(this.start_depthOf_selectedWell);
          this.$toast.clear();
          this.$toast.error(
            `Please Enter From_Depth Between ${this.start_depthOf_selectedWell} to ${this.end_depthOf_selectedWell}`,
            { duration: "2000", position: "top", pauseOnHover: true }
          );
        }
      }
    },
    keyup_toDepth_validationFun() {
      if (
        this.toDepth &&
        this.start_depthOf_selectedWell &&
        this.end_depthOf_selectedWell
      ) {
        if (
          parseFloat(this.start_depthOf_selectedWell) >
            parseFloat(this.toDepth) ||
          parseFloat(this.end_depthOf_selectedWell) < parseFloat(this.toDepth)
        ) {
          this.toDepth = parseFloat(this.end_depthOf_selectedWell);
          this.$toast.clear();
          this.$toast.error(
            `Please Enter To_Depth Between ${this.start_depthOf_selectedWell} to ${this.end_depthOf_selectedWell}`,
            { duration: "2000", position: "top", pauseOnHover: true }
          );
        }
      }
    },
    async getData() {
      this.filter_mnemonic = "";
      this.datagap_filter_mnemonic = "";
      //Data of Nulls
      if (this.log_Selected == "Depth" && this.dataType == "Nulls") {
        this.nulls_Depth_Data = [];
        this.showTableDiv = false;
        this.loader = true;
        let req = {
          wellId: this.wellId,
          wellBoreName: this.wellbore_nameSelected,
          depth: this.fromDepth,
          toDepth: this.toDepth,
          fromDepthOperator: "GTE",
          toDepthOperator: "LT",
          nodataMnemonics: true,
        };
        let res = await api.datagapsServices.depth_nulls(req)
        if(res.status == 200){
          this.showList = res.data;
          // this.nulls_Depth_Data = res.data;

          this.showList = this.showList.filter((eachObj) => {
            if (
              eachObj.nodataMnemonicsData !== "" &&
              eachObj.nodataMnemonicsData !== null
            ) {
              return eachObj;
            }
          });
          // this.showList = [...this.nulls_Depth_Data]

          if (this.showList.length > this.byDefault_to_show) {
            this.nulls_Depth_Data = this.showList.slice(
              0,
              this.byDefault_to_show
            );
          } else {
            this.nulls_Depth_Data = [...this.showList];
          }

          if (this.nulls_Depth_Data != "") {
            this.showTableDiv = true;
            this.loader = false;
          } else {
            this.loader = false;
            this.$toast.error(`No Data Available To Show`, {
              duration: "2000",
              position: "top",
              pauseOnHover: true,
            });
            this.showTableDiv = false;
          }
        }
        else if(res.status == 204){
          this.loader = false;
          this.$toast.error(`No Data Available To Show`, {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });
          this.showTableDiv = false;
        }
        else{
          this.loader = false;
          this.$toast.error(`Something went wrong`, {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });
        }
      } else if (this.log_Selected == "Time" && this.dataType == "Nulls") {
        if (this.sourcetype != "") {
          let path = "";
          let req = "";
          this.nulls_Time_Data = [];
          this.showTableDiv = false;
          this.loader = true;
          if (this.sourcetype.toLowerCase() == "timepreferred") {
            req = {
              wellId: this.wellId,
              wellBoreName: this.wellbore_nameSelected,
              time: Date.parse(this.fromTime),
              toTime: Date.parse(this.toTime),
              fromTimeOperator: "GTE",
              toTimeOperator: "LT",
              nodataMnemonics: true,
            };

            let res = await api.datagapsServices.time_nulls_preferred(req)
            if(res.status == 200){
              this.show_Time_List = res.data;
              this.nulls_Time_List_For_Filter = res.data;
              // this.nulls_Time_Data = res.data;

              this.show_Time_List = this.show_Time_List.filter((eachObj) => {
                if (
                  eachObj.nodataMnemonicsData !== "" &&
                  eachObj.nodataMnemonicsData !== null
                ) {
                  return eachObj;
                }
              });
              this.nulls_Time_List_For_Filter =
                this.nulls_Time_List_For_Filter.filter((eachObj) => {
                  if (
                    eachObj.nodataMnemonicsData !== "" &&
                    eachObj.nodataMnemonicsData !== null
                  ) {
                    return eachObj;
                  }
                });
              // this.nulls_Time_List_For_Filter = [...this.show_Time_List]
              // this.show_Time_List = [...this.nulls_Time_Data]

              if (this.show_Time_List != "") {
                //separating sequence data code starts
                // if(this.logid_time_interval != ''){
                let checking_sequence_of_data_with_time = [
                  ...this.show_Time_List,
                ];

                checking_sequence_of_data_with_time.sort((a, b) => {
                  return new Date(a.time) - new Date(b.time);
                });

                this.sequenceData = [];
                checking_sequence_of_data_with_time.filter((obj) => {
                  const prevObj =
                    checking_sequence_of_data_with_time[
                      checking_sequence_of_data_with_time.indexOf(obj) - 1
                    ]; // get previous object in array
                  if (!prevObj) {
                    // first object
                    return this.sequenceData.push([obj]);
                  }
                  const diff = new Date(obj.time) - new Date(prevObj.time);
                  if (
                    diff == parseInt(this.logid_time_interval) * 1000 &&
                    JSON.stringify(obj.nodataMnemonicsData) ==
                      JSON.stringify(prevObj.nodataMnemonicsData)
                  ) {
                    // check if difference is 1 second and Mnemonics data is same or not
                    this.sequenceData[this.sequenceData.length - 1].push(obj);
                  } else {
                    this.sequenceData.push([obj]);
                  }
                });

                // merging sequenced data as fromTime toTime
                this.nulls_Time_Data = [];
                let arrayOfObjs = [];
                this.sequenceData.map((eachArray) => {
                  let toTime = null;
                  if (eachArray.length > 1) {
                    toTime = new Date(eachArray[eachArray.length - 1].time);
                  }
                  let obj = {
                    fromTime: new Date(eachArray[0].time),
                    toTime: toTime,
                    nodataMnemonicsData: eachArray[0].nodataMnemonicsData,
                  };
                  arrayOfObjs.push(obj);
                });
                this.show_Time_List = [...arrayOfObjs];

                if (this.show_Time_List.length > this.byDefault_to_show) {
                  this.nulls_Time_Data = this.show_Time_List.slice(
                    0,
                    this.byDefault_to_show
                  );
                } else {
                  this.nulls_Time_Data = [...this.show_Time_List];
                }

                this.showTableDiv = true;
                this.loader = false;
                // }
                //separating sequence data code ends
              } else {
                this.loader = false;
                this.$toast.error(`No Data Available To Show`, {
                  duration: "2000",
                  position: "top",
                  pauseOnHover: true,
                });
                this.showTableDiv = false;
              }
            }
            else if(res.status == 204){
              this.loader = false;
              this.showTableDiv = false;
              this.$toast.error(`No Data Available To Show`, {
                duration: "2000",
                position: "top",
                pauseOnHover: true,
              });
            }
            else{
              this.loader = false;
              this.$toast.error(`Something went wrong`, {
                duration: "2000",
                position: "top",
                pauseOnHover: true,
              });
            }
          } else if (this.sourcetype.toLowerCase() == "timemisce") {
            req = {
              wellId: this.wellId,
              wellBoreName: this.wellbore_nameSelected,
              time: Date.parse(this.fromTime),
              toTime: Date.parse(this.toTime),
              fromTimeOperator: "GTE",
              toTimeOperator: "LT",
              nodataMnemonics: true,
            };
            let res = await api.datagapsServices.time_nulls_misce(req)
            if(res.status == 200){
              this.show_Time_List = res.data;
              this.nulls_Time_List_For_Filter = res.data;
              // this.nulls_Time_Data = res.data;

              this.show_Time_List = this.show_Time_List.filter((eachObj) => {
                if (
                  eachObj.nodataMnemonicsData !== "" &&
                  eachObj.nodataMnemonicsData !== null
                ) {
                  return eachObj;
                }
              });
              this.nulls_Time_List_For_Filter =
                this.nulls_Time_List_For_Filter.filter((eachObj) => {
                  if (
                    eachObj.nodataMnemonicsData !== "" &&
                    eachObj.nodataMnemonicsData !== null
                  ) {
                    return eachObj;
                  }
                });
              // this.nulls_Time_List_For_Filter = [...this.show_Time_List]
              // this.show_Time_List = [...this.nulls_Time_Data]

              if (this.show_Time_List != "") {
                //separating sequence data code starts
                // if(this.logid_time_interval != ''){
                let checking_sequence_of_data_with_time = [
                  ...this.show_Time_List,
                ];

                checking_sequence_of_data_with_time.sort((a, b) => {
                  return new Date(a.time) - new Date(b.time);
                });

                this.sequenceData = [];
                checking_sequence_of_data_with_time.filter((obj) => {
                  const prevObj =
                    checking_sequence_of_data_with_time[
                      checking_sequence_of_data_with_time.indexOf(obj) - 1
                    ]; // get previous object in array
                  if (!prevObj) {
                    // first object
                    return this.sequenceData.push([obj]);
                  }
                  const diff = new Date(obj.time) - new Date(prevObj.time);
                  if (
                    diff == parseInt(this.logid_time_interval) * 1000 &&
                    JSON.stringify(obj.nodataMnemonicsData) ==
                      JSON.stringify(prevObj.nodataMnemonicsData)
                  ) {
                    // check if difference is 1 second and Mnemonics data is same or not
                    this.sequenceData[this.sequenceData.length - 1].push(obj);
                  } else {
                    this.sequenceData.push([obj]);
                  }
                });

                // merging sequenced data as fromTime toTime
                this.nulls_Time_Data = [];
                let arrayOfObjs = [];
                this.sequenceData.map((eachArray) => {
                  let toTime = null;
                  if (eachArray.length > 1) {
                    toTime = new Date(eachArray[eachArray.length - 1].time);
                  }
                  let obj = {
                    fromTime: new Date(eachArray[0].time),
                    toTime: toTime,
                    nodataMnemonicsData: eachArray[0].nodataMnemonicsData,
                  };
                  arrayOfObjs.push(obj);
                });
                this.show_Time_List = [...arrayOfObjs];

                if (this.show_Time_List.length > this.byDefault_to_show) {
                  this.nulls_Time_Data = this.show_Time_List.slice(
                    0,
                    this.byDefault_to_show
                  );
                } else {
                  this.nulls_Time_Data = [...this.show_Time_List];
                }

                this.showTableDiv = true;
                this.loader = false;
                // }
                //separating sequence data code ends
              } else {
                this.loader = false;
                this.$toast.error(`No Data Available To Show`, {
                  duration: "2000",
                  position: "top",
                  pauseOnHover: true,
                });
                this.showTableDiv = false;
              }
            }
            else if(res.status == 204){
              this.loader = false;
              this.showTableDiv = false;
              this.$toast.error(`No Data Available To Show`, {
                duration: "2000",
                position: "top",
                pauseOnHover: true,
              });
            }
            else{
              this.loader = false;
              this.$toast.error(`Something went wrong`, {
                duration: "2000",
                position: "top",
                pauseOnHover: true,
              });
            }
          }
        } else {
          this.$toast.error(`Please Select Source Type`, {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });
        }
      }

      //Data of DataGaps
      if (this.log_Selected == "Depth" && this.dataType == "Data_Gaps") {
        if(this.data_gap_status == ''){
          this.$toast.clear();
          this.$toast.error(`Please select the status`, {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });
        }
        else{
          this.dataGaps_Depth_Data = [];
          this.showTableDiv = false;
          this.loader = true;
          let req = {
            wellId: this.wellId,
            wellBoreName: this.wellbore_nameSelected,
            startDepth: this.fromDepth,
            endDepth: this.toDepth,
            startDepthOperator: "GTE",
            endDepthOperator: "LT",
            status: this.data_gap_status,
          };

          let res = await api.datagapsServices.depth_datagaps(req)
          if(res.status == 200){
            this.dataGaps_depth_List = res.data;
            this.dataGaps_Depth_Data = res.data;

            this.dataGaps_Depth_Data = this.dataGaps_Depth_Data.filter(
              (eachObj) => {
                if (
                  (eachObj.startDepth != "" || eachObj.endDepth != "")
                ) {
                  return eachObj;
                }
              }
            );
            this.dataGaps_depth_List = [...this.dataGaps_Depth_Data];

            if (this.dataGaps_Depth_Data != "") {
              this.showTableDiv = true;
              this.loader = false;
            } else {
              this.loader = false;
              this.$toast.error(`No Data Available To Show`, {
                duration: "2000",
                position: "top",
                pauseOnHover: true,
              });
              this.showTableDiv = false;
            }
          }
          else if(res.status == 204){
            this.loader = false;
            this.showTableDiv = false;
            this.$toast.error(`No Data Available To Show`, {
              duration: "2000",
              position: "top",
              pauseOnHover: true,
            });
          }
          else{
            this.loader = false;
            this.$toast.error(
              `Something went wrong`,
              { duration: "2000", position: "top", pauseOnHover: true }
            );
          }
        }
      } else if (this.log_Selected == "Time" && this.dataType == "Data_Gaps") {
        if(this.data_gap_status == ''){
          this.$toast.clear();
          this.$toast.error(`Please select the status`, {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });
        }
        else{
          this.dataGaps_Time_Data = [];
          this.showTableDiv = false;
          this.loader = true;
          let req = {
            wellId: this.wellId,
            wellBoreName: this.wellbore_nameSelected,
            startTime: Date.parse(this.fromTime),
            endTime: Date.parse(this.toTime),
            startTimeOperator: "GTE",
            endTimeOperator: "LT",
            status: this.data_gap_status,
          };

          let res = await api.datagapsServices.time_datagaps(req)
          if(res.status == 200){
            this.dataGaps_time_List = res.data;
            this.dataGaps_Time_Data = res.data;

            this.dataGaps_Time_Data = this.dataGaps_Time_Data.filter(
              (eachObj) => {
                if (
                  (eachObj.startTime != "" || eachObj.endTime != "")
                ) {
                  return eachObj;
                }
              }
            );
            this.dataGaps_time_List = [...this.dataGaps_Time_Data];

            if (this.dataGaps_Time_Data != "") {
              this.showTableDiv = true;
              this.loader = false;
            } else {
              this.loader = false;
              this.$toast.error(`No Data Available To Show`, {
                duration: "2000",
                position: "top",
                pauseOnHover: true,
              });
              this.showTableDiv = false;
            }
          }
          else if(res.status == 204){
            this.loader = false;
            this.showTableDiv = false;
            this.$toast.error(`No Data Available To Show`, {
              duration: "2000",
              position: "top",
              pauseOnHover: true,
            });
          }
          else{
            this.loader = false;
            this.$toast.error(
              `Something went wrong`,
              { duration: "2000", position: "top", pauseOnHover: true }
            );
          }
        }  
      }

      //Data of Nulls for Trajectory
      if (this.log_Selected == "Trajectory" && this.dataType == "Nulls") {
        this.data_of_trajectory = [];
        this.showTableDiv = false;
        this.loader = true;
        let req = {
          wellId: this.wellId,
          wellBoreName: this.wellbore_nameSelected,
          depth: this.fromDepth,
          toDepth: this.toDepth,
          fromDepthOperator: "GTE",
          toDepthOperator: "LT",
          nodataMnemonics: true,
        };
        let res = await api.datagapsServices.trajectory_nulls(req)
        if(res.status == 200){
          this.data_of_trajectory = res.data;
          this.data_of_trajectory = this.data_of_trajectory.filter(
            (eachObj) => {
              if (
                eachObj.nodataMnemonicsData !== "" &&
                eachObj.nodataMnemonicsData !== null
              ) {
                return eachObj;
              }
            }
          );
          if (this.data_of_trajectory != "") {
            this.showTableDiv = true;
            this.loader = false;
          } else {
            this.loader = false;
            this.showTableDiv = false;
            this.$toast.error(`No Data Available To Show`, {
              duration: "2000",
              position: "top",
              pauseOnHover: true,
            });
          }
        }
        else if(res.status == 204){
          this.loader = false;
          this.showTableDiv = false;
          this.$toast.error(`No Data Available To Show`, {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });
        }
        else{
          this.loader = false;
          this.$toast.error(`Something went wrong`, {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });
        }
      }
    },

    printData() {
      let well_name_selected = this.wellnameSelected.replace("/@/", "-");
      let from = "";
      let to = "";
      let long_lati_tudes = this.longitude_latitude.split("/");
      if (this.log_Selected == "Time") {
        from = `From Time: <b>${new Date(this.fromTime).toLocaleString()}</b>`;
        to = `To Time: <b>${new Date(this.toTime).toLocaleString()}</b>`;
      } else if (this.log_Selected == "Depth") {
        from = `From Depth: <b>${this.fromDepth}</b>`;
        to = `To Depth: <b>${this.toDepth}</b>`;
      } else if (this.log_Selected == "Trajectory") {
        from = `From Depth: <b>${this.fromDepth}</b>`;
        to = `To Depth: <b>${this.toDepth}</b>`;
      }

      let selectionData = "";
      if (this.log_Selected == "Time" && this.dataType == "Nulls") {
        selectionData = `<div style="display: flex; margin-bottom: 15px; flex-wrap: wrap;">
                                        <div style="width: 50%">
                                            <p style="margin: 0px 0px 5px 0px;">Customer Name: <b>${this.customerName}</b></p>
                                            <p style="margin: 0px 0px 5px 0px;">Well Name: <b>${well_name_selected}</b></p>
                                            <p style="margin: 0px 0px 5px 0px;">WellBore Name: <b>${this.wellbore_nameSelected}</b></p>
                                            <p style="margin: 0px 0px 5px 0px;">Log: <b>${this.log_Selected}</b></p>
                                            <p style="margin: 0px 0px 5px 0px;">Longitude: <b>${long_lati_tudes[0]}</b></p>
                                        </div>
                                        <div style="width: 50%">
                                            <p style="margin: 0px 0px 5px 0px;">Latitude: <b>${long_lati_tudes[1]}</b></p>
                                            <p style="margin: 0px 0px 5px 0px;">Gap: <b>${this.dataType}</b></p>
                                            <p style="margin: 0px 0px 5px 0px;">Source Type: <b>${this.sourcetype}</b></p>
                                            <p style="margin: 0px 0px 5px 0px;">${from}</p>
                                            <p style="margin: 0px 0px 5px 0px;">${to}</p>
                                        </div>
                                        <div style="margin-top: 20px">
                                            <p style="margin: 0px 0px 5px 0px;">Filtered By: <b>${this.filter_mnemonic}</b></p>
                                        </div>  
                                    </div>`;
      } else if (this.log_Selected == "Depth" && this.dataType == "Nulls") {
        selectionData = `<div style="display: flex; margin-bottom: 15px; flex-wrap: wrap;">
                                        <div style="width: 50%">
                                            <p style="margin: 0px 0px 5px 0px;">Customer Name: <b>${this.customerName}</b></p>
                                            <p style="margin: 0px 0px 5px 0px;">Well Name: <b>${well_name_selected}</b></p>
                                            <p style="margin: 0px 0px 5px 0px;">WellBore Name: <b>${this.wellbore_nameSelected}</b></p>
                                            <p style="margin: 0px 0px 5px 0px;">Log: <b>${this.log_Selected}</b></p>
                                        </div>
                                        <div style="width: 50%">
                                            <p style="margin: 0px 0px 5px 0px;">Longitude: <b>${long_lati_tudes[0]}</b></p>
                                            <p style="margin: 0px 0px 5px 0px;">Latitude: <b>${long_lati_tudes[1]}</b></p>
                                            <p style="margin: 0px 0px 5px 0px;">Gap: <b>${this.dataType}</b></p>
                                            <p style="margin: 0px 0px 5px 0px;">${from}</p>
                                            <p style="margin: 0px 0px 5px 0px;">${to}</p>
                                        </div>
                                        <div style="margin-top: 20px">
                                            <p style="margin: 0px 0px 5px 0px;">Filtered By: <b>${this.filter_mnemonic}</b></p>
                                        </div>  
                                    </div>`;
      } else if (
        (this.log_Selected == "Time" && this.dataType == "Data_Gaps") ||
        (this.log_Selected == "Trajectory" && this.dataType == "Nulls")
      ) {
        selectionData = `<div style="display: flex; margin-bottom: 15px; flex-wrap: wrap;">
                                        <div style="width: 50%">
                                            <p style="margin: 0px 0px 5px 0px;">Customer Name: <b>${this.customerName}</b></p>
                                            <p style="margin: 0px 0px 5px 0px;">Well Name: <b>${well_name_selected}</b></p>
                                            <p style="margin: 0px 0px 5px 0px;">WellBore Name: <b>${this.wellbore_nameSelected}</b></p>
                                            <p style="margin: 0px 0px 5px 0px;">Log: <b>${this.log_Selected}</b></p>
                                        </div>
                                        <div style="width: 50%">
                                            <p style="margin: 0px 0px 5px 0px;">Longitude: <b>${long_lati_tudes[0]}</b></p>
                                            <p style="margin: 0px 0px 5px 0px;">Latitude: <b>${long_lati_tudes[1]}</b></p>
                                            <p style="margin: 0px 0px 5px 0px;">Gap: <b>${this.dataType}</b></p>
                                            <p style="margin: 0px 0px 5px 0px;">${from}</p>
                                            <p style="margin: 0px 0px 5px 0px;">${to}</p>
                                        </div> 
                                    </div>`;
      } else if (this.log_Selected == "Depth" && this.dataType == "Data_Gaps") {
        selectionData = `<div style="display: flex; margin-bottom: 15px; flex-wrap: wrap;">
                                        <div style="width: 50%">
                                            <p style="margin: 0px 0px 5px 0px;">Customer Name: <b>${this.customerName}</b></p>
                                            <p style="margin: 0px 0px 5px 0px;">Well Name: <b>${well_name_selected}</b></p>
                                            <p style="margin: 0px 0px 5px 0px;">WellBore Name: <b>${this.wellbore_nameSelected}</b></p>
                                            <p style="margin: 0px 0px 5px 0px;">Log: <b>${this.log_Selected}</b></p>
                                        </div>
                                        <div style="width: 50%">
                                            <p style="margin: 0px 0px 5px 0px;">Longitude: <b>${long_lati_tudes[0]}</b></p>
                                            <p style="margin: 0px 0px 5px 0px;">Latitude: <b>${long_lati_tudes[1]}</b></p>
                                            <p style="margin: 0px 0px 5px 0px;">Gap: <b>${this.dataType}</b></p>
                                            <p style="margin: 0px 0px 5px 0px;">${from}</p>
                                            <p style="margin: 0px 0px 5px 0px;">${to}</p>
                                        </div>  
                                    </div>`;

        // <div style="margin-top: 20px">
        //         <p style="margin: 0px 0px 5px 0px;">Filtered By: <b>${this.datagap_filter_mnemonic}</b></p>
        //     </div>
      }

      let wdWdth = window.innerWidth;
      let applyWidth = (window.innerWidth * 3) / 4;
      let remainingWidth = wdWdth - applyWidth;

      let wdht = window.innerHeight;
      let applyHeight = (window.innerWidth * 3) / 4;
      let remainingHeight = wdht - applyHeight;

      let leftPos = remainingWidth / 2;
      let topPos = remainingHeight / 2;

      var a = window.open(
        "",
        "",
        `height=${applyHeight}, width=${applyWidth}, left=${leftPos},top=${topPos}, title="Data Gap Analysis"`
      );
      a.document.write("<html>");
      a.document.write(
        '<body > <h2 style="text-align: center;text-decoration: underline;">Data Gap Analysis</h2> <br>'
      );
      a.document.write(selectionData);
      if (this.log_Selected == "Depth" && this.dataType == "Nulls") {
        if (this.filter_mnemonic.length) {
          // if filtered
          for (let each_Data in this.filtered_Nulls_Depth) {
            let creating_html = `<div style="margin-top: 20px;">
                                                <div style="margin-bottom: 5px; text-decoration: underline;">
                                                    <b>${
                                                      this.filtered_Nulls_Depth[
                                                        each_Data
                                                      ].depth
                                                    }</b>
                                                </div>
                                                <div style="display: grid;
                                                    grid-template-columns: repeat(3,1fr);
                                                    gap: 3px;">
                                                    ${this.filtered_Nulls_Depth[
                                                      each_Data
                                                    ].nodataMnemonicsData
                                                      .map((val) => {
                                                        return `<span
                                                                style="padding: 2px 8px;">
                                                                ${val}
                                                                </span>`;
                                                      })
                                                      .join("")}
                                                </div>
                                            </div>`;
            a.document.write(creating_html);
          }
        } else {
          // when not filtered
          for (let each_Data in this.showList) {
            let creating_html = `<div style="margin-top: 20px;">
                                                <div style="margin-bottom: 5px; text-decoration: underline;">
                                                    <b>${
                                                      this.showList[each_Data]
                                                        .depth
                                                    }</b>
                                                </div>
                                                <div style="display: grid;
                                                    grid-template-columns: repeat(3,1fr);
                                                    gap: 3px;">
                                                    ${this.showList[
                                                      each_Data
                                                    ].nodataMnemonicsData
                                                      .map((val) => {
                                                        return `<span
                                                                style="padding: 2px 8px;">
                                                                ${val}
                                                                </span>`;
                                                      })
                                                      .join("")}
                                                </div>
                                            </div>`;
            a.document.write(creating_html);
          }
        }
      } else if (this.log_Selected == "Time" && this.dataType == "Nulls") {
        if (this.filter_mnemonic.length) {
          // if filtered
          for (let each_Data in this.filtered_nulls_time) {
            let toTime = "";
            if (this.filtered_nulls_time[each_Data].toTime) {
              toTime = `- ${new Date(
                this.filtered_nulls_time[each_Data].toTime
              ).toLocaleString()}`;
            }
            let creating_html = `<div style="margin-top: 20px;">
                                                <div style="margin-bottom: 5px; text-decoration: underline;">
                                                    <b>${new Date(
                                                      this.filtered_nulls_time[
                                                        each_Data
                                                      ].fromTime
                                                    ).toLocaleString()} ${toTime}</b>
                                                </div>
                                                <div style="display: grid;
                                                    grid-template-columns: repeat(3,1fr);
                                                    gap: 3px;">
                                                    ${this.filtered_nulls_time[
                                                      each_Data
                                                    ].nodataMnemonicsData
                                                      .map((val) => {
                                                        return `<span
                                                                    style="padding: 2px 8px;">
                                                                    ${val}
                                                                    </span>`;
                                                      })
                                                      .join("")}
                                                </div>
                                            </div>`;
            a.document.write(creating_html);
          }
        } else {
          // when not filtered
          for (let each_Data in this.show_Time_List) {
            let toTime = "";
            if (this.show_Time_List[each_Data].toTime) {
              toTime = `- ${new Date(
                this.show_Time_List[each_Data].toTime
              ).toLocaleString()}`;
            }
            let creating_html = `<div style="margin-top: 20px;">
                                                <div style="margin-bottom: 5px; text-decoration: underline;">
                                                    <b>${new Date(
                                                      this.show_Time_List[
                                                        each_Data
                                                      ].fromTime
                                                    ).toLocaleString()} ${toTime}</b>
                                                </div>
                                                <div style="display: grid;
                                                    grid-template-columns: repeat(3,1fr);
                                                    gap: 3px;">
                                                    ${this.show_Time_List[
                                                      each_Data
                                                    ].nodataMnemonicsData
                                                      .map((val) => {
                                                        return `<span
                                                                    style="padding: 2px 8px;">
                                                                    ${val}
                                                                    </span>`;
                                                      })
                                                      .join("")}
                                                </div>
                                            </div>`;
            a.document.write(creating_html);
          }
        }
      } else if (this.log_Selected == "Depth" && this.dataType == "Data_Gaps") {
        let creating_html = `<table style="margin-top: 20px;width: 100%;">
                                            <thead>
                                                <tr>
                                                    <th style="text-align:left">Start Depth</th>
                                                    <th style="text-align:left">End Depth</th>
                                                </tr>
                                            </thead>  
                                            <tbody>
                                                ${this.dataGaps_Depth_Data
                                                  .map((eachObj) => {
                                                    return `<tr>
                                                                    <td>${eachObj.startDepth}</td>
                                                                    <td>${eachObj.endDepth}</td>
                                                                </tr>`;
                                                  })
                                                  .join("")}  
                                            </tbody>     
                                        </table>`;
        a.document.write(creating_html);
      } else if (this.log_Selected == "Time" && this.dataType == "Data_Gaps") {
        let creating_html = `<table style="margin-top: 20px; width:100%;">
                                            <thead>
                                                <tr>
                                                    <th style="text-align:left">Start Time</th>
                                                    <th style="text-align:left">End Time</th>
                                                </tr>
                                            </thead>  
                                            <tbody>
                                                ${this.dataGaps_Time_Data
                                                  .map((eachObj) => {
                                                    return `<tr>
                                                                    <td>${new Date(
                                                                      eachObj.startTime
                                                                    ).toLocaleString()}</td>
                                                                    <td>${new Date(
                                                                      eachObj.endTime
                                                                    ).toLocaleString()}</td>
                                                                </tr>`;
                                                  })
                                                  .join("")}  
                                            </tbody>     
                                        </table>`;
        a.document.write(creating_html);
      } else if (
        this.log_Selected == "Trajectory" &&
        this.dataType == "Nulls"
      ) {
        for (let each_Data in this.data_of_trajectory) {
          let creating_html = `<div style="margin-top: 20px;">
                                                <div style="margin-bottom: 5px; text-decoration: underline;">
                                                    <b>${
                                                      this.data_of_trajectory[
                                                        each_Data
                                                      ].depth
                                                    }</b>
                                                </div>
                                                <div style="display: grid;
                                                    grid-template-columns: repeat(3,1fr);
                                                    gap: 3px;">
                                                    ${this.data_of_trajectory[
                                                      each_Data
                                                    ].nodataMnemonicsData
                                                      .map((val) => {
                                                        return `<span
                                                                style="padding: 2px 8px;">
                                                                ${val}
                                                                </span>`;
                                                      })
                                                      .join("")}
                                                </div>
                                            </div>`;
          a.document.write(creating_html);
        }
      }

      a.document.write("</body></html>");
      a.document.close();
      a.print();
    },
    filter_mnemonic_fun_Nulls_Depth() {
      // nulls with Depth
      this.$refs.toShowTable.scrollTop = 0;
      if (this.filter_mnemonic.length) {
        this.nulls_Depth_Data = [];
        this.filtered_Nulls_Depth = [];
        this.showList.filter((data, index) => {
          let mnemonics_Data = [];
          data.nodataMnemonicsData.filter((eachString) => {
            if (
              eachString
                .toLowerCase()
                .includes(this.filter_mnemonic.toLowerCase())
            ) {
              mnemonics_Data.push(eachString);
            }
          });
          if (mnemonics_Data.length) {
            let obj = {
              depth: data.depth,
              nodataMnemonicsData: mnemonics_Data,
            };
            if (index < this.byDefault_to_show) {
              this.nulls_Depth_Data.push(obj);
            }
            this.filtered_Nulls_Depth.push(obj);
          }
        });

        // to hide more and less buttons after filtering or searching
        // this.more_less_btns = false;
      } else {
        // this.nulls_Depth_Data = this.showList;
        this.nulls_Depth_Data = this.showList.slice(0, this.byDefault_to_show);
        // this.more_less_btns = true;
        // document.querySelectorAll(".mnemonics_div").forEach((eachNode)=>{
        //     eachNode.style.maxHeight = "22vh";
        // })
      }
    },
    filter_mnemonic_fun_Nulls_Time() {
      // nulls with Time
      this.$refs.toShowTable.scrollTop = 0;
      if (this.filter_mnemonic.length) {
        this.nulls_Time_Data = [];
        this.filtered_nulls_time = [];
        this.show_Time_List.filter((data, index) => {
          let mnemonics_Data = [];
          data.nodataMnemonicsData.filter((eachString) => {
            if (
              eachString
                .toLowerCase()
                .includes(this.filter_mnemonic.toLowerCase())
            ) {
              mnemonics_Data.push(eachString);
            }
          });
          if (mnemonics_Data.length) {
            let toTime = null;
            if (data.toTime) {
              toTime = new Date(data.toTime).toLocaleString();
            }
            let obj = {
              fromTime: new Date(data.fromTime).toLocaleString(),
              toTime: toTime,
              nodataMnemonicsData: mnemonics_Data,
            };
            if (index < this.byDefault_to_show) {
              this.nulls_Time_Data.push(obj);
            }
            this.filtered_nulls_time.push(obj);
          }
        });

        // code to get searched value data in sequence and
        // based on sequence converting data in FromTime and ToTime format

        // if(this.logid_time_interval != ''){
        //     this.sequence_with_Nulls_time = [];
        //     this.sequence_with_Nulls_time = [...this.nulls_Time_List_For_Filter]
        //     this.sequence_with_Nulls_time.sort((a,b)=>{
        //         return new Date(a.time) - new Date(b.time)
        //     })
        //     let sequenceData = []
        //     this.sequence_with_Nulls_time.filter((obj) => {
        //         let previousObj = this.sequence_with_Nulls_time[this.sequence_with_Nulls_time.indexOf(obj) - 1]; // get previous object in array
        //         if (!previousObj){  // first object
        //             let firstObj_prev_mnemons = [];
        //             obj.nodataMnemonicsData.filter((str)=>{
        //                 if(str.toLowerCase().includes(this.filter_mnemonic.toLowerCase())){
        //                     firstObj_prev_mnemons.push(str);
        //                 }
        //             })

        //             let first_object = [{...obj}]
        //             first_object[0]["nodataMnemonicsData"] = firstObj_prev_mnemons;
        //             if(first_object[0]["nodataMnemonicsData"] != ''){
        //                 sequenceData.push(first_object)
        //             }
        //         }
        //         else{
        //             let current_Object_mnemonics = [];
        //             obj.nodataMnemonicsData.filter((str)=>{
        //                 if(str.toLowerCase().includes(this.filter_mnemonic.toLowerCase())){
        //                     current_Object_mnemonics.push(str);
        //                 }
        //             })

        //             let prev_Obj_Mnemonics = [];
        //             previousObj.nodataMnemonicsData.filter((str)=>{
        //                 if(str.toLowerCase().includes(this.filter_mnemonic.toLowerCase())){
        //                     prev_Obj_Mnemonics.push(str);
        //                 }
        //             })

        //             let diff = new Date(obj.time) - new Date(previousObj.time);
        //             if(diff == parseInt(this.logid_time_interval) * 1000 && JSON.stringify(current_Object_mnemonics) == JSON.stringify(prev_Obj_Mnemonics)){     // check if difference is 1 second and Mnemonics data is same or not
        //                 let matchedObj = {...obj};
        //                 matchedObj['nodataMnemonicsData'] = current_Object_mnemonics;
        //                 if(matchedObj['nodataMnemonicsData'] != ''){
        //                     sequenceData[sequenceData.length - 1].push(matchedObj)
        //                 }
        //             }
        //             else{
        //                 let notMatched = {...obj};
        //                 notMatched['nodataMnemonicsData'] = current_Object_mnemonics
        //                 if(notMatched['nodataMnemonicsData'] != ''){
        //                     sequenceData.push([notMatched])
        //                 }
        //             }
        //         }

        //     });

        //     let arrayOfObjs = [];
        //     sequenceData.map((eachArray)=>{
        //         let toTime = null;
        //         if(eachArray.length > 1){
        //             toTime = new Date(eachArray[eachArray.length - 1].time)
        //         }
        //         let obj = {
        //             fromTime: new Date(eachArray[0].time),
        //             toTime: toTime,
        //             nodataMnemonicsData: eachArray[0].nodataMnemonicsData
        //         }
        //         arrayOfObjs.push(obj)
        //     })
        //     this.filtered_nulls_time = [...arrayOfObjs]

        //     this.nulls_Time_Data = this.filtered_nulls_time.slice(0,this.byDefault_to_show)

        // }
      } else {
        this.nulls_Time_Data = this.show_Time_List.slice(
          0,
          this.byDefault_to_show
        );
      }
      // if(this.filter_mnemonic.length){
      //     this.nulls_Time_Data = this.nulls_Time_Data.filter((data)=>{
      //         data.nodataMnemonicsData = data.nodataMnemonicsData.filter((eachString)=>{
      //                                         if(eachString.toLowerCase().includes(this.filter_mnemonic.toLowerCase())){
      //                                             return eachString
      //                                         }
      //                                     })
      //         if(data.nodataMnemonicsData.length){
      //             return data
      //         }
      //     })
      // }
      // else{
      //     this.nulls_Time_Data = this.show_Time_List;
      // }
    },
    datagap_Depth_filter_mnemonic_fun() {
      // datagaps with depth
      if (this.datagap_filter_mnemonic) {
        this.dataGaps_Depth_Data = this.dataGaps_depth_List;

        this.dataGaps_Depth_Data = this.dataGaps_Depth_Data.filter((data) => {
          if (
            data.startDepth
              .toString()
              .includes(this.datagap_filter_mnemonic.toString()) ||
            data.endDepth
              .toString()
              .includes(this.datagap_filter_mnemonic.toString())
          ) {
            return data;
          }
        });
      } else {
        this.dataGaps_Depth_Data = this.dataGaps_depth_List;
      }
    },
    moreBtn_Fun(indx) {
      let dataHolderRef = `mnemonics_div_${indx}`;
      let moreBtnRef = `MoreBtn_${indx}`;
      let lessBtnRef = `LessBtn_${indx}`;
      this.$refs[dataHolderRef][0].style.maxHeight = "fit-content";
      this.$refs[moreBtnRef][0].classList.add("hidden");
      this.$refs[lessBtnRef][0].classList.remove("hidden");
    },
    lessBtn_Fun(indx) {
      let dataHolderRef = `mnemonics_div_${indx}`;
      let moreBtnRef = `MoreBtn_${indx}`;
      let lessBtnRef = `LessBtn_${indx}`;
      this.$refs[dataHolderRef][0].style.maxHeight = "22vh";
      this.$refs[lessBtnRef][0].classList.add("hidden");
      this.$refs[moreBtnRef][0].classList.remove("hidden");
    },
    scrollFuntion() {
      let scrollingDiv = this.$refs.toShowTable;
      let dataDiv_childs = this.$refs.dataDiv.children.length - 1;
      if (this.log_Selected == "Time" && this.dataType == "Nulls") {
        // nulls with time
        if (
          scrollingDiv.scrollTop + scrollingDiv.clientHeight >=
          scrollingDiv.scrollHeight
        ) {
          if (this.filter_mnemonic.length) {
            if (
              this.nulls_Time_Data.length != this.filtered_nulls_time.length
            ) {
              let data = this.filtered_nulls_time.slice(
                dataDiv_childs,
                dataDiv_childs + this.byDefault_to_show
              );
              this.nulls_Time_Data.push(...data);
            }
          } else {
            if (this.nulls_Time_Data.length != this.show_Time_List.length) {
              let data = this.show_Time_List.slice(
                dataDiv_childs,
                dataDiv_childs + this.byDefault_to_show
              );
              this.nulls_Time_Data.push(...data);
            }
          }
        }
      } else if (this.log_Selected == "Depth" && this.dataType == "Nulls") {
        // nulls with depth
        if (
          scrollingDiv.scrollTop + scrollingDiv.clientHeight >=
          scrollingDiv.scrollHeight
        ) {
          if (this.filter_mnemonic.length) {
            if (
              this.nulls_Depth_Data.length != this.filtered_Nulls_Depth.length
            ) {
              let data = this.filtered_Nulls_Depth.slice(
                dataDiv_childs,
                dataDiv_childs + this.byDefault_to_show
              );
              this.nulls_Depth_Data.push(...data);
            }
          } else {
            if (this.nulls_Depth_Data.length != this.showList.length) {
              let data = this.showList.slice(
                dataDiv_childs,
                dataDiv_childs + this.byDefault_to_show
              );
              this.nulls_Depth_Data.push(...data);
            }
          }
        }
      }
    },
    settingHeight(index) {
      if (this.$refs[`mnemonics_div_${index}`][0].clientHeight > 150) {
        this.$refs[`mnemonics_div_${index}`][0].style.maxHeight = "22vh";
        this.$refs[`more_less_btns_${index}`][0].style.display = "block";
      } else {
        this.$refs[`mnemonics_div_${index}`][0].style.maxHeight = "fit-content";
        this.$refs[`more_less_btns_${index}`][0].style.display = "none";
      }
    },
  },
  updated() {
    if (this.dataType == "Nulls" && this.showTableDiv) {
      let len = this.$refs.dataDiv.children.length;
      for (let i = 0; i < len; i++) {
        this.settingHeight(i);
      }
    }
  },
};
</script>
<style scoped>
/* changes for datagaps start */
.bgDark {
  background: var(--searchBarBg);
}
.bgLight {
  background: var(--searchBarBg);
}
.datagappopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: rgb(28 29 31 / 70%);
  display: flex;
}
.Child_Of_datagappopup {
  width: 90%;
  height: 85%;
  margin: auto;
  border-radius: 2px;
  /* background: var(--colorBoxPopUpBg); */
  background: var(--sidebarbg);
  color: var(--textColor);
}
/* .Child_Of_datagappopup.dark-mode{
        background: #404040;
    } */
.datagap_well_selections {
  width: 100%;
  gap: 5px;
  background: transparent;
}
.datagap_well_selections select,
.log_depth input {
  border: none;
  border-radius: 3px;
  left: 0;
  width: 13%;
  height: 25px;
  /* font-size: 12px; */
  padding: 0px 20px 2px 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  appearance: auto;
  background: var(--searchBarBg);
  /* color: var(--searchColor); */
  font-size: calc(0.3vw + 0.3vh + 0.35vmin);
  color: var(--textColor);
}
.log_depth {
  width: 30%;
}
.log_depth input {
  width: 25%;
}
.fontWeight-600 {
  font-weight: 600;
}
.asideSearch {
  padding: 0px 10px;
  margin-top: 5px;
  margin-bottom: 4px;
  height: 30px;
}
.asideSearchIp {
  height: 100%;
  width: 100%;
  background: transparent;
  border: none;
  box-shadow: none;
  /* color: var(--searchColor);
  font-size: 13px; */
  padding-right: 0px;
  padding-left: 8px;
  font-size: calc(0.3vw + 0.3vh + 0.35vmin);
  color: var(--textColor);
}
.asideSearchIp::placeholder {
  font-weight: 400;
  /* font-size: 12px; */
  line-height: 16px;
  color: var(--headingColor) !important;
  font-size: calc(0.3vw + 0.3vh + 0.35vmin);
  /* color: var(--textColor); */
}
.asideSearchIcon > path {
  fill: var(--activeTextColor);
}
.mnemonic_Record {
  /* background: var(--sidebarbg); */
  background: var(--sidebarListBgColor);
  padding: 3px 8px;
  border-radius: 4px;
  /* font-size: 12px; */
  font-size: calc(0.3vw + 0.3vh + 0.35vmin);
}
.MoreBtn,
.LessBtn {
  color: var(--activeTextColor);
  background: transparent;
  border: 1px solid currentColor;
  height: 20px;
  /* font-size: 10px; */
  font-size: calc(0.3vw + 0.3vh + 0.35vmin);
  padding-bottom: 2px;
  position: absolute;
  bottom: -24px;
  right: 0;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  padding-left: 5px;
}
.filterByMnemonicDiv {
  position: sticky;
  top: -1px;
  z-index: 11;
  background: var(--sidebarbg);
}
.loaderDiv {
  background: rgb(28 29 31 / 70%);
}
.loaderWaitText {
  /* background: var(--colorBoxPopUpBg); */
  color: var(--textColor);
}
.applyFont{
  font-size: calc(0.3vw + 0.3vh + 0.35vmin);
}
/* changes for datagaps end */
</style>
