<template>
  <div
    @contextmenu="this.setContextIdWidget"
    :id="div_id"
    @drop="dropCover($event, 1)"
    class="__trend_bg_seperation"
  >
    <!-- without image -->
    <div
      class="block tool_group"
      :style="{
        top: this.mouse_y - 25 + 'px',
        left: this.mouse_x + 'px',
      }"
    >
      <p v-if="isActcBarHover" class="actc_tool text-white">
        {{ actcBarMessage }}
      </p>
    </div>

    <!-- without image end -->

    <div
      :id="'main' + compId"
      :style="{
        height: wh.height + 'px',
        width: 100 + '%',
      }"
      :class="darkDark !== 'white' ? 'noUi-target_dup' : 'light_trend-mode'"
      class="arrangementClass"
    >
      <div class="flex justify-between">
        <div class="mt-2">
          <!-- <span class="text_xs feet_">Feet :</span> -->
          <span class="feet_boxed" style="font-size: 10px; height: 1.9rem">
            <div
              class="flex justify-around rages_of_mnemonics_in_corTrend ml-3"
              style="gap: 5px"
            >
              <div v-for="(feet, i) in feetRange" :key="i">
                <div
                  :style="{
                    fontSize: 10 + 'px',
                    marginTop: '-4px',
                    marginLeft: '-1px',
                  }"
                >
                  <button
                    class="timeBtn"
                    style="padding: 2px 3px"
                    :class="currentFeetRange == feet ? 'activeBg' : 'normalBg'"
                    @click="selectFeet(feet, true)"
                  >
                    <div class="flex">
                      <span>{{ feet }}</span>
                      <span>FT </span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </span>
        </div>
        <div>
          <div class="flex justify-around mt-1">
            <div class="flex __toggle__boxes" v-if="trendIndex == 0">
              <a
                @click="lockunlock()"
                :style="{ fontSize: 13 + 'px' }"
                class="settingPopColor"
                :class="isZoom ? 'text-gray-600' : 'white-white'"
              >
                <div class="tooltip hidden">
                  {{ locker ? "Lock" : "Unlock" }}
                </div>
                <span v-if="locker">
                  <i :class="'lock_ico fas fa-lock'"></i>
                </span>
                <span v-else>
                  <i :class="'lock_ico fas fa-lock-open'"></i>
                </span>
              </a>
            </div>
            <!-- <div class="block"> -->
            <div class="flex items-center justify-between __toggle__boxes">
              <div
                v-if="status == 'UPDATING'"
                :class="status == 'UPDATING' ? '' : 'tooltip__tip_live top'"
                :data-tip="status === 'UPDATING' ? 'text' : 'No live data'"
              >
                <button
                  :class="status == 'UPDATING' ? '' : 'disabled '"
                  @click="makeItLive"
                  :disabled="liveDisabled"
                  class="button-depth noselect text_xs float-right ml-2 newStylesLiveBtn"
                  :style="
                    liveDisabled
                      ? 'color: var(--liveBtnGreen);' +
                        (this.logType == 'depth' ? '' : '')
                      : '' + (this.logType == 'depth' ? '' : '')
                  "
                >
                  <div
                    class="liveBtnDot"
                    :style="
                      liveDisabled === true
                        ? 'background:white'
                        : 'background:gray'
                    "
                  ></div>
                  LIVE
                </button>
              </div>
            </div>
            <!-- </div> -->
            <!-- <div class="flex items-center justify-between __toggle__boxes">
              <div class="flex">
                <div :style="{ fontSize: 9 + 'px' }" class="mt-1 settingPopColor mb-1"
                  :class="isZoom ? 'text-gray-600' : 'white-white'">
                  Alert
                </div>
                <div class="ml-0.5 tool_section">
                  <input type="checkbox" :id="'alertswitch' + this.id" class="tool_toggle_input"
                    v-bind:value="isShowAlerts" @click="changeAlert()" :disabled="isZoom" />
                  <label class="tool_toggle_label ml-1 mb-1" :class="isZoom ? 'muted_bg' : '_alert'"
                    :for="'alertswitch' + this.id">Alerts :</label>
                </div>
              </div>
            </div> -->
            <div class="flex items-center justify-between __toggle__boxes">
              <div class="flex">
                <div
                  :style="{ fontSize: 9 + 'px' }"
                  class="mt-1 settingPopColor mb-1"
                  :class="isZoom ? 'text-gray-600' : 'white-white'"
                >
                  Tooltip
                </div>
                <div class="ml-0.5 tool_section">
                  <input
                    type="checkbox"
                    :id="'switch' + this.id"
                    class="tool_toggle_input"
                    :checked="isShowTooltip"
                    v-bind:value="isShowTooltip"
                    @click="changeTooltip()"
                    :disabled="isZoom"
                  />
                  <label
                    class="tool_toggle_label ml-1 mb-1"
                    :class="isZoom ? 'muted_bg' : '_tooltip'"
                    :for="'switch' + this.id"
                    >Tool Tip :</label
                  >
                </div>
              </div>
            </div>
            <div class="flex items-center justify-between __toggle__boxes">
              <div class="flex">
                <div
                  :style="{ fontSize: 9 + 'px' }"
                  class="mt-1 settingPopColor mb-1"
                >
                  Zoom
                </div>
                <div class="ml-0.5 tool_section">
                  <input
                    type="checkbox"
                    :id="'zoomswitch' + this.id"
                    :checked="this.isZoom"
                    class="tool_toggle_input"
                    v-bind:value="isZoom"
                    @click="changeZoom()"
                  />
                  <label
                    class="tool_toggle_label _zoom ml-1 mb-1"
                    :for="'zoomswitch' + this.id"
                    >Zoom :</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="flex justify-start bg-white"
        :style="{
          width: wh.templateWidth + 'px',
          height: bheight + 'px',
        }"
      >
        <div
          class="text-white px-2"
          :style="{
            background: 'var(--wdigetBg)',
            height: bheight + 'px',
            width: sliderWidth + leftbarWidth + 'px',
          }"
        >
          <div v-if="logType == 'depth'" class="flex mt-1">
            <div class="block" style="width: 100%">
              <div
                :style="{
                  fontSize: 9 + 'px',
                  color: 'var(--colorBoxHeadingText)',
                }"
                class=""
                :class="isZoom ? 'text-gray-600' : 'white-white'"
              >
                WELL DEPTH (ft)
              </div>
              <div class="flex flex-col">
                <div class="ml-0.5 tool_section newStWDepth">
                  <input
                    type="number"
                    :value="scaleMin"
                    class="depth_pick newStWDeptInput"
                    @input="
                      ($event) => {
                        !isNaN($event.target.value) &&
                          selectStartFeet($event.target.value);
                      }
                    "
                    @keyup.enter="selectStartFeet($event.target.value)"
                  />
                </div>
                <span
                  :style="{
                    fontSize: 9 + 'px',
                    color: 'var(--colorBoxHeadingText)',
                  }"
                  class="ml-auto mr-auto"
                  :class="isZoom ? 'text-gray-600' : 'white-white'"
                  >TO</span
                >
                <div class="ml-0.5 tool_section w-full newStWDepth">
                  <input
                    type="number"
                    :value="scaleMax"
                    @input="
                      ($event) => {
                        !isNaN($event.target.value) &&
                          selectEndFeet($event.target.value);
                      }
                    "
                    @keyup.enter="selectEndFeet($event.target.value)"
                    class="depth_pick newStWDeptInput"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="flex"
          :class="darkDark !== 'white' ? 'dark_trend_mode' : 'light_trend-mode'"
          :style="{
            width: wh.templateWidth - sliderWidth - leftbarWidth + 'px',
            maxWidth: templateWidth + 'px',
            height: bheight + 'px',
          }"
        >
          <div class="__label_boxes" v-if="trackConfig.tracks">
            <div class="flex full">
              <div
                class="__label_boxes"
                v-for="(track, j) in trackConfig.tracks"
                :key="j + trendIndex"
              >
                <div
                  id="tempdiv"
                  v-for="(curve, i) in track"
                  :key="i"
                  class="relative flex track_config_border bg-opacity-99"
                  :class="
                    this.darkDark !== 'white' ? 'bg-black' : 'light_trend-mode'
                  "
                  :style="{
                    width: singleTrackWidth + 'px',
                    backgroundColor: 'black',
                    marginRight: 0 + 'px',
                    fontSize: 19 + '%',
                    height: 38 + 'px',
                    marginLeft: j == 0 ? '0px' : '0',
                  }"
                  :draggable="false"
                  @dragstart="
                    startDrag($event, {
                      name: 'child',
                      trackNbr: j,
                      color: 'green',
                      curveNbr: i,
                      title: curve,
                      origin: compId,
                    });

                    $event.stopPropagation();
                  "
                >
                  <div class="t_d_col_1">
                    <button
                      v-on:click="showProps1(j, i)"
                      id='j+"-"+i'
                      class="colorbox"
                      :style="{
                        backgroundColor: curve.color,
                      }"
                    />
                  </div>
                  <div
                    v-if="
                      curveProps[j + '-' + i] && curveProps[j + '-' + i]['show']
                    "
                    :class="'curve_pop_opened'"
                    :style="{ right: singleTrackWidth - 22 + 'px' }"
                  >
                    <Props
                      :width="120"
                      :height="100 + '%'"
                      :curve="curveProps[j + '-' + i]['curve']"
                      @closeIt="closeIt(j, i)"
                      @apply="generalCurveUpdate(j, i, $event)"
                      @delete="generalCurveUpdate(j, i)"
                    />
                  </div>
                  <div
                    :title="curve.fullName.toUpperCase()"
                    class="name_numaric_con flex flex-col justify-center tooltip__tip top truncate"
                    :class="
                      this.darkDark !== 'white'
                        ? 'tooltip__tip_dark'
                        : 'tooltip__tip_light'
                    "
                    :data-tip="getLatestValuesToDisplay(curve.name)"
                  >
                    <div
                      style="paddingleft: 0.8px"
                      class="td_trnk"
                      v-if="curve.fullName"
                      :class="
                        this.darkDark !== 'white'
                          ? 'text_gray colorBox_text_gray'
                          : 'text_dark colorBox_text_gray'
                      "
                      :data-tip="curve.fullName.toUpperCase()"
                    >
                      {{
                        curve.editedName
                          ? curve.editedName
                          : getFullNameDisplay(curve.name)
                      }}
                    </div>
                    <div
                      class="flex justify-center value_of_Mnemonic_in_corTrend"
                    >
                      <p class="truncate">
                        {{ getLatestValuesToDisplay(curve.name) }}
                        &nbsp;
                      </p>
                    </div>
                    <div class="flex justify-around">
                      <p>
                        {{
                          curve.scale[0] ? curve.scale[0].toFixed(2) : "0.00"
                        }}
                      </p>
                      <p>
                        {{
                          this.$store.state.disp.displays[this.displayId]
                            ? this.$store.state.disp.displays[this.displayId]
                                .units[
                                this.$store.state.disp.displays[
                                  this.displayId
                                ].tags.indexOf(curve.name)
                              ]
                            : ""
                        }}
                      </p>
                      <p>
                        {{ curve.scale[1] ? curve.scale[1].toFixed(2) : 0.0 }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-start" :style="{ width: width + 'px' }">
        <div
          :id="compId + 'localSliderParent'"
          class="flex relative"
          :class="darkDark !== 'white' ? 'noUi-target_dup' : 'light_trend-mode'"
          :style="{
            marginTop: '20px',
            height: singleTrackHeight - 40 + 'px',
            width: sliderWidth + 'px',
          }"
        >
          <div
            :style="{
              position: 'absolute',
              width: sliderWidth + 'px',
              height: singleTrackHeight - 40 + 'px',
              display: 'none',
            }"
            :id="compId + 'localSlider'"
          ></div>
        </div>

        <div
          class="flex"
          :class="darkDark !== 'white' ? 'bg-black' : 'light_trend-mode'"
          :style="{
            height: singleTrackHeight + 'px',
            width: wh.templateWidth - sliderWidth + 'px',
          }"
        >
          <div
            class="scrolldiv bg-opcity-95 flex"
            :class="
              darkDark !== 'white' ? 'bg-black_mattie' : 'light_trend-mode'
            "
            :style="{
              height: singleTrackHeight + 'px',
              width: wh.templateWidth - sliderWidth + 'px',
              maxWidth: width - sliderWidth + 'px',
            }"
            :id="div_id + this.trendIndex + 'div_id'"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import nouislider from "../../../js/noUiSlider-15.2.0/src/nouislider.js";
import "../../../js/noUiSlider-15.2.0/src/slider.css";
import { template } from "../../../js/vertical/template.js";
import plotConfig from "../../../js/vertical/wellconfig.js";
import * as d3 from "d3";
import AdminSubNavbar from "../../Navbars/AdminSubNavbar.vue";
import Props from "../Trend/Trendprops.vue";
import CustomSelect from "../../Select/SelectComponent.vue";
import apiService from "../../../api/services";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import defaultRanges from "../../../js/ranges";
import moment from "moment-timezone";

export default {
  name: "Trend",
  components: {
    AdminSubNavbar,
    Props,
    CustomSelect,
    Datepicker,
  },
  data() {
    return {
      isDataLoaded: true,
      scaleTimeStart: "",
      scaleTimeEnd: "",
      minmax: {},
      scaleMinDate: "",
      isZoom: false,
      isRerendering: false,
      zero: [
        "wob",
        "mse",
        "diff",
        "spm1",
        "spm2",
        "spm3",
        "spp",
        "tgpm",
        "rpm",
        "mg1c",
        "simplifiedmse",
        "stickslipindex",
        "washoutindex",
        "chkp",
      ],
      double: [
        "gainloss",
        "flow_out",
        "mfop",
        "hkl",
        "bpos",
        "dbtm",
        "dmea",
        "rop",
        "tor",
        "drtm",
        "inc",
        "az",
        "tvd",
        "dens_in",
        "dens_out",
        "temp_in",
        "temp_out",
      ],
      triple: ["stickslipindex", "depthofcut"],
      mouse_counter: 0,
      mouse_x: 0,
      mouse_y: 0,
      isEraticMse: false,
      isMotorStalled: false,
      isAdFunction: false,
      isDrillInEfficiency: false,
      isDiffPressAlert: false,
      isBitImpact: false,
      isDis: true,
      tooltipChecked: false,
      dropChecked: true,
      timeoutCheck: "",
      // Socket Variables:
      stopUpdation: false,
      // isShowTooltip: false,
      checked_tool: false,
      isShowAlerts: false,
      depthMaxValue: 0,
      depthMinValue: 0,
      currentTimeRange: 4,
      currentFeetRange: 500,
      topic: null,
      socketId: null,
      socket: null,

      liveDisabled: false,
      timeRanges: [2, 4, 8, 12, 24],
      toolTipUpshiftAmount: 3500,
      secondsdataavailability: false,
      focusAxisList: [],
      bgColor: "#202124",
      configurePlots: {},
      templateWidth: "",
      div_id: "divId",
      tickColor: "#ededed",
      visible: false,
      sliderWidth: 170,
      sliderBG: "lightgray",
      readyToPlot: false,
      emptyPlot: true,
      currentCurve: null,
      selectedTrackValue: null,
      isNewTrack: false,
      curve: "",
      trackConfig: {},
      bheight: 90,
      wellId: null,
      wellboreId: null,
      logId: null,
      wellboreState: null,
      logType: "depth",
      data: [],
      completeData: [],
      zoomMin: null,
      zoomMax: null,
      plots: [],
      plotTemplate: template(""),
      curvesMinsMaxes: {},
      sliderWidth: 5,
      leftbarWidth: 100,
      trackWidth: 500,
      scaleMin: 0,
      scaleMax: 1000,
      scaleMinFixed: 0,
      scaleMaxFixed: 1000,
      singleTrackHeight: null,
      slider: null,
      noUislider: null,
      singleTrackWidth: 120,
      totTracks: [],
      compId: "",
      curveProps: {},
      templateHeight: 0,
      tracksNbr: 6,
      tickThickness: 0.1,
      tracks: [],
      renderedHeight: false,
      renderedWidth: false,
      widthChanged: false,
      heightChanged: false,
      // wh: {
      //   width: this.width,
      //   height: this.height,
      // },
      feetRange: [50, 100, 500, 1000],
      // hrsRange: ["default", 4, 8, 12, 24],
      feet: "",
      hrs: "",
      track1Id: "",
      track2Id: "",
      track3Id: "",
      track4Id: "",
      track5Id: "",
      isSpecialtyScreenCreated: false,
      updateTimeout: null,
      status: -1,
      active_popover: false,
      isActcBarHover: false,
      actcBarMessage: "",
      unsubscribe: "",
      defaultColor: [
        ["#c530ab", "#ff0000"],
        ["#2abbbc", "#F08E05"],
        ["#37b937", "#fe019a"],
      ],
      myWatcher: null,
    };
  },
  props: {
    correlationIdx: [String, Number],
    wellDetails: {
      type: Object,
    },
    well_id: {
      type: String,
    },
    requiredData: {
      type: Object,
    },
    displayIndex: [Number, String],
    widgetIndex: Number,
    height: {
      type: [Number, String],
      default: "50",
    },
    width: {
      type: [Number, String],
      default: "50",
    },
    divid: String,
    id: String,
    displayId: String,
    plotConfigProps: {
      type: Object,
      default: {},
    },
    trendIndex: Number,
    selectedWell: {
      type: Object,
    },
  },
  beforeUnmount() {
    // this.data = [];
    // this.completeData = [];
    // this.deleteStoreValues()

    // this.$store.state.rect.wellRelationOptimization?.optimization_type = ''
    this.takeOffSocket();
    // this.unsubscribe()
    if (this.myWatcher) this.myWatcher();
  },
  async mounted() {
    try {
      let well_depth =
        this.wellDetails["wellbores"][this.wellDetails.well_bore_name]["logs"][
          "depth"
        ];
      console.log(
        "🚀 ➽ file: CorrelationTrend.vue:495  ➽ mounted  ➽ well_depth ⏩",
        well_depth
      );

      this.wellId = this.wellDetails.well_id;
      this.wellboreId = this.wellDetails.well_bore_name;
      this.logId = well_depth.topicname;
      this.topic = well_depth.topicname;
      this.time_zone = well_depth.time_zone || "CST6CDT";

      moment.tz.setDefault(this.time_zone);
      this.status = well_depth.state;

      let minMax = await this.getMinMaxRange("mounted");
      if (minMax) {
        let limit = 0;
        // this.scaleMin = parseInt(minMax.max - 500);
        // this.scaleMax = parseInt(minMax.max);
        // limit = parseInt(minMax.max);
        // this.scaleMinFixed = parseInt(minMax.min);
        // this.scaleMaxFixed = parseInt(minMax.max);
        if (minMax.max == minMax.min) minMax.max = minMax.min + 1000;
        this.scaleMin = minMax.max - 500;

        this.scaleMax = minMax.max;
        this.scaleMinFixed = minMax.min;
        this.scaleMaxFixed = minMax.max;
        // this.createSlider();
      } else {
        // if (minMax.max < this.scaleMax) {
        this.scaleMin = parseInt(minMax.max - 500);
        this.scaleMax = parseInt(minMax.max);
        // }
      }

      this.noUislider = { ...nouislider };
      this.compId = Math.random().toString(36).slice(8);
      this.div_id = "id" + this.compId;
      await this.trackPropsMaping();

      await this.createSlider();

      if (this.requiredData && this.requiredData["dmea"]) {
        // this.data = this.requiredData;
        this.completeData = this.requiredData;

        this.zoomIt(
          this.scaleMax,
          this.scaleMin,
          this.feet,
          false,
          "called mounted"
        );
      }
      this.myWatcher = this.$store.subscribe(async (mutation, state) => {
        if (mutation.type == "rect/setRelationDisplay") {
          if (
            this.id ==
            mutation.payload.widgetId + mutation.payload.barIndex
          ) {
          } else {
          }
        }
        if (
          mutation.type == "rect/wellRelationOptimization" &&
          this.displayId == mutation.payload.displayId
        ) {
          this.correlationOptimization =
            mutation.payload.value.optimization_type;

          let mnm = mutation.payload.value.mnemnonics.split(",");
          let tempTracks = [];
          let count = 0;

          let defaultColor = [
            ["#c530ab", "#ff0000"],
            ["#2abbbc", "#F08E05"],
            ["#37b937", "#fe019a"],
          ];
          for (let i = 0; i < 3; i++) {
            let tempJTrack = [];
            for (let j = 0; j < 2; j++) {
              let fullName = mnm[count].trim();
              if (mnm[count].trim() == "doglegseverity") fullName = "DLS";

              if (mnm[count].trim() == "shallowresistivity")
                fullName = "SL RES";

              tempJTrack.push({
                name: mnm[count].trim(),
                fullName: fullName,
                color: defaultColor[i][j],
                trackNbr: 0,
                curveNbr: 0,
                trackId: "t0gihl",
                min: 51.41224,
                max: 52.6431,
                scale: [51.41224, 52.6431],
                scaleType: "auto",
              });
              count++;
            }
            tempTracks.push(tempJTrack);
          }
          this.trackConfig.tracks = tempTracks;
          this.$store.dispatch("rect/setCorrelationConfig", {
            disId: this.displayId,
            widId: this.id.slice(0, -1),
            config: this.trackConfig,
            trendIndex: this.trendIndex,
            option: "wellRelationOptimization",
          });
        }
        if (
          mutation.type == "disp/correlationMapSyncScale" &&
          this.displayId == mutation.payload.display
        ) {
          if (
            mutation.payload.trendIndex != this.trendIndex &&
            this.scaleMaxFixed >= mutation.payload.scaleMax &&
            this.scaleMinFixed <= mutation.payload.scaleMin &&
            this.slider &&
            this.slider.noUiSlider
          ) {
            //
            this.updateToSync(mutation.payload);
          }
        }
        if (
          mutation.type == "rect/mousemove" &&
          this.displayId == mutation.payload.displayId
        ) {
          this.mousemove(mutation.payload.event, mutation.payload.trendIndex);
        }
        if (
          mutation.type == "rect/mouseout" &&
          this.displayId == mutation.payload.displayId
        ) {
          this.mouseout(mutation.payload.event);
        }
        if (
          mutation.type == "rect/mouseover" &&
          this.displayId == mutation.payload.displayId
        ) {
          this.mouseover(mutation.payload.event);
        }
        if (
          mutation.type == "rect/mousescroll" &&
          this.displayId == mutation.payload.displayId
        ) {
          this.mousescroll(mutation.payload.event, mutation.payload.trendIndex);
        }

        if (
          mutation.type == "rect/toolTipToggle" &&
          this.displayId == mutation.payload.displayId
        ) {
          this.isShowTooltip = mutation.payload.isShowTooltip;
          if (!this.isShowTooltip) {
            for (let focusAxis of this.focusAxisList) {
              let focus = focusAxis["focus"];
              focus
                .style("display", "none")
                .transition()
                .duration(250)
                .style("opacity", 0);
            }
          }
        }
        if (
          mutation.type == "rect/toolZoomToggle" &&
          this.displayId == mutation.payload.displayId
        ) {
          this.isZoom = mutation.payload.isZoom;
          this.rerender();
        }
        if (
          mutation.type == "rect/zoomEnd" &&
          this.displayId == mutation.payload.displayId
        ) {
          let event = mutation.payload.event;
          let extent = event.selection;
          let min = this.yLeftScale.invert(extent[0]);
          let max = this.yLeftScale.invert(extent[1]);
          // min = moment(min).unix() * 1000;
          // max = moment(max).unix() * 1000;
          if (this.slider && this.slider.noUiSlider) {
            this.slider.noUiSlider.updateOptions({
              behaviour: "drag",
              start: [parseInt(min), parseInt(max)],
              range: {
                min: this.scaleMinFixed,
                max: this.scaleMaxFixed,
              },
            });
            this.scaleMin = parseInt(min);
            this.scaleMax = parseInt(max);
            this.zoomIt(
              this.scaleMax,
              this.scaleMin,
              this.feet,
              false,
              "3 called"
            );
          }
        }
        if (
          mutation.type == "rect/setCurveProps" &&
          this.displayId == mutation.payload.displayId
        ) {
          if (
            mutation.payload.value.i == -1 &&
            mutation.payload.value.j == -1
          ) {
            this.onDrop(
              mutation.payload.value.value,
              mutation.payload.trendIndex
            );
          } else {
            if (mutation.payload.value.curve)
              this.setCurveProps(
                mutation.payload.value.j,
                mutation.payload.value.i,
                mutation.payload.value.curve
              );
            else
              this.deleteCurve(
                mutation.payload.value.j,
                mutation.payload.value.i
              );
            // }
            // this.rerender();
          }
        }
        if (
          mutation.type == "rect/selectFeet" &&
          this.displayId == mutation.payload.displayId
        ) {
          // this.selectFeet(mutation.payload.value);
          let feet = mutation.payload.value;
          let scaleMin = 0;
          if (feet == "default") {
            this.feet = 1000;
            scaleMin = this.scaleMax - 1000;
          } else {
            this.feet = feet;
            scaleMin = this.scaleMax - feet;
          }
          if (this.slider && this.slider.noUiSlider) {
            this.slider.noUiSlider.updateOptions({
              behaviour: "drag",
              start: [scaleMin, this.scaleMax],
              range: {
                min: this.scaleMinFixed,
                max: this.scaleMaxFixed,
              },
            });
            this.scaleMin = scaleMin;
            this.currentFeetRange = feet;
            this.zoomIt(
              this.scaleMax,
              this.scaleMin,
              this.feet,
              false,
              "4 called"
            );
          }
        }

        if (
          mutation.type == "rect/wellSection" &&
          this.displayId == mutation.payload.displayId
        ) {
          for (let i of mutation.payload.value) {
            if (i.sourceid == this.logId) {
              this.scaleMin = i.start_depth;
              this.selectEndFeet(i.end_depth);
            }
          }
        }

        if (
          mutation.type == "rect/setCorrelationConfig" &&
          this.displayId == mutation.payload.disId &&
          this.trendIndex == mutation.payload.trendIndex
        ) {
          if (mutation.payload.option == "onDrop") {
            console.log(
              "rect / set Correlation Config options: ",
              this.trendIndex,
              mutation.payload.trendIndex,
              mutation.payload.option,
              new Date().getTime()
            );

            this.trackConfig = mutation.payload.config;
            this.zoomIt(
              this.scaleMax,
              this.scaleMin,
              this.feet,
              false,
              "on drop change"
            );
          }
          if (mutation.payload.option == "wellRelationOptimization") {
            console.log(
              "rect / set Correlation Config options: ",
              this.trendIndex,
              mutation.payload.trendIndex,
              mutation.payload.option,
              new Date().getTime()
            );
          }
        }
      });
      if (this.status == "UPDATING") {
        this.getRealtimeData("mounted");
      }
    } catch (error) {
      console.error(error);
    }
    // setTimeout(() => {
    //   this.lockunlock();
    // }, 1500);
  },
  unmounted() {
    console.log("called in destroyed of correlation trend");
  },
  computed: {
    isShowTooltip() {
      return this.$store.state.rect.rects[this.correlationIdx].isShowTooltip;
    },
    wh() {
      let wh = {};
      wh.width = this.width;
      wh.height = this.height;
      wh.templateWidth = this.width - 10;
      wh.templateHeight = this.height - 10;
      this.templateHeight = this.height - 10;
      this.templateWidth = this.width - 10;
      if (this.trackConfig.tracks)
        this.setTrackWidth(this.trackConfig.tracks.length);
      else this.setTrackWidth(0);
      this.setTrackHeight();
      // this.rerender('WH');

      return wh;
    },
    darkDark() {
      d3.selectAll(".y2").style(
        "fill",
        this.$store.state.rect.darkmode !== "white" ? "white" : "black"
      );
      setTimeout(() => {
        this.rerender("darkDark");
      }, 0);
      return this.$store.state.rect.darkmode;
    },
    locker() {
      return this.$store.state.disp.rects[this.displayIndex]?.locker;
    },
  },
  sockets: {
    connect: function () {},
    disconnect() {
      this.isConnected = false;
      try {
        let topic = this.topic;
        this.$socket.emit("unsubscribe", {
          logId: `${this.wellId}~seperation~${this.wellboreId}~seperation~${this.logType}`,
          topic: this.topic,
          id: this.widgetId,
          clientId: this.$clientId,
        });
      } catch (error) {
        console.error("disconnect unsubscribe error ", error);
      }
    },
    error() {},
    reconnect() {
      this.$socket.emit("susbcribe", {
        logId: `${this.wellId}~seperation~${this.wellboreId}~seperation~${this.logType}`,
        topic: this.topic,
        fields: this.fields,
        id: this.id,
        clientId: this.$clientId,
      });
    },
    reconnect_failed() {},
    reconnect_error() {},
    connect_error() {},
    connecting() {},
    connect_timeout() {},
  },
  methods: {
    deleteStoreValues() {},
    getFullNameDisplay(name) {
      try {
        if (name == "diff") return "DIFF PRESS";
        else if (name == "simplifiedmse") return "MSE";
        else if (name == "bpos") return "BLOCK POS";
        else if (name == "stickslipindex") return "STICK SLIP";
        else if (name == "washoutindex") return "WASH OUT";
        else if (name == "sl_res") return "SL RES";
        else
          return this.$store.state.disp.displays[this.displayId].displayTags[
            this.$store.state.disp.displays[this.displayId].tags.indexOf(name)
          ].toUpperCase();
      } catch (error) {
        return "";
      }
    },
    async createSlider() {
      try {
        let format = {};
        this.emptyPlot = true;
        this.slider = document.getElementById(this.compId + "localSlider");
        let behaviour1 = "drag";
        let start1 = [this.scaleMin, this.scaleMax];

        let sliderObj = {
          start: start1,
          limit: 86400000,
          width: 100,
          orientation: "vertical",
          tooltips: true,
          height: this.singleTrackHeight,
          behaviour: behaviour1,
          connect: true,
          range: {
            min: this.scaleMinFixed ? this.scaleMinFixed : 0,
            max: this.scaleMaxFixed ? this.scaleMaxFixed : 100,
          },
        };
        if (format.to) {
          sliderObj.format = format;
        }
        if (this.slider) {
          try {
            this.noUislider.create(this.slider, sliderObj);
          } catch (error) {}

          this.slider.noUiSlider.on("change", () => {
            // if anyone changes the UI Slider then, it will disconnect live sockets & reset time range

            if (this.scaleMax - this.scaleMin == 1000) {
              this.currentTimeRange = 1000;
            } else if (this.scaleMax - this.scaleMin == 500) {
              this.currentTimeRange = 500;
            } else if (this.scaleMax - this.scaleMin == 100) {
              this.currentTimeRange = 100;
            } else if (this.scaleMax - this.scaleMin == 50) {
              this.currentTimeRange = 50;
            } else {
              this.currentTimeRange = "";
            }

            this.takeOffSocket();

            this.liveDisabled = false;
          });

          // this.zoomIt(
          //   this.scaleMax,
          //   this.scaleMin,
          //   this.feet,
          //   false,
          //   "14 called"
          // );
        }
      } catch (error) {
        console.error("create slider error", error);
      }
    },
    getLatestValuesToDisplay(name) {
      return this.data[name]
        ? this.data[name][this.data[name].length - 1]
          ? this.data[name][this.data[name].length - 1].toFixed(2)
          : "0.00"
        : "0.00";
    },
    updateToSync(payload) {
      try {
        this.slider.noUiSlider.updateOptions({
          start: [parseInt(payload.scaleMin), parseInt(payload.scaleMax)],
        });
        this.scaleMin = parseInt(payload.scaleMin);
        this.scaleMax = parseInt(payload.scaleMax);
        this.zoomIt(this.scaleMax, this.scaleMin, this.feet, false, "5 called");
      } catch (error) {}
    },
    selectStartFeet(feet) {
      feet = parseInt(feet);
      if (this.slider && this.slider.noUiSlider && feet < this.scaleMax) {
        this.scaleMin = feet;
        this.slider.noUiSlider.updateOptions({
          behaviour: "drag",
          start: [feet, this.scaleMax],
          range: {
            min: this.scaleMinFixed,
            max: this.scaleMaxFixed,
          },
        });
        this.scaleMin = feet;
        this.zoomIt(this.scaleMax, this.scaleMin, this.feet, false, "6 called");
      } else {
        this.$toast.error("Invalid selected range", {
          position: "top",
          duration: "1000",
        });
      }
    },

    selectEndFeet(feet) {
      feet = parseInt(feet);
      if (this.slider && this.slider.noUiSlider && feet > this.scaleMin) {
        this.scaleMax = parseInt(feet);
        this.slider.noUiSlider.updateOptions({
          behaviour: "drag",
          start: [this.scaleMin, feet],
          range: {
            min: this.scaleMinFixed,
            max: this.scaleMaxFixed,
          },
        });
        this.scaleMax = feet;
        this.zoomIt(this.scaleMax, this.scaleMin, this.feet, false, "7 called");
      } else {
        this.$toast.error("Invalid selected range", {
          position: "top",
          duration: "1000",
        });
      }
    },

    onFromDateChange: function (e) {
      if (e) {
        this.takeOffSocket();
        this.scaleMinDate = e;
        let timeDifference = this.scaleMax - this.scaleMin;
        this.scaleMax = moment(this.scaleMinDate).unix() * 1000;
        this.scaleMin = this.scaleMax - timeDifference;
        if (this.slider) {
          this.slider.noUiSlider.updateOptions({
            behaviour: "drag",
            start: [this.scaleMin, this.scaleMax],
            range: {
              min: this.scaleMinFixed,
              max: this.scaleMaxFixed,
            },
          });
          this.zoomIt(
            this.scaleMax,
            this.scaleMin,
            this.feet,
            false,
            "8 called"
          );
        }
      }
    },

    onToDateChange: function (e) {
      this.scaleMinDate = e;
      this.scaleMin = moment(this.scaleMinDate).unix() * 1000;
      if (this.slider) {
        this.slider.noUiSlider.updateOptions({
          behaviour: "drag",
          start: [this.scaleMin, this.scaleMax],
          range: {
            min: this.scaleMinFixed,
            max: this.scaleMaxFixed,
          },
        });
        this.zoomIt(this.scaleMax, this.scaleMin, this.feet, false, "9 called");
      }
    },

    increase: function (step, event) {
      this.mouse_counter += step;
    },

    updateCoordinates: function (event) {
      // pass event object, bound to mouse move with updat
      this.mouse_x = event.clientX;
      this.mouse_y = event.clientY;
    },

    alertValueChange(value, alert) {
      if (this.isShowAlerts) {
        if (alert === "ERRATIC MSE") this.isEraticMse = value;
        if (alert === "PRESSURE SPIKE") this.isMotorStalled = value;
        if (alert === "AD Dysfunction") this.isAdFunction = value;
        if (alert === "Drlg Ineff") this.isDrillInEfficiency = value;
        if (alert === "Diff. Press") this.isDiffPressAlert = value;
        if (alert === "BIT IMPACT") this.isBitImpact = value;
      }
    },

    createAlertBar(div_id, templates) {
      console.time("Time For Creating createAlertBar : ");
      let template_for_plotting = templates[0];
      let template_overall = template_for_plotting[0]["curve_box"];
      let yAxisHolder = d3.select("#" + div_id).append("div");
      yAxisHolder
        .style("display", "inline-block")
        .style("vertical-align", "top")
        .style("margin-right", "0px")
        .style("width", "20px");

      this.leftRangeSliderSet = true;
      let height_components = template_overall["height"] - 12;
      let y = this.yLeftScale;
      if (this.yLeftScale) {
        let leftbarOuter = yAxisHolder
          .append("div")
          .attr("class", "leftbar-outer")
          .style("width", "20px");

        let leftbarInner = leftbarOuter
          .append("div")
          .attr("class", `leftbar-inner-${div_id}`)
          .style("max-height", height_components + "px")
          .style("width", "20px")
          .style("height", height_components + "px")
          .style("direction", "rtl")
          .style("scrollbar-track", "red");

        let svg = leftbarInner
          .append("svg")
          .style("width", "20px")
          // .attr('height', height);
          .style("overflow-x", "auto")
          .attr("height", height_components);

        let g = svg.append("g").attr("height", height_components);

        let startActcCode = 0;
        let endActcCode = 0;
        let startActcTime = 0;
        let endActcTime = 0;
        let totalRecords = this.data["dmea"].length - 1;
        let actcColor = "white";
        let tooltip = "";
        let logType = this.logType;
        function compare(a, b) {
          if (a.dmea < b.dmea) {
            return -1;
          }
          if (a.dmea > b.dmea) {
            return 1;
          }
          return 0;
        }
        let compId = this.compId;
        g.selectAll(".group")
          .data(this.data)
          .attr("class", "group")
          .enter()
          .each(function (d, i) {
            if (i == 0) {
              startActcCode = d.alert;
              startActcTime = d.dmea;
            } else {
              endActcCode = d.alert;
              endActcTime = d.dmea;

              if (startActcCode != endActcCode || i == totalRecords) {
                let letters = "0123456789ABCDEF";
                let color = "#";
                for (let i = 0; i < 6; i++) {
                  color += letters[Math.floor(Math.random() * 16)];
                }
                actcColor = color;
                tooltip = "Rotary Drilling";
                d3.select(this)
                  .append("line")
                  .style("stroke", color)
                  .style("stroke-width", 2)
                  .attr("x1", 0)
                  .attr("y1", y(startActcTime))
                  .attr("x2", 20)
                  .attr(
                    "y2",
                    y(startActcTime) + (y(endActcTime) - y(startActcTime)) / 2
                  );
                d3.select(this)
                  .append("line")
                  .style("stroke", color)
                  .style("stroke-width", 2)
                  .attr("x1", 0)
                  .attr("y1", y(endActcTime))
                  .attr("x2", 20)
                  .attr(
                    "y2",
                    y(startActcTime) + (y(endActcTime) - y(startActcTime)) / 2
                  );

                d3.select(this)
                  .append("text")
                  .attr("class", "bar")
                  .attr("data-index", i)
                  // .attr("width", "20px")
                  .attr("x", 50)
                  .style("fill", actcColor)
                  .attr(
                    "y",
                    y(startActcTime) + (y(endActcTime) - y(startActcTime)) / 2
                  )
                  .text(startActcCode);

                startActcCode = d.alert;
                startActcTime = d.dmea;
              }
            }
          });
        console.timeEnd("Time For Creating createAlertBar : ");
      }
    },

    takeOffSocket() {
      this.liveDisabled = false;
      if (this.$socket) {
        this.$socket.emit("unsubscribe", {
          logId: `${this.wellId}~seperation~${this.wellboreId}~seperation~${this.logType}`,
          topic: this.topic,
          id: this.id,
          clientId: this.$clientId,
        });
        this.sockets.unsubscribe(this.$clientId);
      }
      this.stopUpdation = false;
    },

    async trackPropsMaping() {
      let saved = false;

      this.trackConfig = {
        height: "500",
        width: "260",
        UWI: "00/01-01-095-19W4/0",
        curveName: "CHALKTALK",
        tracks: [
          [
            {
              name: "wob",
              fullName: "wob",
              color: "#c530ab",
              trackNbr: 0,
              curveNbr: 0,
              trackId: "t0gihl",
              min: 51.41224,
              max: 52.6431,
              scale: [51.41224, 52.6431],
              scaleType: "auto",
              stroke: 2,
            },
            {
              name: "rpm",
              fullName: "rpm",
              color: "#ff0000",
              trackNbr: 0,
              curveNbr: 0,
              trackId: "t0gihl",
              min: 51.41224,
              max: 52.6431,
              scale: [51.41224, 52.6431],
              scaleType: "auto",
              stroke: 2,
            },
          ],
          [
            {
              name: "mfi",
              fullName: "mfi",
              color: "#2abbbc",
              trackNbr: 0,
              curveNbr: 0,
              trackId: "t0gihl",
              min: 51.41224,
              max: 52.6431,
              scale: [51.41224, 52.6431],
              scaleType: "auto",
              stroke: 2,
            },
            {
              name: "rop",
              fullName: "rop",
              color: "#F08E05",
              trackNbr: 0,
              curveNbr: 0,
              trackId: "t0gihl",
              min: 51.41224,
              max: 52.6431,
              scale: [51.41224, 52.6431],
              scaleType: "auto",
              stroke: 2,
            },
          ],
          [
            {
              name: "tor",
              fullName: "TORQUE",
              color: "#37b937",
              trackNbr: 0,
              curveNbr: 0,
              trackId: "t0gihl",
              min: 51.41224,
              max: 52.6431,
              scale: [51.41224, 52.6431],
              scaleType: "auto",
              stroke: 2,
            },
            {
              name: "spp",
              fullName: "spp",
              color: "#fe019a",
              trackNbr: 0,
              curveNbr: 0,
              trackId: "t0gihl",
              min: 51.41224,
              max: 52.6431,
              scale: [51.41224, 52.6431],
              scaleType: "auto",
              stroke: 2,
            },
          ],
        ],
        curveType: "dmea",
        depthName: "dmea",
      };
      let tracks = this.trackConfig.tracks;
      this.templateWidth = this.width;
      let mnemonics = [];

      for (let i = 0; i < tracks.length; i++) {
        let tags = tracks[i];
        for (let j = 0; j < tags.length; j++) {
          let trackId = "t" + Math.random().toString(36).slice(8);
          tags[j].trackId = trackId;
          mnemonics.push(tags[j].name);
        }
        tracks[i] = tags;
      }
      tracks.forEach((track) => {
        track.map((eachMn) => {
          eachMn["description"] = eachMn.fullName;
        });
      });
      this.trackConfig.tracks = tracks;
      this.trackConfig = this.trackConfig;
    },

    getOnScreenMnemonics() {
      let tracks = this.trackConfig.tracks;
      let mnemonics = [];
      if (tracks) {
        for (let i = 0; i < tracks.length; i++) {
          let tags = tracks[i];
          for (let j = 0; j < tags.length; j++) {
            // let trackId = "t" + Math.random().toString(36).slice(8);
            // tags[j].trackId = trackId;
            mnemonics.push(tags[j].name);
          }
          tracks[i] = tags;
        }
      }

      return mnemonics;
    },

    async getMultiTagsData(mnemonics, from) {
      try {
        if (mnemonics.length == 0) {
          return false;
        }
        this.$store.dispatch("data/startLoading");
        let result = null;
        mnemonics.push("dmea");
        if (
          this.$store.state.rect.rects[this.correlationIdx]
            .wellRelationOptimization
        ) {
          result = await apiService.WellServices.getDepthLogs({
            fields: mnemonics,
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
            correlation:
              this.$store.state.rect.rects[this.correlationIdx]
                .wellRelationOptimization.optimization_type,
            isPartial: true,
            start: this.scaleMin,
            end: this.scaleMax,
          });
          // this.$store.state.disp.rects[this.displayIndex]
          let payload = {
            well_name: this.logId,
            data: {
              depth: result.data.data.dmea,
              time: result.data.data.time,
              min_max: {
                dmea: result.data.minmax.dmea,
                time: result.data.minmax.time,
              },
            },
            displayIndex: this.displayIndex,
          };
          this.$store.dispatch("disp/daysvsdepth", payload);
          // result = result.data;
          // for (let i of mnemonics) {
          //   await this.addData(result, i);
          // }
          this.completeData = { ...this.completeData, ...result.data.data };
          this.data = { ...this.data, ...result.data.data };
          this.minmax = { ...this.minmax, ...result.data.minmax };

          this.$store.dispatch("data/stopLoading");
        }
        apiService.WellServices.getDepthLogs({
          fields: mnemonics,
          well_id: this.wellId,
          wellbore_name: this.wellboreId,
          // isPartial: false,
        })
          .then(async (data) => {
            if (data && data.data && data.data.data) {
              this.completeData = {
                ...this.completeData,
                ...data.data.data,
              };
              this.isDataLoaded = true;
            }
          })
          .catch((e) => {
            console.error("whole data error : ", e);
          });
      } catch (e) {
        console.error("error in multidata : ", e);
        this.$store.dispatch("data/stopLoading");
      }
    },

    setContextIdWidget(e) {
      this.$store.dispatch("disp/setContextId", {
        contextId: this.displayId,
        contextWidgetId: this.id,
        contextType: "widget",
      });
    },
    getRealtimeData() {
      this.liveDisabled = true;

      let test = this.id;
      if (this.data === undefined || this.data === null) {
        this.data = [];
        this.mnemonic_groups = {};
      }
      let topic = this.topic;
      let wells = this.$store.state.data.wells;
      let well = wells[this.wellId];
      if (well) {
        let wellbore = well.wellbores[this.wellboreId];
        let log = wellbore["logs"][this.logType];
        this.logId = log.id;
        let fields = this.getOnScreenMnemonics();
        fields.push("time");
        fields.push("dmea");
        this.fields = fields;
        let topicExists = this.$store.state.live.isSubscribed[topic];
        // if (this.logId) {
        this.$socket.emit("susbcribe", {
          logId: `${this.wellId}~seperation~${this.wellboreId}~seperation~${this.logType}`,
          topic: topic,
          fields,
          id: this.id,
          clientId: this.$clientId,
          logType: this.logType,
        });
        if (!topicExists) {
          this.$store.dispatch("live/setSubscriptionFlag", {
            topic,
            flag: true,
          });
        }
        let soket_timeout_variable = null;
        this.sockets.subscribe(this.$clientId, (sResult) => {
          let result = sResult[this.id];
          if (result) {
            let isLiveDepth = false;
            if (
              this.logType == "depth" &&
              this.completeData["dmea"] &&
              this.completeData["dmea"].length &&
              result["dmea"] &&
              result["dmea"].length
            ) {
              if (
                this.completeData["dmea"][
                  this.completeData["dmea"].length - 1
                ] < result["dmea"][result["dmea"].length - 1]
              )
                isLiveDepth = true;
            }
            if (isLiveDepth)
              for (let i in this.completeData) {
                if (result[i]) {
                  this.completeData[i] = [
                    ...this.completeData[i],
                    ...result[i],
                  ];
                }
              }

            this.scaleMin +=
              result["dmea"][result["dmea"].length - 1] - this.scaleMax;
            if (this.scaleMin < 0) {
              this.scaleMin = this.completeData["dmea"][0];
            }
            this.scaleMax = result["dmea"][result["dmea"].length - 1];

            this.scaleMaxFixed = this.scaleMax;

            if (this.slider && this.slider.noUiSlider)
              this.slider.noUiSlider.updateOptions({
                behaviour: "drag",
                start: [this.scaleMin, this.scaleMax],
                range: {
                  min: this.scaleMinFixed,
                  max: this.scaleMaxFixed,
                },
              });

            if (
              this.$store.state.disp.rects[this.displayIndex]
                .isCorrelationMapStreamSync
            ) {
              this.$store.dispatch("disp/correlationMapSyncScale", {
                display: this.displayId,
                scaleMin: this.scaleMin,
                scaleMax: this.scaleMax,
                trendIndex: this.trendIndex,
              });
            }
            this.zoomIt(
              this.scaleMax,
              this.scaleMin,
              this.feet,
              false,
              "update live"
            );
          }
        });
        topicExists = this.$store.state.live.isSubscribed[topic];
      }
    },

    selectFeet(feet) {
      this.$store.dispatch("rect/selectFeet", {
        widgetId: this.id,
        displayId: this.displayId,
        widgetIndex: this.correlationIdx,
        trendIndex: this.trendIndex,
        value: feet,
      });
    },

    async makeItLive() {
      this.scaleMax = parseInt(this.scaleMaxFixed);

      this.currentFeetRange = 500;
      this.selectFeet(500);
      this.getRealtimeData("make it live");
    },

    selectTimeRange(hrs) {
      this.takeOffSocket();
      if (this.liveDisabled) {
        if (hrs == 2 || hrs == 4) {
          this.getRealtimeData("Select time range");
        } else this.liveDisabled = false;
      }

      // this.socket = io(URL.HOST_URL);

      let scaleMin = 0;
      if (hrs == "default") {
        this.hrs = 4;
        scaleMin = this.scaleMax - 4 * 1000 * 60 * 60;
      } else {
        this.hrs = hrs;
        scaleMin = this.scaleMax - hrs * 1000 * 60 * 60;
      }
      if (this.slider && this.slider.noUiSlider) {
        this.slider.noUiSlider.updateOptions({
          behaviour: "drag",
          start: [scaleMin, this.scaleMax],
          range: {
            min: this.scaleMinFixed,
            max: this.scaleMaxFixed,
          },
        });
        this.scaleMin = scaleMin;
        this.zoomIt(this.scaleMax, this.scaleMin, this.feet, true, "10 called");
      }
    },

    // applyScaleRange() {
    //   this.zoomIt(this.scaleMax, this.scaleMin, this.feet);
    // },

    showProps1(j, i) {
      let cProps = {};
      cProps["show"] = true;
      cProps["curve"] = this.trackConfig.tracks[j][i];

      this.curveProps[j + "-" + i] = cProps;
    },

    closeIt(j, i) {
      this.curveProps[j + "-" + i]["show"] = false;
    },

    generalCurveUpdate(j, i, curve) {
      this.$store.dispatch("rect/setCurveProps", {
        widgetId: this.id,
        displayId: this.displayId,
        widgetIndex: this.correlationIdx,
        trendIndex: this.trendIndex,
        value: {
          j: j,
          i: i,
          curve: curve,
        },
      });
    },

    setCurveProps(j, i, curve) {
      if (this.curveProps[j + "-" + i])
        this.curveProps[j + "-" + i]["show"] = false;

      let stroke = curve.thickness;
      let scaleMin = curve.scaleMin;
      let scaleMax = curve.scaleMax;
      let alertMin = curve.alertMin;
      let alertMax = curve.alertMax;
      let editedName = curve.editedDescription;
      if (stroke != null) {
        stroke = Number(stroke);
        this.trackConfig.tracks[j][i].stroke = stroke;
      }
      if (
        scaleMin != null &&
        scaleMin !== "" &&
        scaleMax != null &&
        scaleMax != ""
      ) {
        scaleMin = Number(scaleMin);
        scaleMax = Number(scaleMax);

        this.trackConfig.tracks[j][i].scale = [scaleMin, scaleMax];
        this.trackConfig.tracks[j][i].scaleType = "manual";
      }
      if (alertMin != null && alertMax != null) {
        alertMin = Number(alertMin);
        alertMax = Number(alertMax);
        this.trackConfig.tracks[j][i].alert = [alertMin, alertMax];
      }
      this.trackConfig.tracks[j][i].color = curve.color;
      if (curve.color) {
        this.defaultColor[j][i] = curve.color;
      }
      if (editedName !== this.trackConfig.tracks[j][i].description) {
        this.trackConfig.tracks[j][i].editedName = editedName;
      }

      this.rerender("setCurveProps");
    },

    deleteCurve(j, i) {
      if (this.curveProps[j + "-" + i])
        this.curveProps[j + "-" + i]["show"] = false;
      if (this.trackConfig.tracks[j]) {
        this.trackConfig.tracks[j].splice(i, 1);

        if (this.trackConfig.tracks[j].length == 0) {
          this.trackConfig.tracks.splice(j, 1);
        }
      }
      // this.configure();

      // this.rerender()
      this.configure();
      this.verticalplots(this.div_id, this.plots, true, "deleteCurve");

      this.zoomIt(
        this.scaleMax,
        this.scaleMin,
        this.feet,
        true,
        "after delete and adding"
      );
    },

    updateScale(values) {
      if (Math.round(values[0])) {
        this.scaleMin = Math.round(values[0]);
        this.scaleMax = Math.round(values[1]);
      } else {
        this.scaleMin = moment(values[0].replace("</br>", " ")).unix() * 1000;
        this.scaleMax = moment(values[1].replace("</br>", " ")).unix() * 1000;
      }
    },

    startDrag(ev, props) {
      ev.dataTransfer.dropEffect = "move";
      ev.dataTransfer.effectAllowed = "move";
      let propString = JSON.stringify(props);
      ev.dataTransfer.setData("elProps", propString);
    },

    async dropCover(event) {
      this.$store.dispatch("rect/setCurveProps", {
        widgetId: this.id,
        displayId: this.displayId,
        widgetIndex: this.correlationIdx,
        trendIndex: this.trendIndex,
        value: {
          j: -1,
          i: -1,
          value: event,
        },
      });
    },

    async onDrop(event, trendNo) {
      if (!event.dataTransfer.getData("elProps")) return;
      let jIndex = -1;
      console.log(
        "~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ tracks before add ~~~~ , ",
        this.trackConfig.tracks
      );
      if (this.trackConfig.tracks.length <= 3) {
        let trackId = "";

        if (this.trackConfig.tracks.length == 3) {
          for (let j = 0; j < this.trackConfig.tracks.length; j++) {
            trackId = this.trackConfig.tracks[j][0].trackId;
            if (this.trackConfig.tracks[j].length == 1) {
              jIndex = j;
              break;
            }
          }
        } else {
          trackId = this.addTrack();
          jIndex = this.trackConfig.tracks.length - 1;
        }
        //
        if (jIndex == -1) {
          if (this.trendIndex == 0)
            this.$toast.error("No more minmonics allowed", {
              position: "top",
              duration: "1000",
            });
          return false;
        }
        let title;
        let min;
        let max;
        let color;
        let fullName;
        let singleTagData = null;
        let target = event.target.id;
        let elProps = JSON.parse(event.dataTransfer.getData("elProps"));

        if (elProps.name == "child") {
          title = elProps.title.name;
          min = elProps.title.min;
          max = elProps.title.max;
          color = elProps.title.color;
          if (elProps.origin === this.compId) {
            this.deleteChildCurve(elProps.trackNbr, elProps.curveNbr);
          }
        } else {
          elProps.title = elProps.title
            ? elProps.title.toLowerCase()
            : elProps.fullName.toLowerCase();
          title = elProps.title;
          singleTagData = await this.getSingleTagData(elProps.title);
        }
        fullName = elProps.fullName;
        this.emptyPlot = false;
        // if (trendNo == this.trendIndex) {

        await this.addNewCurve({
          title,
          min,
          max,
          trackId,
          j: jIndex,
          color,
          fullName,
          trendNo,
        });
        // }

        this.$store.dispatch("rect/setCorrelationConfig", {
          disId: this.displayId,
          widId: this.id.slice(0, -1),
          config: this.trackConfig,
          trendIndex: this.trendIndex,
          option: "onDrop",
        });
        this.setTrackWidth(this.trackConfig.tracks.length);
      } else {
        if (this.trendIndex == 0)
          this.$toast.error("No more minmonics allowed", {
            position: "top",
            duration: "1000",
          });
      }

      console.log(
        "~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ tracks after add ~~~~ , ",
        this.trackConfig.tracks
      );
    },
    deleteChildCurve(trackNbr, curveNbr) {
      this.trackConfig.tracks[trackNbr].splice(curveNbr, 1);
      if (this.trackConfig.tracks[trackNbr].length == 0) {
        this.trackConfig.tracks.splice(trackNbr, 1);
        this.totTracks.splice(trackNbr, 1);
      }
    },
    setTrackWidth(nbr) {
      let usableWidth =
        this.templateWidth - this.sliderWidth - this.leftbarWidth;
      this.singleTrackWidth = usableWidth / nbr;

      if (this.singleTrackWidth > this.trackWidth) {
        this.singleTrackWidth = this.trackWidth;
      }
    },
    setTrackHeight() {
      this.singleTrackHeight = this.templateHeight - this.bheight - 30;
    },
    addNewCurve(title) {
      let currentCurve = {};
      currentCurve.name = title.title;
      currentCurve.fullName = title.fullName;

      if (title.j != null) {
        currentCurve.trackNbr = title.j;
        if (this.trackConfig.tracks.length > 0) {
          currentCurve.curveNbr = this.trackConfig.tracks[title.j].length;
        }
      } else {
        currentCurve.trackNbr = 0;
        currentCurve.curveNbr = 0;
      }

      if (title.color != null) {
        currentCurve.color = title.color;
      } else {
        try {
          let code = 0;
          if (this.trackConfig.tracks[currentCurve.trackNbr]) {
            code = this.trackConfig.tracks[currentCurve.trackNbr].length;
          }
          console.log(
            "🚀 ➽ file: CorrelationTrend.vue:1812  ➽ addNewCurve  ➽ code ⏩",
            currentCurve.trackNbr,
            code
          );
          currentCurve.color = this.defaultColor[currentCurve.trackNbr][code];
        } catch (error) {
          currentCurve.color = this.getRandomColor();
        }
      }

      currentCurve.trackId = title.trackId;
      currentCurve.min = title.min;
      currentCurve.max = title.max;
      currentCurve.scale = [title.min, title.max];
      currentCurve.scaleType = "auto";
      currentCurve.stroke = 2;

      if (this.trackConfig.tracks[currentCurve.trackNbr].length < 2)
        this.trackConfig.tracks[currentCurve.trackNbr].push(currentCurve);
    },
    getRandomColor() {
      let letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    createLeftBar(div_id, templates) {
      try {
        let template_for_plotting = templates[0];
        let template_overall = template_for_plotting[0]["curve_box"];
        let leftbarbg = template_overall.leftbarbg;
        let left_bar_bg_ =
          this.darkDark !== "white" ? "light_trend-mode" : "bg-dark";
        let yAxisHolder = d3
          .select("#" + div_id + this.trendIndex + "div_id")
          .append("div");
        const trendtimeStampCol = this.darkDark !== "white" ? "dv" : "ddv";
        yAxisHolder
          .attr("class", "leftbarcontainer")
          .attr("class", left_bar_bg_)
          .style("display", "inline-block")
          .style("vertical-align", "top")
          // .style("background", leftbarbg)
          .style("margin-right", "0px")
          .style("fill", this.darkDark !== "white" ? "white" : "black");

        yAxisHolder.style("width", "100px");
        let depth_min_sec = "";
        let depth_max_sec = "";
        // if (!this.data["time"]) {
        //   depth_min_sec = d3.min(this.data["time"]);
        //   depth_max_sec = d3.max(this.data["time"]);
        // }

        this.depthMaxValue = parseInt(depth_max_sec);
        this.depthMinValue = parseInt(depth_min_sec);
        if (
          this.trendIndex == 0 &&
          isNaN(this.depthMaxValue) &&
          isNaN(this.depthMinValue)
        ) {
          yAxisHolder
            .append("h1")
            .style("position", "absolute")
            .style("transform-origin", "center")
            .style("transform", "translate(-4.5vw, 25vh) rotate(270deg)")
            .text("TIME AXIS NOT AVAILABLE")
            .style("font-size", "14px");
        }

        this.leftRangeSliderSet = true;
        let height_components = template_overall["height"];

        this.yLeftScale = d3
          .scaleLinear()
          .domain([this.scaleMax, this.scaleMin])
          .range([height_components - 12, 0]);
        // .nice();
        if (!isNaN(this.depthMaxValue) && !isNaN(this.depthMinValue))
          this.yLeftScaleCompare = d3
            .scaleTime()
            .domain([this.depthMaxValue, this.depthMinValue])
            .range([height_components - 12, 0]);
        // .nice();
        console.log(
          "checking depth::::::~~~~~~~~~~~~~~~",
          this.scaleMax,
          this.scaleMin
        );
        let leftbarOuter = yAxisHolder
          .append("div")
          .attr("class", "leftbar-outer")
          .style("width", "100px");

        let leftbarInner = leftbarOuter
          .append("div")
          .attr("class", `leftbar-inner-${div_id}`)
          .style("max-height", height_components + "px")
          .style("scrollbar-width", "thin")
          .style("width", "100px")
          .style("height", height_components + "px")
          .style("direction", "rtl")
          .style("scrollbar-track", "red");

        let svg = leftbarInner
          .append("svg")
          .style("width", "60px")
          .attr("height", height_components);
        this.scaleMax = parseInt(this.scaleMax);
        this.scaleMin = parseInt(this.scaleMin);
        let tickInterval = (this.scaleMax - this.scaleMin) / 10;
        let depthTicks = [];
        for (
          let tick = this.scaleMin;
          tick <= this.scaleMax;
          tick = tick + tickInterval
        ) {
          depthTicks.push(tick);
        }

        depthTicks.push(this.scaleMax);
        let tickIntervalCompare = parseInt(
          (this.depthMaxValue - this.depthMinValue) / 10
        );

        let timeTicks = [];
        for (
          let tick = this.depthMinValue;
          tick < this.depthMaxValue;
          tick = tick + tickIntervalCompare
        ) {
          timeTicks.push(tick);
        }
        timeTicks.push(this.depthMaxValue);
        let yTicks = null;
        let yTicksCompare = null;
        if (!isNaN(this.depthMaxValue) && !isNaN(this.depthMinValue))
          if (this.data["dmea"] && this.data["dmea"].length > 0) {
            yTicks = d3
              .axisLeft()
              .scale(this.yLeftScaleCompare)
              .tickValues(timeTicks)
              .tickSize(-65);
            // yTicks.tickFormat(d3.timeFormat("%Y-%m-%d %H:%M:%S"));
          }
        yTicksCompare = d3
          .axisLeft()
          .scale(this.yLeftScale)
          .tickValues(depthTicks)
          .tickSizeInner(-85);

        if (!isNaN(this.depthMaxValue) && !isNaN(this.depthMinValue))
          if (
            this.data["dmea"] &&
            this.data["dmea"].length > 0 &&
            this.trendIndex == 0
          ) {
            let timeAxis = svg
              .append("g")
              .attr("transform", "translate(5,0)")
              .call(yTicks);

            timeAxis.selectAll("line").attr("transform", "translate(10,0)");
            timeAxis.selectAll("path").attr("transform", "translate(10,0)");
            timeAxis.selectAll("text").call(function (t) {
              t.each(function (d) {
                // for each one
                let self = d3.select(this);
                let s = [];
                s[1] = moment(new Date(d)).format("MM-DD-YYYY");
                s[0] = moment(new Date(d)).format("HH:mm:ss");
                // self.path("")
                self.text(""); // clear it out

                self
                  .append("tspan")
                  .attr("x", 20)
                  .attr("dy", "-0.5em")
                  .attr("class", trendtimeStampCol)
                  .text(s[1]);
                self
                  .append("tspan") // insert two tspans
                  .attr("x", 25)
                  .attr("dy", "1.5em")
                  .text(s[0]);
              });
            });
          }

        let depthAxis = svg
          .append("g")
          .attr("transform", "translate(-10,0)")
          .call(yTicksCompare);

        depthAxis
          .selectAll("line")
          .attr("transform", "translate(10,0)")
          .style("opacity", "0.2");

        depthAxis
          .selectAll("path")
          .style("opacity", "0.2")
          .attr("transform", "translate(10,0)");

        depthAxis
          .selectAll("text")
          .attr("x", "25")
          .attr("dy", "1em")
          .style("font-size", "0.7rem");
      } catch (error) {
        console.error("errro in left bar ", error);
      }
    },
    verticalplots(
      div_id,
      templates,
      show_all = true,
      from,
      remove_preexisting = true
    ) {
      console.log(
        "checking depth::::::~~~~~~~~~~~~~~~~~~~~&&&&&&&&&&&&",
        from,
        "#" + div_id + this.trendIndex + "div_id"
      );
      let noDIV = d3
        .select("#" + div_id + this.trendIndex + "div_id")
        .selectAll("div")
        .remove();
      let noSVG = d3
        .select("#" + div_id + this.trendIndex + "div_id")
        .selectAll("svg")
        .remove();
      // console.log("checking depth::::::~~~~~~~~~~~~~~~~~~~~",from,noSVG,noDIV)
      if (templates.length > 0) {
        this.createLeftBar(div_id, templates, "vplots");

        // this.createAlertBar(div_id, templates);
      }
      let new_templates = [];
      curvesMinsMaxes = [];
      for (let i = 0; i < templates.length; i++) {
        let track_bg_s = this.darkDark !== "white" ? "" : this.bgColor;
        let curvebox_holder = d3
          .select("#" + div_id + this.trendIndex + "div_id")
          .append("div");
        curvebox_holder
          .style("vertical-align", "middle")
          .style("background", track_bg_s)
          .attr("id", templates[i][0]["curve_box"]["trackId"]);

        //// to control view of plots on site, user can show-hide by triggering action here. However, if show_all = false then none will show, so developer will need to change CSS with another function one by one!
        if (show_all) {
          curvebox_holder.style("display", "inline-block");
          curvebox_holder.style("margin-right", "0px");
        } else {
          curvebox_holder.style("display", "none");
        }
        // templates[i][0]["curve_box"]["div_id"] = div_id + "curvebox_holder" + i;

        templates[i][0]["curve_box"]["div_id"] =
          templates[i][0]["curve_box"]["trackId"];

        new_templates.push(templates[i]);
        let template = templates[i];
        this.curveBox(template, i, div_id);
      }
    },
    mousemove(e, trendIndex) {
      let offsetYPercent =
        (e.offsetY / e.target.getBoundingClientRect().height) * 100;
      let timeRangeInSeconds = this.scaleMax - this.scaleMin;
      let tooltipShift = timeRangeInSeconds / 4;
      let data = this.data;
      for (let focusAxis of this.focusAxisList) {
        if (!focusAxis) {
          return;
        }
        let focus = focusAxis["focus"];
        let y = focusAxis["y"];
        let x = focusAxis["x"];
        // let data = focusAxis['data'];
        let depth_curve_name = focusAxis["depth_curve_name"];
        let width = focusAxis["width"];
        let curve_names = focusAxis["curve_names"];
        let mouseover_curvename = focusAxis["mouseover_curvename"];
        let height = focusAxis["height"];
        let bisectDate = d3.bisector(function (d) {
          return d;
        }).left; // **

        let y0 = 0;
        let index = 0;

        if (this.logType == "depth") {
          y0 = y.invert(d3.pointer(e)[1]);
        } else {
          y0 = moment(y.invert(d3.pointer(e)[1])).unix() * 1000;
        }
        this.$store.dispatch("rect/yAxisValue", {
          yAxisValue: y0,
          widgetId: this.id,
          displayId: this.displayId,
          widgetIndex: this.correlationIdx,
          trendIndex: this.trendIndex,
        });
        try {
          // if("45be09c1-937c-4fdf-9640-406cad0824d5" == this.logId)

          index = bisectDate(data[depth_curve_name], y0, 1);
        } catch (error) {}

        if (!y0 || !index || !data[depth_curve_name][index]) {
          continue;
        }
        focus.style("display", null);
        focus.transition().duration(200).style("opacity", 1);

        let d = {};
        for (let i in data) {
          d[i] = data[i][index];
        }

        // let d = y0 - d0[depth_curve_name] > d1[depth_curve_name] - y0 ? d1 : d0;

        //// fixed value along y axis
        let fixed_x_value = width * 0.8;
        //// depth value
        let num = d[depth_curve_name] / this.scaleMax;

        focus
          .select("text.y2")
          .attr(
            "transform",
            // "translate(" + x(d[mouseover_curvename]) + "," +
            "translate(" +
              (fixed_x_value - 30) +
              "," +
              `${
                offsetYPercent > 85
                  ? y(d[depth_curve_name] - tooltipShift)
                  : offsetYPercent < 15
                  ? y(d[depth_curve_name] + tooltipShift)
                  : y(d[depth_curve_name])
              })`
            // `${y(d[depth_curve_name])})`
          )
          .text(d[depth_curve_name]);

        //// curve value
        for (let i = 0; i < curve_names.length; i++) {
          let text = d[curve_names[i]];
          if (curve_names[i] == "activitycode") {
            switch (d[curve_names[i]]) {
              case 1:
                text = "ROT DRLG";
                break;
              case 2:
                text = "SLD DRLG";
                break;
              case 3:
                text = "WASHING";
                break;
              case 4:
                text = "UNKNOWN";
                break;
              case 10:
                text = "WASHDOWN";
                break;
              case 11:
                text = "REAMING";
                break;
              case 12:
                text = "BACKREAM";
                break;
              case 13:
                text = "CONNECTION";
                break;
              case 14:
                text = "TIH-DRLG";
                break;
              case 15:
                text = "POOH-DRLG";
                break;
              case 16:
                text = "CIRC WITH ROT";
                break;
              case 17:
                text = "CIRCULATION";
                break;
              case 30:
                text = "STATIONARY";
                break;
              case 5:
                text = "TIH";
                break;
              case 6:
                text = "POOH";
                break;
              case 20:
                text = "WASHING-TIH";
                break;
              case 21:
                text = "REAM-TIH";
                break;
              case 22:
                text = "BACK REAM-TIH";
                break;
              case 23:
                text = "CONN.TIH";
                break;
              case 24:
                text = "CIRC WITH ROT-TIH";
                break;
              case 25:
                text = "CIRC.W/ROT-TIH";
                break;

              default:
                break;
            }
          }
          if (this.zero.indexOf(curve_names[i]) > -1 && text) {
            text = parseInt(text);
          } else if (this.triple.indexOf(curve_names[i]) > -1 && text) {
            text = parseFloat(text).toFixed(3);
          } else {
            if (curve_names[i].includes("capacity")) {
              text = parseFloat(text).toFixed(4);
            } else {
              text = parseFloat(text).toFixed(2);
            }
          }
          focus
            .select(`.data${i + 1}`)
            .attr(
              "transform",
              "translate(" +
                (fixed_x_value - 45) +
                "," +
                `${
                  offsetYPercent > 85
                    ? y(d[depth_curve_name] - tooltipShift)
                    : offsetYPercent < 15
                    ? y(d[depth_curve_name] + tooltipShift)
                    : y(d[depth_curve_name])
                })`
              // `${y(d[depth_curve_name])})`
            )
            .text(text);
        }
        focus
          .select(".x")
          .attr(
            "transform",
            "translate(" + x(d[mouseover_curvename]) + "," + 0 + ")"
          )
          .attr("y2", height);
        //// circle y class part 2
        focus
          .select(".y")
          .attr(
            "transform",
            "translate(" +
              x(d[mouseover_curvename]) +
              "," +
              y(d[depth_curve_name]) +
              ")"
          )
          .text(d[mouseover_curvename]);
        focus
          .select(".yl")
          .attr(
            "transform",
            "translate(" + 0 + "," + y(d[depth_curve_name]) + ")"
          );

        // if (this.trendIndex == 0)
        //   d3.select('#horizontal_line_tooltip_line').attr('y1', y(d[depth_curve_name]))

        // if (this.trendIndex == 1)
        //   d3.select('#horizontal_line_tooltip_line').attr('y2', y(d[depth_curve_name]))

        // if (this.trendIndex == 1)
        //   d3.select('#horizontal_line_tooltip_line1').attr('y1', y(d[depth_curve_name]))

        // if (this.trendIndex == 2)
        //   d3.select('#horizontal_line_tooltip_line1').attr('y2', y(d[depth_curve_name]))
        // .text(d[mouseover_curvename]);
        let back_height = Math.max(curve_names.length * 22, 66);

        focus
          .select(".background")
          .attr(
            "transform",
            "translate(" +
              (fixed_x_value - 50) +
              "," +
              `${
                offsetYPercent > 85
                  ? y(d[depth_curve_name] - tooltipShift)
                  : offsetYPercent < 15
                  ? y(d[depth_curve_name] + tooltipShift)
                  : y(d[depth_curve_name])
              })`
            // `${y(d[depth_curve_name])})`
          )
          .attr("height", back_height)
          .transition()
          .duration(5000)
          .style("opacity", 1);
      }
    },
    mouseout(e) {
      // for (let focusAxis of this.focusAxisList) {
      //   let focus = focusAxis["focus"];
      //   focus
      //     .style("display", "none")
      //     .transition()
      //     .duration(250)
      //     .style("opacity", 0);
      // }
      // d3.select('#horizontal_line_tooltip_line').attr('y1', -10).attr('y2', -10)
      // d3.select('#horizontal_line_tooltip_line1').attr('y1', -10).attr('y2', -10)
    },
    mouseover(focus) {
      focus
        .style("display", "none")
        .transition()
        .duration(250)
        .style("opacity", 0);
    },
    mousescroll(e, trendIndex) {
      console.log(
        " this.$store.state.disp.rects[this.displayIndex].locker ",
        this.$store.state.disp.rects[this.displayIndex].locker,
        this.trendIndex,
        trendIndex
      );
      if (
        !this.$store.state.disp.rects[this.displayIndex].locker &&
        this.trendIndex != trendIndex
      ) {
        return true;
      }

      // if (this.slider && this.slider.noUiSlider) {
      //   this.slider.noUiSlider.updateOptions({
      //     behaviour: "drag",
      //     start: [this.scaleMin, this.scaleMax],
      //     range: {
      //       min: this.scaleMinFixed,
      //       max: this.scaleMaxFixed,
      //     },
      //   });

      // if (this.mousewheeltimeout) clearTimeout(this.mousewheeltimeout);
      // this.mousewheeltimeout = setTimeout(() => {
      let scaleMin = 0;
      if (this.liveDisabled) {
        this.takeOffSocket();
        this.liveDisabled = false;
      }

      let scroll_amount =
        (this.scaleMax - this.scaleMin) / 10 > 0
          ? (this.scaleMax - this.scaleMin) / 10
          : 10;

      // downscroll code
      if (e.deltaY > 0) {
        if (this.scaleMaxFixed - scroll_amount > this.scaleMax) {
          this.scaleMax = this.scaleMax + scroll_amount;
          this.scaleMin = this.scaleMin + scroll_amount;
        }
      }

      // upscroll code
      if (e.deltaY < 0) {
        if (this.scaleMinFixed < this.scaleMin - scroll_amount) {
          this.scaleMax = this.scaleMax - scroll_amount;
          this.scaleMin = this.scaleMin - scroll_amount;
        }
      }

      console.log(
        "🚀 ➽ file: CorrelationTrend.vue:2343  ➽ mousescroll  ➽ scroll_amount ⏩",
        scroll_amount
      );
      this.zoomIt(this.scaleMax, this.scaleMin, this.feet, false, "mousewheel");
      // clearTimeout(this.mousewheeltimeout);
      // }, 100);
      // }
    },
    curveBox(template_for_plotting, index, divId) {
      try {
        let template_overall = template_for_plotting[0]["curve_box"];
        let template_components = template_for_plotting[0]["components"];

        let template_curves = template_components[0]["curves"][0];

        let template_lines = template_components[0]["lines"];
        let template_rectangles = template_components[0]["rectangles"];
        let title = "";

        /// Parameters that define shape & size of overall curve box
        let width = template_overall["width"];
        let height_multiplier_components = 0.95;
        if (template_overall["height_multiplier_components"]) {
          height_multiplier_components =
            template_overall["height_multiplier_components"];
        }

        let height_components = template_overall["height"];
        // let height = template_overall['height'] * height_multiplier_components;
        let height = height_components * height_multiplier_components;
        let margin = template_overall["margin"];
        let gridlines = template_overall["gridlines"];
        let gridlines_color = template_overall["gridlines_color"];
        let gridlines_stroke_width = template_overall["gridlines_stroke_width"];

        let mouseover_depth_or_depth_and_curve =
          template_overall["mouseover_depth_or_depth_and_curve"];
        let mouseover_curvename = template_overall["mouseover_curvename"]; //// default is first curve
        let mouseover_color_or_default_which_is_curve_color =
          template_overall["mouseover_color_or_default_which_is_curve_color"];
        let secondary_depth_exist = "no"; // THIS IS NOT YET EXISTING IN PLOTTING INPUT JSON SO HARDCODING FOR NOW
        //// Data is in d3.js form. An array of objects consisting of single level key:value pairs
        let data = this.data;

        if (data == null || (data["dmea"] && data["dmea"].length == 0)) {
          console.info("no data");
          this.isRerendering = false;
          // setTimeout(() => {
          //   this.zoomIt(
          //     this.scaleMax,
          //     this.scaleMin,
          //     this.feet,
          //     true,
          //     "12 called"
          //   );
          // }, 1000);
          return;
        }

        //// Variables related to curves, these should all be arrays with one or more values!
        let curve_names = template_curves["curve_names"];
        let curve_colors = template_curves["curve_colors"];

        let curve_stroke_dasharray = template_curves["curve_stroke_dasharray"];
        let curve_name = curve_names[0];
        let curve_color = curve_colors[0];
        let curve_units = template_curves["curve_units"];
        let scale_linear_log_or_yours =
          template_curves["scale_linear_log_or_yours"];
        if (template_curves["curve_units"]) {
          curve_units = template_curves["curve_units"];
        } else {
          curve_units = "";
        }
        let curveScales = template_curves["scale"];
        let curveScaleType = template_curves["scaleType"];

        //////// NEED TO MAKE THIS FLAG IN INPUT PLOTTING JSON
        let flag_for_single_scale_or_independent_scales =
          template_overall["grouped_or_independent_x_scales"];
        let grouped_or_independent_x_scale =
          template_overall["grouped_or_independent_x_scales"];
        //// The depth_curve_name needs to be the same for all curves plotted!
        let depth_curve_name = "";
        if (
          template_curves["depth_curve_name"].length > 1 &&
          typeof template_curves["depth_curve_name"] == "object" &&
          template_curves["depth_curve_name"][0] !==
            template_curves["depth_curve_name"][1]
        ) {
          depth_curve_name =
            "depth_curve_name is not the same in two or more curves";
        } else {
          depth_curve_name = template_curves["depth_curve_name"];
        }
        let depth_type_string = "";
        if (
          template_curves["depth_type_string"].length > 1 &&
          typeof template_curves["depth_type_string"] == "object" &&
          template_curves["depth_type_string"][0] !=
            template_curves["depth_type_string"][1]
        ) {
          depth_type_string =
            "depth type string is not the same in two or more curves";
        } else if (template_curves["depth_type_string"][0] == "") {
          depth_type_string = "";
        } else if (template_curves["depth_type_string"]) {
          depth_type_string = "- " + template_curves["depth_type_string"];
        }
        let depth_units_string = "";
        if (
          template_curves["depth_units_string"] &&
          template_curves["depth_units_string"][0] !== ""
        ) {
          depth_units_string = "- " + template_curves["depth_units_string"];
        }
        ///// THIS LINE BELOW DOESN"T MAKE ANY SENSE, CHANGE ////
        let div_id = template_overall["div_id"];
        if (template_overall["div_id"]) {
          div_id = template_overall["div_id"];
        } else {
          return "there_was_no_div_id_in_the_template";
        }
        let trendIndex = this.trendIndex;
        d3.select("#" + div_id + trendIndex + "div_id")
          .selectAll("*")
          .remove();
        ///////// NEED TO FIX DEPTHS AS THERE ARE MULTIPLE DEPTH LIMITS AND THEY NEED TO BE CALCULATED PROPERLY !!!!! //////////////////////////
        //       //// Calculate depth min and max if depth min and/or max is not given explicitly in the template
        let depth_min;
        let depth_max;
        if (
          !template_curves["depth_limits"] ||
          template_curves["depth_limits"][0]["min"] == "autocalculate"
        ) {
          depth_min = this.scaleMin;
        } else {
          depth_min = template_curves["depth_limits"][0]["min"];
        }
        //// max depth
        if (
          !template_curves["depth_limits"] ||
          template_curves["depth_limits"][0]["max"] == "autocalculate"
        ) {
          depth_max = this.scaleMax;
        } else {
          depth_max = template_curves["depth_limits"][0]["max"];
        }
        // [depth_max,depth_min]
        //// Apply depth min and max to incoming well log data
        //// To save time, we'll first check if the first object in the array had as depth that is smaller than min
        //// and check if the last object in the array has a depth that is larger than the max, if not. we do nothing.
        if (data.length > 0 && data != null) {
          if (
            data[0][depth_curve_name] > depth_min &&
            data[data.length - 1][depth_curve_name] < depth_max
          ) {
          } else {
            data = data.filter(function (objects) {
              return (
                objects[depth_curve_name] > depth_min &&
                objects[depth_curve_name] < depth_max
              );
            });
          }
          // let depth_min = -1000000
          // let depth_max = 1000000
          if (data.length > 0) {
            if (
              template_curves["min_depth"][0] == "autocalculate" ||
              template_curves["min_depth"] == "autocalculate"
            ) {
              depth_min = data[0][depth_curve_name];
            } else {
              depth_min = template_curves["min_depth"];
            }
            if (
              template_curves["max_depth"][0] == "autocalculate" ||
              template_curves["max_depth"] == "autocalculate"
            ) {
              depth_max = data[data.length - 1][depth_curve_name];
            } else {
              depth_max = template_curves["max_depth"];
            }
          }
        }
        let svg = "";
        let svg_holder = "";
        let svg_header = "";

        svg = d3
          .select("#" + this.div_id + this.trendIndex + "div_id")
          .append("svg")
          .attr(
            "style",
            `border: 1.5px solid ${
              this.darkDark !== "white" ? "var(--nav1BgClr)" : "#c1c0c0"
            }`
          )
          .style("max-height", height_components + "px")
          .style("height", height_components + "px");

        //////////////  Calculate Axis & Scales =>//////////////
        //// Need to handle: zero curves, arbitrary number of curves, and min/max of all curves in single axis.
        //// For zero curves, need to look into rectange and lines for x scales maybe?
        //// Need to handle scales in linear, log, or arbitary user-provided scale.
        //// Also, need x function for gridlines! so....?
        //////////////  Calculate x domain extent for one or more than one curve, used in scaling =>//////////////
        let mins = [];
        let maxes = [];
        mins = mins;
        maxes = maxes;
        for (let i = 0; i < curve_names.length; i++) {
          let curveObj = {
            name: "",
            min: "",
            max: "",
          };
          let curveName = curve_names[i];
          curveObj.name = curve_names[i];
          let curveScale = curveScales[i];
          let scaleType = curveScaleType[i];
          let min_this = "";
          let max_this = "";

          if (
            scaleType == "manual" &&
            (curveScale != null || curveScale != undefined)
          ) {
            if (curveScale[0] != null || curveScale[0] != "") {
              min_this = curveScale[0];
            } else {
              min_this = this.minmax[curve_names[i]].min;
              curveScale[0] = min_this;
            }
            if (curveScale[1] != null || curveScale[1] != "") {
              max_this = curveScale[1];
            } else {
              max_this = this.minmax[curve_names[i]].max;
              curveScale[1] = max_this;
            }
          } else {
            let currentRange = defaultRanges.ranges.find(
              (data) => data.name == curve_names[i]
            );

            if (currentRange) {
              min_this = currentRange.low;
              max_this = currentRange.high;
            } else {
              // min_this = this.minmax[curve_names[i]].min;
              // max_this = this.minmax[curve_names[i]].max;
              min_this = 0;
              max_this = 500;
            }
            curveScale[0] = min_this;
            curveScale[1] = max_this;
          }
          curveObj.min = min_this;
          curveObj.max = max_this;
          curvesMinsMaxes.push(curveObj);

          mins.push(min_this);
          maxes.push(max_this);
          mins = mins;
          maxes = maxes;
        }

        let min_all_curves = d3.min(mins);
        let max_all_curves = d3.max(maxes);
        if (curve_names.length == 0) {
          //// THIS NEEDS TO CHANGE TO LOOK AT RECTANGLE AT SOME POINT!!!!!!
          min_all_curves = 0;
          max_all_curves = 100;
        }
        //////////////  Calculate x domain extent for one or more than one curve, used in scaling =>//////////////
        let x_func;
        let x;
        let y = this.yLeftScale;

        let yAxis2 = (g) =>
          g
            .attr("transform", `translate(${margin.left - 35},0)`)
            .call(d3.axisLeft(y))
            .call((g) => g.select(".domain"));

        //////////////  Building curvebox parts that aren't header. First define size & title =>//////////////

        x = d3
          .scaleLinear()
          .domain([min_all_curves, max_all_curves])
          .range([margin.left, width - margin.right]);
        // .nice();
        svg.attr("class", `components components-${index}`);
        svg.attr("width", width).attr("height", height - 100);
        svg.attr("id", "svg" + div_id).on("mousewheel", (e) => {
          if (!this.isShowTooltip) {
            this.$store.dispatch("rect/mousescroll", {
              event: e,
              widgetId: this.id,
              displayId: this.displayId,
              widgetIndex: this.correlationIdx,
              trendIndex: this.trendIndex,
            });
          }
        });

        svg.style("margin", "0");

        let distance_from_top = -15;
        if (title !== "") {
          svg
            .append("text") //
            .attr("x", margin.left / 3 + width / 2)
            .attr("y", 0 + -distance_from_top)
            .attr("text-anchor", "middle")
            .style("font-size", template_overall["title"]["title_font_size"])
            .text(title);
        }
        if (gridlines == "yes") {
          let xGrid = d3
            .scaleLinear()
            .domain([0, 500])
            .range([margin.left, width - margin.right]);
          let gridlines_obj = d3
            .axisTop()
            // .ticks((width - margin.left - margin.right) / 25)
            .ticks(4)
            .tickFormat("")
            .tickSize(-height - 50)
            .scale(xGrid);

          svg
            .append("g")
            .attr("class", "grid")
            .call(gridlines_obj)
            .style(
              "stroke",
              this.darkDark !== "white" ? gridlines_color : "black"
            )
            .style("stroke-dasharray", "5 5")
            .style("z-index", -1)
            .style("stroke-width", gridlines_stroke_width);
          let yGrid = d3
            .scaleLinear()
            .domain([this.scaleMax, this.scaleMin])
            .range([height_components, -10]);
          let horizontalLines = d3
            .axisLeft()
            .tickFormat("")
            .tickSize((-width + margin.left + margin.right) * 1)
            .ticks(22)
            .scale(yGrid);

          // gYAxis.selectAll('.tick line').attr('opacity', 0.1)
          //   .style.color = "blue";
          svg
            .append("g")
            .attr("class", "grid")
            // .attr("class", this.darkDark !== 'white'    ? 'grid_dark' : 'grid_light')
            // .attr("transform", "translate(60, -10 )")
            .call(horizontalLines)
            .style("stroke", gridlines_color)
            .style(
              "color",
              this.darkDark !== "white" ? gridlines_color : "black"
            )
            // .style("color", "#424141")
            .style("stroke-width", gridlines_stroke_width)
            // .style("opacity", 0.6)
            .style("z-index", 0)
            .attr("transform", "translate(0, 0 )");
        }

        ////////////////      TOOLTIP Part 1       ///////////////////

        if (!this.isZoom && curve_names.indexOf("alert") > -1) {
          //// appends start of mouseover rectange used for showing hover content
          let focus = svg.append("g").style("opacity", 0);

          focus
            .append("rect")
            .attr("class", "background")
            .attr("fill", tooltipbg)
            .attr("height", 130)
            .attr("width", 95)
            .attr("rx", 8)
            .attr("opacity", 1);
          focus
            .append("line")
            .attr("class", "yl")
            .style("stroke", this.darkDark !== "white" ? "white" : "black")
            .style("stroke-array", "10")
            .style("opacity", 1)
            .attr("x1", 0)
            .attr("x2", height);
          if (
            mouseover_depth_or_depth_and_curve == "depth" ||
            mouseover_depth_or_depth_and_curve == "depth_and_curve"
          ) {
            focus
              .append("text")
              .attr("class", "y2")
              .attr("dx", 6)
              .attr("dy", "-.3em")
              .style("font-size", "0.85em")
              .style("z-index", 999999999)
              .style("stroke", this.darkDark !== "white" ? tooltipbg : "black");
          }
          //// curve value on hover

          if (
            mouseover_depth_or_depth_and_curve == "curve" ||
            mouseover_depth_or_depth_and_curve == "depth_and_curve"
          ) {
            for (let i = 0; i < curve_colors.length; i++) {
              focus
                .append("text")
                .attr("class", `data${i + 1}`)
                .attr("dx", 1)
                .attr("dy", `${0.5 * (i + 1)}cm`)
                .style("font-size", "1.5em")
                .style("fill", "black")
                .style("z-index", "black")
                .style("stroke", curve_colors[i])
                .style("stroke-width", "0.5px");
            }
          }
          // append the rectangle to capture mouse               // **********
          svg
            .append("rect") // **********
            .attr("width", width) // **********
            .style("height", height_components + "px")
            .attr("height", 10) // **********
            .attr("height", height) // **********
            .attr("id", "rect" + div_id)
            .style("fill", "none") // **********
            .style("pointer-events", "all") // **********

            .on("mouseover", (e) => {
              if (this.isShowTooltip)
                this.$store.dispatch("rect/mousemove", {
                  event: e,
                  widgetId: this.id,
                  displayId: this.displayId,
                  widgetIndex: this.correlationIdx,
                  trendIndex: this.trendIndex,
                });
              // this.mouseover(focus);
              // focus.style('display', 'none').transition().duration(250).style('opacity', 0);
            })
            .on("mouseout", (e) => {
              // if (this.isShowTooltip) this.mouseout(e);
              if (this.isShowTooltip)
                this.$store.dispatch("rect/mouseout", {
                  event: e,
                  widgetId: this.id,
                  displayId: this.displayId,
                  widgetIndex: this.correlationIdx,
                  trendIndex: this.trendIndex,
                });
            })
            .on("mousemove", (e) => {
              // if (this.isShowTooltip) this.mousemove(e);
              if (this.isShowTooltip)
                this.$store.dispatch("rect/mousemove", {
                  event: e,
                  widgetId: this.id,
                  displayId: this.displayId,
                  widgetIndex: this.correlationIdx,
                  trendIndex: this.trendIndex,
                });
            })
            .on("mousewheel", (e) => {
              // if (this.isShowTooltip) {
              //   this.$store.dispatch("rect/mousescroll", {
              //     event: e,
              //     widgetId: this.id,
              //     displayId: this.displayId,
              //     widgetIndex: this.correlationIdx,
              //     trendIndex: this.trendIndex,
              //   });
              // }
              // if (this.isDataLoaded) {

              //   this.$store.dispatch("rect/mousescroll", {
              //     event: e,
              //     widgetId: this.id,
              //     displayId: this.displayId,
              //     widgetIndex: this.correlationIdx,
              //     trendIndex: this.trendIndex,
              //   });
              // }
            });
          this.focusAxisList[index] = {
            focus,
            x,
            y,
            curve_colors,
            curve_names,
            mouseover_curvename,
            depth_curve_name,
            width,
            height,
          };
        }

        if (
          this.isZoom &&
          curve_names.indexOf("stickslipindex") == -1 &&
          curve_names.indexOf("washoutindex") == -1
        ) {
          let updateChart = async (event) => {
            let extent = event.selection;
            let min = y.invert(extent[0]);
            let max = y.invert(extent[1]);
            // min = moment(min).unix() * 1000;
            // max = moment(max).unix() * 1000;
            if (this.slider && this.slider.noUiSlider) {
              this.slider.noUiSlider.updateOptions({
                behaviour: "drag",
                start: [parseInt(min), parseInt(max)],
                range: {
                  min: this.scaleMinFixed,
                  max: this.scaleMaxFixed,
                },
              });
              this.scaleMin = parseInt(min);
              this.scaleMax = parseInt(max);
              this.zoomIt(
                this.scaleMax,
                this.scaleMin,
                this.feet,
                true,
                "13 called"
              );
            }
          };

          let updateStart = async (event) => {
            event.sourceEvent.stopPropagation();
          };

          let brushZoom1 = d3
            .brushY()
            .extent([
              [0, 0],
              [width, height],
            ])
            .on("start", updateStart)
            .on("end", (e) => {
              this.$store.dispatch("rect/zoomEnd", {
                event: e,
                widgetId: this.id,
                displayId: this.displayId,
                widgetIndex: this.correlationIdx,
                trendIndex: this.trendIndex,
              });
            });
          svg.call(brushZoom1);
        }

        let x_functions_for_each_curvename = {};
        for (let k = 0; k < curve_names.length; k++) {
          if (!this.data[curve_names[k]]) {
            this.isRerendering = false;
            return false;
          }
          if (curve_names[k] == "alert") {
            let g = svg.append("g").attr("height", height_components);

            let startActcCode = 0;
            let endActcCode = 0;
            let startActcTime = 0;
            let endActcTime = 0;
            let totalRecords = data["dmea"].length - 1;
            let actcColor = "white";
            let tooltip = "";
            let logType = this.logType;
            let onHoverAlert = (alert) => {
              // this.isEraticMse = true;
              this.mouse_x = event.clientX;
              this.mouse_y = event.clientY;
              this.alertValueChange(true, alert);
            };
            let onMouseOut = (alert) => {
              // this.isEraticMse = false;
              this.alertValueChange(false, alert);
              this.mouse_x = 0;
              this.mouse_y = 0;
            };
            g = svg.append("g").attr("height", height_components);
            // .style("transform", "translate(20px, 0px)");
            let lastMessage = "";
            let currentTimeRange = this.currentTimeRange;
            g.selectAll(".group")
              .data(data["alert"])
              .attr("class", "group")
              .enter()
              .each(function (d, i) {
                if (i == 0) {
                  startActcCode = d;
                  startActcTime = data["dmea"][i];
                } else {
                  endActcCode = d;
                  endActcTime = data["dmea"][i];

                  if (startActcCode != endActcCode || i == totalRecords) {
                    actcColor = "red";
                    tooltip = "Rotary Drilling";
                    if (startActcCode) {
                      for (
                        let alertC = 0;
                        alertC < startActcCode.split(",").length;
                        alertC++
                      ) {
                        let textMessage = startActcCode.split(",")[alertC];
                        d3.select(this)
                          .append("line")
                          .style("stroke", actcColor)
                          .style("stroke-width", 2)
                          .attr("x1", 0)
                          .attr("y1", y(startActcTime))
                          .attr("x2", 20)
                          .attr(
                            "y2",
                            y(startActcTime) +
                              (y(endActcTime) - y(startActcTime)) / 2
                          );
                        d3.select(this)
                          .append("line")
                          .style("stroke", actcColor)
                          .style("stroke-width", 2)
                          .attr("x1", 0)
                          .attr("y1", y(endActcTime))
                          .attr("x2", 20)
                          .attr(
                            "y2",
                            y(startActcTime) +
                              (y(endActcTime) - y(startActcTime)) / 2
                          );
                        let skipInterval;
                        switch (currentTimeRange) {
                          case 2:
                            skipInterval = 1 * 300000;
                            break;
                          case 4:
                            skipInterval = 2 * 300000;
                            break;
                          case 8:
                            skipInterval = 6 * 300000;
                            break;
                          case 12:
                            skipInterval = 9 * 300000;
                            break;
                          case 24:
                            skipInterval = 12 * 300000;
                            break;

                          default:
                            skipInterval = 2 * 300000;
                            break;
                        }
                        if (
                          lastMessage == textMessage &&
                          // y(endActcTime) - y(startActcTime) < 12 &&
                          endActcTime - startActcTime < skipInterval &&
                          startActcCode.split(",").length == 1
                        ) {
                        } else {
                          d3.select(this)
                            .append("rect")
                            .attr("class", "bar")
                            // .attr("class", "tooltip_actc")
                            .attr("data-index", i)
                            .attr("width", "100px")
                            .attr("x", "25px")
                            .style("fill", "white")
                            .style("curser", "pointer")

                            .attr(
                              "y",
                              y(startActcTime) +
                                (y(endActcTime) - y(startActcTime)) / 2 -
                                12 * (alertC + 1)
                            )
                            // .attr("height", "48px");
                            .attr("height", " 15px");
                          d3.select(this)
                            .append("text")
                            .attr("class", "bar")
                            .attr("data-index", i)
                            // .attr("width", "20px")
                            .attr("x", 25)
                            .style("fill", actcColor)
                            .style("z-index", "100000000")
                            .style("curser", "pointer")
                            .attr(
                              "y",
                              y(startActcTime) +
                                (y(endActcTime) - y(startActcTime)) / 2 -
                                15 * alertC
                            )
                            // .on(':v-on:moustmove.stop.preve="dummy"')
                            .on("mouseover", function () {
                              onHoverAlert(d3.select(this).text());
                            })
                            .on("mouseout", function () {
                              onMouseOut(d3.select(this).text());
                              this.mouse_x = event.clientX;
                              this.mouse_y = event.clientY;
                            })
                            .text(function () {
                              return textMessage;
                            })
                            .style("font-size", "0.8em");
                        }

                        lastMessage = startActcCode;
                      }
                    }

                    startActcCode = d;

                    startActcTime = data["dmea"][i];
                  } else {
                  }
                }
              });
          } else if (curve_names[k] == "activitycode") {
            let g = svg
              .append("g")
              .attr("height", height_components)
              .style("transform", "translate(30px, 0px)");

            let startActcCode = 0;
            let endActcCode = 0;
            let startActcTime = 0;
            let endActcTime = 0;
            let totalRecords = data["dmea"].length - 1;
            let actcColor = "white";
            let tooltip = "";
            let logType = this.logType;

            g.selectAll(".group")
              .data(data["activitycode"])
              .attr("class", "group")
              .enter()
              .each(function (d, i) {
                if (i == 0) {
                  startActcCode = d;
                  startActcTime = data["dmea"][i];
                } else {
                  endActcCode = d;
                  endActcTime = data["dmea"][i];

                  if (
                    parseInt(startActcCode) != parseInt(endActcCode) ||
                    i == totalRecords
                  ) {
                    switch (startActcCode) {
                      case 1:
                        actcColor = "#92D050";
                        tooltip = "ROT DRLG";
                        break;
                      case 2:
                        actcColor = "#00B050";
                        tooltip = "SLD DRLG";
                        break;
                      case 3:
                        actcColor = "#FFC000";
                        tooltip = "CIRC WITH ROT";
                        break;
                      case 4:
                        actcColor = "#FFFFFF";
                        tooltip = "UNKNOWN";
                        break;
                      case 10:
                        actcColor = "#833C0C";
                        tooltip = "CIRCULATION";
                        break;
                      case 11:
                        actcColor = "#00CC99";
                        tooltip = "REAMING";
                        break;
                      case 12:
                        actcColor = "#008000";
                        tooltip = "BACKREAM";
                        break;
                      case 13:
                        actcColor = "#CC3300";
                        tooltip = "CONNECTION";
                        break;
                      case 14:
                        actcColor = "#2F75B5"; // Bright Blue
                        tooltip = "TIH-DRLG";
                        break;
                      case 15:
                        actcColor = "#8EA9DB"; //Cadet Blue
                        tooltip = "POOH-DRLG";
                        break;
                      case 16:
                        actcColor = "#DBDBDB";
                        tooltip = "CIRC WITH ROT";
                        break;
                      case 17:
                        actcColor = "#FFFF00";
                        tooltip = "CIRCULATION";
                        break;
                      case 30:
                        actcColor = "#FFE699";
                        tooltip = "STATIONARY";
                        break;
                      case 5:
                        actcColor = "#1F4E78";
                        tooltip = "TIH";
                        break;
                      case 6:
                        actcColor = "#BDD7EE";
                        tooltip = "POOH";
                        break;
                      case 20:
                        actcColor = "#8497B0";
                        tooltip = "PUMPOUT";
                        break;
                      case 21:
                        actcColor = "#9900FF";
                        tooltip = "REAM-TRIP";
                        break;
                      case 22:
                        actcColor = "#FF3399";
                        tooltip = "BACK REAM-TRIP";
                        break;
                      case 23:
                        actcColor = "#CCCC00";
                        tooltip = "CONN.TRIP";
                        break;
                      case 24:
                        actcColor = "#CC9900";
                        tooltip = "CIRC WITH ROT-TRIP";
                        break;
                      case 25:
                        actcColor = "#FF9933";
                        tooltip = "CIRC W/O ROT-TRIP";
                        break;
                      case 26:
                        actcColor = "#833C0C";
                        tooltip = "CIRCULATION";
                        break;
                      case 27:
                        actcColor = "#945E0D";
                        tooltip = "WASHDOWN";
                        break;

                      default:
                        break;
                    }
                    // let tooltip = d3
                    //   .select("body")
                    //   .append("div")
                    //   .style("position", "absolute")
                    //   .style("z-index", "10000000000")
                    //   .style("visibility", "hidden")
                    //   .style("background", "white")
                    //   .text(tooltip);
                    d3.select(this)
                      .append("rect")
                      .attr("class", "bar")
                      .attr("data-index", i)
                      .attr("width", "60px")
                      // .attr('x', function(e) { return x(d.Date); })
                      .style("fill", actcColor)
                      .attr("y", y(startActcTime))
                      // .attr("height", "48px");
                      .attr(
                        "height",
                        y(endActcTime) - y(startActcTime)
                          ? y(endActcTime) - y(startActcTime) + "px"
                          : " 0px"
                      )
                      .append("text");
                    // .on("mouseover", function () {
                    //   return tooltip.style("visibility", "visible");
                    // })
                    // .on("mousemove", function () {

                    //   return tooltip
                    //     .style("top", event.pageY - 10 + "px")
                    //     .style("left", event.pageX + 10 + "px");
                    // })
                    // .on("mouseout", function () {
                    //   return tooltip.style("visibility", "hidden");
                    // });

                    startActcCode = d;
                    startActcTime = data["dmea"][i];
                  } else {
                  }
                }
              });
          } else {
            //// code that creates a line for each Curve in order provided and applies
            //// the color in the color array in order provided
            let curveUnit = "";
            if (curve_units[k]) {
              curveUnit = curve_units[k];
            }
            let min = mins[k];
            let max = maxes[k];
            let header_text_line = "";
            if (min != null && max != null) {
              header_text_line =
                parseFloat(min).toFixed(1) +
                " - " +
                curve_names[k] +
                "  " +
                curveUnit +
                " - " +
                parseFloat(max).toFixed(1);
            }
            let curveScale = curveScales[k];
            let min_this = "";
            let max_this = "";
            let scaleType = curveScaleType[k];
            if (
              scaleType == "manual" &&
              (curveScale != null || curveScale != undefined)
            ) {
              if (curveScale[0] != null || curveScale[0] != "") {
                min_this = curveScale[0];
              } else {
                min_this = this.minmax[curve_names[k]].min;
                curveScale[0] = min_this;
              }
              if (curveScale[1] != null || curveScale[1] != "") {
                max_this = curveScale[1];
              } else {
                max_this = this.minmax[curve_names[k]].max;
                curveScale[1] = max_this;
              }
            } else {
              let currentRange = defaultRanges.ranges.find(
                (data) => data.name == curve_names[k]
              );
              if (currentRange) {
                max_this = currentRange.high;
                min_this = currentRange.low;
              } else {
                min_this = d3.min(this.data[curve_names[k]]);
                max_this = d3.max(this.data[curve_names[k]]);
              }
              curveScale[0] = min_this;
              curveScale[1] = max_this;
            }

            let x = d3
              .scaleLinear()
              .domain([min_this, max_this])
              .range([margin.left, width - margin.right]);
            // .nice();
            if (scale_linear_log_or_yours == "log") {
              x = d3
                .scaleLog()
                .domain([min_all_curves, max_all_curves])
                // .domain([min_this, max_this])

                .range([margin.left, width - margin.right]);
              // .nice();
            } else if (scale_linear_log_or_yours == "linear") {
            } else if (typeof scale_linear_log_or_yours !== "string") {
              x = scale_linear_log_or_yours["yours"];
            }
            if (k == 0) {
              x_func == x;
            }
            //// This creates an object to hold multiple x axis scale functions
            //// that will be used if 'between' style fill is selected.
            x_functions_for_each_curvename[curve_names[k]] = x;
            //////////////  Header text, two way depending on  =>//////////////

            let recent_point_x = 0;
            let another_line = function (x_arr, y_arr) {
              return d3
                .line()
                .x(function (d, i) {
                  if (x_arr[i]) recent_point_x = x_arr[i];
                  return x_arr[i] ? x(x_arr[i]) : x(recent_point_x);
                })
                .y(function (d, i) {
                  return y_arr[i] ? y(y_arr[i]) : y(0);
                })(Array(x_arr ? x_arr.length : 0));
            };

            let defs = svg.append("defs");

            let gradient = defs.append("linearGradient").attr("id", "gradient");

            gradient
              .append("stop")
              .attr("offset", "0%")
              .attr("stop-color", "red");
            gradient
              .append("stop")
              .attr("offset", "100%")
              .attr("stop-color", "green");
            svg
              .append("path")
              // .datum(data['dmea'])
              .attr("fill", "none")
              .attr("stroke", curve_colors[k])
              .attr(
                "stroke-width",
                template_curves["stroke_width"][k]
                  ? template_curves["stroke_width"][k]
                  : 2
              )
              .attr("class", "area")
              .attr(
                "d",
                another_line(data[curve_names[k]], data[depth_curve_name])
              );

            if (curve_names[k] === "depthofcut") {
              let name = curve_names[k];
              let fill_color = curve_colors[k];
              let area = d3.area();
              let threshold = max_all_curves;
              let left = min_all_curves;
              area
                .x1((d, i) => (x(d) ? x(d) : x(0)))
                .x0((d) => (x(left) ? x(left) : 0))
                .defined((d, i) => d >= min_all_curves)
                .y((d, i) =>
                  y(data[depth_curve_name][i])
                    ? y(data[depth_curve_name][i])
                    : y(0)
                );

              svg
                .append("path")
                .datum(data[name])
                .attr("class", "area")
                .attr("d", area)
                .attr("stroke", "none")
                .attr("fill", fill_color)
                .attr("fill-opacity", 0.8);
            }

            if (curve_names[k] == "stickslipindex") {
              let lastMsg = "";
              let lastMsgTime = "";
              let skipInterval;
              switch (this.currentTimeRange) {
                case 2:
                  skipInterval = 6 * 300000;
                  break;
                case 4:
                  skipInterval = 12 * 300000;
                  break;
                case 8:
                  skipInterval = 6 * 300000;
                  break;
                case 12:
                  skipInterval = 9 * 300000;
                  break;
                case 24:
                  skipInterval = 12 * 300000;
                  break;

                default:
                  skipInterval = 2 * 300000;
                  break;
              }
              let filterIndex = [];
              if (data["stickslip"]) {
                let txtRect = svg
                  .append("g")
                  .classed("text-labels-group", true)
                  .selectAll("text")
                  .data(data["stickslip"])
                  .enter()
                  .filter(function (d, i) {
                    if (d == undefined) {
                      return false;
                    } else {
                      if (
                        "none" != d &&
                        (lastMsgTime == "" ||
                          lastMsgTime + skipInterval <
                            data[depth_curve_name][i])
                      ) {
                        lastMsgTime = data[depth_curve_name][i];
                        if ("none" != d) filterIndex.push(i);
                        return "none" != d;
                      } else {
                        return false;
                      }
                    }
                  });
                txtRect
                  .append("rect")
                  .attr("width", "120")
                  .attr("height", "20")
                  .style("fill", function (d) {
                    let color = "yellow";
                    if (d == "STICKSLIP PROBABLE") {
                      color = "red";
                    }
                    return color;
                  })
                  .attr("y", function (d, i) {
                    return y(data[depth_curve_name][filterIndex[i]]) - 14;
                  })
                  .style("opacity", "0.6");
                txtRect
                  .append("text")
                  .attr("x", function (d) {
                    return 10;
                  })
                  .attr("y", function (d, i) {
                    return y(data[depth_curve_name][filterIndex[i]]);
                  })
                  .text(function (d, i) {
                    if ("none" != d) return d + "!";
                  })
                  .style("font-size", "0.8em");
              }
            }

            if (curve_names[k] == "washoutindex") {
              let lastMsg = "";
              let lastMsgTime = "";
              let skipInterval;
              if (data["washout"]) {
                switch (this.currentTimeRange) {
                  case 2:
                    skipInterval = 300000;
                    break;
                  case 4:
                    skipInterval = 300000;
                    break;
                  case 8:
                    skipInterval = 3 * 300000;
                    break;
                  case 12:
                    skipInterval = 5 * 300000;
                    break;
                  case 24:
                    skipInterval = 6 * 300000;
                    break;

                  default:
                    break;
                }
                let filterIndex = [];
                let txtRect = svg
                  .append("g")
                  .classed("text-labels-group", true)
                  .selectAll("text")
                  .data(data["washout"])
                  .enter()
                  .filter(function (d, i) {
                    if (d == undefined) {
                      return false;
                    } else {
                      if (
                        "none" != d &&
                        (lastMsgTime == "" ||
                          lastMsgTime + skipInterval <
                            data[depth_curve_name][i])
                      ) {
                        lastMsgTime = data[depth_curve_name][i];
                        if ("none" != d) filterIndex.push(i);
                        return "none" != d;
                      } else {
                        return false;
                      }
                    }
                  });
                txtRect
                  .append("rect")
                  .attr("width", "120")
                  .attr("height", "20")
                  .style("fill", function (d) {
                    return "red";
                  })
                  .attr("y", function (d, i) {
                    return y(data[depth_curve_name][filterIndex[i]]) - 14;
                  })
                  .style("opacity", "0.6");
                txtRect
                  .append("text")
                  .attr("x", function (d) {
                    return x(d);
                  })
                  .attr("y", function (d, i) {
                    return y(data[depth_curve_name][filterIndex[i]]);
                  })
                  .text(function (d, i) {
                    if ("none" != d) return d + "!";
                  })
                  // .attr("transform", "translate(" + 10 + "," + 10 + ")")
                  .style("font-size", "0.8em");
              }
            }
          }
        }

        if (!this.isZoom && !(curve_names.indexOf("alert") > -1)) {
          //// appends start of mouseover rectange used for showing hover content
          let focus = svg.append("g").style("opacity", 0);

          focus
            .append("rect")
            .attr("class", "background")
            .attr("fill", tooltipbg)
            .attr("height", 130)
            .attr("width", 95)
            .attr("rx", 8)
            // .transition().duration(1000).style("opacity", 1)
            .attr("opacity", 1);
          focus
            .append("line")
            .attr("class", "yl")
            .style("stroke", this.darkDark !== "white" ? "white" : "black")
            .style("stroke-array", "10")
            .style("opacity", 1)
            .attr("x1", 0)
            .attr("x2", height);
          if (
            mouseover_depth_or_depth_and_curve == "depth" ||
            mouseover_depth_or_depth_and_curve == "depth_and_curve"
          ) {
            focus
              .append("text")
              .attr("class", "y2")
              .attr("dx", 6)
              .attr("dy", "-.3em")
              .style("font-size", "12px")
              .style("z-index", 999999999)
              .style("stroke", this.darkDark !== "white" ? tooltipbg : "black");
          }
          //// curve value on hover

          if (
            mouseover_depth_or_depth_and_curve == "curve" ||
            mouseover_depth_or_depth_and_curve == "depth_and_curve"
          ) {
            for (let i = 0; i < curve_colors.length; i++) {
              focus
                .append("text")
                .attr("class", `data${i + 1}`)
                .attr("dx", 1)
                .attr("dy", `${0.5 * (i + 1)}cm`)
                .style("font-size", "12px")
                .style("fill", "black")
                .style("z-index", "black")
                .style("stroke", curve_colors[i])
                .style("stroke-width", "0.5px");
            }
          }
          // append the rectangle to capture mouse               // **********

          svg
            .append("rect") // **********
            .attr("width", width) // **********
            .style("height", height_components + "px")
            .attr("height", 10) // **********
            .attr("height", height) // **********
            .attr("id", "rect" + div_id)
            .style("fill", "none") // **********
            .style("pointer-events", "all") // **********

            .on("mouseover", (e) => {
              // if (this.isShowTooltip) this.mousemove(e);
              if (this.isShowTooltip)
                this.$store.dispatch("rect/mousemove", {
                  event: e,
                  widgetId: this.id,
                  displayId: this.displayId,
                  widgetIndex: this.correlationIdx,
                  trendIndex: this.trendIndex,
                });
            })
            .on("mouseout", (e) => {
              // if (this.isShowTooltip) this.mouseout(e);
              if (this.isShowTooltip)
                this.$store.dispatch("rect/mouseout", {
                  event: e,
                  widgetId: this.id,
                  displayId: this.displayId,
                  widgetIndex: this.correlationIdx,
                  trendIndex: this.trendIndex,
                });
            })
            .on("mousemove", (e) => {
              // if (this.isShowTooltip) this.mousemove(e);
              if (this.isShowTooltip)
                this.$store.dispatch("rect/mousemove", {
                  event: e,
                  widgetId: this.id,
                  displayId: this.displayId,
                  widgetIndex: this.correlationIdx,
                  trendIndex: this.trendIndex,
                });
            })
          .on("mousewheel", (e) => {
            if (this.isShowTooltip) {
                this.$store.dispatch("rect/mousescroll", {
                  event: e,
                  widgetId: this.id,
                  displayId: this.displayId,
                  widgetIndex: this.correlationIdx,
                  trendIndex: this.trendIndex,
                });
              }
            // if (this.isDataLoaded) {
            //   this.$store.dispatch("rect/mousescroll", {
            //     event: e,
            //     widgetId: this.id,
            //     displayId: this.displayId,
            //     widgetIndex: this.correlationIdx,
            //     trendIndex: this.trendIndex,
            //   });
            // }
            // this.mousescroll(e);
          });

          // **********
          // }

          this.focusAxisList[index] = {
            focus,
            x,
            y,
            curve_colors,
            curve_names,
            mouseover_curvename,
            depth_curve_name,
            width,
            height,
          };
        }

        //////////////  Horizontal Lines AKA tops =>//////////////

        try {
          for (let i = 0; i < template_lines.length; i++) {
            let this_line = template_lines[i];
            svg
              .append("line")
              .attr("x1", 0 + margin.left)
              .attr("y1", y(this_line["depth"]))
              .attr("x2", width * 0.75)
              .attr("y2", y(this_line["depth"]))
              .style("stroke-width", this_line["stroke_width"])
              .style("stroke", this_line["color"])
              // .style("stroke", function (d, i) {
              //   if (d[curve_names[k]] < 0) return "white";
              //   if (d[curve_names[k]] < 50) return "red";
              //   if (d[curve_names[k]] < 150) return "green";
              // })
              .style("stroke-dasharray", this_line["stroke-dasharray"])
              .style("stroke-linecap", this_line["stroke_linecap"])
              .style("fill", "none");

            svg
              .append("text")
              .attr("x", width * 0.75)
              .attr("y", y(this_line["depth"]))
              .attr("text-anchor", "start")
              .style("font-size", "12px")
              .text(this_line["label"]);
          }
        } catch (err) {
          console.error(
            "could not do lines for tops in curveBox function, error: ",
            err
          );
        }
        if (this.plots.length - 1 == index) {
          this.isRerendering = false;
        }
      } catch (error) {
        this.isRerendering = false;
        console.error("error in create curve box : ", error);
        return true;
      }
    },
    getConfig() {
      let tconfig = {
        height: "500",
        width: "260",
        UWI: "00/01-01-095-19W4/0",
        curveName: "CHALKTALK",
        tracks: this.trackConfig.tracks,
        curveType: "dmea",
        depthName: "dmea",
      };

      this.trackConfig = tconfig;

      return tconfig;
    },
    configure() {
      let UWI = this.trackConfig.UWI;
      // let curveNames = curveNames;
      // let curveColors=curveColors;
      let curveName = this.trackConfig.curveName;
      let _plots = [];
      let tracks_temp = this.trackConfig.tracks;

      for (let i = 0; i < tracks_temp.length; i++) {
        let track = tracks_temp[i];
        let curveNames = track.map((curve) => curve.name);
        let curveColors = track.map((curve, k) => this.defaultColor[i][k]);
        // let fill = track.map((curve)=>curve.fill)

        // let fillColor = track.map((curve) => curve.fillC);
        let fillDir = track.map((curve) => curve.fillD);
        let fill = track.map((curve) => curve.fill);
        let strokes = track.map((curve) => curve.stroke);
        let units = track.map((curve) => {
          // return this.$store.state.disp.displays[this.displayId].units[
          //   this.$store.state.disp.displays[this.displayId].tags.indexOf(
          //     curve.name
          //   )
          // ];
          return "unit";
        });
        let scale = track.map((curve) => curve.scale);
        let scaleType = track.map((curve) => curve.scaleType);
        let trackId;
        if (track.length > 0) {
          trackId = track[0].trackId;
        }

        let trackObjs = [];
        for (let j = 0; j < track.length; j++) {
          let configObj = {
            curve_name: "",
            fill: "",
            fill_direction: "",
            cutoffs: [],
            fill_colors: [],
          };
          configObj.curve_name = track[j].name;
          configObj.fill_direction = track[j].fillD;
          configObj.fill = track[j].fill;
          configObj.cutoffs = [0, 4.25];
          // configObj.fill_colors = [track[j].fillC];
          configObj.color = this.defaultColor[i][j];
          track[j].color = this.defaultColor[i][j];
          trackObjs.push(configObj);
          trackObjs = trackObjs;
        }

        if (this.singleTrackWidth > this.trackWidth) {
          this.singleTrackWidth = this.trackWidth;
        }

        let graphType = "dmea";
        let plot = plotConfig(
          this.plotTemplate,
          UWI,
          curveNames,
          curveColors,
          units,
          strokes,
          scale,
          scaleType,
          trackObjs,
          this.div_id,
          this.singleTrackWidth,
          this.singleTrackHeight,
          graphType,
          this.tickColor,
          this.tickThickness,
          leftbarbg,
          tooltipbg,
          trackId
        );

        _plots.push(plot);
      }

      this.plots = _plots;
      return this.plots;
    },
    async getSingleTagData(tagName, isUpdate) {
      return new Promise(async (resolve, reject) => {
        this.$store.dispatch("data/startLoading");
        let result = null;
        let dconfig = {};

        if (isUpdate) dconfig.fields = tagName;
        else dconfig.fields = [tagName];

        dconfig.wellId = this.wellId;
        dconfig.wellboreId = this.wellboreId;
        dconfig.logId = this.logId;
        dconfig.dataPoints = null;
        dconfig.depthRange = null;

        try {
          if (this.wellId)
            if (
              this.$store.state.rect.rects[this.correlationIdx]
                .wellRelationOptimization
            ) {
              result = await apiService.WellServices.getDepthLogs({
                fields: dconfig.fields,
                well_id: this.wellId,
                wellbore_name: this.wellboreId,
                correlation:
                  this.$store.state.rect.rects[this.correlationIdx]
                    .wellRelationOptimization.optimization_type,
                isPartial: true,
                start: this.scaleMin,
                end: this.scaleMax,
              });
              let payload = {
                well_name: this.logId,
                data: {
                  depth: result.data.data.dmea,
                  time: result.data.data.time,
                  min_max: {
                    dmea: result.data.minmax.dmea,
                    time: result.data.minmax.time,
                  },
                },
                displayIndex: this.displayIndex,
              };

              this.$store.dispatch("disp/daysvsdepth", payload);

              this.data = { ...this.data, ...result.data.data };
              this.minmax = { ...this.minmax, ...result.data.minmax };

              resolve(true);
            }

          apiService.WellServices.getDepthLogs({
            fields: dconfig.fields,
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
            // isPartial: false,
          })
            .then(async (data) => {
              if (data && data.data && data.data.data) {
                this.completeData = {
                  ...this.completeData,
                  ...data.data.data,
                };

                this.isDataLoaded = true;
                // this.rerender('complete data')
              }
            })
            .catch((e) => {
              console.error("whole data error : ", e);
            });
        } catch (error) {
          console.error(error);
        }

        this.$store.dispatch("data/stopLoading");
        // resolve(true)
      });
    },

    async getMinMaxRange(from) {
      if (this.minmaxCalled) {
        return false;
      }

      this.minmaxCalled = true;
      try {
        let result = null;
        result = await apiService.WellServices.getMinMaxDepth({
          well_id: this.wellId,
          wellbore_name: this.wellboreId,
        });
        result.data.min = result.data.min;
        result.data.max = result.data.max;
        this.minmaxCalled = false;
        console.log("checking depth::::::", result.data);
        return result.data;
      } catch (error) {
        console.error(error);
        this.minmaxCalled = false;
        return { min: 0, max: 1000 };
      }
    },

    convertData(iData) {
      let oData = [];
      for (let i = 0; i < iData.data.length; i++) {
        let dataObj = {};
        for (let j = 0; j < iData.data[i].length; j++) {
          // if(iData.columns[j] == 'dmea'){
          //   dataObj[iData.columns[j]] = epoch++;
          // } else {
          dataObj[iData.columns[j]] = iData.data[i][j];
          // }
        }
        oData.push(dataObj);
        oData = oData;
      }
      return oData;
    },

    async addData(iData, tagName) {
      tagName = tagName.toLowerCase();
      let extraParameter = "";
      if (tagName == "stickslipindex") extraParameter = "stickslip";
      if (tagName == "washoutindex") extraParameter = "washout";
      // let this.upperIndex = this.completeData.length;

      if (this.data.length == 0) {
        // data = iData.data;
        this.completeData = this.convertData(iData);
        if (this.logType == "depth") {
          this.completeData.map((row, i) => {
            if (
              row["dmea"] == this.scaleMin ||
              (row["dmea"] < this.scaleMin && !(row["dmea"] > this.scaleMin))
            ) {
              this.lowerIndex = i;
            }

            if (row["dmea"] == this.scaleMax || row["dmea"] < this.scaleMax) {
              this.upperIndex = i;
            }
          });
          this.data = this.completeData.slice(
            this.lowerIndex,
            this.upperIndex + 1
          );
        } else {
          this.data = this.completeData;
        }
      } else {
        let index = iData.columns.indexOf(tagName);
        let extraIndex = iData.columns.indexOf(extraParameter);
        let completeData = this.completeData;
        for (let i = 0; i < iData.data.length; i++) {
          if (completeData[i]) {
            completeData[i][tagName] = iData.data[i][index];
            if (extraParameter) {
              completeData[i][extraParameter] = iData.data[i][extraIndex];
            }
          }
          if (this.logType == "depth") {
            let row = completeData[i];
            if (
              row["dmea"] == this.scaleMin ||
              (row["dmea"] < this.scaleMin && !(row["dmea"] > this.scaleMin))
            ) {
              this.lowerIndex = i;
            }

            if (row["dmea"] == this.scaleMax || row["dmea"] < this.scaleMax) {
              this.upperIndex = i;
            }
          }
        }

        this.completeData = completeData;
        if (this.logType == "depth")
          this.data = completeData.slice(this.lowerIndex, this.upperIndex + 1);
        else this.data = completeData;
      }
      // this.data = iData.data
      // this.completeData = iData.data;
      if (this.completeData[0]) {
        this.zoomMin = this.completeData[0].dmea;
        this.zoomMax = this.completeData[this.completeData.length - 1].dmea;
      }
      return this.data;
    },

    async getItFromLocalData(range, isLogChange) {
      this.$store.dispatch("data/startLoading");
      let graphType = "dmea";

      // if (!this.completeData[graphType] || !this.data[graphType]) {
      //   await this.getMultiTagsData(this.getOnScreenMnemonics(), range);
      // }

      if (this.completeData[graphType]) {
        this.completeData[graphType].map((row, i) => {
          if (
            row == range[0] ||
            (row < range[0] && !(row > range[0])) ||
            (range[0] == 0 && i == 0)
          ) {
            this.lowerIndex = i;
          }

          if (row == range[1] || row < range[1]) {
            this.upperIndex = i;
          }
        });
        let tempData = { ...this.completeData };

        for (let i in tempData) {
          this.data[i] = tempData[i].slice(
            this.lowerIndex,
            this.upperIndex + 1
          );
        }
      } else {
        this.data = this.completeData;
      }
      this.$store.dispatch("data/stopLoading");
    },

    async zoomIt(depthMax, depthMin, feet, isLogChange, from) {
      this.templateWidth = this.width;
      this.setTrackWidth(this.trackConfig.tracks.length);
      // console.log("🚀 ➽ file: CorrelationTrend.vue:4223  ➽ zoomIt  ➽ this.trackConfig.tracks.length ⏩", from, this.width, this.trackConfig.tracks.length)
      if (this.zoomItCalled) {
        return true;
      }
      this.zoomItCalled = true;
      let totalRows;
      let depthRange;

      if (depthMax > depthMin) {
        totalRows = Math.round(depthMax - depthMin);
        depthRange = [depthMin, depthMax];
      } else {
        totalRows = Math.round(depthMin - depthMax);
        depthRange = [depthMax, depthMin];
      }

      await this.getItFromLocalData(depthRange, isLogChange);
      // this.calGraphHeight(totalRows, feet);
      // if (false) {
      // if (this.liveDisabled && this.stopUpdation) {
      //   if (this.timer > 10000) {
      //     this.rerender("zoomIt");
      //     this.stopUpdation = false;
      //   }
      // } else {
      await this.rerender("zoomIt");
      // }
      this.zoomItCalled = false;
    },

    async rerender(from) {
      console.log("called from renderer:::::", from);
      if (!this.trackConfig && !this.trackConfig.tracks) await this.getConfig();

      if (this.trackConfig.tracks && this.trackConfig.tracks.length) {
        this.tracksNbr = this.trackConfig.tracks.length;
        if (this.tracksNbr > 0) {
          // if (this.trackConfig.tracks[0][0].name != null) {
          this.readyToPlot = true;
          this.emptyPlot = false;

          this.configurePlots = await this.configure();

          if (this.plots)
            this.verticalplots(this.div_id, this.plots, true, "rerender");

          let sliderId = "slider" + this.compId;
          let sliderEle = document.querySelector("#" + sliderId);
          if (sliderEle != null) {
            sliderEle.style.height = this.singleTrackHeight - 60 + "px";
            // }
            // this.setTrackInfo();
          }
        }
      } else {
      }
    },

    deleteTrack() {
      this.showCurveInfo = false;
      trackDeleteDisabled = true;

      this.trackConfig.tracks.splice(trackNbr, 1);
      this.trackConfig.tracks = this.trackConfig.tracks;

      // this.getTracksRight();
      // this.adjustTracks();
      this.configure();
      this.verticalplots(this.div_id, this.plots, true, "deleteTrack");
      // this.setTrackInfo();
    },

    addTrack() {
      let trackId = "t" + Math.random().toString(36).slice(8);

      this.readyToPlot = true;
      this.emptyPlot = false;
      this.isNewTrack = true;
      this.showCurveInfo = false;
      this.curveDeleteDisabled = true;
      this.newCurveDisabled = true;
      this.trackConfig.tracks.push([]);

      if (this.trackConfig.tracks[0].length == 0) {
        this.readyToPlot = false;
      }
      // if (tracks[0].length == 0) {
      //     readyToPlot = false;
      // }

      this.getConfig();

      currentCurve = {};
      let trackLength = this.trackConfig.tracks.length;
      trackNbr = trackLength - 1;
      // this.getTracksRight(trackNbr, trackId);
      this.selectedTrackValue = this.totTracks[trackNbr];
      // addCurve();
      // alert(
      //     `New Track is added .Please press "New Tag button to add Tag(s)" `
      // );
      this.newCurveDisabled = false;
      // this.setTrackInfo();
      return trackId;
    },

    changeTooltip() {
      this.$store.dispatch("rect/toolTipToggle", {
        isShowTooltip: !this.isShowTooltip,
        widgetId: this.id,
        displayId: this.displayId,
        widgetIndex: this.correlationIdx,
        trendIndex: this.trendIndex,
      });
      // this.isShowTooltip = !this.isShowTooltip;
      // if(this.isZoom = true){
      //   this.isShowTooltip = false
      // }
    },

    changeZoom() {
      this.$store.dispatch("rect/toolZoomToggle", {
        isZoom: !this.isZoom,
        widgetId: this.id,
        displayId: this.displayId,
        widgetIndex: this.correlationIdx,
        trendIndex: this.trendIndex,
      });
      // this.isZoom = !this.isZoom;
    },

    changeAlert() {
      this.isShowAlerts = !this.isShowAlerts;
    },

    lockunlock() {
      let locker = !this.locker;
      console.log(
        "🚀 ➽ file: CorrelationTrend.vue:4195  ➽ lockunlock  ➽ this.locker ⏩",
        this.locker
      );
      this.$store.dispatch("rect/lockevent", {
        locker,
        widgetId: this.id,
        displayId: this.displayId,
        widgetIndex: this.correlationIdx,
        trendIndex: this.trendIndex,
      });
      this.$store.dispatch("disp/lockevent", {
        locker,
        displayId: this.displayId,
      });
    },
  },
  watch: {
    width(newValue) {
      this.zoomIt(
        this.scaleMax,
        this.scaleMin,
        this.feet,
        false,
        "watch width"
      );
    },
    height(newValue) {
      this.rerender("height");
    },
    async requiredData(newValue) {
      if (newValue && newValue["dmea"]) {
        if (this.wellId) {
          let minMax = await this.getMinMaxRange("watch required");
          let limit = 0;
          if (minMax) {
            if (isNaN(this.scaleMin) || isNaN(this.scaleMax)) {
              // this.scaleMin = parseInt(minMax.max - 500);
              // this.scaleMax = parseInt(minMax.max);
              if (minMax.max == minMax.min) minMax.max = minMax.min + 1000;
              this.scaleMin = minMax.max - 500;

              this.scaleMax = minMax.max;
              this.scaleMinFixed = minMax.min;
              this.scaleMaxFixed = minMax.max;
              this.currentFeetRange = 500;
            } else {
              // if (minMax.max < this.scaleMax) {
              this.scaleMin = parseInt(minMax.max - 500);
              this.scaleMax = parseInt(minMax.max);
              // }
            }

            limit = parseInt(minMax.max);
            this.scaleMinFixed = parseInt(minMax.min);
            this.scaleMaxFixed = parseInt(minMax.max);
            this.createSlider();
            this.completeData = newValue;
            // this.data = newValue;
            this.zoomIt(
              this.scaleMax,
              this.scaleMin,
              this.feet,
              false,
              "requiredData watch"
            );
          }
          // this.data = newValue;
          // this.completeData = newValue;
          // this.zoomIt(
          //   this.scaleMax,
          //   this.scaleMin,
          //   this.feet,
          //   false,
          //   "required called watch"
          // );
        }
      }
    },
    async wellDetails(newValue) {
      let well_depth =
        newValue["wellbores"][newValue.well_bore_name]["logs"]["depth"];
      this.wellId = newValue.well_id;
      this.wellboreId = newValue.well_bore_name;
      this.topic = well_depth.topicname;
      let minMax = await this.getMinMaxRange("watch well_id");
      if (minMax) {
        let limit = 0;
        if (isNaN(this.scaleMin) || isNaN(this.scaleMax)) {
          // this.scaleMin = parseInt(minMax.max - 500);
          // this.scaleMax = parseInt(minMax.max);
          if (minMax.max == minMax.min) minMax.max = minMax.min + 1000;
          this.scaleMin = minMax.max - 500;

          this.scaleMax = minMax.max;
          this.scaleMinFixed = minMax.min;
          this.scaleMaxFixed = minMax.max;
          this.currentFeetRange = 500;
        } else {
          // if (minMax.max < this.scaleMax) {
          this.scaleMin = parseInt(minMax.max - 500);
          this.scaleMax = parseInt(minMax.max);
          // }
        }
        limit = parseInt(minMax.max);
        this.scaleMinFixed = parseInt(minMax.min);
        this.scaleMaxFixed = parseInt(minMax.max);
        this.createSlider();
        this.completeData = this.requiredData;
        // this.data = this.requiredData;
        this.zoomIt(
          this.scaleMax,
          this.scaleMin,
          this.feet,
          false,
          "wellDetails watch"
        );
      }
    },
    async well_id(newValue) {
      let minMax = await this.getMinMaxRange("watch well_id");
      if (minMax) {
        let limit = 0;
        if (isNaN(this.scaleMin) || isNaN(this.scaleMax)) {
          // this.scaleMin = parseInt(minMax.max - 500);
          // this.scaleMax = parseInt(minMax.max);
          if (minMax.max == minMax.min) minMax.max = minMax.min + 1000;
          this.scaleMin = minMax.max - 500;

          this.scaleMax = minMax.max;
          this.scaleMinFixed = minMax.min;
          this.scaleMaxFixed = minMax.max;
          this.currentFeetRange = 500;
        } else {
          // if (minMax.max < this.scaleMax) {
          this.scaleMin = parseInt(minMax.max - 500);
          this.scaleMax = parseInt(minMax.max);
          // }
        }
        limit = parseInt(minMax.max);
        this.scaleMinFixed = parseInt(minMax.min);
        this.scaleMaxFixed = parseInt(minMax.max);
        this.createSlider();
        this.completeData = this.requiredData;
        // this.data = this.requiredData;
        this.zoomIt(
          this.scaleMax,
          this.scaleMin,
          this.feet,
          false,
          "well_id watch"
        );
      }
    },
    async plotConfigProps(newValue) {
      console.info("new config came ", newValue);
      if (newValue.option == "wellRelationOptimization") {
        this.trackConfig = newValue;
        await this.configure();
        this.zoomIt(
          this.scaleMax,
          this.scaleMin,
          this.feet,
          false,
          "config change"
        );
      }
    },
  },
};

// let tracks = [];

let trackNbr = 0;

let tooltipbg = "white";

let leftbarbg = "#202124";

let currentCurve = {};

let curvesMinsMaxes = [];
</script>

<style scoped>
.colorbox {
  width: 10px;
  height: 10px;
}

.zoomin {
  width: 50px;
  background: lightgoldenrodyellow;
  position: absolute;
  bottom: 0;
}

.textBgColor {
  background: yellow;
  background-color: yellow;
}

.area {
  fill: lightsteelblue;
}

/* .scrolldiv {
  } */
.actc_pop {
  position: absolute;
  left: -101px;
}

/* hover tooltip */

.tool_group {
  position: fixed;
  display: inline-grid;
  /* left: 221px; */
  right: 162px;
  z-index: 99999;
}

.tooltip_actc {
  position: relative;
  display: block;
  /* border-bottom: 1px dotted black;
  margin: 5px 0;
  right: 0;
  cursor: pointer;
  text-align: left; */
}

.tooltip_actc .tooltiptext {
  visibility: hidden;
  /* width: 700px; */

  color: #fff;
  text-align: center;
  border-radius: 6px;
}

/* padding: 5px 10px; */

/* Position the tooltip */
/*  Removed the commented CSS By Prerana */
.colorbox {
  width: 5px;
  height: 100%;
}

.zoomin {
  width: 50px;
  background: lightgoldenrodyellow;
  position: absolute;
  bottom: 0;
}

.textBgColor {
  background: yellow;
  background-color: yellow;
}

.area {
  fill: lightsteelblue;
}

.actc_pop {
  position: absolute;
  left: -101px;
}

/* hover tooltip */
.tool_group {
  position: fixed;
  display: inline-grid;
  right: 162px;
  z-index: 99999;
}

.tooltip_actc {
  position: relative;
  display: block;
}

.tooltip_actc .tooltiptext {
  visibility: hidden;
  color: #fff;
  text-align: center;
  border-radius: 6px;

  position: absolute;
  z-index: 1;
  top: -5px;
  right: 105%;
}

.tooltip_actc .tooltiptext::after {
  content: "";
  position: absolute;
  right: -6px;
  top: 5px;
  content: "\1F782";
  font-size: 14px;
  color: #005e62;
}

.tooltip_actc .tooltiptext {
  visibility: visible;
  /* background: #005e62; */
  z-index: 999999;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  /* grid-gap: 2px; */
}

.tooltip_actc .tooltiptext {
  visibility: visible;
  z-index: 999999;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto;

  padding: 0;
  justify-content: center;
  float: right;
}

.grid-container > div {
  text-align: center;
  padding: 5px 10px;
  font-size: 30px;
  margin: 0 auto;
  width: 450px;
}

.grid-container > .drill_eff_item_3 {
  width: 850px;
}

.grid-container > .drill_eff_item_1 {
  width: 850px;
}

/* .item1 {
  grid-column: 1 / 2;
} */
.item1 {
  grid-column: 2 / 2;
}

.grid-container > .drill_eff_item_3 {
  width: 850px;
}

.grid-container > .drill_eff_item_1 {
  width: 850px;
}

.item1 {
  grid-column: 2 / 2;
}

._item_ {
  height: auto;
  background-color: #005e62;
}

._item_:last-child {
  grid-column: 2;
}

._item_ > img {
  height: 100%;
}

._item_:last-child {
  grid-column: 2;
}

._item_ > img {
  height: 100%;
}

.t_d_col_1 {
  width: 5px;
  height: 100%;
  /* display: flex;
  justify-content: end; */
}

.t_d_col_2 {
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.td_trnk {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: calc((0.3em + 0.3vmin) + (0.2em + 0.2vmax));
}

.text_gray {
  color: #dee2e6;
}

.text_dark {
  /* color: #000000; */
  font-weight: bold;
}

.t_d_col_3 {
  width: 25%;
}

.t_d_row_1 {
  font-size: 8px;
  /* font-weight: bold; */
}

.text_gray {
  color: #dee2e6;
}

.text_dark {
  font-weight: 600;
}

.t_d_col_3 {
  width: 25%;
}

.t_d_row_1 {
  font-size: 9px;
  letter-spacing: 0.5px;
  font-weight: 600;
  padding-top: 4px;
}

.t_d_row_2 {
  position: relative;
  bottom: 3px;
  font-size: 9px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.tooltip__tip {
  text-align: center;
}

.tooltip__tip {
  text-align: center;
}

.tooltip__tip::after {
  display: none;
  padding: 10px 15px;
  position: absolute;
  text-align: center;
  z-index: 999;
  color: white;
}

.tooltip__tip::before {
  content: " ";
  display: none;
  position: absolute;
  width: 7px;
  height: 7px;
  z-index: 999;
}

.tooltip__tip_dark::after {
  background: #1b1a1f !important;
  color: #ffffff;
}

.tooltip__tip_dark::before {
  background: #1b1a1f !important;
}

.tooltip__tip_dark::before {
  background: #1b1a1f !important;
}

.tooltip__tip_light::after {
  background: #5dfce4 !important;
  color: #000000 !important;
}

.tooltip__tip_light::before {
  background: #fffff7 !important;
  /* color: #1b1a1f; */
}

.tooltip__tip:hover::after {
  display: block;
}

.tooltip__tip:hover::before {
  display: block;
}

.tooltip__tip_light::before {
  background: #fffff7 !important;
}

.tooltip__tip:hover::after {
  display: block;
}

.tooltip__tip:hover::before {
  display: block;
}

.tooltip__tip.top::after {
  content: attr(data-tip);
  top: 7px;
  left: 50%;
  width: auto;
  font-size: 12px;
  padding: 3px 14px;
  transform: translate(-50%, calc(-100% - 10px));
  border-radius: 0px !important;
}

.tooltip__tip.top::before {
  top: 5px;
  left: 50%;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.tool_tip_units_r.top::after {
  content: attr(data-tip);
  top: 2px;
  left: 81%;
  padding: 3px 14px;
  font-size: 7px;
  -webkit-transform: translate(-50%, calc(-100% - 10px));
  transform: translate(-50%, calc(-100% - 10px));
}

.tool_tip_units_r.top::before {
  top: 0;
  left: 90%;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.tool_tip_units_l.top::after {
  content: attr(data-tip);
  top: 2px;
  left: 16%;
  padding: 3px 14px;
  font-size: 7px;
  -webkit-transform: translate(-50%, calc(-100% - 10px));
  transform: translate(-50%, calc(-100% - 10px));
}

.tool_tip_units_l.top::before {
  top: 0;
  left: 16%;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.tooltip__tip_live {
  /* border-radius: 10px; */
  /* position: relative; */
  text-align: center;
}

.selectedBtn_tooltip {
  width: 100%;
}

.tooltip__tip_live {
  text-align: center;
}

.tooltip__tip_live::after {
  background-color: #000000;
  border-radius: 10px;
  display: none;
  padding: 10px 15px;
  position: absolute;
  text-align: center;
  z-index: 999;
  color: white;
}

.tooltip__tip_live::before {
  background-color: #000000;
  content: " ";
  display: none;
  position: absolute;
  width: 7px;
  height: 7px;
  z-index: 999;
}

.tooltip__tip_live:hover::after {
  display: block;
}

.tooltip__tip_live:hover::before {
  display: block;
}

.tooltip__tip_live:hover::after {
  display: block;
}

.tooltip__tip_live:hover::before {
  display: block;
}

.tooltip__tip_live.top::after {
  content: attr(data-tip);
  bottom: 96px;
  left: 164px;
  font-size: 7px;
  padding: 3px 5px;
  transform: translate(-50%, calc(-100% - 10px));
}

.tooltip__tip_live_dis.top::after {
  content: attr(data-tip);
  bottom: -2px;
  left: 8px;
  width: 56px;
  font-size: 7px;
  padding: 1px 5px;
  -webkit-transform: translate(-50%, calc(-100% - 10px));
  transform: translate(-50%, calc(-100% - 10px));
}

.tooltip__tip_live.top::before {
  bottom: 107px;
  left: 164px;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.tooltip__tip_live_dis.top::before {
  bottom: 16px;
  left: 12px;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.noUi-target_dup {
  background: #202124;
  background: var(--wdigetBg) !important;
}

.bg-black_mattie {
  background: #1e1e1e;
  background: var(--wdigetBg) !important;
}

.light_trend-mode {
  /* background: #FFFFF7 !important; */
  background: var(--wdigetBg) !important;
  transition: var(--transition);

  /* #fcffe2; */
  /* #fff8e4; */
  /* #ffffe7; */
  /* #fcfbda; */
  /* #fffff0 */
}

.noUi-target_dup {
  background: var(--wdigetBg);
  transition: var(--transition);
}

.bg-black_mattie {
  background: #1e1e1e;
  transition: var(--transition);
}

.light_trend-mode {
  /* background: #FFFFF7 !important; */
}

._ann_tooltip_logo {
  width: 100%;
  opacity: 0.3;
}

.ann_head {
}

.left_width {
  width: 100%;
}

.right_width {
  /* width: 10%; */
}

.ann_para {
  list-style: circle;
}

.sub_head_para {
  color: yellow;
}

/* ._item_:last-child{
      grid-column: 1 / -1;
} */

.grid {
  stroke: 10;
}

.left_width {
  width: 100%;
}

.ann_para {
  list-style: circle;
}

.sub_head_para {
  color: yellow;
}

.grid {
  stroke: 10;
}

.tool_toggle_input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
}

.tool_toggle_label {
  cursor: pointer;
  text-indent: -9999px;
  width: 23px;
  height: 12px;
  display: block;
  border-radius: 100px;
  position: relative;
}

.tool_toggle_label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 2px;
  width: 10px;
  height: 10px;
  background: var(--navBar2Bg);
  border-radius: 90px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  transition: var(--transition);
}

.tool_toggle_label {
  /* background: #5aba81; */
  background: var(--toggleSliderBg);
  transition: var(--transition);
}

.tool_toggle_input:checked + .tool_toggle_label:after {
  /* left: calc(100% - 2px); */
  -webkit-transform: translateX(9px);
  transform: translateX(9px);
}

.tool_toggle_label:active:after {
  /* width: 130px; */
}

.tool_toggle_input:checked + ._tooltip {
  /* background: #5aba81; */
  background: var(--activeTextColor);
}

.tool_toggle_input:checked + ._alert {
  /* background: #ff2200; */
  background: var(--activeTextColor);
}

.tool_toggle_input:checked + ._zoom {
  /* background: #00bcd4; */
  background: var(--activeTextColor);
}

/* ._tooltip{
  background: #5aba81;

} */
/* ._alert {
  background: #ff2200;
} */
/* ._zoom{
  background: #00bcd4;
} */
.muted_bg {
  background: #3c3a3a;
}

.muted_bg:after {
  background: #616161;
}

.tool_toggle_input:checked + ._tooltip {
  background: var(--activeTextColor);
}

.tool_toggle_input:checked + ._alert {
  background: var(--activeTextColor);
}

.tool_toggle_input:checked + ._zoom {
  background: var(--activeTextColor);
}

.muted_bg {
  background: #3c3a3a;
}

.muted_bg:after {
  background: #616161;
}

.tool_section {
  display: flex;
  justify-content: center;
  align-self: center;
  margin-top: 4px;
}

.live_section_btn {
  display: flex;
  height: 100%;
  align-items: center;
}

.drill_eff_left {
  width: 100% !important;
}

.drill_eff_left {
  width: 100% !important;
}

.drill_eff_item_1 {
  grid-column: 2;
  grid-row: 1;
}

.drill_eff_item_2 {
  grid-column: 2;
  grid-row: 2;
}

.drill_eff_item_3 {
  grid-column: 2;
  grid-row: 2;
}

.dv {
  fill: lightblue;
  font-weight: 700;
  letter-spacing: 0.1px;
}

.ddv {
  fill: #32e73f;
  font-weight: 700;
  letter-spacing: 0.1px;
}

.dp__input {
  padding: 4px !important;
  color: #ffffff !important;
  background: #0e1016 !important;
}

.dp__main {
  margin: 1px 2px;
  margin-top: 0;
}

.noUi-vertical .noUi-tooltip {
  font-size: 7px !important;
  letter-spacing: 0.4px !important;
  font-weight: 600 !important;
}

mark {
  background: unset;
  background: red;
  color: white;
  font-size: 0.55vw;
  font-weight: 300;
  padding: 0 3px;
}

[type="checkbox"],
[type="radio"] {
  content: "";
  background-color: #28282f !important;
  background-color: unset !important;
  margin-left: auto;
  border-radius: 0;
}

[type="checkbox"]:checked,
[type="radio"]:checked {
  border: 1px solid #5aba81 !important;
  /* background-image: url(/img/check_ico.406e337a.svg); */
}

.dp__input {
  background-color: #262626 !important;
  color: #ffffff !important;
}

.dis_live_btn {
  position: relative;
  right: -7px;
  top: -8px;
}

.non_dis_sec {
  /* width: 130px; */
  display: flex;
  justify-content: space-around;
  gap: 6px;
}

.actc_tool {
  font-size: 10px;
  background: #000000;
  width: auto;
  position: absolute;
  padding: 2px 5px;
  /* border-radius: 20px; */
}

.disabled {
  pointer-events: none;
  cursor: default;
  display: inline-block;
  padding: 4px;
  background-color: #b6adb4;
  border-radius: 2px;
  border: 0px;
  text-align: center;
  /* color: #fff !important; */
  text-decoration: none !important;
  opacity: 0.5;
  z-index: -1;
}

.track_config_border {
  /* border-left: 1px solid #333;
  border-right: 1px solid #333;  */

  /* margin:0.5px; */
  /* margin: 2px;
  */
  margin-bottom: 1px;
}

.td_unit_ {
  font-size: calc((0.2em + 0.2vmin) + (0.2em + 0.2vmax));
}

.td_unit_ {
  font-size: calc((0.2em + 0.2vmin) + (0.2em + 0.2vmax));
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.depth_pick {
  background: unset !important;
  width: 100% !important;
  height: 20px !important;
  font-size: 10px !important;
  margin: 0 3px;
}

.feet_ {
  width: 25%;
}

.feet_boxed {
  width: 75%;
}

.noUi-handle {
  width: 48px !important;
}

.name_numaric_con {
  width: calc(100% - 5px);
  background: var(--colorBoxBg);
  margin: 0 3px;
  display: block;
  /* background:#343538 */
}

.dark_trend_mode {
  background: var(--wdigetBg);
  transition: var(--transition);
}

.colorBox_num {
  color: var(--colorBoxWhiteNum);
}

.colorBox_text_gray {
  color: var(--colorBoxHeadingText);
  font-size: 0.55rem;
  margin-top: 0.07rem;
}

.colorBox_textMesure {
  font-size: 9px;
  color: var(--colorBoxHeadingText);
  letter-spacing: 0.7px;
  font-weight: 600;
}

.arrangementClass {
  /* display: flex;
  flex-direction: column-reverse; */
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.15);
}

.liveBtnDot {
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50%;
  /* background-color: #12B822; */
  /* background:var(--liveBtnGreen) */
}

.newStylesLiveBtn {
  display: flex;
  align-items: center;
  gap: 7px;
  border-radius: 10px;
  padding: 2px 8px;
  /* selectingTimeFeetColorBg */
  background: var(--liveBtnGreen) !important;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 9px !important;
  color: #ffffff !important;
  /* display: flex;
  align-items: center;
  gap: 7px;
  border-radius: 10px;
  padding: 2px 8px;
  background: var(--liveBtnGreen) !important;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 9px !important;
  color: white !important; */
}

.newStWDepth {
  margin: 0px;
}

.newStWDeptInput {
  background: var(--colorBoxPopUpInputBg) !important;
  border: none;
  color: var(--selectingTimeFeetColor) !important;
  margin-left: 0px;
}

.normalBg {
  /* background: var(--selectingTimeFeetColorBg);
  color: var(--selectingTimeFeetColor); */
  background: var(--colorBoxBg);
  color: var(--selectingTimeFeetColor);
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.15);
}

.activeBg {
  background: var(--activeTextColor);
  color: var(--activeFeetBtnText);
}

.timeBtn {
  padding: 0px 7px;
  border-radius: 8px;
}

.arrangementClass {
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.15);
}

.settingPopColor {
  color: var(--textColor);
  font-weight: 600;
}

.__toggle__boxes {
  height: 20px;
  /* margin-top: 5px; */
  /* padding-left: 10px; */
  padding-right: 5px;
}

.__label_boxes {
  margin-top: auto;
}

.__trend_bg_seperation {
  border: solid 0.5px;
  border-color: dimgray;
  background: var(--sidebarbg);
}

.value_of_Mnemonic_in_corTrend {
  font-size: 0.6rem;
}

.rages_of_mnemonics_in_corTrend {
  padding-bottom: 0.1rem;
  font-size: 0.55rem;
}
</style>
