import WellServices from "./well"
import AuthServices from "./auth"
import DashBoardServices from "./dashboard"
import AlertServices from "./alert"
import SupportServices from "./support"
import datagapsServices from "./datagaps"
import lwdServices from "./lwd"
import UploadLwd from "./UploadLwd"
import VerifyUploadLwd from "./VerifyUploadLwd"
export default {
    WellServices,
    AuthServices,
    DashBoardServices,
    AlertServices,
    SupportServices,
    datagapsServices,
    lwdServices,
    UploadLwd,
    VerifyUploadLwd
}