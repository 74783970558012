<template>
  <div ref="customer_list_popUp"
    class="notificationPopUp bg-white text-base float-left list-none text-left rounded shadow-lg min-w-48 absolute cust_drop_list"
    id="menu_top_setting" :style="{ visibility: 'hidden' }" style="width: 70%">
    <div @click="stopPropagation" class="accordion flex flex-col items-center justify-center h-screen">
      <!--  Panel 1  -->
      <div class="w-full">
        <input @click="($event) => $event.stopPropagation()" type="checkbox" name="panel" id="panel-1" class="hidden" />
        <label for="panel-1" class="relative block appS_ px-4 py-4 shadow m-0 acc_label customerLabel">Customer</label>
        <div class="appS_ accordion__content overflow-hidden">
          <p class="accordion__body py-0 custo_accordian_" id="panel1">
            <router-link :key="index" v-for="(i, index) of customers" to=""
              class="cust_drop_list_apps px-4 py-2 font-normal block w-full whitespace-nowrap bg-transparent flex"
              :class="i == $store.state.data.customer ? 'active_customer' : 'inActive_customer'">
              <img :src="'https://apollodarticons.s3.ap-south-1.amazonaws.com/' + this.customerNames[index]" id="selector"
                class="m-0 cust_logo_list" @error="imgErrorHandler" @click="setCustomer(i)" />
              <div class="truncate" @click="setCustomer(i)">
                <p class="ml-2 mt-4">{{ i }}</p>
              </div>
              <button
                class="bg-black text-white active:bg-black text-xs font-thin uppercase py-0.5 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-0 lg:mb-0 ml-3 mb-3 _phone"
                type="button" @click="
                  i.trim() == 'Valence' ? formModal() : formModal()
                  ">
                <i class="fas fa-phone-volume"></i>
              </button>
            </router-link>
          </p>
        </div>
      </div>
    </div>
    <div class="h-0 mx-4" />
    <a v-if="is_super_admin || all_access.includes('WELL ADMINISTRATION')
      " @click="redirect()">
      <label for="" class="relative block appS_ text-white px-4 py-4 shadow">Well Administration</label>
    </a>
    <a @click="toggleUserProfile">
      <label for="" class="relative block appS_ text-white px-4 py-4 shadow">User Profile</label>
    </a>
    <a @click="logout()"
      class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-white cust_drop_list_apps logout_accordian">
      <span class="mx-2">
        <i class="fas fa-sign-out-alt" aria-hidden="true"></i>
      </span>
      Logout
    </a>
  </div>
</template>
<script>
import customer_default_logo from "../../assets/img/user.png";
import api from "../../api/services";
import URL from "../../config";
import jwt_decode from "jwt-decode";
import dictionary from "../../api/dictionary";
import axios from "axios";
import API_SERVICE from "../../api/services";
export default {
  name: "SettingDropdowns",
  props: {
    toggleDropdown: Function,
    toggleUserProfile: Function
  },
  data() {
    return {
      customers: [],
      customerNames: [],
      subEntityList: [],
      roleType: [],
      usersData: null,
      getCust: "",
    };
  },
  methods: {
    stopPropagation(event) {
      event.stopPropagation();
    },
    imgErrorHandler(event) {
      event.target.src = customer_default_logo;
    },
    async formModal() {
      if (
        document
          .getElementById("form-modal")
          .classList.value.indexOf("hidden") > -1
      ) {
      }
      document.getElementById("form-modal").classList.toggle("hidden");
    },
    setCustomer(customer) {
      console.log(this.getCust, "getCust");
      this.getCust.entities.filter((obj) => {
        if (obj.entity_name + "_" + obj.sub_entity_name === customer) {
          localStorage.setItem("selectedEntityId", obj.entity_id);
        }
      });
      if (customer) {
        
      }
      let isInitial = false;
      if (!this.$store.state.disp.rects.length) isInitial = true;
      this.$store.dispatch("data/setCustomer", { customer, isInitial });
      console.log("____selected_customer", this.$store.state.data.customer);
      this.gettingTheCustomerInfo();
      this.toggleDropdown();
    },
    async gettingTheCustomerInfo() {
      try {
        let entityName;
        if (this.$store.state.data.customer) {
          entityName = this.$store.state.data.customer.split("_")[0];
        }
        if (entityName) {
          let subEntitiesRelatedToEntity =
            await api.SupportServices.gettingUsersRelatedToEntity({
              entityName,
            });
          let subEntityData = subEntitiesRelatedToEntity.data.subentities;
          for (let i of subEntityData) {
            this.subEntityList.push(i.subEntityName);
            for (let j of i.usersRoles) {
              this.roleType.push(j.roleName);
            }
          }
          this.usersData = subEntitiesRelatedToEntity.data;
          let dtlsOfuser = {
            subEntities: this.subEntityList,
            roleType: this.roleType,
            data: this.usersData,
          };
          this.$store.dispatch("data/settingUsersData", dtlsOfuser);
        }
      } catch (error) {
        console.error(error);
      }
    },
    redirect: async function () {
      //check for access token expiretion
      const { access_token, refresh_token } =
        this.$serviceHelpers.getDetails("670023242:az");
      const token = jwt_decode(access_token);
      const isExpired = new Date() <= new Date(token.exp * 1000);
      if (!isExpired) {
        const response = await axios.post(
          URL.HOST_URL.SERVER_URL + dictionary.auth.REFRESH_TOKEN,
          {
            token: refresh_token,
          }
        );
        console.log(typeof response);
        const newTokenDet = response.data;
        if (newTokenDet && newTokenDet.access_token) {
          //update the localstorage
          this.$serviceHelpers.setDetails("670023242:az", newTokenDet);
          window.open(
            `${URL.HOST_URL.ADMINISTRATOR_URL
            }auth/login?dfc364ca-86b9-5162-9726-79c48cf0d0c4=${JSON.stringify(
              this.$serviceHelpers.getDetails("y:a")
            )}&abcbf877-edea-5d5b-91c3-a5bba8374a10=${JSON.stringify(
              newTokenDet
            )}`
          );
          console.log("Access token is not valid");
        } else {
          console.log("Access token err", newTokenDet);
          this.$toast.error(`Token is Invalid Please Login to proceed!`, {
            duration: "2000",
            position: "top",
            pauseOnHover: true,
          });
          this.logout();
        }
      } else {
        console.log("Access token is valid");
        window.open(
          `${URL.HOST_URL.ADMINISTRATOR_URL
          }auth/login?dfc364ca-86b9-5162-9726-79c48cf0d0c4=${JSON.stringify(
            this.$serviceHelpers.getDetails("y:a")
          )}&abcbf877-edea-5d5b-91c3-a5bba8374a10=${JSON.stringify(
            this.$serviceHelpers.getDetails("670023242:az")
          )}`
        );
      }
    },
    logout: async function () {
      let logout_body = this.$serviceHelpers.getDetails("y:a");
      await API_SERVICE.AuthServices.emp_logout(logout_body);
      localStorage.clear();
      window.location.reload();
    },
  },
  computed: {
    is_super_admin() {
      return this.$store.state.data.is_super_admin;
    },
    all_access() {
      return this.$store.state.data.access_all;
    },
  },
  mounted() {
    let apollodart = this.$serviceHelpers.getDetails("apollodart");
    this.getCust = apollodart;
    this.customers = apollodart.customers;
    for (let i of this.customers) {
      let arrayOfSplited = i.split("_");
      this.customerNames.push(arrayOfSplited[0].toLowerCase());
    }
  },
};
</script>
