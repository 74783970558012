<template>
  <div class="__trend_bg_seperation" @contextmenu="this.setContextIdWidget">
    <!-- without image -->
    <div
      class="block tool_group"
      :style="{
        top: this.mouse_y + 'px',
        left: this.mouse_x + 'px',
      }"
    >
      <p v-if="isActcBarHover" class="actc_tool text-white">
        {{ actcBarMessage }}
      </p>
    </div>
    <div
      v-if="this.isShowAlerts"
      class="block tool_group"
      :style="{
        top: this.mouse_y + 'px',
        left: this.mouse_x + 'px',
      }"
    >
      <div
        v-for="i in alertAnnotations"
        :key="i"
        class="px-1 py-1 border-green-700 text-green-700 tooltip_actc"
      >
        <template v-if="i.alert_name.replace(/\s/g, '') == hoveringAlert">
          <span class="tooltiptext">
            <div class="grid-container">
              <div class="item1 _item_">
                <div class="flex">
                  <div class="left_width font_xs">
                    <div class="flex justify-between">
                      <p class="ann_head">
                        {{ i.alert_name }}
                        <br />
                      </p>
                    </div>
                    <ul class="ann_para">
                      <div
                        class="annotation_desc"
                        v-html="JSON.parse(i.description).description1"
                      ></div>
                      <div
                        class="annotation_desc"
                        v-html="JSON.parse(i.description).description2"
                      ></div>

                      <div
                        class="annotation_desc"
                        v-html="JSON.parse(i.description).description3"
                      ></div>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- <div class="item2 _item_">
                <div class="flex ...">
                  <div class="left_width font_xs">
                    <ul class="ann_para">

                    </ul>
                  </div>
                </div>
              </div> -->
            </div>
          </span>
        </template>
      </div>
    </div>
    <div
      v-if="this.isShowAlerts"
      class="block tool_group"
      :style="{
        top: this.mouse_y_clicked + '%',
        right: this.mouse_x_clicked + '%',
      }"
    >
      <div
        v-for="i in alertAnnotations"
        :key="i"
        class="px-1 py-1 border-green-700 text-green-700 tooltip_actc"
      >
        <template v-if="i.alert_name.replace(/\s/g, '') == clickedAlert">
          <span class="tooltiptext">
            <div class="grid-container">
              <div class="item1 _item_">
                <div class="flex">
                  <div class="left_width font_xs">
                    <div class="flex justify-between">
                      <p class="ann_head">
                        {{ i.alert_name }}
                        <br />
                      </p>
                      <p
                        @click="clickedAlert = ''"
                        :class="!show ? '' : 'text-white'"
                        class="cursor-pointer"
                      >
                        <svg
                          @click="clickedAlert = ''"
                          class="crossToHide"
                          :class="{ lightIcon: darkDark == 'white' }"
                          xmlns="http://www.w3.org/2000/svg"
                          width="11"
                          height="11"
                          viewBox="0 0 10 10"
                          fill="none"
                        >
                          <path
                            @click="clickedAlert = ''"
                            d="M5.93996 5.00004L8.80662 2.14004C8.93216 2.01451 9.00269 1.84424 9.00269 1.66671C9.00269 1.48917 8.93216 1.31891 8.80662 1.19338C8.68109 1.06784 8.51083 0.997314 8.33329 0.997314C8.15576 0.997314 7.9855 1.06784 7.85996 1.19338L4.99996 4.06004L2.13996 1.19338C2.01442 1.06784 1.84416 0.997314 1.66663 0.997314C1.48909 0.997314 1.31883 1.06784 1.19329 1.19338C1.06776 1.31891 0.997231 1.48917 0.997231 1.66671C0.997231 1.84424 1.06776 2.01451 1.19329 2.14004L4.05996 5.00004L1.19329 7.86004C1.13081 7.92202 1.08121 7.99575 1.04737 8.07699C1.01352 8.15823 0.996094 8.24537 0.996094 8.33337C0.996094 8.42138 1.01352 8.50852 1.04737 8.58976C1.08121 8.671 1.13081 8.74473 1.19329 8.80671C1.25527 8.86919 1.329 8.91879 1.41024 8.95264C1.49148 8.98648 1.57862 9.00391 1.66663 9.00391C1.75463 9.00391 1.84177 8.98648 1.92301 8.95264C2.00425 8.91879 2.07798 8.86919 2.13996 8.80671L4.99996 5.94004L7.85996 8.80671C7.92193 8.86919 7.99567 8.91879 8.07691 8.95264C8.15815 8.98648 8.24528 9.00391 8.33329 9.00391C8.4213 9.00391 8.50844 8.98648 8.58968 8.95264C8.67092 8.91879 8.74465 8.86919 8.80662 8.80671C8.86911 8.74473 8.91871 8.671 8.95255 8.58976C8.9864 8.50852 9.00382 8.42138 9.00382 8.33337C9.00382 8.24537 8.9864 8.15823 8.95255 8.07699C8.91871 7.99575 8.86911 7.92202 8.80662 7.86004L5.93996 5.00004Z"
                            fill="white"
                          />
                        </svg>
                      </p>
                    </div>
                    <ul class="ann_para">
                      <div
                        class="annotation_desc"
                        v-html="JSON.parse(i.description).description1"
                      ></div>
                      <div
                        class="annotation_desc"
                        v-html="JSON.parse(i.description).description2"
                      ></div>

                      <div
                        class="annotation_desc"
                        v-html="JSON.parse(i.description).description3"
                      ></div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </span>
        </template>
      </div>
    </div>
    <!-- without image end -->

    <div
      :id="'main' + compId"
      :style="{
        height: wh.height + 'px',
        width: wh.width + 'px',
      }"
      :class="darkDark !== 'white' ? 'noUi-target_dup' : 'light_trend-mode'"
      class="arrangementClass"
    >
      <div class="flex justify-between">
        <div
          v-if="logType == 'time'"
          class="ml-0 mr-1 flex text-center text-sm mt-2"
        >
          <span style="font-size: 5px; height: 1.9rem">
            <div
              class="flex ml-3"
              :class="this.isDis == false ? 'non_dis_sec' : ''"
              style="gap: 6px"
            >
              <div v-for="(time, i) in timeRanges" :key="i">
                <div
                  :style="{
                    fontSize: 10 + 'px',
                    marginTop: '-4px',
                    marginLeft: '-1px',
                  }"
                >
                  <!-- <input class="mx-1 border-radius-0" type="radio" id="time" name="timeRange" v-bind:value="time"
                                  v-model="currentTimeRange" @change="selectTimeRange(time)" />
                                <div>
                                  <label class="mr-2" for="time"> {{ time }} </label>
                                </div> -->
                  <button
                    class="timeBtn"
                    :class="currentTimeRange == time ? 'activeBg' : 'normalBg'"
                    @click="selectTimeRange(time, true)"
                  >
                    <div class="flex">
                      <span>{{ time }}</span>
                      <span class="ml-1">{{ time == 1 ? " HR" : " HRS" }}</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </span>
        </div>

        <div v-if="logType == 'depth'" class="mt-2" style="height: 30px">
          <!-- <span class="text_xs feet_">Feet :</span> -->
          <span class="feet_boxed" style="font-size: 10px; height: 1.9rem">
            <div class="flex justify-around ml-3" style="gap: 5px">
              <div v-for="(feet, i) in feetRange" :key="i">
                <div
                  :style="{
                    fontSize: 10 + 'px',
                    marginTop: '-4px',
                    marginLeft: '-1px',
                  }"
                >
                  <button
                    class="timeBtn"
                    style="padding: 2px 3px"
                    :class="currentFeetRange == feet ? 'activeBg' : 'normalBg'"
                    @click="selectFeet(feet, true)"
                  >
                    <div class="flex">
                      <span>{{ feet }}</span>
                      <span>FT</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </span>
        </div>

        <div v-if="logType">
          <div class="flex justify-around mt-1">
            <div class="flex items-center justify-between __toggle__boxes">
              <div class="flex">
                <div class="mt-1 settingPopColor mb-1" :class="'white-white'">
                  Alert Annotations
                </div>
                <div class="ml-0.5 tool_section">
                  <input
                    type="checkbox"
                    :id="'alertswitch' + this.id"
                    class="tool_toggle_input"
                    v-bind:value="isShowAlerts"
                    @click="changeAlert()"
                  />
                  <label
                    class="tool_toggle_label ml-1 mb-1"
                    :class="'_alert'"
                    :for="'alertswitch' + this.id"
                    >Alerts :</label
                  >
                </div>
              </div>
            </div>
            <div class="flex items-center justify-between __toggle__boxes">
              <div class="flex">
                <div
                  class="mt-1 settingPopColor mb-1"
                  :class="isZoom ? 'text-gray-600' : 'white-white'"
                >
                  Tooltip
                </div>
                <div class="ml-0.5 tool_section">
                  <input
                    type="checkbox"
                    :id="'switch' + this.id"
                    class="tool_toggle_input"
                    v-bind:value="isShowTooltip"
                    @click="changeTooltip()"
                    :disabled="isZoom"
                  />
                  <label
                    class="tool_toggle_label ml-1 mb-1"
                    :class="isZoom ? 'muted_bg' : '_tooltip'"
                    :for="'switch' + this.id"
                    >Tooltip :</label
                  >
                </div>
              </div>
            </div>
            <div class="flex items-center justify-between __toggle__boxes">
              <div class="flex">
                <div class="mt-1 settingPopColor mb-1">Zoom</div>
                <div class="ml-0.5 tool_section">
                  <input
                    type="checkbox"
                    :id="'zoomswitch' + this.id"
                    :checked="this.isZoom"
                    class="tool_toggle_input"
                    v-bind:value="isZoom"
                    @click="changeZoom()"
                  />
                  <label
                    class="tool_toggle_label _zoom ml-1 mb-1"
                    :for="'zoomswitch' + this.id"
                    >Zoom :</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="flex justify-start bg-white"
        :style="{
          width: wh.templateWidth + 'px',
          height: bheight + 'px',
          background: 'var(--wdigetBg)',
          transition: 'var(--transition)',
        }"
      >
        <div
          class="text-white px-2"
          :style="{
            zIndex: 0,
            height: bheight + 'px',
            width: sliderWidth + leftbarWidth + 'px',
          }"
        >
          <div
            v-if="logType == 'time'"
            class="flex flex-col"
            style="height: fit-content"
          >
            <Datepicker
              class=""
              v-model="scaleMinDate"
              :minDate="
                new Date(scaleMinFixed).toLocaleString('en-US', {
                  timeZone: this.time_zone,
                })
              "
              :maxDate="
                new Date(scaleMaxFixed).toLocaleString('en-US', {
                  timeZone: this.time_zone,
                })
              "
              :dark="darkDark != 'white'"
              textInput
              placeholder="MM/DD/YYYY"
              :enableTimePicker="false"
              :timezone="this.time_zone ? this.time_zone : null"
              @update:modelValue="onFromDateChange"
            />
            <div style="gap: 10px" class="mt-1 ml-1 flex flex-col">
              <div
                class="mt-0 flex flex-col text_xs mr-0.5"
                style="
                  font-size: min(0.9vw, 12px);
                  color: var(--textColor);
                  letter-spacing: 1px;
                  font-weight: var(--fontWeight);
                "
              >
                <span>
                  Start time: <br />
                  {{
                    new Date(scaleMinFixed).toLocaleDateString("en-US", {
                      timeZone: this.time_zone,
                    })
                  }},
                  {{
                    new Date(scaleMinFixed).toLocaleTimeString("en-US", {
                      timeZone: this.time_zone,
                    })
                  }}
                  <!-- {{
                    new Date(scaleMinFixed).toLocaleTimeString("en-US", {
                      timeZone: this.time_zone,
                    })
                  }} -->
                </span>
                <!-- <span>
                  {{
                    new Date(scaleMinFixed).toLocaleTimeString("en-US", {
                      timeZone: this.time_zone,
                    })
                  }}</span> -->
              </div>

              <div
                class="mt-0 flex flex-col text_xs mr-0.5"
                style="
                  font-size: min(0.9vw, 12px);
                  color: var(--textColor);
                  letter-spacing: '1px';
                  font-weight: var(--fontWeight);
                "
              >
                <span>
                  End time: <br />
                  {{
                    new Date(scaleMaxFixed).toLocaleDateString("en-US", {
                      timeZone: this.time_zone,
                    })
                  }},
                  {{
                    new Date(scaleMaxFixed).toLocaleTimeString("en-US", {
                      timeZone: this.time_zone,
                    })
                  }}</span
                >
                <!-- <span>
                  {{
                    new Date(scaleMaxFixed).toLocaleTimeString("en-US", {
                      timeZone: this.time_zone,
                    })
                  }}</span> -->
              </div>
            </div>
          </div>
          <div v-if="logType == 'depth'" class="flex mt-1">
            <div class="block" style="width: 75%">
              <div
                :style="{
                  fontSize: 9 + 'px',
                  color: 'var(--colorBoxHeadingText)',
                }"
                class=""
                :class="isZoom ? 'text-gray-600' : 'white-white'"
              >
                WELL DEPTH (ft)
              </div>
              <div class="flex flex-col">
                <div class="ml-0.5 tool_section newStWDepth">
                  <input
                    type="number"
                    :value="
                      !isNaN(this.scaleMin) ? this.scaleMin.toFixed(0) : 0
                    "
                    class="depth_pick newStWDeptInput"
                    @input="
                      ($event) => {
                        !isNaN($event.target.value) &&
                          selectStartFeet($event.target.value);
                      }
                    "
                    @keyup.enter="selectStartFeet($event.target.value)"
                  />
                </div>
                <span
                  :style="{
                    fontSize: 9 + 'px',
                    color: 'var(--colorBoxHeadingText)',
                  }"
                  class="ml-auto mr-auto"
                  :class="isZoom ? 'text-gray-600' : 'white-white'"
                  >TO</span
                >
                <div class="ml-0.5 tool_section w-full newStWDepth">
                  <input
                    type="number"
                    :value="
                      !isNaN(this.scaleMax) ? this.scaleMax.toFixed(0) : 0
                    "
                    @input="
                      ($event) => {
                        !isNaN($event.target.value) &&
                          selectEndFeet($event.target.value);
                      }
                    "
                    @keyup.enter="selectEndFeet($event.target.value)"
                    class="depth_pick newStWDeptInput"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="flex justify-center"></div>
        </div>
        <div
          class="flex"
          :class="darkDark !== 'white' ? 'dark_trend_mode' : 'light_trend-mode'"
          :style="{
            width: wh.templateWidth - sliderWidth - leftbarWidth + 'px',
            maxWidth: templateWidth + 'px',
            height: bheight + 'px',
            background: 'var(--wdigetBg)',
            transition: 'var(--transition)',
          }"
        >
          <div v-if="trackConfig.tracks">
            <div class="flex h-full">
              <div
                class="__label_boxes"
                v-for="(track, j) in trackConfig.tracks"
                :key="j"
              >
                <div
                  id="tempdiv"
                  v-for="(curve, i) in track"
                  :key="i"
                  class="relative flex track_config_border bg-opacity-99 __bg_label_boxes"
                  :class="getAlertClassOnCondition(curve)"
                  :style="{
                    width: singleTrackWidth + 'px',
                    marginRight: 0 + 'px',
                    // fontSize: 52 + '%',
                    height: 45 + 'px',
                    marginLeft: j == 0 ? '0px' : '0',
                    outline: `1px solid ${
                      darkDark !== 'white' ? 'var(--nav1BgClr)' : '#c1c0c0'
                    }`,
                  }"
                  :draggable="true"
                  @dragstart="
                    startDrag($event, {
                      name: 'child',
                      trackNbr: j,
                      color: 'green',
                      curveNbr: i,
                      title: curve,
                      origin: compId,
                    });

                    $event.stopPropagation();
                  "
                >
                  <div class="t_d_col_1">
                    <button
                      v-on:click="showProps1(j, i)"
                      id='j+"-"+i'
                      class="colorbox"
                      :style="{
                        backgroundColor: curve.color,
                      }"
                    />
                  </div>
                  <div
                    v-if="
                      curveProps[j + '-' + i] && curveProps[j + '-' + i]['show']
                    "
                    :class="'curve_pop_opened'"
                    :style="{ right: singleTrackWidth + 2 + 'px' }"
                  >
                    <Props
                      :width="120"
                      :height="100 + '%'"
                      :curve="curveProps[j + '-' + i]['curve']"
                      @closeIt="closeIt(j, i)"
                      @apply="setCurveProps(j, i, $event)"
                      @delete="deleteCurve(j, i, $event)"
                      :alertArray="this.alertArr"
                    />
                  </div>
                  <div
                    class="name_numaric_con flex flex-col justify-center tooltip__tip top"
                    :class="
                      this.darkDark !== 'white'
                        ? ' tooltip__tip_dark'
                        : ' tooltip__tip_light'
                    "
                    :data-tip="getLatestValuesToDisplay(curve)"
                  >
                    <div
                      style="paddingleft: 0.8px"
                      class="td_trnk"
                      :class="
                        ['activitycode', 'alert', 'washoutindex'].includes(
                          curve.name
                        )
                          ? 'colorBox_text_gray margin_top_title'
                          : 'colorBox_text_gray '
                      "
                    >
                      <!-- {{ curve.fullName.toUpperCase() }} -->
                      {{
                        curve.editedName
                          ? curve.editedName
                          : getFullNameDisplay(curve.name)
                      }}
                    </div>
                    <div
                      class="leading-none value_of_Mnemonic_in_DIS flex justify-center"
                    >
                      <p class="truncate">
                        {{ getLatestValuesToDisplay(curve) }}
                        &nbsp;
                      </p>
                    </div>
                    <div
                      class="font_xs flex justify-around rages_of_mnemonics_in_DIS"
                      v-if="
                        !['activitycode', 'alert', 'washoutindex'].includes(
                          curve.name
                        )
                      "
                    >
                      <p>{{ returningOfScale(curve) }}&nbsp;</p>
                      <p>
                        {{
                          curve.name == "mfi"
                            ? "gpm"
                            : this.$store.state.disp.displays[this.displayId]
                                .units[
                                this.$store.state.disp.displays[
                                  this.displayId
                                ].tags.indexOf(curve.name)
                              ]
                        }}
                      </p>
                      <p>&nbsp;{{ returningOfScale1(curve) }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- live btn -->
      <Teleport :to="'#teleportFromTrendsLiveBtn' + displayId">
        <div class="block">
          <div class="ml-0.5 tool_section live_section_btn">
            <div
              :class="status == 'UPDATING' ? '' : 'tooltip__tip_live top'"
              :data-tip="status === 'UPDATING' ? 'text' : 'No live data'"
            >
              <button
                :class="status == 'UPDATING' ? '' : 'disabled '"
                v-if="logType == 'time' || logType == 'depth'"
                @click="makeItLive"
                :disabled="liveDisabled"
                class="button-depth noselect text_xs float-right ml-2 newStylesLiveBtn"
                :style="
                  liveDisabled
                    ? 'color: #fff; background:var(--liveBtnGreen)' +
                      (this.logType == 'depth' ? '' : '')
                    : 'background:#b6adb4; color:#fff;' +
                      (this.logType == 'depth' ? '' : '')
                "
              >
                <div
                  class="liveBtnDot"
                  :style="
                    liveDisabled === true
                      ? 'background:#ffffff'
                      : 'background:#fff'
                  "
                ></div>
                LIVE
              </button>
            </div>
          </div>
        </div>
      </Teleport>
      <div
        id="distrenddiv"
        class="flex justify-start"
        :style="{ width: width + 'px' }"
      >
        <div
          :id="compId + 'localSliderParent'"
          class="flex relative"
          :class="darkDark !== 'white' ? 'noUi-target_dup' : 'light_trend-mode'"
          :style="{
            marginTop: '20px',
            height: singleTrackHeight - 40 + 'px',
            width: sliderWidth + 'px',
          }"
        >
          <div
            :style="{
              position: 'absolute',
              width: sliderWidth + 'px',
              height: singleTrackHeight - 40 + 'px',
            }"
            :id="compId + 'localSlider'"
          ></div>
        </div>

        <div
          class="flex"
          :class="darkDark !== 'white' ? 'bg-black' : 'light_trend-mode'"
          :style="{
            height: singleTrackHeight + 'px',
            width: wh.templateWidth - sliderWidth + 'px',
          }"
        >
          <div
            class="scrolldiv bg-opcity-95 flex"
            :class="
              darkDark !== 'white' ? 'bg-black_mattie' : 'light_trend-mode'
            "
            :style="{
              height: singleTrackHeight + 'px',
              width: wh.templateWidth - sliderWidth + 'px',
              maxWidth: width - sliderWidth + 'px',
            }"
            :id="div_id"
            @drop="onDrop($event, 1)"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import nouislider from "../../../js/noUiSlider-15.2.0/src/nouislider.js";
import "../../../js/noUiSlider-15.2.0/src/slider.css";
import { template } from "../../../js/vertical/template.js";
import plotConfig from "../../../js/vertical/wellconfig.js";
import * as d3 from "d3";
import Props from "../Trend/Trendprops.vue";
import CustomSelect from "../../Select/SelectComponent.vue";
import apiService from "../../../api/services";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import defaultRanges from "../../../js/ranges";

// import moment from "moment/src/moment";
import moment from "moment-timezone";

export default {
  name: "DisTrend",
  components: {
    Props,
    CustomSelect,
    Datepicker,
  },
  data() {
    return {
      mnemonic_groups: {},
      alertArr: [
        "ERRATIC MSE",
        "DRLG INEFF.",
        "ERRATIC ROP",
        "STACKING WEIGHT",
        "PRESSURE SPIKE",
        "BIT IMPACT",
        "MUD MOTOR DAMAGE POSSIBLE",
        "MUD MOTOR DAMAGE PROBABLE",
        "MOTOR STALL PROBABLE",
        "DIFF STICK"
      ],
      hoveringAlert: "",
      clickedAlert: "",
      mouse_y_clicked: 25,
      mouse_x_clicked: 2,
      scaleMinDate: "",
      isZoom: false,
      isRerendering: false,

      zero: [
        "wob",
        "mse",
        "diff",
        "spm1",
        "spm2",
        "spm3",
        "spp",
        "tgpm",
        "rpm",
        "mg1c",
        "simplifiedmse",
        "chkp",
      ],
      double: [
        "gainloss",
        "flow_out",
        "mfop",
        "hkl",
        "bpos",
        "dbtm",
        "dmea",
        "rop",
        "tor",
        "drtm",
        "inc",
        "az",
        "tvd",
        "dens_in",
        "dens_out",
        "temp_in",
        "temp_out",
      ],
      triple: ["stickslipindex", "depthofcut"],
      mouse_x: 0,
      mouse_y: 0,
      isEraticMse: false,
      isMotorStalled: false,
      isMotorDamagePossible: false,
      isMotorDamageProbable: false,
      isPressSpike: false,
      isErraticRop: false,
      isStackingWeight: false,
      isDrillInEfficiency: false,
      isDiffPressAlert: false,
      isBitImpact: false,
      isDis: false,
      // Socket Variables:
      stopUpdation: false,
      isShowTooltip: false,
      isShowAlerts: false,
      depthMaxValue: 0,
      depthMinValue: 0,
      currentTimeRange: 1,
      currentFeetRange: 500,
      topic: null,
      socketId: null,
      socket: null,

      liveDisabled: false,
      timeRanges: [1, 2, 8, 12, 24],
      secondsdataavailability: false,
      focusAxisList: [],
      bgColor: "#202124",
      configurePlots: {},
      templateWidth: "",
      div_id: "divId",
      tickColor: "#ededed",
      visible: false,
      sliderWidth: 170,
      sliderBG: "lightgray",
      readyToPlot: false,
      emptyPlot: true,
      currentCurve: null,
      selectedTrackValue: null,
      isNewTrack: false,
      curve: "",
      trackConfig: {},
      bheight: 138,
      wellId: null,
      wellboreId: null,
      logId: null,
      wellboreState: null,
      logType: null,
      data: [],
      completeData: [],
      zoomMin: null,
      zoomMax: null,
      plots: [],
      plotTemplate: template(""),
      curvesMinsMaxes: {},
      sliderWidth: 50,
      leftbarWidth: 140,
      trackWidth: 200,
      scaleMin: 0,
      scaleMax: 1000,
      scaleMinFixed: 0,
      scaleMaxFixed: 1000,
      singleTrackHeight: null,
      slider: null,
      noUislider: null,
      singleTrackWidth: 120,
      totTracks: [],
      compId: "",
      curveProps: {},
      templateHeight: 0,
      tracksNbr: 6,
      tickThickness: 0.1,
      tracks: [],
      renderedHeight: false,
      renderedWidth: false,
      widthChanged: false,
      heightChanged: false,
      // wh: {
      //   width: this.width,
      //   height: this.height,
      // },
      feetRange: [50, 100, 500, 1000],
      // hrsRange: ["default", 4, 8, 12, 24],
      feet: "",
      hrs: "",
      track1Id: "",
      track2Id: "",
      track3Id: "",
      track4Id: "",
      track5Id: "",
      isSpecialtyScreenCreated: false,
      updateTimeout: null,
      status: -1,
      active_popover: false,
      isActcBarHover: false,
      actcBarMessage: "",
      timer: -1,
      myDisWatcher: null,
      allow_to_change: null,
    };
  },
  props: {
    height: {
      type: [Number, String],
      default: "50",
    },
    width: {
      type: [Number, String],
      default: "50",
    },
    id: String,
    divid: String,
    displayId: String,
    plotConfigProps: {
      type: Object,
      default: {},
    },
    isReplay: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  beforeUnmount() {
    this.data = null;
    this.completeData = null;
    this.mnemonic_groups = null;
    this.takeOffSocket();
    this.myDisWatcher();
  },
  async mounted() {
    this.$store.dispatch("data/getAlertAnnotations");
    // this.$store.dispatch("data/getAlertsList");
    if (true) {
      this.isDis = true;
      this.leftbarWidth = 120;
    }
    this.customer = this.$store.state.data.customer;

    this.noUislider = nouislider;
    this.compId = this.compId = Math.random().toString(36).slice(8);
    this.compId = this.compId;
    this.div_id = "id" + this.compId;
    this.div_id = this.div_id;
    let dtls = this.$store.state.disp.displays[this.displayId];

    this.myDisWatcher = this.$store.subscribe(async (mutation, state) => {
      if (
        mutation.type == "disp/setDisplay" &&
        this.displayId == mutation.payload.display &&
        this.id
      ) {
        this.timer = -1;
        this.takeOffSocket();
        this.data = [];
        this.completeData = [];
        this.mnemonic_groups = {};
        this.wellId = mutation.payload.wellId;
        this.wellboreId = mutation.payload.wellboreId;
        this.logId = mutation.payload.logId;
        this.secondsdataavailability = mutation.payload.secondsdataavailability;
        this.logType =
          mutation.payload.logType == "trajectory"
            ? "time"
            : mutation.payload.logType;
        this.logTypeId = mutation.payload.logTypeId;
        this.status = mutation.payload.status;
        this.topic = mutation.payload.topicName;
        this.time_zone = mutation.payload.time_zone || "CST6CDT";
        moment.tz.setDefault(this.time_zone);
        // console.log("~~~ time zone alert : mutatiion ", this.time_zone);
        let behaviour = "drag";
        let format = {};
        let sliderOpt = {
          limit: 86400000,
          width: 100,
          orientation: "vertical",
          tooltips: true,

          height: this.singleTrackHeight,
          behaviour,
          connect: true,
        };
        if (this.logType == "time") {
          this.currentTimeRange = 1;

          let minMax = await this.getMinMaxRange();
          // this.scaleMin = minMax.max - 14400000;
          this.scaleMin =
            minMax.max - 3600000 > minMax.min
              ? minMax.max - 3600000
              : minMax.min;
          this.scaleMax =
            minMax.max - 3600000 > minMax.min
              ? minMax.max
              : minMax.min + 3600000;
          this.scaleMinFixed = minMax.min;
          this.scaleMaxFixed = minMax.max;

          this.scaleMinDate = new Date(this.scaleMin);
          this.scaleMaxDate = new Date(this.scaleMax);
          if (minMax.min > minMax.max) {
            this.data = {};
            this.completeData = {};
            this.rerender();
            this.$toast.error("Start time can not be greater than End time.", {
              position: "top",
              duration: 3000,
            });
            // return false;
          }

          format = {
            from: Number,
            to: function (value) {
              let date = moment(value).format("MM-DD-YYYY HH:mm:ss");
              return date.replace(" ", "</br>");
            },
          };
          sliderOpt.format = format;
        } else {
          this.currentFeetRange = 500;

          let minMax = await this.getMinMaxRange();
          if (minMax.max == minMax.min || minMax.max - 500 < minMax.min)
            minMax.max = minMax.min + 1000;
          this.scaleMin = minMax.max - 500;

          this.scaleMax = minMax.max;
          this.scaleMinFixed = minMax.min;
          this.scaleMaxFixed = minMax.max;
        }
        sliderOpt.range = {
          min: this.scaleMinFixed,
          max: this.scaleMaxFixed,
        };
        sliderOpt.start = [this.scaleMin, this.scaleMax];
        try {
          this.slider.noUiSlider.destroy();
        } catch (error) {
          // console.error("the slider error dis", error);
        }
        this.slider = document.getElementById(this.compId + "localSlider");

        let timeout_var_call = setTimeout(() => {
          clearTimeout(timeout_var_call);
          if (this.noUislider) {
            this.noUislider.create(this.slider, sliderOpt);
            this.slider.noUiSlider.on("change", (values) => {
              //
              this.updateScale(values);
              // if anyone changes the UI Slider then, it will disconnect live sockets & reset time range
              this.takeOffSocket();
              if (this.logType == "time") {
                if (this.scaleMax - this.scaleMin == 86400000) {
                  this.currentTimeRange = 24;
                } else if (this.scaleMax - this.scaleMin == 43200000) {
                  this.currentTimeRange = 12;
                } else if (this.scaleMax - this.scaleMin == 28800000) {
                  this.currentTimeRange = 8;
                } else if (this.scaleMax - this.scaleMin == 14400000) {
                  this.currentTimeRange = 4;
                } else if (this.scaleMax - this.scaleMin == 7200000) {
                  this.currentTimeRange = 2;
                } else if (this.scaleMax - this.scaleMin == 3600000) {
                  this.currentTimeRange = 1;
                } else {
                  this.currentTimeRange = "";
                }
              } else {
                if (this.scaleMax - this.scaleMin == 1000) {
                  this.currentTimeRange = 1000;
                } else if (this.scaleMax - this.scaleMin == 500) {
                  this.currentTimeRange = 500;
                } else if (this.scaleMax - this.scaleMin == 100) {
                  this.currentTimeRange = 100;
                } else if (this.scaleMax - this.scaleMin == 50) {
                  this.currentTimeRange = 50;
                } else {
                  this.currentTimeRange = "";
                }
              }
              this.currentTimeRange = "";
              this.liveDisabled = false;
              if (this.sliderchangetimeout)
                clearTimeout(this.sliderchangetimeout);
              this.sliderchangetimeout = setTimeout(() => {
                this.zoomIt(
                  this.scaleMax,
                  this.scaleMin,
                  this.feet,
                  false,
                  "on change timeout setdisplay"
                );
                clearTimeout(this.sliderchangetimeout);
              }, 350);
            });

            // this.slider.noUiSlider.on('update', (values) => {
            //   // Slider Update listner : when user move the slider this function run with upper and lower values.
            //   this.scaleMin = values[0];
            //   this.scaleMax = this.scaleMax = Math.round(values[1]);
            //   this.updateScale(values);
            //   if (this.updateTimeout) {
            //     clearTimeout(this.updateTimeout);
            //   }
            //   this.updateTimeout = setTimeout(() => {
            //     this.zoomIt(this.scaleMax, this.scaleMin, this.feet, false, "update 1409");
            //   }, 10);
            // });
          }
        }, 500);
        this.trackPropsMaping();
        if (this.status == "UPDATING") {
          let timeout_var_livedata = setTimeout(() => {
            clearTimeout(timeout_var_livedata);
            this.getRealtimeData();
          }, 600);
        } else {
        }
        this.zoomIt(
          this.scaleMax,
          this.scaleMin,
          this.feet,
          true,
          "update 1409"
        );
      }
    });
    let format = {};
    if (
      dtls != null &&
      dtls.wellId != null &&
      dtls.wellboreId != null &&
      dtls.logId != null &&
      dtls.wellboreState != null
    ) {
      this.wellId = this.wellId = dtls.wellId;
      this.wellboreId = this.wellboreId = dtls.wellboreId;
      this.logId = this.logId = dtls.logId;
      this.secondsdataavailability = dtls.secondsdataavailability;
      this.wellboreState = this.wellboreState = dtls.wellboreState;
      this.status = dtls.status;
      this.logType = dtls.logType == "trajectory" ? "time" : dtls.logType;
      this.logTypeId = dtls.logTypeId;
      this.topic = dtls.topicName;
      this.time_zone = dtls.time_zone || "CST6CDT";

      // console.log("~~~ time zone alert : mounted ", this.time_zone);
      moment.tz.setDefault(this.time_zone);
      let minMax = await this.getMinMaxRange();

      let limit = 0;

      if (this.logType == "time") {
        // this.scaleMin = minMax.max - 14400000;
        this.scaleMin =
          minMax.max - 3600000 > minMax.min ? minMax.max - 3600000 : minMax.min;
        this.scaleMax =
          minMax.max - 3600000 > minMax.min ? minMax.max : minMax.min + 3600000;
        this.scaleMinDate = new Date(this.scaleMin);
        this.scaleMaxDate = new Date(this.scaleMax);
        limit = 86400000;
        format = {
          from: Number,
          to: (value) => {
            let date = moment(value).format("MM-DD-YYYY HH:mm:ss");
            return date.replace(" ", "</br>");
          },
        };
        this.scaleMinFixed = minMax.min;
        this.scaleMaxFixed = minMax.max;
      } else {
        let minMax = await this.getMinMaxRange();
        if (minMax.max == minMax.min || minMax.max - 500 < minMax.min)
          minMax.max = minMax.min + 1000;
        this.scaleMin = minMax.max - 500;

        this.scaleMax = minMax.max;
        this.scaleMinFixed = minMax.min;
        this.scaleMaxFixed = minMax.max;
      }

      if (minMax.min > minMax.max) {
        this.data = {};
        this.completeData = {};
        this.rerender();
        this.$toast.error("Start time can not be greater than End time.", {
          position: "top",
          duration: 3000,
        });
        // return false;
      }
    } else {
      if (this.logType == "time") {
        format = {
          from: Number,
          to: (value) => {
            let date = moment(value).format("MM-DD-YYYY HH:mm:ss");
            return date.replace(" ", "</br>");
          },
        };
      }
    }
    this.emptyPlot = true;
    this.slider = document.getElementById(this.compId + "localSlider");
    let behaviour1 = "drag";
    let start1 = [this.scaleMin, this.scaleMax];

    let sliderObj = {
      start: start1,
      limit: 86400000,
      width: 100,
      orientation: "vertical",
      tooltips: true,
      height: this.singleTrackHeight,
      behaviour: behaviour1,
      connect: true,
      range: {
        min: this.scaleMinFixed ? this.scaleMinFixed : 0,
        max: this.scaleMaxFixed ? this.scaleMaxFixed : 100,
      },
    };
    if (format.to) {
      sliderObj.format = format;
    }
    if (this.slider) {
      this.noUislider.create(this.slider, sliderObj);

      this.trackPropsMaping();
      if (this.status == "UPDATING") {
        setTimeout(() => {
          this.getRealtimeData();
        }, 600);
      }
      this.slider.noUiSlider.on("change", (values) => {
        // if anyone changes the UI Slider then, it will disconnect live sockets & reset time range
        //
        this.updateScale(values);
        if (this.logType == "time") {
          if (this.scaleMax - this.scaleMin == 86400000) {
            this.currentTimeRange = 24;
          } else if (this.scaleMax - this.scaleMin == 43200000) {
            this.currentTimeRange = 12;
          } else if (this.scaleMax - this.scaleMin == 28800000) {
            this.currentTimeRange = 8;
          } else if (this.scaleMax - this.scaleMin == 14400000) {
            this.currentTimeRange = 4;
          } else if (this.scaleMax - this.scaleMin == 7200000) {
            this.currentTimeRange = 2;
          } else if (this.scaleMax - this.scaleMin == 3600000) {
            this.currentTimeRange = 1;
          } else {
            this.currentTimeRange = "";
          }
        } else {
          if (this.scaleMax - this.scaleMin == 1000) {
            this.currentTimeRange = 1000;
          } else if (this.scaleMax - this.scaleMin == 500) {
            this.currentTimeRange = 500;
          } else if (this.scaleMax - this.scaleMin == 100) {
            this.currentTimeRange = 100;
          } else if (this.scaleMax - this.scaleMin == 50) {
            this.currentTimeRange = 50;
          } else {
            this.currentTimeRange = "";
          }
        }

        this.takeOffSocket();
        this.liveDisabled = false;
        if (this.sliderchangetimeout) clearTimeout(this.sliderchangetimeout);
        this.sliderchangetimeout = setTimeout(() => {
          this.zoomIt(
            this.scaleMax,
            this.scaleMin,
            this.feet,
            false,
            "on change timeout"
          );
          clearTimeout(this.sliderchangetimeout);
        }, 350);
      });
    }
    this.zoomIt(this.scaleMax, this.scaleMin, this.feet, false, "update 1409");
  },
  computed: {
    wh() {
      let wh = {};
      wh.width = this.width;
      wh.height = this.height;
      wh.templateWidth = this.width - 10;
      wh.templateHeight = this.height - 10;
      this.templateHeight = this.height - 10;
      this.templateWidth = this.width - 10;
      if (this.trackConfig.tracks)
        this.setTrackWidth(this.trackConfig.tracks.length);
      else this.setTrackWidth(0);
      this.setTrackHeight();
      // this.rerender('WH');

      return wh;
    },
    darkDark() {
      d3.selectAll(".y2").style(
        "fill",
        this.$store.state.rect.darkmode !== "white" ? "white" : "black"
      );
      setTimeout(() => {
        this.rerender("darkDark");
      }, 0);
      return this.$store.state.rect.darkmode;
    },
    alertAnnotations() {
      return this.$store.state.data.annotations;
    },
  },
  sockets: {
    connect: function () {},
    disconnect(data) {
      console.log("data : disconnect : ", data);
      this.$socket.emit("unsubscribe", {
        logId: `${this.wellId}~seperation~${this.wellboreId}~seperation~${this.logType}`,
        topic: this.topic,
        id: this.id,
        clientId: this.$clientId,
      });
    },
    error(data) {
      console.log("data : disconnect : ", data);
    },
    reconnect() {
      this.$socket.emit("susbcribe", {
        logId: `${this.wellId}~seperation~${this.wellboreId}~seperation~${this.logType}`,
        topic: this.topic,
        fields: this.fields,
        id: this.id,
        clientId: this.$clientId,
      });
    },
    reconnect_failed() {},
    reconnect_error() {},
    connect_error() {},
    connecting() {},
    connect_timeout() {},
  },
  methods: {
    getAlertClassOnCondition(curve) {
      let name = curve.name;
      let alert = curve.alert;
      if (curve.isAlert) {
        // console.log("alert in get alert class", alert);
        if (
          name == "alert" ||
          name == "washoutindex" ||
          name == "activitycode"
        ) {
          return "";
        } else {
          if (this.data[name] && this.data[name][this.data[name].length - 1]) {
            let value = this.data[name][this.data[name].length - 1];
            if (
              !isNaN(value) &&
              alert &&
              !isNaN(alert[0]) &&
              !isNaN(alert[1])
            ) {
              if (alert[0] >= value || alert[1] <= value) {
                return "___quadrat";
              } else {
                return "";
              }
            } else {
              return "";
            }
          } else {
            return "";
          }
        }
      } else {
        return "";
      }
    },
    getFullNameDisplay(name) {
      try {
        if (name == "diff") return "DIFF PRESS";
        else if (name == "simplifiedmse") return "MSE";
        else if (name == "bpos") return "BLOCK POS";
        else if (name == "stickslipindex") return "STICK SLIP";
        else if (name == "washoutindex") return "WASH OUT";
        else
          return this.$store.state.disp.displays[this.displayId].displayTags[
            this.$store.state.disp.displays[this.displayId].tags.indexOf(name)
          ].toUpperCase();
      } catch (error) {
        return "";
      }
    },
    getLatestValuesToDisplay(curve) {
      let name = curve.name;
      let value = "0";
      let decimals = curve.decimals || 0;
      if (this.data[name] && this.data[name][this.data[name].length - 1]) {
        let value = this.data[name][this.data[name].length - 1];
        if (!isNaN(value)) {
          if (name == "activitycode") {
            let values = {
              1: "ROT DRLG",

              2: "SLD DRLG",

              3: "CIRC WITH ROT",

              4: "UNKNOWN",

              10: "CIRCULATION",

              11: "REAMING",

              12: "BACKREAM",

              13: "CONNECTION",

              14: "TIH-DRLG",

              15: "POOH-DRLG",

              16: "CIRC WITH ROT",

              17: "CIRCULATION",

              30: "STATIONARY",

              5: "TIH",

              6: "POOH",

              20: "PUMPOUT",

              21: "REAM-TRIP",

              22: "BACK REAM-TRIP",

              23: "CONN.TRIP",

              24: "CIRC WITH ROT-TRIP",

              25: "CIRC W/O ROT-TRIP",

              26: "CIRCULATION",

              27: "WASHDOWN",
            };
            return values[value];
          } else return value.toFixed(decimals);
        } else if (name == "alert" && value) {
          return value;
        } else if (name == "washoutindex") {
          return "";
        }
      } else {
        if (
          name == "alert" ||
          name == "washoutindex" ||
          name == "activitycode"
        ) {
          return "";
        } else {
          return value;
        }
      }
      // this.data[name] && this.data[name][this.data[name].length - 1]
      //   ? (name == "alert" && this.data[name][this.data[name].length - 1]) ||
      //     this.data[name][this.data[name].length - 1].toFixed(2)
      //   : "0";
    },
    returningOfScale1(curve) {
      try {
        return curve.scale[1] ? curve.scale[1].toFixed(2) : 0.0;
      } catch (error) {
        return 0;
      }
    },
    returningOfScale(curve) {
      try {
        return curve.scale[0] ? curve.scale[0].toFixed(2) : "0.00";
      } catch (error) {
        return 0;
      }
    },
    selectStartFeet(feet) {
      feet = parseInt(feet);
      if (this.slider && this.slider.noUiSlider && feet < this.scaleMax) {
        this.slider.noUiSlider.updateOptions({
          behaviour: "drag",
          start: [feet, this.scaleMax],
          range: {
            min: this.scaleMinFixed,
            max: this.scaleMaxFixed,
          },
        });
        this.scaleMin = feet;
        this.zoomIt(
          this.scaleMax,
          this.scaleMin,
          this.feet,
          false,
          "update 1409"
        );
      } else {
        this.$toast.error("Invalide Depth Selection", {
          position: "top",
          duration: 1000,
        });
      }
    },
    selectEndFeet(feet) {
      feet = parseInt(feet);
      if (this.slider && this.slider.noUiSlider && feet > this.scaleMin) {
        this.slider.noUiSlider.updateOptions({
          behaviour: "drag",
          start: [this.scaleMin, feet],
          range: {
            min: this.scaleMinFixed,
            max: this.scaleMaxFixed,
          },
        });
        this.scaleMax = feet;
        this.zoomIt(
          this.scaleMax,
          this.scaleMin,
          this.feet,
          false,
          "update 1409"
        );
      } else {
        this.$toast.error("Invalide Depth Selection", {
          position: "top",
          duration: 1000,
        });
      }
    },
    onFromDateChange: function (e) {
      if (e) {
        this.takeOffSocket();
        this.scaleMinDate = e;
        console.log(
          "date trend : ",
          e,
          moment(this.scaleMinDate).format("HH:MM:SS mm:ss")
        );
        let timeDifference = this.scaleMax - this.scaleMin;
        this.scaleMax = moment(this.scaleMinDate).unix() * 1000;
        this.scaleMin = this.scaleMax - timeDifference;
        if (this.slider) {
          this.slider.noUiSlider.updateOptions({
            behaviour: "drag",
            start: [this.scaleMin, this.scaleMax],
            range: {
              min: this.scaleMinFixed,
              max: this.scaleMaxFixed,
            },
          });
          this.zoomIt(
            this.scaleMax,
            this.scaleMin,
            this.feet,
            false,
            "update 1409"
          );
        }
      }
    },
    alertValueChange(value, alert) {
      alert = alert.replace(/ /g, "");

      if (this.isShowAlerts && value) {
        this.hoveringAlert = alert;
      }
    },
    alertValueClicked(value, alert) {
      alert = alert.replace(/ /g, "");
      if (this.isShowAlerts) {
        this.clickedAlert = alert;
      } else {
        this.clickedAlert = "";
      }
    },
    createACTCLine(div_id, templates) {
      // console.time('Time For Creating createACTCLine : ');
      let template_for_plotting = templates[0];
      let template_overall = template_for_plotting[0]["curve_box"];
      let leftbarbg = template_overall.leftbarbg;
      let yAxisHolder = d3.select("#" + div_id).append("div");
      yAxisHolder
        // .attr("class", "leftbarcontainer")
        .style("display", "inline-block")
        .style("vertical-align", "top")
        // .style("background", 'red')
        .style("margin-right", "0px")
        .style("width", "20px");
      let template_components = template_for_plotting[0]["components"];
      let template_curves = template_components[0]["curves"][0];

      this.leftRangeSliderSet = true;
      // }
      let margin = template_overall["margin"];
      /// Parameters that define shape & size of overall curve box
      let width = template_overall["width"];
      let height_multiplier_components = 0.95;
      if (template_overall["height_multiplier_components"]) {
        height_multiplier_components =
          template_overall["height_multiplier_components"];
      }
      let height = template_overall["height"] * height_multiplier_components;
      let height_components = template_overall["height"] - 12;
      let y = this.yLeftScale;
      if (this.yLeftScale) {
        let yCompare;

        let leftbarOuter = yAxisHolder
          .append("div")
          .attr("class", "leftbar-outer")
          .style("width", "20px");

        let leftbarInner = leftbarOuter
          .append("div")
          .attr("class", `leftbar-inner-${div_id}`)
          .style("max-height", height_components + "px")
          .style("width", "20px")
          .style("height", height_components + "px")
          .style("direction", "rtl")
          .style("scrollbar-track", "red");

        let svg = leftbarInner
          .append("svg")
          .style("width", "20px")
          // .attr('height', height);
          .style("overflow-x", "auto")
          .attr("height", height_components);

        const g = svg.append("g").attr("height", height_components);
        let data = this.data;
        let startActcCode = 0;
        let endActcCode = 0;
        let startActcTime = 0;
        let endActcTime = 0;
        let totalRecords = data["activitycode"].length - 1;
        let actcColor = "white";
        let tooltip = "";
        let logType = this.logType;
        function compare(a, b) {
          if (logType == "time" && a.time < b.time) {
            return -1;
          }
          if (logType == "time" && a.time > b.time) {
            return 1;
          }
          if (logType == "depth" && a.dmea < b.dmea) {
            return -1;
          }
          if (logType == "depth" && a.dmea > b.dmea) {
            return 1;
          }
          return 0;
        }
        let compId = this.compId;
        let showActcBarToolTip = (event, text, isShow) => {
          this.mouse_x = event.clientX;
          this.mouse_y = 336;
          this.actcBarMessage = text;
          this.isActcBarHover = isShow;
        };
        let hideActcBarToolTip = (event, text, isShow) => {
          if (this.tooltipTimeOut) {
            clearTimeout(this.tooltipTimeOut);
          }
          this.tooltipTimeOut = setTimeout(() => {
            this.mouse_x = 0;
            this.mouse_y = 0;
            this.actcBarMessage = "";
            this.isActcBarHover = isShow;
          }, 500);
        };
        if (data["activitycode"]) {
          g.selectAll(".group")
            .data(data["activitycode"])
            .attr("class", "group")
            .enter()
            .each(function (d, i) {
              if (i == 0) {
                if (logType == "time") {
                  startActcCode = d;
                  startActcTime = data["time"][i];
                } else {
                  startActcCode = d;
                  startActcTime = data["dmea"][i];
                }
              } else {
                if (logType == "time") {
                  endActcCode = d;
                  endActcTime = data["time"][i];
                } else {
                  endActcCode = d;
                  endActcTime = data["dmea"][i];
                }

                if (
                  parseInt(startActcCode) != parseInt(endActcCode) ||
                  i == totalRecords
                ) {
                  switch (startActcCode) {
                    case 1:
                      actcColor = "#92D050";
                      tooltip = "ROT DRLG";
                      break;
                    case 2:
                      actcColor = "#00B050";
                      tooltip = "SLD DRLG";
                      break;
                    case 3:
                      actcColor = "#FFC000";
                      tooltip = "CIRC WITH ROT";
                      break;
                    case 4:
                      actcColor = "#FFFFFF";
                      tooltip = "UNKNOWN";
                      break;
                    case 10:
                      actcColor = "#833C0C";
                      tooltip = "CIRCULATION";
                      break;
                    case 11:
                      actcColor = "#00CC99";
                      tooltip = "REAMING";
                      break;
                    case 12:
                      actcColor = "#008000";
                      tooltip = "BACKREAM";
                      break;
                    case 13:
                      actcColor = "#CC3300";
                      tooltip = "CONNECTION";
                      break;
                    case 14:
                      actcColor = "#2F75B5"; // Bright Blue
                      tooltip = "TIH-DRLG";
                      break;
                    case 15:
                      actcColor = "#8EA9DB"; //Cadet Blue
                      tooltip = "POOH-DRLG";
                      break;
                    case 16:
                      actcColor = "#DBDBDB";
                      tooltip = "CIRC WITH ROT";
                      break;
                    case 17:
                      actcColor = "#FFFF00";
                      tooltip = "CIRCULATION";
                      break;
                    case 30:
                      actcColor = "#FFE699";
                      tooltip = "STATIONARY";
                      break;
                    case 5:
                      actcColor = "#1F4E78";
                      tooltip = "TIH";
                      break;
                    case 6:
                      actcColor = "#BDD7EE";
                      tooltip = "POOH";
                      break;
                    case 20:
                      actcColor = "#8497B0";
                      tooltip = "PUMPOUT";
                      break;
                    case 21:
                      actcColor = "#9900FF";
                      tooltip = "REAM-TRIP";
                      break;
                    case 22:
                      actcColor = "#FF3399";
                      tooltip = "BACK REAM-TRIP";
                      break;
                    case 23:
                      actcColor = "#CCCC00";
                      tooltip = "CONN.TRIP";
                      break;
                    case 24:
                      actcColor = "#CC9900";
                      tooltip = "CIRC WITH ROT-TRIP";
                      break;
                    case 25:
                      actcColor = "#FF9933";
                      tooltip = "CIRC W/O ROT-TRIP";
                      break;
                    case 26:
                      actcColor = "#833C0C";
                      tooltip = "CIRCULATION";
                      break;
                    case 27:
                      actcColor = "#945E0D";
                      tooltip = "WASHDOWN";
                      break;

                    default:
                      break;
                  }
                  d3.select(this)
                    .append("rect")
                    .attr("class", "bar")
                    .attr("data-index", i)
                    .attr("width", "20px")
                    // .attr('x', function(e) { return x(d.Date); })
                    .style("fill", actcColor)
                    .attr("y", y(startActcTime))
                    // .attr("height", "48px");
                    .attr(
                      "height",
                      y(endActcTime) - y(startActcTime)
                        ? y(endActcTime) - y(startActcTime) + "px"
                        : " 0px"
                    )
                    // .append("text")

                    .on("mouseover", function (e) {
                      showActcBarToolTip(e, d3.select(this).attr("text"), true);
                    })
                    .on("mousemove", function (e) {
                      showActcBarToolTip(e, d3.select(this).attr("text"), true);
                    })

                    .attr("text", tooltip);
                  if (logType == "time") {
                    startActcCode = d;
                    startActcTime = data["time"][i];
                  } else {
                    startActcCode = d;
                    startActcTime = data["dmea"][i];
                  }
                } else {
                }
              }
            });
          g.on("mouseout", function (e) {
            hideActcBarToolTip(e, "", false);
          });
          // console.timeEnd('Time For Creating createACTCLine : ');
        }
      }
    },
    getRandomColor() {
      let letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    createAlertBar(div_id, templates) {
      let template_for_plotting = templates[0];
      let template_overall = template_for_plotting[0]["curve_box"];
      let yAxisHolder = d3.select("#" + div_id).append("div");
      yAxisHolder
        .style("display", "inline-block")
        .style("vertical-align", "top")
        .style("margin-right", "0px")
        .style("width", "20px");

      this.leftRangeSliderSet = true;
      let height_components = template_overall["height"] - 12;
      let y = this.yLeftScale;
      if (this.yLeftScale) {
        let leftbarOuter = yAxisHolder
          .append("div")
          .attr("class", "leftbar-outer")
          .style("width", "20px");

        let leftbarInner = leftbarOuter
          .append("div")
          .attr("class", `leftbar-inner-${div_id}`)
          .style("max-height", height_components + "px")
          .style("width", "20px")
          .style("height", height_components + "px")
          .style("direction", "rtl")
          .style("scrollbar-track", "red");

        let svg = leftbarInner
          .append("svg")
          .style("width", "20px")
          // .attr('height', height);
          .style("overflow-x", "auto")
          .attr("height", height_components);

        let g = svg.append("g").attr("height", height_components);

        let startActcCode = 0;
        let endActcCode = 0;
        let startActcTime = 0;
        let endActcTime = 0;
        let totalRecords = this.data["dmea"].length - 1;
        let actcColor = "white";
        let tooltip = "";
        let logType = this.logType;
        function compare(a, b) {
          if (logType == "time" && a.time < b.time) {
            return -1;
          }
          if (logType == "time" && a.time > b.time) {
            return 1;
          }
          if (logType == "depth" && a.dmea < b.dmea) {
            return -1;
          }
          if (logType == "depth" && a.dmea > b.dmea) {
            return 1;
          }
          return 0;
        }
        let compId = this.compId;
        g.selectAll(".group")
          .data(this.data)
          .attr("class", "group")
          .enter()
          .each(function (d, i) {
            if (i == 0) {
              if (logType == "time") {
                startActcCode = d.alert;
                startActcTime = d.time;
              } else {
                startActcCode = d.alert;
                startActcTime = d.dmea;
              }
            } else {
              if (logType == "time") {
                endActcCode = d.alert;
                endActcTime = d.time;
              } else {
                endActcCode = d.alert;
                endActcTime = d.dmea;
              }

              if (startActcCode != endActcCode || i == totalRecords) {
                let letters = "0123456789ABCDEF";
                let color = "#";
                for (let i = 0; i < 6; i++) {
                  color += letters[Math.floor(Math.random() * 16)];
                }
                actcColor = color;
                tooltip = "ROT DRLG";
                d3.select(this)
                  .append("line")
                  .style("stroke", color)
                  .style("stroke-width", 2)
                  .attr("x1", 0)
                  .attr("y1", y(startActcTime))
                  .attr("x2", 20)
                  .attr(
                    "y2",
                    y(startActcTime) + (y(endActcTime) - y(startActcTime)) / 2
                  );
                d3.select(this)
                  .append("line")
                  .style("stroke", color)
                  .style("stroke-width", 2)
                  .attr("x1", 0)
                  .attr("y1", y(endActcTime))
                  .attr("x2", 20)
                  .attr(
                    "y2",
                    y(startActcTime) + (y(endActcTime) - y(startActcTime)) / 2
                  );

                d3.select(this)
                  .append("text")
                  .attr("class", "bar")
                  .attr("data-index", i)
                  // .attr("width", "20px")
                  .attr("x", 50)
                  .style("fill", actcColor)
                  .attr(
                    "y",
                    y(startActcTime) + (y(endActcTime) - y(startActcTime)) / 2
                  )
                  .text(startActcCode);
                if (logType == "time") {
                  startActcCode = d.alert;
                  startActcTime = d.time;
                } else {
                  startActcCode = d.alert;
                  startActcTime = d.dmea;
                }
              } else {
              }
            }
          });
      }
    },
    takeOffSocket() {
      this.timer = -1;
      this.liveDisabled = false;
      if (this.$socket) {
        this.$socket.emit("unsubscribe", {
          logId: `${this.wellId}~seperation~${this.wellboreId}~seperation~${this.logType}`,
          topic: this.topic,
          id: this.id,
          clientId: this.$clientId,
        });
        this.sockets.unsubscribe(this.$clientId);
      }
      this.stopUpdation = false;
    },
    async trackPropsMaping() {
      let saved = false;

      if (
        this.plotConfigProps.tracks !== undefined &&
        this.plotConfigProps.tracks !== null
      ) {
        this.trackConfig = this.plotConfigProps;
        saved = true;
        this.trackConfig = {};

        let tracks = this.plotConfigProps.tracks;
        this.templateWidth = this.width;
        let mnemonics = [];

        for (let i = 0; i < tracks.length; i++) {
          let tags = tracks[i];
          for (let j = 0; j < tags.length; j++) {
            let trackId = "t" + Math.random().toString(36).slice(8);
            tags[j].trackId = trackId;
            mnemonics.push(tags[j].name);
          }
          tracks[i] = tags;
        }
        tracks.forEach((track) => {
          track.map((eachMn) => {
            eachMn["description"] = eachMn.fullName;
          });
        });
        this.plotConfigProps.tracks = tracks;
        this.trackConfig = this.plotConfigProps;
        this.tracks = tracks;
        console.log("tracks::::::::::::::", tracks);
        // await this.getMultiTagsData(mnemonics, 'trackPropsMaping');
        // this.adjustTracks();
        // this.rerender('trackPropsMaping');
      } else {
        this.trackConfig = this.plotConfigProps;
      }
    },
    getOnScreenMnemonics() {
      let tracks = this.trackConfig.tracks;
      let mnemonics = [];
      if (tracks) {
        for (let i = 0; i < tracks.length; i++) {
          let tags = tracks[i];
          for (let j = 0; j < tags.length; j++) {
            // let trackId = "t" + Math.random().toString(36).slice(8);
            // tags[j].trackId = trackId;

            let mn_name =
              tags[j].name == "DBTM"
                ? tags[j].name.toLowerCase()
                : tags[j].name;
            mnemonics.push(mn_name);

            mnemonics.push("time");
            this.getMnemonicType(mn_name);
          }
          tracks[i] = tags;
        }
      }
      let extra = ["stickslip", "washout", "dmea", "alert"];
      for (let a of extra) {
        if (
          mnemonics.indexOf("stickslipindex") > -1 &&
          mnemonics.indexOf("stickslip") == -1
        ) {
          mnemonics.push(a);
          this.getMnemonicType(a);
        }

        if (
          mnemonics.indexOf("washoutindex") > -1 &&
          mnemonics.indexOf("washout") == -1
        ) {
          mnemonics.push(a);
          this.getMnemonicType(a);
        }

        if (mnemonics.indexOf("dmea") == -1) {
          mnemonics.push(a);
          this.getMnemonicType(a);
        }
        if (mnemonics.indexOf("alert") == -1) {
          mnemonics.push(a);
          this.getMnemonicType(a);
        }
      }

      mnemonics = [...new Set(mnemonics)];

      return mnemonics;
    },
    getMnemonicType(mnemonic) {
      // console.time("get mnemonic type", mnemonic)
      let mnemonic_groups = {};
      let all_mnemonics = this.$store.state.disp.displays[this.displayId]
        ? this.$store.state.disp.displays[this.displayId].mnemonics
        : [];
      let mnemonic_type = "UNPREFERRED";
      for (let i in all_mnemonics) {
        if (all_mnemonics[i].find((o) => o.std_mnemonic === mnemonic)) {
          mnemonic_type = i;
          mnemonic_groups[i] = [];
          break;
        }
      }
      if (!this.mnemonic_groups[mnemonic_type])
        this.mnemonic_groups[mnemonic_type] = [];
      if (this.mnemonic_groups[mnemonic_type].indexOf(mnemonic) == -1)
        this.mnemonic_groups[mnemonic_type] = [
          ...this.mnemonic_groups[mnemonic_type],
          mnemonic,
        ];
      // console.timeEnd("get mnemonic type", mnemonic)

      return mnemonic_type;
    },
    async getMultiTagsData(mnemonics, from) {
      this.$store.dispatch("data/startLoading");
      let result = null;
      if (mnemonics.indexOf("dmea") == -1) mnemonics.push("dmea");
      if (mnemonics.indexOf("alert") == -1) mnemonics.push("alert");

      try {
        if (this.logType == "time") {
          for (let i in this.mnemonic_groups) {
            result = await apiService.WellServices.getTimeLogs({
              fields: this.mnemonic_groups[i],
              well_id: this.wellId,
              wellbore_name: this.wellboreId,
              secondsdataavailability: this.secondsdataavailability,
              // days: 0.2,
              type: i,
              from: this.scaleMax,
              to: this.scaleMin,
            });

            if (result && result.data && result.data.data) {
              if (
                result.data.data[this.mnemonic_groups[i][0]] &&
                result.data.data[this.mnemonic_groups[i][0]].length == 0
              ) {
                // this.$toast.error("Data is not available for this interval", {
                //   position: "top",
                //   duration: 3000,
                // });
              } else {
                this.completeData = {
                  ...this.completeData,
                  ...result.data.data,
                };
                this.data = this.completeData;
                this.minmax = { ...this.minmax, ...result.data.minmax };
              }
            } else {
              // this.$toast.error("Server is taking longer than usual", {
              //   position: "top",
              //   duration: 3000,
              // });
            }
          }
        } else {
          apiService.WellServices.getDepthLogs({
            fields: mnemonics,
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
            // isPartial: false,
          })
            .then(async (data) => {
              if (data && data.data && data.data.data) {
                this.completeData = { ...this.completeData, ...data.data.data };
              }
            })
            .catch((e) => {
              console.error("whole data error : ", e);
            });
          result = await apiService.WellServices.getDepthLogs({
            fields: mnemonics,
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
            isPartial: true,
            start: this.scaleMin,
            end: this.scaleMax,
          });

          // result = result.data;
          // for (let i of mnemonics) {
          //   await this.addData(result, i);
          // }
          if (result && result.data && result.data.data) {
            if (
              result.data.data["dmea"] &&
              result.data.data["dmea"].length == 0
            ) {
              this.$toast.error("Data is not available for this well", {
                position: "top",
                duration: 3000,
              });
            }
          }
          this.completeData = { ...this.completeData, ...result.data.data };
          this.data = { ...this.data, ...result.data.data };
          this.minmax = { ...this.minmax, ...result.data.minmax };
        }
      } catch (error) {
        console.error(error);
      }

      setTimeout(() => {
        this.$store.dispatch("data/stopLoading");
      }, 700);
    },
    setContextIdWidget(e) {
      this.$store.dispatch("disp/setContextId", {
        contextId: this.displayId,
        contextWidgetId: this.id,
        contextType: "widget",
      });
    },
    getRealtimeData() {
      this.liveDisabled = true;
      let test = this.id;
      if (this.data === undefined || this.data === null) {
        this.data = [];
        this.mnemonic_groups = {};
      }
      let topic = this.topic;
      let wells = this.$store.state.data.wells;
      let well = wells[this.wellId];

      if (well) {
        let wellbore = well.wellbores[this.wellboreId];
        let log = wellbore["logs"][this.logType];
        this.logId = log.id;
        let fields = this.getOnScreenMnemonics();
        console.log("fields  : ", fields, log);
        fields.push("time");
        this.fields = fields;
        let topicExists = this.$store.state.live.isSubscribed[topic];
        if (this.wellId && this.wellboreId && this.logType) {
          this.$socket.emit("susbcribe", {
            logId: `${this.wellId}~seperation~${this.wellboreId}~seperation~${this.logType}`,
            topic: this.topic,
            fields: this.fields,
            id: this.id,
            clientId: this.$clientId,
            logType: this.logType,
          });
          if (!topicExists) {
            this.$store.dispatch("live/setSubscriptionFlag", {
              topic,
              flag: true,
            });
          }

          this.sockets.subscribe(this.$clientId, (sResult) => {
            let result = sResult[this.id];
            if (result) {
              let isLiveDepth = false;
              if (
                this.logType == "depth" &&
                this.completeData["dmea"] &&
                this.completeData["dmea"].length &&
                result["dmea"] &&
                result["dmea"].length
              ) {
                if (
                  this.completeData["dmea"][
                    this.completeData["dmea"].length - 1
                  ] < result["dmea"][result["dmea"].length - 1]
                )
                  isLiveDepth = true;
              }

              if (this.logType == "time") {
                if (
                  this.data["time"][this.data["time"].length - 1] <
                    result["time"][result["time"].length - 1] &&
                  this.data["time"][this.data["time"].length - 1] <
                    result["time"][0]
                )
                  for (let i in this.completeData) {
                    if (result[i]) {
                      if (this.logType == "time") {
                        this.completeData[i].splice(0, result[i].length);
                      }
                      this.completeData[i] = [
                        ...this.completeData[i],
                        ...result[i],
                      ];
                    }
                  }
              } else {
                if (isLiveDepth)
                  for (let i in this.completeData) {
                    if (result[i]) {
                      this.completeData[i] = [
                        ...this.completeData[i],
                        ...result[i],
                      ];
                    }
                  }
              }
              if (this.logType == "time") {
                this.data = this.completeData;
                this.scaleMin = this.data["time"][0];
                this.scaleMax = this.data["time"][this.data["time"].length - 1];

                // this.scaleMin = parseInt(this.scaleMin);
                // this.scaleMax = parseInt(this.scaleMax);
              } else {
                this.scaleMin +=
                  result["dmea"][result["dmea"].length - 1] - this.scaleMax;
                if (this.scaleMin < 0) {
                  this.scaleMin = this.completeData["dmea"][0];
                }
                this.scaleMax = result["dmea"][result["dmea"].length - 1];
                // this.scaleMin = parseInt(this.scaleMin);
                // this.scaleMax = parseInt(this.scaleMax);
              }
              this.scaleMaxFixed = this.scaleMax;
              localStorage.setItem("time", this.scaleMaxFixed);
              // console.log(this.data );

              // console.log(
              //   "value of scalemax is smaller than scale Min : ",
              //   new Date(this.scaleMax).toISOString(),
              //   new Date(this.scaleMin).toISOString()
              // );

              if (
                this.scaleMax > this.scaleMin &&
                this.slider &&
                this.slider.noUiSlider
              ) {
                this.slider.noUiSlider.updateOptions({
                  behaviour: "drag",
                  start: [this.scaleMin, this.scaleMax],
                  range: {
                    min: this.scaleMinFixed,
                    max: this.scaleMaxFixed,
                  },
                });
              }
              this.zoomIt(
                this.scaleMax,
                this.scaleMin,
                this.feet,
                false,
                "update live"
              );
            }
          });
        }
        topicExists = this.$store.state.live.isSubscribed[topic];
      }
    },
    selectFeet(feet) {
      let scaleMin = 0;
      this.currentFeetRange = feet;
      if (feet == "default") {
        this.feet = 1000;
        scaleMin = this.scaleMax - 1000;
      } else {
        this.feet = feet;
        scaleMin = this.scaleMax - feet;
      }
      if (this.slider && this.slider.noUiSlider)
        this.slider.noUiSlider.updateOptions({
          behaviour: "drag",
          start: [scaleMin, this.scaleMax],
          range: {
            min: this.scaleMinFixed,
            max: this.scaleMaxFixed,
          },
        });
      this.scaleMin = scaleMin;
      this.zoomIt(
        this.scaleMax,
        this.scaleMin,
        this.feet,
        false,
        "update 1409"
      );
    },
    async makeItLive() {
      // it fetches the latest 4 hour of data and start the socket data stream

      // this.liveDisabled = true;

      this.scaleMax = this.scaleMaxFixed;
      if (this.logType == "time") {
        this.data = [];
        this.mnemonic_groups = {};
        this.currentTimeRange = 1;
        await this.selectTimeRange(1);
      } else {
        this.currentFeetRange = 500;
        this.selectFeet(500);
      }

      this.getRealtimeData();
    },
    selectTimeRange(hrs) {
      this.currentTimeRange = hrs;
      if (this.liveDisabled) {
        this.takeOffSocket();
        if (hrs == 2 || hrs == 1) {
          this.liveDisabled = false;
          if (hrs == 2)
            setTimeout(() => {
              this.liveDisabled = true;
              this.getRealtimeData();
            }, 400);
        } else this.liveDisabled = false;
      }

      // this.socket = io(URL.HOST_URL);

      let scaleMin = 0;
      if (hrs == "default") {
        this.hrs = 1;
        scaleMin = this.scaleMax - 1 * 1000 * 60 * 60;
      } else {
        this.hrs = hrs;
        scaleMin = this.scaleMax - hrs * 1000 * 60 * 60;
      }
      if (this.slider && this.slider.noUiSlider)
        this.slider.noUiSlider.updateOptions({
          behaviour: "drag",
          start: [scaleMin, this.scaleMax],
          range: {
            min: this.scaleMinFixed,
            max: this.scaleMaxFixed,
          },
        });
      this.scaleMin = scaleMin;
      this.zoomIt(
        this.scaleMax,
        this.scaleMin,
        this.feet,
        false,
        "update 1409"
      );
    },
    showProps1(j, i) {
      let cProps = {};
      cProps["show"] = true;
      cProps["curve"] = this.trackConfig.tracks[j][i];
      this.curveProps[j + "-" + i] = cProps;
    },
    closeIt(j, i) {
      this.curveProps[j + "-" + i]["show"] = false;
    },
    setCurveProps(j, i, curve) {
      console.log("@@@@@@@@@@@", j, i, curve, this.trackConfig.tracks[j][i]);
      this.trackConfig.tracks[j][i]["editedDescription"] =
        curve.editedDescription;
      if (this.trackConfig.tracks[j][i].name == "alert") {
        this.alertArr = curve.alertsArr;
      }
      this.curveProps[j + "-" + i]["show"] = false;
      let stroke = curve.thickness;
      let scaleMin = curve.scaleMin;
      let scaleMax = curve.scaleMax;
      let alertMin = curve.alertMin;
      let alertMax = curve.alertMax;
      let decimals = curve.decimals;
      let editedName = curve.editedDescription;
      if (stroke != null) {
        stroke = Number(stroke);
        this.trackConfig.tracks[j][i].stroke = stroke;
      }
      if (
        scaleMin != null &&
        scaleMin !== "" &&
        scaleMax != null &&
        scaleMax != ""
      ) {
        scaleMin = Number(scaleMin);
        scaleMax = Number(scaleMax);
        this.trackConfig.tracks[j][i].scale = [scaleMin, scaleMax];
        this.trackConfig.tracks[j][i].scaleType = "manual";
      }
      if (alertMin != null && alertMax != null) {
        alertMin = Number(alertMin);
        alertMax = Number(alertMax);

        this.trackConfig.tracks[j][i].alert = [alertMin, alertMax];
      }
      if (!isNaN(decimals)) {
        this.trackConfig.tracks[j][i].decimals = decimals;
      }
      if (editedName !== this.trackConfig.tracks[j][i].description) {
        this.trackConfig.tracks[j][i].editedName = editedName;
      }
      this.trackConfig.tracks[j][i].color = curve.color;
      this.trackConfig.tracks[j][i].isAlert = curve.isAlert;
      this.rerender("setCurveProps");
    },
    deleteCurve(j, i) {
      this.curveProps[j + "-" + i]["show"] = false;
      this.trackConfig.tracks[j].splice(i, 1);
      if (this.trackConfig.tracks[j].length == 0) {
        this.trackConfig.tracks.splice(j, 1);
      }
      this.configure();
      this.verticalplots(this.div_id, this.plots, true);
      this.rerender();
      // visible = !visible;
    },
    updateScale(values) {
      function subtractHoursFromTime(inputTime, hoursToSubtract, minutesToAdd) {
        try {
          const [datePart, timePart] = inputTime.split("</br>");
          const [month, day, year] = datePart.trim().split("-");
          const datetimeString = `${month}-${day}-${year} ${timePart.trim()}`;
          const inputDatetime = new Date(datetimeString);
          inputDatetime.setHours(inputDatetime.getHours() + hoursToSubtract);
          inputDatetime.setMinutes(inputDatetime.getMinutes() - minutesToAdd);
          const resultFormat = `${String(inputDatetime.getMonth() + 1).padStart(
            2,
            "0"
          )}-${String(inputDatetime.getDate()).padStart(2, "0")}-${String(
            inputDatetime.getFullYear()
          ).substr(-2)} ${String(inputDatetime.getHours()).padStart(
            2,
            "0"
          )}:${String(inputDatetime.getMinutes()).padStart(2, "0")}:${String(
            inputDatetime.getSeconds()
          ).padStart(2, "0")}`;

          return resultFormat;
        } catch (error) {
          return "Invalid input format. Please use the format 'mm-dd-yy</br>HH:MM:SS'.";
        }
      }
      function calculateTimeZoneOffset(targetTimeZone) {
        try {
          const now = new Date();

          const targetDate = new Date(
            now.toLocaleString("en-US", {
              timeZone: targetTimeZone,
            })
          );

          // Calculate the offset in minutes by comparing the current time to the target time
          const offsetInMinutes = (targetDate - now) / (60 * 1000);

          return offsetInMinutes;
        } catch (error) {
          return "Error calculating time zone offset.";
        }
      }
      const targetTimeZone = this.time_zone; // Replace with the desired time zone
      const result = calculateTimeZoneOffset(targetTimeZone);
      const hoursToSubtract = 0;
      const minutesToAdd = result;

      if (Math.round(values[0])) {
        this.scaleMin = Math.round(values[0]);
        this.scaleMax = Math.round(values[1]);
      } else {
        this.scaleMin =
          moment(
            subtractHoursFromTime(values[0], hoursToSubtract, minutesToAdd)
          ).unix() * 1000;
        this.scaleMax =
          moment(
            subtractHoursFromTime(values[1], hoursToSubtract, minutesToAdd)
          ).unix() * 1000;
      }
    },
    startDrag(ev, props) {
      ev.dataTransfer.dropEffect = "move";
      ev.dataTransfer.effectAllowed = "move";
      let propString = JSON.stringify(props);
      ev.dataTransfer.setData("elProps", propString);
    },
    async onDrop(event) {
      let title;
      let min;
      let max;
      let color;
      let fullName;
      let singleTagData = null;
      let target = event.target.id;
      let elProps = JSON.parse(event.dataTransfer.getData("elProps"));

      for (let j = 0; j < this.tracks.length; j++) {
        let trackId = this.tracks[j][0].trackId;
        if (target === "rect" + trackId) {
          if (this.tracks[j].length == 3) {
            this.$toast.error("Max limit is over", {
              position: "top",
              duration: "1000",
            });
            return false;
          }
        }
      }

      if (elProps.name == "child") {
        title = elProps.title.name;
        min = elProps.title.min;
        max = elProps.title.max;
        color = elProps.title.color;
        if (elProps.origin === this.compId) {
          this.deleteChildCurve(elProps.trackNbr, elProps.curveNbr);
        }
      } else {
        elProps.title = elProps.title
          ? elProps.title.toLowerCase()
          : elProps.fullName.toLowerCase();
        title = elProps.title;
        singleTagData = await this.getSingleTagData(
          elProps.title,
          false,
          "onDrop"
        );
        if (this.logType == "depth") {
          min = Number.POSITIVE_INFINITY;
          max = Number.NEGATIVE_INFINITY;
          let tmp;
          for (let i = singleTagData.length - 1; i >= 0; i--) {
            tmp = parseFloat(singleTagData[i][title.toLowerCase()]);
            if (tmp < min) min = tmp;
            if (tmp > max) max = tmp;
          }
        } else {
          // min = this.minmax[elProps.title].min
          // max = this.minmax[elProps.title].max
          min = d3.min(this.data[elProps.title]);
          max = d3.max(this.data[elProps.title]);
        }
      }
      fullName = elProps.fullName;
      this.emptyPlot = false;

      if (this.div_id == target) {
        let trackId = this.addTrack();
        let j = this.tracks.length - 1;
        await this.addNewCurve({
          title,
          min,
          max,
          trackId,
          j,
          color,
          fullName,
        });
      } else {
        for (let j = 0; j < this.tracks.length; j++) {
          let trackId = this.tracks[j][0].trackId;

          if (target === "rect" + trackId) {
            await this.addNewCurve({
              title,
              min,
              max,
              trackId,
              j,
              color,
              fullName,
            });
            break;
          }
        }
      }

      this.$store.dispatch("rect/setConfig", {
        disId: this.displayId,
        widId: this.id,
        config: this.trackConfig,
      });
      // this.selectedMnenonics.unshift(title);

      this.setTrackWidth(this.trackConfig.tracks.length);
      this.rerender("onDrop");
      // this.rerenderTest(this.compId);
      if (this.liveDisabled && this.status == "UPDATING") {
        this.getRealtimeData();
      }
    },
    deleteChildCurve(trackNbr, curveNbr) {
      this.trackConfig.tracks[trackNbr].splice(curveNbr, 1);
      if (this.trackConfig.tracks[trackNbr].length == 0) {
        this.trackConfig.tracks.splice(trackNbr, 1);
        this.totTracks.splice(trackNbr, 1);
      }
    },
    setTrackWidth(nbr) {
      let usableWidth =
        this.templateWidth - this.sliderWidth - this.leftbarWidth - 50;
      this.singleTrackWidth = usableWidth / nbr;
      if (this.singleTrackWidth > this.trackWidth) {
        this.singleTrackWidth = this.trackWidth;
      }
    },
    setTrackHeight() {
      this.singleTrackHeight = this.templateHeight - this.bheight - 30;
    },
    addNewCurve(title) {
      let currentCurve = {};
      currentCurve.name = title.title;
      currentCurve.fullName = title.fullName || "";
      if (title.color != null) {
        currentCurve.color = title.color;
      } else {
        currentCurve.color = this.getRandomColor();
      }
      if (title.j != null) {
        currentCurve.trackNbr = title.j;
        if (this.tracks.length > 0) {
          currentCurve.curveNbr = this.tracks[title.j].length;
        }
      } else {
        currentCurve.trackNbr = 0;
        currentCurve.curveNbr = 0;
      }
      currentCurve.trackId = title.trackId;
      currentCurve.min = title.min;
      currentCurve.max = title.max;
      currentCurve.scale = [title.min, title.max];
      currentCurve.scaleType = "auto";
      this.trackConfig.tracks[currentCurve.trackNbr].push(currentCurve);
    },
    getRandomColor() {
      let letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    createLeftBar(div_id, templates) {
      try {
        let template_for_plotting = templates[0];
        let template_overall = template_for_plotting[0]["curve_box"];
        let leftbarbg = template_overall.leftbarbg;
        let left_bar_bg_ =
          this.darkDark !== "white" ? "light_trend-mode" : "bg-dark";
        let yAxisHolder = d3.select("#" + div_id).append("div");
        const trendtimeStampCol = this.darkDark !== "white" ? "dv" : "ddv";
        yAxisHolder
          .attr("class", "leftbarcontainer")
          .attr("class", left_bar_bg_)
          .style("display", "inline-block")
          .style("vertical-align", "top")
          // .style("background", leftbarbg)
          .style("margin-right", "0px")
          .style("fill", this.darkDark !== "white" ? "white" : "black");

        if (this.logType == "time") {
          yAxisHolder
            .style("width", "120px")
            .style("color", this.darkDark !== "white" ? "white" : "black");
        } else {
          yAxisHolder.style("width", "120px");
        }

        if (this.logType == "time") {
          // let depth_min_sec = this.minmax['dmea'].min

          // let depth_max_sec = this.minmax['dmea'].max
          this.depthMaxValue = 0;
          this.depthMinValue = 0;
        } else {
          let depth_min_sec = d3.min(this.data["time"]) || null;
          let depth_max_sec = d3.max(this.data["time"]) || null;
          this.depthMaxValue = parseInt(depth_max_sec);
          this.depthMinValue = parseInt(depth_min_sec);
        }

        if (isNaN(this.depthMaxValue) && isNaN(this.depthMinValue)) {
          yAxisHolder
            .append("h1")
            .style("position", "absolute")
            .style("transform-origin", "center")
            .style("transform", "translate(-1.5vw, 25vh) rotate(270deg)")
            .text("TIME AXIS NOT AVAILABLE")
            .style("font-size", "14px");
        }

        this.leftRangeSliderSet = true;
        let height_components = template_overall["height"];
        if (this.logType == "depth") {
          this.yLeftScale = d3
            .scaleLinear()
            .domain([this.scaleMax, this.scaleMin])
            .range([height_components - 20, 0]);
          // .nice();
          if (!isNaN(this.depthMaxValue) && !isNaN(this.depthMinValue))
            this.yLeftScaleCompare = d3
              .scaleTime()
              .domain([this.depthMaxValue, this.depthMinValue])
              .range([height_components - 20, 0]);
          // .nice();
        } else {
          this.yLeftScale = d3
            .scaleTime()
            .domain([this.scaleMax, this.scaleMin])
            .range([height_components - 20, 0]);
          // .nice();
        }

        let leftbarOuter = yAxisHolder
          .append("div")
          .attr("class", "leftbar-outer")
          .style("width", "120px");

        let leftbarInner = leftbarOuter
          .append("div")
          .attr("class", `leftbar-inner-${div_id}`)
          .style("max-height", height_components + "px")
          .style("scrollbar-width", "thin")
          .style("width", "120px")
          .style("height", height_components + "px")
          .style("direction", "rtl")
          .style("scrollbar-track", "red");

        let svg = leftbarInner
          .append("svg")
          .style("width", "120px")
          .style("overflow", "visible")
          .attr("height", height_components);
        let yTicks = null;
        let yTicksCompare = null;
        if (this.logType == "time") {
          let tickInterval = parseInt((this.scaleMax - this.scaleMin) / 9);
          let timeTicks = [];
          for (
            let tick = this.scaleMin;
            tick < this.scaleMax;
            tick = tick + tickInterval
          ) {
            if (timeTicks.length <= 8) timeTicks.push(tick);
          }
          timeTicks.push(this.scaleMax);
          yTicks = d3
            .axisLeft()
            .scale(this.yLeftScale)
            .tickValues(timeTicks)
            .tickSizeInner(-105);
        } else {
          let tickInterval = (this.scaleMax - this.scaleMin) / 10;
          let depthTicks = [];
          for (
            let tick = this.scaleMin;
            tick <= this.scaleMax;
            tick = tick + tickInterval
          ) {
            depthTicks.push(tick);
          }

          depthTicks.push(this.scaleMax);
          let tickIntervalCompare = parseInt(
            (this.depthMaxValue - this.depthMinValue) / 10
          );

          let timeTicks = [];
          if (!isNaN(this.depthMaxValue) && !isNaN(this.depthMinValue)) {
            for (
              let tick = this.depthMinValue;
              tick < this.depthMaxValue;
              tick = tick + tickIntervalCompare
            ) {
              timeTicks.push(tick);
            }
            timeTicks.push(this.depthMaxValue);
            // console.log("interval and ticks : ", timeTick);
            if (this.data["dmea"] && this.data["dmea"].length > 0) {
              yTicks = d3
                .axisLeft()
                .scale(this.yLeftScaleCompare)
                .tickValues(timeTicks)
                .tickSize(-65);
              // yTicks.tickFormat(d3.timeFormat("%Y-%m-%d %H:%M:%S"));
            }
          }
          yTicksCompare = d3
            .axisLeft()
            .scale(this.yLeftScale)
            .tickValues(depthTicks)
            .tickSizeInner(-35);
        }

        if (this.logType == "time") {
          let timeAxis = svg
            .append("g")
            .attr("transform", "translate(35,0)")
            .call(yTicks);

          timeAxis.selectAll("line").attr("transform", "translate(-34,0)");
          timeAxis.selectAll("path").attr("transform", "translate(15,0)");
          // timeAxis.selectAll("path").style("opacity", "1").attr("transform", "translate(10,0)");
          let bisectDate = d3.bisector(function (d) {
            return d;
          }).left;
          let scale = this.yLeftScale;
          let data = this.data;
          timeAxis.selectAll("text").call(function (t) {
            t.each(function (d) {
              // for each one
              let self = d3.select(this);

              let s = [];
              s[1] = moment(new Date(d).toLocaleString()).format("MM-DD-YYYY");
              s[0] = moment(new Date(d).toLocaleString()).format("HH:mm:ss");
              // self.path("")
              self.text(""); // clear it out

              self
                .append("tspan")
                .attr("x", 20)
                .attr("dy", "-0.5em")
                .attr("class", trendtimeStampCol)
                .text(s[1])
                .style("font-size", "min(1vw, 9px)");

              self
                .append("tspan") // insert two tspans
                .attr("x", 25)
                .attr("dy", "1.5em")
                .text(s[0])
                .style("font-size", "min(1vw, 9px)");

              if (data["dmea"] && data["dmea"].length) {
                let y0 = scale.invert(d),
                  i = bisectDate(data["time"], d, 1),
                  d0 = data["dmea"][i - 1];
                // d1 = data[i];
                self
                  .append("tspan") // insert two tspans
                  .attr("x", -34)
                  .attr("dy", "0em")
                  .text(d0 ? d0.toFixed(2) : 0);
                // .style("font-size", "12px");
              }
            });
          });
          // if (this.data && this.data.length > 0) {
          //   let depthAxis = svg
          //     .append("g")
          //     .attr("transform", "translate(10,0)")
          //     .call(yTicksCompare);

          //   depthAxis.selectAll("line").attr("x", "10");

          // depthAxis.selectAll("path").style("opacity", "0").attr("transform", "translate(10,0)");

          //   depthAxis.selectAll("text").attr("x", "5").attr("dy", "1em");
          // }
        } else {
          if (!isNaN(this.depthMaxValue) && !isNaN(this.depthMinValue))
            if (this.data["dmea"] && this.data["dmea"].length > 0) {
              let timeAxis = svg
                .append("g")
                .attr("transform", "translate(5,5)")
                .call(yTicks);

              // timeAxis.selectAll("line").attr("x", "10");

              // timeAxis.selectAll("path").style("opacity", "0");

              // timeAxis.selectAll("text").attr("x", "3").attr("dy", "1em");
              timeAxis.selectAll("line").attr("transform", "translate(10,0)");
              timeAxis.selectAll("path").attr("transform", "translate(10,0)");
              // timeAxis.selectAll("path").style("opacity", "0");
              timeAxis.selectAll("text").call(function (t) {
                t.each(function (d) {
                  // for each one
                  let self = d3.select(this);
                  let s = [];
                  s[1] = moment(new Date(d)).format("MM-DD-YYYY");
                  s[0] = moment(new Date(d)).format("HH:mm:ss");
                  // self.path("")
                  self.text(""); // clear it out

                  self
                    .append("tspan")
                    .attr("x", 20)
                    .attr("dy", "-0.5em")
                    .attr("class", trendtimeStampCol)
                    .text(s[1]);
                  self
                    .append("tspan") // insert two tspans
                    .attr("x", 25)
                    .attr("dy", "1.5em")
                    .text(s[0]);
                });
              });
            }

          let depthAxis = svg
            .append("g")
            .attr("transform", "translate(75,5)")
            .call(yTicksCompare);

          depthAxis.selectAll("line").attr("transform", "translate(10,0)");

          depthAxis
            .selectAll("path")
            .style("opacity", "1")
            .attr("transform", "translate(10,0)");

          depthAxis.selectAll("text").attr("x", "13").attr("dy", "1em");
        }
      } catch (error) {}
    },
    verticalplots(
      div_id,
      templates,
      show_all = true,
      remove_preexisting = true
    ) {
      let noDIV = d3
        .select("#" + div_id)
        .selectAll("div")
        .remove();
      let noSVG = d3
        .select("#" + div_id)
        .selectAll("svg")
        .remove();

      if (templates.length > 0) {
        this.createLeftBar(div_id, templates);
        if (!this.isDis) this.createACTCLine(div_id, templates);

        // this.createAlertBar(div_id, templates);
      }
      let new_templates = [];
      curvesMinsMaxes = [];
      let arrPromise = [];

      for (let i in templates) {
        //  templates.forEach((it, i)=>{
        let arr = [];
        let track_bg_s = this.darkDark !== "white" ? "" : this.bgColor;
        let curvebox_holder = d3.select("#" + div_id).append("div");
        curvebox_holder
          .style("vertical-align", "middle")
          .style("background", this.track_bg_s)
          .style("transition:", "var(--transition)")
          .attr("id", templates[i][0]["curve_box"]["trackId"]);
        // console.log("_______ 1 height _________ ");
        //// to control view of plots on site, user can show-hide by triggering action here. However, if show_all = false then none will show, so developer will need to change CSS with another function one by one!
        if (show_all) {
          curvebox_holder.style("display", "inline-block");
          curvebox_holder.style("margin-right", "0px");
        } else {
          curvebox_holder.style("display", "none");
        }
        // templates[i][0]["curve_box"]["div_id"] = div_id + "curvebox_holder" + i;

        templates[i][0]["curve_box"]["div_id"] =
          templates[i][0]["curve_box"]["trackId"];

        new_templates.push(templates[i]);
        let template = templates[i];

        this.curveBox(template, i, div_id, this.data);
        // setTimeout(() => {
        //   //
        // }, 0);
      }

      // this.$store.dispatch('data/stopLoading');
      // return new_templates;
    },
    mousemove(e) {
      try {
        let offsetYPercent =
          (e.offsetY / e.target.getBoundingClientRect().height) * 100;

        let timeRangeInSeconds =
          this.logType == "time"
            ? (this.scaleMax - this.scaleMin) / 1000
            : this.scaleMax - this.scaleMin;
        let tooltipShift =
          this.logType == "time"
            ? // ? timeRangeInSeconds < 1200
              //   ? 5600000
              //   : timeRangeInSeconds > 1200 && timeRangeInSeconds <= 3600
              //   ? 3800000
              //   : timeRangeInSeconds > 3600 && timeRangeInSeconds <= 7200
              //   ? 1200000
              //   : 10000000
              // this.data.length < 5000
              // ? 6000000
              // : this.data.length > 5000 && this.data.length < 9000
              // ? 6000000
              100
            : timeRangeInSeconds / 4;

        let data = this.data;

        for (let focusAxis of this.focusAxisList) {
          if (!focusAxis) {
            return;
          }
          let focus = focusAxis["focus"];
          let y = focusAxis["y"];
          let x = focusAxis["x"];
          // let data = focusAxis['data'];
          let depth_curve_name = focusAxis["depth_curve_name"];
          let width = focusAxis["width"];
          let curve_names = focusAxis["curve_names"];
          let mouseover_curvename = focusAxis["mouseover_curvename"];
          let height = focusAxis["height"];
          let bisectDate = d3.bisector(function (d) {
            return d;
          }).left; // **

          focus.style("display", null);
          focus.transition().duration(200).style("opacity", 1);
          let y0 = 0;
          let index = 0;
          if (this.logType == "depth") {
            y0 = y.invert(d3.pointer(e)[1]);
          } else {
            y0 = moment(y.invert(d3.pointer(e)[1])).unix() * 1000;
          }
          index = bisectDate(data[depth_curve_name], y0, 1);
          if (!y0 || !index || !data[depth_curve_name][index]) {
            continue;
          }
          let d = {};
          for (let i in data) {
            d[i] = data[i][index];
          }

          // let d = y0 - d0[depth_curve_name] > d1[depth_curve_name] - y0 ? d1 : d0;

          //// fixed value along y axis
          let fixed_x_value = width * 0.8;
          //// depth value
          let num = d[depth_curve_name] / this.scaleMax;

          if (this.logType == "time") {
            focus.select(".y2_bg").attr(
              "transform",
              // "translate(" + x(d[mouseover_curvename]) + "," +
              "translate(" +
                (fixed_x_value - 45) +
                "," +
                `${
                  offsetYPercent > 85
                    ? y(d[depth_curve_name]) - tooltipShift - 20
                    : offsetYPercent < 15
                    ? y(d[depth_curve_name]) + Math.floor(tooltipShift) - 20
                    : y(d[depth_curve_name] + 50) - 20
                })`
              // `${y(d[depth_curve_name] + 50)})`
            );
            focus
              .select("text.y2")
              .attr(
                "transform",
                // "translate(" + x(d[mouseover_curvename]) + "," +
                "translate(" +
                  (fixed_x_value - 45) +
                  "," +
                  `${
                    offsetYPercent > 85
                      ? y(d[depth_curve_name]) - tooltipShift
                      : offsetYPercent < 15
                      ? y(d[depth_curve_name]) + Math.floor(tooltipShift)
                      : y(d[depth_curve_name] + 50)
                  })`
                // `${y(d[depth_curve_name] + 50)})`
              )
              .text(
                moment(new Date(d[depth_curve_name]).toLocaleString()).format(
                  "HH:mm:ss"
                )
              );
          } else {
            // focus.select(".y2_bg").attr(
            //   "transform",
            //   // "translate(" + x(d[mouseover_curvename]) + "," +
            //   "translate(" +
            //     (fixed_x_value - 45) +
            //     "," +
            //     `${
            //       offsetYPercent > 85
            //         ? y(d[depth_curve_name] - tooltipShift -23 )
            //         : offsetYPercent < 15
            //         ? y(d[depth_curve_name] + tooltipShift - 23)
            //         : y(d[depth_curve_name] - 23)
            //     })`
            //   // `${y(d[depth_curve_name] + 50)})`
            // );
            focus
              .select("text.y2")
              .attr(
                "transform",
                // "translate(" + x(d[mouseover_curvename]) + "," +
                "translate(" +
                  (fixed_x_value - 30) +
                  "," +
                  `${
                    offsetYPercent > 85
                      ? y(d[depth_curve_name] - tooltipShift)
                      : offsetYPercent < 15
                      ? y(d[depth_curve_name] + tooltipShift)
                      : y(d[depth_curve_name])
                  })`
                // `${y(d[depth_curve_name])})`
              )
              .text(d[depth_curve_name]);

            let textTransition = focus.select("text.y2").attr("transform");
            console.log("transition of text:::::::", textTransition);
            let textX = Number(textTransition.split("(")[1].split(",")[0]);
            let textY = Number(
              textTransition.split("(")[1].split(",")[1].split(")")[0]
            );
            // console.log("transition checking::::::",textTransition)

            // let textY =
            focus
              .select(".y2_bg")
              .attr("transform", `translate(${textX},${textY - 20})`);
            // focus.select(".y2_bg").attr(
            // "transform",textTransition)

            // console.log("positions:::::::",d3.select("text.y2").attr('transform'),d3.select('rect.y2_bg').attr('transform'));
          }
          let back_height = Math.max(curve_names.length * 22, 66);
          //// curve value
          for (let i = 0; i < curve_names.length; i++) {
            let text = d[curve_names[i]];
            if (curve_names[i] == "activitycode") {
              switch (d[curve_names[i]]) {
                case 1:
                  text = "ROT DRLG";
                  break;
                case 2:
                  text = "SLD DRLG";
                  break;
                case 3:
                  text = "CIRC WITH ROT";
                  break;
                case 4:
                  text = "UNKNOWN";
                  break;
                case 10:
                  text = "CIRCULATION";
                  break;
                case 11:
                  text = "REAMING";
                  break;
                case 12:
                  text = "BACKREAM";
                  break;
                case 13:
                  text = "CONNECTION";
                  break;
                case 14:
                  text = "TIH-DRLG";
                  break;
                case 15:
                  text = "POOH-DRLG";
                  break;
                case 16:
                  text = "CIRC WITH ROT";
                  break;
                case 17:
                  text = "CIRCULATION";
                  break;
                case 30:
                  text = "STATIONARY";
                  break;
                case 5:
                  text = "TIH";
                  break;
                case 6:
                  text = "POOH";
                  break;
                case 20:
                  text = "PUMPOUT";
                  break;
                case 21:
                  text = "REAM-TRIP";
                  break;
                case 22:
                  text = "BACK REAM-TRIP";
                  break;
                case 23:
                  text = "CONN.TRIP";
                  break;
                case 24:
                  text = "CIRC WITH ROT-TRIP";
                  break;
                case 25:
                  text = "CIRC W/O ROT-TRIP";
                  break;
                case 26:
                  text = "CIRCULATION";
                  break;
                case 27:
                  text = "WASHDOWN";
                  break;

                default:
                  break;
              }
            }

            if (this.zero.indexOf(curve_names[i]) > -1) {
              text = text ? parseInt(text) : Number(text)
            } else if (this.triple.indexOf(curve_names[i]) > -1) {
              text = text ? parseFloat(text).toFixed(3) : Number(text).toFixed(3)
            } else {
              let value = parseFloat(text);
              if (!isNaN(value)) {
                if (curve_names[i].includes("capacity")) {
                  text = parseFloat(text).toFixed(4);
                } else {
                  text = parseFloat(text).toFixed(2);
                }
              }
            }
            if (this.logType == "time") {
              if (curve_names[i] == "alert") {
                text = d[curve_names[i]];
                if (typeof text == "string") {
                  let alertArr = text.split(",");

                  if (alertArr.length > 3) {
                    back_height = Math.max(alertArr.length * 22, 66);
                  }

                  for (let j = 0; j < 5; j++) {
                    let alerttext = alertArr[j] || "";

                    focus
                      .select(`.data${Number(j) + 1}`)
                      .attr(
                        "transform",
                        "translate(" +
                          (fixed_x_value - 78) +
                          "," +
                          `${
                            offsetYPercent > 85
                              ? y(d[depth_curve_name]) -
                                Math.floor(tooltipShift)
                              : offsetYPercent < 15
                              ? y(d[depth_curve_name]) +
                                Math.floor(tooltipShift)
                              : y(d[depth_curve_name])
                          })`
                      )
                      .text(alerttext)
                      .style("font-size", "0.6em");
                  }
                }
              } else {
                focus
                  .select(`.data${i + 1}`)
                  .attr(
                    "transform",
                    "translate(" +
                      (fixed_x_value - 78) +
                      "," +
                      `${
                        offsetYPercent > 85
                          ? y(d[depth_curve_name]) - Math.floor(tooltipShift)
                          : offsetYPercent < 15
                          ? y(d[depth_curve_name]) + Math.floor(tooltipShift)
                          : y(d[depth_curve_name])
                      })`
                    // `${y(d[depth_curve_name])})`
                  )
                  .text(text)
                  .style(
                    "font-size",
                    curve_names[i] == "activitycode" ||
                      curve_names[i] == "alert"
                      ? "0.6em"
                      : "0.8em"
                  );
              }
            } else {
              focus
                .select(`.data${i + 1}`)
                .attr(
                  "transform",
                  "translate(" +
                    (fixed_x_value - 45) +
                    "," +
                    `${
                      offsetYPercent > 85
                        ? y(d[depth_curve_name] - tooltipShift)
                        : offsetYPercent < 15
                        ? y(d[depth_curve_name] + tooltipShift)
                        : y(d[depth_curve_name])
                    })`
                  // `${y(d[depth_curve_name])})`
                )
                .text(text)
                .style(
                  "font-size",
                  curve_names[i] == "activitycode" || curve_names[i] == "alert"
                    ? "0.5em"
                    : "0.8em"
                );
            }
          }
          focus
            .select(".x")
            .attr(
              "transform",
              "translate(" + x(d[mouseover_curvename]) + "," + 0 + ")"
            )
            .attr("y2", height);
          //// circle y class part 2
          focus
            .select(".y")
            .attr(
              "transform",
              "translate(" +
                x(d[mouseover_curvename]) +
                "," +
                y(d[depth_curve_name]) +
                ")"
            )
            .text(d[mouseover_curvename]);
          focus
            .select(".yl")
            .attr(
              "transform",
              "translate(" + 0 + "," + y(d[depth_curve_name]) + ")"
            )
            .text(d[mouseover_curvename]);

          if (this.logType == "time") {
            focus
              .select(".background")
              .attr(
                "transform",
                "translate(" +
                  (fixed_x_value - 80) +
                  "," +
                  `${
                    offsetYPercent > 85
                      ? y(d[depth_curve_name]) - Math.floor(tooltipShift)
                      : offsetYPercent < 15
                      ? y(d[depth_curve_name]) + Math.floor(tooltipShift)
                      : y(d[depth_curve_name])
                  })`
                // `${y(d[depth_curve_name])})`
              )
              .attr("height", back_height)
              .transition()
              .duration(5000)
              .style("opacity", 1);
          } else {
            focus
              .select(".background")
              .attr(
                "transform",
                "translate(" +
                  (fixed_x_value - 50) +
                  "," +
                  `${
                    offsetYPercent > 85
                      ? y(d[depth_curve_name] - tooltipShift)
                      : offsetYPercent < 15
                      ? y(d[depth_curve_name] + tooltipShift)
                      : y(d[depth_curve_name])
                  })`
                // `${y(d[depth_curve_name])})`
              )
              .attr("height", back_height)
              .transition()
              .duration(5000)
              .style("opacity", 1);
          }
        }
      } catch (error) {
        console.log(
          "🚀 ➽ file: DisTrend.vue:3916  ➽ mousemove  ➽ error ⏩",
          error
        );
      }
    },
    mouseout(e) {
      // for (let focusAxis of this.focusAxisList) {
      //   let focus = focusAxis["focus"];
      //   focus
      //     .style("display", "none")
      //     .transition()
      //     .duration(250)
      //     .style("opacity", 0);
      // }
    },
    mousescroll(e) {
      if (this.logType == "time") {
        let scaleMin = 0;
        this.takeOffSocket();
        this.liveDisabled = false;

        // downscroll code
        if (e.deltaY > 0) {
          if (this.logType == "time") {
            if (this.scaleMaxFixed - 1 * 1000 * 60 * 60 - 2 > this.scaleMax) {
              this.scaleMax = this.scaleMax + 1 * 1000 * 60 * 60;
              this.scaleMin = this.scaleMin + 1 * 1000 * 60 * 60;
            } else {
              this.scaleMax = this.scaleMaxFixed;
              this.scaleMin = this.scaleMax - 1 * 1000 * 60 * 60;
            }
          } else {
            if (this.scaleMaxFixed - this.currentFeetRange > this.scaleMax) {
              this.scaleMax = this.scaleMax + this.currentFeetRange;
              this.scaleMin = this.scaleMin + this.currentFeetRange;
            } else {
              this.scaleMax = this.scaleMaxFixed;
              this.scaleMin = this.scaleMax - this.currentFeetRange;
            }
          }
        }
        // upscroll code
        if (e.deltaY < 0) {
          if (this.logType == "time") {
            if (this.scaleMinFixed < this.scaleMin - 1 * 1000 * 60 * 60) {
              this.scaleMax = this.scaleMax - 1 * 1000 * 60 * 60;
              this.scaleMin = this.scaleMin - 1 * 1000 * 60 * 60;
            } else {
              this.scaleMin = this.scaleMinFixed;
            }
          } else {
            if (this.scaleMinFixed < this.scaleMin - this.currentFeetRange) {
              this.scaleMax = this.scaleMax - this.currentFeetRange;
              this.scaleMin = this.scaleMin - this.currentFeetRange;
            } else {
              this.scaleMin = this.scaleMinFixed;
            }
          }
        }

        // console.log(
        //   "mouse scroll : min max : ",
        //   this.scaleMin,
        //   moment(this.scaleMin).format("MM-DD-YYYY HH:mm:ss"),
        //   "\n",
        //   this.scaleMax,
        //   moment(this.scaleMax).format("MM-DD-YYYY HH:mm:ss")
        // );
        if (this.slider && this.slider.noUiSlider) {
          this.slider.noUiSlider.updateOptions({
            behaviour: "drag",
            start: [this.scaleMin, this.scaleMax],
            // range: {
            //   min: this.scaleMinFixed,
            //   max: this.scaleMaxFixed,
            // },
          });
          if (this.mousewheeltimeout) clearTimeout(this.mousewheeltimeout);
          this.mousewheeltimeout = setTimeout(() => {
            this.zoomIt(
              this.scaleMax,
              this.scaleMin,
              this.feet,
              false,
              "mousewheel"
            );
            clearTimeout(this.mousewheeltimeout);
          }, 300);
        }
      } else {
        if (this.allow_to_change) clearTimeout(this.allow_to_change);
        this.allow_to_change = setTimeout(() => {
          let scaleMin = 0;
          this.takeOffSocket();
          this.liveDisabled = false;

          // downscroll code
          if (e.deltaY > 0) {
            if (this.logType == "time") {
              if (this.scaleMaxFixed - 1 * 1000 * 60 * 60 - 2 > this.scaleMax) {
                this.scaleMax = this.scaleMax + 1 * 1000 * 60 * 60;
                this.scaleMin = this.scaleMin + 1 * 1000 * 60 * 60;
              } else {
                this.scaleMax = this.scaleMaxFixed;
                this.scaleMin = this.scaleMax - 1 * 1000 * 60 * 60;
              }
            } else {
              if (this.scaleMaxFixed - this.currentFeetRange > this.scaleMax) {
                this.scaleMax = this.scaleMax + this.currentFeetRange;
                this.scaleMin = this.scaleMin + this.currentFeetRange;
              } else {
                this.scaleMax = this.scaleMaxFixed;
                this.scaleMin = this.scaleMax - this.currentFeetRange;
              }
            }
          }
          // upscroll code
          if (e.deltaY < 0) {
            if (this.logType == "time") {
              if (this.scaleMinFixed < this.scaleMin - 1 * 1000 * 60 * 60) {
                this.scaleMax = this.scaleMax - 1 * 1000 * 60 * 60;
                this.scaleMin = this.scaleMin - 1 * 1000 * 60 * 60;
              } else {
                this.scaleMin = this.scaleMinFixed;
              }
            } else {
              if (this.scaleMinFixed < this.scaleMin - this.currentFeetRange) {
                this.scaleMax = this.scaleMax - this.currentFeetRange;
                this.scaleMin = this.scaleMin - this.currentFeetRange;
              } else {
                this.scaleMin = this.scaleMinFixed;
              }
            }
          }

          // console.log(
          //   "mouse scroll : min max : ",
          //   this.scaleMin,
          //   moment(this.scaleMin).format("MM-DD-YYYY HH:mm:ss"),
          //   "\n",
          //   this.scaleMax,
          //   moment(this.scaleMax).format("MM-DD-YYYY HH:mm:ss")
          // );
          if (this.slider && this.slider.noUiSlider) {
            this.slider.noUiSlider.updateOptions({
              behaviour: "drag",
              start: [this.scaleMin, this.scaleMax],
              // range: {
              //   min: this.scaleMinFixed,
              //   max: this.scaleMaxFixed,
              // },
            });
            if (this.mousewheeltimeout) clearTimeout(this.mousewheeltimeout);
            this.mousewheeltimeout = setTimeout(() => {
              this.zoomIt(
                this.scaleMax,
                this.scaleMin,
                this.feet,
                false,
                "mousewheel"
              );
              clearTimeout(this.mousewheeltimeout);
            }, 300);
          }
          clearTimeout(this.allow_to_change);
        }, 100);
      }
    },
    async curveBox(template_for_plotting, index, divId, data) {
      // console.time("curve box timing : ", index)
      let template_overall = template_for_plotting[0]["curve_box"];

      let template_components = template_for_plotting[0]["components"];

      let template_curves = template_components[0]["curves"][0];

      let template_lines = template_components[0]["lines"];
      let template_rectangles = template_components[0]["rectangles"];
      let title = "";

      /// Parameters that define shape & size of overall curve box

      let height_multiplier_components = 0.95;
      if (template_overall["height_multiplier_components"]) {
        height_multiplier_components =
          template_overall["height_multiplier_components"];
      }

      let height_components = template_overall["height"];
      // let height = template_overall['height'] * height_multiplier_components;
      let height = height_components * height_multiplier_components;
      let margin = template_overall["margin"];
      let gridlines = template_overall["gridlines"];
      let gridlines_color = template_overall["gridlines_color"];
      let gridlines_stroke_width = template_overall["gridlines_stroke_width"];

      let mouseover_depth_or_depth_and_curve =
        template_overall["mouseover_depth_or_depth_and_curve"];
      let mouseover_curvename = template_overall["mouseover_curvename"]; //// default is first curve
      let mouseover_color_or_default_which_is_curve_color =
        template_overall["mouseover_color_or_default_which_is_curve_color"];
      let secondary_depth_exist = "no"; // THIS IS NOT YET EXISTING IN PLOTTING INPUT JSON SO HARDCODING FOR NOW
      //// Data is in d3.js form. An array of objects consisting of single level key:value pairs
      let width = template_overall["width"];

      if (data == null || data.length == 0 || !data["time"]) {
        this.isRerendering = false;
        return;
      }
      //// Variables related to curves, these should all be arrays with one or more values!
      let curve_names = template_curves["curve_names"];
      // if (curve_names && curve_names[0] == "alert") width *= 1.5
      let curve_colors = template_curves["curve_colors"];
      let curve_stroke_dasharray = template_curves["curve_stroke_dasharray"];
      let curve_name = curve_names[0];
      let curve_color = curve_colors[0];
      let curve_units = template_curves["curve_units"];
      let scale_linear_log_or_yours =
        template_curves["scale_linear_log_or_yours"];
      if (template_curves["curve_units"]) {
        curve_units = template_curves["curve_units"];
      } else {
        curve_units = "";
      }
      let curveScales = template_curves["scale"];
      let curveScaleType = template_curves["scaleType"];
      let alertRange = template_curves["alert"];

      //////// NEED TO MAKE THIS FLAG IN INPUT PLOTTING JSON
      let flag_for_single_scale_or_independent_scales =
        template_overall["grouped_or_independent_x_scales"];
      let grouped_or_independent_x_scale =
        template_overall["grouped_or_independent_x_scales"];
      //// The depth_curve_name needs to be the same for all curves plotted!
      let depth_curve_name = "";
      if (
        template_curves["depth_curve_name"].length > 1 &&
        typeof template_curves["depth_curve_name"] == "object" &&
        template_curves["depth_curve_name"][0] !==
          template_curves["depth_curve_name"][1]
      ) {
        depth_curve_name =
          "depth_curve_name is not the same in two or more curves";
      } else {
        depth_curve_name = template_curves["depth_curve_name"];
      }
      let depth_type_string = "";
      if (
        template_curves["depth_type_string"].length > 1 &&
        typeof template_curves["depth_type_string"] == "object" &&
        template_curves["depth_type_string"][0] !=
          template_curves["depth_type_string"][1]
      ) {
        depth_type_string =
          "depth type string is not the same in two or more curves";
      } else if (template_curves["depth_type_string"][0] == "") {
        depth_type_string = "";
      } else if (template_curves["depth_type_string"]) {
        depth_type_string = "- " + template_curves["depth_type_string"];
      }
      let depth_units_string = "";
      if (
        template_curves["depth_units_string"] &&
        template_curves["depth_units_string"][0] !== ""
      ) {
        depth_units_string = "- " + template_curves["depth_units_string"];
      }
      ///// THIS LINE BELOW DOESN"T MAKE ANY SENSE, CHANGE ////
      let div_id = template_overall["div_id"];
      if (template_overall["div_id"]) {
        div_id = template_overall["div_id"];
      } else {
        return "there_was_no_div_id_in_the_template";
      }
      d3.select("#" + div_id)
        .selectAll("*")
        .remove();

      ///////// NEED TO FIX DEPTHS AS THERE ARE MULTIPLE DEPTH LIMITS AND THEY NEED TO BE CALCULATED PROPERLY !!!!! //////////////////////////
      //       //// Calculate depth min and max if depth min and/or max is not given explicitly in the template
      let depth_min;
      let depth_max;
      if (
        !template_curves["depth_limits"] ||
        template_curves["depth_limits"][0]["min"] == "autocalculate"
      ) {
        depth_min = this.scaleMin;
      } else {
        depth_min = template_curves["depth_limits"][0]["min"];
      }
      //// max depth
      if (
        !template_curves["depth_limits"] ||
        template_curves["depth_limits"][0]["max"] == "autocalculate"
      ) {
        depth_max = this.scaleMax;
      } else {
        depth_max = template_curves["depth_limits"][0]["max"];
      }
      // [depth_max,depth_min]
      //// Apply depth min and max to incoming well log data
      //// To save time, we'll first check if the first object in the array had as depth that is smaller than min
      //// and check if the last object in the array has a depth that is larger than the max, if not. we do nothing.
      if (data.length > 0 && data != null) {
        if (
          data[0][depth_curve_name] > depth_min &&
          data[data.length - 1][depth_curve_name] < depth_max
        ) {
        } else {
          data = data.filter(function (objects) {
            return (
              objects[depth_curve_name] > depth_min &&
              objects[depth_curve_name] < depth_max
            );
          });
        }
        // let depth_min = -1000000
        // let depth_max = 1000000
        if (data.length > 0) {
          if (
            template_curves["min_depth"][0] == "autocalculate" ||
            template_curves["min_depth"] == "autocalculate"
          ) {
            depth_min = data[0][depth_curve_name];
          } else {
            depth_min = template_curves["min_depth"];
          }
          if (
            template_curves["max_depth"][0] == "autocalculate" ||
            template_curves["max_depth"] == "autocalculate"
          ) {
            depth_max = data[data.length - 1][depth_curve_name];
          } else {
            depth_max = template_curves["max_depth"];
          }
        }
      }
      let svg = "";
      let svg_holder = "";
      let svg_header = "";

      svg = d3
        .select("#" + div_id)
        .append("svg")
        .attr("class", "track_styles")
        .attr(
          "style",
          `border: 1.5px solid ${
            this.j !== "white" ? "var(--nav1BgClr)" : "#c1c0c0"
          }`
        )
        .style("max-height", height_components + "px")
        .style("height", height_components + "px");
      //////////////  Calculate Axis & Scales =>//////////////
      //// Need to handle: zero curves, arbitrary number of curves, and min/max of all curves in single axis.
      //// For zero curves, need to look into rectange and lines for x scales maybe?
      //// Need to handle scales in linear, log, or arbitary user-provided scale.
      //// Also, need x function for gridlines! so....?
      //////////////  Calculate x domain extent for one or more than one curve, used in scaling =>//////////////

      let mins = [];
      let maxes = [];
      mins = mins;
      maxes = maxes;

      for (let i = 0; i < curve_names.length; i++) {
        let curveObj = {
          name: "",
          min: "",
          max: "",
        };
        let curveName = curve_names[i];
        curveObj.name = curve_names[i];
        let curveScale = curveScales[i];
        let scaleType = curveScaleType[i];
        let min_this = "";
        let max_this = "";

        if (
          scaleType == "manual" &&
          (curveScale != null || curveScale != undefined)
        ) {
          if (curveScale[0] != null || curveScale[0] != "") {
            min_this = curveScale[0];
          } else {
            // min_this = this.minmax[curve_names[i]].min
            min_this = d3.min(this.data[curve_names[i]]);
            curveScale[0] = min_this;
          }
          if (curveScale[1] != null || curveScale[1] != "") {
            max_this = curveScale[1];
          } else {
            max_this = d3.max(this.data[curve_names[i]]);
            curveScale[1] = max_this;
          }
        } else {
          let currentRange = defaultRanges.ranges.find(
            (data) => data.name == curve_names[i]
          );

          if (currentRange) {
            min_this = currentRange.low;
            max_this = currentRange.high;
          } else {
            min_this = d3.min(this.data[curve_names[i]]);
            max_this = d3.max(this.data[curve_names[i]]);
          }
          curveScale[0] = min_this;
          curveScale[1] = max_this;
        }
        curveObj.min = min_this;
        curveObj.max = max_this;

        curvesMinsMaxes.push(curveObj);

        mins.push(min_this);
        maxes.push(max_this);
        mins = mins;
        maxes = maxes;
      }

      let min_all_curves = d3.min(mins);
      let max_all_curves = d3.max(maxes);
      if (curve_names.length == 0) {
        //// THIS NEEDS TO CHANGE TO LOOK AT RECTANGLE AT SOME POINT!!!!!!
        min_all_curves = 0;
        max_all_curves = 100;
      }
      //////////////  Calculate x domain extent for one or more than one curve, used in scaling =>//////////////
      let x_func;
      let x;
      let y = this.yLeftScale;

      let yAxis2 = (g) =>
        g
          .attr("transform", `translate(${margin.left - 35},0)`)
          .call(d3.axisLeft(y))
          .call((g) => g.select(".domain"));

      //////////////  Building curvebox parts that aren't header. First define size & title =>//////////////

      x = d3
        .scaleLinear()
        .domain([min_all_curves, max_all_curves])
        .range([margin.left, width - margin.right]);
      // .nice();
      svg.attr("class", `components components-${index}`);
      svg.attr("width", width).attr("height", height - 100);
      svg.attr("id", "svg" + div_id).on("mousewheel", (e) => {
        if (!this.isShowTooltip) this.mousescroll(e);
      });

      svg.style("margin", "0 auto");

      let distance_from_top = -15;
      if (title !== "") {
        svg
          .append("text") //
          .attr("x", margin.left / 3 + width / 2)
          .attr("y", 0 + -distance_from_top)
          .attr("text-anchor", "middle")
          .style("font-size", template_overall["title"]["title_font_size"])
          .text(title);
      }
      if (gridlines == "yes") {
        let xGrid = d3
          .scaleLinear()
          .domain([0, 500])
          .range([margin.left, width - margin.right]);

        let gridlines_obj = d3
          .axisTop()
          // .ticks((width - margin.left - margin.right) / 25)
          .ticks(4)
          .tickFormat("")
          .tickSize(-height - 50)
          .scale(xGrid);

        svg
          .append("g")
          .attr("class", "grid")
          .call(gridlines_obj)
          .style(
            "stroke",
            this.darkDark !== "white" ? gridlines_color : "black"
          )
          .style("stroke-dasharray", "5 5")
          .style("z-index", -1)
          .style("stroke-width", gridlines_stroke_width);
        let yGrid = d3
          .scaleLinear()
          .domain([this.scaleMax, this.scaleMin])
          .range([height_components, -10]);

        let horizontalLines = d3
          .axisLeft()
          .tickFormat("")
          .tickSize((-width + margin.left + margin.right) * 1)
          .ticks(18)
          .scale(yGrid);

        svg
          .append("g")
          .attr("class", "grid")
          .call(horizontalLines)
          .style("stroke-dasharray", "5 5")
          .style("stroke", gridlines_color)
          .style("color", this.darkDark !== "white" ? gridlines_color : "black")
          .style("stroke-width", gridlines_stroke_width)
          .style("z-index", 0)
          .attr("transform", "translate(0, 0 )");
      }

      ////////////////      TOOLTIP Part 1       ///////////////////
      if (this.isZoom) {
        let updateChart = async (event) => {
          let extent = event.selection;
          let min = y.invert(extent[0]);
          let max = y.invert(extent[1]);
          if (this.logType == "time") {
            min = moment(min).unix() * 1000;
            max = moment(max).unix() * 1000;
          }

          if (this.slider && this.slider.noUiSlider)
            this.slider.noUiSlider.updateOptions({
              behaviour: "drag",
              start: [parseInt(min), parseInt(max)],
              range: {
                min: this.scaleMinFixed,
                max: this.scaleMaxFixed,
              },
            });
          this.scaleMin = parseInt(min);
          this.scaleMax = parseInt(max);
          this.zoomIt(
            this.scaleMax,
            this.scaleMin,
            this.feet,
            false,
            "update 1409"
          );
        };

        let updateStart = async (event) => {
          event.sourceEvent.stopPropagation();
          // this.takeOffSocket()
        };

        let brushZoom1 = d3
          .brushY()
          .extent([
            [0, 0],
            [width, height_components - 20],
          ])
          .on("start", updateStart)
          .on("end", updateChart);
        svg.call(brushZoom1);
      }

      if (
        !this.isZoom &&
        (curve_names.indexOf("alert") > -1 ||
          curve_names.indexOf("stickslipindex") > -1 ||
          curve_names.indexOf("washoutindex") > -1)
      ) {
        //// appends start of mouseover rectange used for showing hover content
        let focus = svg
          .append("g")
          .attr("class", "g_focus_class")
          .style("opacity", 0);

        focus
          .append("rect")
          .attr("class", "background")
          .attr("fill", tooltipbg)
          .attr("height", 130)
          .attr("width", 95)
          .attr("rx", 8)
          .attr("opacity", 0.7);
        focus
          .append("line")
          .attr("class", "yl")
          .style("stroke", this.darkDark !== "white" ? "white" : "black")
          .style("stroke-array", "10")
          .style("opacity", 1)
          .attr("x1", 0)
          .attr("x2", height);
        if (
          mouseover_depth_or_depth_and_curve == "depth" ||
          mouseover_depth_or_depth_and_curve == "depth_and_curve"
        ) {
          focus
            .append("rect")
            .attr("class", "y2_bg")
            .attr("rx", "5px")
            .style("fill", " var(--wdigetBg)")
            .style("filter", "drop-shadow(gray 0px 3px 3px)")
            .style("opacity", 0.8)
            .attr("height", 20)
            .attr("width", width);
          focus
            .append("text")
            .attr("class", "y2")
            .attr("dx", 6)
            .attr("dy", "-.3em")
            .style("font-size", "0.85em")
            .style("z-index", 999999999)
            .style("stroke", this.darkDark !== "white" ? tooltipbg : "black");
        }
        //// curve value on hover

        if (
          mouseover_depth_or_depth_and_curve == "curve" ||
          mouseover_depth_or_depth_and_curve == "depth_and_curve"
        ) {
          for (let i = 0; i < 5; i++) {
            focus
              .append("text")
              .attr("class", `data${i + 1}`)
              .attr("dx", 1)
              .attr("dy", `${0.5 * (i + 1)}cm`)
              .style("font-size", "0.8em")
              .style("fill", "black")
              .style("z-index", "black")
              .style("stroke", curve_colors[0])
              .style("stroke-width", "0.5px");
          }
        }
        // append the rectangle to capture mouse               // **********

        svg
          .append("rect") // **********
          .attr("width", width) // **********
          .style("height", height_components + "px")
          .attr("height", 10) // **********
          .attr("height", height) // **********
          .attr("id", "rect" + div_id)
          .style("fill", "none") // **********
          .style("pointer-events", "all") // **********

          .on("mouseover", (e) => {
            if (this.isShowTooltip) this.mousemove(e);
            // focus
            //   .style("display", "none")
            //   .transition()
            //   .duration(250)
            //   .style("opacity", 0);
          })
          .on("mouseout", (e) => {
            if (this.isShowTooltip) this.mouseout(e);
          })
          .on("mousemove", (e) => {
            if (this.isShowTooltip) this.mousemove(e);
          })
          .on("mousewheel", (e) => {
            this.mousescroll(e);
          });

        // **********
        // }
        this.focusAxisList[index] = {
          focus,
          x,
          y,
          curve_colors,
          curve_names,
          mouseover_curvename,
          depth_curve_name,
          width,
          height,
        };
      }

      let x_functions_for_each_curvename = {};
      let onHoverAlert = (event, alert) => {
        // this.isEraticMse = true;
        let remaining_height = window.innerHeight - event.clientY;
        this.mouse_x = event.clientX - 60;
        this.mouse_y =
          remaining_height < 388 ? window.innerHeight - 388 : event.clientY; //336;
        this.alertValueChange(true, alert);
        return true;
      };
      let onClickAlert = (event, alert) => {
        this.alertValueClicked(true, alert);
        return true;
      };
      let onMouseOut = (alert) => {
        // this.isEraticMse = false;
        if (this.liveDisable) {
          if (this.alertTimeOut) {
            clearTimeout(this.alertTimeOut);
          }
          this.alertTimeOut = setTimeout(() => {
            this.alertValueChange(false, alert);
            this.hoveringAlert = "";
            this.mouse_x = 0;
            this.mouse_y = 0;
          }, 2000);
        } else {
          if (this.alertTimeOut) {
            clearTimeout(this.alertTimeOut);
          }
          this.alertTimeOut = setTimeout(() => {
            this.alertValueChange(false, alert);
            this.hoveringAlert = "";
            this.mouse_x = 0;
            this.mouse_y = 0;
          }, 2000);
        }
        return true;
      };
      let drawTrend = () => {
        for (let k = 0; k < curve_names.length; k++) {
          if (curve_names[k] == "alert") {
            console.log("checking note alert in DIS trend~~~~1");
            let g = svg.append("g").attr("height", height_components);

            let startActcCode = 0;
            let endActcCode = 0;
            let startActcTime = 0;
            let endActcTime = 0;
            let totalRecords = data["activitycode"].length - 1;
            let actcColor = "red";
            let tooltip = "";
            let logType = this.logType;

            g = svg.append("g").attr("height", height_components);
            // .style("transform", "translate(20px, 0px)");
            let lastMessage = "";
            let currentTimeRange = this.currentTimeRange;
            let alertArray = this.alertArr;
            let lastTime = 0;
            let lastEndTime = 0;
            let countAlert = 0;
            let lastOffset = 18;
            function wrap(text, width) {
              text.each(function (d) {
                // this.bbox = this.getBBox();
                // console.log("🚀 ➽ file: DisTrend.vue:3982  ➽ d.bbox ⏩" , d.bbox)
                let text = d3.select(this),
                  words = text.text().split(/\s+/).reverse(),
                  word,
                  line = [],
                  lineNumber = 0,
                  lineHeight = 0.8, // ems
                  x = text.attr("x"),
                  y = text.attr("y"),
                  dy = 0, //parseFloat(text.attr("dy")),
                  tspan = text
                    .text(null)
                    .append("tspan")
                    .attr("x", x)
                    .attr("y", y)
                    .attr("dy", dy + "em");

                while ((word = words.pop())) {
                  line.push(word);
                  tspan.text(line.join(" "));

                  if (tspan.node().getComputedTextLength() > width) {
                    line.pop();
                    tspan.text(line.join(" "));
                    line = [word];
                    tspan = text
                      .append("tspan")
                      .attr("x", x)
                      .attr("y", y)
                      .attr("dy", ++lineNumber * lineHeight + dy + "em")
                      .text(word);
                  }
                }
              });
            }

            function drawAlert(istance, alerts, isPair, i) {
              if (!lastEndTime) {
                lastEndTime =
                  y(alerts[0].time) +
                  (y(alerts[alerts.length - 1].time) - y(alerts[0].time)) / 2;
              } else {
                let newPlacement =
                  y(alerts[0].time) +
                  (y(alerts[alerts.length - 1].time) - y(alerts[0].time)) / 2;

                lastEndTime += lastOffset;
                if (newPlacement > lastEndTime) {
                  lastEndTime = newPlacement;
                }
              }

              let message = alerts[0].msg.split(",");
              let messageLength = message.length;
              for (let alertC = 0; alertC < messageLength; alertC++) {
                // lastEndTime = alerts[0].time +
                //   ((alerts[alerts.length - 1].time - alerts[0].time) / 2);
                
                let textMessage = message[alertC];
                // if(alertArray.indexOf(textMessage) == -1){
                //   console.log("insde alert filter : ", textMessage)
                //   continue;
                // }
                console.log("alertmessage::::::::::", textMessage);
                if (alertC) lastEndTime += lastOffset;
                if (isPair) {
                  d3.select(istance)
                    .append("line")
                    .style("stroke", actcColor)
                    .style("stroke-width", 2)
                    .attr("x1", 0)
                    .attr("y1", y(alerts[0].time))
                    .attr("x2", 13)
                    .attr("y2", lastEndTime);

                  d3.select(istance)
                    .append("line")
                    .style("stroke", actcColor)
                    .style("stroke-width", 2)
                    .attr("x1", 0)
                    .attr("y1", y(alerts[alerts.length - 1].time))
                    .attr("x2", 13)
                    .attr("y2", lastEndTime);
                } else {
                  // lastEndTime = alerts[0].time;
                  d3.select(istance)
                    .append("line")
                    .style("stroke", actcColor)
                    .style("stroke-width", 2)
                    .attr("x1", 0)
                    .attr("y1", y(alerts[0].time))
                    .attr("x2", 13)
                    .attr("y2", lastEndTime);
                }
                let textWarp = d3
                  .select(istance)
                  .append("text")
                  .attr("class", "bar")
                  .attr("data-index", i)
                  .attr("x", 14)
                  .style("fill", actcColor);

                textWarp
                  .text(textMessage)
                  .attr("y", function () {
                    return lastEndTime + 3;
                  })
                  .style("font-size", "0.8em")
                  .style("font-weight", "400")
                  .style("z-index", 999999999)
                  .style("cursur", "pointer")
                  .on("click", function (e) {
                    onClickAlert(e, d3.select(this).text());
                  })
                  .on("mousenter", function (e) {
                    d3.select(this).style("cursor", "pointer");
                    onHoverAlert(e, d3.select(this).text());
                  })
                  .on("mouseover", function (e) {
                    d3.select(this).style("cursor", "pointer");
                    onHoverAlert(e, d3.select(this).text());
                  })
                  .on("mouseout", function (e) {
                    onMouseOut(d3.select(this).text());
                  });
                // console.log("textWarp.node().getComputedTextLength()", textWarp.node().getComputedTextLength(), textMessage, (width - 15) / textWarp.node().getComputedTextLength())
                let height = "13px";
                lastOffset = 15;
                if (
                  (width - 15) / textWarp.node().getComputedTextLength() <
                  1
                ) {
                  height = "23px";
                  lastOffset = 25;
                }
                if (
                  (width - 15) / textWarp.node().getComputedTextLength() <
                  0.5
                ) {
                  height = "33px";
                  lastOffset = 35;
                }
                if (
                  (width - 15) / textWarp.node().getComputedTextLength() <
                  0.4
                ) {
                  height = "43px";
                  lastOffset = 45;
                }
                d3.select(istance)
                  .insert("rect", "text")
                  .attr("class", "bar")
                  .attr("ry", "5px")
                  .attr("data-index", i)
                  .attr("width", width - 15 + "px")
                  .attr("x", "13px")
                  // .style("opacity", 0.5)
                  .style("fill", "white")
                  .style("filter", "drop-shadow(gray 0px 3px 3px)")
                  .attr("y", lastEndTime - 8)
                  .attr("height", height);

                // if (messageLength == 1) {
                textWarp.call(wrap, width - 15);
                // }
              }
            }
            let alerts = [];
            g.selectAll(".group")

              .data(data["alert"])
              .attr("class", "group")
              .enter()
              .each(function (d, i) {
                alerts.push({ msg: d, index: i, time: data["time"][i] });
                if (alerts[0].msg) {
                  if (alerts[0].msg == data["alert"][i + 1]) {
                  } else {
                    if (alerts.length > 1) {
                      console.log(
                        "more" +
                          alerts[0].msg +
                          " start index " +
                          alerts[0].index +
                          " end index : " +
                          alerts[alerts.length - 1].index
                      );
                      drawAlert(this, alerts, true, i);
                      alerts = [];
                    } else {
                      console.log(
                        "else " +
                          alerts[0].msg +
                          " start index " +
                          alerts[0].index
                      );
                      drawAlert(this, alerts, false, i);
                      alerts.shift();
                    }
                  }
                } else {
                  alerts = [];
                }
              });
          } else if (curve_names[k] == "activitycode") {
            let g = svg
              .append("g")
              .attr("height", height_components)
              .style("transform", "translate(30px, 0px)");

            let startActcCode = 0;
            let endActcCode = 0;
            let startActcTime = 0;
            let endActcTime = 0;
            let totalRecords = data["activitycode"].length - 1;
            let actcColor = "white";
            let tooltip = "";
            let logType = this.logType;

            g.selectAll(".group")
              .data(data["activitycode"])
              .attr("class", "group")
              .enter()
              .each(function (d, i) {
                if (i == 0) {
                  if (logType == "time") {
                    startActcCode = d;
                    startActcTime = data["time"][i];
                  } else {
                    startActcCode = d;
                    startActcTime = data["dmea"][i];
                  }
                } else {
                  if (logType == "time") {
                    endActcCode = d;
                    endActcTime = data["time"][i];
                  } else {
                    endActcCode = d;
                    endActcTime = data["dmea"][i];
                  }

                  if (
                    parseInt(startActcCode) != parseInt(endActcCode) ||
                    i == totalRecords
                  ) {
                    switch (startActcCode) {
                      case 1:
                        actcColor = "#92D050";
                        tooltip = "ROT DRLG";
                        break;
                      case 2:
                        actcColor = "#00B050";
                        tooltip = "SLD DRLG";
                        break;
                      case 3:
                        actcColor = "#FFC000";
                        tooltip = "CIRC WITH ROT";
                        break;
                      case 4:
                        actcColor = "#FFFFFF";
                        tooltip = "UNKNOWN";
                        break;
                      case 10:
                        actcColor = "#833C0C";
                        tooltip = "CIRCULATION";
                        break;
                      case 11:
                        actcColor = "#00CC99";
                        tooltip = "REAMING";
                        break;
                      case 12:
                        actcColor = "#008000";
                        tooltip = "BACKREAM";
                        break;
                      case 13:
                        actcColor = "#CC3300";
                        tooltip = "CONNECTION";
                        break;
                      case 14:
                        actcColor = "#2F75B5"; // Bright Blue
                        tooltip = "TIH-DRLG";
                        break;
                      case 15:
                        actcColor = "#8EA9DB"; //Cadet Blue
                        tooltip = "POOH-DRLG";
                        break;
                      case 16:
                        actcColor = "#DBDBDB";
                        tooltip = "CIRC WITH ROT";
                        break;
                      case 17:
                        actcColor = "#FFFF00";
                        tooltip = "CIRCULATION";
                        break;
                      case 30:
                        actcColor = "#FFE699";
                        tooltip = "STATIONARY";
                        break;
                      case 5:
                        actcColor = "#1F4E78";
                        tooltip = "TIH";
                        break;
                      case 6:
                        actcColor = "#BDD7EE";
                        tooltip = "POOH";
                        break;
                      case 20:
                        actcColor = "#8497B0";
                        tooltip = "PUMPOUT";
                        break;
                      case 21:
                        actcColor = "#9900FF";
                        tooltip = "REAM-TRIP";
                        break;
                      case 22:
                        actcColor = "#FF3399";
                        tooltip = "BACK REAM-TRIP";
                        break;
                      case 23:
                        actcColor = "#CCCC00";
                        tooltip = "CONN. TRIP";
                        break;
                      case 24:
                        actcColor = "#CC9900";
                        tooltip = "CIRC W/O ROT-TRIP";
                        break;
                      case 25:
                        actcColor = "#FF9933";
                        tooltip = "CIRC W/O ROT-TRIP";
                        break;
                      case 26:
                        actcColor = "#833C0C";
                        tooltip = "CIRCULATION";
                        break;
                      case 27:
                        actcColor = "#945E0D";
                        tooltip = "WASHDOWN";
                        break;

                      default:
                        break;
                    }

                    d3.select(this)
                      .append("rect")
                      .attr("class", "bar")
                      .attr("data-index", i)
                      .attr("width", "60px")
                      // .attr('x', function(e) { return x(d.Date); })
                      .style("fill", actcColor)
                      .attr("y", y(startActcTime))
                      // .attr("height", "48px");
                      .attr(
                        "height",
                        y(endActcTime) - y(startActcTime)
                          ? y(endActcTime) - y(startActcTime) + "px"
                          : " 0px"
                      )
                      .append("text");

                    if (logType == "time") {
                      startActcCode = d;
                      startActcTime = data["time"][i];
                    } else {
                      startActcCode = d;
                      startActcTime = data["dmea"][i];
                    }
                  } else {
                  }
                }
              });
          } else {
            //// code that creates a line for each Curve in order provided and applies
            //// the color in the color array in order provided
            let curveUnit = "";
            if (curve_units[k]) {
              curveUnit = curve_units[k];
            }
            let min = mins[k];
            let max = maxes[k];
            let header_text_line = "";
            if (min != null && max != null) {
              header_text_line =
                parseFloat(min).toFixed(1) +
                " - " +
                curve_names[k] +
                "  " +
                curveUnit +
                " - " +
                parseFloat(max).toFixed(1);
            }
            let curveScale = curveScales[k];
            let min_this = "";
            let max_this = "";
            let scaleType = curveScaleType[k];
            if (
              scaleType == "manual" &&
              (curveScale != null || curveScale != undefined)
            ) {
              if (curveScale[0] != null || curveScale[0] != "") {
                min_this = curveScale[0];
              } else {
                // min_this = this.minmax[curve_names[k]].min
                min_this = d3.min(this.data[curve_names[k]]);
                curveScale[0] = min_this;
              }
              if (curveScale[1] != null || curveScale[1] != "") {
                max_this = curveScale[1];
              } else {
                // max_this = this.minmax[curve_names[k]].max
                max_this = d3.max(this.data[curve_names[k]]);
                curveScale[1] = max_this;
              }
            } else {
              let currentRange = defaultRanges.ranges.find(
                (data) => data.name == curve_names[k]
              );
              if (currentRange) {
                max_this = currentRange.high;
                min_this = currentRange.low;
              } else {
                min_this = d3.min(this.data[curve_names[k]]);
                max_this = d3.max(this.data[curve_names[k]]);
              }
              curveScale[0] = min_this;
              curveScale[1] = max_this;
            }

            if (min_this == max_this && max_this == 0) {
              max_this = 1;
              min_this = 0;
            }

            let x = d3
              .scaleLinear()
              .domain([min_this, max_this])
              .range([margin.left, width - margin.right]);

            if (k == 0) {
              x_func == x;
            }
            //// This creates an object to hold multiple x axis scale functions
            //// that will be used if 'between' style fill is selected.
            x_functions_for_each_curvename[curve_names[k]] = x;
            //////////////  Header text, two way depending on  =>//////////////

            let recent_point_x = 0;
            let another_line = function (x_arr, y_arr, name) {
              return d3
                .line()
                .x(function (d, i) {
                  // if (x_arr[i])
                  //   recent_point_x = x_arr[i]
                  let point = x(x_arr[i]);
                  // ? x(x_arr[i])
                  // : x(recent_point_x)
                  // ? x(recent_point_x)
                  // : 0;
                  if (isNaN(x(point))) {
                    // point = null;
                    return 0;
                  } else return point;
                })
                .y(function (d, i) {
                  let point = y(y_arr[i]);
                  if (isNaN(point)) {
                    // console.log(point, "point to be noted y", name);
                    // point = null;
                  } else return point;
                })(Array(x_arr ? x_arr.length : 0));
            };

            svg
              .insert("path", ".g_focus_class")

              .attr("fill", "none")
              .attr("stroke", curve_colors[k])
              .attr("stroke-width", template_curves["stroke_width"][k])
              .attr("class", "area")
              .attr(
                "d",
                another_line(
                  data[curve_names[k]],
                  data[depth_curve_name],
                  curve_names[k]
                )
              );
            if (curve_names[k] === "depthofcut") {
              let name = curve_names[k];
              let fill_color = curve_colors[k];
              let area = d3.area();
              let threshold = max_all_curves;
              let left = min_all_curves;
              area
                .x1((d, i) => (x(d) ? x(d) : x(0)))
                .x0((d) => (x(left) ? x(left) : x(0)))
                .defined((d, i) => d >= min_all_curves)
                .y((d, i) =>
                  y(data[depth_curve_name][i])
                    ? y(data[depth_curve_name][i])
                    : y(0)
                );

              svg
                .insert("path", ".g_focus_class")
                .datum(data[name])
                .attr("class", "area")
                .attr("d", area)
                .attr("stroke", "none")
                .attr("fill", fill_color)
                .attr("fill-opacity", 0.8);
            }

            if (curve_names[k] == "stickslipindex") {
              let lastMsg = "";
              let lastMsgTime = "";
              let skipInterval;
              switch (this.currentTimeRange) {
                case 1:
                  skipInterval = 1 * 300000;
                  break;
                case 2:
                  skipInterval = 2 * 300000;
                  break;
                case 4:
                  skipInterval = 12 * 300000;
                  break;
                case 8:
                  skipInterval = 6 * 300000;
                  break;
                case 12:
                  skipInterval = 9 * 300000;
                  break;
                case 24:
                  skipInterval = 12 * 300000;
                  break;

                default:
                  skipInterval = 2 * 300000;
                  break;
              }
              let filterIndex = [];
              if (data["stickslip"]) {
                let txtRect = svg
                  .append("g")
                  .classed("text-labels-group", true)
                  .selectAll("text")
                  .data(data["stickslip"])
                  .enter()
                  .filter(function (d, i) {
                    if (d == undefined) {
                      return false;
                    } else {
                      if (
                        "NONE" != d &&
                        (lastMsgTime == "" ||
                          lastMsgTime + skipInterval <
                            data[depth_curve_name][i])
                      ) {
                        lastMsgTime = data[depth_curve_name][i];
                        if ("NONE" != d) filterIndex.push(i);
                        return "NONE" != d;
                      } else {
                        return false;
                      }
                    }
                  });
                txtRect
                  .append("rect")
                  .attr("width", width)
                  .attr("height", "20")
                  .style("fill", function (d) {
                    let color = "yellow";
                    if (d == "STICKSLIP PROBABLE") {
                      color = "red";
                    }
                    return color;
                  })
                  .attr("y", function (d, i) {
                    return y(data[depth_curve_name][filterIndex[i]]) - 14;
                  })
                  .style("opacity", "0.6");
                txtRect
                  .append("text")
                  .attr("x", function (d) {
                    return 0;
                  })
                  .attr("y", function (d, i) {
                    return y(data[depth_curve_name][filterIndex[i]]);
                  })
                  .text(function (d, i) {
                    if ("NONE" != d) return d;
                  })
                  .style("font-size", "0.7em")
                  .attr("fill", "black")
                  .style("font-weight", "400")
                  .on("click", function (e) {
                    onClickAlert(e, d3.select(this).text());
                  })
                  .on("mousenter", function (e) {
                    d3.select(this).style("cursor", "pointer");
                    onHoverAlert(e, d3.select(this).text());
                  })
                  .on("mouseover", function (e) {
                    d3.select(this).style("cursor", "pointer");
                    onHoverAlert(e, d3.select(this).text());
                  })
                  .on("mouseout", function (e) {
                    onMouseOut(d3.select(this).text());
                  });
              }
            }

            if (curve_names[k] == "washoutindex") {
              let lastMsg = "";
              let lastMsgTime = "";
              let skipInterval;
              if (data["washout"]) {
                switch (this.currentTimeRange) {
                  case 1:
                    skipInterval = 1 * 300000;
                    break;
                  case 2:
                    skipInterval = 2 * 300000;
                    break;
                  case 4:
                    skipInterval = 3 * 300000;
                    break;
                  case 8:
                    skipInterval = 3 * 300000;
                    break;
                  case 12:
                    skipInterval = 5 * 300000;
                    break;
                  case 24:
                    skipInterval = 6 * 300000;
                    break;

                  default:
                    break;
                }
                let filterIndex = [];
                let txtRect = svg
                  .append("g")
                  // .classed("text-labels-group", true)
                  .selectAll("text")
                  .data(data["washout"])
                  .enter()
                  .filter(function (d, i) {
                    if (d == undefined) {
                      return false;
                    } else {
                      if (
                        "NONE" != d &&
                        (lastMsgTime == "" ||
                          lastMsgTime + skipInterval <
                            data[depth_curve_name][i])
                      ) {
                        lastMsgTime = data[depth_curve_name][i];
                        if ("NONE" != d) filterIndex.push(i);
                        return "NONE" != d;
                      } else {
                        return false;
                      }
                    }
                  });
                txtRect
                  .append("rect")
                  .attr("width", width)
                  .attr("height", "20")
                  .style("fill", function (d) {
                    return "red";
                  })
                  .attr("y", function (d, i) {
                    return y(data[depth_curve_name][filterIndex[i]]) - 14;
                  })
                  .style("opacity", "0.6");
                txtRect
                  .append("text")
                  .attr("x", function (d) {
                    return 0;
                  })
                  .attr("y", function (d, i) {
                    return y(data[depth_curve_name][filterIndex[i]]);
                  })
                  .text(function (d, i) {
                    if ("NONE" != d) return d;
                  })
                  .attr("fill", "black")
                  .style("font-size", "0.7em")
                  .style("font-weight", "400")
                  .on("click", function (e) {
                    // console.log("🚀 ➽ file: DisTrend.vue:4078  ➽ e ⏩", e);
                    onClickAlert(e, d3.select(this).text());
                  })
                  .on("mousenter", function (e) {
                    d3.select(this).style("cursor", "pointer");
                    onHoverAlert(e, d3.select(this).text());
                  })
                  .on("mouseover", function (e) {
                    d3.select(this).style("cursor", "pointer");
                    onHoverAlert(e, d3.select(this).text());
                  })
                  .on("mouseout", function (e) {
                    onMouseOut(d3.select(this).text());
                  });
              }
            }
          }
        }

        if (
          !this.isZoom &&
          (!(
            curve_names.indexOf("alert") > -1 ||
            curve_names.indexOf("stickslipindex") > -1 ||
            curve_names.indexOf("washoutindex") > -1
          ) ||
            !this.isShowAlerts)
        ) {
          //// appends start of mouseover rectange used for showing hover content

          let focus = svg
            .append("g")
            .attr("class", "g_focus_class")
            .style("opacity", 0);
          focus
            .append("rect")
            .attr("class", "background")
            .attr("fill", tooltipbg)
            .attr("height", 130)
            .attr("width", 95)
            .attr("rx", 8)
            // .transition().duration(1000).style("opacity", 1)
            .attr("opacity", 0.7);
          focus
            .append("line")
            .attr("class", "yl")
            .style("stroke", this.darkDark !== "white" ? "white" : "black")
            .style("stroke-array", "10")
            .style("opacity", 1)
            .attr("x1", 0)
            .attr("x2", height);
          if (
            mouseover_depth_or_depth_and_curve == "depth" ||
            mouseover_depth_or_depth_and_curve == "depth_and_curve"
          ) {
            focus
              .append("rect")
              .attr("class", "y2_bg")
              .attr("rx", "5px")
              .style("fill", " var(--wdigetBg)")
              .style("filter", "drop-shadow(gray 0px 3px 3px)")
              .style("opacity", 0.8)
              .attr("height", 20)
              .attr("width", width);
            focus
              .append("text")
              .attr("class", "y2")
              .attr("dx", 6)
              .attr("dy", "-.3em")
              .style("font-size", "0.85em")
              .style("z-index", 999999999)
              .style("stroke", this.darkDark !== "white" ? tooltipbg : "black");
          }
          //// curve value on hover

          if (
            mouseover_depth_or_depth_and_curve == "curve" ||
            mouseover_depth_or_depth_and_curve == "depth_and_curve"
          ) {
            for (let i = 0; i < curve_colors.length; i++) {
              focus
                .append("text")
                .attr("class", `data${i + 1}`)
                .attr("dx", 1)
                .attr("dy", `${0.5 * (i + 1)}cm`)
                .style("font-size", "0.8em")
                .style("fill", "black")
                .style("z-index", "black")
                .style("stroke", curve_colors[i])
                .style("stroke-width", "0.5px");
            }

            if (curve_names.indexOf("alert") > -1) {
              for (let i = 1; i < 5; i++) {
                focus
                  .append("text")
                  .attr("class", `data${i + 1}`)
                  .attr("dx", 1)
                  .attr("dy", `${0.5 * (i + 1)}cm`)
                  .style("font-size", "0.8em")
                  .style("fill", "black")
                  .style("z-index", "black")
                  .style("stroke", curve_colors[0])
                  .style("stroke-width", "0.5px");
              }
            }
          }
          // append the rectangle to capture mouse               // **********

          svg
            .append("rect") // **********
            .attr("width", width) // **********
            .style("height", height_components + "px")
            .attr("height", 10) // **********
            .attr("height", height) // **********
            .attr("id", "rect" + div_id)
            .style("fill", "none") // **********
            .style("pointer-events", "all") // **********

            .on("mouseover", (e) => {
              if (this.isShowTooltip) this.mousemove(e);
              // focus
              //   .style("display", "none")
              //   .transition()
              //   .duration(250)
              //   .style("opacity", 0);
            })
            .on("mouseout", (e) => {
              if (this.isShowTooltip) this.mouseout(e);
            })
            .on("mousemove", (e) => {
              if (this.isShowTooltip) this.mousemove(e);
            })
            .on("mousewheel", (e) => {
              this.mousescroll(e);
            });

          // **********
          // }

          this.focusAxisList[index] = {
            focus,
            x,
            y,
            curve_colors,
            curve_names,
            mouseover_curvename,
            depth_curve_name,
            width,
            height,
          };
        }

        try {
          for (let i = 0; i < template_lines.length; i++) {
            let this_line = template_lines[i];
            svg
              .append("line")
              .attr("x1", 0 + margin.left)
              .attr("y1", y(this_line["depth"]))
              .attr("x2", width * 0.75)
              .attr("y2", y(this_line["depth"]))
              .style("stroke-width", this_line["stroke_width"])
              .style("stroke", this_line["color"])
              // .style("stroke", function (d, i) {
              //   if (d[curve_names[k]] < 0) return "white";
              //   if (d[curve_names[k]] < 50) return "red";
              //   if (d[curve_names[k]] < 150) return "green";
              // })
              .style("stroke-dasharray", this_line["stroke-dasharray"])
              .style("stroke-linecap", this_line["stroke_linecap"])
              .style("fill", "none");

            if (this.logType == "time") {
              svg
                .append("text")
                .attr("x", width * 0.75)
                .attr("y", y(this_line["depth"]))
                .attr("text-anchor", "start")
                .style("font-size", "calc(0.2vw + 0.3vh + 0.65vmin)")
                .text(
                  new Date(this_line["label"]).toLocaleString("en-US", {
                    timeZone: this.time_zone,
                  })
                );
            } else {
              svg
                .append("text")
                .attr("x", width * 0.75)
                .attr("y", y(this_line["depth"]))
                .attr("text-anchor", "start")
                .style("font-size", "min(1vw, 9px)")
                .text(this_line["label"]);
            }
          }
        } catch (err) {
          console.error(
            "could not do lines for tops in curveBox function, error: ",
            err
          );
        }
      };
      // if (!this.liveDisabled) {
      //   setTimeout(drawTrend, (11 - index));
      // } else {
      drawTrend();
      // }

      if (this.plots.length - 1 == index) {
        this.isRerendering = false;
        let graphType = "dmea";

        if (this.logType == "time") {
          graphType = "time";
        }
      }
      // console.timeEnd("curve box timing : ", index)
    },
    getConfig() {
      let tconfig = {
        height: "500",
        width: "260",
        UWI: "00/01-01-095-19W4/0",
        curveName: "CHALKTALK",
        tracks: this.tracks,
        curveType: "dmea",
        depthName: "dmea",
      };
      this.trackConfig = tconfig;
      return tconfig;
    },
    configure() {
      let UWI = this.trackConfig.UWI;
      // let curveNames = curveNames;
      // let curveColors=curveColors;
      let curveName = this.trackConfig.curveName;
      let _plots = [];
      for (let i = 0; i < this.trackConfig.tracks.length; i++) {
        let track = this.trackConfig.tracks[i];
        let curveNames = track.map((curve) => curve.name);
        let curveColors = track.map((curve) => curve.color);

        let fillColor = track.map((curve) => curve.fillC);
        let fillDir = track.map((curve) => curve.fillD);
        let fill = track.map((curve) => curve.fill);
        let strokes = track.map((curve) => curve.stroke || 2);
        let units = track.map((curve) => {
          return this.$store.state.disp.displays[this.displayId].units[
            this.$store.state.disp.displays[this.displayId].tags.indexOf(
              curve.name
            )
          ];
        });
        let scale = track.map((curve) => curve.scale);
        let alert = track.map((curve) => curve.alert);
        let isAlert = track.map((curve) => curve.isAlert);
        let decimals = track.map((curve) => curve.decimals);
        let scaleType = track.map((curve) => curve.scaleType);
        let trackId;
        if (track.length > 0) {
          trackId = track[0].trackId;
        }

        let trackObjs = [];
        for (let j = 0; j < track.length; j++) {
          let configObj = {
            curve_name: "",
            fill: "",
            fill_direction: "",
            cutoffs: [],
            fill_colors: [],
          };
          configObj.curve_name = track[j].name;
          configObj.fill_direction = track[j].fillD;
          configObj.fill = track[j].fill;
          configObj.cutoffs = [0, 4.25];
          configObj.fill_colors = [track[j].fillC];
          trackObjs.push(configObj);
          trackObjs = trackObjs;
        }
        if (this.singleTrackWidth > this.trackWidth) {
          this.singleTrackWidth = this.trackWidth;
        }
        let graphType = "dmea";
        this.logType == "time" ? (graphType = "time") : null;
        let plot = plotConfig(
          this.plotTemplate,
          UWI,
          curveNames,
          curveColors,
          units,
          strokes,
          scale,
          scaleType,
          trackObjs,
          this.div_id,
          this.singleTrackWidth,
          this.singleTrackHeight,
          graphType,
          this.tickColor,
          this.tickThickness,
          leftbarbg,
          tooltipbg,
          trackId,
          alert,
          decimals,
          isAlert
        );
        _plots.push(plot);
      }

      this.plots = _plots;
    },
    async getSingleTagData(tagName, isUpdate, from) {
      this.getMnemonicType(tagName);
      this.$store.dispatch("data/startLoading");
      let result = null;
      let dconfig = {};

      if (isUpdate) dconfig.fields = tagName;
      else dconfig.fields = [tagName];

      dconfig.wellId = this.wellId;
      dconfig.wellboreId = this.wellboreId;
      dconfig.logId = this.logId;
      dconfig.dataPoints = null;
      dconfig.depthRange = null;

      try {
        if (this.logType == "time") {
          let fieldArr =
            tagName == "dmea" ? [tagName, "alert"] : [tagName, "dmea", "alert"];
          if (fieldArr.indexOf("stickslipindex") > -1)
            fieldArr.push("stickslip");
          if (fieldArr.indexOf("washoutindex") > -1) fieldArr.push("washout");
          for (let i in this.mnemonic_groups) {
            result = await apiService.WellServices.getTimeLogs({
              fields: this.mnemonic_groups[i],
              well_id: this.wellId,
              wellbore_name: this.wellboreId,
              isParse: 1,
              type: i,
              from: this.scaleMax,
              to: this.scaleMin,
            });
            if (result && result.data && result.data.data) {
              if (
                result.data.data[this.mnemonic_groups[i][0]] &&
                result.data.data[this.mnemonic_groups[i][0]].length == 0
              ) {
                // this.$toast.error("Data is not available for this interval", {
                //   position: "top",
                //   duration: 3000,
                // });
              } else {
                this.completeData = {
                  ...this.completeData,
                  ...result.data.data,
                };
                this.data = this.completeData;
                this.minmax = { ...this.minmax, ...result.data.minmax };
              }
            } else {
              // this.$toast.error("Server is taking longer than usual", {
              //   position: "top",
              //   duration: 3000,
              // });
            }
          }
        } else {
          console.log("getSingleTagData called");
          apiService.WellServices.getDepthLogs({
            fields: dconfig.fields,
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
            // isPartial: false,
          })
            .then(async (data) => {
              console.log("---------- whole data came 2 ----------");
              if (data && data.data && data.data.data) {
                console.log(data.data);
                this.completeData = { ...this.completeData, ...data.data.data };
              }
            })
            .catch((e) => {
              console.error("whole data error : ", e);
            });
          result = await apiService.WellServices.getDepthLogs({
            fields: dconfig.fields,
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
            isPartial: true,
            start: this.scaleMin,
            end: this.scaleMax,
          });

          // result = result.data;

          // if (isUpdate) {
          //   for (let i of tagName) {
          //     await this.addData(result, i);
          //   }
          // } else {
          //   result = await this.addData(result, tagName);
          // }
          if (result && result.data && result.data.data) {
            if (
              result.data.data["dmea"] &&
              result.data.data["dmea"].length == 0
            ) {
              this.$toast.error("Data is not available for this well", {
                position: "top",
                duration: 3000,
              });
            }
          }
          this.completeData = { ...this.completeData, ...result.data.data };
          this.data = { ...this.completeData };
          this.minmax = { ...this.minmax, ...result.data.minmax };
        }
      } catch (error) {
        console.error(error);
      }
      setTimeout(() => {
        this.$store.dispatch("data/stopLoading");
      }, 700);

      // }
      return result;
    },
    async getMinMaxRange() {
      try {
        let result = null;
        if (this.logType == "time") {
          result = await apiService.WellServices.getMinMaxTime({
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
          });
          console.log(
            "time zone difference : ",
            result.data.min,
            moment(result.data.min).unix() * 1000
          );
          result.data.min = moment(result.data.min).unix() * 1000;
          result.data.max = moment(result.data.max).unix() * 1000;
        } else {
          result = await apiService.WellServices.getMinMaxDepth({
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
          });
          result.data.min = result.data.min;
          result.data.max = result.data.max;
        }

        return result.data;
      } catch (error) {
        console.error(error);
        return { min: 0, max: 1000 };
      }
    },
    async getItFromLocalData(range, isLogChange) {
      if (this.logType == "depth") {
        console.log("complete data befor ", this.completeData, isLogChange);
        if (isLogChange) {
          let fields = this.getOnScreenMnemonics();

          await this.getMultiTagsData(fields, "getItFromLocalData");

          this.rerender("getItFromLocalData depth");
        } else {
          let graphType = "dmea";
          this.logType == "time" ? (graphType = "time") : null;

          if (!this.completeData[graphType]) {
            let fields = this.getOnScreenMnemonics();

            await this.getMultiTagsData(fields, "getItFromLocalData");
          }

          this.completeData[graphType].map((row, i) => {
            if (
              row == range[0] ||
              (row < range[0] && !(row > range[0])) ||
              (range[0] == 0 && i == 0)
            ) {
              this.lowerIndex = i;
            }

            if (row == range[1] || row < range[1]) {
              this.upperIndex = i;
            }
          });
          let tempData = { ...this.completeData };

          for (let i in tempData) {
            this.data[i] = tempData[i].slice(
              this.lowerIndex,
              this.upperIndex + 1
            );
          }
        }
      } else {
        this.getOnScreenMnemonics();

        if (!this.liveDisabled || this.data.length == 0) {
          this.$store.dispatch("data/startLoading");
          for (let i in this.mnemonic_groups) {
            // if(thisgraph is replay)
            let result = await apiService.WellServices.getTimeLogs({
              fields: this.mnemonic_groups[i],
              well_id: this.wellId,
              wellbore_name: this.wellboreId,
              secondsdataavailability: this.secondsdataavailability,
              isParse: 1,
              type: i,
              // days: 0.2,
              from: parseInt(range[1]),
              to: parseInt(range[0]),
            });
            if (result && result.data && result.data.data) {
              if (
                result.data.data[this.mnemonic_groups[i][0]] &&
                result.data.data[this.mnemonic_groups[i][0]].length == 0
              ) {
                // this.$toast.error("Data is not available for this interval", {
                //   position: "top",
                //   duration: 3000,
                // });
              } else {
                this.completeData = {
                  ...this.completeData,
                  ...result.data.data,
                };
                this.data = this.completeData;
                this.minmax = { ...this.minmax, ...result.data.minmax };
              }
            } else {
              // this.$toast.error("Server is taking longer than usual", {
              //   position: "top",
              //   duration: 3000,
              // });
            }
          }
          setTimeout(() => {
            this.$store.dispatch("data/stopLoading");
          }, 700);
        } else {
        }
      }
    },
    async zoomIt(depthMax, depthMin, feet, isLogChange, from) {
      let totalRows;
      let depthRange;

      if (depthMax > depthMin) {
        totalRows = Math.round(depthMax - depthMin);
        depthRange = [depthMin, depthMax];
      } else {
        totalRows = Math.round(depthMin - depthMax);
        depthRange = [depthMax, depthMin];
      }

      await this.getItFromLocalData(depthRange, isLogChange);

      this.rerender("zoomIt");
    },
    async rerender(from) {
      // console.log("delete rerender checkl : ", this.isRerendering);
      if (this.isRerendering) {
        return 0;
      }
      this.isRerendering = true;

      this.trackConfig = await this.getConfig();
      if (this.trackConfig.tracks && this.trackConfig.tracks.length) {
        this.tracksNbr = this.trackConfig.tracks.length;
        if (this.tracksNbr > 0) {
          if (this.trackConfig.tracks[0][0].name != null) {
            this.readyToPlot = true;
            this.emptyPlot = false;

            this.configurePlots = await this.configure();
            if (this.plots) this.verticalplots(this.div_id, this.plots, true);

            let sliderId = "slider" + this.compId;
            let sliderEle = document.querySelector("#" + sliderId);
            if (sliderEle != null) {
              sliderEle.style.height = this.singleTrackHeight - 60 + "px";
            }
            // this.setTrackInfo();
          }
        }
      } else {
        this.isRerendering = false;
      }
    },
    deleteTrack() {
      this.showCurveInfo = false;
      trackDeleteDisabled = true;

      this.trackConfig.tracks.splice(trackNbr, 1);
      this.trackConfig.tracks = this.trackConfig.tracks;
      this.trackConfig = this.trackConfig;
      // this.getTracksRight();
      // this.adjustTracks();
      this.configure();
      this.verticalplots(this.div_id, this.plots, true);
      // this.setTrackInfo();
    },
    addTrack() {
      let trackId = "t" + Math.random().toString(36).slice(8);

      this.readyToPlot = true;
      this.emptyPlot = false;
      this.isNewTrack = true;
      this.showCurveInfo = false;
      this.curveDeleteDisabled = true;
      this.newCurveDisabled = true;
      if (this.trackConfig == null) {
        // getConfig();
      }
      // getConfig();
      // if (this.trackConfig.tracks.length == 0 ) {
      //
      //     tracks = [];
      //     this.trackConfig.tracks = tracks;
      // }
      // let trackObj = {};
      // trackObj.trackId = trackId;

      this.tracks.push([]);
      this.tracks = this.tracks;
      // this.trackConfig.tracks = tracks;
      if (this.tracks[0].length == 0) {
        this.readyToPlot = false;
      }
      // if (tracks[0].length == 0) {
      //     readyToPlot = false;
      // }

      this.getConfig();
      this.trackConfig.tracks = this.tracks;
      // this.trackConfig.tracks.push([]);
      // this.trackConfig.tracks = this.trackConfig.tracks;
      this.trackConfig = this.trackConfig;
      currentCurve = {};
      let trackLength = this.trackConfig.tracks.length;
      trackNbr = trackLength - 1;
      // this.getTracksRight(trackNbr, trackId);
      this.selectedTrackValue = this.totTracks[trackNbr];

      this.newCurveDisabled = false;
      // this.setTrackInfo();
      return trackId;
    },
    changeTooltip() {
      this.isShowTooltip = !this.isShowTooltip;
      if (!this.isShowTooltip) {
        for (let focusAxis of this.focusAxisList) {
          let focus = focusAxis["focus"];
          focus
            .style("display", "none")
            .transition()
            .duration(250)
            .style("opacity", 0);
        }
      }
      // if(this.isZoom = true){
      //   this.isShowTooltip = false
      // }
    },
    changeZoom() {
      this.isZoom = !this.isZoom;
      this.rerender();
    },
    changeAlert() {
      this.isShowAlerts = !this.isShowAlerts;
      this.rerender();
    },
  },
  watch: {
    width(newValue) {
      this.rerender("width");
    },
    height(newValue) {
      this.rerender("height");
    },
  },
};

// let tracks = [];

let trackNbr = 0;

let tooltipbg = "white";

let leftbarbg = "#202124";

let currentCurve = {};

let curvesMinsMaxes = [];
</script>

<style scoped>
.colorbox {
  width: 5px;
  height: 100%;
}

.zoomin {
  width: 50px;
  background: lightgoldenrodyellow;
  position: absolute;
  bottom: 0;
}

.textBgColor {
  background: yellow;
  background-color: yellow;
}

.area {
  fill: lightsteelblue;
}

/* .scrolldiv {
  } */
.actc_pop {
  position: absolute;
  left: -101px;
}

/* hover tooltip */

.tool_group {
  position: fixed;
  display: inline-grid;
  right: 162px;
  z-index: 99999;
}

.tooltip_actc {
  position: absolute;
  display: block;
  height: 100%;
}

.tooltip_actc .tooltiptext {
  visibility: hidden;
  color: #fff;
  text-align: center;
  border-radius: 12px;
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 105%;
}

.tooltip_actc .tooltiptext::after {
  content: "";
  position: absolute;
  right: -6px;
  top: 5px;
  content: "\1F782";
  font-size: 14px;
  color: #005e62;
}

.tooltip_actc .tooltiptext {
  visibility: visible;
  background: #0e1016;
  background: #00071a;
  z-index: 999999;
}

.tooltiptext {
  background: var(--nav1BgClrsame) !important;
  padding: 2vmin 2vmin;
  padding-bottom: 1vmin;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  /* grid-gap: 2px; */
  padding: 0;
  justify-content: center;
  float: right;
}

.grid-container > div {
  text-align: center;
  padding: 5px 10px;
  font-size: 30px;
  margin: 0 auto;
  width: 330px;
}

.grid-container > .drill_eff_item_3 {
  width: 850px;
}

.grid-container > .drill_eff_item_1 {
  width: 850px;
}

/* .item1 {
  grid-column: 1 / 2;
} */
.item1 {
  grid-column: 2 / 2;
}

._item_ {
  height: auto;
  max-height: 22em;
  border-radius: 12px;
}

._item_:last-child {
  grid-column: 2;
}

._item_ > img {
  height: 100%;
}

.t_d_col_1 {
  /* width: 20%;
  display: flex;
  justify-content: end; */
}

.t_d_col_2 {
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.td_trnk {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: calc((0.3em + 0.3vmin) + (0.2em + 0.2vmax));
}

.text_gray {
  color: #dee2e6;
}

.text_dark {
  /* color: #000000; */
  font-weight: 600;
}

.t_d_col_3 {
  width: 25%;
}

.t_d_row_1 {
  /* font-size: 8px; */
  /* font-weight: bold; */
  font-size: 9px;
  letter-spacing: 0.5px;
  font-weight: 600 !important;
  padding-top: 4px;
}

.t_d_row_2 {
  /* position: relative;
  bottom: -2px;
  font-size: 7.9px;
  font-weight: bold; */
  position: relative;
  bottom: 2px;
  font-size: 9px;
  font-weight: 500;
  letter-spacing: 0.5px;
}

.tooltip__tip {
  text-align: center;
}

.tooltip__tip::after {
  display: none;
  padding: 10px 15px;
  position: absolute;
  text-align: center;
  z-index: 999;
  color: white;
}

.tooltip__tip::before {
  content: " ";
  display: none;
  position: absolute;
  width: 7px;
  height: 7px;
  z-index: 999;
}

.tooltip__tip_dark::after {
  background: #1b1a1f !important;
  color: #ffffff;
}

.tooltip__tip_dark::before {
  background: #1b1a1f !important;
}

.tooltip__tip_light::after {
  background: #99fad7 !important;
  color: #000000 !important;
}

.tooltip__tip_light::before {
  background: #99fad7 !important;
  color: #1b1a1f !important;
}

.tooltip__tip:hover::after {
  display: block;
}

.tooltip__tip:hover::before {
  display: block;
}

.tooltip__tip.top::after {
  content: attr(data-tip);
  top: 7px;
  left: 50%;
  width: auto;
  font-size: min(1vw, 9px);
  /* calc(0.2vw + 0.2vh + 0.65vmin); */
  padding: 3px 14px;
  transform: translate(-50%, calc(-100% - 10px));
}

.tooltip__tip.top::before {
  top: 5px;
  left: 50%;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.tool_tip_units_r.top::after {
  content: attr(data-tip);
  top: 2px;
  left: 81%;
  padding: 3px 14px;
  font-size: 7px;
  -webkit-transform: translate(-50%, calc(-100% - 10px));
  transform: translate(-50%, calc(-100% - 10px));
}

.tool_tip_units_r.top::before {
  top: 0;
  left: 90%;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.tool_tip_units_l.top::after {
  content: attr(data-tip);
  top: 2px;
  left: 16%;
  padding: 3px 14px;
  font-size: 7px;
  -webkit-transform: translate(-50%, calc(-100% - 10px));
  transform: translate(-50%, calc(-100% - 10px));
}

.tool_tip_units_l.top::before {
  top: 0;
  left: 16%;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.selectedBtn_tooltip {
  width: 100%;
  /* font-size: calc((.3em + .3vmin) + (.3em + .3vmax)); */
  /* width: 100%;
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap; */
  /* padding-left: 3px; */
  text-align: left;
}

.tooltip__tip_live {
  /* border-radius: 10px; */
  /* position: relative; */
  text-align: center;
}

.tooltip__tip_live::after {
  background-color: #000000;
  display: none;
  padding: 10px 15px;
  position: absolute;
  text-align: center;
  z-index: 999;
  color: white;
}

.tooltip__tip_live::before {
  background-color: #000000;
  content: " ";
  display: none;
  position: absolute;
  height: 7px;
  z-index: 999;
}

.tooltip__tip_live:hover::after {
  display: block;
}

.tooltip__tip_live:hover::before {
  display: block;
}

.tooltip__tip_live.top::after {
  content: attr(data-tip);
  bottom: 96px;
  left: 164px;
  width: 100%;
  font-size: 7px;
  padding: 3px 5px;
  transform: translate(-50%, calc(-100% - 10px));
}

.tooltip__tip_live_dis.top::after {
  content: attr(data-tip);
  bottom: -2px;
  left: 8px;
  font-size: 7px;
  padding: 1px 5px;
  -webkit-transform: translate(-50%, calc(-100% - 10px));
  transform: translate(-50%, calc(-100% - 10px));
}

.tooltip__tip_live.top::before {
  bottom: 107px;
  left: 164px;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.tooltip__tip_live_dis.top::before {
  bottom: 16px;
  left: 12px;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.selectedBtn_tooltip {
  width: 100%;
  /* width: 100%;
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap; */
}

.noUi-target_dup {
  /* background: #202124; */
  background: var(--wdigetBg);
  transition: var(--transition);
}

.bg-black_mattie {
  /* background: #1e1e1e; */
  background: var(--wdigetBg);
  transition: var(--transition);
}

.light_trend-mode {
  /* background: #FFFFF7 !important; */
  background: var(--wdigetBg);
  transition: var(--transition);
  /* #fcffe2; */
  /* #fff8e4; */
  /* #ffffe7; */
  /* #fcfbda; */
  /* #fffff0 */
}

._ann_tooltip_logo {
  width: 100%;
  opacity: 0.3;
}

.ann_head {
  font-size: medium;
  font-family: "Ubuntu", sans-serif;
}

.left_width {
  width: 100%;
  height: 100%;
  max-height: 50em;
  overflow: scroll;
}

.right_width {
  /* width: 10%; */
}

.sub_head_para {
  color: yellow;
}


.grid {
  stroke: 10;
}

.tool_toggle_input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
}

.tool_toggle_label {
  cursor: pointer;
  text-indent: -9999px;
  width: 23px;
  height: 12px;
  display: block;
  border-radius: 100px;
  position: relative;
}

.tool_toggle_label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 2px;
  width: 10px;
  height: 10px;
  background: var(--sidebarbg);
  border-radius: 90px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.tool_toggle_label {
  /* background: #5aba81; */
  /* background: #4b4c4c; */
  background: var(--toggleSliderBg);
}

.tool_toggle_input:checked + .tool_toggle_label:after {
  -webkit-transform: translateX(9px);
  transform: translateX(9px);
}

.tool_toggle_label:active:after {
  /* width: 130px; */
}

.tool_toggle_input:checked + ._tooltip {
  background: #5aba81;
}

.tool_toggle_input:checked + ._alert {
  background: #ff2200;
}

.tool_toggle_input:checked + ._zoom {
  background: #00bcd4;
}

/* ._tooltip{
  background: #5aba81;

} */
/* ._alert {
  background: #ff2200;
} */
/* ._zoom{
  background: #00bcd4;
} */
.muted_bg {
  background: #3c3a3a;
}

.muted_bg:after {
  background: #616161;
}

.tool_section {
  display: flex;
  justify-content: center;
  align-self: center;
  margin-top: 4px;
}

.live_section_btn {
  display: flex;
  height: 100%;
  align-items: center;
}

.drill_eff_left {
  width: 100% !important;
}

.drill_eff_item_1 {
  grid-column: 2;
  grid-row: 1;
}

.drill_eff_item_2 {
  grid-column: 2;
  grid-row: 2;
}

.drill_eff_item_3 {
  grid-column: 2;
  grid-row: 2;
}

.dv {
  /* fill: #208cfb; */
  /* fill: #32e73f; */
  /* fill: #87cefa */
  fill: lightblue;
  font-weight: 700;
  letter-spacing: 0.1px;
}

.ddv {
  fill: #32e73f;
  font-weight: 700;
  letter-spacing: 0.1px;
}

.dp__input {
  padding: 4px !important;
  color: #ffffff !important;
  background: #0e1016 !important;
  font-size: min(0.9vw, 12px);
}

.dp__main {
  margin: 1px 2px;
  margin-top: 0;
}

mark {
  background: unset;
  /* background: red; */
  color: white;
  font-size: 0.8vw;
  font-weight: 500;
  letter-spacing: 1.5px;
}

[type="checkbox"],
[type="radio"] {
  content: "";
  background-color: #28282f !important;
  background-color: unset !important;
  margin-left: auto;
  border-radius: 0;
}

[type="checkbox"]:checked,
[type="radio"]:checked {
  border: 1px solid #5aba81 !important;
  /* background-image: url(/img/check_ico.406e337a.svg); */
}

.dp__input {
  background-color: #262626 !important;
  color: #ffffff !important;
}

.dis_live_btn {
  position: relative;
  right: -7px;
  top: -8px;
}

.non_dis_sec {
  width: 130px;
  display: flex;
  justify-content: space-around;
  gap: 6px;
}

.actc_tool {
  font-size: 10px;
  background: #000000;
  width: auto;
  position: absolute;
  padding: 2px 5px;
  /* border-radius: 20px; */
}

.disabled {
  /* pointer-events: none; */
  pointer-events: none;
  cursor: default;
  display: inline-block;
  padding: 4px;
  background-color: #b6adb4;
  border-radius: 2px;
  border: 0px;
  text-align: center;
  /* color: #fff !important; */
  text-decoration: none !important;
  opacity: 0.5;
  z-index: -1;
}

.track_config_border {
  /* border-left: 1px solid #3f3f46;
  border-right: 1px solid #3f3f46; */
  /* margin: 2px; */
  /* margin-bottom: 1px; */
  /* border:1px solid red */
}

.td_unit_ {
  font-size: calc((0.2em + 0.2vmin) + (0.2em + 0.2vmax));
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.depth_pick {
  background: unset !important;
  width: 100% !important;
  height: 20px !important;
  font-size: 10px !important;
  margin: 0 3px;
}

.feet_ {
  width: 25%;
}

.feet_boxed {
  width: 75%;
}

.noUi-handle {
  width: 48px !important;
}

.__bg_label_boxes {
  background: var(--colorBoxBg);
  transition: var(--transition);
}

.name_numaric_con {
  width: calc(100% - 5px);
  margin: 0 3px;
  display: block;
}

.dark_trend_mode {
  background: var(--central_bg);
  transition: var(--transition);
}

.margin_top_title {
  margin-top: 0.5rem !important;
}

.colorBox_text_gray {
  color: var(--colorBoxHeadingText);
  font-weight: 600 !important;
  font-size: calc(0.29vw + 0.3vh + 0.35vmin);
  height: 14px;
  display: flex;
  align-items: end;
  justify-content: center;
  /* background: var(--colorBoxHeadBackround); */
  /* font-size: 0.55rem; */
  margin-top: 0.07rem;
}

.colorBox_num {
  color: var(--colorBoxWhiteNum);
  /* font-weight: 600; */
}

.colorBox_textMesure {
  font-size: 9px;
  color: var(--colorBoxHeadingText);
  letter-spacing: 0.7px;
}

.liveBtnDot {
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50%;
  /* background-color: #12B822; */
  /* background:var(--liveBtnGreen) */
}

.newStylesLiveBtn {
  display: flex;
  align-items: center;
  gap: 7px;
  border-radius: 10px;
  padding: 2px 8px;
  /* selectingTimeFeetColorBg */
  /* background: var(--liveBtnGreen) !important; */
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 9px !important;
  color: #fff;
  /* color: #ffffff !important; */
}

.newStWDepth {
  margin: 0px;
  /* width: 75%; */
  justify-content: start !important;
}

.newStWDeptInput {
  background: var(--colorBoxPopUpInputBg) !important;
  border: none;
  color: var(--textColor) !important;
  margin-left: 0px;
  font-weight: 600;
  text-align: center;
  border-radius: 10px;
}

.normalBg {
  background: var(--colorBoxBg);
  color: var(--selectingTimeFeetColor);
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.15);
  transition: var(--transition);
}

.timeBtn:hover {
  background: var(--activeTextColor);
  color: #ffffff;
  transition: var(--transition);
}

.activeBg {
  background: var(--activeTextColor);
  color: #ffffff;
}

.timeBtn {
  padding: 0px 7px;
  border-radius: 8px;
}

.arrangementClass {
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.15);
}

.settingPopColor {
  color: var(--textColor);
  /* font-weight: 500; */
  letter-spacing: 1px;
  font-size: 9px;
}

.__toggle__boxes {
  /* height: 20px; */
  /* margin-top: 5px; */
  /* padding-left: 10px; */
  padding-right: 5px;
}

.__label_boxes {
  margin-top: auto;
}

.__trend_bg_seperation {
  border: solid 0.5px;
  border-color: dimgray;
}

.value_of_Mnemonic_in_DIS {
  font-size: 0.65rem;
}

.rages_of_mnemonics_in_DIS {
  padding-bottom: 0.1rem;
  /* font-size: 0.55rem; */
}

mark {
  font-weight: 300px;
}

.ann_para ::v-deep ol,
ul {
  list-style-type: decimal;
  word-spacing: 2.5px;
  margin-left: 5%;
}

.annotation_desc ::v-deep h5 > strong {
  letter-spacing: 3px;
}

.annotation_desc ::v-deep p > strong {
  letter-spacing: 3px;
}

::v-deep li.ql-indent-1:not(.ql-direction-rtl) {
  list-style-type: disc;
  margin-left: 5%;
}

::v-deep li.ql-indent-2:not(.ql-direction-rtl) {
  list-style-type: disc;
  margin-left: 10%;
}
</style>
