

let plotConfig = '';
export default plotConfig = function (example_template,
    well_names, curve_names, curve_colors, curve_units,
    strokes, scale,scaleType, fill, div_id, width, height, depth_curve_name,
    tickColor, tickThickness, leftbarbg, tooltipbg, trackId, alert, decimals,isAlert) {
    //// remember that all curve components should be an array, even if one item!
    let example_template_n = JSON.parse(JSON.stringify(example_template));
    // example_template_n[0]["components"][0]["curves"][0]["data"] = data;
    // example_template_n[0]["components"][0]["curves"][0]["well_names"] = well_names;
    example_template_n[0]["components"][0]["curves"][0]["curve_names"] = curve_names;
    example_template_n[0]["components"][0]["curves"][0]["curve_colors"] = curve_colors;
    // example_template_n[0]["components"][0]["curves"][0]["curve_units"] = curve_units;
    example_template_n[0]["components"][0]["curves"][0]["stroke_width"] = strokes;
    
    // example_template_n[0]["components"][0]["curves"][0]["fill"] = fill;
    example_template_n[0]["components"][0]["curves"][0]["depth_curve_name"] = depth_curve_name;
    example_template_n[0]["components"][0]["curves"][0]["scale"] = scale;
    example_template_n[0]["components"][0]["curves"][0]["alert"] = alert;
    example_template_n[0]["components"][0]["curves"][0]["scaleType"] = scaleType;
    example_template_n[0]["components"][0]["curves"][0]["decimals"] = decimals ;
    example_template_n[0]["components"][0]["curves"][0]["isAlert"] = isAlert ;
    // example_template_n[0]["components"][0]["curves"][0]["gridlines_color"] = tickColor;
    // example_template_n[0]["components"][0]["curves"][0]["gridlines_stroke_width"] = tickThickness;


    example_template_n[0]["curve_box"]["div_id"] = div_id;
    example_template_n[0]["curve_box"]["width"] = width;
    example_template_n[0]["curve_box"]["height"] = height;
    // example_template_n[0]["curve_box"]["gridlines_color"] = tickColor;
    // example_template_n[0]["curve_box"]["gridlines_stroke_width"] = tickThickness;
    example_template_n[0]["curve_box"]["leftbarbg"] = leftbarbg;
    example_template_n[0]["curve_box"]["tooltipbg"] = tooltipbg;
    example_template_n[0]["curve_box"]["trackId"] = trackId;



    return example_template_n;
}