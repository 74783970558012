export default {
    GET_MNEMONICS: () => "lwd-mnemonics",
    DATA_POINTS: () => "lwd-exist",
    WELL_INFO_SEARCH: () => "wellinfo/search",
    WITSML_INFO_SEARCH: () => "witsmlsource/search",
    LWD_MAPPING_SEARCH: () => "lwdmapping/search",
    LWD_MAPPING_SAVE_UPDATE: () => "loggingwhiledrilling/mapping/data",
    LWD_MAPPING__UPDATE: () => "lwdmapping/saveorupdate",
    SOURCE_LOG_SEARCH: () => "sourcelog/search",
    SOURCE_WELL_DATA: () => "witsml/wellsdata",

    // LITHOLOGY
    LITHOLOGY_SAVE_UPDATE: () => "displayicon/saveorupdate",
    LITHOLOGY_LIST: () => "displayicon/list",
    LITHOLOGY_WELL_SAVE_UPDATE: () => "lithologyrange/update",
    LITHOLOGY_WELL_SEARCH: () => "lithologyrange/search",
    GET_MNEMONICS: () => "lwd-mnemonics",
    DATA_POINTS: () => "lwd-exist",
    DATA_POINTS1: () => "lwd-exist-v1",
    ICONS_LIST: () => "displayicon/list",
    WELL_BASED_LITHOLOGY_ICONS: () => "lithologyrange/search",
    DELETE_LITHOLOGY: () => "lithologyrange/delete",

    CONSOLIDATED_LIST: () => "lwdconsolidatedrange/consolidatedlist",
    CONSOLIDATED_DATA_SAVE: () => "lwdconsolidatedrange/saveorupdate",
    CONSOLIDATED_DATA_LIST_SAVE: () => "lwdconsolidatedrange/list/saveorupdate",
    CONSOLIDATED_DATA_LIST_DELETE: () => "lwdconsolidatedrange/delete/consolidatedlist",
    ADD_ICON_TO_FILE: () => "loggingwhiledrilling/lwddata/update",
    CONSOLIDATE_LITHO_ICONS: () => "lwd-lithology-exist",
    SAVE_NEW_RUN: () => "lwd",
    GET_SAVED_RUNS: () => "lwds",
    DELETE_SAVED_RUN: () => "saved_lwd",
}
