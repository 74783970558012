export default {
    GETTING_ALERTS: () => "miscellaneous/type/values",
    MISCELLANEOUS_LIST: () => "miscellaneous/list",


    COMMUNICATION_CONFIG_SEARCH: () => "communicationconfiguration/search",
    COMMUNICATION_CONFIG_SAVE_UPDATE: () => "communicationconfiguration/saveorupdate",
    COMMUNICATION_CONFIG_LIST: () => "communicationconfiguration/list",

    //ALERT ANNOTATIONS
    ANNOTATIONS_LIST: () => "alertannotations/list",
    ANNOTATIONS: () => "public/annotations",
    ALERTS_LIST: () => "public/alerts_list",

}
// miscellaneous/type/values