<template>
  <div class="loader_component">
    <div class="progress" id="PreLoaderBar">
      <div class="indeterminate"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "loader",

  data() {
    return {

    };
  },
  mounted() {
    document.onreadystatechange = function () {
      if (document.readyState === "complete") {
        
        document.getElementById("PreLoaderBar").style.display = "none";
      }
    }
  },
  methods: {

  },
};
</script>
<style>
.progress {
  position: relative;
  height: 2px;
  display: block;
  width: 100%;
  background-color: white;
  border-radius: 2px;
  background-clip: padding-box;
  /*margin: 0.5rem 0 1rem 0;*/
  overflow: hidden;

}

.progress .indeterminate {
  background-color: black;
}

.progress .indeterminate:before {
  content: '';
  position: absolute;
  background-color: #2C67B1;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}

.progress .indeterminate:after {
  content: '';
  position: absolute;
  background-color: #2C67B1;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  -webkit-animation-delay: 1.15s;
  animation-delay: 1.15s;
}

@-webkit-keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -90%;
  }
}

@-webkit-keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }

  60% {
    left: 107%;
    right: -8%;
  }

  100% {
    left: 107%;
    right: -8%;
  }
}

@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }

  60% {
    left: 107%;
    right: -8%;
  }

  100% {
    left: 107%;
    right: -8%;
  }
}

.loader_gif {
  position: absolute;
  z-index: 99999;
  left: 0;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
}

.loader_gif_icon {
  z-index: 99999999;
  width: 19%;
  /* transform: translate(-50%, calc(-100% - 5px)) rotate(45deg); */
  /* display: flex; */
  /* align-items: center; */
  justify-content: center;
}

.load_percent {
  margin: 3px 10px;
}

.loader_component {
  /* background: #6060604a; */
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 99999999999;
}
</style>
