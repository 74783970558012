<template>
  <div
    class="list w-auto title-bar-controls left_bg__"
    v-bind:id="compId"
    :style="whStyles"
    @drop="onDrop($event, 1)"
    @dragenter="onDragEnter($event, 2)"
    @dragover.prevent
    
  >
    <div>
      <div>
        <VueDragResize
          v-for="(rect, index) in rects"
          :snapToGrid="false"
          :gridX="20"
          :key="rect.widgetId"
          :id="rect.widgetId"
          :w="setting_width(rect)"
          :h="setting_height(rect)"
          :x="rect.left"
          :y="rect.top"
          :parentW="width"
          :parentH="height"
          :axis="rect.axis"
          :isActive="rect.active"
          :minw="rect.minw"
          :minh="rect.minh"
          :isDraggable="rect.draggable"
          :isResizable="rect.resizable"
          :parentLimitation="rect.parentLim"
          :aspectRatio="rect.aspectRatio"
          :z="rect.zIndex"
          :contentClass="rect.class"
          v-on:activated="activateEv(rect.widgetId)"
          v-on:deactivated="deactivateEv(rect.widgetId)"
          v-on:dragging="changePosition($event, rect.widgetId, rect)"
          v-on:resizing="changeSize($event, rect.widgetId, rect)"
          :class="[
            rect.name == 'data-table' || rect.name == 'well-detail'
              ? ''
              : rect.isMinimize
              ? 'displayNone'
              : '',
            rect.name == 'gauge' ? 'gauge_view' : '',
          ]"
        >
          <div
            class="filler"
            :style="{
              backgroundColor: rect.name == 'Background' ? rect.color : '',
              borderRadius:
                rect.name == 'line-graph' || rect.name == 'line-graph-tvd'
                  ? '3%'
                  : '',
              overflow:
                rect.name == 'data-table' || rect.name == 'well-detail'
                ? ''
                  : '',
            }"
          >
            <Trend
              v-if="rect.name == 'trend' && !rect.isDis && rect.isReplay==false "
              :id="rect.widgetId"
              :key="index"
              :height="rect.height - 2"
              :width="rect.width - 2"
              :top="rect.top"
              :left="rect.left"
              :displayId="rect.displayId"
              :plotConfigProps="rect.plotConfig"
              :name="rect.name"
            ></Trend>
            <!-- <Trend
              v-if="rect.name == 'replay'"
              :id="rect.widgetId"
              :key="index"
              :height="rect.height - 2"
              :width="rect.width - 2"
              :top="rect.top"
              :left="rect.left"
              :displayId="rect.displayId"
              :plotConfigProps="rect.plotConfig"
              :name="rect.name"
            ></Trend> -->
            <VTrend
              v-if="rect.name == 'vtrend'"
              :widgetId="rect.widgetId"
              :widgetIndex="index"
              :key="index"
              :height="rect.height"
              :width="rect.width"
              :top="rect.top"
              :left="rect.left"
              :displayId="rect.displayId"
              :plotConfigProps="rect.plotConfig"
            ></VTrend>
            <DisTrend
              v-if="rect.name == 'trend' && rect.isDis && rect.isReplay==false"
              :id="rect.widgetId"
              :key="index"
              :height="rect.height - 2"
              :width="rect.width - 2"
              :top="rect.top"
              :left="rect.left"
              :displayId="rect.displayId"
              :plotConfigProps="rect.plotConfig"
              :isDisProps="rect.isDis"
            ></DisTrend>
            <Trend
              v-if="rect.name == 'trend' && rect.isReplay==true"
              :id="rect.widgetId"
              :key="index"
              :height="rect.height - 2"
              :width="rect.width - 2"
              :top="rect.top"
              :left="rect.left"
              :displayId="rect.displayId"
              :plotConfigProps="rect.plotConfig"
              :name="rect.name"
              :isReplay="rect.isReplay"
            ></Trend>
            <Background
              v-if="rect.name == 'Background'"
              :id="rect.widgetId"
              :key="index"
              :displayId="rect.displayId"
              :widgetId="rect.widgetId"
              :backgroundId="rect.backgroundId"
              :cardBackground="rect.backgroundColor"
              :height="rect.height"
              :width="rect.width"
              :top="rect.top"
              :left="rect.left"
              :onMinMax="windowToggle"
              aria-label="Minimize"
              :openPropertyFn="openProperty"
              v-on:dblclick="openProperty(index, rect)"
            >
            </Background>

            <label-widget
              v-if="rect.name == 'label'"
              :id="rect.widgetId"
              :displayId="rect.displayId"
              :widgetId="rect.widgetId"
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :titleSize="rect.titleSize"
              :titleDisplay="rect.titleDisplay"
              :autoFit="rect.autoFit"
              :borderDisplay="rect.borderDisplay"
              :borderWidth="rect.borderWidth"
              :title="rect.title"
              :titleColor="rect.titleColor"
              v-bind:class="[
                isRightOpen || $store.state.rect.leftSideBarOpen
                  ? 'widge_resp_width'
                  : 'w-100',
              ]"
              v-on:dblclick="openProperty(index, rect)"
            ></label-widget>

            <numeric-widget
              v-if="rect.name == 'numeric'"
              :key="index"
              :id="rect.widgetId"
              :displayId="rect.displayId"
              :widgetId="rect.widgetId"
              :isChange="rect.isChange"
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :borderDisplay="rect.borderDisplay"
              :borderWidth="rect.borderWidth"
              :borderColor="rect.borderColor"
              :alertStartRange="rect.alertStartRange"
              :alertEndRange="rect.alertEndRange"
              :alertColo="rect.alertColor"
              :blinkAlert="rect.blinkAlert"
              :cardBackground="rect.cardBackground"
              :autoFit="rect.autoFit"
              :titleDisplay="rect.titleDisplay"
              :titleSize="rect.titleSize"
              :titleColor="rect.titleColor"
              :titleBgColor="rect.titleBgColor"
              :title="rect.title"
              :fullName="rect.fullName"
              :valueDisplay="rect.valueDisplay"
              :valueSize="rect.valueSize"
              :valueColor="rect.valueColor"
              :valueBgColor="rect.valueBgColor"
              :decimalValue="rect.decimalValue"
              :value="rect.value"
              :unitBgColor="rect.unitBgColor"
              :unitDisplay="rect.unitDisplay"
              :unitSize="rect.unitSize"
              :unitColor="rect.unitColor"
              :widgetUnitL="rect.widgetUnitL"
              :isDragStarted="isDragStarted"
              v-bind:class="[
                isRightOpen || $store.state.rect.leftSideBarOpen
                  ? 'widge_resp_width'
                  : 'w-100',
              ]"
              v-on:dblclick="openProperty(index, rect)"
              href="#pablo"
              ref="btnDropdownRef"
            ></numeric-widget>
            <KillSheetWidget
              v-if="rect.name == 'killsheet'"
              :key="index"
              :id="rect.widgetId"
              :displayId="rect.displayId"
              :widgetId="rect.widgetId"
              :isChange="rect.isChange"
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :borderDisplay="rect.borderDisplay"
              :borderWidth="rect.borderWidth"
              :borderColor="rect.borderColor"
              :alertStartRange="rect.alertStartRange"
              :alertEndRange="rect.alertEndRange"
              :alertColo="rect.alertColor"
              :blinkAlert="rect.blinkAlert"
              :cardBackground="rect.cardBackground"
              :autoFit="rect.autoFit"
              :titleDisplay="rect.titleDisplay"
              :titleSize="rect.titleSize"
              :titleColor="rect.titleColor"
              :titleBgColor="rect.titleBgColor"

              :fullName="rect.fullName"
              :valueDisplay="rect.valueDisplay"
              :valueSize="rect.valueSize"
              :valueColor="rect.valueColor"
              :valueBgColor="rect.valueBgColor"
              :decimalValue="rect.decimalValue"
              :value="rect.value"
              :unitBgColor="rect.unitBgColor"
              :unitDisplay="rect.unitDisplay"
              :unitSize="rect.unitSize"
              :unitColor="rect.unitColor"
              :widgetUnitL="rect.widgetUnitL"
              v-bind:class="[
                isRightOpen || $store.state.rect.leftSideBarOpen
                  ? 'widge_resp_width'
                  : 'w-100',
              ]"
              v-on:dblclick="openProperty(index, rect)"
              href="#pablo"
              ref="btnDropdownRef"
            ></KillSheetWidget>
            <SwabOrSurge
              v-if="rect.name == 'swaborsurge'"
              :key="index"
              :id="rect.widgetId"
              :displayId="rect.displayId"
              :widgetId="rect.widgetId"
              :isChange="rect.isChange"
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :borderDisplay="rect.borderDisplay"
              :borderWidth="rect.borderWidth"
              :borderColor="rect.borderColor"
              :alertStartRange="rect.alertStartRange"
              :alertEndRange="rect.alertEndRange"
              :alertColo="rect.alertColor"
              :blinkAlert="rect.blinkAlert"
              :cardBackground="rect.cardBackground"
              :autoFit="rect.autoFit"
              :titleDisplay="rect.titleDisplay"
              :trip_Depth="rect.trip_Depth"
              :trip_Depth_TVD="rect.trip_Depth_TVD"
              :mud_wght="rect.mud_wght"
              :hole_size="rect.hole_size"
              :dc1_od="rect.dc1_od"
              :dc1_id="rect.dc1_id"
              :dc1_length="rect.dc1_length"
              :dc2_od="rect.dc2_od"
              :dc2_id="rect.dc2_id"
              :dc2_length="rect.dc2_length"
              :hwdp_od="rect.hwdp_od"
              :hwdp_id="rect.hwdp_id"
              :hwdp_length="rect.hwdp_length"
              :dp_od="rect.dp_od"
              :dp_id="rect.dp_id"
              :dp_length="rect.dp_length"
              :selected_tripmode="rect.selected_tripmode"
              :flow_index="rect.flow_index"
              :selected_tripstatus="rect.selected_tripstatus"
              :r300="rect.r300"
              :r600="rect.r600"
              :plastic_Viscosity="rect.plastic_Viscosity"
              :Consistency_index="rect.Consistency_index"
              :rect_index="index"
              :rect_data="rect"
              :static_casing_length="rect.static_casing_length"
              :static_casing_id="rect.static_casing_id"
              :xAutoScale="rect?.xAutoScale"
              :yAutoScale="rect?.yAutoScale"
              :xRangeStart="rect?.xRangeStart"
              :xRangeEnd="rect?.xRangeEnd"
              :yRangeStart="rect?.yRangeStart"
              :yRangeEnd="rect?.yRangeEnd"
              :xAutoScalePpg="rect?.xAutoScalePpg"
              :yAutoScalePpg="rect?.yAutoScalePpg"
              :xRangeStartPpg="rect?.xRangeStartPpg"
              :xRangeEndPpg="rect?.xRangeEndPpg"
              :yRangeStartPpg="rect?.yRangeStartPpg"
              :yRangeEndPpg="rect?.yRangeEndPpg"
              @to_openProperty="openProperty"
              v-bind:class="[
                isRightOpen || $store.state.rect.leftSideBarOpen
                  ? 'widge_resp_width'
                  : 'w-100',
              ]"
              href="#pablo"
              ref="btnDropdownRef"
            ></SwabOrSurge>

            <DirectionDifficultyWidget
              v-if="rect.name == 'DirectionalDifficulty'"
              :key="index"
              :id="rect.widgetId"
              :displayId="rect.displayId"
              :widgetId="rect.widgetId"
              :isChange="rect.isChange"
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :borderDisplay="rect.borderDisplay"
              :borderWidth="rect.borderWidth"
              :borderColor="rect.borderColor"
              :alertStartRange="rect.alertStartRange"
              :alertEndRange="rect.alertEndRange"
              :alertColo="rect.alertColor"
              :blinkAlert="rect.blinkAlert"
              :cardBackground="rect.cardBackground"
              :autoFit="rect.autoFit"
              :titleDisplay="rect.titleDisplay"
              :titleSize="rect.titleSize"
              :titleColor="rect.titleColor"
              :titleBgColor="rect.titleBgColor"
              :title="rect.title"
              :fullName="rect.fullName"
              :valueDisplay="rect.valueDisplay"
              :valueSize="rect.valueSize"
              :valueColor="rect.valueColor"
              :valueBgColor="rect.valueBgColor"
              :decimalValue="rect.decimalValue"
              :value="rect.value"
              :unitBgColor="rect.unitBgColor"
              :unitDisplay="rect.unitDisplay"
              :unitSize="rect.unitSize"
              :unitColor="rect.unitColor"
              :widgetUnitL="rect.widgetUnitL"
              :tableData="rect.table"
              :tag="rect.tag"
              :getTableColumns="getTableColumns(cols)"
              :headColor="rect.headColor "
              :headBackground="rect.headBackground"
              :color="rect.gridColor "
              :background="rect.backgroundColor "
              :headFontSize="rect.headFontSize"
              :graphLabelColor="rect.graphLabelColor "
              :graphColor="rect.graphColor "
              :xAxisLabelSize="rect.xaxisLabelSize "
              :yAxisLabelSize="rect.yaxisLabelSize "
              :gridColor="rect.gridColor "
              :gridBackgroundColor="rect.gridBackgroundColor "
              :isXmanualRange="rect.xManualRange "
              :xStartRange="rect.xStartRange "
              :xEndRange="rect.xEndRange "
              :isYmanualRange="rect.yManualRange "
              :yStartRange="rect.yStartRange "
              :yEndRange="rect.yEndRange "
              v-bind:class="[
                isRightOpen || $store.state.rect.leftSideBarOpen
                  ? 'widge_resp_width'
                  : 'w-100',
              ]"
              v-on:dblclick="openProperty(index, rect)"
              href="#pablo"
              ref="btnDropdownRef"
            >
            </DirectionDifficultyWidget>
            <XYAxisWidget
              v-if="rect.name == 'XYAxis'"
              :key="index"
              :id="rect.widgetId"
              :displayId="rect.displayId"
              :widgetId="rect.widgetId"
              :isChange="rect.isChange"
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :borderDisplay="rect.borderDisplay"
              :borderWidth="rect.borderWidth"
              :borderColor="rect.borderColor"
              :alertStartRange="rect.alertStartRange"
              :alertEndRange="rect.alertEndRange"
              :alertColo="rect.alertColor"
              :blinkAlert="rect.blinkAlert"
              :cardBackground="rect.cardBackground"
              :autoFit="rect.autoFit"
              :titleDisplay="rect.titleDisplay"
              :titleSize="rect.titleSize"
              :titleColor="rect.titleColor"
              :titleBgColor="rect.titleBgColor"
              :title="rect.title"
              :fullName="rect.fullName"
              :valueDisplay="rect.valueDisplay"
              :valueSize="rect.valueSize"
              :valueColor="rect.valueColor"
              :valueBgColor="rect.valueBgColor"
              :decimalValue="rect.decimalValue"
              :value="rect.value"
              :unitBgColor="rect.unitBgColor"
              :unitDisplay="rect.unitDisplay"
              :unitSize="rect.unitSize"
              :unitColor="rect.unitColor"
              :widgetUnitL="rect.widgetUnitL"
              :isSecondaryAxis="rect.isSecondaryAxis || false"
              :gridColor="rect.gridColor"
              :gridBackground="rect.graphBackgroundColor"
              :axisLabelColor="rect.axisLabelColor"
              :graphScaleColor="rect.graphScaleColor"
              :xAxisLabelSize="rect.xAxisLabelSize"
              :yAxisLabelSize="rect.yAxisLabelSize"
              :secondaryAxisLabelColor="rect.sevondaryAxisLabelColor"
              :graphSecondaryScaleColor="rect.graphSecondaryScaleColor"
              :yAxisSecondaryLabelSize="rect.yAxisSecondaryLabelSize"
              :primaryXaxisFullName="rect.primaryXaxisFullName"
              :primaryXaxisUnit="rect.primaryXaxisUnit"
              :primaryYaxisFullName="rect.primaryYaxisFullName"
              :primaryYaxisUnit="rect.primaryYaxisUnit"
              :secondaryYaxisFullName="rect.secondaryYaxisFullName"
              :secondryYaxisUnit="rect.secondryYaxisUnit"
              v-bind:class="[
                isRightOpen || $store.state.rect.leftSideBarOpen
                  ? 'widge_resp_width'
                  : 'w-100',
              ]"
              v-on:dblclick="openProperty(index, rect)"
              href="#pablo"
              ref="btnDropdownRef"
            >
            </XYAxisWidget>
            <mud-motor-health
              v-if="rect.name == 'mud-motor'"
              :key="index"
              :id="rect.widgetId"
              :displayId="rect.displayId"
              :widgetId="rect.widgetId"
              :isChange="rect.isChange"
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :borderDisplay="rect.borderDisplay"
              :borderWidth="rect.borderWidth"
              :borderColor="rect.borderColor"
              :alertStartRange="rect.alertStartRange"
              :alertEndRange="rect.alertEndRange"
              :alertColo="rect.alertColor"
              :blinkAlert="rect.blinkAlert"
              :cardBackground="rect.cardBackground"
              :autoFit="rect.autoFit"
              :titleDisplay="rect.titleDisplay"
              :titleSize="rect.titleSize"
              :titleColor="rect.titleColor"
              :titleBgColor="rect.titleBgColor"
              :title="rect.title"
              :fullName="rect.fullName"
              :valueDisplay="rect.valueDisplay"
              :valueSize="rect.valueSize"
              :valueColor="rect.valueColor"
              :valueBgColor="rect.valueBgColor"
              :decimalValue="rect.decimalValue"
              :value="rect.value"
              :unitBgColor="rect.unitBgColor"
              :unitDisplay="rect.unitDisplay"
              :unitSize="rect.unitSize"
              :unitColor="rect.unitColor"
              :widgetUnitL="rect.widgetUnitL"
              v-bind:class="[
                isRightOpen || $store.state.rect.leftSideBarOpen
                  ? 'widge_resp_width'
                  : 'w-100',
              ]"
              v-on:dblclick="openProperty(index, rect)"
              href="#pablo"
              ref="btnDropdownRef"
            ></mud-motor-health>

            <hydraulics-calculator
              v-if="rect.name == 'hydraulic-calculator'"
              :key="index"
              :id="rect.widgetId"
              :displayId="rect.displayId"
              :widgetId="rect.widgetId"
              :isChange="rect.isChange"
              :dry_pipe_length="rect.dry_pipe_length"
              :slug_weight="rect.slug_weight"
              :slug_dry_pipe_length="rect.slug_dry_pipe_length"
              :slug_volume="rect.slug_volume"
              :annular_capacity="rect.annular_capacity"
              :annular_slug_volume="rect.annular_slug_volume"
              :washout_factor="rect.washout_factor"
              :drill_string_volume_left="rect.drill_string_volume_left"
              :stroke_required_displace_surface="
                rect.stroke_required_displace_surface
              "
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :borderDisplay="rect.borderDisplay"
              :borderWidth="rect.borderWidth"
              :borderColor="rect.borderColor"
              :alertStartRange="rect.alertStartRange"
              :height_spot_column="rect.height_spot_column"
              :alertEndRange="rect.alertEndRange"
              :alertColo="rect.alertColor"
              :blinkAlert="rect.blinkAlert"
              :cardBackground="rect.cardBackground"
              :autoFit="rect.autoFit"
              :titleDisplay="rect.titleDisplay"
              :titleSize="rect.titleSize"
              :titleColor="rect.titleColor"
              :titleBgColor="rect.titleBgColor"
              
              :fullName="rect.fullName"
              :valueDisplay="rect.valueDisplay"
              :valueSize="rect.valueSize"
              :valueColor="rect.valueColor"
              :valueBgColor="rect.valueBgColor"
              :decimalValue="rect.decimalValue"
              :value="rect.value"
              :unitBgColor="rect.unitBgColor"
              :unitDisplay="rect.unitDisplay"
              :unitSize="rect.unitSize"
              :unitColor="rect.unitColor"
              :widgetIndex="index"
              :widgetConfig="rect"
              v-on:dblclick="openProperty(index, rect)"
              href="#pablo"
              ref="btnDropdownRef"
            >
            </hydraulics-calculator>

            <vertical-bar-widget
              v-if="rect.name == 'vertical-bar'"
              :id="rect.widgetId"
              :displayId="displayId"
              :widgetId="rect.widgetId"
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :title="rect.title"
              :fullName="rect.fullName"
              :unit="rect.unit"
              :autoFit="rect.autoFit"
              :width="rect.width"
              :height="rect.height * 1.05"
              :barWidth="rect.width - 60"
              :barHeight="rect.height - rect.height * 0.3"
              :barValue="rect.barValue"
              :rangeStart="rect.rangeStart"
              :alertColor="rect.alertColor"
              :blinkAlert="rect.blinkAlert"
              :alertStartRange="rect.alertStartRange"
              :alertEndRange="rect.alertEndRange"
              :rangeEnd="rect.rangeEnd"
              :interval="rect.interval"
              :borderColor="rect.borderColor"
              :borderWidth="rect.borderWidth"
              :borderDisplay="rect.borderDisplay"
              :titleColor="rect.titleColor"
              :valueColor="rect.valueColor"
              :unitColor="rect.unitColor"
              :unitDisplay="rect.unitDisplay"
              :titleDisplay="rect.titleDisplay"
              :titleSize="rect.titleSize"
              :valueSize="rect.valueSize"
              :unitSize="rect.unitSize"
              :valueDisplay="rect.valueDisplay"
              :decimalValue="rect.decimalValue"
              :cardBackground="'black'"
              :isChanged="rect.isChanged"
              :barBreakPoints="rect.breakPoints"
              v-bind:class="[
                isRightOpen || $store.state.rect.leftSideBarOpen
                  ? 'widge_resp_width'
                  : 'w-100',
              ]"
              v-on:dblclick="openProperty(index, rect)"
            ></vertical-bar-widget>

            <image-widget
              v-if="rect.isWidget && rect.name == 'image'"
              :displayId="displayId"
              :widgetId="rect.widgetId"
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              v-bind:class="[
                isRightOpen || $store.state.rect.leftSideBarOpen
                  ? 'widge_resp_width'
                  : 'w-100',
              ]"
              v-on:dblclick="openProperty(index, rect)"
            ></image-widget>

            <rosebud-widget
              v-if="rect.name == 'directional'"
              :log_type="rect.log_type"
              :directionalID="index"
              :displayId="displayId"
              :widgetId="rect.widgetId"
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :textColor="rect.textColor"
              :backgroundColor="rect.backgroundColor"
              :rangeStart="rect.rangeStart"
              :rangeEnd="rect.rangeEnd"
              :radarStart="rect.radarStart"
              :radarEnd="rect.radarEnd"
              :showGrid="rect.showGrid"
              :logTypes="rect.logTypes"
              :isRadarVisible="rect.isRadarVisible"
              :numberOfDataPoints="rect.numberOfDataPoints"
              v-bind:class="[
                isRightOpen || $store.state.rect.leftSideBarOpen
                  ? 'widge_resp_width'
                  : 'w-100',
              ]"
              v-on:dblclick="openProperty(index, rect)"
            ></rosebud-widget>

            <gauge-widget
              v-if="rect.name == 'gauge'"
              :id="rect.widgetId"
              :displayId="displayId"
              :autoFit="rect.autoFit"
              :widgetId="rect.widgetId"
              :widgetHeight="rect.width"
              :widgetWidth="rect.height"
              :fontSize="rect.width * 0.08"
              :title="rect.title"
              :fullName="rect.fullName"
              :unit="rect.unit"
              :squareDisplay="rect.squareDisplay"
              :titleDisplay="rect.titleDisplay"
              :gaugeStartAngle="rect.startAngle"
              :gaugeEndAngle="rect.endAngle"
              :alertStartRange="rect.alertStartRange"
              :alertEndRange="rect.alertEndRange"
              :rangeStart="rect.rangeStart"
              :rangeEnd="rect.rangeEnd"
              :bgColor="rect.bgColor"
              :saveColor="rect.saveColor"
              :valueDecimals="rect.valueDecimals"
              :interval="rect.interval"
              :niddleAngle="rect.niddleAngle"
              :saveValueColor="rect.saveValueColor"
              :saveTitleColor="rect.saveTitleColor"
              :saveNeedleColor="rect.saveNeedleColor"
              :labelFontPercent="rect.labelFontPercent"
              :alertColor="rect.alertColor"
              :blinkAlert="rect.blinkAlert"
              :borderDisplay="rect.borderDisplay"
              :gaugeBreakPoints="rect.breakPoints"
              :isChanged="rect.isChanged"
              :arcCount="rect.arcCount"
              v-bind:class="[
                isRightOpen || $store.state.rect.leftSideBarOpen
                  ? 'widge_resp_width'
                  : 'w-100',
              ]"
              v-on:dblclick="openProperty(index, rect)"
            ></gauge-widget>

            <DataTable
              v-if="rect.name == 'data-table'"
              :id="rect.widgetId"
              :displayId="displayId"
              :widgetId="rect.widgetId"
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :headColor="rect.headColor"
              :headBackground="rect.headBackground"
              :color="rect.gridColor"
              :background="rect.backgroundColor"
              :headFontSize="rect.headFontSize"
              :alertColor="rect.alertColor"
              :blinkAlert="rect.blinkAlert"
              :tableData="rect.table"
              :tag="rect.tag"
              :getTableColumns="getTableColumns(cols)"
              v-on:dblclick="openProperty(index, rect)"
            ></DataTable>

            <WellDetail
              v-if="rect.name == 'well-detail'"
              :id="rect.widgetId"
              :displayId="displayId"
              :widgetId="rect.widgetId"
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :headColor="rect.headColor || '#cccccc'"
              :headBackground="rect.headBackground || '#0d0d0d'"
              :color="rect.gridColor || '#fff'"
              :background="rect.backgroundColor || '#28282B'"
              :headFontSize="rect.headFontSize"
              :alertColor="rect.alertColor"
              :blinkAlert="rect.blinkAlert"
              :tableData="rect.table"
              :tag="rect.tag"
              :getTableColumns="getTableColumns(cols)"
            ></WellDetail>

            <plotly-graph
              v-if="rect.name == 'plotly-graph'"
              :displayId="displayId"
              :widgetId="rect.widgetId"
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :gridColor="rect.gridColor"
              :backgroundColor="rect.backgroundColor"
              v-on:dblclick="openProperty(index, rect)"
              :mnemonicTag1="rect.mnemonicTag1"
              :mnemonicTag2="rect.mnemonicTag2"
              :mnemonicTag3="rect.mnemonicTag3"
              :mnemonicTag4="rect.mnemonicTag4"
            ></plotly-graph>

            <PlanVsActual
              v-if="rect.name == 'plan-vs-actual'"
              :displayId="displayId"
              :widgetId="rect.widgetId"
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :gridColor="rect.gridColor"
              :backgroundColor="rect.backgroundColor"
              v-on:dblclick="openProperty(index, rect)"
              :mnemonicTag1="rect.mnemonicTag1"
              :mnemonicTag2="rect.mnemonicTag2"
              :mnemonicTag3="rect.mnemonicTag3"
              :mnemonicTag4="rect.mnemonicTag4"
              :xRangeStart="rect.xRangeStart"
              :xRangeEnd="rect.xRangeEnd"
              :zRangeStart="rect.zRangeStart"
              :zRangeEnd="rect.zRangeEnd"
              :yRangeStart="rect.yRangeStart"
              :yRangeEnd="rect.yRangeEnd"
              :xAutoScale="rect.xAutoScale"
              :yAutoScale="rect.yAutoScale"
              :zAutoScale="rect.zAutoScale"
            ></PlanVsActual>

            <TvdSection
              v-if="rect.name == 'line-graph'"
              :displayId="displayId"
              :widgetId="rect.widgetId"
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :xAxisLabel="rect.xAxisLabel"
              :yAxisLabel="rect.yAxisLabel"
              :gridColor="rect.gridColor"
              :gridBackground="rect.gridBackground"
              :xAxisLabelSize="rect.xAxisLabelSize"
              :yAxisLabelSize="rect.yAxisLabelSize"
              :isSwitched="rect.isSwitched"
              :actualColor="rect.actualColor"
              :planColor="rect.planColor"
              :backgroundColor="rect.backgroundColor"
              v-on:dblclick="openProperty(index, rect)"
              :xRangeStart="rect.xRangeStart"
              :xRangeEnd="rect.xRangeEnd"
              :yRangeStart="rect.yRangeStart"
              :yRangeEnd="rect.yRangeEnd"
              :xAutoScale="rect.xAutoScale"
              :yAutoScale="rect.yAutoScale"
            ></TvdSection>
            <VerticalSection
              v-if="rect.name == 'line-graph-tvd'"
              :displayId="displayId"
              :widgetId="rect.widgetId"
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :xAxisLabel="rect.xAxisLabel"
              :yAxisLabel="rect.yAxisLabel"
              :gridColor="rect.gridColor"
              :gridBackground="rect.gridBackground"
              :xAxisLabelSize="rect.xAxisLabelSize"
              :yAxisLabelSize="rect.yAxisLabelSize"
              :isSwitched="rect.isSwitched"
              :actualColor="rect.actualColor"
              :planColor="rect.planColor"
              :backgroundColor="rect.backgroundColor"
              v-on:dblclick="openProperty(index, rect)"
              :xRangeStart="rect.xRangeStart"
              :xRangeEnd="rect.xRangeEnd"
              :yRangeStart="rect.yRangeStart"
              :yRangeEnd="rect.yRangeEnd"
              :xAutoScale="rect.xAutoScale"
              :yAutoScale="rect.yAutoScale"
            ></VerticalSection>

            <RigChart
              v-if="rect.name == 'rig-chart'"
              :displayId="displayId"
              :widgetId="rect.widgetId"
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :xAxisLabel="rect.xAxisLabel"
              :yAxisLabel="rect.yAxisLabel"
              :gridColor="rect.gridColor"
              :gridBackground="rect.gridBackground"
              :xAxisLabelSize="rect.xAxisLabelSize"
              :yAxisLabelSize="rect.yAxisLabelSize"
              :isSwitched="rect.isSwitched"
              :actualColor="rect.actualColor"
              :planColor="rect.planColor"
              :backgroundColor="rect.backgroundColor"
              v-on:dblclick="openProperty(index, rect)"
              :xRangeStart="rect.xRangeStart"
              :xRangeEnd="rect.xRangeEnd"
              :yRangeStart="rect.yRangeStart"
              :yRangeEnd="rect.yRangeEnd"
              :xAutoScale="rect.xAutoScale"
              :yAutoScale="rect.yAutoScale"
            ></RigChart>

            <BroomStickPlot
              v-if="rect.name == 'broom-stick-plot'"
              :displayId="displayId"
              :widgetId="rect.widgetId"
              :widgetHeight="rect.height"
              :widgetWidth="rect.width"
              :xAxisLabel="rect.xAxisLabel"
              :yAxisLabel="rect.yAxisLabel"
              :gridColor="rect.gridColor"
              :gridBackground="rect.gridBackground"
              :xAxisLabelSize="rect.xAxisLabelSize"
              :yAxisLabelSize="rect.yAxisLabelSize"
              :isSwitched="rect.isSwitched"
              :actualColor="rect.actualColor"
              :planColor="rect.planColor"
              :backgroundColor="rect.backgroundColor"
              :bhaStartTime="rect.bhaStartTime"
              :bhaEndTime="rect.bhaEndTime"
              :isReplay="rect.isReplay"
              v-on:dblclick="openProperty(index, rect)"
            >
            </BroomStickPlot>
            <WellCorrelation
              v-if="rect.name == 'correlation-plot'"
              :displayIndex="displayIndex"
              :widgetId="rect.widgetId"
              :key="index"
              :widgetIndex="index"
              :height="rect.height - 15"
              :width="rect.width"
              :top="rect.top"
              :left="rect.left"
              :displayId="rect.displayId"
              :plotConfig="rect.plotConfig"
              :parentW="width"
              :parentH="height"
            >
            </WellCorrelation>
            <Lwd
              v-if="rect.name == 'LWD'"
              :displayIndex="displayIndex"
              :widgetId="rect.widgetId"
              :key="index"
              :widgetIndex="index"
              :height="rect.height - 15"
              :width="rect.width"
              :top="rect.top"
              :left="rect.left"
              :displayId="rect.displayId"
              :plotConfig="rect.plotConfig"
              :parentW="width"
              :parentH="height"
            >
            </Lwd>

            <alert-screen
              v-if="rect.name == 'alert-screen'"
              :selectedWellBore="selectedWellBore"
              :selectedWell="selectedWell"
              :displayId="rect.displayId"
              @alertWidgetClicked="alertWidgetClicked(index, rect)"
            ></alert-screen>
          </div>
        </VueDragResize>
      </div>
    </div>
  </div>
</template>

<script>
var dragX = null;
var dragY = null;
var gx = null;
var gy = null;
var elProps = null;

var test = function (a, b) {
  gx = a;
  gy = b;
};
var test1 = function (e) {
  (dragX = e.pageX)((dragY = e.pageY));
  let obj = document.getElementById(e.target.id);

  if (obj.offsetParent) {
    do {
      dragX = dragX - obj.offsetLeft;

      dragY = dragY - obj.offsetTop;
    } while ((obj = obj.offsetParent));
  }
  dragY = dragY - offsetY;
  dragX = dragX - offsetX;

  test(dragX, dragY);
};
var elId = null;
var elDisId = null;
var compId = null;
var targetId = null;

document.addEventListener(
  "dragenter",
  function (event) {
    targetId = event.target.id;
    //

    // highlight potential drop target when the draggable element enters it
    if (event.target.id == compId) {
      //
    }
  },
  false
);
document.addEventListener(
  "dragend",
  function (event) {
    targetId = event.target.id;
    //
  },
  false
);

import VueDragResize from "../components/DragAndDrop/vue-drag-resize.vue";
import PropertyFileBar from "../components/Navbars/PropertyFileBar.vue";
import VerticalBarProperty from "../components/Navbars/VerticalBarProperty";
import DirectionalProperties from "../components/Navbars/DirectionalProperties";
import DataTableProperties from "../components/Navbars/DataTableProperties";
import ImageProperties from "../components/Navbars/ImageBar.vue";
import AdminLayout from "../components/Navbars/AdminNavbar.vue";
import AdminSubNavbar from "../components/Navbars/AdminSubNavbar.vue";
import { ref } from "vue";
import NumericWidget from "../components/Widgets/Numeric/NumericWidget.vue";
import MudMotorHealth from "../components/Widgets/MudMotorHealth/MudMotorHealth.vue";
import HydraulicsCalculator from "./Widgets/HydraulicsCalculator/HydraulicsCalculator.vue";
import ImageWidget from "../components/Widgets/Image/ImageWidget.vue";
import LabelWidget from "../components/Widgets/Label/LabelWidget.vue";
import RosebudWidget from "../components/Widgets/Rosebud/Rosebud.vue";
import GaugeWidget from "../components/Widgets/Gauge/GaugeWidget.vue";
import VerticalBarWidget from "../components/Widgets/VerticalBar/VerticalBarWidget";
import DataTable from "../components/Widgets/DataTable/DataTableWidget.vue";
import WellDetail from "../components/Widgets/WellInfo/DataTableWidget.vue";
import LabelPropertiesBar from "../components/Navbars/LabelPropertiesBar.vue";
import CircularPropertyFile from "../components/Navbars/CircularPropertiesBar.vue";
import PlotlyGraph from "./Widgets/Babylon3dGraph/PlotlyGraph.vue";
import PlanVsActual from "./Widgets/PlanVsActual/PlanVsActual.vue";
import TvdSection from "./Widgets/TvdSection/TvdSection.vue";
import VerticalSection from "./Widgets/VerticalSection/VerticalSection.vue";
import BroomStickPlot from "./Widgets/BroomStickPlot/BroomStickPlot.vue";
import KillSheetWidget from "./Widgets/Killsheet/KillSheetWidget.vue";
import PlotlyGraphProperties from "../components/Navbars/PlotlyGraphProperties.vue";
import Sidebar from "../components/Sidebar/Sidebar.vue";
import Background from "../components/Widgets/Background/Background.vue";
import BackgroundProp from "../components/Navbars/Backgroundproperty.vue";
import Trend from "../components/Widgets/Trend/Trends.vue";
import VTrend from "../components/Widgets/TrendV/Trend.vue";
import DisTrend from "../components/Widgets/DIS/DisTrend.vue";
import SwabOrSurge from "./Widgets/SwabOrSurge/SwabOrSurge.vue";
import DirectionDifficultyWidget from "./Widgets/DDI/DirectionalDifficultyIndex.vue";
import XYAxisWidget from "./Widgets/XYAxis/xyaxis.vue";

import RangeSlider from "./Navbars/RangeSlider.vue";
import WellCorrelation from "../components/Widgets/WellCorrelation/CorrelationPlot.vue";
import RigChart from "../components/Widgets/RigPieChart/RigChart.vue";
import AlertScreen from "./Widgets/Alerts/AlertScreen.vue";
import Lwd from "./Widgets/Lwd/LwdMain.vue"
export default {
  name: "Maindash",
  props: {
    displayIndex: Number,
    height: Number,
    width: Number,
    displayId: String,
    isMinimize: {
      type: Boolean,
      default: false,
    },
    isScreenMaximized: Boolean,
  },
  components: {
    KillSheetWidget,
    MudMotorHealth,
    PlanVsActual,
    NumericWidget,
    VueDragResize,
    PropertyFileBar,
    VerticalBarProperty,
    ImageProperties,
    DirectionalProperties,
    AdminLayout,
    AdminSubNavbar,
    ImageWidget,
    LabelWidget,
    RosebudWidget,
    GaugeWidget,
    DataTable,
    VerticalBarWidget,
    LabelPropertiesBar,
    CircularPropertyFile,
    Sidebar,
    DataTableProperties,
    PlotlyGraph,
    PlotlyGraphProperties,
    Background,
    BackgroundProp,
    Trend,
    DisTrend,
    RangeSlider,
    TvdSection,
    VerticalSection,
    BroomStickPlot,
    WellDetail,
    WellCorrelation,
    RigChart,
    VTrend,
    HydraulicsCalculator,
    AlertScreen,
    SwabOrSurge,
    DirectionDifficultyWidget: DirectionDifficultyWidget,
    XYAxisWidget,
    Lwd
  },

  data() {
    return {
      isDragStarted: false,
      classes: "",
      listWidth: 0,
      listHeight: 0,
      toggle_right_Sidebar: "",
      toggle_right_margin: "",
      show: true,
      propertyIndex: -1,
      selectedWidgetConfig: {},
      isRightSideBar: false,
      activatedIndex: -1,
      navbarOpen: false,
      isOpen: false,
      isRightOpen: false,
      childId: null,
      compId: null,
      entered: false,
      set_height: true,
      set_width: true,
    };
  },
  setup() {
    const childComponentRef = ref();

    const test = ref("Maindash Menu");
    return {
      test,
      childComponentRef,
    };
  },
  beforeMount() {
    this.compId = "id" + Math.random().toString(16).slice(2);
    // this.childId = id;

    // //
    // // elId = id;

    // // elDisId = this.displayId;
    // // compId = elId + elDisId;
    // this.compId = id;
    //
  },
  mounted() {
    try {
      let listEl = document.getElementById(this.dashId);
      this.listWidth = listEl.clientWidth;
      this.listHeight = listEl.clientHeight;
      console.log("rects ", this.rects);
      window.addEventListener("keypress", (e) => {
        //
        // if (e.key == "Delete" && this.activatedIndex > -1) {
        //   this.$store.dispatch("disp/deleteWidget", { id: this.activatedIndex });
        //   this.activatedIndex = -1
        // }
      });
      window.addEventListener("resize", () => {
        this.listWidth = listEl.clientWidth;
        this.listHeight = listEl.clientHeight;
      });
    } catch (error) {
      //
    }
    let listEl = document.getElementById(this.compId);
    this.listWidth = listEl.clientWidth;
    this.listHeight = listEl.clientHeight;

    window.addEventListener("resize", () => {
      this.listWidth = listEl.clientWidth;
      this.listHeight = listEl.clientHeight;
    });
  },
  watch: {
    rects() {
      let allRects = this.$store.state.rect.rects;
      return allRects.filter(this.noDisplays);
    },
    isScreenMaximized(val){
      let allRects = this.$store.state.rect.rects;
      allRects.map((each)=>{
        if(each?.name == 'LWD'){
          let payload = {};
          payload.widgetId = each.widgetId;
          payload.left = each.left;
          payload.top = each.top;
          payload.type = "widget";
          if(val && each.displayId == this.displayId && Object.keys(this.$store.state.data).includes('main_nav_show')
            && !this.$store.state.data.main_nav_show)
          {
            payload.height = window?.innerHeight - 55;
            payload.width = window?.innerWidth - 20;
          }
          else{
            payload.height = window?.innerHeight - 145;
            payload.width = window?.innerWidth - 20;
          }
          this.$store.dispatch("rect/setDimensions", payload);
        }
      })
    }
  },

  computed: {
    rects() {
      let allRects = this.$store.state.rect.rects;
      allRects.forEach((each)=>{
        if(each?.name == 'LWD'){
          if(each.displayId == this.displayId && Object.keys(this.$store.state.data).includes('main_nav_show')
            && !this.$store.state.data.main_nav_show){
        //     each.height = window?.innerHeight - 55
        //     each.width = window?.innerWidth - 20
            each.resizable = false;
          }
          else{
            // if(!this.isScreenMaximized){
              each.height = window?.innerHeight - 145
              each.width = window?.innerWidth - 20
              each.resizable = true;
            // }
          }
        }
        return each
      })
      return allRects.filter(this.noDisplays);
    },
    whStyles() {
      return {
        height: `${this.height}px`,
        width: `${this.width}px`,
      };
    },
  },

  methods: {
    // changeFontSizeNumaric(value) {
    //   this.isDragStarted = value;
    //   console.log("form maindash", value);
    // },
    alertWidgetClicked(index, rect) {
      console.log("checking for added parameters", index, rect);
      this.openProperty(index, rect);
    },
    getTableColumns(numOfCols) {},

    deleteWidget() {
      let menu = document.querySelector("#menu" + this.displayId);
      menu.style.visibility = "";
      this.$store.dispatch("disp/deleteDisplay", {
        displayId: this.displayId,
      });
      this.closePropertyFile();
    },

    resizable() {
      this.toggleResizable();
    },

    change() {
      this.toggleDraggable();
    },

    windowToggle(name) {
      this.classes = name;
    },

    noDisplays(rect) {
      return (
        rect.name != "display" &&
        rect.displayId == this.displayId &&
        rect.bgId == null
      );
    },

    startDrag(ev) {
      const rect = ev.target.getBoundingClientRect();
      let offsetX = ev.clientX - rect.x;
      let offsetY = ev.clientY - rect.y;
    },

    cravings: function () {
      if (this.$store.state.rect.rects) {
        return widge_resp_width;
      }
      if ((isRightOpen = true)) {
        return isRightOpenWidth;
      }
    },

    onDragEnter(e) {
      e.preventDefault();
      this.entered = true;
    },

    onDrop(event) {
      let ondropTargetId = event.target.id;
      if (this.compId === ondropTargetId) {
        let obj1 = document.getElementById(compId);

        elProps = JSON.parse(event.dataTransfer.getData("elProps"));
        let id = "id" + Math.random().toString(16).slice(2);

        this.wId = id;
        if (elProps.name != "display") {
          elProps.widgetId = id;
        }
        if (elProps.name == "Background") {
          elProps.backgroundId = "id" + Math.random().toString(16).slice(2);
        }

        elProps.displayId = this.displayId;
        let displayDtls = this.$store.state.disp.displays[this.displayId];

        let isDraggable = false;

        for (let i = 0; i < this.$store.state.disp.rects.length; i++) {
          if (this.displayId == this.$store.state.disp.rects[i].displayId) {
            isDraggable = this.$store.state.disp.rects[i].isDraggableWidget
              ? true
              : false;
          }
        }
        elProps.draggable = isDraggable;
        const offsetY = elProps.offsetY;
        const offsetX = elProps.offsetX;
        if (elProps.name == "trend") {
          console.log(
            "needed trend height: ",
            event.target.offsetWidth,
            event.target.offsetHeight
          );
          elProps.top = 5;
          elProps.left = 5;
          elProps.height = event.target.offsetHeight - 50;
          elProps.width = event.target.offsetWidth - 15;
        }
        if (elProps.name == "replay") {
          elProps.width = this.width - 50;
          elProps.height = this.height * 0.95;
        }
        if (elProps.name == "Dis") {
          elProps.width = this.width - 50;
          elProps.height = this.height * 0.95;
        }
        (dragX = event.pageX), (dragY = event.pageY);

        let obj = document.getElementById(event.target.id);

        if (obj.offsetParent) {
          do {
            dragX = dragX - obj.offsetLeft;

            dragY = dragY - obj.offsetTop;
          } while ((obj = obj.offsetParent));
        }
        dragY = dragY - offsetY;
        dragX = dragX - offsetX;

        test(dragX, dragY);

        setTimeout(this.test3, 500);

        if (elProps.widgets) {
          // alert(elProps.widgets.length);

          for (let widget of elProps.widgets) {
            let bgWiId = "bg" + Math.random().toString(16).slice(2);
            setTimeout(
              this.addSavedWidgets(
                widget,
                0,
                0,
                this.displayId,
                bgWiId,
                elProps.backgroundId
              ),
              500
            );
          }
        }
      } else {
      }
    },

    addSavedWidgets(widget, gx, gy, displayId, widgetId, backgroundId) {
      widget.displayId = displayId;
      if (widgetId) widget.widgetId = widgetId;
      if (backgroundId) {
        widget.bgId = backgroundId;
      }

      this.$store.dispatch("rect/addNewRect", {
        x: widget.left,
        y: widget.top,
        elProps: widget,
      });
    },

    test3() {
      this.$store.dispatch("rect/addNewRect", {
        x: gx,
        y: gy,
        elProps: elProps,
      });
    },

    activateEv(index) {
      //
      this.activatedIndex = index;
      this.$store.dispatch("rect/setActive", { id: index, type: "widget" });
    },

    deactivateEv(index) {
      //
      this.$store.dispatch("rect/unsetActive", { id: index, type: "widget" });
    },

    changePosition(newRect, index, rect) {
      let payload = {};

      payload.widgetId = index;
      payload.height = newRect.height;
      payload.width = newRect.width;
      payload.left = newRect.left;
      payload.top = newRect.top;
      payload.type = "widget";
      if (rect?.name == "swaborsurge") {
        if (newRect.left >= 0) {
          payload.left = newRect.left;
        } else {
          rect.left = 0;
          payload.left = 0;
          let ele = document.getElementById(rect.widgetId);
          ele.getAttribute("class").includes("vdr") ? (ele.style.left = 0) : "";
        }
        if (newRect.top >= 0) {
          payload.top = newRect.top;
        } else {
          rect.top = 0;
          payload.top = 0;
          let ele = document.getElementById(rect.widgetId);
          ele.getAttribute("class").includes("vdr") ? (ele.style.top = 0) : "";
        }
      }
      else if(rect?.name == 'LWD' && this.isScreenMaximized){
        return;
      }
      this.$store.dispatch("rect/setDimensions", payload);
    },

    changeSize(newRect, index, rect) {
      this.changePosition(newRect, index, rect);
    },

    changeProperties(newRect, index) {
      this.changePosition(newRect, index);
    },

    openProperty(index, config) {
      if (
        this.$store.state.rect.selectedWidgetProps &&
        this.$store.state.rect.selectedWidgetProps.widgetId
      ) {
        if (
          config.widgetId == this.$store.state.rect.selectedWidgetProps.widgetId
        ) {
          this.$store.dispatch("disp/togglrRightSideBarWidth", { id: -1 });
        } else {
        }
      } else {
        this.$store.dispatch("disp/togglrRightSideBarWidth", { id: -1 });
      }
      this.$store.dispatch("rect/toggleRightSideBar", {
        id: index,
        value: config,
      });
    },

    closeRightNavigation(isClose) {
      this.isRightSideBar = false;
      this.toggle_right_Sidebar = "-16";
      this.toggle_right_margin = "0";
      this.isRightOpen = false;
    },

    setNavbarOpen: function () {
      this.navbarOpen = !this.navbarOpen;
      this.isRightOpen = !this.isRightOpen;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },

    dark() {
      document.querySelector("nav").classList.add("dark-mode");
      document.querySelector("div").classList.add("dark-mode");
      document.querySelector("button").classList.add("dark-mode");
      document.querySelector("a").classList.add("text-red-600");
      this.darkMode = true;
      this.$emit("dark");
    },

    light() {
      document.querySelector("nav").classList.remove("dark-mode");
      document.querySelector("div").classList.remove("dark-mode");
      document.querySelector("button").classList.remove("dark-mode");
      document.querySelector("a").classList.remove("text-red-600");
      this.darkMode = false;
      this.$emit("light");
    },
    setting_width(rect) {
      if (rect?.name == "swaborsurge" && this.set_width) {
        this.set_width = false;
        rect.width = window?.innerWidth - 20;
        rect.left = 5;
        return window?.innerWidth - 20;
      } else {
        return rect.width;
      }
    },
    setting_height(rect) {
      if (rect?.name == "swaborsurge" && this.set_height) {
        this.set_height = false;
        rect.height = window?.innerHeight - 127;
        return window?.innerHeight - 127;
      } else {
        return rect.height;
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

:root {
  --text: #ffffff;
  --background: #1d1d23;
}

body {
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  background: #04070e;
  -webkit-font-smoothing: subpixel-antialiased;
  text-rendering: geometricPrecision;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: subpixel-antialiased;
  backface-visibility: hidden;
}

.drag-el {
  background-color: #3498db;
  color: white;
  padding: 5px;
  margin-bottom: 10px;
  width: 100px;
  height: 100px;
}

#app {
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  /* position: relative; */
  /* font-family: "Open Sans", sans-serif; */
  /* font-family: 'Lato', sans-serif; */
  /* "Lato", sans-serif; */
  background: #0e1016;
  overflow-x: hidden;
}

.filler {
  width: 100%;
  height: 100%;
  display: inline-block;
  /* position: absolute; */
}

.list {
  /* position: relative; */
  top: 0;
  bottom: 5px;
  /* width: 100%; */
  /* left: 5px; */
  /* right: 5px; */
  /* box-shadow: 0 0 2px #aaa; */
  /* background-color: black; */
  background: #1b1a1f;
  transition:var(--transition)
}

/* .box-shaddow {
  box-shadow: 10px 10px 15px 0px rgba(125, 125, 125, 1);
} */
.resize {
  resize: both;
  background-color: blue;
  overflow: hidden;
}

/* width */
::-webkit-scrollbar {
  height: 3px;
  width: 3px;
  /* display: none; */
}

.top-16 {
  top: 3.9rem;
}

.left_bg {
  width: 9%;
  background: #1b1a1f;
}

.graph_down {
  right: 500px !important;
  position: absolute !important;
}

.resp_width {
  width: 100vw !important;
  /* width: 80% !important; */
  /* margin-left: auto !important;
  margin-right: auto !important; */
}

/* .widge_resp_width {
  width: 120px !important;
  right: 0 !important;
} */
/* .isRightOpenWidth {
  width: 500px !important;
} */
.graph_down {
  right: 330px !important;
  position: absolute;
}

/* .resp_width {
  width: 95% !important;
  margin-left: auto;
  margin-right: auto;
} */
.over_bg {
  background: #727174;
}

/* .minimize {
  width: 0px;
  height: 0px;
  animation-name: animate-minimize;
  animation-duration: 2s;
  border: solid 1px;
} */

.maximize {
  /* width: 400px;
  height: 400px; */
  height: 20px !important;
  position: fixed;
  bottom: 0;
  animation-name: animate-maximize;
  animation-duration: 2s;
  border: solid 1px;
  width: 250px !important;
}

.container_ {
  height: 100%;
  width: 100%;
  background: #1b1a1f;
  /* margin: 0 0 20px;
  position: relative;

  background: azure;

  box-shadow: 0px 6px 20px #001;
  display: flex;
  justify-content: center;
  align-items: center; */
}

/* // Context Menu */
/* .alert{
  visibility: hidden;
} */
.menu {
  height: 200px;
  width: 140px;

  visibility: hidden;

  position: absolute;
  z-index: 9999;

  /* border: 1px solid #04070e; */
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.item {
  flex: 1;
  width: 100%;
  font-weight: 100;
  /* background: #04070e; */
  font-size: 8px;
  color: white;
  letter-spacing: 1px;
  /* color: #5aba81; */

  /* border-bottom: 1px solid lightgrey; */
  box-sizing: border-box;
  padding: 5px 10px;

  display: flex;
  align-items: center;

  transition: background 0.2s, color 0.1s;

  /* &:hover {
		cursor: pointer;
		background: slateblue;
		color: white;
		transition: background .2s, color .1s;
	} */
}

.context_img {
  width: 15px;
  margin-right: 8px;
}

.delete {
  /* color: red !important; */
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  color: white !important;
  font-weight: 700;
  /* background: red !important; */
}

.delete:hover {
  color: white !important;
  /* background: red !important; */
}

.checkbox {
  position: relative;
  right: 9px;
}

.label_heading {
  /* color: #5aba81 !important; */
  font-size: 8px !important;
  letter-spacing: 0.5px;
}

.label_heading:hover {
  /* color: white !important; */
}

[type="checkbox"]:checked,
[type="radio"]:checked {
  border-color: transparent;
  content: "";
  background-color: unset !important;
}

[type="checkbox"],
[type="radio"] {
  content: "";
  /* background-color: #28282f !important; */
  background-color: unset !important;
  margin-left: auto;
}

[type="checkbox"]:checked {
  background-image: url("../assets/img/check_ico.svg") !important;
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
}

.displayNone {
  display: none;
}

.dynamic {
  z-index: 999999;
}

.right_bg {
  z-index: 99999;
}

.lables_with_ico {
  display: flex;
}

.lables_with_ico > img {
  margin-right: 10px;
  background: #000000;
  padding: 3px 3px;
  border-radius: 100%;
  object-fit: cover;
}

.mneminics_mt {
  margin-top: 0px;
}

[type="checkbox"] {
  border: 1px solid gray;
}

[type="checkbox"]:checked,
[type="radio"]:checked {
  border: 1px solid var(--activeTextColor) !important;
}

[type="checkbox"]:focus,
[type="radio"]:focus {
  outline-offset: none;
  --tw-ring-offset-color: none;
  --tw-ring-color: none;
}

/* .text-xs{
  font-size: 10px !important;
} */
.left_close_btn {
  padding: 0 1px;
  display: flex;
  height: 100%;
  align-self: center;
}

.righ_side_head_ {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 6px;
  width: 100%;
}

.hide_show_btn {
  top: 0.1rem;
}

.modal_width {
  width: 22%;
}

.modal_body {
  font-size: 10px;
  padding: 0 15px 18px 15px;
}

.label_ {
  font-size: 8px;
}

.font-color {
  /* color: white; */
  /* font-size: 0.95rem; */
  font-size: 10px;
  padding: 0px 8px;
  height: 20px;
  width: 53%;
  /* height:100%; */
  /* text-align: center; */
}

input[type="color"] {
  -webkit-appearance: none;
  padding: 0;
  border: none;
  border-radius: 10px;
  width: 20px;
  height: 20px;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
  border-radius: 10px;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 10px;
}

input[type="range"] {
  -webkit-appearance: auto;
}

/* tooltips */
.menu_icons_bar {
  cursor: pointer;
  position: relative;
  text-align: center;
  -webkit-transform: translateZ(0);
  -webkit-font-smoothing: antialiased;
  z-index: 999;
}

.menu_icons_bar .tooltip {
  /* background: #1496bb; */
  text-transform: capitalize;
  bottom: 100%;
  color: #fff;
  display: block;
  left: -20px;
  padding: 0 5px;
  margin-bottom: 15px;
  opacity: 0;
  bottom: -2rem;
  pointer-events: none;
  position: absolute;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -ms-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
}

.menu_icons_bar .tooltip:before {
  bottom: -20px;
  content: " ";
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  width: 100%;
}

/* .menu_icons_bar .tooltip:after {
border-left: solid transparent 10px;
border-right: solid transparent 10px;
border-top: solid #1496bb 10px;
bottom: -10px;
content: " ";
height: 0;
left: 50%;
margin-left: -13px;
position: absolute;
width: 0;
} */

.gauge_view {
  border-radius: 750px;
}

.menu_icons_bar:hover .tooltip {
  opacity: 1;
  /* pointer-events: auto; */
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  -ms-transform: translateY(0px);
  -o-transform: translateY(0px);
  transform: translateY(0px);
  font-size: 8.5px;
}

.lte8 .menu_icons_bar .tooltip {
  display: none;
}

.lte8 .menu_icons_bar:hover .tooltip {
  display: block;
}

.left_bg__ {
  background: #eeeeee !important;
  color: var(--textColor) !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #eeeeee),
    var(--tw-ring-shadow, 0 0 #eeeeee), var(--tw-shadow) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #eeeeee),
    var(--tw-ring-shadow, 0 0 #eeeeee), var(--tw-shadow) !important;
}

.dark-mode {
  background: #1b1a1f;
  color: white !important;
  transition-delay: 150ms;
}

.well_drop_icon {
  float: right;
  margin-right: 10px;
  position: relative;
  top: -2px;
}
</style>
