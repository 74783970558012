import { ref, reactive } from "vue";
import apiService from "../api/services";
import store from '../store/index';
import moment from "moment-timezone";

const isAuto = ref(true);
let completeData = reactive({});
let mnemonic_groups = ref([]);
let wellInfo = ref(null);
let tempObj = reactive(null);
const actaualReplayVal = ref({});
const replayDetails = ref(null);
const counter = ref(0);
let minMax = ref(null);
let timeInterval = ref(null);
let startTDate = ref(0);
let endDate = ref(0);
export function useReplay() {
  function setAutoPlay(val) {
    isAuto.value = val;
    if (val && !timeInterval.value) {
      // startTDate.value += 14400000;
      if (startTDate.value < minMax.value.max) {
        fetchDetailsFromDb(true);
      } else {
        // console.log('Max time limite');
        isAuto.value = !val;
      }

    }
    if (!val && timeInterval.value) {
      timeInterval.value = clearInterval(timeInterval.value);
      if (replayDetails.value && replayDetails.value.time && replayDetails.value.time.length > 0) {
        startTDate.value = replayDetails.value.time[replayDetails.value.time.length - 1];
        // console.log('setAutoPlay stop');
      }
    }
  }
  async function setData(wellDetails, menomenics) {
    mnemonic_groups.value = menomenics;
    wellInfo.value = wellDetails;
    // console.log("details", wellDetails, menomenics);
    minMax.value = await getMinMaxRange(wellDetails.logType, wellDetails.wellId, wellDetails.wellboreId);
    // console.log('MinMax', minMax.value);
    isAuto.value = true;
    await fetchDetailsFromDb();
  }
  async function fetchDetailsFromDb(auto = false) {
    store.dispatch("data/startLoading");
    if (timeInterval.value) {
      timeInterval.value = clearInterval(timeInterval.value);
    }
    const { wellId, wellboreId, startTime } = wellInfo.value;
    startTDate.value = auto ? startTDate.value : new Date(startTime).getTime();
    endDate.value = startTDate.value + 14400000;
    // console.log("start:", startTDate.value < minMax.value.max);
    for (let i in mnemonic_groups.value) {
      if (i == "SOLUTION") {
        if (mnemonic_groups.value[i].indexOf("stickslipindex") > -1 && mnemonic_groups.value[i].indexOf("stickslip") == -1)
          mnemonic_groups.value[i].push("stickslip");
        if (mnemonic_groups.value[i].indexOf("washoutindex") > -1 && mnemonic_groups.value[i].indexOf("washout") == -1) mnemonic_groups.value[i].push("washout");
      }
      let result = await apiService.WellServices.getTimeLogs({
        fields: mnemonic_groups.value[i],
        well_id: wellId,
        wellbore_name: wellboreId,
        isParse: 1,
        type: i,
        // days: 0.2,
        from: endDate.value,
        to: startTDate.value,
      });
      completeData = { ...completeData, ...result.data.data };

    }
    // console.log("result in useReplay", completeData);
    setTimeout(() => {
      store.dispatch("data/stopLoading");
      changeDetailByInterval();
    }, 700);
  }
  function changeDetailByInterval() {
    tempObj = JSON.parse(JSON.stringify(completeData));
    let tempObjKeys = Object.keys(completeData);
    timeInterval.value = setInterval(() => {
      for (let obKey in completeData) {
        let totalRows = completeData[obKey].length;
        let recTobePushed = Math.round(totalRows / 27);
        let cc =
          tempObj[obKey].length > recTobePushed
            ? recTobePushed
            : recTobePushed - tempObj[obKey].length;
        let currDetails = tempObj[obKey].splice(0, cc);
        actaualReplayVal.value[obKey] = currDetails;
        if (tempObj[obKey].length == 0) {
          timeInterval.value = clearInterval(timeInterval.value);
          if (isAuto.value) {
            tempObjKeys.splice(0, 1);
            if (tempObjKeys.length == 0) {
              startTDate.value += 14400000;
              if (startTDate.value < minMax.value.max) {
                fetchDetailsFromDb(true);
              } else {
                isAuto.value = false;
              }
            }
          }
        }
      }
      replayDetails.value = { ...actaualReplayVal.value };
      counter.value += 1;
    }, 1000);
  }
  async function getMinMaxRange(logType, wellId, wellboreId) {
    try {
      let result = null;
      result = await apiService.WellServices.getMinMaxTime({
        well_id: wellId,
        wellbore_name: wellboreId,
      });
      if (logType == 'time') {
        result.data.min = moment(result.data.min).unix() * 1000;
        result.data.max = moment(result.data.max).unix() * 1000;
      } else {
        result.data.min = result.data.min;
        result.data.max = result.data.max;
      }
      console.log('result minmax:', result);
      return result.data;
    } catch (error) {
      console.error(error);
      return { min: 0, max: 1000 };
    }
  }
  function removeruningIntervals() {
    if (timeInterval.value) {
      timeInterval.value = clearInterval(timeInterval.value);
    }
  }
  return {
    isAuto,
    setAutoPlay,
    setData,
    replayDetails,
    counter,
    removeruningIntervals
  };
}
