<template>
  <div
    @contextmenu="this.setContextIdWidget"
    :style="{
      height: this.widgetHeight + 'px',
      width: this.widgetWidth + 'px',
      background: '#ffff',
      'border-width': this.borderWidth + 'px',
      'border-color': this.borderColor,
    }"
    class="
      relative
      text-center
      flex flex-col
      min-w-0
      break-words
      rounded
      xl:mb-0
      bg-black
    "
  >
    <div class="overflow-auto flex-auto" @click="openConfig">
      <div class="flex flex-wrap">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <img
            :style="{
              height: parseInt(this.widgetHeight) + 'px',
              width: parseInt(this.widgetWidth) + 'px',
            }"
            v-if="this.imageDisplay"
            src="https://picsum.photos/id/870/200/300?grayscale&blur=2"
            alt
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "image",
  methods: {
    setContextIdWidget(e) {
      this.$store.dispatch("disp/setContextId", {
        contextId: this.displayId,
        contextWidgetId: this.widgetId,
        contextType: "widget",
      });
      // e.stopPropagation();
    },
  },
  props: {
    imageWidgetConfig: Object,
    displayId: {
      type: String,
      default: "",
    },
    widgetId: {
      type: String,
      default: "",
    },
    widgetHeight: {
      type: Number,
      default: 200,
    },
    widgetWidth: {
      type: Number,
      default: 300,
    },
    borderWidth: {
      type: Number,
      default: 0,
    },
    borderColor: {
      type: String,
      default: "#FFFFFF",
    },
    imageDisplay: {
      type: String,
      default: "#FFFFFF",
    },
    openConfig: Function,
  },
};
</script>