<template>
  <div>
    <div
      class="block tool_group_pop tool_pop"
      :style="{
        top: this.mouse_y + 25 + 'px',
        left: this.mouse_x - 10 + 'px',
      }"
    >
      <div
        v-if="tool_popup"
        class="px-1 py-1 border-green-700 text-green-700 tooltip_hydraC"
      >
        <span class="tooltiptext">
          <div class="grid-container">
            <div class="item1 _item_">
              <div class="mx-auto">
                <div v-if="isPipeString" class="left_width font_xs_tooltip py-2">
                  <p class="left_width font_xs_tooltip">
                    Hole Depth:
                    {{
                      this.data.dmea ? parseFloat(this.data.dmea).toFixed(2) : 0
                    }}
                    ft
                  </p>
                  <p class="left_width font_xs_tooltip">
                    Bit Depth: {{ parseFloat(this.data.dbtm).toFixed(2) }} ft
                  </p>
                  <p class="left_width font_xs_tooltip">
                    Pill Depth:
                    {{
                      this.pill_depth_annular && this.pill_depth_annular >= 0
                        ? this.pill_depth_annular.toFixed(2)
                        : this.pill_depth && this.pill_depth >= 0
                        ? this.pill_depth.toFixed(2)
                        : 0
                    }}
                    ft
                  </p>
                  <p class="left_width font_xs_tooltip">
                    Current Strokes:
                    {{ this.current_strokes }}
                    stks
                  </p>
                  <template
                    v-if="
                      parseInt(dry_pipe_length) &&
                      parseInt(slug_weight) &&
                      (hydrostatic_pressure ||
                        gradient_btn_slug_mud_wt ||
                        slug_length ||
                        slugVolume ||
                        this.data['dpcapacity'])
                    "
                  >
                    <br />

                    <p class="tooltip__heading">
                      Barrels of slug required for a desired length of dry pipe
                    </p>
                    <p
                      v-if="hydrostatic_pressure"
                      class="left_width font_xs_tooltip"
                    >
                      Hydrostatic Pressure required to give desired drop in DP:
                      {{ hydrostatic_pressure.toFixed(2) }} psi
                    </p>
                    <p
                      v-if="gradient_btn_slug_mud_wt"
                      class="left_width font_xs_tooltip"
                    >
                      Difference in pressure gradient between SW & MW:
                      {{ gradient_btn_slug_mud_wt.toFixed(2) }} psi/ft
                    </p>
                    <p v-if="slug_length" class="left_width font_xs_tooltip">
                      Length of Slug in DP: {{ slug_length.toFixed(2) }} ft
                    </p>
                    <p
                      v-if="calc_slugVolume"
                      class="left_width font_xs_tooltip"
                    >
                      Vol. of Slug: {{ calc_slugVolume.toFixed(2) }} bbls
                    </p>
                    <p
                      v-if="this.data['dpcapacity']"
                      class="left_width font_xs_tooltip"
                    >
                      DP Capacity:
                      {{ parseFloat(this.data["dpcapacity"]).toFixed(3) }}
                      bbl/ft
                    </p>
                  </template>
                  <template
                    v-if="
                      parseInt(slug_dry_pipe_length) &&
                      parseInt(slug_volume) &&
                      (calc_slug_weight ||
                        calc_slug_length ||
                        calc_hydrostatic_pressure_for_drop)
                    "
                  >
                    <br />
                    <p class="tooltip__heading">
                      Weight of slug required for a desired length of dry pipe
                    </p>
                    <p
                      v-if="calc_slug_length"
                      class="left_width font_xs_tooltip"
                    >
                      Length of slug in DP:
                      {{ parseFloat(calc_slug_length).toFixed(2) }} ft
                    </p>
                    <p
                      v-if="calc_hydrostatic_pressure_for_drop"
                      class="left_width font_xs_tooltip"
                    >
                      Hydrostatic pressure required to give desired pressure
                      drop in DP:
                      {{
                        parseFloat(calc_hydrostatic_pressure_for_drop).toFixed(
                          2
                        )
                      }}
                      psi
                    </p>
                    <p
                      v-if="calc_slug_weight"
                      class="left_width font_xs_tooltip"
                    >
                      Weight of Slug:
                      {{ parseFloat(calc_slug_weight).toFixed(2) }} ppg
                    </p>
                  </template>

                  <br v-if="heightGained || pressureGained || volumeGained" />
                  <p
                    v-if="heightGained || pressureGained || volumeGained"
                    class="tooltip__heading"
                  >
                    Volume, height, pressure gained because of slug
                  </p>
                  <p v-if="heightGained" class="left_width font_xs_tooltip">
                    Ht of Slug in Ann: {{ heightGained.toFixed(2) }} ft
                  </p>
                  <p v-if="pressureGained" class="left_width font_xs_tooltip">
                    Hydrostatic Pressure Gain in Ann :
                    {{ pressureGained.toFixed(2) }} psi
                  </p>
                  <p v-if="volumeGained" class="left_width font_xs_tooltip">
                    Vol Gain in Pit : {{ volumeGained.toFixed(2) }} bbls
                  </p>
                  <br
                    v-if="
                      total_volume_spotting_fluid || spot_pill_strokes_required
                    "
                  />
                  <p
                    class="tooltip__heading"
                    v-if="
                      total_volume_spotting_fluid ||
                      stroke_required_pump_pill ||
                      spot_pill_strokes_required ||
                      volume_required_pill_chase ||
                      stroke_required_chase_pill
                    "
                  >
                    Spotting pill calculation
                  </p>

                  <p
                    v-if="total_volume_spotting_fluid"
                    class="left_width font_xs_tooltip"
                  >
                    Total Barrels of spotting pill required:
                    {{ this.total_volume_spotting_fluid.toFixed(2) }} bbls
                  </p>
                  <p
                    v-if="stroke_required_pump_pill"
                    class="left_width font_xs_tooltip"
                  >
                    Strokes required to pump pill:
                    {{ this.stroke_required_pump_pill.toFixed(2) }} stks
                  </p>
                  <p
                    v-if="volume_required_pill_chase"
                    class="left_width font_xs_tooltip"
                  >
                    Volume(bbls) required to chase pill:
                    {{ this.volume_required_pill_chase.toFixed(2) }} bbls
                  </p>
                  <p
                    v-if="stroke_required_chase_pill"
                    class="left_width font_xs_tooltip"
                  >
                    Strokes required to chase pill:
                    {{ this.stroke_required_chase_pill.toFixed(2) }} stks
                  </p>
                  <p
                    v-if="spot_pill_strokes_required"
                    class="left_width font_xs_tooltip"
                  >
                    Strokes Required to spot pill:
                    {{ this.spot_pill_strokes_required.toFixed(2) }} stks
                  </p>
                </div>
                <div v-if="!isPipeString && this.geometry" class="left_width">
                  Casing Details
                  <p class="left_width font_xs_tooltip">
                    Section Type: {{ this.geometry.section_type }}
                  </p>
                  <p class="left_width font_xs_tooltip">
                    Casing OD: {{ this.geometry.odia }} inch
                  </p>
                  <p class="left_width font_xs_tooltip">
                    Casing ID: {{ this.geometry.idia }} inch
                  </p>
                  <p class="left_width font_xs_tooltip">
                    Casing Depth: {{ this.geometry.md }} ft
                  </p>
                  <p class="left_width font_xs_tooltip">
                    TVD: {{ this.geometry.tvd }} ft
                  </p>
                </div>
              </div>
            </div>
          </div>
        </span>
      </div>
    </div>
    <div
      :id="widgetId"
      @drop="onDropOnWidgets($event, 1)"
      @contextmenu="this.setContextIdWidget"
      :style="{
        height: this.widgetHeight + 'px',
        width: this.widgetWidth + 'px',
        overflowy: 'hidden',
        paddingTop: '5px',
        borderWidth: '0px',

        background: darkDark !== 'white'  ? this.cardBackground : '#FFFFF7',
      }"
      class="bg_purple shadow-lg cust_shadow"
      style="position: relative"
      @mouseover="isHovering = true"
      @mouseout="isHovering = false"
    >
      <div
        :id="widgetId"
        @drop="onDropOnWidgets($event, 1)"
        @contextmenu="this.setContextIdWidget"
        :style="{
          height: this.widgetHeight - 30 + 'px',
          width: this.widgetWidth - 100 + 'px',
          overflowy: 'hidden',
          paddingTop: '5px',
          borderWidth: '0px',

          background: darkDark !== 'white'  ? this.cardBackground : '#FFFFF7',
        }"
        class="mx-auto bg_purple shadow-lg cust_shadow"
        style="position: relative"
        @mouseover="isHovering = true"
        @mouseout="isHovering = false"
      >
        <div :id="'hydraulics-calculator' + widgetId">
          <span
            v-if="isNoData"
            class="font_xs_tooltip flex justify-center py-0.5 font-bold absolute top-0 w-full text-center mx-auto"
          >
            {{ this.message }}</span
          >
          <span
            class="text_xs flex justify-center py-0.5 font-bold absolute bottom-0 w-full text-center mx-auto"
            >Bit Depth:
            {{
              this.data.dbtm ? parseFloat(this.data.dbtm).toFixed(2) : 0
            }}
            (ft)</span
          >
        </div>
      </div>
      <form class="opt___s">
        <input
          id="pillTrace"
          value="pill"
          class="tracer"
          name="tracer"
          type="radio"
          @input="startPillTrace"
        />
        <label for="pillTrace" class="ml-2 uppercase text_xs"
          >Pill Tracer</label
        >
        <input
          id="lagTrace"
          value="lag"
          name="tracer"
          type="radio"
          @input="startPillTrace($event, true)"
        />
        <label for="lagTrace" class="ml-2 uppercase text_xs">Lag Tracer</label>
      </form>
    </div>
  </div>
</template>
<script>
import * as d3 from "d3";
// import textures from 'textures';
import Service from "../../../api/services";
import { useReplay } from "../../../composable/useReplay.js";
let svg;
export default {
  name: "hydraulics-calculator",
  props: {
    cardBackground: {
      type: String,
      default: "var(--wdigetBg)",
    },
    widgetConfig: {
      type: Object,
    },
    widgetIndex: {
      type: Number,
    },
    widgetHeight: {
      type: Number,
      default: 700,
    },
    widgetWidth: {
      type: Number,
      default: 300,
    },
    widgetId: {
      type: String,
    },
    displayId: {
      type: String,
    },
    dry_pipe_length: {
      type: [Number, String],
    },
    slug_weight: {
      type: [Number, String],
    },
    slug_dry_pipe_length: {
      type: [Number, String],
    },
    slug_volume: {
      type: [Number, String],
    },
    annular_capacity: {
      type: [Number, String],
    },
    annular_slug_volume: {
      type: [Number, String],
    },
    washout_factor: {
      type: [Number, String],
    },
    drill_string_volume_left: {
      type: [Number, String],
    },
    stroke_required_displace_surface: {
      type: [Number, String],
    },
    height_spot_column: {
      type: [Number, String],
    },
  },
  data() {
    return {
      current_strokes: 0,
      mnemonic_groups: {},
      position: 0,
      yScale: null,
      xScale: null,
      mouse_x: 0,
      mouse_y: 0,
      tool_popup: false,
      mudDensity: 0,
      fields_required: [
        "hwdpannularvolume",
        "hwdpannularcapacity",
        "dc1annularvolume",
        "dc2annularvolume",
        "dpannularcapacity",
        "dc1volume",
        "dc2volume",
        "dc1annularcapacity",
        "dc2annularcapacity",
        "dc1capacity",
        "dc2capacity",
        "hwdpvolume",
        "dpvolume",
        "lagstrokes",
        "hwdpannularstrokes",
        "dpannularstrokes",
        "tot_stk",
        "dpstrokes",
        "hwdpstrokes",
        "dc1length",
        "dc2length",
        "dc1strokes",
        "dc2strokes",
        "dc2annularstrokes",
        "dplength",
        "hwdplength",
        "totalstrokes_2",
        "pumpoutput",
        "dpcapacity",
        "pumpedvolume",
        "dbtm",
        "dmea",
        "stringstrokes",
        "casingdc1length",
        "casingdc2length",
        "casingdplength",
        "hydrostaticpressure",
        "mud density",
        "drillstringvolume",
        "openholedplength",
        "openholehwdplength",
        "openholedc1length",
        "openholedc2length",
        "ohdc1annularcapacity",
        "ohdc2annularcapacity",
        "ohhwdpannularcapacity",
        "ohdpannularcapacity",
        "casinglength",
        "openholelength",
        "ohdc1annularstrokes",
        "ohdc2annularstrokes",
        "ohhwdpannularstrokes",
        "chdpannularstrokes",
        "chhwdpannularstrokes",
        "chdc1annularstrokes",
        "chdc2annularstrokes",
        "casinghwdplength",
        "casingdc1annularcapacity",
        "casingdc2annularcapacity",
        "casinghwdpannularcapacity",
        "casingdpannularcapacity",
        "ohdpannularstrokes",
      ],
      data: {},
      condition: 0,
      initial_strokes: 0,
      pill_depth: 0,
      pill_depth_annular: -1,
      trajectoryLogId: "",
      isPipeString: false,
      isNoData: false,
      pillTraces: false,
      total_volume_spotting_fluid: 0,
      spot_pill_strokes_required: 0,
      isLagDepth: false,
      slug_length: 0,
      slugVolume: 0,
      heightGained: 0,
      pressureGained: 0,
      volumeGained: 0,
    };
  },
  computed: {
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
  },
  async mounted() {
    let displayId = this.displayId;
    let displayDtls = this.$store.state.disp.displays[displayId];

    if (displayDtls) {
      //
      this.wellId = displayDtls.wellId;
      this.wellboreId = displayDtls.wellboreId;
      this.logId = displayDtls.logId;
      this.status = displayDtls.status;
      this.logType = displayDtls.logType;
      this.logTypeId = displayDtls.logTypeId;
      this.max = displayDtls.max;
      this.table = displayDtls.table;
      this.numericValue = "";
      this.tooltipValue = "";
      this.topic = displayDtls.topicName;

      this.trajectoryLogId = displayDtls.trajectoryLogId;
      await this.getMinMaxRange();
      await this.getWellInformation();
      await this.getMenmonics(displayDtls);

      // }
    } else {
    }
    this.$store.subscribe(async (mutation, state) => {
      switch (mutation.type) {
        case "disp/setDisplay":
          if (mutation.payload.display == this.displayId) {
            if (this.$socket) {
              this.takeOffSocket();
            }

            this.wellId = mutation.payload.wellId;
            this.wellboreId = mutation.payload.wellboreId;
            this.logId = mutation.payload.logId;
            this.status = mutation.payload.status;
            this.logType = mutation.payload.logType;
            this.logTypeId = mutation.payload.logTypeId;
            this.max = mutation.payload.max;
            this.table = mutation.payload.table;
            this.numericValue = "";
            this.tooltipValue = "";
            this.trajectoryLogId = mutation.payload.trajectoryLogId;
            this.topic = mutation.payload.topicName;
            await this.getMinMaxRange();
            await this.getWellInformation();
            await this.getMenmonics();

            d3.select(`#hydraulics-calculator${this.widgetId}`).selectAll('svg').remove();
            this.buildHydraulicsCalculator();
          }
          break;
      }
    });
    this.buildHydraulicsCalculator();
  },
  unmounted() {
    //

    this.sockets.unsubscribe(this.$clientId);
  },
  beforeUnmount() {
    if (this.$socket) {
      this.takeOffSocket();
    }
  },
  methods: {
    async getMinMaxRange() {
      try {
        let result = null;
        if (this.logType == "time") {
          result = await Service.WellServices.getMinMaxTime({
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
          });
          console.log(
            "time zone difference : ",
            result.data.min,
            moment(result.data.min).unix() * 1000
          );
          this.max = result.data.max;
          // result.data.min = moment(result.data.min).unix() * 1000;
          // result.data.max = moment(result.data.max).unix() * 1000;
        }

        return result;
      } catch (error) {
        console.error(error);
        return { min: 0, max: 1000 };
      }
    },
    getCondition(current_strokes, remaining_strokes) {
      // When Current strokes <DrillPipe Strokes(dp strokes). (Calculated in string strokes)
      console.log(
        "getcondition 1",
        current_strokes,
        parseFloat(this.data.dpstrokes)
      );
      if (!this.isLagDepth) {
        if (current_strokes <= parseFloat(this.data.dpstrokes))
          this.condition = 1;
        // WhenDrill pipe strokes<current strokes < (drill pipe strokes+ HWDP Strokes),
        else if (
          parseFloat(this.data.dpstrokes) <= current_strokes &&
          current_strokes <=
            parseFloat(this.data.dpstrokes) + parseFloat(this.data.hwdpstrokes)
        )
          this.condition = 2;
        // When (Drill pipe strokes + HWDP Strokes)<Current strokes<string strokes (stringstrokes).
        else if (
          parseFloat(this.data.dc1length) > 0 &&
          parseFloat(this.data.dc2length) < 0.01 &&
          parseFloat(this.data.dpstrokes) + parseFloat(this.data.hwdpstrokes) <=
            current_strokes &&
          current_strokes <= parseFloat(this.data.stringstrokes)
        )
          this.condition = "3a";
        else if (
          parseFloat(this.data.dc1length) > 0 &&
          parseFloat(this.data.dc2length) > 0 &&
          parseFloat(this.data.dpstrokes) + parseFloat(this.data.hwdpstrokes) <=
            current_strokes &&
          current_strokes <=
            parseFloat(this.data.stringstrokes) -
              parseFloat(this.data.dc1strokes)
        )
          this.condition = "3b";
        else if (
          parseFloat(this.data.dc1length) > 0 &&
          parseFloat(this.data.dc2length) > 0 &&
          parseFloat(this.data.dpstrokes) +
            parseFloat(this.data.hwdpstrokes) +
            parseFloat(this.data.dc2strokes) <=
            current_strokes &&
          current_strokes <= parseFloat(this.data.stringstrokes)
        )
          this.condition = "3c";
        else
          this.getPillCalculationConditions(current_strokes, remaining_strokes);
      } else {
        this.getPillCalculationConditions(current_strokes, remaining_strokes);
      }

      return this.condition;
    },
    dblClick(index, config) {
      config = this.widgetConfig;
      if (
        this.$store.state.rect.selectedWidgetProps &&
        this.$store.state.rect.selectedWidgetProps.widgetId
      ) {
        if (
          config.widgetId == this.$store.state.rect.selectedWidgetProps.widgetId
        ) {
          this.$store.dispatch("disp/togglrRightSideBarWidth", { id: -1 });
        } else {
        }
      } else {
        this.$store.dispatch("disp/togglrRightSideBarWidth", { id: -1 });
      }
      this.$store.dispatch("rect/toggleRightSideBar", {
        id: this.widgetIndex,
        value: this.widgetConfig,
      });
    },
    setContextIdWidget(e) {
      this.$store.dispatch("disp/setContextId", {
        contextId: this.displayId,
        contextWidgetId: this.widgetId,
        contextType: "widget",
      });
      //   e.stopPropagation();
    },
    showPill() {
      // this.pill_depth_annular = 12000;
      // this.pill_depth = 1200;
      console.log("called show pill : ", this.pill_depth_annular);
      if (
        this.pill_depth_annular > 0 &&
        this.pill_depth_annular < this.data.dbtm
      ) {
        svg
          .append("circle")
          .attr("id", "orange1-circle")
          .style("stroke", "orange")
          .style("stroke-width", "5")
          .style("fill", "transparent")
          .attr("r", 10)
          .attr("cx", this.xScale(5))
          .style("filter", "drop-shadow(orange 0px 3px 2px)")
          .attr("cy", this.yScale(this.pill_depth_annular));
        // .transition()
        // .duration(10000)
        // .attr("cy", this.yScale(0))
        // .remove();

        svg
          .append("circle")
          .attr("id", "orange2-circle")
          .style("stroke", "orange")
          .style("stroke-width", "5")
          .style("fill", "transparent")
          .attr("r", 10)
          .attr("cx", this.xScale(25))
          .style("filter", "drop-shadow(orange 0px 3px 2px)")
          .attr("cy", this.yScale(this.pill_depth_annular));
        // .transition()
        // .duration(10000)
        // .attr("cy", this.yScale(0)).remove();
      } else {
        svg.select("#orange1-circle").remove();
        svg.select("#orange1-circle").remove();
      }
      if (!this.isLagDepth) {
        if (this.pill_depth > 0 && this.pill_depth < this.data.dbtm) {
          svg
            .append("circle")
            .attr("id", "green-circle")
            .style("stroke", "green")
            .style("fill", "transparent")
            .style("stroke-width", "5")
            .style("filter", "drop-shadow(green 0px -2px 2px)")
            .attr("r", 8)
            .attr("cx", this.xScale(15))
            .attr("cy", this.yScale(this.pill_depth));
          // .transition()
          // .duration(1000)
          // .attr("cy", this.yScale(this.pill_depth))
          // .remove();
        } else {
          svg.select("#green-circle").remove();
        }
      }
    },
    async startPillTrace(event, isLagDepth) {

      this.pillTraces = event.target.checked;
      this.isLagDepth = isLagDepth;
      this.current_strokes = 0;
      svg.select("#green-circle").remove();
      svg.select("#orange1-circle").remove();
      svg.select("#orange1-circle").remove();
      if ((this.status == "UPDATING" || this.status == 0) && !this.widgetConfig.isreplay ) {

        if (this.$socket) {
          await this.takeOffSocket();
        }
        this.getRealtimeData();
        this.showPill();
      }
      // if (event.target.checked) {
      //     if (this.status == "UPDATING" || this.status == 0) {
      //         if (this.$socket) {
      //             await this.takeOffSocket()
      //         }
      //         this.getRealtimeData();
      //         this.showPill()
      //     }
      // } else {
      //     if (this.$socket) {
      //         await this.takeOffSocket()
      //     }

      // }
    },
    getPillCalculationConditions(current_strokes, remaining_strokes) {
      // CASING LENGTH > 0, Open Hole Length > 0 ,
      // CURRENT STROKES <  (OH DC1 Annular strokes + OH DC2 Annular Strokes + OH HWDP Annular strokes + OH DP Annular strokes.)
      // this.current_strokes is also current strokes across annulus
      // if (this.data.isLagDepth) this.current_strokes = this.current_strokes;
      // else
      //   this.current_strokes = this.current_strokes - this.data.stringstrokes;

      let current_strokes_annulus = 0;
      //(CUMMULATIVETOTAL STROKES - INITIAL STROKES).
      if (current_strokes > Number(this.data.stringstrokes))
        current_strokes_annulus =
          Number(current_strokes) -
          (this.isLagDepth ? 0 : Number(this.data.stringstrokes));
      else
        current_strokes_annulus =
          Number(current_strokes) -
          (Number(0) + (this.isLagDepth ? 0 : Number(this.data.stringstrokes)));

      let current_pill_depth = 0;
      console.log(
        "~~~~~~~~~~~ condition current strokes annulus ~~~~~~~~~~~~~~ ",
        this.data.tot_stk,
        current_strokes_annulus,
        current_strokes,
        this.data.casinglength >= 0,
        this.data.openholelength > 0,
        current_strokes <
          this.data.ohdc1annularstrokes +
            this.data.ohdc2annularstrokes +
            this.data.ohhwdpannularstrokes +
            this.data.ohdpannularstrokes
      );
      current_strokes = current_strokes_annulus;

      if (
        this.data.casinglength >= 0 &&
        this.data.openholelength > 0 &&
        current_strokes <
          this.data.ohdc1annularstrokes +
            this.data.ohdc2annularstrokes +
            this.data.ohhwdpannularstrokes +
            this.data.ohdpannularstrokes
      ) {
        console.log("~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ", 2);
        if (
          this.data.casingdc1length >= 0 &&
          this.data.openholedc1length >= 0 &&
          this.data.openholedc2length >= 0 &&
          this.data.openholehwdplength >= 0 &&
          this.data.openholedplength >= 0
        ) {
          if (current_strokes <= this.data.ohdc1annularstrokes) {
            let annular_volume_accross_dc1 =
              current_strokes * this.data.pumpoutput;
            console.log("~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ", 3);
            if (
              this.data.chdpannularstrokes +
                this.data.ohdpannularstrokes +
                this.data.ohhwdpannularstrokes +
                this.data.ohdc2annularstrokes +
                this.data.chhwdpannularstrokes +
                this.data.chdc1annularstrokes +
                this.data.chdc2annularstrokes <=
              remaining_strokes
            ) {
              console.log("~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ", 4);
              this.condition = 5;
              let oh_length_accross_dc1 =
                annular_volume_accross_dc1 / this.data.ohdc1annularcapacity;
              this.pill_depth_annular = this.data.dbtm - oh_length_accross_dc1;
            }
          }
        }
        // OH DC1 Annular strokes < Current stokes or Cal Current Strokes across Annulus <= OH DC1 Annular strokes + OH DC2 Annular strokes
        if (
          this.data.ohdc1annularstrokes < current_strokes &&
          current_strokes <=
            this.data.ohdc1annularstrokes + this.data.ohdc2annularstrokes
        ) {
          console.log("~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ", 5);
          let oh_dc2_curr_annular_strokes =
            current_strokes - this.data.ohdc1annularstrokes;
          let annular_volume_accross_dc2 =
            oh_dc2_curr_annular_strokes * this.data.pumpoutput;
          // (CH DP Annular strokes + CH HWDP Annular Strokes+ CH DC2 Annular strokes) < Remaining strokes to surface.
          if (
            this.data.chdpannularstrokes +
              this.data.ohdpannularstrokes +
              this.data.ohhwdpannularstrokes +
              this.data.chhwdpannularstrokes +
              this.data.chdc2annularstrokes <
            remaining_strokes
          ) {
            console.log("~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ", 6);
            this.condition = 6;
            let oh_length_accross_dc2 =
              annular_volume_accross_dc2 / this.data.ohdc2annularcapacity;
            this.pill_depth_annular =
              this.data.dbtm -
              (oh_length_accross_dc2 + this.data.openholedc1length);
          }
        }

        // OH DC1 Annular strokes + OH DC2 Annular strokes < Current stokes or Cal Current Strokes across Annulus <= OH DC1 Annular strokes + OH DC2 Annular strokes + OH HWDP Annular strokes.
        if (
          this.data.ohdc1annularstrokes + this.data.ohdc2annularstrokes <
            current_strokes &&
          current_strokes <=
            this.data.ohdc1annularstrokes +
              this.data.ohdc2annularstrokes +
              this.data.ohhwdpannularstrokes
        ) {
          console.log("~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ", 7);
          let oh_hwdp_curr_annular_strokes =
            current_strokes -
            (this.data.ohdc1annularstrokes + this.data.ohdc2annularstrokes);
          let annular_volume_accross_hwdp =
            oh_hwdp_curr_annular_strokes * this.data.pumpoutput;
          // (CH DP Annular strokes+ CH HWDP Annular Strokes) < Remaining strokes to surface
          if (
            this.data.chdpannularstrokes +
              this.data.ohdpannularstrokes +
              this.data.chhwdpannularstrokes <
            remaining_strokes
          ) {
            console.log("~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ", 8);
            this.condition = 7;
            let oh_length_accross_hwdp =
              annular_volume_accross_hwdp / this.data.ohhwdpannularcapacity;
            this.pill_depth_annular =
              this.data.dbtm -
              (oh_length_accross_hwdp +
                this.data.openholedc2length +
                this.data.openholedc1length);
          }
        }

        // OH DC1 Annular strokes + OH DC2 Annular strokes + OH HWDP Annular Strokes < Current stokes or Cal Current Strokes across Annulus <= OH DC1 Annular strokes + OH DC2 Annular strokes+ OH HWDP Annular strokes + OH DP Annular strokes
        if (
          this.data.ohdc1annularstrokes +
            this.data.ohdc2annularstrokes +
            this.data.ohhwdpannularstrokes <
            current_strokes &&
          current_strokes <=
            this.data.ohdc1annularstrokes +
              this.data.ohdc2annularstrokes +
              this.data.ohhwdpannularstrokes +
              this.data.ohdpannularstrokes
        ) {
          console.log(
            "~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ",
            9,
            this.data.ohdc1annularstrokes +
              this.data.ohdc2annularstrokes +
              this.data.ohhwdpannularstrokes <
              current_strokes,
            current_strokes <=
              this.data.ohdc1annularstrokes +
                this.data.ohdc2annularstrokes +
                this.data.ohhwdpannularstrokes +
                this.data.ohdpannularstrokes
          );

          let oh_dp_curr_annular_strokes =
            current_strokes -
            (this.data.ohdc1annularstrokes +
              this.data.ohdc2annularstrokes +
              this.data.ohhwdpannularstrokes);
          let annular_volume_accross_dp =
            oh_dp_curr_annular_strokes * this.data.pumpoutput;
          if (this.data.chdpannularstrokes <= remaining_strokes) {
            console.log(
              "~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ",
              10,
              this.data.chdpannularstrokes <= remaining_strokes
            );

            this.condition = 8;
            let oh_length_accross_dp =
              annular_volume_accross_dp / this.data.ohdpannularcapacity;
            this.pill_depth_annular =
              this.data.dbtm -
              (oh_length_accross_dp +
                this.data.openholehwdplength +
                this.data.openholedc2length +
                this.data.openholedc1length);
          }
        }
      }

      // ************************************************ Casing Hole Conditions ************************************************
      if (this.data.casinglength > 0 && this.data.openholelength >= 0) {
        console.log(
          "~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ",
          11,
          this.data.casingdc1length > 0,
          this.data.openholedc1length >= 0,
          this.data.casingdc2length >= 0,
          this.data.casinghwdplength >= 0,
          this.data.casingdplength >= 0,
          this.data.openholedplength >= 0
        );
        console.log(
          "~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ",
          11,
          this.data.casingdc1length,
          this.data.openholedc1length,
          this.data.casingdc2length,
          this.data.casinghwdplength,
          this.data.casingdplength,
          this.data.openholedplength
        );
        //Casing DC1 length > 0, Open Hole DC1 Length ≥ 0 && Casing DC2 Length >= 0 , Casing HWDP Length >= 0 & Casing DP Length Open Hole DP Length ≥ 0
        if (
          this.data.casingdc1length > 0 &&
          this.data.openholedc1length >= 0 &&
          this.data.casingdc2length >= 0 &&
          this.data.casinghwdplength >= 0 &&
          this.data.casingdplength >= 0 &&
          this.data.openholedplength >= 0
        ) {
          console.log("~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ", 11.1);
          // OH DC1 Annular strokes < Current stokes or Cal Current Strokes across Annulus <= OH DC1 Annular strokes + CH DC1 Annular Strokes.
          if (
            this.data.ohdc1annularstrokes < current_strokes &&
            current_strokes <=
              this.data.ohdc1annularstrokes + this.data.chdc1annularstrokes
          ) {
            console.log("~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ", 12);
            let ch_dc1_curr_annular_strokes =
              current_strokes - this.data.ohdc1annularstrokes;

            let annular_volume_accross_ch_dc1 =
              ch_dc1_curr_annular_strokes * this.data.pumpoutput;
            // CH DP Annular strokes + CH HWDP Annular Strokes+ CH DC2 Annular strokes) ≤ Remaining annular strokes to surface
            if (
              this.data.chdpannularstrokes +
                this.data.chhwdpannularstrokes +
                this.data.chdc2annularstrokes <=
              remaining_strokes
            ) {
              console.log("~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ", 13);
              this.condition = 9;
              let ch_length_across_dc1 =
                annular_volume_accross_ch_dc1 /
                this.data.casingdc1annularcapacity;
              this.pill_depth_annular =
                this.data.dbtm -
                ch_length_across_dc1 +
                this.data.openholedc1length;
            }
          }
        }

        // Casing DC2 length > 0, Open Hole DC2 Length ≥ 0 && (OH DC1 Annular strokes + OH DC2 Annular strokes)  < Current strokes or Cal Current Strokes across Annulus <= (OH DC1 Annular strokes + OH DC2 Annular strokes+ CH DC1 Annular strokes + CH DC2 Annular strokes).
        console.log(
          "~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ *** ",
          11,
          this.data.casingdc2length > 0,
          this.data.openholedc2length >= 0,
          this.data.ohdc1annularstrokes + this.data.ohdc2annularstrokes <
            current_strokes,
          current_strokes <=
            this.data.ohdc1annularstrokes +
              this.data.ohdc2annularstrokes +
              this.data.chdc1annularstrokes +
              this.data.chdc2annularstrokes
        );
        console.log(
          "~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ *** ",
          11,
          this.data.casingdc2length,
          this.data.openholedc2length,
          this.data.ohdc1annularstrokes + this.data.ohdc2annularstrokes,
          current_strokes,
          this.data.ohdc1annularstrokes +
            this.data.ohdc2annularstrokes +
            this.data.chdc1annularstrokes +
            this.data.chdc2annularstrokes
        );

        if (
          this.data.casingdc2length > 0 &&
          this.data.openholedc2length >= 0 &&
          this.data.ohdc1annularstrokes + this.data.ohdc2annularstrokes <
            current_strokes &&
          current_strokes <=
            this.data.ohdc1annularstrokes +
              this.data.ohdc2annularstrokes +
              this.data.chdc1annularstrokes +
              this.data.chdc2annularstrokes
        ) {
          console.log("~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ", 14);
          let ch_dc2_annular_strokes =
            current_strokes -
            (this.data.ohdc1annularstrokes +
              this.data.ohdc2annularstrokes +
              this.data.chdc1annularstrokes);
          let annular_volume_accross_ch_dp2 =
            ch_dc2_annular_strokes * this.data.pumpoutput;
          // (CH DP Annular strokes + CH HWDP Annular Strokes) <= Remaining strokes to surface
          if (
            this.data.chdpannularstrokes + this.data.chhwdpannularstrokes <=
            remaining_strokes
          ) {
            console.log("~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ", 15);
            this.condition = 10;
            let ch_length_across_dc2 =
              annular_volume_accross_ch_dp2 /
              this.data.casingdc2annularcapacity;
            this.pill_depth_annular =
              this.data.dbtm -
              (ch_length_across_dc2 +
                this.data.openholedc2length +
                this.data.openholedc1length);
          }
        }

        console.log(
          "******** conditions below below 11 ************** ",
          this.data.casinghwdplength > 0,
          this.data.openholehwdplength >= 0,
          this.data.ohdc1annularstrokes +
            this.data.ohdc2annularstrokes +
            this.data.ohhwdpannularstrokes <
            current_strokes,
          current_strokes <=
            this.data.ohdc1annularstrokes +
              this.data.ohdc2annularstrokes +
              this.data.ohhwdpannularstrokes +
              this.data.chdc1annularstrokes +
              this.data.chdc2annularstrokes +
              this.data.chhwdpannularstrokes
        );
        console.log(
          "******** conditions below below 11 ************** ",
          this.data.casinghwdplength,
          this.data.openholehwdplength,
          this.data.ohdc1annularstrokes +
            this.data.ohdc2annularstrokes +
            this.data.ohhwdpannularstrokes,
          current_strokes,
          this.data.ohdc1annularstrokes +
            this.data.ohdc2annularstrokes +
            this.data.ohhwdpannularstrokes +
            this.data.chdc1annularstrokes +
            this.data.chdc2annularstrokes +
            this.data.chhwdpannularstrokes
        );
        // Casing HWDP Length > 0, Open Hole HWDP Length ≥ 0 && OH DC1 Annular strokes + OH DC2 Annular strokes + OH HWDP Annular strokes < Current stokes or Cal Current Strokes across Annulus <= OH DC1 Annular strokes + OH DC2 Annular strokes+ OH HWDP Annular strokes+ CH DC1 Annular strokes + CH DC2 Annular strokes + CH HWDP Annular strokes
        if (
          this.data.casinghwdplength > 0 &&
          this.data.openholehwdplength >= 0 &&
          this.data.ohdc1annularstrokes +
            this.data.ohdc2annularstrokes +
            this.data.ohhwdpannularstrokes <
            current_strokes &&
          current_strokes <=
            this.data.ohdc1annularstrokes +
              this.data.ohdc2annularstrokes +
              this.data.ohhwdpannularstrokes +
              this.data.chdc1annularstrokes +
              this.data.chdc2annularstrokes +
              this.data.chhwdpannularstrokes
        ) {
          console.log("~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ", 16);
          let ch_hwdp_annular_strokes =
            current_strokes -
            (this.data.ohdc1annularstrokes +
              this.data.ohdc2annularstrokes +
              this.data.ohhwdpannularstrokes +
              this.data.chdc1annularstrokes +
              this.data.chdc2annularstrokes);

          let ch_hwdp_annular_volume =
            ch_hwdp_annular_strokes * this.data.pumpoutput;

          if (this.data.chdpannularstrokes <= remaining_strokes) {
            console.log("~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ", 17);
            this.condition = 11;
            let ch_length_across_hwdp =
              ch_hwdp_annular_volume / this.data.casinghwdpannularcapacity;
            this.pill_depth_annular =
              this.data.dbtm -
              (ch_length_across_hwdp +
                this.data.openholehwdplength +
                this.data.openholedc2length +
                this.data.openholedc1length);
          }
        }

        console.log(
          "************ cond 11. >>>>>>>>>>  ",
          this.data.casingdplength > 0,
          this.data.openholedplength >= 0,
          this.data.ohdc1annularstrokes +
            this.data.ohdc2annularstrokes +
            this.data.ohhwdpannularstrokes +
            this.data.ohdpannularstrokes <
            current_strokes,
          current_strokes <=
            this.data.ohdc1annularstrokes +
              this.data.ohdc2annularstrokes +
              this.data.ohhwdpannularstrokes +
              this.data.ohdpannularstrokes +
              this.data.chdc1annularstrokes +
              this.data.chdc2annularstrokes +
              this.data.chhwdpannularstrokes +
              this.data.chdpannularstrokes
        );
        console.log(
          "************ cond 11. >>>>>>>>>>  ",
          this.data.casingdplength,
          this.data.openholedplength,
          this.data.ohdc1annularstrokes +
            this.data.ohdc2annularstrokes +
            this.data.ohhwdpannularstrokes +
            this.data.ohdpannularstrokes,
          current_strokes,
          this.data.ohdc1annularstrokes,
          this.data.ohdc2annularstrokes,
          this.data.ohhwdpannularstrokes,
          this.data.ohdpannularstrokes,
          this.data.chdc1annularstrokes,
          this.data.chdc2annularstrokes,
          this.data.chhwdpannularstrokes,
          this.data.chdpannularstrokes
        );
        // Casing DP Length > 0, Open Hole DP Length ≥ 0 && OH DC1 Annular strokes + OH DC2 Annular strokes + OH HWDP Annular strokes + OH DP Annular strokes < Current stokes or Cal Current Strokes across Annulus < (OH DC1 Annular strokes + OH DC2 Annular strokes+ OH HWDP Annular strokes+ OH DP Annular strokes + CH DC1 Annular strokes + CH DC2 Annular strokes + CH HWDP Annular strokes + CH DP Annular strokes.
        if (
          this.data.casingdplength > 0 &&
          this.data.openholedplength >= 0 &&
          this.data.ohdc1annularstrokes +
            this.data.ohdc2annularstrokes +
            this.data.ohhwdpannularstrokes +
            this.data.ohdpannularstrokes <
            current_strokes &&
          current_strokes <=
            this.data.ohdc1annularstrokes +
              this.data.ohdc2annularstrokes +
              this.data.ohhwdpannularstrokes +
              this.data.ohdpannularstrokes +
              this.data.chdc1annularstrokes +
              this.data.chdc2annularstrokes +
              this.data.chhwdpannularstrokes +
              this.data.chdpannularstrokes
        ) {
          console.log("~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ", 18);
          let ch_dp_annular_strokes =
            current_strokes -
            (this.data.ohdc1annularstrokes +
              this.data.ohdc2annularstrokes +
              this.data.ohhwdpannularstrokes +
              this.data.chdc1annularstrokes +
              this.data.chdc2annularstrokes +
              this.data.chhwdpannularstrokes +
              this.data.ohdpannularstrokes);

          let annular_volume_accross_ch_dp =
            ch_dp_annular_strokes * this.data.pumpoutput;

          if (this.data.chdpannularstrokes >= remaining_strokes) {
            console.log("~~~~~~~~~~~ condition ~~~~~~~~~~~~~~ ", 19);
            this.condition = 12;
            let ch_length_across_dp =
              annular_volume_accross_ch_dp / this.data.casingdpannularcapacity;
            this.pill_depth_annular =
              this.data.dbtm -
              (ch_length_across_dp +
                this.data.openholedplength +
                this.data.openholehwdplength +
                this.data.openholedc2length +
                this.data.openholedc1length);
          }
        }
      }
      return this.condition;
    },
    buildHydraulicsCalculator() {
      try {
        this.isNoData = false;
        if (this.data.tot_stk != null) {
          d3.select(`#hydraulics-calculator${this.widgetId}`).selectAll('svg').remove();
          svg = null;
          // const texture = textures.circles()
          //     .size(5);
          svg = d3
            .select(`#hydraulics-calculator${this.widgetId}`)
            .append("svg")
            .attr("width", this.widgetWidth - 100)
            .attr("height", this.widgetHeight - 50)
            .attr("class", "mx-auto")
            .style("overflow", "visible");

          var y = d3
            .scaleLinear()
            .domain([parseFloat(this.data.dbtm) || 500, 0]) // This is what is written on the Axis: from 0 to 100
            .range([this.widgetHeight - 50, 0]); // Note it is reversed

          this.yScale = y;

          var dataset = [{ x: 18 }, { x: 85 }],
            i = 0;

          //

          // svg.call(texture);

          let scaleX = d3
            .scaleLinear()
            .domain([0, 30])
            .range([0, this.widgetWidth - 100]);

          this.xScale = scaleX;

          if (Number(this.data.tot_stk) >= Number(this.data_initial.tot_stk)) {
            this.current_strokes +=
              Number(this.data.tot_stk) - Number(this.data_initial.tot_stk);
          } else {
            this.current_strokes += Number(this.data.tot_stk) || 1;
          }
          this.data_initial.tot_stk = Number(this.data.tot_stk);

          console.log("current strokes : ", this.current_strokes);

          let current_strokes = this.current_strokes;
          let current_strokes_annulus = 0;
          //(CUMMULATIVETOTAL STROKES - INITIAL STROKES).
          if (Number(current_strokes) < Number(this.data_initial.tot_stk))
            current_strokes_annulus =
              Number(current_strokes) -
              (this.isLagDepth ? 0 : Number(this.data.stringstrokes));
          else
            current_strokes_annulus =
              Number(current_strokes) -
              (Number(0) +
                (this.isLagDepth ? 0 : Number(this.data.stringstrokes)));

          let remaining_strokes =
            Number(this.data.lagstrokes) - current_strokes_annulus;

          // if (current_strokes > Number(this.data.stringstrokes)) {
          //   this.current_strokes =
          //     current_strokes - Number(this.data.stringstrokes);
          //   current_strokes = this.current_strokes;
          // }
          this.getCondition(current_strokes, remaining_strokes);

          let current_volume = 0;
          let length_of_pill = 0;
          let pill_depth = 0;
          let pill_depth_annular = 0;
          let current_strokes_across_annulus = 0;
          let remaining_strokes_surface = 0;
          let annular_volume_across = 0;
          let length_of_pill_across = 0;
          let annular_volume_across_dp = 0;
          let annular_volume_across_hwdp = 0;

          if (this.condition == 1) {
            current_volume = current_strokes * this.data.pumpoutput;
            length_of_pill = current_volume / this.data.dpcapacity;
            pill_depth = length_of_pill;
          } else if (this.condition == 2) {
            current_volume = current_strokes * this.data.pumpoutput;
            let pumped_volume_inside_hwdp = current_volume - this.data.dpvolume;
            length_of_pill = pumped_volume_inside_hwdp / this.data.hwdpcapacity; //getting NaN
            pill_depth = this.data.dplength + length_of_pill;
          } else if (this.condition == "3a") {
            current_volume = current_strokes * this.data.pumpoutput;
            let pumped_volume_inside_dc1_mwd =
              current_volume - (this.data.dpvolume + this.data.hwdpvolume);
            length_of_pill =
              pumped_volume_inside_dc1_mwd / this.data.dc1capacity; //getting NaN
            pill_depth =
              this.data.dplength + this.data.hwdplength + length_of_pill;
          } else if (this.condition == "3b") {
            current_volume = current_strokes * this.data.pumpoutput;
            let pumped_volume_inside_dc2 =
              current_volume - (this.data.dpvolume + this.data.hwdpvolume);
            length_of_pill = pumped_volume_inside_dc2 / this.data.dc2capacity; //getting NaN
            pill_depth =
              this.data.dplength + this.data.hwdplength + length_of_pill;
          } else if (this.condition == "3c") {
            current_volume = current_strokes * this.data.pumpoutput;
            let pumped_volume_inside_dc2 =
              current_volume -
              (this.data.dpvolume + this.data.hwdpvolume + this.data.dc2volume);
            length_of_pill = pumped_volume_inside_dc2 / this.data.dc1capacity; //getting NaN
            pill_depth =
              parseFloat(this.data.dplength) +
              parseFloat(this.data.hwdplength) +
              length_of_pill +
              parseFloat(this.data.dc1length);
          } else if (this.condition == "4a") {
            annular_volume_across =
              current_strokes_annulus * parseFloat(this.data.pumpoutput);
            length_of_pill_across =
              annular_volume_across / parseFloat(this.data.dc1annularcapacity);
            pill_depth_annular =
              parseFloat(this.data.dbtm) - length_of_pill_across;
          } else if (this.condition == "4b") {
            annular_volume_across =
              current_strokes_annulus * parseFloat(this.data.pumpoutput);
            length_of_pill_across =
              annular_volume_across / parseFloat(this.data.dc2annularcapacity);
            pill_depth_annular =
              parseFloat(this.data.dbtm) - length_of_pill_across;
          } else if (this.condition == "4c") {
            annular_volume_across =
              current_strokes_annulus * parseFloat(this.data.pumpoutput);
            length_of_pill_across =
              annular_volume_across / parseFloat(this.data.dc1annularcapacity);
            pill_depth_annular =
              parseFloat(this.data.dbtm) -
              length_of_pill_across -
              parseFloat(this.data.dc1length);
          } else if (this.condition == 5) {
            annular_volume_across =
              parseFloat(current_strokes_annulus) *
              parseFloat(this.data.pumpoutput);
            annular_volume_across_hwdp =
              parseFloat(annular_volume_across) -
              parseFloat(this.data.dc1annularvolume);
            length_of_pill_across =
              parseFloat(annular_volume_across_hwdp) /
              parseFloat(this.data.hwdpannularcapacity);
            pill_depth_annular =
              parseFloat(this.data.dbtm) -
              (parseFloat(length_of_pill_across) +
                parseFloat(this.data.dc1length) +
                parseFloat(this.data.dc2length));
          } else if (this.condition == 6) {
            annular_volume_across =
              parseFloat(current_strokes_annulus) *
              parseFloat(this.data.pumpoutput);
            annular_volume_across_dp =
              parseFloat(annular_volume_across) -
              (parseFloat(this.data.hwdpannularvolume) +
                parseFloat(this.data.dc1annularvolume) +
                parseFloat(this.data.dc2annularvolume));
            length_of_pill_across =
              parseFloat(annular_volume_across_dp) /
              parseFloat(this.data.dpannularcapacity);
            pill_depth_annular =
              parseFloat(this.data.dbtm) -
              (parseFloat(length_of_pill_across) +
                parseFloat(this.data.hwdplength) +
                parseFloat(this.data.dc1length) +
                parseFloat(this.data.dc2length));
          }
          pill_depth =
            pill_depth > this.data.dbtm ? this.data.dbtm : pill_depth;
          // this.pill_depth_annular = pill_depth_annular;
          console.log(
            "~~~~~~~~~~ condition with annular depth : ",
            this.pill_depth_annular,
            this.condition,
            pill_depth,
            this.data.dbtm
          );
          this.pill_depth = pill_depth;
          let bha_length =
            parseFloat(this.data.dbtm) -
            parseFloat(this.data.dc1length || 0) -
            parseFloat(this.data.dc2length || 0) -
            parseFloat(this.data.hwdplength || 0);
          this.data.bhalength;
          let first_marker =
            (parseFloat(this.data.dbtm) || 500) -
            parseFloat(this.data.dc2length || 0) -
            parseFloat(this.data.dc1length || 0);
          console.log(
            " bha length, first_marker : ",
            bha_length,
            first_marker,
            this.data.dbtm,
            bha_length > 0 && first_marker >= 0,
            this.data.dbtm > 0
          );

          // first_marker = 15000
          // bha_length = 10000
          if (bha_length > 0 && first_marker >= 0 &&
            this.data.dbtm > 0) {
            let scaleY = y;
            let dbtm = 500;

            // let poly = [
            //   { x: 7, y: this.data.dbtm || 500 }, //left bottom point
            //   { x: 7, y: first_marker }, //left most top point
            //   {
            //     x: 10,
            //     y: first_marker > 100 ? first_marker - 100 : first_marker,
            //   }, //point merging with pipe again
            //   { x: 10, y: first_marker },
            //   { x: 20, y: first_marker },
            //   {
            //     x: 20,
            //     y: first_marker > 100 ? first_marker - 100 : first_marker,
            //   }, //point merging with pipe again
            //   { x: 23, y: first_marker }, //Right most top point
            //   { x: 23, y: this.data.dbtm || 500 }, //Right most corner

            //   { x: 20, y: this.data.dbtm - 50 || 500 },
            //   { x: 15, y: this.data.dbtm || 500 },
            //   { x: 10, y: this.data.dbtm - 50 || 500 },
            // ];

            let poly = [
              { x: 7, y: this.data.dbtm || 500 }, //left bottom point
              { x: 7, y: this.data.dbtm * (1 - 0.0884) }, //left most top point
              {
                x: 10,
                y: this.data.dbtm * (1 - 0.106),
              }, //point merging with pipe again
              { x: 11, y: this.data.dbtm * (1 - 0.1415) },
              { x: 19, y: this.data.dbtm * (1 - 0.1415) },
              {
                x: 20,
                y: this.data.dbtm * (1 - 0.106),
              }, //point merging with pipe again
              { x: 23, y: this.data.dbtm * (1 - 0.0884) }, //Right most top point
              { x: 23, y: this.data.dbtm || 500 }, //Right most corner

              { x: 20, y: this.data.dbtm * (1 - 0.0084) || 500 },
              { x: 15, y: this.data.dbtm || 500 },
              { x: 10, y: this.data.dbtm * (1 - 0.0084) || 500 },
            ];

            let poly1 = [
              { x: 11, y: this.data.dbtm * (1 - 0.1415) },
              { x: 10, y: 0 },
              { x: 20, y: 0 },
              { x: 19, y: this.data.dbtm * (1 - 0.1415) },
            ];

            svg
              .append("polygon")
              .attr("points", function (d) {
                return poly1
                  .map(function (d) {
                    return [scaleX(d.x), scaleY(d.y)].join(",");
                  })
                  .join(" ");
              })
              .attr("stroke", "gray")
              .attr("fill", "gray")
              .attr("stroke-width", 2)
              .attr("px", 15)
              .attr("py", 15)
              .style("filter", "drop-shadow(gray 0px 5px 5px)")
              .on("mouseenter", (e) => this.mouseEnter(e, true))
              .on("mousemove", (e) => this.mouseMove(e, true))
              .on("mouseleave", (e) => this.mouseLeave(e, true));

            svg
              .append("polygon")
              .attr("points", function (d) {
                return poly
                  .map(function (d) {
                    return [scaleX(d.x), scaleY(d.y)].join(",");
                  })
                  .join(" ");
              })
              .attr("px", 15)
              .attr("py", 15)
              .attr("fill", "#007EA7")
              .style("filter", "drop-shadow(#007EA7 0px 0px 0px)")
              .attr("stroke-width", 2)
              .attr("class", "plygon_shadow")
              .on("mouseenter", (e) => this.mouseEnter(e, true))
              .on("mousemove", (e) => this.mouseMove(e, true))
              .on("mouseleave", (e) => this.mouseLeave(e, true));

            console.log("before ellipse called", scaleX(20) - scaleX(15));
            svg
              .append("ellipse")
              .style("stroke", "black")
              .style("fill", "gray")
              .style("stroke-width", "1")
              .style("filter", "drop-shadow(#007EA7 6px 0px 20px)")
              // .attr("r", 8)
              // .attr("cx", 150)
              //     .attr("cy", 100)
              .attr("transform", ` rotate(90)`)
              .attr("rx", 7)
              .attr("ry", scaleX(20) - scaleX(15))
              .attr("cx", scaleX(0))
              .attr("cy", scaleX(-15));

            var customShapeTri = {
              draw: function (context, size) {
                let s = Math.sqrt(size / 4);
                context.moveTo(-s, -s * 2);
                context.lineTo(s * 2, s);
                context.lineTo(-s, s);
                context.closePath();
              },
            };
            var customTri = d3.symbol().type(customShapeTri).size(200);

            svg
              .append("path")
              .attr("d", customTri)
              .attr("fill", "#000000")
              .style("opacity", 1)
              .attr(
                "transform",
                `translate(${this.widgetWidth - 92},${y(
                  this.data.dbtm / 4 || 500
                )})`
              )
              .style("filter", "drop-shadow(black 2px 5px 5px)")
              .on("mouseenter", this.mouseEnter)
              .on("mousemove", this.mouseMove)
              .on("mouseleave", this.mouseLeave);

            svg
              .append("path")
              .attr("d", customTri)
              .attr("fill", "#000000")
              .style("opacity", 1)
              .attr(
                "transform",
                `translate(-7,${y(this.data.dbtm / 4 || 500)}) rotate(-90)`
              )
              .style("filter", "drop-shadow(black -6px -2px 5px)")
              .on("mouseenter", this.mouseEnter)
              .on("mousemove", this.mouseMove)
              .on("mouseleave", this.mouseLeave);

            if (this.pillTraces) this.showPill();
          } else {
            // this.$toast.error("Not a condition for hydraulcis", {
            //   position: "top",
            //   duration: 1000,
            // });
            this.isNoData = true;
            this.message = "Irrelevant data to hydraulics.";
          }
        } else {
          this.$toast.error("Data not available", {
            position: "top",
            duration: 1000,
          });
          this.message = "Data not available";
          this.isNoData = true;
        }
        this.getSlugCalulationData();
      } catch (error) {
        svg = d3
          .select(`#hydraulics-calculator${this.widgetId}`)
          .selectAll("*")
          .remove();
        console.error(error);
      }
    },
    async getWellGeometry() {
      try {
        let geometryResponse = await Service.WellServices.getWellGeometry({
          well_id: this.wellId,
          wellbore_name: this.wellboreId,
        });
        for (let i = 0; i < geometryResponse.data.rows.length; i++) {
          console.log("geometry : ",geometryResponse.data.rows[i])
          if (
            geometryResponse.data.rows[i] &&
            geometryResponse.data.rows[i].section_type
              .toLowerCase()
              .indexOf("casing") > -1
          ) {
            this.geometry = geometryResponse.data.rows[i];
            break;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getWellInformation() {
      try {
        let dataForTable = await Service.WellServices.getWellInformation({});
        dataForTable.data.rows &&
          dataForTable.data.rows.map((info) => {
            if (info.latest_bha && info.sourceid == this.trajectoryLogId)
              this.planId = info.planid;
          });
      } catch (error) {
        console.error(`error in get well info : ${error}`);
      }
    },
    mouseOver(event, isPipe) {
      this.isPipeString = isPipe;
      this.mouse_x = event.clientX;
      this.mouse_y = event.clientY;
    },
    mouseEnter(event, isPipe) {
      this.tool_popup = true;
      this.isPipeString = isPipe;
      // this.$el.addEventListener("mousemove", this.mouseMove, false);
    },
    mouseLeave(event) {
      this.tool_popup = false;
      // this.$el.removeEventListener('mousemove', this.mouseMove());
    },
    mouseMove(event, isPipe) {
      this.isPipeString = isPipe;
      this.mouse_x = event.clientX;
      this.mouse_y = event.clientY;
    },
    async getMenmonics() {
      let valueAt = "end";

      let dconfig = {};
      let wells = this.$store.state.data.wells;
      this.fields_required.map((mn) => this.getMnemonicType(mn));
      let well = wells[this.wellId];
      if (well) {
        await this.getWellGeometry();
        for (let i in this.mnemonic_groups) {
          let response = await Service.WellServices.getSingleValueTimeLog({
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
            field: this.mnemonic_groups[i].join(","),
            type: i,
            max: this.max,
          });
          // console.log("response of data : response.data", response.data);
          let tempResponse = {};
          for (let res in response.data[0]) {
            if (res == "time") tempResponse[res] = response.data[0][res];
            else tempResponse[res] = Number(response.data[0][res]);
          }
          console.log("got the data : ", tempResponse, this.data);

          this.data = { ...this.data, ...tempResponse };
          this.data_initial = { ...this.data, ...response.data[0] };
        }
      } else {
      }
      return this.data;
    },
    getMnemonicType(mnemonic) {
      // console.time("get mnemonic type", mnemonic)
      let mnemonic_groups = {};
      let all_mnemonics =
        this.$store.state.disp.displays[this.displayId].mnemonics;
      let mnemonic_type = "UNPREFERRED";
      for (let i in all_mnemonics) {
        if (all_mnemonics[i].find((o) => o.std_mnemonic === mnemonic)) {
          mnemonic_type = i;
          mnemonic_groups[i] = [];
          break;
        }
      }
      if (!this.mnemonic_groups[mnemonic_type])
        this.mnemonic_groups[mnemonic_type] = [];
      this.mnemonic_groups[mnemonic_type] = [
        ...this.mnemonic_groups[mnemonic_type],
        mnemonic,
      ];
      // console.timeEnd("get mnemonic type", mnemonic)

      return mnemonic_type;
    },
    getSlugCalulationData() {
      try {
        // Barrels of slug required for a desired length of dry pipe

        if (this.dry_pipe_length && this.slug_weight) {
          let hydrostatic_pressure =
            parseFloat(this.data["mud density"]).toFixed(4) *
            0.052 *
            (parseFloat(this.dry_pipe_length).toFixed(4) || 184);
          this.hydrostatic_pressure = hydrostatic_pressure;
          console.log(
            " see hydrostatic_pressure : ",
            hydrostatic_pressure,
            this.data["mud density"],
            this.mudDensity
          );
          // hydrostatic_pressure = this.data['hydrostaticpressure'];.toFixed(4)
          let gradient_btn_slug_mud_wt =
            ((parseFloat(this.slug_weight).toFixed(4) || 0) -
              parseFloat(this.data["mud density"] || this.mudDensity).toFixed(
                4
              )) *
            0.052;
          this.gradient_btn_slug_mud_wt = gradient_btn_slug_mud_wt;
          let slug_in_dp = hydrostatic_pressure / gradient_btn_slug_mud_wt;
          this.slug_length = slug_in_dp;

          let slug_volume_temp =
            slug_in_dp * parseFloat(this.data["dpcapacity"]).toFixed(4);
          this.calc_slugVolume = slug_volume_temp;
          // Weight of slug required for a desired length of dry pipe with a set volume

          if (
            parseFloat(this.annular_capacity).toFixed(4) &&
            parseFloat(this.annular_slug_volume).toFixed(4)
          ) {
            // Volume, height, and pressure gained because of slug:
            let volume_gained =
              (parseFloat(this.dry_pipe_length).toFixed(4) || 184) *
              parseFloat(this.data["dpcapacity"]).toFixed(4);
            let height_gained =
              parseFloat(this.annular_capacity).toFixed(4) *
              parseFloat(this.annular_slug_volume).toFixed(4);
            let hydrostatic_pressure_gained =
              height_gained * gradient_btn_slug_mud_wt;
            this.heightGained = height_gained;
            this.pressureGained = hydrostatic_pressure_gained;
            this.volumeGained = volume_gained;
            // Barrels of spotting fluid (pill) required
            // Pump strokes required to spot the pill
          } else {
            this.heightGained = 0;
            this.pressureGained = 0;
            this.volumeGained = 0;
          }
        } else {
          this.slug_length = 0;
          this.slugVolume = 0;
          this.heightGained = 0;
          this.pressureGained = 0;
          this.volumeGained = 0;
        }
        console.log(
          "calculation vvvvvv ",
          this.slug_volume,
          this.slug_dry_pipe_length
        );
        if (this.slug_dry_pipe_length && this.slug_volume) {
          let slug_length =
            parseFloat(this.slug_volume) /
            parseFloat(this.data["dpcapacity"]).toFixed(4);
          this.calc_slug_length = parseFloat(slug_length);

          let hydrostatic_pressure_for_drop =
            parseFloat(this.data["mud density"] || this.mudDensity).toFixed(4) *
            0.052 *
            (parseFloat(this.slug_dry_pipe_length).toFixed(4) || 184);
          this.calc_hydrostatic_pressure_for_drop =
            hydrostatic_pressure_for_drop;
          let slug_weight =
            hydrostatic_pressure_for_drop / (0.052 * slug_length) +
            parseFloat(this.data["mud density"] || this.mudDensity);
          console.log(
            "~~~~~~~~ calculator mud weig ~~~~ ",
            hydrostatic_pressure_for_drop / (0.052 * slug_length) +
              parseFloat(this.data["mud density"] || this.mudDensity).toFixed(4)
          );
          this.calc_slug_weight = slug_weight;
        }
        if (
          parseFloat(this.drill_string_volume_left) &&
          parseFloat(this.stroke_required_displace_surface) &&
          parseFloat(this.washout_factor)
        ) {
          let annulur_volume_required_dc =
            parseFloat(this.data["ohdc1annularcapacity"]).toFixed(4) *
            (1 + parseFloat(this.washout_factor) / 100) *
            (this.height_spot_column &&
            parseFloat(this.height_spot_column) >=
              parseFloat(this.data["openholedc1length"]).toFixed(4)
              ? parseFloat(this.data["openholedc1length"]).toFixed(4)
              : parseFloat(this.height_spot_column));

          let annulur_volume_required_hwdp =
            parseFloat(this.data["ohhwdpannularcapacity"]).toFixed(4) *
            (1 + parseFloat(this.washout_factor) / 100) *
            (this.height_spot_column &&
            parseFloat(this.data["openholedc1length"]).toFixed(4) <=
              parseFloat(this.height_spot_column) &&
            parseFloat(this.height_spot_column) <=
              parseFloat(this.data["openholedc1length"]).toFixed(4) +
                parseFloat(this.data["openholehwdplength"]).toFixed(4)
              ? parseFloat(this.height_spot_column) -
                parseFloat(this.data["openholedc1length"]).toFixed(4)
              : parseFloat(this.data["openholehwdplength"]).toFixed(4) -
                parseFloat(this.data["openholedc1length"]).toFixed(4));
          let annulur_volume_required_dp =
            parseFloat(this.data["ohdpannularcapacity"]).toFixed(4) *
            (1 + parseFloat(this.washout_factor) / 100) *
            (this.height_spot_column &&
            parseFloat(this.height_spot_column) >=
              parseFloat(
                parseFloat(this.data["openholedc1length"]).toFixed(4) +
                  parseFloat(this.data["openholehwdplength"]).toFixed(4)
              )
              ? parseFloat(this.height_spot_column) -
                parseFloat(this.data["openholedc1length"]).toFixed(4) -
                parseFloat(this.data["openholehwdplength"]).toFixed(4)
              : parseFloat(this.data["openholedplength"]).toFixed(4) -
                parseFloat(this.data["openholedc1length"]).toFixed(4) -
                parseFloat(this.data["openholedc1length"]).toFixed(4));

          let annulur_volume_required =
            annulur_volume_required_dp +
            annulur_volume_required_dc +
            annulur_volume_required_hwdp;
          console.log(
            "annular volume values ~ ",
            parseFloat(this.data["ohdc1annularcapacity"]).toFixed(2),
            this.washout_factor,
            annulur_volume_required_dp,
            annulur_volume_required_dc,
            annulur_volume_required_hwdp
          );

          // ~~~~~~ volumes ~~~~~~~  2.537135902386503 2.089202448027977 0.447933454358526 0
          let total_volume_spotting_fluid =
            annulur_volume_required + parseFloat(this.drill_string_volume_left);
          let drill_string_capacity =
            parseFloat(this.data["dc1volume"]).toFixed(4) +
            parseFloat(this.data["dpvolume"]).toFixed(4) +
            parseFloat(this.data["hwdpvolume"]).toFixed(4);

          let stroke_required_pump_pill =
            total_volume_spotting_fluid /
            parseFloat(this.data["pumpoutput"]).toFixed(4);

          let volume_required_pill_chase =
            parseFloat(this.data["drillstringvolume"]).toFixed(4) -
            parseFloat(this.drill_string_volume_left);

          let stroke_required_chase_pill =
            volume_required_pill_chase /
              parseFloat(this.data["pumpoutput"]).toFixed(4) +
            parseFloat(this.stroke_required_displace_surface);

          let spot_pill_strokes_required =
            stroke_required_pump_pill + stroke_required_chase_pill;
          this.stroke_required_chase_pill = stroke_required_chase_pill;
          this.stroke_required_pump_pill = stroke_required_pump_pill;
          this.total_volume_spotting_fluid = total_volume_spotting_fluid;
          this.spot_pill_strokes_required = spot_pill_strokes_required;
          this.volume_required_pill_chase = volume_required_pill_chase;
        } else {
          this.volume_required_pill_chase = 0;
          this.stroke_required_pump_pill = 0;
          this.total_volume_spotting_fluid = 0;
          this.spot_pill_strokes_required = 0;
        }
      } catch (error) {
        console.error("error in slug calculations ", error);
      }
    },
    getRealtimeData(title) {
      let topic = `${this.wellId}-${this.wellboreId}-${this.logTypeId}-${this.$store.state.data.customer}`;
      let wells = this.$store.state.data.wells;
      let well = wells[this.wellId];
      if (well) {
        let wellbore = well.wellbores[this.wellboreId];
        let log = wellbore["logs"][this.logType];
        this.logId = log.id;
        let fields = this.fields_required;
        let topicExists = this.$store.state.live.isSubscribed[topic];
        if (this.wellId && this.wellboreId && this.logType) {
          this.$socket.emit("susbcribe", {
            logId: `${this.wellId}~seperation~${this.wellboreId}~seperation~${this.logType}`,
            topic: this.topic,
            fields,
            id: this.widgetId,
            clientId: this.$clientId,
            logType: this.logType,
          });
          if (!topicExists) {
            this.$store.dispatch("live/setSubscriptionFlag", {
              topic: this.topic,
              flag: true,
            });
          }

          this.sockets.subscribe(this.$clientId, (sResult) => {
            // for (let i = 0; i < socketResult.length; i++) {
            //     let sResult = socketResult[i]
            let common = sResult["common"];
            let result = sResult[this.widgetId];

            if (result) {
              let length = result["dbtm"].length || 0;
              let temp_data = {};
              console.log(
                "~~~~~~~~~~ this.data ~~~~~~~~~~~~~ ",
                this.data,
                length
              );
              this.fields_required.map((field) => {
                if (length && !isNaN(result[field][length - 1])) {
                  temp_data[field] = parseFloat(result[field][length - 1]);
                } else {
                  temp_data[field] = 0;
                }
              });
              if (!this.isFirst) {
                this.data_initial = temp_data;
                this.isFirst = true;
              }
              this.data = temp_data;
              d3.select(`#hydraulics-calculator${this.widgetId}`).selectAll('svg').remove();
              this.buildHydraulicsCalculator();
            }
            // }
          });
        }

        topicExists = this.$store.state.live.isSubscribed[topic];
        //
      }
    },
    takeOffSocket() {
      if (this.$socket) {
        this.$socket.emit("unsubscribe", {
          logId: `${this.wellId}~seperation~${this.wellboreId}~seperation~${this.logType}`,
          topic: this.topic,
          id: this.widgetId,
          clientId: this.$clientId,
        });
        this.sockets.unsubscribe(this.$clientId);
        this.isFirst = false;
      }
    },
  },
  watch: {
    widgetHeight(newValue) {
      // d3.select(`#hydraulics-calculator${this.widgetId} svg`).remove();
      d3.select(`#hydraulics-calculator${this.widgetId}`).selectAll('svg').remove();
      this.buildHydraulicsCalculator();
    },
    widgetWidth(newValue) {
      // d3.select(`#hydraulics-calculator${this.widgetId} svg`).remove();
      d3.select(`#hydraulics-calculator${this.widgetId}`).selectAll('svg').remove();
      this.buildHydraulicsCalculator();
    },
    dry_pipe_length(newValue) {
      this.getSlugCalulationData();
    },
    slug_weight(newValue) {
      this.getSlugCalulationData();
    },
    slug_dry_pipe_length(newValue) {
      this.getSlugCalulationData();
    },
    slug_volume(newValue) {
      this.getSlugCalulationData();
    },
    annular_capacity(newValue) {
      this.getSlugCalulationData();
    },
    annular_slug_volume(newValue) {
      this.getSlugCalulationData();
    },
    washout_factor(newValue) {
      this.getSlugCalulationData();
    },
    drill_string_volume_left(newValue) {
      this.getSlugCalulationData();
    },
    stroke_required_displace_surface(newValue) {
      this.getSlugCalulationData();
    },
    height_spot_column(newValue) {
      this.getSlugCalulationData();
    },
    replayDetails(result) {
      let length = result["dbtm"].length || 0;
      let temp_data = {};
      this.fields_required.map((field) => {
        if (length && !isNaN(result[field][length - 1])) {
          temp_data[field] = parseFloat(result[field][length - 1]);
        } else {
          temp_data[field] = 0;
        }
      });
      if (!this.isFirst) {
        let initial_temp_data = {};
        this.fields_required.map((field) => {
        if (length && !isNaN(result[field][0])) {
          initial_temp_data[field] = parseFloat(result[field][0]);
        } else {
          initial_temp_data[field] = 0;
        }
      });

        this.data_initial = initial_temp_data;

        this.isFirst = true;
      }
      this.data = temp_data;
      d3.select(`#hydraulics-calculator${this.widgetId}`).selectAll('svg').remove();
      this.buildHydraulicsCalculator();
    },
  },
  setup() {
    const { isAuto, replayDetails } = useReplay();
    return {
      isAuto,
      replayDetails,
    };
  },
};
</script>
<style scoped>
[type="radio"] {
  /* background-color: #28282f !important; */
  margin-left: auto;
}

[type="radio"]:checked {
  border: 1px solid #5aba81 !important;
  background-color: #5aba81 !important;
}

.tooltip_hydraC {
  position: relative;
  display: block;
}

/* .tool_pop{
    background: #28282f;
} */
.tooltip_hydraC .tooltiptext {
  visibility: hidden;

  color: #fff;
  text-align: center;
  /* border-radius: 6px; */
  /* padding: 5px 10px; */

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  background: #28282f;
  right: 105%;
  width: 280px;
}

.tooltip_hydraC .tooltiptext::after {
  content: "";
  position: absolute;
  right: -6px;
  top: 5px;
  content: "\1F782";
  font-size: 14px;
  color: #005e62;
}

.tooltip_hydraC .tooltiptext {
  visibility: visible;
  /* background: #005e62; */
  z-index: 999999;
}

.tool_group_pop {
  position: fixed;
  display: inline-grid;
  /* left: 221px; */
  z-index: 99999;
  background: unset;
}

.opt___s {
  /* width: 62%; */
  /* margin: 0 auto; */

  margin-top: 3%;
  justify-content: space-between;
  display: flex;
  margin-left: -10%;
  margin-right: 5%;
}

.font_xs_tooltip {
  font-size: 10px;
}

.plygon_shadow {
  box-shadow: 0 0 5px black, 1px 1px 5px red, 2px 2px 5px green, 3px 3px 5px red;
}
</style>
