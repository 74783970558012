
<template>
  <div
    class="shadow-lg"
    :style="{ height: this.widgetHeight + 'px',overflow:'auto',background:'var(--wdigetBg)' }"
    @contextmenu="this.setContextIdWidget"
  >
    <table-lite
      @dblclick="openConfig"
      :has-checkbox="true"
      :is-loading="table.isLoading"
      :is-re-search="table.isReSearch"
      :columns="table.columns"
      :rows="table.rows"
      :total="table.totalRecordCount"
      :sortable="table.sortable"
      :messages="table.messages"
      :page-size="pageSize"
      :color="color"
      :background="background"
      :height="widgetHeight"
      :width="widgetWidth"
      :headColor="headColor"
      :headBackground="headBackground"
      :headFontSize="headFontSize"
      :tableConfiguration="tableConfiguration"
      :tableId="id"
      :widgetPropertyIndex="widgetPropertyIndex"
      :alertColor="newAlertColor"
      @do-search="doSearch"
      @is-finished="tableLoadingFinish"
      @return-checked-rows="updateCheckedRows"
    ></table-lite>
  </div>
</template>

<script>
import TableLite from "./DataTableLibrary";
let newCols;
let id;
import apiService from "../../../api/services";
export default {
  name: "DataTableWidget",

  components: {
    TableLite,
  },

  props: {
    widgetId: String,
    displayId: String,
    alertColor: String,
    blinkAlert: Boolean,
    id: String,
    widgetWidth: Number,
    widgetHeight: Number,
    openConfig: Function,
    headColor: String,
    headBackground: String,
    headFontSize: Number,
    color: String,
    background: String,
    getTableColumns: Function,
    tableConfiguration: {},
    tableData: {
      default: {
        isLoading: false,
        isReSearch: false,
        columns: [],
        rows: [],
        sortable: {
          order: "name",
          sort: "asc",
        },
      },
    },
  },

  async beforeMount() {
    let rects = this.$store.state.rect.rects;
    if (typeof rects != "undefined" && rects.length > 0) {
      for (let i = 0; i < rects.length; i++) {
        if (
          rects[i].widgetId == this.widgetId &&
          rects[i].displayId == this.displayId
        )
          this.widgetPropertyIndex = i;
      }
    }
  },
  async mounted() {
    let dtls = this.$store.state.disp.displays[this.displayId];
    if (dtls) {
      this.wellId = dtls.wellId;
      this.wellboreId = dtls.wellboreId;
      this.logId = dtls.logId;
      this.wellboreState = dtls.wellboreState;
      this.logType = dtls.logType;
      this.table = dtls.table;
      this.wellName = dtls.wellName;
      this.wellData = dtls;
      this.loadTable(dtls);
    }

    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case "disp/setDisplay":
          if (mutation.payload.display == this.displayId) {
            this.wellId = mutation.payload.wellId;
            this.wellboreId = mutation.payload.wellboreId;
            this.logId = mutation.payload.logId;
            this.wellboreState = mutation.payload.wellboreState;
            this.logType = mutation.payload.logType;
            this.table = mutation.payload.table;
            this.wellName = mutation.payload.wellName;
            this.wellData = mutation.payload;
            this.loadTable(mutation.payload);
          }
          break;
      }
    });
  },
  data() {
    return {
      newAlertColor: "#ffffff",
      isBlinkAlert: false,
      columns: [],
      rows: [],
      tableConfig: {
        color: "",
        background: "#0d0d0d",
        headColor: "#cccccc",
        headBackground: "#ffffff",
      },
      pageSize: 10,
      backupTableRows: [],
      table: this.tableData,
      widgetPropertyIndex: -1,
      wellData: {},
    };
  },

  methods: {
    setContextIdWidget(e) {
      this.$store.dispatch("disp/setContextId", {
        contextId: this.displayId,
        contextWidgetId: this.widgetId,
        contextType: "widget",
      });
      // e.stopPropagation();
    },
    async loadTable(dtls) {
      let data_well_info = await apiService.WellServices.getWellInformation({
        wellId: dtls.wellId,
        wellboreId: dtls.wellboreId,
        logType: dtls.logType,
      });
      this.wellData = data_well_info.data
      console.log("well info details : ", data_well_info);
      let dataForTable = [
        "WELL ID",
        "WELL NAME",
        "CUSTOMER",
        "LONGITUDE",
        "LATITUDE",
        "WELL PROFILE",
        "DRILLING TYPE",
        "FEED TYPE",
        "TIME ZONE",
        "RIG NAME"
      ];
      let column = [
        {
          label: "WELL",
          field: "title",
        },
        {
          label: "INFORMATION",
          field: "value",
        },
      ];
      let rows = [];
      
      rows.push({
        title: dataForTable[0],
        value: this.wellData["well_id"],
      });
      rows.push({
        title: dataForTable[1],
        value: this.wellData["well_name"],
      });
      
      rows.push({
        title: dataForTable[2],
        value: this.wellData["customer_name"],
      });
      rows.push({
        title: dataForTable[9],
        value: this.wellData["rig_name"],
      });
      rows.push({
        title: dataForTable[3],
        value: this.wellData["longitude"],
      });
      rows.push({
        title: dataForTable[4],
        value: this.wellData["latitude"],
      });
      rows.push({
        title: dataForTable[5],
        value: this.wellData["well_profile"],
      });
      rows.push({
        title: dataForTable[6],
        value: this.wellData["drilling_type"],
      });
      rows.push({
        title: dataForTable[7],
        value: this.wellData["feed_type"],
      });
      rows.push({
        title: dataForTable[8],
        value: this.wellData["time_zone"],
      });

      this.table = {
        isLoading: false,
        isReSearch: false,
        columns: column,
        rows: rows,
        sortable: {
          order: "name",
          sort: "asc",
        },
      };
    },
    searchHandler(e) {
      let value = e.target.value;
      let newRows = JSON.parse(JSON.stringify(this.table.rows));
      this.table.rows = this.backupTableRows.filter((row) =>
        row.dept.toString().includes(value)
      );

      //
    },

    doSearch(offset, limit, order, sort) {
      // sortFunction
      this.table.isLoading = true;
      this.table.isReSearch = offset == undefined ? true : false;
      let compare = (a, b) => {
        if (a[order] < b[order]) {
          return sort == "desc" ? 1 : -1;
        }
        if (a[order] > b[order]) {
          return sort == "desc" ? -1 : 1;
        }
        return 0;
      };
      this.backupTableRows.sort(compare);
      //
      let newRows = JSON.parse(JSON.stringify(this.backupTableRows));
      // let paginatedTableRows = newRows.slice(
      //   offset,
      //   parseInt(limit) + parseInt(offset)
      // );
      this.table.rows = newRows;

      this.table.sortable.order = order;
      this.table.sortable.sort = sort;
      this.table.isLoading = false;
    },

    tableLoadingFinish(elements) {
      this.table.isLoading = false;
      Array.prototype.forEach.call(elements, function (element) {
        if (element.classList.contains("name-btn")) {
          element.addEventListener("click", function () {
            // do your click event
            //
          });
        }
        if (element.classList.contains("quick-btn")) {
          // do your click event
          element.addEventListener("click", function () {
            //
          });
        }
      });
    },
  },
  watch: {
    blinkAlert: function (newVal) {
      // this.isBlinkAlert = newVal;
      // if (this.isBlinkAlert == false) {
      //   for (var i = 1; i < id; i++) {
      //     window.clearInterval(i);
      //   }
      // }
    },
    alertColor: async function (newVal, oldVal) {
      let i = 0;
      if (this.isBlinkAlert) {
        id = setInterval(() => {
          let color = ["white", newVal];
          i = (i + 1) % color.length;
          this.newAlertColor = color[i];
          if (!this.isBlinkAlert) {
            clearInterval(id);
          }
        }, 1000);
      } else {
        this.newAlertColor = newVal;
      }
    },
  },

  emits: ["toggle-madal"],
};
</script>
