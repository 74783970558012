<template>
   <div class="relative mb-3">
                      <div 
                        v-if="!allUsersSelected" 
                        class="selectedOptions flex" 
                        @click = openListDiv()
                        :class="{'activeBorders' : showAllMailsFromParent}" >
                        <div   v-for="(item,index ) of selectedOptions" :key="index" class="flex m-1 items-center selectedOption">
                          <li class="list-none mr-2 text-xs">{{item }}</li>
                          <button type="button" @click.stop="onCrossClickedOnSelectedOne(item,index)" class="cursor-pointer">
                            <i  class="fas fa-times cursor-pointer"></i>
                          </button>
                        </div>
                       
                      </div>
                      <div v-if="allUsersSelected"
                       class=" selectedOptions  flex" 
                       @click = openListDiv() 
                       :class="{'activeBorders' : showAllMailsFromParent}">
                        <div   class="flex m-1 items-center selectedOption">
                          <li class="list-none mr-2 text-xs">{{`All ${selectedAll} Selected`}}</li>
                          <button type="button" @click.stop="onCrossClickedOnSelectedOne('all')" class="cursor-pointer">
                            <i  class="fas fa-times cursor-pointer"></i>
                          </button>
                        </div>
                       
                      </div>
                      <div v-if="showAllMailsFromParent" :class="{'activeBordersOfList' : showAllMailsFromParent}" class="listDiv">
                        
                        <li v-show="reqOptions.length>0" class="list-none text-sm select-none flex items-center listItem" v-on:change="optionClicked('all')" >
                          <input :checked="selectedOptions.length===reqOptions.length" type="checkbox" class="cursor-pointer mr-2" id="all"/>
                          <label for="all" class="w-full inline-block cursor-pointer">All</label>
                        </li>
                        <li  
                        class="list-none listItem text-sm select-none flex items-center " 
                        v-for="(item) of reqOptions" :key="item" v-on:change="optionClicked(item)">
                        <input :checked="selectedOptions.includes(item)" type="checkbox" class="cursor-pointer mr-2" :id="item"/>
                          <label class="w-full inline-block cursor-pointer" :for="item">{{ item }}</label>
                        </li>
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg"
                      @click = openListDiv()
                        width="10"
                        height="10"
                        class="downArrowOfSelect cursor-pointer" 
                        :class="{'activeDown':showAllMailsFromParent}"
                        viewBox="0 0 384 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                        <path fill="white" d="M169.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 274.7 54.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/>
                        </svg>
                    </div>
</template>

<script>
export default {
    mounted(){
        
    },
    data(){
        return{
            showAllMails:false,
           
            
        }
    },
   
    computed:{
      
      // reqOptions(){
      //   return this.reqOptions
      // },
   
    },
    props:{
      
        selectedAll:{
            type:String
        },
        reqOptions:{
            type: Array,
            default:[]
            
        },
        reqOptions:{
            type:Array
        },
      
        selectedOptions:{
          type:Array
        },
        allUsersSelected:{
          type:Boolean
        },
        showAllMailsFromParent:{
          type:Boolean
        }
    },
    methods:{
        onCrossClickedOnSelectedOne(item,index){
            this.$emit('onCrossClicked',this.selectedOptions,item,index)
        },
        optionClicked(data){ 
          this.$emit('getingSelectedOptions',this.selectedOptions,data)
        },
        openListDiv(){
            this.showAllMails = !this.showAllMails
            console.log("new users Data>>>>reqOptionFromMulti",this.reqOptions)
            this.$emit('getUsersBasedOnselection')
        }
    }
}

</script>

<style>
.activeBordersOfList{
    border:  1px solid var(--activeTextColor);
    border-top: none;
}
.activeBorders{
    border-top: 1px solid var(--activeTextColor) !important;
    border-right:  1px solid var(--activeTextColor) !important;
    border-left:  1px solid var(--activeTextColor) !important   ;
}
.selectedOption{
  background-color: #b4b5ba15;
  /* padding:1px;  */
  font-size: 10px;
  padding-left: 6px;
    padding-right: 6px;
    height: 22px;

}
.selectedOptions{
  /* border: none; */
    border-radius: 3px;
    left: 0;
    /* height: 50px; */
    min-height: 30px;
    max-height: 50px;
    font-size: 13px;
    padding: 0px 0px 0px 2px;
    overflow-y: auto;
    flex-wrap: wrap;
    appearance: auto;
    /* background: var(--searchBarBg); */
    /* color: var(--searchColor); */
    background: var(--sidebarListBgColor);
    color: var(--textColor);
}
.listItem{
  padding: 1px 8px;
  font-size: calc(0.3vw + 0.3vh + 0.35vmin);
}
.listItem:hover {
  background-color: #b4b5ba15;
  border-bottom: 1px solid var(--activeTextColor);
}
.activeDown{
  transform: rotate(-180deg);
}
.activeDown path {
    fill: var(--activeTextColor);
}
.listDiv{
    min-height: 50px;
    max-height: 150px;
    overflow-y: auto;
    padding-top: 10px;
    /* background: var(--searchBarBg); */
    background: var(--sidebarListBgColor);
}
.downArrowOfSelect{
  position: absolute;
  top: 10px;
  right: 5px;
  margin-left:5px;
  
}
.downArrowOfSelect path{
  fill: var(--textColor);
}

</style>