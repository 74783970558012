<template>
  <div class="alertScreen">
    <nav class="flex justify-center alertNav">
      <div class="flex items-center">
        <div class="m-2 flex items-center">
          <label
            @click="onSelectingTheAlertType('PIVOTAL_ALERTS')"
            for="povoAlerts"
            class="m-1 cursor-pointer selectAlertType"
            :class="{
              activenav: Object.keys(storingObj).includes('PIVOTAL_ALERTS'),
            }"
            >Pivotal Alerts
          </label>
        </div>
        <div class="m-2 flex items-center">
          <label
            @click="onSelectingTheAlertType('CRUCIAL_ALERTS')"
            for="crucialAlerts"
            class="m-1 cursor-pointer selectAlertType"
            :class="{
              activenav: Object.keys(storingObj).includes('CRUCIAL_ALERTS'),
            }"
            >Crucial Alerts</label
          >
        </div>
        <div class="m-2 flex items-center">
          <label
            @click="onSelectingTheAlertType('CRITICAL_CONDITION_ALERTS')"
            for="criticalConditionAlerts"
            class="m-1 cursor-pointer selectAlertType"
            :class="{
              activenav: Object.keys(storingObj).includes(
                'CRITICAL_CONDITION_ALERTS'
              ),
            }"
            >Critical Condition Alerts</label
          >
        </div>
      </div>
    </nav>
    <div class="flex flex-col">
      <div class="allWidgetsDiv mt-3">
        <div class="flex gap-2 flex-wrap m-3">
          <div
            v-for="item in alerts"
            :key="item"
            @dblclick="widgetclicked()"
            class="widgetContainer flex justify-center items-center cursor-pointer"
          >
            <p class="widgetName text-sm select-none">{{ item }}</p>
          </div>
          <div v-if="alerts.length" class="m-2 note-of-alerts widgetName w-full">
        <p class="">Note : Above alerts are showing for which alerts belongs to which category, to customize alert configuration  double click on alert </p>
      </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import apiService from "../../../api/services";

export default {
  name: "alert-screen",
  sockets: {
    connect: function () {
      console.log("socket connected in trend");
    },
    disconnect() {
      try {

        this.$socket.emit("unsubscribe", {
          logId: `${this.wellId}~seperation~${this.wellboreId}~seperation~${this.logType}`,
          topic: this.topic,
          id: this.widgetId,
          clientId: this.$clientId,
        });
      } catch (error) {
        console.error('disconnect unsubscribe error ', error)
      }
    },

    reconnect() {
      // this.$socket.emit("susbcribe", {
      //   logId: this.logId,
      //   topic: this.topic,
      //   fields: this.fields,
      //   id: this.widgetId,
      //   clientId: this.$clientId,
      // });
      this.$socket.emit("susbcribe", {
        logId: `${this.wellId}~seperation~${this.wellboreId}~seperation~${this.logType}`,
        topic: this.topic,
        fields: this.fields,
        id: this.widgetId,
        clientId: this.$clientId,
      });

      console.log("----------------------------------------");
    },
  },

  data() {
    return {
      isSeveariyClicked: false,
      storingObj: {},
      userId: null,
      entityId: null,
      toDate: null,
      formDate: null,
      pivotalAlerts: [],
      crucialAlerts: [],
      criticalAlerts: [],
      rightBarSelectclicked: [1],
      showRightSideBar: false,
      alertScreenWidht: "",
      alertScreenHeight: "",
      showingAlertScreen: false,
      alerts: [],
      whichWidgetsAreShown: [],
      mapOfWellNameAndId: [],
      selectedWel: this.selectedWell,
      organizationHierarchy: {
        displayName: null,
        jobRole: null,
        apolloDartSolutionType: null,
        alertMode: null,
      },
      alertInfo: {
        alertPicker: null,
        alertFrequency: null,
        alertDuration: null,
        communicationRequired: true,
        communicationtype: null,
      },
      alertSeverity: null,
      aletPicker: null,
      alertPreference: null,
      alertfrequency: null,
      alertDuration: null,
      displayId: this.displayId,
    };
  },
  computed: {
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
    isSettingsPopUpShowing() {},
  },
  watch: {
    selectedWellBore(newValue) {
      console.log("newValue", newValue);

      this.wellNameChanged(newValue);
      // return newValue
    },
  },
  props: {
    selectedWell: { type: String, defalut: "" },
    selectedWellBore: { type: String, defalut: "" },
    displayId: { type: String },
  },

  methods: {

    gettingNewRecordKeyAndValuesBasedOnSelectionOfAlert() {
      let newRecord = {
        userid: [this.userId],
        communicationrequired: this.alertInfo.communicationRequired,
        communicationtype: this.organizationHierarchy.alertMode,
      };
      for (let eachAlert of this.whichWidgetsAreShown) {
        let keyWord = eachAlert.split("_")[0].toLowerCase();
        let noOfMsgs = `default${keyWord}alertsmaxnumberofmessages`;
        let duration = `default${keyWord}alertsduration`;
        newRecord[noOfMsgs] = this.alertInfo.alertFrequency;
        newRecord[duration] = this.alertInfo.alertDuration;
      }
      return newRecord;
    },

    getingModifiedCommunicationData(
      communicationData,
      matchedOneIndex,
      matchedObj,
      indexOfAlredyHaveingRecord,
      alredyHaveingRecordBasedOnUser
    ) {
      let modifiedCommunicationConfigData = [];
      // for (let eachObj of communicationData) {
      //   if (!matchedObj) {
      //     console.log("Alert---enter to undefined", matchedOneIndex);
      //     if (
      //       indexOfAlredyHaveingRecord !== communicationData.indexOf(eachObj)
      //     ) {
      //       console.log("Alert--- enter to inner of undefined");
      //       modifiedCommunicationConfigData.push(eachObj);
      //     }
      //   } else {
      //     console.log("Alert---enter to undefined else");
      //     if (alredyHaveingRecordBasedOnUser) {
      //       if (matchedOneIndex !== communicationData.indexOf(eachObj)) {
      //         modifiedCommunicationConfigData.push(eachObj);
      //         continue
      //       }else if (indexOfAlredyHaveingRecord !== communicationData.indexOf(eachObj)){
      //         modifiedCommunicationConfigData.push()
      //       }
      //     } else {
      //       console.log("Alert---entter to unknown");
      //       if (matchedOneIndex !== communicationData.indexOf(eachObj)) {
      //         modifiedCommunicationConfigData.push(eachObj);
      //       }
      //     }
      //   }
      // }
      if (matchedObj && !alredyHaveingRecordBasedOnUser) {
        modifiedCommunicationConfigData = communicationData.filter(
          (each) => each !== matchedObj
        );
        console.log(
          "Alert---modified commuconFig 1",
          modifiedCommunicationConfigData
        );
      } else if (alredyHaveingRecordBasedOnUser && matchedObj) {
        modifiedCommunicationConfigData = communicationData.filter(
          (each) => each !== matchedObj
        );
        modifiedCommunicationConfigData =
          modifiedCommunicationConfigData.filter(
            (each) => each !== alredyHaveingRecordBasedOnUser
          );
        console.log(
          "Alert---modified commuconFig 2",
          modifiedCommunicationConfigData
        );
      } else if (alredyHaveingRecordBasedOnUser && !matchedObj) {
        modifiedCommunicationConfigData = communicationData.filter(
          (each) => each !== alredyHaveingRecordBasedOnUser
        );
        console.log(
          "Alert---modified commuconFig 3",
          modifiedCommunicationConfigData
        );
      }
      return modifiedCommunicationConfigData;
    },
    toggleClicked() {
      console.log(
        "Alert---checked or not",
        this.alertInfo.communicationRequired
      );
    },



    async wellNameChanged() {
      console.log("geting res", this.selectedWell, this.selectedWellBore);
    },

    widgetclicked() {
      let dtls = this.$store.state.disp.displays[this.displayId];
      console.log("🚀 ➽ file: AlertScreen.vue:265  ➽ widgetclicked  ➽ dtls ⏩" , dtls)
      try {
        if(dtls.wellId && dtls.wellboreId){
        this.$emit("alertWidgetClicked");
      }else{
        this.$toast.error("please select well & well bore",{
            position: "top",
            duration: 1000,
          })
      }
      } catch (error) {
        console.log("🚀 ➽ file: AlertScreen.vue:275  ➽ widgetclicked  ➽ error ⏩" , error)
        this.$toast.error("please select well & well bore",{
            position: "top",
            duration: 1000,
          })
      }


    },
    onSelectingTheAlertType(tabNum) {
      console.log("alerts>>>>", tabNum);
      let isExistedTab = tabNum in this.storingObj;
      console.log("alerts clicking", isExistedTab);
      if (tabNum === "CRITICAL_CONDITION_ALERTS") {
        if (isExistedTab) {
          delete this.storingObj[tabNum];
        } else {
          this.storingObj[tabNum] = this.criticalAlerts;
          console.log("alerts pivotalExicuting", this.storingObj);
        }
      } else if (tabNum === "CRUCIAL_ALERTS") {
        if (isExistedTab) {
          delete this.storingObj[tabNum];
        } else {
          this.storingObj[tabNum] = this.crucialAlerts;
        }
      } else if (tabNum === "PIVOTAL_ALERTS") {
        if (isExistedTab) {
          delete this.storingObj[tabNum];
        } else {
          console.log("alerts entered");
          this.storingObj[tabNum] = [...this.pivotalAlerts];
        }
      } else if (tabNum === "all") {
        this.alerts = [
          ...this.pivotalAlerts,
          this.crucialAlerts,
          this.criticalAlerts,
        ];
      }
      let arrayForStoring = [];
      Object.keys(this.storingObj).map((each) => {
        arrayForStoring = [...arrayForStoring, ...this.storingObj[each]];
        console.log("alerts>>>>>>>", this.storingObj[each]);
      });
      this.alerts = arrayForStoring;
      console.log("alerts>>>", arrayForStoring);
    },
  },

  async mounted() {
    try {
      let details = this.$serviceHelpers.getDetails("apollodart");
      console.log("new---------details apollodart", details);
      this.userId = details.user.user_id;
      this.$store.state.disp.displayIdsAndNames.push({
        name: "alertScreen",
        Did: this.displayId,
      });
      console.log("Alert---displayId>>>>>", this.displayId);

      this.pivotalAlerts;
      let response = await apiService.AlertServices.gettingMiscellaneousList();
      let response_of_alert_alias = await apiService.AlertServices.gettingAlerts({type:'Alert_Alias'})
      response.data.map((each) => {
        if (each.type === "PIVOTAL_ALERTS") {
          this.pivotalAlerts = each.typeValues;
        } else if (each.type === "CRUCIAL_ALERTS") {
          this.crucialAlerts = each.typeValues;
        } else if (each.type === "CRITICAL_CONDITION_ALERTS") {
          this.criticalAlerts = each.typeValues;
        }
      });
      let alertAliasDist = {}
      if(response_of_alert_alias.status == 200){
        response_of_alert_alias.data.forEach(each => {
          alertAliasDist[each.split('-')[0]] = each.split('-')[1]
        })
      }else{
        console.error(response_of_alert_alias)
      }
      
      this.pivotalAlerts = this.pivotalAlerts.map((each) => { 
        return  alertAliasDist[each] ?  alertAliasDist[each] : each 
      });
      this.crucialAlerts = this.crucialAlerts.map((each) => {
        return  alertAliasDist[each] ?  alertAliasDist[each] : each 
      });
      this.criticalAlerts = this.criticalAlerts.map((each) => {
        return  alertAliasDist[each] ?  alertAliasDist[each] : each
      });
    } catch (error) {
      console.error(error)
    }

  },
};
</script>

<style scoped>
.AlertTimeHeading {
  /* font-size: 13px; */
  padding: 6px;
}
.dp__input {
  outline: none;
  width: 100%;
 font-size: min(0.9vw, 12px);
    font-weight: 600;
  line-height: 0.1rem;
  padding: 6px 0 6px 6px;
  /* color: #ffffff; */
  border: 0.5px solid #898989;
  /* text-align: center; */
  height: 20px;
  background: var(--colorBoxPopUpInputBg);
  color: var(--selectingTimeFeetColor);
  border: none;
  border-radius: 0;
}
.saveBtn {
  font-size: 11px;
  background-color: var(--activeTextColor);
  padding: 2px 16px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 11px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 9px;
  width: 9px;
  left: 3px;
  /* bottom: 4px; */
  top: 1px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(11px);
  -ms-transform: translateX(11px);
  transform: translateX(11px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.alertNav {
  background-color: var(--colorBoxPopUpInputBg);
}
.note-of-alerts{
  font-style:italic
}
</style>
