import Axios from "axios";
import constant from "../constant";
import api from "../dictionary/index";

export default {
    gettingAlerts: async (data) => {
        try {
            let response = await Axios.get(constant.DATASERVICES_URL + api.alerts.GETTING_ALERTS(), { params: data })
            return response
        } catch (error) {
            return error
        }
    },
    
    selectedWellAlertInfo: async (data) => {
        try {
            let response = await Axios.post(constant.DATASERVICES_URL + api.alerts.COMMUNICATION_CONFIG_SEARCH(), data)
            return response
        } catch (error) {
            return error
        }
    },
    saveTheWellAlertInfo: async (data) => {
        try {
            let response = await Axios.post(constant.DATASERVICES_URL + api.alerts.COMMUNICATION_CONFIG_SAVE_UPDATE(), data)
            return response
        } catch (error) {
            return error

        }
    },
    gettingCommunicationConfigList: async () => {
        try {
            let response = await Axios.get(constant.DATASERVICES_URL + api.alerts.COMMUNICATION_CONFIG_LIST())
            return response

        } catch (error) {
            return error

        }
    },
    gettingMiscellaneousList: async () => {
        try {
            let response = await Axios.get(constant.DATASERVICES_URL + api.alerts.MISCELLANEOUS_LIST())
            return response
        } catch (error) {
            return error
        }
    },
    listAnnotation: async () => {
        try {
            let alertAnno = await Axios.get(
                constant.DATASERVICES_URL + api.alerts.ANNOTATIONS_LIST()
            );
            return alertAnno;
        } catch (error) {
            console.error(error);
            return error;
        }
    },
    listNodeAnnotation: async () => {
        try {
            let alertAnno = await Axios.get(
                constant.BASE_URL + api.alerts.ANNOTATIONS()
            );
            return alertAnno;
        } catch (error) {
            console.error(error);
            return error;
        }
    },
    listDefaultAlerts: async () => {
        try {
            let alertsList = await Axios.get(
                constant.BASE_URL + api.alerts.ALERTS_LIST()
            );
            return alertsList;
        } catch (error) {
            console.error(error);
            return error;
        }
    },

}