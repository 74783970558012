module.exports = {
  ranges: [
    {
      "name": "ecdatcasingwithbinghamplasticmodel",
      "Standard Curve Description": "ECD @ CASING",
      "DISPLAY NAME": "ECD @ CASING",
      "Units": "lbf/galUS",
      "low": 8,
      "high": 16
    },
    {
      "name": "ecdatbitwithbinghamplasticmodel",
      "Standard Curve Description": "ECD @ BIT",
      "DISPLAY NAME": "ECD @ BIT",
      "Units": "lbf/galUS",
      "low": 7.5,
      "high": 16
    },
    {
      "name": "cuttingtransportefficiency",
      "Standard Curve Description": "OVERALL TE",
      "DISPLAY NAME": "OVERALL TE",
      "Units": "%",
      "low": 0,
      "high": 100
    },
    {
      "name": "drillcuttingconcentration",
      "Standard Curve Description": "CUTTING CON",
      "DISPLAY NAME": "CUTTING CON",
      "Units": "%",
      "low": 0,
      "high": 100
    },
    {
      "name": "stickslipindex",
      "Standard Curve Description": "stick slip index",
      "DISPLAY NAME": "stick slip index",
      "Units": "unitless",
      "low": 0,
      "high": 1
    },
    {
      "name": "depthofcut",
      "Standard Curve Description": "depth of cut",
      "DISPLAY NAME": "depth of cut",
      "Units": "unitless",
      "low": 0,
      "high": 1
    },
    {
      "name": "simplifiedmse",
      "Standard Curve Description": "depth of cut",
      "DISPLAY NAME": "depth of cut",
      "Units": "unitless",
      "low": 0,
      "high": 500000
    },
    {
      "name": "gainloss",
      "Standard Curve Description": "Gain Loss",
      "DISPLAY NAME": "Gain Loss",
      "Units": "bbls",
      "low": -100,
      "high": 100
    },
    {
      "name": "azimuth",
      "Standard Curve Description": "Survey - Azimuth",
      "DISPLAY NAME": "AZI",
      "Units": "dega",
      "low": 0,
      "high": 360
    },
    {
      "name": "bitor",
      "Standard Curve Description": "Bit Torque",
      "DISPLAY NAME": "BIT TOR",
      "Units": "kft.lbf",
      "low": 0,
      "high": 50
    },
    {
      "name": "bpos",
      "Standard Curve Description": "Block Position",
      "DISPLAY NAME": "BLOCK POS",
      "Units": "ft",
      "low": -5,
      "high": 200
    },
    {
      "name": "bit rpm",
      "Standard Curve Description": "Bit RPM",
      "DISPLAY NAME": "BIT RPM",
      "Units": "rpm",
      "low": 0,
      "high": 600
    },
    {
      "name": "bit size",
      "Standard Curve Description": "Bit size",
      "DISPLAY NAME": "Bit Size",
      "Units": "in",
      "low": 0,
      "high": 40
    },
    {
      "name": "bit tvd - wits",
      "Standard Curve Description": "Bit tvd-wits",
      "DISPLAY NAME": "Bit TVD WITS",
      "Units": "ft",
      "low": 0,
      "high": 10000
    },
    {
      "name": "ann_pressure",
      "Standard Curve Description": "Annular Pressure",
      "DISPLAY NAME": "ANN PRESS",
      "Units": "psi",
      "low": 0,
      "high": 3000
    },
    {
      "name": "cirh",
      "Standard Curve Description": "Circulating Hours",
      "DISPLAY NAME": "CIR HRS",
      "Units": "H",
      "low": 0,
      "high": 96
    },
    {
      "name": "dbtm",
      "Standard Curve Description": "Bit Depth (measured)",
      "DISPLAY NAME": "BIT DEPTH",
      "Units": "ft",
      "low": 0,
      "high": 20000
    },
    {
      "name": "diff",
      "Standard Curve Description": "Differential Pressure",
      "DISPLAY NAME": "DIFF PRESS",
      "Units": "psi",
      "low": 0,
      "high": 4500
    },
    {
      "name": "dmea",
      "Standard Curve Description": "Hole Depth (measured)",
      "DISPLAY NAME": "HOLE DEPTH",
      "Units": "ft",
      "low": 0,
      "high": 20000
    },
    {
      "name": "dls",
      "Standard Curve Description": "Survey Dogleg Severity",
      "DISPLAY NAME": "DLS",
      "Units": "dega/100ft",
      "low": 0,
      "high": 15
    },
    {
      "name": "mud density",
      "Standard Curve Description": "Mud Weight In",
      "DISPLAY NAME": "MW IN",
      "Units": "ppg",
      "low": 7,
      "high": 16
    },
    {
      "name": "mud density out",
      "Standard Curve Description": "Mud Weight Out",
      "DISPLAY NAME": "MW OUT",
      "Units": "ppg",
      "low": 7,
      "high": 16
    },
    {
      "name": "flow_out",
      "Standard Curve Description": "Flow out ",
      "DISPLAY NAME": "FLOW OUT",
      "Units": "%",
      "low": 0,
      "high": 100
    },
    {
      "name": "gamma_ray",
      "Standard Curve Description": "Gamma Ray",
      "DISPLAY NAME": "GAMMA RAY",
      "Units": "gAPI",
      "low": 0,
      "high": 300
    },
    {
      "name": "gas_total",
      "Standard Curve Description": "Total gas",
      "DISPLAY NAME": "TOTAL GAS",
      "Units": "%",
      "low": 0,
      "high": 100
    },
    {
      "name": "hkl",
      "Standard Curve Description": "Hook Load",
      "DISPLAY NAME": "HOOK LOAD",
      "Units": "KLBF",
      "low": 0,
      "high": 300
    },
    {
      "name": "wob",
      "Standard Curve Description": "Weight on Bit",
      "DISPLAY NAME": "WOB",
      "Units": "KLBF",
      "low": 0,
      "high": 50
    },
    {
      "name": "inclination",
      "Standard Curve Description": "Survey - Inclination",
      "DISPLAY NAME": "INC",
      "Units": "dega",
      "low": 0,
      "high": 90
    },
    {
      "name": "lag_dpt_md",
      "Standard Curve Description": "Lag Depth",
      "DISPLAY NAME": "LAG DEPTH",
      "Units": "ft",
      "low": 0,
      "high": 10000
    },
    {
      "name": "tmv",
      "Standard Curve Description": "Total Mud Volume",
      "DISPLAY NAME": "TOT MUD VOL",
      "Units": "bbl",
      "low": 0,
      "high": 1000
    },
    {
      "name": "tt_vol",
      "Standard Curve Description": "Total TTK Volume",
      "DISPLAY NAME": "TOT TTK VOL",
      "Units": "bbl",
      "low": 0,
      "high": 100
    },
    {
      "name": "tt1_vol",
      "Standard Curve Description": "Trip Tank 1 Volume",
      "DISPLAY NAME": "TTK1 VOL",
      "Units": "bbl",
      "low": 0,
      "high": 50
    },
    {
      "name": "tt2_vol",
      "Standard Curve Description": "Trip Tank 2 Volume",
      "DISPLAY NAME": "TTK2 VOL",
      "Units": "bbl",
      "low": 0,
      "high": 50
    },
    {
      "name": "mfi",
      "Standard Curve Description": "Mud Flow In",
      "DISPLAY NAME": "MUD FLOW IN",
      "Units": "gpm",
      "low": 0,
      "high": 1000
    },
    {
      "name": "obh",
      "Standard Curve Description": "On Bottom Hours",
      "DISPLAY NAME": "OB HOURS",
      "Units": "h",
      "low": 0,
      "high": 100
    },
    {
      "name": "obr",
      "Standard Curve Description": "On Bottom ROP",
      "DISPLAY NAME": "OB ROP",
      "Units": "ft/h",
      "low": 0,
      "high": 500
    },
    {
      "name": "op",
      "Standard Curve Description": "Overpull",
      "DISPLAY NAME": "OVERPULL",
      "Units": "klbf",
      "low": 0,
      "high": 20
    },
    {
      "name": "pcas",
      "Standard Curve Description": "Casing Pressure",
      "DISPLAY NAME": "CASING PRESS",
      "Units": "psi",
      "low": 0,
      "high": 5000
    },
    {
      "name": "rop",
      "Standard Curve Description": "Rate Of Penetration",
      "DISPLAY NAME": "ROP",
      "Units": "ft/h",
      "low": 0,
      "high": 500
    },
    {
      "name": "rpm",
      "Standard Curve Description": "Rotation per Minute",
      "DISPLAY NAME": "RPM",
      "Units": "rpm",
      "low": 0,
      "high": 200
    },
    {
      "name": "tot_spm",
      "Standard Curve Description": "Total SPM",
      "DISPLAY NAME": "TOTAL SPM",
      "Units": "spm",
      "low": 0,
      "high": 250
    },
    {
      "name": "spm1",
      "Standard Curve Description": "Pump 1 strokes/min",
      "DISPLAY NAME": "SPM1",
      "Units": "spm",
      "low": 0,
      "high": 110
    },
    {
      "name": "spm2",
      "Standard Curve Description": "Pump 2 strokes/min",
      "DISPLAY NAME": "SPM2",
      "Units": "spm",
      "low": 0,
      "high": 120
    },
    {
      "name": "spm3",
      "Standard Curve Description": "Pump 3 strokes/min",
      "DISPLAY NAME": "SPM3",
      "Units": "spm",
      "low": 0,
      "high": 130
    },
    {
      "name": "spp",
      "Standard Curve Description": "Standpipe Pressure",
      "DISPLAY NAME": "SPP",
      "Units": "psi",
      "low": 0,
      "high": 5000
    },
    {
      "name": "tf",
      "Standard Curve Description": "Tool Face",
      "DISPLAY NAME": "TOOL FACE",
      "Units": "dega",
      "low": 0,
      "high": 360
    },
    {
      "name": "time",
      "Standard Curve Description": "Time of measurement",
      "DISPLAY NAME": "TIME",
      "Units": "s",
      "low": 0
    },
    {
      "name": "tor",
      "Standard Curve Description": "Torque",
      "DISPLAY NAME": "TORQUE",
      "Units": "K FT.LBF",
      "low": 0,
      "high": 50
    },
    {
      "name": "temp_in",
      "Standard Curve Description": "Mud Temp In",
      "DISPLAY NAME": "TEMP IN",
      "Units": "degF",
      "low": 0,
      "high": 500
    },
    {
      "name": "temp_out",
      "Standard Curve Description": "Mud Temp Out",
      "DISPLAY NAME": "TEMP OUT",
      "Units": "degF",
      "low": 0,
      "high": 350
    },
    {
      "name": "ts1",
      "Standard Curve Description": "Pump 1 total strokes",
      "DISPLAY NAME": "PUMP 1 TOT STKS",
      "Units": "stks",
      "low": 0,
      "high": 5000
    },
    {
      "name": "ts2",
      "Standard Curve Description": "Pump 2 total strokes",
      "DISPLAY NAME": "PUMP 2 TOTSTKS",
      "Units": "stks",
      "low": 0,
      "high": 5000
    },
    {
      "name": "ts3",
      "Standard Curve Description": "Pump 3 total strokes",
      "DISPLAY NAME": "PUMP 3 TOT STKS",
      "Units": "stks",
      "low": 0,
      "high": 5000
    },
    {
      "name": "ts4",
      "Standard Curve Description": "Pump 4 total strokes",
      "DISPLAY NAME": "PUMP 4 TOT STKS",
      "Units": "stks",
      "low": 0,
      "high": 5000
    },
    {
      "name": "tot_stk",
      "Standard Curve Description": "Total Pump Strokes",
      "DISPLAY NAME": " TOT PUMP STKS",
      "Units": "stks",
      "low": 0,
      "high": 25000
    },
    {
      "name": "tvd",
      "Standard Curve Description": "TVD",
      "DISPLAY NAME": "TVD",
      "Units": "ft",
      "low": 0,
      "high": 10000
    },
    {
      "name": "tvd - wits",
      "Standard Curve Description": "TVD - WITS",
      "DISPLAY NAME": "TVD - WITS",
      "Units": "ft",
      "low": 0,
      "high": 7000
    },
    {
      "name": "tv1",
      "Standard Curve Description": "Mud Tank 1 Volume",
      "DISPLAY NAME": "MUD TANK 1 VOL",
      "Units": "bbl",
      "low": 0,
      "high": 500
    },
    {
      "name": "tv2",
      "Standard Curve Description": "Mud Tank 2 Volume",
      "DISPLAY NAME": "MUD TANK 2 VOL",
      "Units": "bbl",
      "low": 0,
      "high": 500
    },
    {
      "name": "tv3",
      "Standard Curve Description": "Mud Tank 3 Volume",
      "DISPLAY NAME": "MUD TANK 3 VOL",
      "Units": "bbl",
      "low": 0,
      "high": 500
    },
    {
      "name": "tv4",
      "Standard Curve Description": "Mud Tank 4 Volume",
      "DISPLAY NAME": "MUD TANK 4 VOL",
      "Units": "bbl",
      "low": 0,
      "high": 500
    },
    {
      "name": "tv5",
      "Standard Curve Description": "Mud Tank 5 Volume",
      "DISPLAY NAME": "MUD TANK 5 VOL",
      "Units": "bbl",
      "low": 0,
      "high": 500
    },
    {
      "name": "tv6",
      "Standard Curve Description": "Mud Tank 6 Volume",
      "DISPLAY NAME": "MUD TANK 6 VOL",
      "Units": "bbl",
      "low": 0,
      "high": 500
    },
    {
      "name": "tv7",
      "Standard Curve Description": "Mud Tank 7 Volume",
      "DISPLAY NAME": "MUD TANK 7 VOL",
      "Units": "bbl",
      "low": 0,
      "high": 500
    },
    {
      "name": "tv8",
      "Standard Curve Description": "Mud Tank 8 Volume",
      "DISPLAY NAME": "MUD TANK 8 VOL",
      "Units": "bbl",
      "low": 0,
      "high": 500
    },
    {
      "name": "tv9",
      "Standard Curve Description": "Mud Tank 9 Volume",
      "DISPLAY NAME": "MUD TANK 9 VOL",
      "Units": "bbl",
      "low": 0,
      "high": 500
    },
    {
      "name": "tv10",
      "Standard Curve Description": "Mud Tank 10 Volume",
      "DISPLAY NAME": "MUD TANK 10 VOL",
      "Units": "bbl",
      "low": 0,
      "high": 500
    },
    {
      "name": "tv11",
      "Standard Curve Description": "Mud Tank 11 Volume",
      "DISPLAY NAME": "MUD TANK 11 VOL",
      "Units": "bbl",
      "low": 0,
      "high": 500
    },
    {
      "name": "tv12",
      "Standard Curve Description": "Mud Tank 12 Volume",
      "DISPLAY NAME": "MUD TANK 12 VOL",
      "Units": "bbl",
      "low": 0,
      "high": 500
    },
    {
      "name": "tv13",
      "Standard Curve Description": "Mud Tank 13 Volume",
      "DISPLAY NAME": "MUD TANK 13 VOL",
      "Units": "bbl",
      "low": 0,
      "high": 500
    },
    {
      "name": "tv14",
      "Standard Curve Description": "Mud Tank 14 Volume",
      "DISPLAY NAME": "MUD TANK 14 VOL",
      "Units": "bbl",
      "low": 0,
      "high": 500
    },
    {
      "name": "tv15",
      "Standard Curve Description": "Mud Tank 15 Volume",
      "DISPLAY NAME": "MUD TANK 15 VOL",
      "Units": "bbl",
      "low": 0,
      "high": 500
    }
  ],
};



