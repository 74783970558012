<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

import api from "./api/services";
import { useRoute } from "vue-router";
import darklogoImage from "./assets/img/apollo_logo_light.png"
import lightlogoImage from "./assets/img/apollo_logo.png"
// import FooterAdmin from "./components/Footers/FooterAdmin.vue";
export default {
  components: {

  },
  data() {
    return {
      isAuth: true,
      isAuth: true,
      deferredPrompt: null,
      close: true,
      title: "Apollodart",
      title: "Apollodart",
      isLoading: false,
      installBtn: "none",
      installer: undefined,
      darkDark: "grey",
      root: null,
    };
  },
  watch: {
    darkDark: {
      handler: function () {
        if (this.darkDark === 'white') {
          this.root.style.setProperty("--defaultBorderColorForWidgets", "#000");
          this.root.style.setProperty("--verticalBarScaleRectBg", "#f5f5f5");
          this.root.style.setProperty("--transition", "background 3s ease, color 0.5s ease");
          this.root.style.setProperty("--popupBg", 'rgba(70, 69, 69, 0.86)');
          this.root.style.setProperty("--fontWeight", 600);
          this.root.style.setProperty("--central_bg", "#ffffffe6");
          this.root.style.setProperty("--nav1HdngClr", "#073099");
          this.root.style.setProperty("--nav1HdngClrsame", "#A9A9A9");
          this.root.style.setProperty("--nav1BgClr", "#000000");
          this.root.style.setProperty("--nav1BgClrsame", "#D0D5E3");
          this.root.style.setProperty("--root_nav", "#F5F5F5");
          this.root.style.setProperty("--navBar2Bg", "#FFFFF7");
          this.root.style.setProperty("--navBar2Bgsame", "#eef1f5");
          this.root.style.setProperty("--wdigetBg", "#FFFFF7");
          this.root.style.setProperty("--textColor", "#000000");
          this.root.style.setProperty("--activeTextColor", "#073099");
          this.root.style.setProperty("--sidebarbg", "#fff");
          this.root.style.setProperty("--sidebarMnList", "#2a2a2a");
          this.root.style.setProperty("--sidebarButtonbg", "#2a2a2a");
          this.root.style.setProperty("--headingColor", "#073099");
          this.root.style.setProperty("--sidebarListBgColor", "#F5F5F5");
          this.root.style.setProperty("--sidebarListColor", "#073099");
          this.root.style.setProperty("--colorBoxBg", "#EEF1F5");
          this.root.style.setProperty("--colorBoxHeadingText", "#000000");
          this.root.style.setProperty("--colorBoxHeadBackround", "#fdffe5");
          this.root.style.setProperty("--colorBoxWhiteNum", "#000000");
          this.root.style.setProperty("--colorBoxPopUpBg", "#fff");
          this.root.style.setProperty("--colorBoxPopUpHeadingColor", "#3C455C");
          this.root.style.setProperty("--colorBoxPopUpInputBg", "#EEF1F5");
          this.root.style.setProperty("--colorBoxPopUpDeleteBg", "#B4B5BA");
          this.root.style.setProperty("--colorBoxPopUpSaveBg", "#073099");
          this.root.style.setProperty("--colorBoxPopUpDeleteText", "#fff");
          this.root.style.setProperty("--dropDownBg", "#EEF1F5");
          this.root.style.setProperty("--searchBarBg", "#EEF1F5");
          this.root.style.setProperty("--rightTwobars", "#3C455C");
          this.root.style.setProperty("--deleteDragRizeWindowBg", "#2a2a2a");
          this.root.style.setProperty("--deleteDragRizeWindowBgColor", "#F5F5F5");
          this.root.style.setProperty("--selectionWellPopupBg", "#fff");
          this.root.style.setProperty("--selectingTimeFeetColor", "#3C455C");
          this.root.style.setProperty("--selectingTimeFeetColorBg", "#3f3f3f");
          this.root.style.setProperty("--selectingTimeFeetColorBgActive", "#073099");
          this.root.style.setProperty("--botoomMinimaizerBodyBg", "#D0D5E3");
          this.root.style.setProperty("--gridLinesColor", "#E5E5E5");
          this.root.style.setProperty("--TandDCorrelationLeftBarBg", "#fff");
          this.root.style.setProperty("--toggleSliderBg", "#B4B5BA");
          this.root.style.setProperty("--liveBtnGreen", "#12B822");
          this.root.style.setProperty("--defalutHamburger", "#6C7593");
          this.root.style.setProperty("--activeHamburger", "#073099");
          this.root.style.setProperty("--searchColor", "#6C7593");
          this.root.style.setProperty("--rightBarSelecteleBg", "#F5F5F5");
          this.root.style.setProperty("--dataTableOddRowBg", "#FFFFF7");
          this.root.style.setProperty("--dataTableEvenRowBg", "#f5f5f5");
          this.root.style.setProperty("--logoImage", lightlogoImage);
          this.root.style.setProperty("--defaultBgOfBgWidget", "#d8d8ed");
          this.root.style.setProperty("--defaultBgOfRadialWidget", "#fff");
          this.root.style.setProperty("--activeCustomerBg", "#d0d5e3");
          this.root.style.setProperty("--lwdGraphBg", "#fff");
          this.root.style.setProperty("--activeFeetBtnText", "#fff");
        } else if (this.darkDark === 'grey') {
          this.root.style.setProperty("--defaultBorderColorForWidgets", "#fff");
          this.root.style.setProperty("--verticalBarScaleRectBg", "#fff");
          this.root.style.setProperty("--popupBg", 'rgba(142, 137, 137, 0.86)');
          this.root.style.setProperty("--fontWeight", 300);
          this.root.style.setProperty("--central_bg", "#2a2a2a");
          this.root.style.setProperty("--nav1HdngClr", "#A9A9A9");
          this.root.style.setProperty("--nav1HdngClrsame", "#A9A9A9");
          this.root.style.setProperty("--activeTextColor", "#63CDFF");
          this.root.style.setProperty("--nav1BgClr", "#2a2a2a");
          this.root.style.setProperty("--nav1BgClrsame", "#2a2a2a");
          this.root.style.setProperty("--root_nav", "#545454");
          this.root.style.setProperty("--navBar2Bg", "#3f3f3f");
          this.root.style.setProperty("--navBar2Bgsame", "#3f3f3f");
          this.root.style.setProperty("--wdigetBg", "#3f3f3f");
          this.root.style.setProperty("--textColor", "#ffffff");
          this.root.style.setProperty("--sidebarbg", "#3f3f3f");
          this.root.style.setProperty("--sidebarMnList", "#3f3f3f");
          this.root.style.setProperty("--sidebarButtonbg", "#2a2a2a");
          this.root.style.setProperty("--headingColor", "#B4B5BA");
          this.root.style.setProperty("--sidebarListBgColor", "#2a2a2a");
          this.root.style.setProperty("--sidebarListColor", "#c3c3c3");
          this.root.style.setProperty("--colorBoxBg", "#2a2a2a");
          this.root.style.setProperty("--colorBoxHeadingText", "#cfd6e1");
          this.root.style.setProperty("--colorBoxHeadBackround", "#1e293b");
          this.root.style.setProperty("--colorBoxWhiteNum", "#ffffff");
          this.root.style.setProperty("--colorBoxPopUpBg", "#414244");
          this.root.style.setProperty("--colorBoxPopUpHeadingColor", "#fff");
          this.root.style.setProperty("--colorBoxPopUpInputBg", "#2a2a2a");
          this.root.style.setProperty("--colorBoxPopUpDeleteBg", "#393939");
          this.root.style.setProperty("--colorBoxPopUpSaveBg", "#555");
          this.root.style.setProperty("--colorBoxPopUpDeleteText", "#B4B8BE");
          this.root.style.setProperty("--dropDownBg", "#2a2a2a");
          this.root.style.setProperty("--searchBarBg", "#000");
          this.root.style.setProperty("--rightTwobars", "#777");
          this.root.style.setProperty("--deleteDragRizeWindowBg", "#E5E5E5");
          this.root.style.setProperty(
            "--deleteDragRizeWindowBgColor",
            "#2a2a2a"
          );
          this.root.style.setProperty("--selectionWellPopupBg", "#2a2a2a");
          this.root.style.setProperty("--selectingTimeFeetColor", "#B4B8BE");
          this.root.style.setProperty("--selectingTimeFeetColorBg", "#414244");
          this.root.style.setProperty(
            "--selectingTimeFeetColorBgActive",
            "#63CDFF"
          );
          this.root.style.setProperty("--botoomMinimaizerBodyBg", "#303030");
          this.root.style.setProperty("--gridLinesColor", "#414244");
          this.root.style.setProperty("--TandDCorrelationLeftBarBg", "#383838");
          this.root.style.setProperty("--toggleSliderBg", "#B4B5BA");
          this.root.style.setProperty("--liveBtnGreen", "#12B822");
          this.root.style.setProperty("--defalutHamburger", "#B4B5BA");
          this.root.style.setProperty("--activeHamburger", "#fff");
          this.root.style.setProperty("--searchColor", "#dad7d7");
          this.root.style.setProperty("--rightBarSelecteleBg", "#4A4E57");
          this.root.style.setProperty("--dataTableOddRowBg", "#1e293b");
          this.root.style.setProperty("--dataTableEvenRowBg", "#3f3f3f");
          this.root.style.setProperty("--logoImage", darklogoImage);
          this.root.style.setProperty("--defaultBgOfBgWidget", "#919193");
          this.root.style.setProperty("--defaultBgOfRadialWidget", "#19181b");
          this.root.style.setProperty("--activeCustomerBg", "#3f3f3f");
          this.root.style.setProperty("--lwdGraphBg", "#fff");
          this.root.style.setProperty("--activeFeetBtnText", "#fff");

          // selectingTimeFeetColorBg
          // selectingTimeFeetColorBgActive
          // TandDCorrelationLeftBarBg
          // toggleSliderBg
        } else if (this.darkDark === 'blue') {
          this.root.style.setProperty("--defaultBorderColorForWidgets", "#fff");
          this.root.style.setProperty("--verticalBarScaleRectBg", "#fff");
          this.root.style.setProperty("--popupBg", 'rgba(142, 137, 137, 0.86)');
          this.root.style.setProperty("--inactivePopBg", "#fff");
          this.root.style.setProperty("--lwdGraphBg", "#fff");
          this.root.style.setProperty("--fontWeight", 300);
          this.root.style.setProperty("--central_bg", "#0f172a");
          this.root.style.setProperty("--nav1HdngClr", "#A9A9A9");
          this.root.style.setProperty("--nav1HdngClrsame", "#A9A9A9");
          this.root.style.setProperty("--activeTextColor", "#63CDFF");
          this.root.style.setProperty("--nav1BgClr", "#0f172a");
          this.root.style.setProperty("--nav1BgClrsame", "#0f172a");
          this.root.style.setProperty("--root_nav", "#334155");
          this.root.style.setProperty("--navBar2Bg", "#1e293b");
          this.root.style.setProperty("--navBar2Bgsame", "#1e293b");
          this.root.style.setProperty("--wdigetBg", "#1e293b");
          this.root.style.setProperty("--textColor", "#ffffff");
          this.root.style.setProperty("--sidebarbg", "#334155");
          this.root.style.setProperty("--sidebarMnList", "#334155");
          this.root.style.setProperty("--sidebarButtonbg", "#0f172a");
          this.root.style.setProperty("--headingColor", "#B4B5BA");
          this.root.style.setProperty("--sidebarListBgColor", "#0f172a");
          this.root.style.setProperty("--sidebarListColor", "#c3c3c3");
          this.root.style.setProperty("--colorBoxBg", "#334155");
          this.root.style.setProperty("--colorBoxHeadingText", "#ffffff");
          this.root.style.setProperty("--colorBoxHeadBackround", "#1e293b");
          this.root.style.setProperty("--colorBoxWhiteNum", "#ffffff");
          this.root.style.setProperty("--colorBoxPopUpBg", "#414244");
          this.root.style.setProperty("--colorBoxPopUpHeadingColor", "#fff");
          this.root.style.setProperty("--colorBoxPopUpInputBg", "#0f172a");
          this.root.style.setProperty("--colorBoxPopUpDeleteBg", "#393939");
          this.root.style.setProperty("--colorBoxPopUpSaveBg", "#555");
          this.root.style.setProperty("--colorBoxPopUpDeleteText", "#B4B8BE");
          this.root.style.setProperty("--dropDownBg", "#0f172a");
          this.root.style.setProperty("--searchBarBg", "#000");
          this.root.style.setProperty("--rightTwobars", "#777");
          this.root.style.setProperty("--deleteDragRizeWindowBg", "#E5E5E5");
          this.root.style.setProperty(
            "--deleteDragRizeWindowBgColor",
            "#0f172a"
          );
          this.root.style.setProperty("--selectionWellPopupBg", "#0f172a");
          this.root.style.setProperty("--selectingTimeFeetColor", "#B4B8BE");
          this.root.style.setProperty("--selectingTimeFeetColorBg", "#414244");
          this.root.style.setProperty(
            "--selectingTimeFeetColorBgActive",
            "#0f172a"
          );
          this.root.style.setProperty("--botoomMinimaizerBodyBg", "#303030");
          this.root.style.setProperty("--gridLinesColor", "#414244");
          this.root.style.setProperty("--TandDCorrelationLeftBarBg", "#383838");
          this.root.style.setProperty("--toggleSliderBg", "#B4B5BA");
          this.root.style.setProperty("--liveBtnGreen", "#12B822");
          this.root.style.setProperty("--defalutHamburger", "#B4B5BA");
          this.root.style.setProperty("--activeHamburger", "#fff");
          this.root.style.setProperty("--searchColor", "#B4B5BA");
          this.root.style.setProperty("--rightBarSelecteleBg", "#4A4E57");
          this.root.style.setProperty("--dataTableOddRowBg", "#1e293b");
          this.root.style.setProperty("--dataTableEvenRowBg", "#33415");
          this.root.style.setProperty("--defaultBgOfBgWidget", "#919193");
          this.root.style.setProperty("--defaultBgOfRadialWidget", "#19181b");
          this.root.style.setProperty("--activeCustomerBg", "#1e293b");
          this.root.style.setProperty("--dataTableEvenRowBg", "#334155");
          this.root.style.setProperty("--inactivePopBg", "#0f172a");
          this.root.style.setProperty("--activeFeetBtnText", "#fff");



          // selectingTimeFeetColorBg
          // selectingTimeFeetColorBgActive
          // TandDCorrelationLeftBarBg
          // toggleSliderBg
        }
      },
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.fullPath.includes("auth") || this.$route.fullPath.includes("support") ? false : true;
    },
  },
  created() {
    // window.onbeforeunload = function(){
    //        return "handle your events or msgs here";
    // }
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
      this.installBtn = "block";
    });
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
    this.installer = () => {
      this.installBtn = "none";
      installPrompt.prompt();
      installPrompt.userChoise.then((result) => {
        if (result.outcome === "accepted") {
        } else {
        }
        installPrompt = null;
      });
    };
  },
  methods: {
    removeUser() {
      alert("Sure")
    },
    async onTokenLogin(accessToken, refreshToken, customer, scope) {
      var authData = {
        accessToken,
        refreshToken,
        customer,
        scope,
      };
      try {
        let login = await api.AuthServices.tokenLogin(authData);
        if (login.status == 200) {
          this.$router.push("/app");
          this.$toast.success("Welcome to Apollo", {
            position: "top",
            duration: 1000,
          });
          // window.location.replace("/app")
        } else {
          this.errcode = login.data;
          this.$router.push("/auth/login");
        }
      } catch (error) {
        this.$router.push("/auth/login");
      }
    },
    async dismiss() {
      this.deferredPrompt = null;
      this.close == false;
    },
    async install() {
      this.deferredPrompt.prompt();
    },
    async open() {
      this.deferredPrompt.prompt();
    },
    closeDownload() {
      this.close = !this.close;
    },
  },
  // watch: {
  // title() {
  //   document.title = 'Apollodart'
  // },
  // },
  mounted() {
    document.title = "Apollodart";
    this.root = document.querySelector(":root");
    this.$store.subscribe((mutation, state) => {
      if (mutation.type == "rect/DARK_MODE") {
        this.darkDark = this.$store.state.rect.darkmode;
      }
    });
  },

};
</script>
<style >
/* @import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap'); */
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&family=Work+Sans:wght@600&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400&display=swap');

#app {
  background: #04070e;
  box-sizing: border-box;
  width: 100%;
  /* font-family: "Poppins", sans-serif;
  font-family: "Work Sans", sans-serif;
  font-family: "Open Sans", sans-serif; */
  /* font-family: 'Ubuntu', sans-serif; */
  font-family: 'Work Sans', sans-serif;
  /* 'Ubuntu', sans-serif; */
  overflow: hidden;
}

* {
  /* font-family: "Open Sans", sans-serif; */
  /* font-family: 'Ubuntu', sans-serif; */
  font-family: 'Work Sans', sans-serif;
  /* 'Ubuntu', sans-serif; */
}

#color1 {
  -webkit-appearance: none;
  padding: 0;
  border: none;
  border-radius: 10px;
  width: 20px;
  height: 20px;
}

#color1::-webkit-color-swatch {
  border: none;
  border-radius: 10px;
  padding: 0;
}

#color1::-webkit-color-swatch-wrapper {
  border: none;
  border-radius: 10px;
  padding: 0;
}

@media only screen and (max-device-width: 667px) and (min-device-width: 320px) and (orientation: landscape) {
  #app {
    display: block;
  }
}

.close_button {
  width: 9%;
  position: absolute;
  top: 5px;
  right: 0;
  font-size: 9px;
  padding: 1px;
}

.bg_install_btns {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
  font-size: 9px;
}

.download_logo {
  width: 30%;
  margin: 10px auto;
}

/* root for colors */
:root {
  --activeFeetBtnText:#fff;
  --defaultBorderColorForWidgets:#fff;
  --verticalBarScaleRectBg:#fff;
  --popupBg: rgba(142, 137, 137, 0.86);
  --activeCustomerBg: #3f3f3f;
  --nav1HdngClrsame: #A9A9A9;
  --navBar2Bgsame: #3f3f3f;
  --nav1BgClrsame: #2a2a2a;
  --navBar2Bg: #3f3f3f;
  --wdigetBg: #3f3f3f;
  --nav1BgClr: #2a2a2a;
  --root_nav: #545454;
  --nav1HdngClr: #a9a9a9;
  --textColor: #ffffff;
  --activeTextColor: #63cdff;
  --headingColor: #b4b5ba;
  --menuListColor: #c3c3c3;
  /* --sidebarbg: #2a2a2a; */
  --sidebarbg: #3f3f3f;
  --sidebarMnList: #3f3f3f;
  --sidebarButtonbg: #2a2a2a;
  --sidebarListColor: #c3c3c3;
  --sidebarListBgColor: #2a2a2a;
  --colorBoxBg: #2a2a2a;
  --central_bg: #2a2a2a;
  --colorBoxHeadingText: #cfd6e1;
  --colorBoxHeadBackround: #1e293b;
  --colorBoxWhiteNum: #ffffff;
  --colorBoxPopUpBg: #414244;
  --colorBoxPopUpHeadingColor: #fff;
  --colorBoxPopUpInputBg: #2a2a2a;
  --colorBoxPopUpDeleteBg: #393939;
  --colorBoxPopUpSaveBg: #555555;
  --colorBoxPopUpDeleteText: #b4b8be;
  --dropDownBg: #2a2a2a;
  --numericWidget: #414244;
  --numericValue: #fff;

  --rightSideBarOptions: #b4b8be;
  --rightSideBarOptionsSvgPath: #727272;
  --rightSideBarCloseBtn: #b4b5ba;
  --searchBarBg: #000;
  --rightTwobars: #777;
  --sideBarPopUpBg: #e5e5e5;
  --sideBarPopUpColor: #2a2a2a;
  --deleteDragRizeWindowBg: #e5e5e5;
  --deleteDragRizeWindowBgColor: #2a2a2a;
  --selectionWellPopupBg: #2a2a2a;
  --selectingTimeFeetColor: #b4b8be;
  /* --selectingTimeFeetColorBg: #3f3f3f; */
  --selectingTimeFeetColorBg: #414244;
  --selectingTimeFeetColorBgActive: #63cdff;
  /* --botoomMinimaizerBodyBg: #2a2a2a; */
  --botoomMinimaizerBodyBg: #303030;
  --gridLinesColor: #414244;
  --TandDCorrelationLeftBarBg: #383838;
  --toggleSliderBg: #b4b5ba;
  /* --liveBtnGreen:#32ff46; */
  --liveBtnGreen: #12b822;
  --defalutHamburger: #b4b5ba;
  --activeHamburger: #fff;
  --searchColor: #dad7d7;
  --rightBarSelecteleBg: #4a4e57;
  --dataTableOddRowBg: #1e293b;
  --dataTableEvenRowBg: #3f3f3f;
  --fontWeight: 300;
  --lwdGraphBg: #fff;
  --transition: background 3s ease, color 0.5s ease;

  --logoImage: darklogoImage;
  --defaultBgOfBgWidget: #919193;
  --defaultBgOfRadialWidget: #19181b;
  --higherZIndex: 10000
}

/* poppins */
@font-face {
  font-family: "poppins";
  font-style: normal;
  font-weight: 600;
  src: url(./assets/font/Poppins/Poppins-ExtraBold.ttf) format("woff");
}

/* open Sans */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"), local("OpenSans-Light"),
    url(./assets/font/Open_Sans/static/OpenSans/OpenSans-Light.ttf) format("woff");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), local("OpenSans"),
    url(./assets/font/Open_Sans/static/OpenSans/OpenSans-Regular.ttf) format("woff");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans Semibold"), local("OpenSans-Semibold"),
    url(./assets/font/Open_Sans/static/OpenSans/OpenSans-SemiBold.ttf) format("woff");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: bold;
  src: local("Open Sans Bold"), local("OpenSans-Bold"),
    url(./assets/font/Open_Sans/static/OpenSans/OpenSans-Bold.ttf) format("woff");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans Bold"), local("OpenSans-Bold"),
    url(./assets/font/Open_Sans/static/OpenSans/OpenSans-ExtraBold.ttf) format("woff");
}

/* * {
  transition: var(--transition);
} */

/* UBUNTU Fonts */
/* @import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&family=Work+Sans:wght@300&display=swap'); */
</style>
