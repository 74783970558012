const HOST_URL = {
    // BUCKET_URL: "https://apollologos.s3.ap-south-1.amazonaws.com/",

    // DEMO URLS
    SERVER_URL: "https://demo.apollodart.com/",
    SOCKET_URL: "wss://demo.apollodart.com/",
    ADMINISTRATOR_URL: "https://demo-usrm.apollodart.com/",
    DATASERVICES_URL: "https://demo-dataservices.apollodart.com/apollosolutions/",
    APOLLO_UTIL_URL: "https://demo-dataservices.apollodart.com/apolloutils/",
    TND_SERVICE: "https://tnd.apollodart.com:8002/",
    LWD_UPLOAD: 'https://lwd-demo.apollodart.com:5050/',

    // SIT STAGING IPS
    //SERVER_URL: "https://sit-newui.adart.apl-local/",
    //SOCKET_URL: "wss://sit-newui.adart.apl-local/",
    //ADMINISTRATOR_URL: "https://sit-usrm.adart.apl-local/",
    //DATASERVICES_URL: "https://sit-dataservices.adart.apl-local/apollosolutions/",
    //APOLLO_UTIL_URL: "https://sit-dataservices.adart.apl-local/apolloutils/",
    //TND_SERVICE: "https://sit-tnd.adart.apl-local:8001/",
    //LWD_UPLOAD: 'https://sit-lwd.adart.apl-local:5050/',

    // // STAGING IPS
    // SERVER_URL: "https://newui.adart.apl-local/",
    // SOCKET_URL: "wss://newui.adart.apl-local/",
    // ADMINISTRATOR_URL: "https://dev-usrm.adart.apl-local/",
    // DATASERVICES_URL: "https://dataservices.adart.apl-local/apollosolutions/",
    // APOLLO_UTIL_URL: "https://dataservices.adart.apl-local/apolloutils/",
    // TND_SERVICE: "https://tnd.adart.apl-local:8001/",
    // LWD_UPLOAD: 'https://lwd.adart.apl-local:5050/',

    // Zulip chat realated config
    ZULIP_REALM: "https://zulip.adart.apl-local",
    AWS_S3_URL: "https://apollodarticons.s3.ap-south-1.amazonaws.com/"
};
export default {
    HOST_URL,
};
