import serviceHelpers from "../../../helper-services"
export default {
  settingUsersData(state, dtls) {
    let subEntities = dtls.subEntities
    let roleType = dtls.roleType
    let data = dtls.data
    state.usersData.subEntities = subEntities
    state.usersData.roleType = roleType
    state.usersData.data = data
  },
  setCustomer(state, customer) {

    state.customer = customer.trim();
  },
  setCustomerUpdate(state, isCustomerUpdate) {
    state.isCustomerUpdate = isCustomerUpdate
  },
  setLogData(state, params) {

    let obj = { data: params.data, 'duration': "oneday" };
    state.logData[params.logId] = obj;
    // state.logData['oneday'] = true;

  },

  setWells(state, wells) {
    state.wells = wells;
    let wellIds = [];
    for (let i = 0; i < wells.length; i++) {
      let wellProps = {};
      let wellboreIds = [];
      for (let j = 0; j < wells[i].wellbores.length; j++) {
        let wellboreProps = {};
        wellboreProps.wellboreId = wells[i].wellbores[j].wellbore_id;
        wellboreProps.wellboreName = wells[i].wellbores[j].wellbore_name;
        wellboreProps.wellboreState = wells[i].wellbores[j].wellbore_state;
        wellboreIds[j] = wellboreProps;
      }
      wellProps.wellId = wells[i].well_id;
      wellProps.wellboreIds = wellboreIds;
      wellIds[i] = wellProps;
    }
    console.log("wellIdswellIdswellIds", wellIds);
    state.wellIds = wellIds;
  },

  setTndWells(state, wells) {
    state.tndwells = wells;
  },

  setTagValue(state, { tagName, tags }) {
    //

    state.tagValues[tagName] = tags;
  },
  setSingleValue(state, dtls) {
    let name =
      dtls.title + "-" + dtls.display + "-" + dtls.logId + "-" + dtls.valueAt;
    //
    state[name] = dtls;
    return null;
  },
  displayChanged(state, dtls) {
    state.displayChanged = dtls;
  },
  startLoader(state) {
    state.isLoading = true;
  },
  stopLoader(state) {
    state.isLoading = false;
  },
  getOptimizations(state, data) {
    state.optimizations = data;
  },
  getAlertAnnotations(state, data) {
    state.annotations = data;
  },
  getAlertsList(state, data) {
    state.defaultAlerts = data;
  },
  setAccessPermissions(state) {
    if (serviceHelpers.getDetails("apollodart")) {
      try {

        state.access_all = serviceHelpers.getDetails("apollodart").policies[0].policies.split(',')
        state.is_super_admin = serviceHelpers.getDetails("apollodart").user.user_roles.role_type === "Super Admin"

        console.log('set access permission : ', state.is_super_admin)
      } catch (error) {
        console.log("err in set access : ", error)
        state.access_all = []
      }
    }
  },

  // Replay details
  setReplayDetails(state, {details}) {
    state.replayDetails =  details;
  },
  SET_BOOLEAN_VALUE(state, newValue) {
    state.myBooleanValue = newValue;
  },
  showHideHeader(state, value) {
    state.main_nav_show = value;
  },
  // loggedInUserDetails(state, data) {
  //   console.log("logged : ",  data)
  //   state.userInfo.userDetails = data?.userDetails;
  //   state.userInfo.customerList = data?.customerList;
  // },
};
