<template>
    <div>
        <div class="block tool_group" :style="{
            top: this.mouse_y + 'px',
            left: this.mouse_x + 'px',
        }" :id="'tooltip' + this.widgetId">
            <div v-if="isRigTooltip" class="actc_tool text-white">
                <p>
                    {{ tvdTooltipValues }}
                </p>
                <p>
                    {{ totalRecord }}
                </p>
            </div>
        </div>
        <svg :id="'svgpiechart' + widgetId" :width="widgetWidth" :height="widgetHeight"></svg>
    </div>
</template>

<script>
import * as d3 from "d3";
import apiService from "../../../api/services";
export default {
    name: "rig-chart",
    components: {},
    props: {
        widgetHeight: {
            type: Number,
            default: 200,
        },
        widgetWidth: {
            type: Number,
            default: 200,
        },
        widgetId: String,
        displayId: String,
    },
    data() {
        return {
            isRigTooltip: false,
            tvdTooltipValues: "",
            mouse_x: 0,
            mouse_y: 0,
            pieData: [],
            totalRecord: 1
        }

    },
    computed: {},
    methods: {
        async getPieData(dtls) {
            try {
                if (this.logType == "time") {
                    const response = await apiService.WellServices.getRigActivityData({
                        log_id: this.logId,
                        // from: new Date(this.start).getTime(),
                        // to: (new Date(this.start).getTime() - 8600000),
                    });
                    this.pieData = response.data.data;
                    this.totalRecord = response.data.data.total
                }

                
            } catch (error) {
                console.error("Trajectory data is not present : ", error.message);
            }
        },
        drawRigPieChart() {
            // Step 3
            var svg = d3.select("#svgpiechart" + this.widgetId)
            if (svg) {
                svg.selectAll("*").remove();
                svg = "";
            } else {
                let gauge = document.createElement("div");
                gauge.setAttribute("id", "svgpiechart" + this.widgetId);
            }
            svg = d3.select("#svgpiechart" + this.widgetId)
            var width = this.widgetWidth,
                height = this.widgetHeight,
                radius = this.widgetHeight / 2;



            // Step 1        
            var data = this.pieData.data;
            let total = this.pieData.total;
            var g = svg.append("g")
                .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

            // Step 4
            // var ordScale = d3.scaleOrdinal()
            //     .domain(data)
            //     .range(this.pieData.color);

            // Step 5
            var pie = d3.pie().value(function (d) {
                return d.count;
            });

            var arc = g.selectAll("arc")
                .data(pie(data))
                .enter();

            // Step 6
            var path = d3.arc()
                .outerRadius(radius)
                .innerRadius(0);

            let realTimeTooltip = (event, data, isShow) => {
                // 
                this.mouse_x = event.clientX;
                this.mouse_y = event.clientY;
                this.totalRecord = ((data.count / total) * 24).toFixed(2) + "hrs"
                this.tvdTooltipValues = data.tooltip;
                this.isRigTooltip = isShow;
            };
            let hideRealTimeTooltip = (event, text, isShow) => {
                if (this.tooltipTimeOut) {
                    clearTimeout(this.tooltipTimeOut);
                }
                this.tooltipTimeOut = setTimeout(() => {
                    this.mouse_x = 0;
                    this.mouse_y = 0;
                    this.tvdTooltipValues = '';
                    this.isRigTooltip = isShow;
                }, 1000);
            };

            arc.append("path")
                .attr("d", path)
                .attr("fill", function (d) {
                    return d.data.actcColor
                })
                .on('mouseover', function (e) {
                    let path = d3.select(this)
                    realTimeTooltip(e, path.data()[0].data, true);
                })
                .on('mousemove', function (e) {
                    let path = d3.select(this);
                    realTimeTooltip(e, path.data()[0].data, true);
                })
                .on('mouseout', function (e) {
                    hideRealTimeTooltip(e, '', false);
                });

            // Step 7
            // var label = d3.arc()
            //     .outerRadius(radius)
            //     .innerRadius(0);

            // arc.append("text")
            //     .attr("transform", function (d, i) {
            //         return "translate(" + label.centroid(d) + ")";
            //     })
            //     .text(function (d) { return d.data.tooltip; })
            //     .style("font-family", "arial")
            //     .style("font-size", 15)

        }
    },
    async mounted() {
        let dtls = this.$store.state.disp.displays[this.displayId];
        if (dtls) {
            
            this.wellId = dtls.wellId;
            this.wellboreId = dtls.wellboreId;
            this.logId = dtls.logId;
            this.wellboreState = dtls.wellboreState;
            this.logType = dtls.logType;
            this.table = dtls.table;
            this.start = dtls.max

        }
        await this.getPieData()
        
        this.drawRigPieChart()
        this.$store.subscribe(async (mutation, state) => {
            if (mutation.type == "disp/setDisplay") {
                if (mutation.payload.display == this.displayId) {
                    this.wellId = mutation.payload.wellId;
                    this.wellboreId = mutation.payload.wellboreId;
                    this.logId = mutation.payload.logId;
                    this.wellboreState = mutation.payload.wellboreState;
                    this.logType = mutation.payload.logType;
                    this.table = mutation.payload.table;
                    this.start = dtls.max
                    
                    await this.getPieData()
                    
                    this.drawRigPieChart()
                }
            }
        });
    },
    watch: {
        widgetHeight: async function (newVal, oldVal) {
            this.drawRigPieChart()
        },
        widgetWidth: async function (newVal, oldVal) {
            this.drawRigPieChart()
        }
    }

}
</script>

<style>
.overlay {
    fill: none;
    pointer-events: all;
}

.focus circle {
    fill: rgb(0, 0, 0);
}

.focus text {
    font-size: 14px;
}

.tooltip {
    fill: white;
    stroke: rgb(255, 249, 249);
}

.tooltip-date,
.tooltip-likes {
    font-weight: bold;
}

.tool_group {
    position: fixed;
    display: inline-grid;
    /* left: 221px; */
    right: 162px;
    z-index: 99999;
}

.actc_tool {
    font-size: 10px;
    background: #000000;
    width: auto;
    position: absolute;
    padding: 2px 5px;
    /* border-radius: 20px; */
}
</style>