import types, { CHANGE_ZINDEX } from "./mutation-types";

export default {
  disScreenWidgetId({ commit }, { widgetId }) {
    commit("SET_DIS_SCREEN", { widgetId });
  },
  chnageBackgroundName({ commit, state }, { backgroundId, savedName }) {
    commit("CHANGE_SAVED_NAME", { backgroundId, savedName });
  },
  deleteDisplay({ commit }, { displayId, minimzedIndex }) {
    commit(types.DELETE_DISPLAY, { displayId, minimzedIndex });
  },
  setDisplayId({ commit }, { displayId }) {
    commit(types.SET_DISPLAY_ID, { displayId });
  },

  update_zIndex({ commit }, { id }) {
    commit(types.UPDATE_ZINDEX, { id });
  },
  toggleDragHandler({ commit }, { value, displayId }) {
    commit(types.DRAGGABLE_ALL, { value, displayId });
  },
  toggleResizeHandler({ commit }, { displayId }) {
    commit(types.RESIZABLE_ALL, { displayId });
  },
  changeAlertColor({ commit, state }, { displayId, widgetId, value }) {
    commit(types.ALERT_COLOR, { displayId, widgetId, value });
  },
  changeBlinkAlert({ commit, state }, { displayId, widgetId, value }) {
    commit(types.BLINK_ALERT, { displayId, widgetId, value });
  },

  numericAlarmStartRange({ commit, state }, { displayId, widgetId, value }) {
    commit(types.NUMERIC_ALARM_START, { displayId, widgetId, value });
  },
  numericAlarmEndRange({ commit, state }, { displayId, widgetId, value }) {
    commit(types.NUMERIC_ALARM_END, { displayId, widgetId, value });
  },
  circularAlarmStartRange({ commit, state }, { displayId, widgetId, value }) {
    commit(types.CIRCULAR_ALARM_START, { displayId, widgetId, value });
  },
  circularAlarmEndRange({ commit, state }, { displayId, widgetId, value }) {
    commit(types.CIRCULAR_ALARM_END, { displayId, widgetId, value });
  },
  numericMemonicChange(
    { commit, state },
    { displayId, widgetId, value, fullName }
  ) {
    commit(types.NUMERIC_MEMONIC, { displayId, widgetId, value, fullName });
  },
  numericValueUpdate({ commit, state }, { displayId, widgetId, value }) {
    commit(types.NUMERIC_VALUE, { displayId, widgetId, value });
  },
  barMemonicChange(
    { commit, state },
    { displayId, widgetId, value, fullName }
  ) {
    commit(types.BAR_MEMONIC, { displayId, widgetId, value, fullName });
  },
  barValueUpdate({ commit, state }, { displayId, widgetId, value }) {
    commit(types.BAR_VALUE, { displayId, widgetId, value });
  },
  changeTitleColor({ commit, state }, { displayId, widgetId, value }) {
    commit(types.TITLE_COLOR, { displayId, widgetId, value });
  },
  changeTitleColorGauge({ commit, state }, { displayId, widgetId, value }) {
    commit(types.TITLE_COLOR_GAUGE, { displayId, widgetId, value });
  },
  changeValueColorGauge({ commit, state }, { displayId, widgetId, value }) {
    commit(types.VALUE_COLOR_GAUGE, { displayId, widgetId, value });
  },
  needleColorGauge({ commit, state }, { displayId, widgetId, value }) {
    commit(types.NEEDLE_COLOR_GAUGE, { displayId, widgetId, value });
  },

  // changeValueColor({ commit, state }, { displayId, widgetId, value }) {
  //     commit(types.VALUE_COLOR, { displayId, widgetId, value })
  // },
  // changeUnitColor({ commit, state }, { displayId, widgetId, value }) {
  //     commit(types.UNIT_COLOR, { displayId, widgetId, value })
  // },
  toggleTitleVisibility({ commit }, { displayId, widgetId }) {
    commit(types.TITLE_VISIBILITY, { displayId, widgetId });
  },
  toggleSquareGauge({ commit }, { displayId, widgetId }) {
    commit(types.SQUARE_GAUGE, { displayId, widgetId });
  },
  toggleValueVisibility({ commit }, { displayId, widgetId }) {
    commit(types.VALUE_VISIBILITY, { displayId, widgetId });
  },
  changeTitle({ commit, state }, { displayId, widgetId, value, fullName }) {
    commit(types.CHANGE_TITLE, { displayId, widgetId, value, fullName });
  },

  // Slug Actions
  changeDrillPipeLength({ commit, state }, { displayId, widgetId, value }) {
    commit("changeDrillPipeLength", { displayId, widgetId, value });
  },
  changeSlugDrillPipeLength({ commit, state }, { displayId, widgetId, value }) {
    commit("changeSlugDrillPipeLength", { displayId, widgetId, value });
  },
  changeSlugWeight({ commit, state }, { displayId, widgetId, value }) {
    commit("changeSlugWeight", { displayId, widgetId, value });
  },
  changeAnnulusVolume({ commit, state }, { displayId, widgetId, value }) {
    commit("changeAnnulusVolume", { displayId, widgetId, value });
  },
  changeSpotColumn({ commit, state }, { displayId, widgetId, value }) {
    commit("changeSpotColumn", { displayId, widgetId, value });
  },
  changeAnnulusSlugVolume({ commit, state }, { displayId, widgetId, value }) {
    commit("changeAnnulusSlugVolume", { displayId, widgetId, value });
  },
  changeSlugVolume({ commit, state }, { displayId, widgetId, value }) {
    commit("changeSlugVolume", { displayId, widgetId, value });
  },
  changeWashoutFactor({ commit, state }, { displayId, widgetId, value }) {
    commit("changeWashoutFactor", { displayId, widgetId, value });
  },
  changeDrillStringVolume({ commit, state }, { displayId, widgetId, value }) {
    commit("changeDrillStringVolume", { displayId, widgetId, value });
  },
  changeStrokeRequired({ commit, state }, { displayId, widgetId, value }) {
    commit("changeStrokeRequired", { displayId, widgetId, value });
  },
  // End

  changeUnit({ commit, state }, { displayId, widgetId, value }) {
    commit("CHANGE_UNIT", { displayId, widgetId, value });
  },

  changeTitleColor({ commit }, { displayId, widgetId, value }) {
    commit(types.CHANGE_TITLE_COLOR, { displayId, widgetId, value });
  },

  changeValueColor({ commit, state }, { displayId, widgetId, value }) {
    commit(types.CHANGE_VALUE_COLOR, { displayId, widgetId, value });
  },
  changeUnitColor({ commit, state }, { displayId, widgetId, value }) {
    commit(types.CHANGE_UNIT_COLOR, { displayId, widgetId, value });
  },

  changeBorderColor({ commit, state }, { displayId, widgetId, value }) {
    commit(types.CHANGE_BORDER_COLOR, { displayId, widgetId, value });
  },

  changeBorderWidth({ commit, state }, { displayId, widgetId, value }) {
    commit(types.CHANGE_BORDER_WIDTH, { displayId, widgetId, value });
  },

  toggleUnitVisibility({ commit, state }, { displayId, widgetId }) {
    commit(types.UNIT_VISIBILITY, { displayId, widgetId });
  },
  toggleAutoFit({ commit }, { displayId, widgetId }) {
    commit(types.AUTO_FIT, { displayId, widgetId });
  },
  toggleBorderVisibility({ commit }, { displayId, widgetId }) {
    commit(types.BORDER_VISIBILITY, { displayId, widgetId });
  },
  decimalValue({ commit }, { displayId, widgetId, value }) {
    commit(types.DECIMALS, { displayId, widgetId, value });
  },
  titleFontValue({ commit }, { displayId, widgetId, value }) {
    commit(types.TITLE_FONT_SIZE, { displayId, widgetId, value });
  },
  valueFontValue({ commit }, { displayId, widgetId, value }) {
    commit(types.VALUE_FONT_SIZE, { displayId, widgetId, value });
  },
  unitFontValue({ commit }, { displayId, widgetId, value }) {
    commit(types.UNTI_FONT_SIZE, { displayId, widgetId, value });
  },

  changeNumericUnit({ commit }, { displayId, widgetId, value }) {
    commit("NUMERIC_UNIT", { displayId, widgetId, value });
  },
  addNewRect({ commit, state }, xy) {
    
    let rectProps = {
      width: xy.elProps.width,
      height: xy.elProps.height,
      top: xy.y,
      left: xy.x,
      draggable: xy.elProps.draggable,
      resizable: true,
      minw: xy.elProps.minw ? xy.elProps.minw : 10,
      minh: xy.elProps.minh ? xy.elProps.minh : 10,
      axis: "both",
      parentLim: true,
      snapToGrid: false,
      aspectRatio: xy.elProps.aspectRatio,
      borderDisplay: xy.elProps.borderDisplay,
      zIndex: 3,
      // 'color': '#E6C27A',
      color: xy.elProps.color,
      active: false,
      class: "box-shaddow",
      name: xy.elProps.name,
      autoFit: true,
      isWidget: true,
      ...xy.elProps,
    };
    //
    if (xy.elProps.displayId != null) {
      rectProps.displayId = xy.elProps.displayId;
    }
    if (xy.elProps.widgetId != null) {
      rectProps.widgetId = xy.elProps.widgetId;
    }
    if (xy.elProps.bgId != null) {
      rectProps.bgId = xy.elProps.bgId;
    }
    if (xy.elProps.backgroundId != null) {
      rectProps.backgroundId = xy.elProps.backgroundId;
    }
    if (xy.elProps.title != null) {
      rectProps.title = xy.elProps.title;
    }
    if (xy.elProps.savedId != null) {
      rectProps.savedId = xy.elProps.savedId;
    }
    if (xy.elProps.savedName != null) {
      rectProps.savedName = xy.elProps.savedName;
    }

    if ("gauge" == xy.elProps.name) {
      rectProps.borderDisplay = true;
      // rectProps.breakPoints = []
    }

    if ("gauge" == xy.elProps.name && xy.elProps.bgColor) {
      rectProps.bgColor = xy.elProps.bgColor;
      rectProps.color = xy.elProps.bgColor;
    }

    if (xy.elProps.name == "plotly-graph") {
      rectProps.mnemonicTag1 = xy.elProps.mnemonicTag1 || "DEFAULT0";
      rectProps.mnemonicTag2 = xy.elProps.mnemonicTag2 || "DEFAULT1";
      rectProps.mnemonicTag3 = xy.elProps.mnemonicTag3 || "DEFAULT2";
      rectProps.mnemonicTag4 = xy.elProps.mnemonicTag4 || "DEFAULT3";
      rectProps.draggable = false;
    }

    if (xy.elProps.name == "plan-vs-actual") {
      rectProps.mnemonicTag1 = xy.elProps.mnemonicTag1 || "dispns";
      rectProps.mnemonicTag2 = xy.elProps.mnemonicTag2 || "dispew";
      rectProps.mnemonicTag3 = xy.elProps.mnemonicTag3 || "tvd";
      rectProps.mnemonicTag4 = xy.elProps.mnemonicTag4 || "DEFAULT3";
      rectProps.draggable = false;
    }

    if (xy.elProps.units) {
      rectProps.widgetUnitL = xy.elProps.units;
    }

    // if (xy.elProps.name == 'trend') {
    //     rectProps.draggable = false;
    // }
    if (
      xy.elProps.displayId &&
      xy.elProps.displayId.indexOf("generalDrilling") > -1 &&
      xy.elProps.y
    ) {
      rectProps.top = xy.elProps.y;
      rectProps.left = xy.elProps.x;
    }

    commit(types.ADD_NEW_RECT, rectProps);
  },
    
  addNewDisplay({ commit, state }, xy) {
    //
    let rectProps = {
      width: xy.elProps.width,
      height: xy.elProps.height,
      top: xy.y,
      left: xy.x,
      draggable: true,
      resizable: true,
      minw: 10,
      minh: 10,
      axis: "both",
      parentLim: true,
      snapToGrid: false,
      aspectRatio: false,
      zIndex: 3,
      // 'color': '#E6C27A',
      color: xy.elProps.color,
      active: false,
      class: "box-shaddow",
      name: xy.elProps.name,

      isWidget: true,
    };
    if (xy.elProps.displayId != null) {
      rectProps.displayId = xy.elProps.displayId;
    }
    if (xy.elProps.widgetId != null) {
      rectProps.widgetId = xy.elProps.widgetId;
    }

    commit("addNewDisplay", rectProps);
  },

  setActive({ commit, state }, { id, type }) {
    //
    for (let i = 0, l = state.rects.length; i < l; i++) {
      if (type == "widget") {
        if (state.rects[i].widgetId === id) {
          //
          commit(types.ENABLE_ACTIVE, i);
          continue;
        }
      }
      if (type == "bg") {
        if (state.rects[i].widgetId === id) {
          //
          commit(types.ENABLE_ACTIVE, i);
          continue;
        }
      }

      commit(types.DISABLE_ACTIVE, i);
    }
    //
  },

  unsetActive({ commit, state }, { id, type }) {
    //
    //
    for (let i = 0, l = state.rects.length; i < l; i++) {
      if (type == "widget") {
        if (state.rects[i].widgetId === id) {
          //
          commit(types.DISABLE_ACTIVE, i);
          continue;
        }
      }
      if (type == "bg") {
        if (state.rects[i].widgetId === id) {
          //
          commit(types.DISABLE_ACTIVE, i);
          continue;
        }
      }
    }
  },

  // toggleDraggable({ commit, state }, { displayId, widgetId }) {
  //     if (!state.rects[id].draggable) {
  //         commit(types.ENABLE_DRAGGABLE, id);
  //     } else {
  //         commit(types.DISABLE_DRAGGABLE, id);
  //     }
  // },

  toggleResizable({ commit, state }, { id, displayId, widgetId }) {
    if (id != "") {
      if (state.rects[index] && !state.rects[id].resizable) {
        commit(types.ENABLE_RESIZABLE, id);
      } else {
        commit(types.DISABLE_RESIZABLE, id);
      }
    } else {
      commit("TOGGLE_RESIZABLE", { displayId, widgetId });
    }
  },

  toggleParentLimitation({ commit, state }, { id }) {
    if (!state.rects[id].parentLim) {
      commit(types.ENABLE_PARENT_LIMITATION, id);
    } else {
      commit(types.DISABLE_PARENT_LIMITATION, id);
    }
  },

  toggleSnapToGrid({ commit, state }, { id }) {
    if (!state.rects[id].snapToGrid) {
      commit(types.ENABLE_SNAP_TO_GRID, id);
    } else {
      commit(types.DISABLE_SNAP_TO_GRID, id);
    }
  },

  setAspect({ commit }, { id }) {
    commit(types.ENABLE_ASPECT, id);
  },
  unsetAspect({ commit }, { id }) {
    commit(types.DISABLE_ASPECT, id);
  },

  setWidth({ commit, state }, payload) {
    commit(types.CHANGE_WIDTH, payload);
  },

  setHeight({ commit, state }, payload) {
    commit(types.CHANGE_HEIGHT, payload);
  },

  setTop({ commit }, payload) {
    commit(types.CHANGE_TOP, payload);
  },

  setLeft({ commit }, payload) {
    commit(types.CHANGE_LEFT, payload);
  },

  setDimensions({ commit, state }, payload) {
    for (let i = 0, l = state.rects.length; i < l; i++) {
      if (payload.type == "widget") {
        if (state.rects[i].widgetId === payload.widgetId) {
          //
          payload.index = i;
          commit("setDimensions", payload);
          continue;
        }
      }
      if (payload.type == "bg") {
        //

        if (state.rects[i].widgetId === payload.widgetId) {
          //
          payload.index = i;
          commit("setDimensions", payload);
          continue;
        }
      }
    }
  },

  // setWidth({ commit }, { id, width }) {
  //     commit(types.CHANGE_WIDTH, { id, width });
  // },

  // setHeight({ commit }, { id, height }) {
  //     commit(types.CHANGE_HEIGHT, { id, height });
  // },

  // setTop({ commit }, { id, top }) {
  //     commit(types.CHANGE_TOP, { id, top });
  // },

  // setLeft({ commit }, { id, left }) {
  //     commit(types.CHANGE_LEFT, { id, left });
  // },
  changeXLock({ commit, state }, { id }) {
    switch (state.rects[id].axis) {
      case "both":
        commit(types.ENABLE_Y_AXIS, id);
        break;
      case "x":
        commit(types.ENABLE_NONE_AXIS, id);
        break;
      case "y":
        commit(types.ENABLE_BOTH_AXIS, id);
        break;
      case "none":
        commit(types.ENABLE_X_AXIS, id);
        break;
    }
  },

  changeYLock({ commit, state }, { id }) {
    switch (state.rects[id].axis) {
      case "both":
        commit(types.ENABLE_X_AXIS, id);
        break;
      case "x":
        commit(types.ENABLE_BOTH_AXIS, id);
        break;
      case "y":
        commit(types.ENABLE_NONE_AXIS, id);
        break;
      case "none":
        commit(types.ENABLE_Y_AXIS, id);
        break;
    }
  },

  changeZToBottom({ commit, state }, { id }) {
    //
    if (state.rects[id].zIndex === 1) {
      return;
    }

    commit(types.CHANGE_ZINDEX, { id, zIndex: 1 });

    for (let i = 0, l = state.rects.length; i < l; i++) {
      if (i !== id) {
        if (state.rects[i].zIndex === state.rects.length) {
          continue;
        }
        commit(types.CHANGE_ZINDEX, {
          id: i,
          zIndex: state.rects[i].zIndex + 1,
        });
      }
    }
  },

  changeZToTop({ commit, state }, { id }) {
    if (state.rects[id].zIndex === state.rects.length) {
      return;
    }

    commit(types.CHANGE_ZINDEX, { id, zIndex: 9999 });

    for (let i = 0, l = state.rects.length; i < l; i++) {
      if (i !== id) {
        if (state.rects[i].zIndex === 1) {
          continue;
        }
        commit(types.CHANGE_ZINDEX, { id: i, zIndex: 1 });
      }
    }
  },

  toggleDraggable({ commit }, { displayId, widgetId }) {
    commit(types.TOGGLE_DRAGGABLE, { displayId, widgetId });
  },

  setMinWidth({ commit }, { id, width }) {
    commit(types.CHANGE_MINW, { id, minw: width });
  },

  setMinHeight({ commit }, { id, height }) {
    commit(types.CHANGE_MINH, { id, minh: height });
  },

  // Actions For Circular Gauge Widget
  breakPointChange({ commit }, { displayId, widgetId }) {
    commit(types.HAS_BREAKPOINT_CHANGED, { displayId, widgetId });
  },

  breakpointRemove({ commit }, { id, displayId, widgetId }) {
    commit(types.GAUGE_BREAKPOINT_REMOVE, { id, displayId, widgetId });
  },

  breakpointAdd(
    { commit },
    { displayId, widgetId, isVerticalBar, value, color }
  ) {
    commit(types.GAUGE_BREAKPOINT_ADD, {
      displayId,
      widgetId,
      isVerticalBar,
      value,
      color,
    });
  },
  breakpointValueHandler(
    { commit },
    { widgetId, value, breakPointIndex, displayId }
  ) {
    commit(types.GAUGE_BREAKPOINT_VALUE, {
      widgetId,
      value,
      breakPointIndex,
      displayId,
    });
  },
  breakpointColorHandler(
    { commit },
    { widgetId, value, breakPointIndex, displayId }
  ) {
    commit(types.GAUGE_BREAKPOINT_COLOR, {
      widgetId,
      value,
      breakPointIndex,
      displayId,
    });
  },

  gaugeMemonicChange(
    { commit, state },
    { displayId, widgetId, value, fullName }
  ) {
    commit(types.GAUGE_MEMONIC, { displayId, widgetId, value, fullName });
  },
  gaugeValueUpdate({ commit, state }, { displayId, widgetId, value }) {
    commit(types.GAUGE_VALUE, { displayId, widgetId, value });
  },

  changeGaugeTitle(
    { commit, state },
    { displayId, widgetId, value, fullName }
  ) {
    commit(types.CHANGE_GAUGE_TITLE, { displayId, widgetId, value, fullName });
  },
  gaugeDecimalValue({ commit }, { displayId, widgetId, value }) {
    commit(types.VALUE_DECIMALS, { displayId, widgetId, value });
  },
  changeGaugeBgColor({ commit }, { displayId, widgetId, value }) {
    commit(types.GAUGE_BG_COLOR, { displayId, widgetId, value });
  },
  changeGaugeSaveColor({ commit }, { displayId, widgetId, value }) {
    commit("GAUGE_SAVE_COLOR", { displayId, widgetId, value });
  },
  changeGaugeStartAngle({ commit }, { displayId, widgetId, value }) {
    commit(types.GAUGE_START_ANGLE, { displayId, widgetId, value });
  },
  changeGaugeEndAngle({ commit }, { displayId, widgetId, value }) {
    commit(types.GAUGE_END_ANGLE, { displayId, widgetId, value });
  },
  changeGaugeStartRange({ commit }, { displayId, widgetId, value }) {
    commit(types.GAUGE_START_RANGE, { displayId, widgetId, value });
  },
  changeGaugeEndRange({ commit }, { displayId, widgetId, value }) {
    commit(types.GAUGE_END_RANGE, { displayId, widgetId, value });
  },
  deleteWidget({ commit }, { displayId, widgetId, minimzedIndex }) {
    commit(types.DELETE_WIDGET, { displayId, widgetId, minimzedIndex });
  },
  gaugeIntervalValue({ commit }, { displayId, widgetId, value }) {
    commit(types.GAUGE_INTERVAL, { displayId, widgetId, value });
  },
  gaugeLabelFontValue({ commit }, { displayId, widgetId, value }) {
    commit(types.GAUGE_LABEL_FONT, { displayId, widgetId, value });
  },

  // Verical Bar Gauge Actions

  startRangeBarGauge({ commit }, { displayId, widgetId, value }) {
    commit(types.BAR_START_RANGE, { displayId, widgetId, value });
  },
  endRangeBarGauge({ commit }, { displayId, widgetId, value }) {
    commit(types.BAR_END_RANGE, { displayId, widgetId, value });
  },
  intervalBarGauge({ commit }, { displayId, widgetId, value }) {
    commit(types.BAR_INTERVAL, { displayId, widgetId, value });
  },

  // Directional Widget Actions
  changeTextColor({ commit }, { displayId, widgetId, value }) {
    commit(types.CHANGE_TEXT_COLOR, { displayId, widgetId, value });
  },

  directionalStartRange({ commit }, { displayId, widgetId, value }) {
    commit(types.DIRECTIONAL_START_RANGE, { displayId, widgetId, value });
  },
  directionalEndRange({ commit }, { displayId, widgetId, value }) {
    commit(types.DIRECTIONAL_END_RANGE, { displayId, widgetId, value });
  },
  directionalRadar({ commit }, { displayId, widgetId, value }) {
    commit(types.DIRECTIONAL_START_RADAR, { displayId, widgetId, value });
  },
  directionalEndRadar({ commit }, { displayId, widgetId, value }) {
    commit(types.DIRECTIONAL_END_RADAR, { displayId, widgetId, value });
  },
  toggleGridVisibility({ commit }, { displayId, widgetId }) {
    commit(types.DIRECTIONAL_GRID, { displayId, widgetId });
  },

  changeRadarVisibilityHandler({ commit }, { displayId, widgetId }) {
    commit(types.RADAR_VISIBILITY, { displayId, widgetId });
  },
  toggleDarkMode({ commit },payload) {
    commit(types.DARK_MODE,payload);
  },
  toggleLeftSideBar({ commit }) {
    commit(types.LEFT_SIDE_BAR);
  },
  toggleLeftSideDbBar({ commit }) {
    commit(types.LEFT_SIDE_DB_BAR);
  },
  toggleLeftSideDbBar({ commit }) {
    commit(types.LEFT_SIDE_DB_BAR);
  },
  toggleRightSideBar({ commit }, { id, value }) {
    commit(types.RIGT_SIDE_BAR, { id, value });
  },
  togglrRightSideBarWidth({ commit }) {
    commit(types.RIGT_SIDE_BAR_WIDTH);
  },

  directionalChangeLogType({ commit }, { displayId, widgetId, value }) {
    commit(types.CHANGE_LOG_TYPE, { displayId, widgetId, value });
  },

  diectionalChangeDataPointsHandler(
    { commit },
    { displayId, widgetId, value }
  ) {
    commit(types.DIRECTIONAL_DATA_POINTS, { displayId, widgetId, value });
  },

  // Data Table Actions
  changeStartRange({ commit }, { widgetId, tag, value, displayId }) {
    commit(types.CHANGE_START_RANGE, { widgetId, tag, value, displayId });
  },
  changeEndRange({ commit }, { widgetId, tag, value, displayId }) {
    commit(types.CHANGE_END_RANGE, { widgetId, tag, value, displayId });
  },

  changeTag({ commit }, { displayId, widgetId, value }) {
    commit(types.CHANGE_TAG, { displayId, widgetId, value });
  },
  changeColor({ commit }, { displayId, widgetId, value }) {
    commit(types.CHANGE_COLOR, { displayId, widgetId, value });
  },
  changeBackgroundColor({ commit }, { displayId, widgetId, value }) {
    commit(types.CHANGE_BACKGROUND_COLOR, { displayId, widgetId, value });
  },
  changeCardBackgroundColor({ commit }, { displayId, widgetId, value }) {
    commit('changeCardBackgroundColor', { displayId, widgetId, value });
  },
  changeHeadColor({ commit }, { displayId, widgetId, value }) {
    commit(types.CHANGE_HEAD_COLOR, { displayId, widgetId, value });
  },
  changeHeadBackgroundColor({ commit }, { displayId, widgetId, value }) {
    commit(types.CHANGE_HEAD_BACKGROUND_COLOR, { displayId, widgetId, value });
  },

  changeHeadFontSize({ commit }, { displayId, widgetId, value }) {
    commit(types.CHANGE_HEAD_FONT_SIZE, { displayId, widgetId, value });
  },
  minimizeRect({ commit }, { displayId, widgetId }) {
    commit(types.MINIMIZE_RECT, { displayId, widgetId });
  },
  maximizeWidget({ commit }, { displayId, widgetId, minimzedIndex }) {
    commit(types.MAXIMIZE_WIDGET, { displayId, widgetId, minimzedIndex });
  },

  // Plotly actions

  plotlyMnemonicChange(
    { commit, state },
    { displayId, widgetId, value, index }
  ) {
    commit(types.PLOTLY_MNEMONIC_CHANGE, { displayId, widgetId, value, index });
  },
  setConfig({ commit, state }, payload) {
    commit("setConfig", payload);
  },

  setCorrelationConfig({ commit, state }, payload) {
    commit("setCorrelationConfig", payload);
  },

  // BroomStick Props
  bhaStartTime({ commit, state }, { displayId, widgetId, value }) {
    commit("BHASTARTTIME", { displayId, widgetId, value });
  },
  bhaEndTime({ commit, state }, { displayId, widgetId, value }) {
    commit("BHAENDTIME", { displayId, widgetId, value });
  },

  // Line Graph actions

  lineGraphMnemonicChange(
    { commit, state },
    { displayId, widgetId, value, index }
  ) {
    commit(types.LINE_GRAPH_MNEMONIC_CHANGE, {
      displayId,
      widgetId,
      value,
      index,
    });
  },

  lineGraphXAxis({ commit, state }, { displayId, widgetId, value }) {
    commit(types.LINE_GRAPH_X_AXIS, { displayId, widgetId, value });
  },
  actualColor({ commit, state }, { displayId, widgetId, value }) {
    commit("actualColor", { displayId, widgetId, value });
  },
  planColor({ commit, state }, { displayId, widgetId, value }) {
    commit("planColor", { displayId, widgetId, value });
  },
  toggleMnemonics({ commit, state }, { displayId, widgetId }) {
    commit("toggleMnemonics", { displayId, widgetId });
  },
  lineGraphYAxis({ commit, state }, { displayId, widgetId, value }) {
    commit(types.LINE_GRAPH_Y_AXIS, { displayId, widgetId, value });
  },

  lineGraphGridColor({ commit, state }, { displayId, widgetId, value }) {
    commit(types.LINE_GRAPH_GRID_COLOR, { displayId, widgetId, value });
  },
  lineGraphGridBackground({ commit, state }, { displayId, widgetId, value }) {
    commit(types.LINE_GRAPH_GRID_BACKGROUND, { displayId, widgetId, value });
  },
  lineGraphXAxisLabelSize({ commit, state }, { displayId, widgetId, value }) {
    commit(types.LINE_GRAPH_X_AXIS_LABEL_SIZE, { displayId, widgetId, value });
  },
  lineGraphYAxisLabelSize({ commit, state }, { displayId, widgetId, value }) {
    commit(types.LINE_GRAPH_Y_AXIS_LABEL_SIZE, { displayId, widgetId, value });
  },

  // Line Graph Tvd actions

  lineGraphTvdXAxis({ commit, state }, { displayId, widgetId, value }) {
    commit(types.LINE_GRAPH_TVD_X_AXIS, { displayId, widgetId, value });
  },
  lineGraphTvdYAxis({ commit, state }, { displayId, widgetId, value }) {
    commit(types.LINE_GRAPH_TVD_Y_AXIS, { displayId, widgetId, value });
  },

  lineGraphTvdGridColor({ commit, state }, { displayId, widgetId, value }) {
    commit(types.LINE_GRAPH_TVD_GRID_COLOR, { displayId, widgetId, value });
  },
  lineGraphTvdGridBackground(
    { commit, state },
    { displayId, widgetId, value }
  ) {
    commit(types.LINE_GRAPH_TVD_GRID_BACKGROUND, {
      displayId,
      widgetId,
      value,
    });
  },
  lineGraphTvdXAxisLabelSize(
    { commit, state },
    { displayId, widgetId, value }
  ) {
    commit(types.LINE_GRAPH_TVD_X_AXIS_LABEL_SIZE, {
      displayId,
      widgetId,
      value,
    });
  },
  lineGraphTvdYAxisLabelSize(
    { commit, state },
    { displayId, widgetId, value }
  ) {
    commit(types.LINE_GRAPH_TVD_Y_AXIS_LABEL_SIZE, {
      displayId,
      widgetId,
      value,
    });
  },
  setConfig({ commit, state }, payload) {
    commit("setConfig", payload);
  },
  setCorrelationSelectedWell(
    { commit, state },
    { displayId, widgetId, value }
  ) {
    commit("setCorrelationSelectedWell", { displayId, widgetId, value });
  },
  wellsToRelate(
    { commit, state },
    { displayId, widgetId, widgetIndex, value, checked, index, isReset }
  ) {
    commit("wellsToRelate", {
      displayId,
      widgetId,
      widgetIndex,
      value,
      checked,
      index,
      isReset
    });
  },
  wellSection({ commit, state }, payload) {
    commit("wellSection", payload);
  },
  wellRelationOptimization(
    { commit, state },
    { displayId, widgetId, widgetIndex, value, checked }
  ) {
    commit("wellRelationOptimization", {
      displayId,
      widgetId,
      widgetIndex,
      value,
      checked,
    });
  },
  setRelationDisplay({ commit, state }, payload) {
    commit("setRelationDisplay", payload);
  },
  mousemove({ commit, state }, payload) {
    commit("mousemove", payload);
  },
  mouseout({ commit, state }, payload) {
    commit("mouseout", payload);
  },
  yAxisValue({ commit, state }, payload) {
    commit("yAxisValue", payload);
  },
  lockevent({ commit, state }, payload) {
    commit("lockevent", payload);
  },
  mousescroll({ commit, state }, payload) {
    commit("mousescroll", payload);
  },
  toolTipToggle({ commit, state }, payload) {
    commit("toolTipToggle", payload);
  },
  toolZoomToggle({ commit, state }, payload) {
    commit("toolZoomToggle", payload);
  },
  zoomStart({ commit, state }, payload) {
    commit("zoomStart", payload);
  },
  zoomEnd({ commit, state }, payload) {
    commit("zoomEnd", payload);
  },
  setCurveProps({ commit, state }, payload) {
    commit("setCurveProps", payload);
  },
  selectFeet({ commit, state }, payload) {
    commit("selectFeet", payload);
  },

  trajectoryRangeChange({ commit }, { displayId, widgetId, value }) {
    commit("trajectoryRangeChange", { displayId, widgetId, value });
  },
  trajectoryToggleAutoScale({ commit }, { displayId, widgetId, value }) {
    commit("trajectoryToggleAutoScale", { displayId, widgetId, value });
  },

  //Swab Static 
  changeTripeDepth({ commit, state }, { displayId, widgetId, value }) {
    commit('changeTripeDepth', { displayId, widgetId, value })
  },
  changeTripeDepthTVD({ commit, state }, { displayId, widgetId, value }) {
    commit('changeTripeDepthTVD', { displayId, widgetId, value })
  },
  changeHoleSize({ commit, state }, { displayId, widgetId, value }) {
    commit('changeHoleSize', { displayId, widgetId, value })
  },
  changeDC1OD({ commit, state }, { displayId, widgetId, value }) {
    commit('changeDC1OD', { displayId, widgetId, value })
  },
  changeDC1ID({ commit, state }, { displayId, widgetId, value }) {
    commit('changeDC1ID', { displayId, widgetId, value })
  },
  changeDC1Length({ commit, state }, { displayId, widgetId, value }) {
    commit('changeDC1Length', { displayId, widgetId, value })
  },
  changeDC2OD({ commit, state }, { displayId, widgetId, value }) {
    commit('changeDC2OD', { displayId, widgetId, value })
  },
  changeDC2ID({ commit, state }, { displayId, widgetId, value }) {
    commit('changeDC2ID', { displayId, widgetId, value })
  },
  changeDC2Length({ commit, state }, { displayId, widgetId, value }) {
    commit('changeDC2Length', { displayId, widgetId, value })
  },
  changeHWDPOD({ commit, state }, { displayId, widgetId, value }) {
    commit('changeHWDPOD', { displayId, widgetId, value })
  },
  changeHWDPID({ commit, state }, { displayId, widgetId, value }) {
    commit('changeHWDPID', { displayId, widgetId, value })
  },
  changeHWDPLength({ commit, state }, { displayId, widgetId, value }) {
    commit('changeHWDPLength', { displayId, widgetId, value })
  },
  changeDPOD({ commit, state }, { displayId, widgetId, value }) {
    commit('changeDPOD', { displayId, widgetId, value })
  },
  changeDPID({ commit, state }, { displayId, widgetId, value }) {
    commit('changeDPID', { displayId, widgetId, value })
  },
  changeDPLength({ commit, state }, { displayId, widgetId, value }) {
    commit('changeDPLength', { displayId, widgetId, value })
  },
  changeMudDensity({ commit, state }, { displayId, widgetId, value }) {
    commit('changeMudDensity', { displayId, widgetId, value })
  },
  changeR300({ commit, state }, { displayId, widgetId, value }) {
    commit('changeR300', { displayId, widgetId, value })
  },
  changeR600({ commit, state }, { displayId, widgetId, value }) {
    commit('changeR600', { displayId, widgetId, value })
  },
  changeTripMode({ commit, state }, { displayId, widgetId, value }) {
    commit('changeTripMode', { displayId, widgetId, value })
  },
  changeTripStatus({ commit, state }, { displayId, widgetId, value }) {
    commit('changeTripStatus', { displayId, widgetId, value })
  },
  changeFlowIndex({ commit, state }, { displayId, widgetId, value }) {
    commit('changeFlowIndex', { displayId, widgetId, value })
  },
  changeConsistencyIndex({ commit, state }, { displayId, widgetId, value }) {
    commit('changeConsistencyIndex', { displayId, widgetId, value })
  },
  changePlasticViscosity({ commit, state }, { displayId, widgetId, value }) {
    commit('changePlasticViscosity', { displayId, widgetId, value })
  },

  // Swab Live 

  changeTripeDepthLive({ commit, state }, { displayId, widgetId, value }) {
    commit('changeTripeDepthLive', { displayId, widgetId, value })
  },
  changeTripeDepthTVDLive({ commit, state }, { displayId, widgetId, value }) {
    commit('changeTripeDepthTVDLive', { displayId, widgetId, value })
  },
  changeHoleSizeLive({ commit, state }, { displayId, widgetId, value }) {
    commit('changeHoleSizeLive', { displayId, widgetId, value })
  },
  changeDC1ODLive({ commit, state }, { displayId, widgetId, value }) {
    commit('changeDC1ODLive', { displayId, widgetId, value })
  },
  changeDC1IDLive({ commit, state }, { displayId, widgetId, value }) {
    commit('changeDC1IDLive', { displayId, widgetId, value })
  },
  changeDC1LengthLive({ commit, state }, { displayId, widgetId, value }) {
    commit('changeDC1LengthLive', { displayId, widgetId, value })
  },
  changeDC2ODLive({ commit, state }, { displayId, widgetId, value }) {
    commit('changeDC2ODLive', { displayId, widgetId, value })
  },
  changeDC2IDLive({ commit, state }, { displayId, widgetId, value }) {
    commit('changeDC2IDLive', { displayId, widgetId, value })
  },
  changeDC2LengthLive({ commit, state }, { displayId, widgetId, value }) {
    commit('changeDC2LengthLive', { displayId, widgetId, value })
  },
  changeHWDPODLive({ commit, state }, { displayId, widgetId, value }) {
    commit('changeHWDPODLive', { displayId, widgetId, value })
  },
  changeHWDPIDLive({ commit, state }, { displayId, widgetId, value }) {
    commit('changeHWDPIDLive', { displayId, widgetId, value })
  },
  changeHWDPLengthLive({ commit, state }, { displayId, widgetId, value }) {
    commit('changeHWDPLengthLive', { displayId, widgetId, value })
  },
  changeDPODLive({ commit, state }, { displayId, widgetId, value }) {
    commit('changeDPODLive', { displayId, widgetId, value })
  },
  changeDPIDLive({ commit, state }, { displayId, widgetId, value }) {
    commit('changeDPIDLive', { displayId, widgetId, value })
  },
  changeDPLengthLive({ commit, state }, { displayId, widgetId, value }) {
    commit('changeDPLengthLive', { displayId, widgetId, value })
  },
  changeCasingOD({ commit, state }, { displayId, widgetId, value }) {
    commit('changeCasingOD', { displayId, widgetId, value })
  },
  changeCassingID({ commit, state }, { displayId, widgetId, value }) {
    commit('changeCassingID', { displayId, widgetId, value })
  },
  changeCassingLength({ commit, state }, { displayId, widgetId, value }) {
    commit('changeCassingLength', { displayId, widgetId, value })
  },
  changeMudDensityLive({ commit, state }, { displayId, widgetId, value }) {
    commit('changeMudDensityLive', { displayId, widgetId, value })
  },
  changeR300Live({ commit, state }, { displayId, widgetId, value }) {
    commit('changeR300Live', { displayId, widgetId, value })
  },
  changeR600Live({ commit, state }, { displayId, widgetId, value }) {
    commit('changeR600Live', { displayId, widgetId, value })
  },
  changeTripModeLive({ commit, state }, { displayId, widgetId, value }) {
    commit('changeTripModeLive', { displayId, widgetId, value })
  },
  changeTripStatusLive({ commit, state }, { displayId, widgetId, value }) {
    commit('changeTripStatusLive', { displayId, widgetId, value })
  },
  changeFlowIndexLive({ commit, state }, { displayId, widgetId, value }) {
    commit('changeFlowIndexLive', { displayId, widgetId, value })
  },
  changeConsistencyIndexLive({ commit, state }, { displayId, widgetId, value }) {
    commit('changeConsistencyIndexLive', { displayId, widgetId, value })
  },
  changePlasticViscosityLive({ commit, state }, { displayId, widgetId, value }) {
    commit('changePlasticViscosityLive', { displayId, widgetId, value })
  },
  clickedGraph({ commit, state }, { displayId, widgetId, value }) {
    commit('clickedGraph', { displayId, widgetId, value })
  },
  swabSurgeRealTimedata({ commit, state }, { displayId, widgetId, value, field }){
    commit('swabSurgeRealTimedata', { displayId, widgetId, value, field })
  },
  LwdData({ commit, state }, { displayId, widgetId, value, field }){
    commit('LwdData', { displayId, widgetId, value, field })
  },
  addNewRectForVerticalBar({ commit, state }, data){
    commit('addNewRectForVerticalBar', data)
  },

  //End

  /*Start Directional Difficulty*/
  changeGraphLabelColor({ commit }, { displayId, widgetId, value }) {
    commit(types.GRAPH_LABEL_COLOR, { displayId, widgetId, value })
  },
  changeGraphColor({ commit }, { displayId, widgetId, value }) {
    commit(types.GRAPH_COLOR, { displayId, widgetId, value })
  },
  changeAxisLabelSize({ commit }, { displayId, widgetId, value }) {
    commit(types.X_AXIS_LABEL_SIZE, { displayId, widgetId, value })
  },
  changeYxisLabelSize({ commit }, { displayId, widgetId, value }) {
    commit(types.Y_AXIS_LABEL_SIZE, { displayId, widgetId, value })
  },
  changeGridColor({ commit }, { displayId, widgetId, value }) {
    commit(types.GRID_COLOR, { displayId, widgetId, value })
  },
  changeGraphBackground({ commit }, { displayId, widgetId, value }) {
    commit(types.GHARPH_BACKGROUND, { displayId, widgetId, value })
  },
  changeXmanualRange({ commit }, { displayId, widgetId, value }) {
    commit(types.X_MANUAL_RANGE, { displayId, widgetId, value })
  },
  changeXstartRange({ commit }, { displayId, widgetId, value }) {
    commit(types.X_START_RANGE, { displayId, widgetId, value })
  },
  changeXEndRange({ commit }, { displayId, widgetId, value }) {
    commit(types.X_END_RANGE, { displayId, widgetId, value })
  },
  changeYmanualRange({ commit }, { displayId, widgetId, value }) {
    commit(types.Y_MANUAL_RANGE, { displayId, widgetId, value })
  },
  changeYstartRange({ commit }, { displayId, widgetId, value }) {
    commit(types.Y_START_RANGE, { displayId, widgetId, value })
  },
  changeYEndRange({ commit }, { displayId, widgetId, value }) {
    commit(types.Y_END_RANGE, { displayId, widgetId, value })
  },
  /*End Directional Difficulty*/
  
  /* Start of xy axis wedigs */
  isEnableSecondayAxis({ commit }, { displayId, widgetId, value }){
    commit(types.IS_SECONDARY_AXIS, { displayId, widgetId, value })
  },
  changeAxisLabelColor({ commit }, { displayId, widgetId, value }){
    commit(types.AXIS_LABEL_COLOR, { displayId, widgetId, value })
  },
  changeGraphColor({ commit }, { displayId, widgetId, value }){
    commit(types.GRAPH_COLOR_XY_AXIS, { displayId, widgetId, value })
  },
  changeGridColor({ commit }, { displayId, widgetId, value }){
    commit(types.GRID_COLOR_XY_AXIS, { displayId, widgetId, value })
  },
  changeBackgroundGridColor({ commit }, { displayId, widgetId, value }){
    commit(types.BACKGROUND_COLOR_XY_AXIS, { displayId, widgetId, value })
  },
  changedGraphXaxisLabel({ commit }, { displayId, widgetId, value }){
    commit(types.XAXIS_LABEL_XY_AXIS, { displayId, widgetId, value })
  },
  changedGraphYaxisLabel({ commit }, { displayId, widgetId, value }){
    commit(types.YAXIS_LABEL_XY_AXIS, { displayId, widgetId, value })
  },
  changedSecondaryAxisLabelColor({ commit }, { displayId, widgetId, value }){
    commit(types.AXIS_SECONDARY_LABEL_COLOR, { displayId, widgetId, value })
  },
  changedSecondaryAxisScaleColor({ commit }, { displayId, widgetId, value }){
    commit(types.AXIS_SECONDARY_SCALE_COLOR, { displayId, widgetId, value })
  },
  changedSecondaryAxisScaleLabelSize({ commit }, { displayId, widgetId, value }){
    commit(types.AXIS_SECONDARY_LABEL_SIZE, { displayId, widgetId, value })
  },
  changePrimaryXsxisNemonice({ commit }, { displayId, widgetId, value,fullName }){
    commit(types.PRIMART_X_AXIS_MNEMONICS, { displayId, widgetId, value,fullName })
  },
  changePrimaryYsxisNemonice({ commit }, { displayId, widgetId, value,fullName }){
    commit(types.PRIMART_Y_AXIS_MNEMONICS, { displayId, widgetId, value,fullName })
  },
  changeSecondaryYsxisNemonice({ commit }, { displayId, widgetId, value,fullName }){
    commit(types.SECONDARY_Y_AXIS_MNEMONICS, { displayId, widgetId, value,fullName })
  },
  /* End of xy axis wedigs */

};
