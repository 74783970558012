import { createApp } from "vue";
import { createWebHistory, createRouter } from "vue-router";
import Toaster from '@meforma/vue-toaster';
import store from './store';

// open layers map
import OpenLayersMap from 'vue3-openlayers'
import 'vue3-openlayers/dist/vue3-openlayers.css'

// styles
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import "@/assets/styles/tailwind.css";
import "@/assets/styles/global.css"

// mouting point for the whole app

import App from './App.vue';
// layouts
import Auth from "@/layouts/Auth.vue";

// views for Admin layout
import CentralPanel from "@/components/CentralPanel.vue";

import VueSocketIO from 'vue-3-socket.io'
import URL from './config.js';

// export const SocketInstance = socketio(URL.HOST_URL.SOCKET_URL);
// views for Auth layout

import Login from "@/views/auth/Login.vue";
import Support from "./support/support.vue"
import Privacy from "./support/Privacy.vue"
import Terms from "./support/TermAndCond.vue"
import ForgetPassword from "./views/auth/ForgetPassword.vue"
import Reset from "./views/auth/Reset.vue"
let clientId = Math.random().toString(36).slice(2);

import serviceHelpers from "./helper-services.js"
// code to hide all console.log statements for production only
// console.log = function () {};
const validate_onload = async () => {
  try {
    // let tokenValidation = await services.AuthServices.validate_session(serviceHelpers.getDetails("670023242:az"));
    // return tokenValidation.data.message;
    let device_to = localStorage.getItem("670023242:az");
    if (device_to)
      return 'Session validated'
    else
      return 'Expired'
  } catch (error) {
    console.error('error in validate onload : ', error);
    return error.message;
  }
}

let beforeEnterAuth = async (to, from, next) => {
  let tokenValidation = await validate_onload();
  if (tokenValidation == 'Session validated') {
    next("/app")
  } else {
    next()
  }
}
let beforeEnterApp = async (to, from, next) => {
  let tokenValidation = await validate_onload();
  try {
    console.log('tokenValidation', tokenValidation)
    if (tokenValidation == 'Session validated')
      next()
    else
      next("/auth/login")
  } catch (error) {
    next("/auth/login")
  }
}

const routes = [
  {
    path: "/auth/:login",
    redirect: "/auth/login",
    component: Auth,
    beforeEnter: beforeEnterAuth,
    children: [
      {
        path: "/auth/login",
        component: Login,
      },
      {
        path: "/auth/login",
        component: Login,
      },
      {
        path: "/auth/cb-usm",
        component: Login,
      },
      {
        path: "/auth/reset",
        component: ForgetPassword,
        children: [
          {
            path: "/auth/reset/:reset_code",
            component: ForgetPassword,
          },
        ]
      },

      {
        path: "auth/:pathMatch(.*)*", redirect: "/auth/login"
      }
    ],

  },
  {
    path: "/",
    redirect: "/app",
    component: CentralPanel,
    beforeEnter: beforeEnterApp,
    children: [
      {
        path: "/app",
        component: CentralPanel,
      }
    ],
  },
  {
    path: "/support",
    component: Support,
  },
  {
    path: "/privacy",
    component: Privacy,
  },
  {
    path: "/terms",
    component: Terms,
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const app = createApp(App)


app.config.globalProperties.$clientId = clientId;
app.config.globalProperties.$serviceHelpers = serviceHelpers;
import SocketIO from 'socket.io-client'
app.use(new VueSocketIO({
  debug: false,
  connection: URL.HOST_URL.SOCKET_URL,
  vuex: {
    store,
    actionPrefix: "SOCKET_",
    mutationPrefix: "SOCKET_"
  }
})
);
app.use(Toaster, {
  // One of the options
  maxToasts: 1,
  duration: "2000",
  position: "top",
  pauseOnHover: true,
})
app.use(OpenLayersMap)
app.use(store)
app.use(router)
app.mount('#app')