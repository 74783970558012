<template>
  <div @contextmenu="this.setContextIdWidget" :id="widgetId" :style="{
    height: this.widgetHeight + 'px',
    width: this.widgetWidth + 'px',
    paddingTop: '2px',
    'border-radius': '10px',
  }" class="flex widgetBackground shadow-lg">
    <table-lite @dblclick="openConfig" :has-checkbox="true" :is-loading="table.isLoading" :is-re-search="table.isReSearch"
      :columns="table.columns" :rows="table.rows" :total="table.totalRecordCount" :sortable="table.sortable"
      :messages="table.messages" :page-size="pageSize" :color="color" :background="background" :height="widgetHeight"
      :width="widgetWidth" :headColor="headColor" :headBackground="headBackground" :headFontSize="headFontSize"
      :tableConfiguration="tableConfiguration" :tableId="id" :widgetPropertyIndex="widgetPropertyIndex"
      :alertColor="newAlertColor" @do-search="doSearch" @is-finished="tableLoadingFinish"
      @return-checked-rows="updateCheckedRows"></table-lite>
    <div :id="'ddi' + this.widgetId" class="text-white" @dblclick="openConfig"></div>
  </div>
</template>
<script>
import TableLite from "./DirectionaDifficultyLibrary";
import {
  select,
  scaleLinear,
  axisBottom,
  axisLeft,
  brush,
  symbol,
  symbolCircle,
  bisector,
  pointer,
  symbolDiamond,
  line,
  max,
  text,
} from "d3";
import apiService from "../../../api/services";
var svg = "";
let id = null;
export default {
  name: "DirectionalDifficulty-widget",
  components: {
    TableLite,
  },
  props: {
    currentColor: {
      type: String,
      default: "#f00",
    },
    cardBackground: {
      type: String,
      default: "#3f3f3f",
    },
    widgetHeight: {
      type: Number,
      default: 200,
    },
    widgetWidth: {
      type: Number,
      default: 850,
    },
    widgetpopHeight: {
      type: Number,
      default: 350,
    },
    widgetpopWidth: {
      type: Number,
      default: 700,
    },
    displayId: String,
    id: String,
    gridColor: {
      type: String,
      default: "",
    },

    gridBackground: {
      type: String,
      default: "",
    },
    xAxisLabel: {
      type: String,
      default: "white",
    },
    yAxisLabel: {
      type: String,
    },
    backgroundColor: {
      type: String,
      default: "white",
    },
    widgetId: String,
    isSwitched: {
      type: Boolean,
      default: false,
    },
    actualColor: {
      type: String,
      default: "red",
    },
    planColor: {
      type: String,
      default: "#00d15a",
    },
    // xAutoScale: {
    //   type: [Boolean],
    //   default: false,
    // },
    // yAutoScale: {
    //   type: [Boolean],
    //   default: false,
    // },
    // xRangeStart: {
    //   type: [Number, String, undefined],
    //   default: 0,
    // },
    // xRangeEnd: {
    //   type: [Number, String, undefined],
    //   default: 1000,
    // },
    // yRangeStart: {
    //   type: [Number, String, undefined],
    //   default: 0,
    // },
    // yRangeEnd: {
    //   type: [Number, String, undefined],
    //   default: 1000,
    // },
    alertColor: String,
    blinkAlert: Boolean,
    id: String,
    openConfig: Function,
    headColor: String,
    headBackground: String,
    headFontSize: Number,
    color: String,
    background: String,
    getTableColumns: Function,
    tableConfiguration: {},
    tableData: {
      default: {
        isLoading: false,
        isReSearch: false,
        columns: [
          {
            label: "Type",
            field: "type",
            isKey: true,
          },
          {
            label: "RT",
            field: "rt",
            // width: `${100 / this.columns.length}`,
            sortable: true,
            isKey: true,
          },

          {
            label: "Run Number",
            field: "run",
            sortable: true,
          },
          {
            label: "Service Company",
            field: "serviceCompany",
            sortable: false,
          },
        ],
        rows: [
          {
            type: "",
            rt: "",
            run: "",
            serviceCompany: "",
          },
        ],
        sortable: {
          order: "name",
          sort: "asc",
        },
      },
    },

    //Graph properties
    graphLabelColor: {
      type: String,
    },
    graphColor: {
      type: String,
      default: "#595656",
      require: false
    },
    xAxisLabelSize: {
      type: Number,
      require: false,
      default: 10
    },
    yAxisLabelSize: {
      type: Number,
      require: false,
      default: 10
    },
    gridColor: {
      type: String,
      require: false,
      default: '#776f6f'
    },
    gridBackgroundColor: {
      type: String,
      require: false,
      default: ''
    },
    isXmanualRange: {
      type: Boolean,
      require: false,
      default: false
    },
    xStartRange: {
      type: Number,
      require: false,
      default: 0
    },
    xEndRange: {
      type: Number,
      require: false,
      default: 0
    },
    isYmanualRange: {
      type: Boolean,
      require: false,
      default: false
    },
    yStartRange: {
      type: Number,
      require: false,
      default: 0
    },
    yEndRange: {
      type: Number,
      require: false,
      default: 0
    },
  },
  data() {
    return {
      minX: 0,
      maxX: 100,
      minY: 0,
      maxY: 3500,
      // widgetpopHeight: 350,
      // widgetpopWidth: 700,
      width: this.widgetpopWidth,
      height: this.widgetHeight,
      megaData: [],
      graphData: {
        rows: [],
        rowsPlan: [],
        columns: [],
      },
      selectedTags: {
        x: "drillingdifficultyindex",
        y: "tvd",
      },
      drillingdifficultyremarks: [],
      newAlertColor: "#ffffff",
      isBlinkAlert: false,
      columns: [],
      rows: [],
      tableConfig: {
        color: "",
        background: "#0d0d0d",
        headColor: "#cccccc",
        headBackground: "#ffffff",
      },
      pageSize: 10,
      backupTableRows: [],
      table: this.tableData,
      widgetPropertyIndex: -1,
    };
  },
  async beforeMount() {
    let id = "id" + Math.random().toString(16).slice(2);
    this.tempWidgetId = id;
    let rects = this.$store.state.rect.rects;
    if (typeof rects != "undefined" && rects.length > 0) {
      for (let i = 0; i < rects.length; i++) {
        if (
          rects[i].widgetId == this.widgetId &&
          rects[i].displayId == this.displayId
        )
          this.widgetPropertyIndex = i;
      }
    }
  },
  async mounted() {
    let dtls = this.$store.state.disp.displays[this.displayId];
    if (dtls) {
      this.wellId = dtls.wellId;
      this.wellboreId = dtls.wellboreId;
      this.logId = dtls.trajectoryLogId;
      this.wellboreState = dtls.wellboreState;
      this.logType = dtls.logType;
      this.table = dtls.table;
      this.loadTable(dtls);
    }
    this.$store.subscribe(async (mutation, state) => {
      if (mutation.type == "rect/LINE_GRAPH_MNEMONIC_CHANGE") {
        let rects = this.$store.state.rect.rects;
        if (typeof rects != "undefined" && rects.length > 0) {
          for (let i = 0; i < rects.length; i++) {
            if (
              rects[i].widgetId == this.widgetId &&
              rects[i].displayId == this.displayId
            ) {
              this.selectedTags = {
                x:
                  this.$store.state.rect.rects[i].mnemonicTag1 ||
                  "drillingdifficultyindex",
                y: this.$store.state.rect.rects[i].mnemonicTag2 || "tvd",
              };
            }
          }
        }
      }
      if (mutation.type == "disp/setDisplay") {
        if (mutation.payload.display == this.displayId) {
          this.wellId = mutation.payload.wellId;
          this.wellboreId = mutation.payload.wellboreId;
          this.logId = mutation.payload.logId;
          this.wellboreState = mutation.payload.wellboreState;
          this.logType = mutation.payload.logType;
          this.table = mutation.payload.table;
          this.loadTable(mutation.payload);
          await this.get3DGraphData(mutation.payload);
          this.buildSVG();
        }
      }
    });
    await this.get3DGraphData(dtls);
    this.buildSVG();
  },
  computed: {
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
    axiosParams() {
      const params = new URLSearchParams();
      params.append("well_id", this.wellId);
      params.append("well_bore_id", this.wellboreId);
      params.append("customer", this.customer);
      return params;
    },
  },
  sockets: {
    connect: function () {
      console.log("socket connected in trend");
    },
    disconnect() {
      try {
        let topic = this.topic;
        this.$socket.emit("unsubscribe", {
          logId: `${this.wellId}~seperation~${this.wellboreId}~seperation~${this.logType}`,
          topic: this.topic,
          id: this.widgetId,
          clientId: this.$clientId,
        });
      } catch (error) {
        console.error('disconnect unsubscribe error ', error)
      }
    },

    reconnect() {
      this.$socket.emit("susbcribe", {
        logId: `${this.wellId}~seperation~${this.wellboreId}~seperation~${this.logType}`,
        topic: this.topic,
        fields: this.fields,
        id: this.widgetId,
        clientId: this.$clientId,
      });
    },
  },
  methods: {
    setContextIdWidget(e) {
      this.$store.dispatch("disp/setContextId", {
        contextId: this.displayId,
        contextWidgetId: this.widgetId,
        contextType: "widget",
      });
    },
    processResult(sResult) {
      console.log("processResult ", sResult);
      console.log("processResult widgetId", this.widgetId);
    },

    windowToggle(name) {
      this.classes = name;
    },
    dark() {
      document.querySelector("nav").classList.add("dark-mode");
      document.querySelector("div").classList.add("dark-mode");
      document.querySelector("button").classList.add("dark-mode");
      document.querySelector("a").classList.add("text-red-600");
      this.darkMode = true;
      this.$emit("dark");
    },
    light() {
      document.querySelector("nav").classList.remove("dark-mode");
      document.querySelector("div").classList.remove("dark-mode");
      document.querySelector("button").classList.remove("dark-mode");
      document.querySelector("a").classList.remove("text-red-600");
      this.darkMode = false;
      this.$emit("light");
    },
    async get3DGraphData(dtls) {
      try {
        const response = await apiService.WellServices.getPlanActualData({
          well_id: dtls.wellId,
          wellbore_name: dtls.wellboreId,
        });
        this.graphData = response.data;
        let val = this.graphData.rows;
      } catch (error) {
        console.log("Trajectory data is not present : ", error.message);
      }

      await this.set3DGraphData();
    },
    set3DGraphData() {
      this.megaData = this.graphData.rows.map((ele) => {
        return {
          x: ele[this.selectedTags.x],
          y: ele[this.selectedTags.y],
        };
      });
      this.drillingdifficultyremarks = this.graphData.rows.map((ele) => {
        return {
          x: ele["drillingdifficultyremarks"],
          y: ele[this.selectedTags.y],
          z: ele[this.selectedTags.x],
        };
      });
      // console.log("dificulty ", dificulty)

      this.megaData1 = this.graphData.rowsPlan.map((ele) => {
        return {
          x: ele[this.selectedTags.x] ? ele[this.selectedTags.x] : 0,
          y: ele[this.selectedTags.y] ? ele[this.selectedTags.y] : 0,
        };
      });

      this.megaData = this.megaData.filter((ele) => {
        return (
          (ele.x != null || ele.x != undefined) &&
          (ele.y != null || ele.y != undefined)
        );
      });
      this.megaData1 = this.megaData1.filter((ele) => {
        return (
          (ele.x != null || ele.x != undefined) &&
          (ele.y != null || ele.y != undefined)
        );
      });
      this.drillingdifficultyremarks = this.drillingdifficultyremarks.filter(
        (ele) => {
          return (
            (ele.x != null || ele.x != undefined) &&
            (ele.y != null || ele.y != undefined)
          );
        }
      );
    },
    async buildSVG() {
      var margin = {
        top: 10,
        right: 10,
        bottom: 50,
        left: 50,
      },
        width = this.width / 2 - margin.left - margin.right - 100,
        height = this.height - margin.top - margin.bottom;

      let labelFontSize = 10;
      let tooltipFontSize = 10;
      var maxX = 0;
      var maxY = 0;
      var minX = 0;
      var minY = 0;
      //create base svg
      svg = select("#ddi" + this.widgetId);
      if (svg) {
        svg.selectAll("*").remove();
        svg = "";
      } else {
        let ddi = document.createElement("div");
        ddi.setAttribute("id", "ddi" + this.widgetId);
      }
      svg = select("#ddi" + this.widgetId)
        .append("svg")
        .style("background", (this.darkDark !== 'white'  ? 'var(--navBar2Bg)' : 'var(--wdigetBg)'))
        .style('transition','var(--transition)')
        .attr("width", width + margin.left + margin.right + 130)
        .attr("height", height + margin.top + margin.bottom)
        .attr("id", "ddiSVG")
        .append("g")
        .attr("id", "ddi1")
        .attr("transform", `translate(${margin.left + 80} , ${margin.top})`)
        .attr("width", width + margin.left + margin.right + 130)
        .attr("height", height + margin.top + margin.bottom);


      // structure of drillingdifficultyremarks

      var drillingdifficultyremarks;

      drillingdifficultyremarks = this.drillingdifficultyremarks.map(function (
        d
      ) {
        let obj = {
          y: parseFloat(d["y"]),
          x: d["x"],
          z: parseFloat(d["z"]),
        };
        maxX = maxX < obj.x ? obj.x : maxX;
        minX = minX < obj.x ? minX : obj.x;
        maxY = maxY < obj.y ? obj.y : maxY;
        minY = minY < obj.y ? minY : obj.y;
        return obj;
        // return obj;
      });
      let currentAlrt = "";

      let filterdifficultyremarks = [];
      drillingdifficultyremarks.filter((d) => {
        if (d.x != currentAlrt) {
          filterdifficultyremarks.push(d);
          currentAlrt = d.x;
        }
      });
      //structure current TVD
      var tvdData;
      tvdData = this.megaData1.map(function (d) {
        let obj = {
          y: parseFloat(d["y"]),
          x: parseFloat(d["x"]),
          name: "ft",
        };
        maxX = maxX < obj.x ? obj.x : maxX;
        minX = minX < obj.x ? minX : obj.x;
        maxY = maxY < obj.y ? obj.y : maxY;
        minY = minY < obj.y ? minY : obj.y;
        return obj;
      });
      //structure current DDI
      var ddiData = this.megaData.map(function (d) {
        let obj = {
          y: parseFloat(d["y"]),
          x: parseFloat(d["x"]),
          name: "",
        };
        maxX = maxX < obj.x ? obj.x : maxX;
        minX = minX < obj.x ? minX : obj.x;
        maxY = maxY < obj.y ? obj.y : maxY;
        minY = minY < obj.y ? minY : obj.y;
        return obj;
      });
      //create axis
      if (this.isXmanualRange) {
        minX = this.xStartRange;
        maxX = this.xEndRange;
      }

      if (this.isYmanualRange) {
        minY = this.yStartRange;
        maxY = this.yEndRange;
      }

      var x = scaleLinear().domain([minX, maxX]).range([0, width]);
      var y = scaleLinear().domain([maxY, minY]).range([height, 0]);
      if (!this.isXmanualRange) {
        x.nice()
      }

      if (!this.isYmanualRange) {
        y.nice()
      }
      //create planned line chart

      let filteredtvdData = tvdData.filter((d) => {
        return d.x && d.x >= minX && maxY >= d.y && d.y >= minY;
      });
      svg
        .append("path")
        .datum(filteredtvdData)
        .attr("fill", "none")
        .attr("class", "tvd")
        .attr("stroke", this.planColor)
        .attr("stroke-width", 3)
        .attr(
          "d",
          line()
            .x(function (d) {
              return x(d.x);
            })
            .y(function (d) {
              return y(d.y);
            })
        )
        .on("mouseover", function (e) {
          let data = select(this).data()[0];
          var bisectDate = bisector(function (d) {
            return d.y;
          }).left; // **
          let x0 = y.invert(pointer(e)[1]);

          let i = bisectDate(data, x0, 1);
          let d0 = data[i - 1];
          let d1 = data[i];
          let d = d1;
          realTimeTooltip(e, [d0], true);
        })
        .on("mousemove", function (e) {
          let data = select(this).data()[0];
          var bisectDate = bisector(function (d) {
            return d.y;
          }).left;
          let x0 = y.invert(pointer(e)[1]);

          let i = bisectDate(data, x0, 1);
          let d0 = data[i - 1];
          let d1 = data[i];
          let d = d1;
          realTimeTooltip(e, [d0], true);
        })
        .on("mouseout", function (e) {
          hideRealTimeTooltip(e, "", false);
        });
      let filteredData = ddiData.filter((d) => {
        return d.x && d.x >= minX && maxY >= d.y && d.y >= minY;
      });
      svg
        .append("path")
        .datum(filteredData)
        .attr("class", "ddi")
        .attr("fill", "none")
        .attr("stroke", this.actualColor)
        .attr("stroke-width", 3)
        .attr(
          "d",
          line()
            .x(function (d) {
              if (maxX >= d.x && d.x >= minX) return x(d.x);
            })
            .y(function (d) {
              if (maxY >= d.y && d.y >= minY) return y(d.y);
            })
        )
        .on("mouseover", function (e) {
          let data = select(this).data()[0];
          var bisectDate = bisector(function (d) {
            return d.y;
          }).left;
          let x0 = y.invert(pointer(e)[1]);

          let i = bisectDate(data, x0, 1);
          let d0 = data[i - 1];
          let d1 = data[i];
          let d = d1;
          realTimeTooltip(e, [d0], true);
        })
        .on("mousemove", function (e) {
          let data = select(this).data()[0];
          var bisectDate = bisector(function (d) {
            return d.y;
          }).left;
          let x0 = y.invert(pointer(e)[1]);

          let i = bisectDate(data, x0, 1);
          let d0 = data[i - 1];
          let d1 = data[i];
          let d = d1;
          //
          realTimeTooltip(e, [d0], true);
        })
        .on("mouseout", function (e) {
          hideRealTimeTooltip(e, "", false);
        });
      var xAxis = axisBottom(x).ticks();
      var yAxis = axisLeft(y).ticks();

      //create grid axis
      const xAxisGrid = axisBottom(x).tickSize(-height).tickFormat("").ticks();
      const yAxisGrid = axisLeft(y).tickSize(-width).tickFormat("").ticks();

      var clip = svg
        .append("defs")
        .append("svg:clipPath")
        .attr("id", "clip")
        .append("svg:rect")
        .attr("width", width - 80)
        .attr("height", height - 10)
        .attr("x", 0)
        .attr("y", 0);
      svg
        .append("g")
        .attr("class", "x axis-grid")
        .attr("transform", "translate(0," + height + ")")
        .style("color", this.gridColor)
        .style("fill", this.gridBackgroundColor)
        .attr("stroke-opacity", 0.6)
        .call(xAxisGrid);
      svg
        .append("g")
        .attr("class", "y axis-grid")
        .style("color", this.gridColor)
        .attr("stroke-opacity", 0.6)
        .call(yAxisGrid);
      svg
        .append("g")
        .attr("class", "xAxis")
        .attr("id", "test")
        .attr("transform", "translate(0," + height + ")")
        .attr('color', this.graphColor)
        .style("font-size", labelFontSize)
        .call(xAxis);
      svg.selectAll(".xAxis").selectAll("text").attr("color", (this.yAxisLabel ? this.yAxisLabel : this.darkDark !== 'white'  ? 'white' : 'black'));
      svg
        .append("g")
        .attr("class", "yAxis")
        .attr('color', this.graphColor)
        .style("font-size", labelFontSize)
        .call(yAxis);
      svg.selectAll(".yAxis").selectAll("text").attr("color", (this.yAxisLabel ? this.yAxisLabel : this.darkDark !== 'white'  ? 'white' : 'black'));

      svg
        .append("text")
        .attr("class", "x label")
        .attr("text-anchor", "middle")
        .attr("transform", "translate(" + (width / 2) + "," + (height + 35) + ")")
        .style("fill", (this.graphLabelColor ? this.graphLabelColor : this.darkDark !== 'white'  ? 'white' : 'black'))
        .style("font-size", `${this.xAxisLabelSize}px`)
        .text("DDI".toUpperCase());

      svg
        .append("text")
        .attr("class", "y label")
        .attr("text-anchor", "middle")
        .attr("transform", "translate(" + (-45) + "," + (height / 2) + ")rotate(-90)")
        .style("fill", (this.graphLabelColor?this.graphLabelColor :this.darkDark !== 'white'  ? 'white': 'black'))
        .style("font-size", `${this.yAxisLabelSize}px`)
        .text("TVD (ft)".toUpperCase());

      //create actual line chart
      let realTimeTooltip = (event, data, isShow) => {
        this.mouse_x = event.clientX;
        this.mouse_y = event.clientY;
        let text = "";
        text = `${this.selectedTags.x.toUpperCase()} : ${data[0]["x"].toFixed(
          2
        )} </br>${this.selectedTags.y.toUpperCase()} : ${data[0]["y"].toFixed(
          2
        )} </br>`;
        this.tvdEWNSTooltipValues = text;
        this.isTvdEWNSTooltip = isShow;
      };
      let hideRealTimeTooltip = (event, text, isShow) => {
        if (this.tooltipTimeOut) {
          clearTimeout(this.tooltipTimeOut);
        }
        this.tooltipTimeOut = setTimeout(() => {
          this.mouse_x = 0;
          this.mouse_y = 0;
          this.tvdEWNSTooltipValues = "";
          this.isTvdEWNSTooltip = isShow;
        }, 1000);
      };
    },
    setContextIdWidget(e) {
      this.$store.dispatch("disp/setContextId", {
        contextId: this.displayId,
        contextWidgetId: this.id,
        contextType: "widget",
      });
    },
    async loadTable(dtls) {
      let dataForTable = await apiService.WellServices.getDataTableData({
        well_id: dtls.wellId,
        wellbore_name: dtls.wellboreId,
      });
      let sequence = [
        "tvd",
        "drillingdifficultyindex",
        "drillingdifficultyremarks",
      ];
      let tempCol = {};
      dataForTable.data.columns.map((col) => {
        tempCol[col.field] = col;
      });
      let column = [];
      sequence.map((ele) => {
        if (tempCol[ele]) {
          column.push(tempCol[ele]);
          delete tempCol[ele];
        }
      });
      this.table = {
        isLoading: false,
        isReSearch: false,
        columns: column,
        rows: dataForTable.data.rows,
        sortable: {
          order: "name",
          sort: "asc",
        },
      };
    },
    tableLoadingFinish() {

    },
    updateCheckedRows() {

    },
    doSearch() {

    },
  },
  watch: {

    isSwitched: async function (newVal, oldVal) {
      try {
        let prevTags = { ...this.selectedTags };
        this.selectedTags.x = prevTags.y;
        this.selectedTags.y = prevTags.x;
        this.set3DGraphData();
        this.buildSVG();
      } catch (error) {
        //
      }
    },
    actualColor: async function (newVal, oldVal) {
      this.buildSVG();
    },
    planColor: async function (newVal, oldVal) {
      this.buildSVG();
    },
    selectedTags: {
      handler(val) {
        this.set3DGraphData();
        this.buildSVG();
      },
      deep: true,
    },
    gridColor: async function (newVal, oldVal) {
      try {
        this.buildSVG();
      } catch (error) {
        //
      }
    },
    gridBackground: async function (newVal, oldVal) {
      try {
        this.buildSVG();
      } catch (error) {
        //
      }
    },
    xAxisLabel: async function (newVal, oldVal) {
      try {
        this.buildSVG();
      } catch (error) {
        //
      }
    },
    yAxisLabel: async function (newVal, oldVal) {
      try {
        this.buildSVG();
      } catch (error) {
        //
      }
    },

    widgetWidth: async function (newVal, oldVal) {
      try {
        // await this.clearSvgHandler();
        this.width = newVal;
        this.buildSVG();
      } catch (error) {
        //
      }
    },
    widgetHeight: async function (newVal, oldVal) {
      try {
        // await this.clearSvgHandler();
        this.height = newVal;
        this.buildSVG();
      } catch (error) {
        //
      }
    },
    blinkAlert: function (newVal) {
      this.isBlinkAlert = newVal;
      if (this.isBlinkAlert == false) {
        for (var i = 1; i < id; i++) {
          window.clearInterval(i);
        }
      }
    },
    alertColor: async function (newVal, oldVal) {
      let i = 0;
      if (this.isBlinkAlert) {
        id = setInterval(() => {
          let color = ["white", newVal];
          i = (i + 1) % color.length;
          this.newAlertColor = color[i];
          if (!this.isBlinkAlert) {
            clearInterval(id);
          }
        }, 1000);
      } else {
        this.newAlertColor = newVal;
      }
    },
    darkDark() {
      this.buildSVG();
    },
    graphLabelColor() {
      this.buildSVG();
    },
    graphColor() {
      this.buildSVG();
    },
    xAxisLabelSize() {
      this.buildSVG();
    },
    yAxisLabelSize() {
      this.buildSVG();
    },
    gridColor() {
      this.buildSVG();
    },
    gridBackgroundColor() {
      this.buildSVG();
    },
    isXmanualRange() {
      this.buildSVG();
    },
    xStartRange() {
      this.buildSVG();
    },
    xEndRange() {
      this.buildSVG();
    },
    isYmanualRange() {
      this.buildSVG();
    },
    yStartRange() {
      this.buildSVG();
    },
    yEndRange() {
      this.buildSVG();
    },
  },
  emits: ["toggle-madal"],
};
</script>
<style scoped>
h1 {
  font-size: 20px;
}

.axis-grids .tick {
  display: none !important;
}

.arrangementClass {
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.15);
}

path.domain {
  color: red;
}
</style>
