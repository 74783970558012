export default {
    'rects': [
    ],

    displays: [
        // {
        //     'rects': [{
        //         'width': 150,
        //         'height': 50,
        //         'top': 10,
        //         'left': 10,
        //         'draggable': true,
        //         'resizable': true,
        //         'minw': 10,
        //         'minh': 10,
        //         'axis': 'both',
        //         'parentLim': true,
        //         'snapToGrid': false,
        //         'aspectRatio': false,
        //         'zIndex': 2,
        //         'color': '#AED581',
        //         'active': false,
        //         isWidget: true,
        //         name: 'label',
        //         title: "WAAQQ",
        //         autoFit: true,
        //         titleSize: 35,
        //         titleDisplay: true
        //     }]
        // }
    ],
    minimizedWidgets: [],
    darkmode: 'grey',
    "leftSideBarOpen": false,
    "leftSideDbBarOpen": false,
    "isRightOpen": false,
    "toggle_right_Sidebar": false,
    displayIds: [],
    plotConfig: [],
   
    whichIsActiveInOpentab3:[],
    toolTipforlastUpdatedTime:{logType:'time',lastUpdatedTime:''},
    
};
