import api from "../../../api/services";

const dept = "dept";
export default {
  settingUsersData({ commit }, dtls) {
    commit('settingUsersData', dtls)
  },
  async getWells({ commit, state }) {
    let wells = {};
    try {
      let result = await api.WellServices.getWells({ customer: state.customer })

      if (result == null) return;

      let cols = result.data["columns"];

      let wellPos = cols.indexOf("well");
      let srcWellPos = cols.indexOf("src_well_id");
      let srcWellBorePos = cols.indexOf("src_well_bore_id");
      let wellborePos = cols.indexOf("wellbore");
      let statusPos = cols.indexOf("well_status");
      let tableNamePos = cols.indexOf("log_data");
      let logIdPos = cols.indexOf("sourceid");
      let namePos = cols.indexOf("well_name");
      let sDepthPos = cols.indexOf("start_depth");
      let eDepthPos = cols.indexOf("end_depth");
      let sTimePos = cols.indexOf("start_time");
      let eTimePos = cols.indexOf("end_time");
      let tagsPos = cols.indexOf("stdMnemonicsData");
      let logPos = cols.indexOf("log");
      let logidPos = cols.indexOf("logid");
      let rigPos = cols.indexOf("rigname");
      let wellBoreNamePos = cols.indexOf("wellbore_name");
      let wellLat = cols.indexOf("well_latitude");
      let wellLong = cols.indexOf("well_longitude");
      let topicName = cols.indexOf("topicname");
      let swabSurgeTopicnamePos = cols.indexOf("swab_surge_topicname");
      let timeZonePos = cols.indexOf("time_zone");
      let unitConversionPos = cols.indexOf("unit_conversion");

      let wellboreArray = {};
      let logsArray = {};
      let wellbore = {};
      let wellboreObj = {};
      let log = {};
      let well = {};

      if (wellPos !== -1) {
        let data = result.data.data;

        for (let i = 0; i < data.length; i++) {
          log = {};
          let logType = data[i][logPos];
          let logid = data[i][logidPos];
          let srcWellId = data[i][srcWellPos];
          let srcWellBoreId = data[i][srcWellBorePos];
          log["srcWellId"] = srcWellId;
          log["srcWellBoreId"] = srcWellBoreId;
          log["type"] = logType;
          log["logTypeId"] = logid;
          log["id"] = data[i][logIdPos];
          log["table"] = data[i][tableNamePos];

          if (data[i][logPos] === "depth") {
            log["startDepth"] = data[i][sDepthPos];
            log["endDepth"] = data[i][eDepthPos];
          }
          if (data[i][logPos] === "time") {
            log["startTime"] = data[i][sTimePos];
            log["endTime"] = data[i][eTimePos];
          }
          log["topicname"] = data[i][topicName];
          log['swab_surge_topicname'] = data[i][swabSurgeTopicnamePos]
          console.log("well formationg ``````````````: ", i, timeZonePos, data[i][timeZonePos])
          log['time_zone'] = data[i][timeZonePos];
          log['unit_conversion'] = data[i][unitConversionPos];
          log["tags"] = data[i][tagsPos]["tags"];
          log["units"] = data[i][tagsPos]["units"];
          log["sourceUnit"] = data[i][tagsPos]["sourceUnit"];
          log["displayTags"] = data[i][tagsPos]["display"];
          log["description"] = data[i][tagsPos]["description"];
          log["mnemonics"] = data[i][tagsPos]["mnemonics"];
          log["state"] = data[i][statusPos];

          let wellId = data[i][wellPos];
          console.log("~~~~~~~~~~~ well positions ~~~~~~~~~~~ ", wellId);
          let wellObj = wells[wellId];

          if (wellObj) {
            let wellboreId = data[i][wellborePos];
            wellboreArray = wellObj["wellbores"];
            if (!wellboreArray[wellboreId]) {
              let wellboreObj = {};
              let logsArray = {};
              wellboreObj["state"] = data[i][statusPos];
              logsArray[logType] = log;

              wellboreObj["logs"] = logsArray;
              wellboreArray[wellboreId] = wellboreObj;
              if (!wellboreObj["wellbore_name"])
                wellboreObj["wellbore_name"] = data[i][wellBoreNamePos];
            } else {
              let wellboreObj = wellObj["wellbores"][wellboreId];
              logsArray = wellboreObj["logs"];
              logsArray[logType] = log;

              wellboreObj["logs"] = logsArray;
              if (!wellboreObj["wellbore_name"])
                wellboreObj["wellbore_name"] = data[i][wellBoreNamePos];
            }

            wellObj["wellbores"] = wellboreArray;
            if (logType == "depth" || logType == "time") {
              wellObj["name"] = data[i][namePos];
              if (!wellObj["rigname"]) wellObj["rigname"] = data[i][rigPos];

              if (!wellObj["lat"]) wellObj["lat"] = data[i][wellLat];
              if (!wellObj["long"]) wellObj["long"] = data[i][wellLong];
            }
            wells[wellId] = wellObj;
          } else {
            let wellboreId = data[i][wellborePos];
            let wellboreObj = {};
            let wellboreArray = {};
            let logsArray = {};
            wellboreObj["state"] = data[i][statusPos];
            logsArray[logType] = log;

            wellboreObj["logs"] = logsArray;
            if (!wellboreObj["wellbore_name"])
              wellboreObj["wellbore_name"] = data[i][wellBoreNamePos];
            wellboreArray[wellboreId] = wellboreObj;

            wellObj = {};
            if (logType == "depth" || logType == "time") {
              wellObj["name"] = data[i][namePos];
              if (!wellObj["rigname"]) wellObj["rigname"] = data[i][rigPos];

              if (!wellObj["lat"]) wellObj["lat"] = data[i][wellLat];
              if (!wellObj["long"]) wellObj["long"] = data[i][wellLong];
            }
            wellObj["wellbores"] = wellboreArray;
            wells[wellId] = wellObj;
          }
        }
      }

      commit("setWells", wells);
    } catch (e) {
      console.error("Error in set wells : ", e);
    }
  },

  setSingleValue({ commit }, dtls) {
    commit("setSingleValue", dtls);
  },

  async setCustomer({ commit }, { customer, isInitial }) {
    commit("setCustomer", customer);
    let wells = {};
    try {
      let result = await api.WellServices.getWells({ customer: customer });

      if (result == null) return;

      let cols = result.data["columns"];

      let wellPos = cols.indexOf("well_id");
      let wellborePos = cols.indexOf("well_borename");
      let srcWellPos = cols.indexOf("src_well_id");
      let srcWellBorePos = cols.indexOf("src_well_bore_id");
      let statusPos = cols.indexOf("well_status");
      let tableNamePos = cols.indexOf("log_data");
      let logIdPos = cols.indexOf("src_well_id");
      let namePos = cols.indexOf("well_name");
      let sDepthPos = cols.indexOf("start_depth");
      let eDepthPos = cols.indexOf("end_depth");
      let sTimePos = cols.indexOf("start_time");
      let eTimePos = cols.indexOf("end_time");
      let tagsPos = cols.indexOf("stdMnemonicsData");
      let logPos = cols.indexOf("log");
      let logidPos = cols.indexOf("logid");
      let rigPos = cols.indexOf("rig_name");
      let wellBoreNamePos = cols.indexOf("well_borename");
      let wellLat = cols.indexOf("latitude");
      let wellLong = cols.indexOf("longitude");
      let topicName = cols.indexOf("topicname");
      let swabSurgeTopicnamePos = cols.indexOf("swab_surge_topicname");
      let timeZonePos = cols.indexOf("time_zone");
      let unitConversionPos = cols.indexOf("unit_conversion");
      let wellboreArray = {};
      let logsArray = {};
      let wellbore = {};
      let wellboreObj = {};
      let log = {};
      let well = {};

      if (wellPos !== -1) {
        let data = result.data.data;
        for (let i = 0; i < data.length; i++) {
          log = {};
          let srcWellId = data[i][srcWellPos];
          let srcWellBoreId = data[i][srcWellBorePos];
          log["srcWellId"] = srcWellId;
          log["srcWellBoreId"] = srcWellBoreId;
          let logType = data[i][logPos];
          let logid = data[i][logidPos];
          log["type"] = logType;
          log["logTypeId"] = logid;

          log["id"] = data[i][logIdPos];
          log["table"] = data[i][tableNamePos];

          if (data[i][logPos] === "depth") {
            log["startDepth"] = data[i][sDepthPos];
            log["endDepth"] = data[i][eDepthPos];
          }
          if (data[i][logPos] === "time") {
            log["startTime"] = data[i][sTimePos];
            log["endTime"] = data[i][eTimePos];
          }

          log["topicname"] = data[i][topicName];
          log['swab_surge_topicname'] = data[i][swabSurgeTopicnamePos]
          // console.log("well formationg : ", i, timeZonePos, data[i][timeZonePos])
          log['time_zone'] = data[i][timeZonePos];
          log['unit_conversion'] = data[i][unitConversionPos];
          log["tags"] = data[i][tagsPos]["tags"];
          log["units"] = data[i][tagsPos]["units"];
          log["sourceUnit"] = data[i][tagsPos]["sourceUnit"];
          log["displayTags"] = data[i][tagsPos]["display"];
          log["description"] = data[i][tagsPos]["description"];
          log["mnemonics"] = data[i][tagsPos]["mnemonics"];
          log["state"] = data[i][statusPos];

          let wellId = data[i][wellPos];

          let wellObj = wells[wellId];

          if (wellObj) {
            let wellboreId = data[i][wellborePos];
            wellboreArray = wellObj["wellbores"];
            if (!wellboreArray[wellboreId]) {
              let wellboreObj = {};
              let logsArray = {};
              wellboreObj["state"] = data[i][statusPos];
              logsArray[logType] = log;

              wellboreObj["logs"] = logsArray;
              if (!wellboreObj["wellbore_name"])
                wellboreObj["wellbore_name"] = data[i][wellBoreNamePos];
              wellboreArray[wellboreId] = wellboreObj;
            } else {
              let wellboreObj = wellObj["wellbores"][wellboreId];
              logsArray = wellboreObj["logs"];
              logsArray[logType] = log;
              if (!wellboreObj["wellbore_name"])
                wellboreObj["wellbore_name"] = data[i][wellBoreNamePos];
              wellboreObj["logs"] = logsArray;
            }

            wellObj["wellbores"] = wellboreArray;
            if (logType == "depth" || logType == "time" || logType == "trajectory") {
              wellObj["name"] = data[i][namePos];
              if (!wellObj["rigname"]) wellObj["rigname"] = data[i][rigPos];

              if (!wellObj["lat"]) wellObj["lat"] = data[i][wellLat];
              if (!wellObj["long"]) wellObj["long"] = data[i][wellLong];
              if (!wellObj["well_id"]) wellObj["well_id"] = data[i][wellPos];
              if (!wellObj["well_bore_name"])
                wellObj["well_bore_name"] = data[i][wellborePos];
            }

            wells[wellId] = wellObj;
          } else {
            let wellboreId = data[i][wellborePos];
            let wellboreObj = {};
            let wellboreArray = {};
            let logsArray = {};
            wellboreObj["state"] = data[i][statusPos];
            logsArray[logType] = log;
            if (!wellboreObj["wellbore_name"])
              wellboreObj["wellbore_name"] = data[i][wellBoreNamePos];
            wellboreObj["logs"] = logsArray;
            wellboreArray[wellboreId] = wellboreObj;

            wellObj = {};

            if (logType == "depth" || logType == "time" || logType == "trajectory") {
              wellObj["name"] = data[i][namePos];
              if (!wellObj["rigname"]) wellObj["rigname"] = data[i][rigPos];

              if (!wellObj["lat"]) wellObj["lat"] = data[i][wellLat];
              if (!wellObj["long"]) wellObj["long"] = data[i][wellLong];
            }
            wellObj["wellbores"] = wellboreArray;
            wells[wellId] = wellObj;
          }
        }
      }
      // console.log("~~~~~~~~~~~ well positions ~~~~~~~~~~~ ", wells);
      commit("setWells", wells);
      if (!isInitial) {
        commit("setCustomerUpdate", true);
      }
    } catch (e) {
    } finally {
    }
  },

  setCustomerUpdate({ commit }, isCustomerUpdate) {
    commit("setCustomerUpdate", isCustomerUpdate);
  },

  async setTndWells({ commit, state }) {
    try {
      let response = await api.WellServices.getWellsInformation({
        customer: state.customer,
      });

      let wells = response.data.rows;
      commit("setTndWells", wells);
    } catch (error) {
      console.error(error);
    }
  },

  startLoading({ commit }) {
    commit("startLoader");
  },

  stopLoading({ commit }) {
    commit("stopLoader");
  },

  async getOptimizations({ commit }) {
    try {
      let response = await api.WellServices.getOptimizations();
      commit("getOptimizations", response.data.rows);
    } catch (error) {
      console.error("error in get optimizations : ", error);
    }
  },
  async getAlertAnnotations({ commit }) {
    try {
      let response = await api.AlertServices.listNodeAnnotation();
      console.log("alert annotations", response.data)
      commit("getAlertAnnotations", response.data);
    } catch (error) {
      console.error("error in get optimizations : ", error);
    }
  },

  async getAlertsList({ commit }) {
    try {
      let response = await api.AlertServices.listDefaultAlerts();
      console.log("alert s", response.data)
      commit("getAlertsList", response.data);
    } catch (error) {
      console.error("error in get optimizations : ", error);
    }
  },

  setAccessPermissions({ commit }) {
    commit("setAccessPermissions");
  },
  saveReplayDetailsToStore({ commit }, details) {
    commit("setReplayDetails", { details });
  },
  setBooleanValue({ commit }, newValue) {
    commit('SET_BOOLEAN_VALUE', newValue);
  },
  showHideHeader({ commit }, newValue) {
    commit('showHideHeader', newValue);
  },
}
