<template :id="'CtvdTrajectoryTrend' + this.widgetId">
  <div>
    <p class="hidden">{{ rtColumnDisplay }}</p>
    <p class="hidden">{{ columnDisplay }}</p>
    <p class="hidden">{{ planColumnDisplay }}</p>
    <div class="block tool_group" :style="{
      top: this.mouse_y - 25 + 'px',
      left: this.mouse_x + 'px',
    }" :id="'tooltip' + this.widgetId">
      <div v-if="isPlanTooltip" class="actc_tool text-white">
        <p :key="i" v-for="(msg, i) of planTooltipValues.split('</br>')">
          {{ msg }}
        </p>
        <!-- {{planTooltipValues | json}} -->
        <!-- <p :key="i" v-for="(msg, i) of planTooltipValues">
                {{ msg }}
              </p> -->
      </div>
    </div>

    <TDNavbar :wellId="wellId" :wellboreId="wellboreId" :showAll="zoomOut" :onToggleSideBar="onToggleSideBar"
      :onChangeValues="onChangeValues" :onSaveBha="onSaveBha" :displayId="displayId" :widgetId="widgetId"
      :isSaved="isSaved" :time_zone="time_zone" :startTime="wellStartTime" :setDrilligAsDefault="setDrilligAsDefault"/>

    <span class="hidden">{{ darkDark }}</span>
    <span class="hidden">{{ leftSideBarOpenForTD }}</span>

    <!-- {{leftSideBarOpenForTD}} -->

    <div class="flex_items">
      <div class="flex-item flex_1" :style="{
        background: darkDark !=='white'
          ? gridBackground || 'var(--sidebarMnList)'
          : gridBackground || '#ffffff',
      }">
        <TdLeftbar :wellId="wellId" :wellboreId="wellboreId" :sideBarForProp="sideBarForProp"
          :toggleSidebarProp="toggleSidebar" :assemblySelected="renderGraph" :torHklToggle="torHklToggle"
          :rtVsPlanToggle="rtVsPlanToggle" :rtToggle="rtToggle" :isRealtime="isRealtime" :isSaved="isSaved"
          :listCount="listCount" :bhaSelected="bhaSelected" :fmChange="fmChange" :planvsrt="planvsrt"
          :planvsreal="planvsreal" :zoomOnOff="zoomOnOff" :planId="planId" :bhaId="bhaId" :bhaName="bhaName"
          :selectedWellInfo="selectedWellInfo" :hklOrTor="hklOrTor != 'TORQUE'" :selectedSavedRun="selectedSavedRun"
          :isZoomFeature="isZoomFeature" :changeWellSelectHandler="resetGraph" />
      </div>
      <div v-if="!planvsreal || isRTvsPlan" class="flex-item flex_2 flex" :style="{
        width:
          this.sideBarForProp == 'TD'
            ? this.isRTvsPlan ? this.widgetWidth - this.widgetWidth * 0.72 + '%' : this.widgetWidth - this.widgetWidth * 0.58 + '%'
            : this.isRTvsPlan ? this.widgetWidth - this.widgetWidth * 0.70 + '%' : this.widgetWidth - this.widgetWidth * 0.52 + '%',
      }">
        <div class="w-full" :style="{
          background: darkDark !=='white'
            ? gridBackground || 'var(--sidebarMnList)'
            : gridBackground || '#FFFFF7',
        }" :id="'tvdTrajectoryTrend' + this.widgetId"></div>
      </div>
      <div v-if="planvsreal || isRTvsPlan" class="flex-item flex_2 flex" :style="{
        width:
          this.sideBarForProp == 'TD'
            ? this.isRTvsPlan ? this.widgetWidth - this.widgetWidth * 0.72 + '%' : this.widgetWidth - this.widgetWidth * 0.60 + '%'
            : this.isRTvsPlan ? this.widgetWidth - this.widgetWidth * 0.70 + '%' : this.widgetWidth - this.widgetWidth * 0.629 + '%',
      }">
        <div class="w-full" :style="{
          background: darkDark !=='white'
            ? gridBackground || 'var(--sidebarMnList)'
            : gridBackground || '#FFFFF7',
        }" :id="'tvdTrajectoryTrendRT' + this.widgetId"></div>
      </div>
      <div :style="{
        background: darkDark !=='white'
          ? gridBackground || 'var(--navBar2Bg)'
          : gridBackground || '#FFFFF7',
      }" class="flex-item flex_2 flex">
        <div :class="'wrapper flex_1 mr-1 wrapper-cond-3'">
          <div class="" v-if="rtColumnDisplay">
            <ul>
              <p class="text-center text-xs">RT</p>
              <hr class="legends_hr_" />
              <li :key="i" v-for="(msg, i) of rtColumn"
                :style="{ color: this[msg], 'font-size': 'calc(0.2vw + 0.2vh + 0.65vmin)' }">
                <p class="flex mb-1">
                  <svg class="diamondShape_" :id="'diamondShape_' + msg + this.widgetId"></svg>
                  <span class="break-all legend_txt">
                    {{ msg.toUpperCase() }}
                  </span>
                </p>
              </li>
            </ul>
          </div>
          <div class="" v-if="columnDisplay">
            <ul>
              <p class="text-center text-xs">Legends</p>
              <hr class="legends_hr_" />
              <li :key="i" v-for="(msg, i) of columns"
                :style="{ color: this[msg], 'font-size': 'calc(0.2vw + 0.2vh + 0.65vmin)' }">
                <p class="flex mb-1">
                  <svg class="dashes_" :id="'dashes_' + msg + this.widgetId"></svg>
                  <span class="break-all legend_txt">
                    {{ msg.toUpperCase() }}
                  </span>
                </p>
                <!-- {{ msg.toUpperCase() }} -->
              </li>
            </ul>
          </div>
          <!-- <div class="" v-if="planColumnDisplay">
            <ul>
              <p class="text-center text-xs">PLAN</p>
              <hr class="legends_hr_" />
              <li :key="i" v-for="(msg, i) of plannedColumn"
                :style="{ color: this[msg], 'font-size': 'calc(0.2vw + 0.2vh + 0.65vmin)' }">
                <p class="flex mb-1">
                  <svg class="circle_symbol_" :id="'circle_symbol_' + msg + this.widgetId"></svg>
                  <span class="break-all legend_txt">
                    {{ msg.toUpperCase() }}
                  </span>
                </p>

              </li>
            </ul>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiService from "../../../api/services";
import TDNavbar from "../../Navbars/TDNavbar.vue";
import TdLeftbar from "../../Sidebar/TdLeftbar.vue";
import { useTndReplay } from "../../../composable/useTndReplay"
var svg = "";
var svg1 = "";
import {
  select,
  scaleLinear,
  axisBottom,
  axisLeft,
  brush,
  symbol,
  symbolCircle,
  bisector,
  pointer,
  symbolDiamond,
  line,
} from "d3";
export default {
  name: "BroomStickPlot",
  components: {
    TDNavbar,
    TdLeftbar,
  },
  props: {
    openConfig: Function,
    displayId: String,
    widgetHeight: {
      type: Number,
      default: 200,
    },
    widgetWidth: {
      type: Number,
      default: 200,
    },
    gridColor: {
      type: String,
      default: "",
    },

    gridBackground: {
      type: String,
      default: "",
    },
    xAxisLabel: {
      type: String,
      default: "",
    },
    yAxisLabel: {
      type: String,
      default: "",
    },
    xAxisLabelSize: {
      type: Number,
    },
    yAxisLabelSize: {
      type: Number,
    },
    backgroundColor: {
      type: String,
      default: "white",
    },
    widgetId: String,
    isSwitched: {
      type: Boolean,
      default: false,
    },
    actualColor: {
      type: String,
      default: "red",
    },
    planColor: {
      type: String,
      default: "steelblue",
    },
    bhaStartTime: {
      type: String,
      default: "",
    },
    bhaEndTime: {
      type: String,
      default: "",
    },
    isReplay: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  data() {
    return {
      setDrilligAsDefault:false,
      wellStartTime: "",
      broomStickWatcher: null,
      realTimeoutDelay: 10000,
      megaDataRT: [],
      megaData: [],
      isRTvsPlan: false,
      selectedSavedRun: {},
      selectedWellInfo: {},
      isZoomFeature: false,
      mouse_x: 0,
      mouse_y: 0,
      isPlanTooltip: false,
      planTooltipValues: "",
      realtimeTimeOut: "",
      isSaved: false,
      whichShow: "drilling",
      isRealtime: true,
      sideBarForProp: "",
      width: this.widgetWidth,
      height: this.widgetHeight,
      graphData: {
        // rows: [],
        rows: [],
        rowsPlan: [],
        columns: ["hkl", "depth"],
      },
      selectedTags: {
        x: "torque",
        y: "depth ",
      },
      hklOrTor: "TORQUE",
      columns: [],
      rtColumn: [],
      plannedColumn: [],
      colors: [],
      minX: 0,
      maxX: 100,
      minY: 0,
      maxY: 100,
      minRTX:0,
      maxRTX:100,
      minRTY:0,
      maxRTY:100,
      isZoomed: false,
      isZoomedRt:false,
      extent: [],
      extentRT: [],
      soColor: "",
      puColor: "",
      robColor: "",
      slackoff: "",
      pickup: "",
      rotateoffbottom: "",
      slackofftripin: "",
      pickuptripout: "",
      toggleSidebar: "-16",
      sideBarFor: "",
      tdopen: false,

      planId: "",
      bhaId: "",
      sourceid: "",
      bhaName: "",
      start_time: this.bhaStartTime,
      end_time: this.bhaEndTime,
      bhaCount: 0,
      selectedBhaSourceId: "",
      selected_start_time: "",
      selected_end_time: "",
      wellFormation: [],
      geometry: [],
      FM: true,
      planvsreal: false,
      tq_pu_ff1: "",
      tq_pu_ff2: "",
      tq_pu_ff3: "",
      tq_pu_ff4: "",
      tq_pu_ff5: "",
      tq_rob: "",
      tq_so_ff1: "",
      tq_so_ff2: "",
      tq_so_ff3: "",
      tq_so_ff4: "",
      tq_so_ff5: "",
      depthUnit: "(ft)",
      unit: " (k ft-lbf)",
    };
  },
  beforeUnmount() {
    this.broomStickWatcher();
    if (this.realtimeTimeOut) {
      clearTimeout(this.realtimeTimeOut);
    }
  },
  async mounted() {
    let dtls = this.$store.state.disp.displays[this.displayId];
    if (dtls) {
      this.wellId = dtls.wellId;
      this.wellboreId = dtls.wellboreId;
      this.logId = dtls.trajectoryLogId;
      this.wellboreState = dtls.wellboreState;
      this.logType = dtls.logType;
      this.table = dtls.table;
      this.time_zone = dtls.time_zone || "CST6CDT";
      this.wellStartTime = dtls.min
      console.log("~~~ time zone alert : mounted ", dtls);
    }
    this.broomStickWatcher = this.$store.subscribe(async (mutation, state) => {
      switch (mutation.type) {
        case "disp/setDisplay":
          console.log("build svg:::::insubscribe")
          this.selectedSavedRun = {};
          this.selectedWellInfo = {};
          this.wellId = mutation.payload.wellId;
          this.wellboreId = mutation.payload.wellboreId;
          this.logId = mutation.payload.logId;
          this.status = mutation.payload.status;
          this.logType = mutation.payload.logType;
          this.logTypeId = mutation.payload.logTypeId;
          this.time_zone = mutation.payload.time_zone || "CST6CDT";
          this.wellStartTime = mutation.payload.min
          console.log("~~~ time zone alert : mounted ", mutation.payload);
          this.isZoomFeature = false;
          this.mouse_x = 0;
          this.mouse_y = 0;
          this.isPlanTooltip = false;
          this.planTooltipValues = "";
          this.realtimeTimeOut = "";
          this.isSaved = false;
          this.whichShow = "drilling";
          this.isRealtime = true;
          this.sideBarForProp = "";
          this.width = this.widgetWidth;
          this.height = this.widgetHeight;
          this.graphData = {
            // rows: [],
            rows: [],
            rowsPlan: [],
            columns: ["hkl", "depth"],
          };
          this.selectedTags = {
            x: "torque",
            y: "depth",
          };
          this.hklOrTor = "TORQUE";
          this.columns = [];
          this.rtColumn = [];
          this.plannedColumn = [];
          this.colors = [];
          this.minX = 0;
          this.maxX = 100;
          this.minY = 0;
          this.maxY = 100;
          this.isZoomed = false;
          this.isZoomedRt = false;
          this.extent = [];
          this.soColor = "";
          this.puColor = "";
          this.robColor = "";
          this.slackoff = "";
          this.pickup = "";
          this.rotateoffbottom = "";
          this.slackofftripin = "";
          this.pickuptripout = "";
          this.toggleSidebar = "-16";
          this.sideBarFor = "";
          this.tdopen = false;

          this.planId = "";
          this.bhaId = "";
          this.sourceid = "";
          this.start_time = this.bhaStartTime;
          this.end_time = this.bhaEndTime;
          this.bhaCount = 0;
          this.selectedBhaSourceId = "";
          this.selected_start_time = "";
          this.selected_end_time = "";
          this.wellFormation = [];
          this.geometry = [];
          this.FM = true;
          this.planvsreal = false;
          this.tq_pu_ff1 = "";
          this.tq_pu_ff2 = "";
          this.tq_pu_ff3 = "";
          this.tq_pu_ff4 = "";
          this.tq_pu_ff5 = "";
          this.tq_rob = "";
          this.tq_so_ff1 = "";
          this.tq_so_ff2 = "";
          this.tq_so_ff3 = "";
          this.tq_so_ff4 = "";
          this.tq_so_ff5 = "";
          this.buildSVG();
          this.buildSVGRT();
          this.table = mutation.payload.table;
          this.setDrilligAsDefault = this.setDrilligAsDefault ? false:true
          break;

        default:
          break;
      }
    });

    this.buildSVG();
    this.buildSVGRT();
  },
  unmounted() {
    this.clearRuningIntervals();
  },
  computed: {
    darkDark() {
      if (this.$store.state.rect.darkmode !== 'white') {
        this.$store.dispatch("rect/lineGraphYAxis", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          value: "white",
        });
        this.$store.dispatch("rect/lineGraphXAxis", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          value: "white",
        });

        // valueColor
      } else {
        this.$store.dispatch("rect/lineGraphYAxis", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          value: "black",
        });
        this.$store.dispatch("rect/lineGraphXAxis", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          value: "black",
        });
      }
      return this.$store.state.rect.darkmode;
    },
    leftSideBarOpenForTD() {
      if (this.$store.state.rect.leftSideBarOpenForTD)
        this.onToggleSideBar("TD");

      return this.$store.state.rect.leftSideBarOpenForTD;
    },
    rtColumnDisplay() {
      return this.rtColumn.length && (this.isRealtime || this.planvsreal)
        ? true
        : false;
    },
    columnDisplay() {
      return this.columns.length ? true : false;
    },
    planColumnDisplay() {
      return this.plannedColumn.length && (!this.isRealtime || this.planvsreal)
        ? true
        : false;
    },
  },
  methods: {
    zoomOnOff() {
      this.isZoomFeature = !this.isZoomFeature;
      // this.zoomOut();
    },
    fmChange() {
      this.FM = !this.FM;
      this.buildSVG();
      this.buildSVGRT();
    },
    planvsrt() {
      this.planvsreal = !this.planvsreal;
      setTimeout(() => {
        if (this.planvsreal) this.buildSVGRT();
        else this.buildSVG();
      }, 500);
    },
    async getWellFormations() {
      try {
        let formationMakersResponse =
          await apiService.WellServices.getWellFormations({
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
          });
        this.wellFormation = formationMakersResponse.data.rows;
        //
      } catch (error) {
        console.error(error);
      }
    },
    async getWellGeometry() {
      try {
        let geometryResponse = await apiService.WellServices.getWellGeometry({
          well_id: this.wellId,
          wellbore_name: this.wellboreId,
        });
        this.geometry = geometryResponse.data.rows;
        //
      } catch (error) {
        console.error(error);
      }
    },
    async bhaSelected(run, isChecked) {
      if (isChecked) {
        this.selectedBhaSourceId = run.sourceid;
        this.planId = run.plan_id;
        this.bhaId = run.bha_id;
        this.sourceid = run.sourceid;
        this.bhaName = run.name;
        this.selected_start_time = run.start_time;
        this.selected_end_time = run.end_time;
        this.selectedSavedRun = run;
        //
      } else {
        this.selected_start_time = "";
        this.selected_end_time = "";
        this.selectedSavedRun = {};
      }
      await this.get3DGraphData({
        planId: this.planId,
        bhaId: this.bhaId,
        sourceid: this.sourceid,
      });
      this.buildSVG();
      this.buildSVGRT();
    },
    async onSaveBha(run_name) {
      let tempBody = {
        // name: "BHA RUN " + (this.bhaCount + 1),
        name: run_name,
        bha_for: this.hklOrTor,
        well: this.wellId,
        wellbore: this.wellboreId,
        wellsection_id: "",
        bha_id: this.bhaId,
        plan_id: this.planId,
        sourceid: this.sourceid,
        start_time: this.start_time,
        end_time: this.end_time,
      };
      console.log(tempBody);
      //
      let response = "API NOT CALLED";
      if (
        tempBody.name &&
        tempBody.well &&
        tempBody.wellbore &&
        tempBody.bha_id &&
        tempBody.plan_id &&
        tempBody.start_time
      ) {
        response = await apiService.WellServices.saveBha(tempBody);

        this.isSaved = !this.isSaved;
        this.$toast.success("BHA RUN Saved", {
          position: "top",
          duration: 1000,
        });
      } else {
        if (!tempBody.name || !tempBody.start_time) {
          if (!tempBody.name) {
            this.$toast.error("Please Enter Name", {
              position: "top",
              duration: 1000,
            });
          }
          if (!tempBody.start_time) {
            this.$toast.error("Please Select Start Date", {
              position: "top",
              duration: 1000,
            });
          }
        } else {
          this.$toast.error("Please Select Mandatory Details", {
            position: "top",
            duration: 1000,
          });
        }
      }
    },
    onChangeValues(value) {
      if (this.wellId && this.wellboreId) {
        this.whichShow = value;
        this.buildSVG();
        this.buildSVGRT();
      }
    },
    async rtToggle(toggle, planId, bhaId, sourceid, isRealtime) {
      console.log("build svg:::::rtToggle",this.isRTvsPlan)
      this.isRealtime = isRealtime;
      this.planId = planId;
      this.bhaId = bhaId;
      this.sourceid = sourceid;

      if (planId && bhaId) {
        await this.get3DGraphData({ planId, bhaId, sourceid });
        this.buildSVG();
        this.buildSVGRT();
      }
    },
    listCount(count) {
      this.bhaCount = count;
    },
    async torHklToggle(toggle, planId, bhaId, sourceid) {
      try {
        this.hklOrTor = toggle;
        this.planId = planId;
        this.bhaId = bhaId;
        this.sourceid = sourceid;
        this.unit = " (kft-lbf)";

        if (toggle == "HOOKLOAD") this.unit = " (klbs)";
        this.selectedTags.x = toggle;
        if (planId && bhaId) {
          await this.get3DGraphData({ planId, bhaId, sourceid });
          this.buildSVG();
          this.buildSVGRT();
        }
      } catch (error) {
        console.error(error);
      }
    },
    async rtVsPlanToggle(toggle, planId, bhaId, sourceid, isRealtime) {
      try {
        this.isRTvsPlan = !this.isRTvsPlan;
        this.planId = planId;
        this.bhaId = bhaId;
        this.sourceid = sourceid;
        if (planId && bhaId) {
          await this.get3DGraphData({ planId, bhaId, sourceid });
          this.buildSVG(false, isRealtime);
          if (isRealtime || this.isRTvsPlan) this.buildSVGRT();
          else {
            svg1 = select("#tvdTrajectoryTrendRT" + this.widgetId);
            if (svg1) {
              svg1.selectAll("*").remove();
              svg1 = "";
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    zoomOut() {
      this.isZoomed = false;
      this.isZoomedRt = false
      // this.isZoomFeature = false
      this.extent = []
      this.extentRT = []
      this.buildSVG();
      this.buildSVGRT();
    },
    async renderGraph(planId, bhaId, sourceid, bhaCount, data, assembly) {
      console.log("🚀 ➽ file: BroomStickPlot.vue:660  ➽ renderGraph  ➽ assembly ⏩", assembly)
      this.selectedWellInfo = data;
      this.planId = planId;
      this.bhaId = bhaId;
      this.sourceid = sourceid;
      this.bhaCount = bhaCount;

      this.assembly = assembly;
      await this.get3DGraphData({ planId, bhaId, sourceid });
      await this.getWellFormations();
      await this.getWellGeometry();
      this.buildSVG();
      this.buildSVGRT();
    },
    async resetGraph() {
      console.log("reset graph");
      this.graphData = {
        rows: [],
        rowsPlan: [],
        columns: ["hkl", "depth"],
        rows_real_time: [],
      };
      this.planId = "";
      this.bhaId = "";
      this.rtColumn = [];
      this.columns = [];
      await this.set3DGraphData(true);
      this.buildSVG();
      this.buildSVGRT();
    },
    setContextIdWidget(e) {
      this.$store.dispatch("disp/setContextId", {
        contextId: this.displayId,
        contextWidgetId: this.widgetId,
        contextType: "widget",
      });
    },

    async get3DGraphData(dtls) {
      try {

        const response = await apiService.WellServices.getTorqueAndDragData({
          graphFor: this.hklOrTor,
          ...dtls
        });
        this.graphData = response.data;

        let columns = [];
        this.soColor = "#2195ff";
        this.puColor = "#00b545";
        this.robColor = "#FFC000";

        for (let i in response.data.rows[0]) {
          if (i != "bha_id" && i != "planid" && i != "md") {
            columns.push(i);
          }
        }
        this.columns = columns;

        if (this.isRealtime || this.planvsreal) {
          this.slackoff = "#ff006d";

          this.pickup = "#fd91ce";

          this.rotateoffbottom = "#ff6f00";

          this.slackofftripin = "#ac4dff";

          this.pickuptripout = "#53d4ff";
          let dateTimeQuery = {};
          if (this.selected_start_time || this.selected_end_time) {
            dateTimeQuery.start_time = this.selected_start_time;
            dateTimeQuery.end_time = this.selected_end_time;
          }
          if (this.isReplay) {
            this.setData(this.hklOrTor, dateTimeQuery, this.wellId, this.wellboreId, this.time_zone, dtls);
          } else {
            const rtResponse =
              await apiService.WellServices.getTorqueAndDragDataTime({
                graphFor: this.hklOrTor,
                ...dtls,
                ...dateTimeQuery,
                well_id: this.wellId,
                wellbore_name: this.wellboreId,
                time_zone: this.time_zone,
                assembly: this.assembly
              });

            columns = [
              "pickup",
              "rotateoffbottom",
              "slackoff",
              "pickuptripout",
              "slackofftripin",
            ];
            if (rtResponse.data.rows[0] && rtResponse.data.rows[0].well_id) {
              this.rtColumn = columns;
            } else {
              this.rtColumn = [];
            }
            this.rtData = rtResponse.data.rows;
          }
        }
      } catch (error) {
        console.error(error);
      }
      await this.set3DGraphData();
      return true;

    },

    set3DGraphData(isReset=false) {
      this.minX = 0;
      this.maxX = isReset? 100:0;
      this.minY = 0;
      this.maxY =isReset? 100:0;
      this.megaData = this.graphData.rows.map((ele, indexU) => {
        let tempResult = {};
        this.columns.map((col, index) => {
          if (index == 0 && indexU == 0) {
            this.minX = ele[col];
            this.maxX = ele[col];
          }

          tempResult[col] = ele[col];
          if (this.minX > ele[col]) {
            this.minX = ele[col];
          }
          if (this.maxX < ele[col]) {
            this.maxX = ele[col];
          }
        });
        tempResult["md"] = ele["md"];
        if (this.minY > ele["md"]) {
          this.minY = ele["md"];
        }
        if (this.maxY < ele["md"]) {
          this.maxY = ele["md"];
        }
        return tempResult;
      });
      this.minRTX = 0;
      this.maxRTX = 0;
      this.minRTY = 0;
      this.maxRTY = 0;
      this.megaDataRT = this.graphData.rows_real_time.map((ele, indexU) => {
        let tempResult = {};
        this.columns.map((col, index) => {
          if (index == 0 && indexU == 0) {
            this.minRTX = ele[col];
            this.maxRTX = ele[col];
          }

          tempResult[col] = ele[col];
          if (this.minRTX > ele[col]) {
            this.minRTX = ele[col];
          }
          if (this.maxRTX < ele[col]) {
            this.maxRTX = ele[col];
          }
        });
        tempResult["md"] = ele["md"];
        if (this.minRTY > ele["md"]) {
          this.minRTY = ele["md"];
        }
        if (this.maxRTY < ele["md"]) {
          this.maxRTY = ele["md"];
        }
        return tempResult;
      });

      if (this.megaDataRT && this.megaDataRT.length) {
        this.megaDataRT = [
          ...this.megaDataRT,
          ...this.megaData.slice(this.megaDataRT.length),
        ];
      } else {
        this.megaDataRT = this.megaData;
      }
    },

    async buildSVG(isCalledFromZoom) {

      // console.log(`build svg:::::1",${isCalledFromZoom},${this.minX},${this.minXZoom},
      // ``````````````````````````````````````````````````${this.maxY},${this.maxYZoom}`)
      console.log("build svg:::::1:::::::::",this.minX,this.minXZoom,this.maxY,this.maxYZoom)
      if (!this.isReplay) {
        if (this.isRealtime || this.isRTvsPlan) {
          if (this.realtimeTimeOut) {
            clearTimeout(this.realtimeTimeOut);
          }
          this.realtimeTimeOut = setTimeout(() => {
            this.rtToggle(
              {},
              this.planId,
              this.bhaId,
              this.sourceid,
              this.isRealtime
            );
          }, this.realTimeoutDelay);
        } else {
          clearTimeout(this.realtimeTimeOut);
        }
      }

      var margin = {
        top: 10,
        right: this.isRTvsPlan ? this.width * 0.63 : (this.tdopen ? this.width * 0.25 : this.width * 0.15),
        bottom: this.height * 0.10,
        left: 65,
      },
        width = this.width - margin.left - margin.right,
        height = this.height - margin.top - margin.bottom;

      var maxX = 0;
      var maxY = 0;
      var minX = 0;
      var minY = 0;

      let labelFontSize = 12;
      let tooltipFontSize = 10;

      //create base svg
      svg = select("#tvdTrajectoryTrend" + this.widgetId);
      if (svg) {
        svg.selectAll("*").remove();
        svg = "";
      } else {
        let gauge = document.createElement("div");
        gauge.setAttribute("id", "tvdTrajectoryTrend" + this.widgetId);
      }
      svg = select("#tvdTrajectoryTrend" + this.widgetId)
        .append("svg")
        .attr("width", width + margin.left + margin.right - 20)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("id", "tvdTrajectoryTrend1")
        .attr("transform", `translate(${margin.left}, ${margin.top})`);

      //structure for trajectory
      var broomStickData = this.megaData;
      //create axis

      // ;
      console.log("checking:::::::!!!!!!!!!********", this.isZoomFeature && this.isZoomed && this.extent, "this.extent.lenght:::",this.extent);
      if (this.isZoomFeature && this.isZoomed && this.extent) {
        var x = scaleLinear()
          .domain([this.minXZoom || this.minX, this.maxXZoom || this.maxX])
          .range([0, width])
          ;
        var y = scaleLinear()
          .domain([this.maxYZoom || this.maxY, this.minYZoom || this.minY])
          .range([height, 0])

        console.log("this extent rt values inside: ", this.extent);
        if (isCalledFromZoom) {
          this.minXZoom = x.invert(this.extent[0][0]);
          this.maxXZoom = x.invert(this.extent[1][0]);
          this.minYZoom = y.invert(this.extent[0][1]);
          this.maxYZoom = y.invert(this.extent[1][1]);
        }

        x.domain([this.minXZoom, this.maxXZoom]);
        y.domain([this.maxYZoom, this.minYZoom]);
        console.log("went to zoomed view")
      } else {
        this.minXZoom = null;
        this.maxXZoom = null;

        this.minYZoom = null;
        this.maxYZoom = null;
        console.log("minx maxX::::::::1",this.minX,this.maxX)
        var x = scaleLinear()
          .domain([this.minX, this.maxX])
          .range([0, width])
          ;
        var y = scaleLinear().domain([this.maxY, this.minY]).range([height, 0]);
        console.log("went to zoomed view else", this.maxY, this.minY)
      }
      console.log(
        "this extent rt values inside: ",
        "this.minXZoom : ",
        this.minXZoom,
        ", this.maxXZoom : ",
        this.maxXZoom,
        " , this.minYZoom : ",
        this.minYZoom,
        ", this.maxYZoom : ",
        this.maxYZoom,
        "this.minx : ",
        this.minX,
        "this.maxX : ",
        this.maxX,
        "this.minY : ",
        this.minY,
        "this.maxY : ",
        this.maxY
      );
      var xAxis = axisBottom(x).ticks();
      var yAxis = axisLeft(y).ticks();
      //
      //create grid axis
      const xAxisGrid = axisBottom(x).tickSize(-height).tickFormat("").ticks();
      const yAxisGrid = axisLeft(y).tickSize(-width).tickFormat("").ticks();

      var clip = svg
        .append("defs")
        .append("svg:clipPath")
        .attr("id", "clip")
        .append("svg:rect")
        .attr("width", width)
        .attr("height", height)
        .attr("x", 0)
        .attr("y", 0);

      // Create grids.

      svg
        .append("g")
        .attr("class", "x axis-grid")
        .attr("transform", "translate(0," + height + ")")
        .style("color", this.gridColor)
        .style("fill", this.gridBackground)
        .style("opacity", 0.2)
        .style("stroke-dasharray", "5 5")
        .style("z-index", -1)
        .call(xAxisGrid);

      svg
        .append("g")
        .attr("class", "y axis-grid")
        .style("color", this.gridColor)
        .style("opacity", 0.2)
        .style("stroke-dasharray", "5 5")
        .style("z-index", -1)
        .call(yAxisGrid);

      // Create axes.
      svg
        .append("g")
        .attr("class", "xAxis")
        .attr("transform", "translate(0," + height + ")")
        .style("font-size", this.xAxisLabelSize)
        .call(xAxis);
      svg.selectAll(".xAxis").selectAll("text").attr("fill", this.yAxisLabel);
      svg
        .append("g")
        .attr("class", "yAxis")
        .style("font-size", this.yAxisLabelSize)
        .call(yAxis);
      svg.selectAll(".yAxis").selectAll("text").attr("fill", this.yAxisLabel);
      if (this.isZoomFeature)
        var brushB = brush() // Add the brush feature using the brush function
          .extent([
            [0, 0],
            [width, height],
          ]) // initialise the brush area: start at 0,0 and finishes at width,height: it means I select the whole graph area
          .on("start", this.updateStart)
          .on("end", this.updateChart);

      let lineB = svg.append("g").attr("clip-path", "url(#clip)");
      if (this.isZoomFeature)
        lineB.append("g").attr("class", "brush").call(brushB);

      console.log("this.minX, this.maxX", this.minX, this.maxX)
      if (this.FM && this.minX != this.maxX) {
        try {
          let lastDepth = 0
          for (let i of this.wellFormation) {
            // this.minXZoom, this.maxXZoom

            if ((!this.minYZoom || (i.md >= this.minYZoom && i.md <= this.maxYZoom))) {
              lineB
                .append("line")
                .attr("class", "x label")
                .attr("x1", x(x.domain()[0]))
                .attr("x2", x(x.domain()[1]))
                .attr("y1", y(i.md))
                .attr("y2", y(i.md))
                .attr("stroke", "#E6CC1A")
                .attr("stroke-width", 3)
                .style("stroke-dasharray", [10, 10])
                .style("opacity", 0.5);
              console.log("last depth  check : md : ", y(i.md), "last depth : ", lastDepth, lastDepth < y(i.md) + 20, y(i.md) + 20)
              if (lastDepth < y(i.md))
                lastDepth = y(i.md) + 15
              else
                lastDepth += 15
              lineB
                .append("line")
                .attr("class", "indication")
                .attr("x1", x(x.domain()[1]) * 0.67)
                .attr("x2", x(x.domain()[1]) * 0.7)
                .attr("y1", y(i.md))
                .attr("y2", lastDepth + 1)
                .attr("stroke", this.darkDark !== 'white' ? "white" : "black")
                .attr("stroke-width", 0.5)
                .style("opacity", 1);

              lineB
                .append("text")
                .attr("class", "x label")
                .attr("text-anchor", "start")
                .attr("x", x(x.domain()[1])* 0.7)
                .attr("y", lastDepth)
                .style("fill", this.darkDark !== 'white' ? "white" : "black")
                .style("font-size", "0.7em")
                .text(i.formation)
                .style('text-decoration', 'underline')
                .style("opacity", 0.8);
            } else {

            }



          }

          for (let j in this.geometry) {
            let i = this.geometry[j];

            if (j != this.geometry.length - 1) {
              var customShapeTri = {
                draw: function (context, size) {
                  let s = Math.sqrt(size / 4);
                  context.moveTo(-s, -s * 2);
                  context.lineTo(s * 2, s);
                  context.lineTo(-s, s);
                  context.closePath();
                },
              };
              var customTri = symbol().type(customShapeTri).size(100);

              lineB
                .append("path")
                .attr("d", customTri)
                .attr("fill", "red")
                .style("opacity", 0.5)
                .attr(
                  "transform",
                  "translate(" + x(x.domain()[0]) + ", " + y(i.md) + ")"
                );
              let csg_data = "";

              if (i.section_type === "Open Hole") {
                this.csg_data = ' "OH';
              } else {
                this.csg_data = ' "CSG';
              }


              lineB
                .append("text")
                .attr("class", "x label")
                .attr("text-anchor", "start")
                .attr("x", x(x.domain()[0]) + 20)
                .attr("y", y(i.md) + 5)
                .style("fill", this.darkDark !== 'white' ? "white" : "black")
                .style("font-size", "0.7em")
                .text(i.odia + this.csg_data)
                .style("opacity", 0.8);
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
      let colors = [];

      for (let index in this.columns) {
        // colors.push(this.getRandomColor());
        let color = "";
        let dashWidth = "";
        if (this.columns[index].indexOf("so") > -1) {
          color = this.soColor;
        } else {
          color = this.puColor;
        }
        if (this.columns[index].indexOf("ff1") > -1) {
          dashWidth = [1, 1];
        } else if (this.columns[index].indexOf("ff2") > -1) {
          dashWidth = [10, 10];
        } else if (this.columns[index].indexOf("ff3") > -1) {
          dashWidth = [20, 5];
        } else if (this.columns[index].indexOf("ff4") > -1) {
          dashWidth = [15, 3, 3, 3];
        } else if (this.columns[index].indexOf("ff5") > -1) {
          dashWidth = [12, 3, 3];
        } else if (this.columns[index].indexOf("rob") > -1) {
          color = this.robColor;
          dashWidth = [20, 3, 3, 3, 3, 3, 3, 3];
        } else {
          color = "white";
        }
        this[this.columns[index]] = color;
        select("#dashes_" + this.columns[index] + this.widgetId)
          .append("line")
          .attr("class", "x label")
          .attr("x1", 0)
          .attr("x2", 40)
          .attr("y1", 8)
          .attr("y2", 8)
          .attr("fill", "none")
          .attr("stroke", color)
          .attr("stroke-width", 2)
          .style("stroke-dasharray", dashWidth);
      }

      if (this.isRealtime || this.planvsreal) {
        if (this.rtColumn && this.rtColumn.length)
          for (let index in this.rtColumn) {
            var sym = symbol().type(symbolDiamond).size(80);
            select("#diamondShape_" + this.rtColumn[index] + this.widgetId)
              .append("path")
              .attr("d", sym)
              .attr("fill", this[this.rtColumn[index]])
              .attr("transform", "translate(10,10)");
          }
      }
      if (!this.isRealtime || this.planvsreal) {
        if (this.plannedColumn && this.plannedColumn.length)
          for (let index in this.plannedColumn) {
            var cym = symbol().type(symbolCircle).size(80);
            select(
              "#circle_symbol_" + this.plannedColumn[index] + this.widgetId
            )
              .append("path")
              .attr("d", cym)
              .style("fill", this[this.plannedColumn[index]])
              .attr("transform", "translate(20,10)");
          }
      }
      svg
        .append("text")
        // .attr("class", "x label")
        .attr("text-anchor", "end")
        .attr("x", width - 20)
        .attr("y", height + 50)
        .style("fill", this.xAxisLabel)
        .style("font-size", labelFontSize)
        .text("PLANNED");
      svg
        .append("text")
        // .attr("class", "x label")
        .attr("text-anchor", "end")
        .attr("x", x(x.domain()[1]) / 2)
        .attr("y", height + 40)
        .style("fill", this.xAxisLabel)
        .style("font-size", labelFontSize)
        .text(this.selectedTags.x.toUpperCase() + this.unit);

      svg
        .append("text")
        .attr("class", "y label")
        .attr("text-anchor", "end")
        // .attr("x", height/2)
        .attr("dy", "-3.8em")
        .attr("dx", -height / 2)
        .attr("transform", "rotate(-90)")
        .style("fill", this.xAxisLabel)
        .style("font-size", labelFontSize)
        .text(this.selectedTags.y.toUpperCase() + " " + this.depthUnit);
      // plot chart
      let index = 0;
      let realTimeTooltip = (event, data, isShow) => {
        //
        this.mouse_x = event.clientX;
        this.mouse_y = event.clientY;
        let text = "";
        for (let i in data[0]) {
          if (
            data[0][i] &&
            i != "sourceid" &&
            i != "trajectorysourceid" &&
            i != "well_id" &&
            i != "wellbore_name"
          )
            if (data[0][i])
              //
              text += `${i.toUpperCase()} : ${typeof data[0][i] == "number"
                ? this.hklOrTor == "TORQUE"
                  ? i == "dmea" || i == "dbtm"
                    ? data[0][i].toFixed(2)
                    : data[0][i].toFixed(2)
                  : data[0][i].toFixed(2)
                : data[0][i] && i == "time"
                  ? new Date(data[0].time).toLocaleString("en-US", {
                    timeZone: this.time_zone,
                  })
                  : data[0][i]
                } </br>`;
        }

        this.planTooltipValues = text;

        //
        this.isPlanTooltip = isShow;
      };
      let hideRealTimeTooltip = (event, text, isShow) => {
        if (this.tooltipTimeOut) {
          clearTimeout(this.tooltipTimeOut);
        }
        this.tooltipTimeOut = setTimeout(() => {
          this.mouse_x = 0;
          this.mouse_y = 0;
          this.planTooltipValues = "";
          this.isPlanTooltip = isShow;
        }, 1000);
      };
      for (let i of this.columns) {
        let col = i;
        let color = "";
        let dashType = "";
        let dashWidth = "";
        if (col.indexOf("so") > -1) {
          color = this.soColor;
        } else {
          color = this.puColor;
        }
        if (col.indexOf("ff1") > -1) {
          dashType = "stroke-dasharray";
          dashWidth = [1, 1];
        } else if (col.indexOf("ff2") > -1) {
          dashType = "stroke-dasharray";
          dashWidth = [10, 10];
        } else if (col.indexOf("ff3") > -1) {
          dashType = "stroke-dasharray";
          dashWidth = [20, 5];
        } else if (col.indexOf("ff4") > -1) {
          dashType = "stroke-dasharray";
          dashWidth = [15, 3, 3, 3];
        } else if (col.indexOf("ff5") > -1) {
          dashType = "stroke-dasharray";
          dashWidth = [12, 3, 3];
        } else if (col.indexOf("rob") > -1) {
          color = this.robColor;
          dashType = "stroke-dasharray";
          dashWidth = [20, 3, 3, 3, 3, 3, 3, 3];
        } else {
          color = "white";
        }
        lineB
          .append("path")
          .datum(broomStickData)
          .attr("fill", "none")
          .attr("stroke", color)
          .attr("stroke-width", 2)
          .style(dashType, dashWidth)
          .attr(
            "d",
            line()
              .x(function (d) {
                //
                return x(d[col]);
              })
              .y(function (d) {
                //
                return y(d.md);
              })
          )
          .on("mouseover", function (e) {
            let data = select(this).data()[0];
            var bisectDate = bisector(function (d) {
              return d.md;
            }).left; // **
            let x0 = y.invert(pointer(e)[1]);

            let i = bisectDate(data, x0, 1);
            let d0 = data[i - 1];
            let d1 = data[i];
            let d = d1;
            //
            realTimeTooltip(e, [d0], true);
          })
          .on("mousemove", function (e) {
            let data = select(this).data()[0];
            var bisectDate = bisector(function (d) {
              return d.md;
            }).left; // **
            let x0 = y.invert(pointer(e)[1]);

            let i = bisectDate(data, x0, 1);
            let d0 = data[i - 1];
            let d1 = data[i];
            let d = d1;
            //
            realTimeTooltip(e, [d0], true);
          })
          .on("mouseout", function (e) {
            hideRealTimeTooltip(e, "", false);
          });
        index++;
      }

      if (this.isRealtime || this.planvsreal) {
        // Scatter Plot
        let torqueOrHkl = this.hklOrTor;
        let diamondShape = symbol().type(symbolDiamond).size(30);
        for (let rtData of this.rtColumn) {
          lineB
            .append("g")
            .selectAll("dot")
            .data(this.rtData)
            .enter()
            .filter(function (d, i) {
              return d[rtData];
            })
            .append("path")
            .attr("d", diamondShape)
            .attr("transform", function (d) {
              let yValue = 0;
              let xValue = 0;
              // if (
              //   rtData == "slackoff" ||
              //   rtData == "pickup" ||
              //   rtData == "rotateoffbottom"
              // ) {
              //   yValue = y(d.dmea);
              // } else {
              yValue = y(d.dbtm ? d.dbtm : y.domain()[1]);
              // }
              if (torqueOrHkl == "TORQUE") {
                xValue = d[rtData];
              } else {
                xValue = d[rtData];
              }
              return "translate(" + x(xValue) + ", " + yValue + ")";
            })

            .style("display", (d) => {
              let opacity = 'none';

              if (this.whichShow == "drilling") {
                if (
                  rtData == "slackoff" ||
                  rtData == "pickup" ||
                  rtData == "rotateoffbottom"
                )
                  opacity = '';
                else opacity = 'none';
                return opacity;
              }
              if (this.whichShow == "tripin") {
                if (rtData == "slackofftripin") opacity = '';
                else opacity = 'none';
                return opacity;
              }
              if (this.whichShow == "tripout") {
                if (rtData == "pickuptripout") opacity = '';
                else opacity = 'none';
                return opacity;
              }
              return opacity;
            })
            .style("fill", this[rtData])
            .on("mouseover", function (e) {
              realTimeTooltip(e, select(this).data(), true);
            })
            .on("mousemove", function (e) {
              realTimeTooltip(e, select(this).data(), true);
            })
            .on("mouseout", function (e) {
              hideRealTimeTooltip(e, "", false);
            });
        }
      }

      if (!this.isRealtime || this.planvsreal) {
        // Scatter Plot Planned
        for (let rtData of this.plannedColumn) {
          lineB
            .append("g")
            .selectAll("dot")
            .data(this.plannedData)
            .enter()
            .filter(function (d, i) {
              return d[rtData];
            })
            .append("circle")
            .attr("cx", function (d) {
              return x(d[rtData]);
            })

            .attr("cy", function (d) {
              return y(d.dbtm ? d.dbtm : y.domain()[1]);
            })
            .attr("r", 3)
            .style("opacity", (d) => {
              let opacity = 0;
              if (this.whichShow == "drilling") {
                if (
                  rtData == "slackoff" ||
                  rtData == "pickup" ||
                  rtData == "rotateoffbottom"
                )
                  opacity = 1;
                else opacity = 0;
                return opacity;
              }
              if (this.whichShow == "tripin") {
                if (rtData == "slackofftripin") opacity = 1;
                else opacity = 0;
                return opacity;
              }
              if (this.whichShow == "tripout") {
                if (rtData == "pickuptripout") opacity = 1;
                else opacity = 0;
                return opacity;
              }
              return opacity;
            })
            .style("fill", this[rtData])
            .on("mouseover", function (e) {
              realTimeTooltip(e, select(this).data(), true);
            })
            .on("mousemove", function (e) {
              realTimeTooltip(e, select(this).data(), true);
            })
            .on("mouseout", function (e) {
              hideRealTimeTooltip(e, "", false);
            });
        }
      }

      // if(this.minX == this.maxX){
      //   svg.append("text").text("DATA NOT AVAILABLE")
      // }
    },
    async buildSVGRT(isCalledFromZoom) {
      console.log("build svg:::::2",isCalledFromZoom,this.minX,this.minXZoomRt,this.maxX,this.maxXZoomRt)
      // console.log(`build svg:::::2,${isCalledFromZoom},${this.minX},${this.maxXZoomRt}
      // ``````````````````````````````````````````````````${this.maxY},${this.maxXZoomRt}`)
      // if (this.isRealtime) {
      //   if (this.realtimeTimeOut) {
      //     clearTimeout(this.realtimeTimeOut);
      //   }
      //   this.realtimeTimeOut = setTimeout(() => {
      //     this.rtToggle(
      //       {},
      //       this.planId,
      //       this.bhaId,
      //       this.sourceid,
      //       this.isRealtime
      //     );
      //   }, this.realTimeoutDelay);
      // } else {
      //   clearTimeout(this.realtimeTimeOut);
      // }

      let margin = {
        top: 10,
        right: this.isRTvsPlan ? this.width * 0.63 : (this.tdopen ? this.width * 0.25 : this.width * 0.15),
        bottom: this.height * 0.10,
        left: 65,
      },
        width = this.width - margin.left - margin.right,
        height = this.height - margin.top - margin.bottom;

      let maxX = 0;
      let maxY = 0;
      let minX = 0;
      let minY = 0;

      let labelFontSize = 12;
      let tooltipFontSize = 10;

      //create base svg
      svg1 = select("#tvdTrajectoryTrendRT" + this.widgetId);
      if (svg1) {
        svg1.selectAll("*").remove();
        svg1 = "";
      } else {
        let gauge = document.createElement("div");
        gauge.setAttribute("id", "tvdTrajectoryTrendRT" + this.widgetId);
      }
      svg1 = select("#tvdTrajectoryTrendRT" + this.widgetId)
        .append("svg")
        .attr("width", width + margin.left + margin.right - 20)
        // .attr("width", 100% + '%')
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("id", "tvdTrajectoryTrend2")
        .attr("transform", `translate(${margin.left}, ${margin.top})`);

      //structure for trajectory
      var broomStickData = this.megaDataRT;
      //create axis

      // ;
      console.log("checking:::::::!!!!!!!!!******** rt", this.isZoomFeature && this.isZoomedRt && this.extentRT, "this.extentRt.lenght:::",this.extentRT);
      if (this.isZoomFeature && this.isZoomedRt && this.extentRT) {
        var x = scaleLinear()
          .domain([this.minXZoomRt || this.minX, this.maxXZoomRt || this.maxX])
          .range([0, width])
          ;
        var y = scaleLinear()
          .domain([this.maxYZoomRt || this.maxY, this.minYZoomRt || this.minY])
          .range([height, 0]);

        if (isCalledFromZoom) {
          console.log("this extent rt values inside&&&&&&&&&&: ", this.extentRT);
          this.minXZoomRt = x.invert(this.extentRT[0][0]);
          this.maxXZoomRt = x.invert(this.extentRT[1][0]);
          this.minYZoomRt = y.invert(this.extentRT[0][1]);
          this.maxYZoomRt = y.invert(this.extentRT[1][1]);

        }

        x.domain([this.minXZoomRt, this.maxXZoomRt]);
        y.domain([this.maxYZoomRt, this.minYZoomRt]);
      } else {
        this.minXZoomRt = null;
        this.maxXZoomRt = null;

        this.minYZoomRt = null;
        this.maxYZoomRt = null;
        console.log("minx maxX::::::::2",this.minX,this.maxX,this.minRTX,this.maxRTX)
        var x = scaleLinear()
          .domain([this.minX, this.maxX])
          .range([0, width])
          ;
        var y = scaleLinear().domain([this.maxY, this.minY]).range([height, 0]);
      }

      console.log(
        "this extent rt values inside: REALTIME--RT ",
        "this.minXZoomRt : ",
        this.minXZoomRt,
        ", this.maxXZoomRt : ",
        this.maxXZoomRt,
        " , this.minYZoomRt : ",
        this.minYZoomRt,
        ", this.maxYZoomRt : ",
        this.maxYZoomRt
      );
      var xAxis = axisBottom(x).ticks();
      var yAxis = axisLeft(y).ticks();
      //
      //create grid axis
      const xAxisGrid = axisBottom(x).tickSize(-height).tickFormat("").ticks();
      const yAxisGrid = axisLeft(y).tickSize(-width).tickFormat("").ticks();

      var clip = svg1
        .append("defs")
        .append("svg:clipPath")
        .attr("id", "clip")
        .append("svg:rect")
        .attr("width", width)
        .attr("height", height)
        .attr("x", 0)
        .attr("y", 0);

      // Create grids.

      svg1
        .append("g")
        .attr("class", "x axis-grid")
        .attr("transform", "translate(0," + height + ")")
        .style("color", this.gridColor)
        .style("fill", this.gridBackground)
        .style("opacity", 0.2)
        .style("stroke-dasharray", "5 5")
          .style("z-index", -1)
        .call(xAxisGrid);

      svg1
        .append("g")
        .attr("class", "y axis-grid")
        .style("color", this.gridColor)
        .style("opacity", 0.2)
        .style("stroke-dasharray", "5 5")
          .style("z-index", -1)
        .call(yAxisGrid);

      // Create axes.
      svg1
        .append("g")
        .attr("class", "xAxis")
        .attr("transform", "translate(0," + height + ")")
        .style("font-size", this.xAxisLabelSize)
        .call(xAxis);
      svg1.selectAll(".xAxis").selectAll("text").attr("fill", this.yAxisLabel);
      svg1
        .append("g")
        .attr("class", "yAxis")
        .style("font-size", this.yAxisLabelSize)
        .call(yAxis);
      svg1.selectAll(".yAxis").selectAll("text").attr("fill", this.yAxisLabel);
      if (this.isZoomFeature)
        var brushBRT = brush() // Add the brush feature using the brush function
          .extent([
            [0, 0],
            [width, height],
          ]) // initialise the brush area: start at 0,0 and finishes at width,height: it means I select the whole graph area
          .on("start", this.updateStartRt)
          .on("end", this.updateChartRT);

      let lineB = svg1.append("g").attr("clip-path", "url(#clip)");
      if (this.isZoomFeature)
        lineB.append("g").attr("class", "brush").call(brushBRT);
      if (this.FM && this.minX != this.maxX) {
        try {
          let lastDepth = 0
          for (let i of this.wellFormation) {
            // this.minXZoom, this.maxXZoom

            if ((!this.minYZoomRt || (i.md >= this.minYZoomRt && i.md <= this.maxYZoomRt))) {
              lineB
                .append("line")
                .attr("class", "x label")
                .attr("x1", x(x.domain()[0]))
                .attr("x2", x(x.domain()[1]))
                .attr("y1", y(i.md))
                .attr("y2", y(i.md))
                .attr("stroke", "#E6CC1A")
                .attr("stroke-width", 3)
                .style("stroke-dasharray", [10, 10])
                .style("opacity", 0.5);
              console.log("last depth  check : md : ", y(i.md), "last depth : ", lastDepth, lastDepth < y(i.md) + 20, y(i.md) + 20)
              if (lastDepth < y(i.md))
                lastDepth = y(i.md) + 15
              else
                lastDepth += 15



              lineB
                .append("line")
                .attr("class", "indication")
                .attr("x1", x(x.domain()[1]) * 0.67)
                .attr("x2", x(x.domain()[1]) * 0.7)
                .attr("y1", y(i.md))
                .attr("y2", lastDepth + 1)
                .attr("stroke", this.darkDark !== 'white' ? "white" : "black")
                .attr("stroke-width", 0.5)
                .style("opacity", 1);

              lineB
                .append("text")
                .attr("class", "x label")
                .attr("text-anchor", "start")
                .attr("x", x(x.domain()[1]) * 0.7)
                .attr("y", lastDepth)
                .style("fill", this.darkDark !== 'white' ? "white" : "black")
                .style("font-size", "0.7em")
                .text(i.formation)
                .style('text-decoration', 'underline')
                .style("opacity", 0.8);
            } else {

            }



          }


          for (let j in this.geometry) {
            let i = this.geometry[j];

            if (j != this.geometry.length - 1) {
              var customShapeTri = {
                draw: function (context, size) {
                  let s = Math.sqrt(size / 4);
                  context.moveTo(-s, -s * 2);
                  context.lineTo(s * 2, s);
                  context.lineTo(-s, s);
                  context.closePath();
                },
              };
              var customTri = symbol().type(customShapeTri).size(100);

              lineB
                .append("path")
                .attr("d", customTri)
                .attr("fill", "red")
                .style("opacity", 0.5)
                .attr(
                  "transform",
                  "translate(" + x(x.domain()[0]) + ", " + y(i.md) + ")"
                );
              let csg_data = "";

              if (i.section_type === "Open Hole") {
                this.csg_data = ' "OH';
              } else {
                this.csg_data = ' "CSG';
              }


              lineB
                .append("text")
                .attr("class", "x label")
                .attr("text-anchor", "start")
                .attr("x", x(x.domain()[0]) + 20)
                .attr("y", y(i.md) + 5)
                .style("fill", this.darkDark !== 'white' ? "white" : "black")
                .style("font-size", "0.7em")
                .text(i.odia + this.csg_data)
                .style("opacity", 0.8);
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
      let colors = [];

      for (let index in this.columns) {
        // colors.push(this.getRandomColor());
        let color = "";
        let dashWidth = "";
        if (this.columns[index].indexOf("so") > -1) {
          color = this.soColor;
        } else {
          color = this.puColor;
        }
        if (this.columns[index].indexOf("ff1") > -1) {
          dashWidth = [1, 1];
        } else if (this.columns[index].indexOf("ff2") > -1) {
          dashWidth = [10, 10];
        } else if (this.columns[index].indexOf("ff3") > -1) {
          dashWidth = [20, 5];
        } else if (this.columns[index].indexOf("ff4") > -1) {
          dashWidth = [15, 3, 3, 3];
        } else if (this.columns[index].indexOf("ff5") > -1) {
          dashWidth = [12, 3, 3];
        } else if (this.columns[index].indexOf("rob") > -1) {
          color = this.robColor;
          dashWidth = [20, 3, 3, 3, 3, 3, 3, 3];
        } else {
          color = "white";
        }
        this[this.columns[index]] = color;
        select("#dashes_" + this.columns[index] + this.widgetId)
          .append("line")
          .attr("class", "x label")
          .attr("x1", 0)
          .attr("x2", 40)
          .attr("y1", 8)
          .attr("y2", 8)
          .attr("fill", "none")
          .attr("stroke", color)
          .attr("stroke-width", 2)
          .style("stroke-dasharray", dashWidth);
      }

      if (this.isRealtime || this.planvsreal) {
        if (this.rtColumn && this.rtColumn.length)
          for (let index in this.rtColumn) {
            var sym = symbol().type(symbolDiamond).size(80);
            select("#diamondShape_" + this.rtColumn[index] + this.widgetId)
              .append("path")
              .attr("d", sym)
              .attr("fill", this[this.rtColumn[index]])
              .attr("transform", "translate(10,10)");
          }
      }
      if (!this.isRealtime || this.planvsreal) {
        if (this.plannedColumn && this.plannedColumn.length)
          for (let index in this.plannedColumn) {
            var cym = symbol().type(symbolCircle).size(80);
            select(
              "#circle_symbol_" + this.plannedColumn[index] + this.widgetId
            )
              .append("path")
              .attr("d", cym)
              .style("fill", this[this.plannedColumn[index]])
              .attr("transform", "translate(20,10)");
          }
      }

      svg1
        .append("text")
        // .attr("class", "x label")
        .attr("text-anchor", "end")
        .attr("x", width - 20)
        .attr("y", height + 50)
        .style("fill", this.xAxisLabel)
        .style("font-size", labelFontSize)
        .text("REAL TIME");

      svg1
        .append("text")
        // .attr("class", "x label")
        .attr("text-anchor", "end")
        .attr("x", x(x.domain()[1]) / 2)
        .attr("y", height + 40)
        .style("fill", this.xAxisLabel)
        .style("font-size", labelFontSize)
        .text(this.selectedTags.x.toUpperCase() + this.unit);

      svg1
        .append("text")
        .attr("class", "y label")
        .attr("text-anchor", "end")
        // .attr("x", height/2)
        .attr("dy", "-3.8em")
        .attr("dx", -height / 2)
        .attr("transform", "rotate(-90)")
        .style("fill", this.xAxisLabel)
        .style("font-size", labelFontSize)
        .text(this.selectedTags.y.toUpperCase() + " " + this.depthUnit);
      // plot chart
      let index = 0;
      let realTimeTooltip = (event, data, isShow) => {
        //
        this.mouse_x = event.clientX;
        this.mouse_y = event.clientY;
        let text = "";
        for (let i in data[0]) {
          if (
            data[0][i] &&
            i != "sourceid" &&
            i != "trajectorysourceid" &&
            i != "well_id" &&
            i != "wellbore_name"
          )
            if (data[0][i])
              //
              text += `${i.toUpperCase()} : ${typeof data[0][i] == "number"
                ? this.hklOrTor == "TORQUE"
                  ? i == "dmea" || i == "dbtm"
                    ? data[0][i].toFixed(2)
                    : data[0][i].toFixed(2)
                  : data[0][i].toFixed(2)
                : data[0][i] && i == "time"
                  ? new Date(data[0].time).toLocaleString("en-US", {
                    timeZone: this.time_zone,
                  })
                  : data[0][i]
                } </br>`;

          //  if(data[0].time){
          //    text += `${i.toUpperCase()} : ${
          //   data[0].time = new Date(data[0].time).toLocaleString()
          // }`
          // }
        }

        this.planTooltipValues = text;

        //
        this.isPlanTooltip = isShow;
      };
      let hideRealTimeTooltip = (event, text, isShow) => {
        if (this.tooltipTimeOut) {
          clearTimeout(this.tooltipTimeOut);
        }
        this.tooltipTimeOut = setTimeout(() => {
          this.mouse_x = 0;
          this.mouse_y = 0;
          this.planTooltipValues = "";
          this.isPlanTooltip = isShow;
        }, 1000);
      };
      for (let i of this.columns) {
        let col = i;
        let color = "";
        let dashType = "";
        let dashWidth = "";
        if (col.indexOf("so") > -1) {
          color = this.soColor;
        } else {
          color = this.puColor;
        }
        if (col.indexOf("ff1") > -1) {
          dashType = "stroke-dasharray";
          dashWidth = [1, 1];
        } else if (col.indexOf("ff2") > -1) {
          dashType = "stroke-dasharray";
          dashWidth = [10, 10];
        } else if (col.indexOf("ff3") > -1) {
          dashType = "stroke-dasharray";
          dashWidth = [20, 5];
        } else if (col.indexOf("ff4") > -1) {
          dashType = "stroke-dasharray";
          dashWidth = [15, 3, 3, 3];
        } else if (col.indexOf("ff5") > -1) {
          dashType = "stroke-dasharray";
          dashWidth = [12, 3, 3];
        } else if (col.indexOf("rob") > -1) {
          color = this.robColor;
          dashType = "stroke-dasharray";
          dashWidth = [20, 3, 3, 3, 3, 3, 3, 3];
        } else {
          color = "white";
        }
        lineB
          .append("path")
          .datum(broomStickData)
          .attr("fill", "none")
          .attr("stroke", color)
          .attr("stroke-width", 2)
          .style(dashType, dashWidth)
          .attr(
            "d",
            line()
              .x(function (d) {
                //
                return x(d[col]);
              })
              .y(function (d) {
                //
                return y(d.md);
              })
          )
          .on("mouseover", function (e) {
            let data = select(this).data()[0];
            var bisectDate = bisector(function (d) {
              return d.md;
            }).left; // **
            let x0 = y.invert(pointer(e)[1]);

            let i = bisectDate(data, x0, 1);
            let d0 = data[i - 1];
            let d1 = data[i];
            let d = d1;
            //
            realTimeTooltip(e, [d0], true);
          })
          .on("mousemove", function (e) {
            let data = select(this).data()[0];
            var bisectDate = bisector(function (d) {
              return d.md;
            }).left; // **
            let x0 = y.invert(pointer(e)[1]);

            let i = bisectDate(data, x0, 1);
            let d0 = data[i - 1];
            let d1 = data[i];
            let d = d1;
            //
            realTimeTooltip(e, [d0], true);
          })
          .on("mouseout", function (e) {
            hideRealTimeTooltip(e, "", false);
          });
        index++;
      }

      if (this.isRealtime || this.planvsreal) {
        // Scatter Plot
        let torqueOrHkl = this.hklOrTor;
        let diamondShape = symbol().type(symbolDiamond).size(30);
        for (let rtData of this.rtColumn) {
          lineB
            .append("g")
            .selectAll("dot")
            .data(this.rtData)
            .enter()
            .filter(function (d, i) {
              return d[rtData];
            })
            .append("path")
            .attr("d", diamondShape)
            .attr("transform", function (d) {
              let yValue = 0;
              let xValue = 0;
              // if (
              //   rtData == "slackoff" ||
              //   rtData == "pickup" ||
              //   rtData == "rotateoffbottom"
              // ) {
              //   yValue = y(d.dmea);
              // } else {
              yValue = y(d.dbtm ? d.dbtm : y.domain()[1]);
              // }
              if (torqueOrHkl == "TORQUE") {
                xValue = d[rtData];
              } else {
                xValue = d[rtData];
              }
              return "translate(" + x(xValue) + ", " + yValue + ")";
            })

            .style("opacity", (d) => {
              let opacity = 0;

              if (this.whichShow == "drilling") {
                if (
                  rtData == "slackoff" ||
                  rtData == "pickup" ||
                  rtData == "rotateoffbottom"
                )
                  opacity = 1;
                else opacity = 0;
                return opacity;
              }
              if (this.whichShow == "tripin") {
                if (rtData == "slackofftripin") opacity = 1;
                else opacity = 0;
                return opacity;
              }
              if (this.whichShow == "tripout") {
                if (rtData == "pickuptripout") opacity = 1;
                else opacity = 0;
                return opacity;
              }
              return opacity;
            })
            .style("fill", this[rtData])
            .on("mouseover", function (e) {
              realTimeTooltip(e, select(this).data(), true);
            })
            .on("mousemove", function (e) {
              realTimeTooltip(e, select(this).data(), true);
            })
            .on("mouseout", function (e) {
              hideRealTimeTooltip(e, "", false);
            });
        }
      }

      if (!this.isRealtime || this.planvsreal) {
        // Scatter Plot Planned
        for (let rtData of this.plannedColumn) {
          lineB
            .append("g")
            .selectAll("dot")
            .data(this.plannedData)
            .enter()
            .filter(function (d, i) {
              return d[rtData];
            })
            .append("circle")
            .attr("cx", function (d) {
              return x(d[rtData]);
            })

            .attr("cy", function (d) {
              return y(d.dbtm ? d.dbtm : y.domain()[1]);
            })
            .attr("r", 3)
            .style("opacity", (d) => {
              let opacity = 0;
              if (this.whichShow == "drilling") {
                if (
                  rtData == "slackoff" ||
                  rtData == "pickup" ||
                  rtData == "rotateoffbottom"
                )
                  opacity = 1;
                else opacity = 0;
                return opacity;
              }
              if (this.whichShow == "tripin") {
                if (rtData == "slackofftripin") opacity = 1;
                else opacity = 0;
                return opacity;
              }
              if (this.whichShow == "tripout") {
                if (rtData == "pickuptripout") opacity = 1;
                else opacity = 0;
                return opacity;
              }
              return opacity;
            })
            .style("fill", this[rtData])
            .on("mouseover", function (e) {
              realTimeTooltip(e, select(this).data(), true);
            })
            .on("mousemove", function (e) {
              realTimeTooltip(e, select(this).data(), true);
            })
            .on("mouseout", function (e) {
              hideRealTimeTooltip(e, "", false);
            });
        }
      }
    },
    async updateChart(e) {
      this.extent = e.selection;
      console.log("this extent*******___: ", this.extent);
      this.isZoomed = true;
      if (e.selection) {
        this.buildSVG(true);
        // this.buildSVGRT(false);
      }
    },
    updateStart(event) {
      event.sourceEvent.stopPropagation();
    },
    updateStartRt(event) {
      event.sourceEvent.stopPropagation();
    },

    async updateChartRT(e) {
      this.extentRT = e.selection;
      console.log("this extent rt values inside&&&&&&&&&& in updateChart: ", this.extentRT,e.selection);
      this.isZoomedRt = true;
      if (e.selection) {
        // this.buildSVG(false);
        this.buildSVGRT(true);
      }
    },

    dark() {
      this.$emit("dark");
    },

    getRandomColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },

    light() {
      this.$emit("light");
    },

    onToggleSideBar() {


      if (this.wellId && this.wellboreId) {
        if (this.sideBarForProp == "TD") {
          this.sideBarForProp = "";
          this.toggleSidebar = 0;
          this.tdopen = false;
        } else {
          this.sideBarForProp = "TD";
          this.toggleSidebar = -16;
          this.tdopen = true;
        }
        this.buildSVG()
      } else {
        this.$toast.info("Please select Wells", {
          position: "top",
          duration: 1000,
        });
      }

    },
  },
  watch: {
    actualColor: async function (newVal, oldVal) {
      this.buildSVG();
      this.buildSVGRT();
    },
    bhaStartTime: async function (newVal, oldVal) {
      this.start_time = newVal;
    },
    bhaEndTime: async function (newVal, oldVal) {
      this.end_time = newVal;
    },
    planColor: async function (newVal, oldVal) {
      this.buildSVG();
      this.buildSVGRT();
    },
    gridColor: async function (newVal, oldVal) {
      try {
        this.buildSVG();
        this.buildSVGRT();
      } catch (error) {
        //
      }
    },
    gridBackground: async function (newVal, oldVal) {
      try {
        this.buildSVG();
        this.buildSVGRT();
      } catch (error) {
        //
      }
    },
    xAxisLabel: async function (newVal, oldVal) {
      try {
        this.buildSVG();
        this.buildSVGRT();
      } catch (error) {
        //
      }
    },
    yAxisLabel: async function (newVal, oldVal) {
      try {
        this.buildSVG();
        this.buildSVGRT();
      } catch (error) {
        //
      }
    },
    xAxisLabelSize: async function (newVal, oldVal) {
      try {
        this.buildSVG();
        this.buildSVGRT();
      } catch (error) {
        //
      }
    },
    yAxisLabelSize: async function (newVal, oldVal) {
      try {
        this.buildSVG();
        this.buildSVGRT();
      } catch (error) {
        //
      }
    },
    widgetWidth: async function (newVal, oldVal) {
      try {
        // await this.clearSvgHandler();
        this.width = newVal;
        this.buildSVG();
      } catch (error) {
        //
      }
    },
    widgetHeight: async function (newVal, oldVal) {
      try {
        // await this.clearSvgHandler();
        this.height = newVal;
        this.buildSVG();
        this.buildSVGRT();
      } catch (error) {
        //
      }
    },
    replayDetails: async function (response) {
      let columns = [
        "pickup",
        "rotateoffbottom",
        "slackoff",
        "pickuptripout",
        "slackofftripin",
      ];
      if (response[0] && response[0].well_id) {
        this.rtColumn = columns;
      } else {
        this.rtColumn = [];
      }
      this.rtData = response;
      this.buildSVG();
      this.buildSVGRT();
    }
  },
  setup() {
    const { setData, completeData, replayDetails, clearRuningIntervals } = useTndReplay();
    return {
      setData,
      completeData,
      replayDetails,
      clearRuningIntervals
    }
  }
};
</script>
<style scoped>
.margin_left_7 {
  margin-left: 13%;
}

.margin_left_0 {
  margin-left: 0;
}

.flex_1 {
  /* width: 13%; */
  transition-delay: 0.15s;
  /* background: #28282b; */
  /* position: absolute; */
  height: 100vh;
  /* background: #1b1a1f; */
}



.flex_2 {
  width: 130%;
  overflow: hidden;
}

.flex_3 {
  width: 13%;
  position: absolute;
  right: 0;
  margin: 7px 0;
}

.flex_items {
  display: flex;
  background: #28282b;
}

.tool_group {
  position: fixed;
  display: inline-grid;
  /* left: 221px; */
  right: 162px;
  z-index: 99999;
}

.actc_tool {
  font-size: 10px;
  background: #000000;
  width: auto;
  position: absolute;
  padding: 2px 5px;
  /* border-radius: 20px; */
}


.legends_hr_ {
  width: 90%;
  margin-bottom: 15px;
}

.diamondShape_ {
  width: 50px;
  height: 20px;
}

.circle_symbol_ {
  width: 50px;
  height: 20px;
}

.dashes_ {
  width: 50px;
  height: 20px;
}

.wrapper {
  display: grid;

  /* grid-template-columns: repeat(auto-fit, minmax(160px, 1fr)); */
  gap: 5px;
  /* grid-auto-rows: minmax(350px, auto); */
  grid-auto-rows: 250px;
  margin: 9px 0;
  max-width: 1000px;
}

.wrapper-cond-1 {
  grid-template-columns: repeat(2, 195px);
}

.wrapper-cond-2 {
  grid-template-columns: repeat(2, 175px);
}

.wrapper-cond-3 {
  grid-template-columns: repeat(1, 175px);
}

.wrapper>div {
  border: 1px solid #404040;
  height: 250px;
  overflow-y: auto;
}

.wrapper>div>ul {
  width: 80%;
  margin: 0 auto;
}

.diamondShape_ {
  width: 25%;
  word-break: break-all;
}

.legend_txt {
  width: 66%;
  display: flex;
  align-items: center;
  font-weight: 600;
}
</style>
