<template>
    <div class="bg-gray-600 border-2 border-opacity-25 border-white textSize relative" :style="{
      width: width + 'px',
      height: height + 'px',
      background: 'black',
      opacity: 0.9,
    }">
      <!--  -->
      <div class="flex pop_track_tool">
        <div v-on:click="closeIt" style="width: 20%"
          class="absolute right-0 -top-3 mt-2 text-base text-sm px-2 text-red_">
          x
        </div>
        <div class="text-white font-semibold text-xs uppercase mb-4 p-0 text-center left_bg_tool" style="width: 80%">
          {{ name }}
        </div>
      </div>
  
      <div class="flex justify-between">
        <div class="text-white mb-2 ml-1">Color</div>
  
        <div class="mr-1 -mt-1">
          <input class="iColor" type="color" v-model="color" />
        </div>
      </div>
      <div class="flex justify-between">
        <div class="text-white mb-3 my-1 ml-1">Thickness</div>
        <div class="mr-1 bg-gray-600 mt-1 text-black tSelect text-center">
          <CustomSelect :options="thickness" :default="tValue" @input="selectThickness($event)" class="th_cust_select" />
        </div>
      </div>
      <div class="flex justify-between">
        <div class="text-white mb-2 my-1 ml-1">Scale</div>
        <div class="flex mr-1 text-white bg-gray-600 text-xs">
          <input class="scaleInput bg-gray-600" v-model="sLower" />
          <input class="scaleInput bg-gray-600" v-model="sUpper" />
        </div>
      </div>
      <div class="flex justify-between">
        <div class="text-white mb-2 my-1 ml-1">Alert</div>
        <div class="flex mr-1 text-white bg-gray-600">
          <input class="scaleInput bg-gray-600" v-model="aLower" />
          <input class="scaleInput bg-gray-600" v-model="aUpper" />
        </div>
      </div>
      <div v-if="name == 'alert'" class="block justify-center my-2">
        <button v-on:click="toggle_height_handler_bha" :class="[
          'font-bold',
          'text-left',
          'w-11/12',
          'rounded',
          'td_btn',
          'alert_pop_btn',
        
        ]">
          Pick Alerts
          <span v-if="!isExpandAlertDropdown"><i class="fas fa-angle-down"></i></span>
          <span v-else><i class="fas fa-angle-up"></i></span>
        </button>
  
        <ul v-if="isExpandAlertDropdown" v-bind:style="{
          height: isExpandAlertDropdown ? '' : '0px',
        }" class="alert_tip_pop text-xs p-0">
          <li :key="index" v-for="(alert, index) in defaultAlertArr" class="top_ text-left ">
            <label :for="'radio_bha' + index" v-on:click="selectAlerts($event, alert)" :class="[
              'font-bold',
              'text-left',
              'w-full',
              'rounded',
              'td_btn',
              'flex',
              'mt-2',
            ]">
              <div class="flex w-full text-white">
                {{ alert }}
                <input type="checkbox" :id="'radio_bha' + index" :name="'radio_bha' + index"
                  :checked="alertArr.indexOf(alert) > -1" />
              </div>
            </label>
          </li>
        </ul>
      </div>
      <div class="flex justify-center my-2">
        <div class="w-2/4">
          <img :src="check" alt="" class="context_img del_ico" v-on:click="apply" />
        </div>
        <div class="w-2/4">
          <img :src="bin" alt="" class="context_img del_ico" v-on:click="deleteCurve" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import CustomSelect from '../../Select/SelectComponent.vue';
  import bin from '../../../assets/img/delete_1.png';
  import check from '../../../assets/img/check.png';
  export default {
    name: 'TrendProps',
    components: {
      CustomSelect,
    },
  
    data() {
      return {
        bin,
        check,
        thickness: [1, 2, 3, 4, 5, 6],
        updatedCurve: {},
        tValue: 1,
        sLower: '',
        sUpper: '',
        aLower: '',
        aUpper: '',
        color: null,
        name: '',
        isExpandAlertDropdown: false,
        defaultAlertArr: [
          'ERRATIC MSE',
          'PRESSURE SPIKE',
          'AD Dysfunction',
          'DRLG INEFF.',
          'Motor Stalled',
          'BIT IMPACT',
          'Motor Stalled Likely',
          'MOTOR STALL PROBABLE',
          'Motor Damage Imminent',
          'Motor Damage Critical',
          'Motor Threshold 50%',
          'Motor Threshold cropped 60%'
          // 'Diff. Press',
        ],
        alertArr: this.alertArray,
        selectedAlerts: this.alertArray,
      };
    },
    props: {
      height: [String, Number],
      width: [String, Number],
      curve: Object,
      alertArray: Array
    },
    mounted() {
      console.log("curve props : ", this.curve)
      this.color = this.curve.color;
      this.name = this.curve.name;
      this.sLower = this.curve.scale[0] && typeof this.curve.scale[0] == 'number'
        ? this.curve.scale[0].toFixed(2)
        : this.curve.scale[0];
      this.sUpper = this.curve.scale[1] && typeof this.curve.scale[1] == 'number'
        ? this.curve.scale[1].toFixed(2)
        : this.curve.scale[1];
      this.tValue = this.curve.stroke ? this.curve.stroke : 1;
      console.log('curvemounted', this.curve);
    },
    computed: {
      curve() {
        console.log('curve computed', this.curve);
        this.color = this.curve.color;
        this.name = this.curve.name;
        this.sLower = this.curve.scale[0]
          ? this.curve.scale[0].toFixed(2)
          : this.curve.scale[0];
        this.sUpper = this.curve.scale[1]
          ? this.curve.scale[1].toFixed(2)
          : this.curve.scale[1];
        this.tValue = this.curve.stroke ? this.curve.stroke : 1;
      },
    },
    methods: {
      toggle_height_handler_bha() {
        this.isExpandAlertDropdown = !this.isExpandAlertDropdown;
      },
      deleteCurve() {
        this.$emit('delete');
      },
      closeIt() {
        console.log('in close dialogue');
        this.$emit('closeIt');
      },
      selectAlerts(e, alert) {
  
        let selectedAlerts = this.selectedAlerts;
        if (e.target.checked) {
          selectedAlerts.push(alert);
  
        } else {
          selectedAlerts.splice(selectedAlerts.indexOf(alert), 1);
        }
        console.log(" selected alerts : ", this.selectedAlerts, selectedAlerts)
        this.selectedAlerts = selectedAlerts;
      },
      apply() {
        console.log('in close');
  
        this.updatedCurve['color'] = this.color;
        this.updatedCurve['thickness'] = this.tValue;
        this.updatedCurve['scaleMin'] = this.sLower;
        this.updatedCurve['scaleMax'] = this.sUpper;
        this.updatedCurve['alertMin'] = this.aLower;
        this.updatedCurve['alertMax'] = this.aUpper;
        if (this.name == 'alert') {
          this.updatedCurve['alertsArr'] = this.selectedAlerts
        }
        console.log('updatedCurve ', this.updatedCurve);
        this.$emit('apply', this.updatedCurve);
      },
      selectThickness(thickness) {
        console.log('thickness ', thickness);
        this.tValue = thickness;
      },
    },
    watch: {
      alertArray(newValue) {
        this.alertArr = newValue
      }
    }
  };
  </script>
  <style scoped>
  .iColor {
    background: transparent;
    height: 20px;
    width: 20px;
    border-radius: 100%;
  }
  
  .textSize {
    font-size: 12px;
  }
  
  .scaleInput {
    width: 35px;
    height: 25px;
    border: 1px solid gray;
    margin-right: 0.5px;
    font-size: 1vh;
    text-align: center;
  }
  
  .tSelect {
    width: 35px;
    height: 25px;
    border: 1px solid gray;
    margin-right: 4px;
    color: black;
  }
  
  .items-table div {
    padding: 0px 0px !important;
    height: -13px !important;
    /* position: inherit; */
    /* width: 100%; */
    color: black;
  }
  
  .del_ico {
    margin: 0 auto;
    width: 20px;
    cursor: pointer;
  }
  
  .button_ {
    display: flex;
    padding: 0 10px;
    width: 100%;
    margin: 0 5px;
    height: 100%;
    vertical-align: middle;
    align-self: center;
  }
  
  .button_img {
    margin: 1px 3px;
    width: 14px;
    padding: 1px;
    cursor: pointer;
  }
  
  .text-red_ {
    color: #cf634b;
    font-size: 13px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  
  .text-base {
    font-size: 13px;
  }
  
  .left_bg_tool {
    width: 80%;
    word-break: break-all;
    margin-bottom: 0;
    font-size: 8px;
  }
  
  .text-white {
    color: white;
  }
  
  .pop_track_tool {
    background: #29292f;
    margin-bottom: 9px;
    padding: 3px;
  }
  
  .alert_tip_pop {
    position: absolute;
    background: #28282f;
    top: -13px;
    /* padding: 10px; */
    font-size: calc((0.2em + 0.2vmin) + (0.2em + 0.2vmax));
    height: 170px;
    overflow-y: auto;
  }
  
  .alert_pop_btn {
    display: flex;
    justify-content: space-between;
    padding: 0 6px;
    color: white;
  }
  
  .th_cust_select {
    width: 100%;
  }
  </style>
  