<template>
  <div slider id="slider-distance">
    <div>
      <div inverse-right :style="{ width: sliderMax + '%' }"></div>
      <div inverse-left :style="{ width: sliderMin + '%' }"></div>

      <div
        range
        :style="
          sliderMin >= 0
            ? { width: sliderMin + '%' }
            : { width: sliderMax + '%' }
        "
      ></div>

      <span thumb :style="{ left: sliderMin + '%' }"></span>
      <span thumb :style="{ left: sliderMax + '%' }"></span>
      <div sign :style="{ left: sliderMin + '%' }">
        <span id="value">{{ sliderMin }}</span>
      </div>
      <div sign :style="{ left: sliderMax + '%' }">
        <span id="value">{{ sliderMax }}</span>
      </div>
    </div>
    <input
      type="range"
      v-model="sliderMin"
      max="100"
      min="0"
      step="1"
      :oninput="sliderMin"
    />

    <input
      type="range"
      v-model="sliderMax"
      max="100"
      min="0"
      step="1"
      :oninput="sliderMax"
    />
  </div>
  <input
    type="range"
    class="cust_range_slide"
    v-model="sliderMin"
    max="100"
    min="0"
    step="1"
    :oninput="sliderMin"
  />

  <input
    type="range"
    class="cust_range_slide"
    v-model="sliderMax"
    max="100"
    min="0"
    step="1"
    :oninput="sliderMax"
  />

  <p :class="sliderMin >= 30 ? 'text-red-500' : 'text-white'">
    {{ sliderMin }}
  </p>
  <p :class="sliderMax >= 150 ? 'text-red-500' : 'text-white'">
    {{ sliderMax }}
  </p>

  <!-- <div class='range-slider'>
    <input class="range" type="range" min="0" max="180" step="1" v-model="sliderMin"  >
    <input class="range" type="range" min="0" max="180" step="1" v-model="sliderMax" >
  </div> -->

  <p :class="sliderMin >= 30 ? 'text-red-500' : 'text-white'">
    {{ sliderMin }}
  </p>
  <p :class="sliderMax >= 150 ? 'text-red-500' : 'text-white'">
    {{ sliderMax }}
  </p>
</template>

<script>
export default {
  data() {
    return {
      minAngle: 10,
      maxAngle: 80,
      fillColor: "",
    };
  },

  computed: {
    sliderMin: {
      get: function () {
        var val = parseInt(this.minAngle);
        return val;
      },
      set: function (val) {
        val = parseInt(val);
        if (val > this.maxAngle) {
          this.maxAngle = val;
        }
        this.minAngle = val;
      },
    },
    sliderMax: {
      get: function () {
        var val = parseInt(this.maxAngle);
        return val;
      },
      set: function (val) {
        val = parseInt(val);
        if (val < this.minAngle) {
          this.minAngle = val;
        }
        this.maxAngle = val;
      },
    },
  },
};
</script>

<style >
/* range slider */
.danger {
  color: red;
}
.range-slider {
  width: 300px;
  margin: auto;
  text-align: center;
  position: relative;
  transform: rotate(90deg);
  height: 6em;
  left: -2rem;
  top: -3rem;
  z-index: 9999;
}

.range-slider .range {
  position: absolute;
  left: 0;
  bottom: 0;
}

[slider] {
  width: 300px;
  position: absolute;
  height: 50px;
  /* margin: 45px 0 10px 0; */
  margin: 0;
  transform: rotate(90deg);
  right: 5rem;
  background: blue;
  z-index: 9999;
}

[slider] > div {
  position: absolute;
  left: 13px;
  right: 15px;
  /* height: 5px; */
  width: 100%;
}
[slider] > div > [inverse-left] {
  position: absolute;
  /* left: 0; */
  height: 14px;
  border-radius: 10px;
  background-color: #5aba81;
  margin: 0 7px;
  width: 100%;
}

[slider] > div > [inverse-right] {
  position: absolute;
  /* right: 0; */
  height: 14px;
  border-radius: 10px;
  background-color: #5aba81;
  margin: 0 7px;
  width: 100%;
}

[slider] > div > [range] {
  position: relative;
  left: 0;
  height: 14px;
  border-radius: 14px;
  background-color: #ffffff;
}

[slider] > div > [thumb] {
  position: absolute;
  top: -7px;
  z-index: 2;
  height: 25px;
  width: 25px;
  text-align: left;
  margin-left: -14px;
  cursor: pointer;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4);
  background-color: #5aba81;
  /* border-radius: 50%; */
  outline: none;
}

[slider] > .cust_range_slide {
  position: absolute;
  pointer-events: none;
  -webkit-appearance: none;
  z-index: 3;
  height: 14px;
  top: -2px;
  width: 100%;
  opacity: 0;
}

div[slider] > .cust_range_slide:focus::-webkit-slider-runnable-track {
  background: transparent;
  border: transparent;
}

div[slider] > .cust_range_slide:focus {
  outline: none;
}

div[slider] > .cust_range_slide::-webkit-slider-thumb {
  pointer-events: all;
  width: 28px;
  height: 28px;
  border-radius: 0px;
  border: 0 none;
  background: #5aba81;
  -webkit-appearance: none;
}

div[slider] > .cust_range_slide::-ms-fill-lower {
  background: transparent;
  border: 0 none;
}

div[slider] > .cust_range_slide::-ms-fill-upper {
  background: transparent;
  border: 0 none;
}

div[slider] > .cust_range_slide::-ms-tooltip {
  display: none;
}

[slider] > div > [sign] {
  opacity: 0;
  position: absolute;
  margin-left: -11px;
  top: -39px;
  z-index: 3;
  background-color: #5aba81;
  color: #fff;
  width: 28px;
  height: 28px;
  border-radius: 28px;
  -webkit-border-radius: 28px;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  text-align: center;
}

[slider] > div > [sign]:after {
  position: absolute;
  content: "";
  left: 0;
  border-radius: 16px;
  top: 19px;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-top-width: 16px;
  border-top-style: solid;
  border-top-color: #5aba81;
}

[slider] > div > [sign] > span {
  font-size: 12px;
  font-weight: 700;
  line-height: 28px;
}

[slider]:hover > div > [sign] {
  opacity: 1;
}
</style>
