<template>
  <div>
    <ol-map
      ref="map"
      :loadTilesWhileAnimating="true"
      :loadTilesWhileInteracting="true"
      :style="{ width: this.width + 'px', height: this.height + 'px' }"
    >
      <ol-view
        ref="view"
        :center="center"
        :rotation="rotation"
        :zoom="zoom"
        :projection="projection"
      />
      <ol-tile-layer>
        <ol-source-osm />
      </ol-tile-layer>

      <ol-vector-layer>
        <ol-source-vector ref="vectorSource">
          <!-- <ol-animation-drop :duration="1000"> -->
          <ol-interaction-draw
            v-if="drawEnable"
            :type="drawType"
            @drawend="drawend"
            @drawstart="drawstart"
            :source="vectorSource"
            ref="draw"
            
          >
          </ol-interaction-draw>
          <ol-feature v-for="item in wells" :key="item">
            <ol-geom-multi-point
              :coordinates="[[item.long, item.lat]]"
            ></ol-geom-multi-point>
            <ol-style>
              <ol-style-circle :radius="radius">
                <ol-style-fill
                  :color="item.state == 'UPDATING' ? 'green' : 'red'"
                ></ol-style-fill>
                <ol-style-stroke
                  :color="item.state == 'UPDATING' ? 'green' : 'red'"
                  :width="strokeWidth"
                >
                </ol-style-stroke>
              </ol-style-circle>
            </ol-style>
          </ol-feature>
        </ol-source-vector>
      </ol-vector-layer>
    </ol-map>

    <span class="hidden">{{ darkDark }}</span>
  </div>
</template>
<script>
import { ref } from "vue";
export default {
  setup() {
    const center = ref([0, 0]);
    const projection = ref("EPSG:4326");
    const zoom = ref(0);
    const rotation = ref(0);
    const radius = ref(5);
    const strokeWidth = ref(5);
    const strokeColor = ref("red");
    const fillColor = ref("white");
    const drawEnable = ref(true);
    const drawType = ref("Polygon");
    

    const drawstart = (event) => {};

    const drawend = (event) => {
      //
      // for(let item in this.wells){
      //
      // }
    };
    return {
      center,
      projection,
      zoom,
      rotation,
      radius,
      strokeWidth,
      strokeColor,
      fillColor,
      drawEnable,
      drawType,
      drawstart,
      drawend,
    };
  },
  data() {
    return {
      wellSelectionObj: {},
      wellBoreSelectionObj: {},
      wellNames: [],
      wellNamesOrg: [],
      wellBoreNames: [],
      wellBoreNamesOrg: [],
      logNames: [],
      wellBores: [],
      selectedWell: null,
      selectedWellbore: null,
      selectedLog: null,
      wellIndex: 0,
      wellboreIndex: 0,
      logIndex: 0,
      dWell: "Well",
      dWellbore: "Wellbore",
      dLog: "Log",
      show: true,
      errcode: "",
      currentCenter: this.center,
      currentZoom: this.zoom,
      currentResolution: this.resolution,
      currentRotation: this.rotation,
      selectedWells: {},
    };
  },
  methods: {
    removeTheInteractionInMap(){
      if(this.$refs.vectorSource.source){
        this.$refs.vectorSource.source.clear()
      }
    },
    initDrawInteraction() {
      const map = this.$refs.map.$map;
      const vectorSource = this.$refs.vectorSource.$source;

      this.draw = new ol.interaction.Draw({
        source: vectorSource,
        type: "Point",
      });

      this.draw.on("drawend", (event) => {
        const feature = event.feature;
        console.log("Feature drawn:", feature);

        // Check if vectorSource is defined before calling removeFeature
        if (vectorSource) {
          vectorSource.removeFeature(feature);
        }
      });

      map.addInteraction(this.draw);
    },
    getIndex(state, widgetId, displayId) {
      let index = -1;
      for (let i = 0; i < state.rects.length; i++) {
        if (
          state.rects[i].widgetId == widgetId &&
          state.rects[i].displayId == displayId
        ) {
          index = i;
          return index;
        }
      }
    },
    drawend(e) {
      let tempWells = {};
      let wellToRelateCount = 0;
      for (let item in this.wells) {
        if (this.wells[item].lat && this.wells[item].long) {
          // if (e.target.sketchLineCoords_[0])
          var polygonGeometry = e.feature.getGeometry();

          let coords = [this.wells[item].long, this.wells[item].lat];

          if (
            polygonGeometry.intersectsCoordinate(coords) &&
            !this.selectedWells.hasOwnProperty(item)
          )
            tempWells[item] = this.wells[item];
          //
        }
      }

      this.selectedWells = { ...tempWells, ...this.selectedWells };
      console.log(
        "correlation::::wells drawEnd",
        this.selectedWells,
        tempWells
      );
      this.wellSelection();

      if (Object.keys(this.selectedWells).length >= 2) {
        let count = 0;
        for (let i in this.selectedWells) {
          //
          if (wellToRelateCount == 3) break;
          wellToRelateCount++;
          if (this.selectedWells[i].state == "UPDATING") count++;
          let idx = this.getIndex(
            this.$store.state.rect,
            this.widgetId,
            this.displayId
          );
          if (
            this.$store.state.rect.rects[idx].wellsToRelate &&
            this.$store.state.rect.rects[idx]?.wellsToRelate.find(
              (data) => data.wellId == i
            )
          )
            console.log("found");
          else
            this.$store.dispatch("rect/wellsToRelate", {
              displayId: this.displayId,
              widgetId: this.widgetId,
              widgetIndex: this.widgetIndex,
              value: { ...this.selectedWells[i], wellId: i },
              checked: true,
            });
        }
        console.log(
          "correlation::::wells drawEnd~~~~~~~~2",
          this.selectedWells,
          tempWells
        );
        //
        this.$store.dispatch("disp/correlationMap", {
          display: this.displayId,
        });
       

        this.$store.dispatch("rect/wellRelationOptimization", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          widgetIndex: this.widgetIndex,
          value: this.$store.state.data.optimizations[0],
          checked: true,
        });
        console.log(
          "checking the store values:::::",
          this.$store.state.data.optimizations
        );
        if (count == 1) {
          this.$store.dispatch("disp/correlationMapStreamSync", {
            display: this.displayId,
            value: true,
          });
        } else {
          this.$store.dispatch("disp/correlationMapStreamSync", {
            display: this.displayId,
            value: false,
          });
        }
      }

      // drawEnable.value = false;
    },

    dark() {
      this.$emit("dark");
    },
    light() {
      this.$emit("light");
    },
    changeBG() {
      if (this.darkDark !== "white") {
        console.log("change bg", document.querySelector(".ol-layer canvas"));
        if (document.querySelector(".ol-layer canvas")) {
          let canvasArray = document.querySelectorAll(".ol-layer canvas");
          canvasArray.forEach(
            (each) => (each.style.filter = "invert(90%)  saturate(100%)")
          );
        }
      } else {
        console.log("change bg1", document.querySelector(".ol-layer canvas"));
        if (document.querySelector(".ol-layer canvas"))
          document.querySelector(".ol-layer canvas").style.filter = null;
        let canvasArray = document.querySelectorAll(".ol-layer canvas");
        canvasArray.forEach((each) => (each.style.filter = null));
      }
    },
    wellSelection() {
      this.$store.dispatch("rect/setCorrelationSelectedWell", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: this.selectedWells,
      });
    },
  },
  props: {
    correlationIdx: [String, Number],
    height: [String, Number],
    width: [String, Number],
    displayId: String,
    widgetId: String,
    widgetIndex: [Number, String],
  },
  computed: {
    wells() {
      if (this.$store.state.data.isCustomerUpdate) {
        this.$store.dispatch("data/setCustomerUpdate", false);
        this.wellNames = Object.keys(this.$store.state.data.wells);
        this.wellBoreNames = [];
        this.wellBoreNamesOrg = [];
        this.logNames = [];
        this.wellBores = [];

        if (!this.wellNames.length) {
          if (this.$store.state.disp.rects.length) {
            this.$store.state.disp.rects.map((display) => {
              //
              this.$store.dispatch("disp/setDisplay", {
                display: display.displayId,
                wellId: "",
                wellboreId: "",
                logId: "",
                logType: "",
                wellboreState: "",
                table: "",
                min: "",
                max: "",
                status: "",
                trajectoryLogId: "",
                logTypeId: "",
              });
            });
          }
        } else {
          this.selectedWell = this.wellNames[0];
          this.wellNamesOrg = [];
          for (let i in this.$store.state.data.wells) {
            this.wellNames.push(
              i +
                (this.$store.state.data.wells[i].rigname
                  ? " - " + this.$store.state.data.wells[i].rigname
                  : "")
            );
            this.wellSelectionObj[this.$store.state.data.wells[i].name] = i;
            this.wellNamesOrg.push(
              this.$store.state.data.wells[i].name +
                (this.$store.state.data.wells[i].rigname
                  ? " - " + this.$store.state.data.wells[i].rigname
                  : "")
            );
          }
          this.wellBoreNames = Object.keys(
            this.$store.state.data.wells[this.selectedWell]["wellbores"]
          );
          this.selectedWellbore = this.wellBoreNames[0];

          this.logNames = Object.keys(
            this.$store.state.data.wells[this.selectedWell]["wellbores"][
              this.selectedWellbore
            ]["logs"]
          );
          this.selectedLog = this.logNames[0];
          setTimeout(() => {
            this.$store.state.disp.rects.map((display) => {
              //
              this.setDisplay(display.displayId);
            });
          }, 500);
        }
      }
      //
      // return this.$store.state.data.wells?.filter((well)=> well.long && well.lat);
      let finalWells = {};
      const regexLat = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/;
      const regexLon =
        /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/;

      function check_lat_lon(lat, lon) {
        let validLat = regexLat.test(lat);
        let validLon = regexLon.test(lon);
        return validLat && validLon;
      }
      console.log(
        "correlation::::wells computed",
        this.$store.state.data.isCustomerUpdate,
        this.$store.state.data.wells
      );

      for (let well in this.$store.state.data.wells) {
        if (
          this.$store.state.data.wells[well].lat &&
          this.$store.state.data.wells[well].long &&
          check_lat_lon(
            this.$store.state.data.wells[well].lat,
            this.$store.state.data.wells[well].long
          )
        ) {
          finalWells[well] = this.$store.state.data.wells[well];
          try {
            for (let wellbore in finalWells[well].wellbores) {
              finalWells[well].state =
                finalWells[well].wellbores[wellbore]["logs"]["depth"].state;
              if (!finalWells[well].wellbores[wellbore]["logs"]["depth"]) {
                delete finalWells[well];
              }
            }
          } catch (error) {
            // finalWells[well].state = "err";
            delete finalWells[well];
          }
          this.center = [
            this.$store.state.data.wells[well].lat,
            this.$store.state.data.wells[well].long,
          ];
        }
      }
      console.log("correlation::::wells computed::::finalWells", finalWells);
      return finalWells;
      // return this.$store.state.data.wells
    },

    darkDark() {
      return this.$store.state.rect.darkmode;
    },
  },
  watch: {
    darkDark() {
      this.changeBG();
    },
  },
  beforeMount() {
    // this.$store.dispatch("data/getWells");
    this.wellNames = [];
    this.wellNamesOrg = [];
    for (let i in this.wells) {
      (this.wellLat = this.wells[i].lat),
        (this.wellLog = this.wells[i].long),
        this.wellNames.push(
          i + (this.wells[i].rigname ? " - " + this.wells[i].rigname : "")
        );
      this.wellSelectionObj[this.wells[i].name] = i;
      this.wellNamesOrg.push(
        this.wells[i].name +
          (this.wells[i].rigname ? " - " + this.wells[i].rigname : "")
      );
    }
    //
    //  this.getWellNames(this.wells);
  },
  mounted() {
    setTimeout(() => {
      this.changeBG();
      this.wellSelection();
    }, 100);
    this.$store.subscribe(async (mutation, state) => {
      if (mutation.type === "data/setCustomer") {
        console.log("listed in corre map");
        this.selectedWells = {};
      this.removeTheInteractionInMap()
        this.$store.dispatch("disp/correlationMap", {
          display: this.displayId,
          where: "customerChange",
          widgetId: this.widgetId,
        });
        this.$store.dispatch("rect/setCorrelationSelectedWell", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          value: {},
        });
        this.$store.dispatch("rect/wellsToRelate", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          widgetIndex: this.widgetIndex,
          value: {},
          checked: true,
          isReset: true,
        });
      }
    });
  },
};
</script>
<style scoped>
.map {
  height: 800px;
  width: 920px;
}
</style>
