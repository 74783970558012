<template>
  <div :id="widgetId" :style="{
    height: this.widgetHeight + 'px',
    width: this.widgetWidth + 'px',
    paddingTop: '2px',
    background: 'var(--wdigetBg)',
    transition: 'var(--transition)',
    'border-radius': '10px',
  }"
  class="killsheet_main"
  @contextmenu="this.setContextIdWidget"
  >
    <div class="h-full overflow-scroll" :style="{
      background: 'var(--wdigetBg)',
      color: darkDark !== 'white' ? '#fff' : '#222',
      transition: 'var(--transition)'
    }">
      <div class="flex justify-end px-3">
        <div class="float-right ptop flex items-center">
          <div>
            <button class="refresh text-xxxxxs font-semibold uppercase relative" v-on:click="toggleInclinationDataNull">
              Inclination
            </button>
          </div>
          <div class="px-3">
            <button class="refresh text-xxxxxs font-semibold uppercase relative" v-on:click="refresh">
              Refresh
            </button>
          </div>
          <div class="pr-3">
            <button class="refresh text-xxxxxs font-semibold uppercase relative" v-on:click="printData">
              Print
            </button>
          </div>
          <div class="ptop">
            <input type="checkbox" class="checkboxs hidden" id="checkboxs" v-model="checked"
              v-on:click="toggleVerticalHorizontal" />
            <label for="checkboxs" class="checkbox-labels">
              <svg width="8.025" height="10.5" viewBox="0 0 8.025 10.5" xmlns="http://www.w3.org/2000/svg">
                <g id="svgGroup" stroke-linecap="round" fill-rule="evenodd" font-size="9pt" stroke="#61cba1"
                  stroke-width="0.25mm" fill="#03e9f4" style="stroke: #03e9f4; stroke-width: 0.25mm; fill: #03e9f4">
                  <path
                    d="M 1.425 10.5 L 0 10.5 L 0 0 L 1.425 0 L 1.425 4.485 L 6.6 4.485 L 6.6 0 L 8.025 0 L 8.025 10.5 L 6.6 10.5 L 6.6 5.715 L 1.425 5.715 L 1.425 10.5 Z"
                    vector-effect="non-scaling-stroke" />
                </g>
              </svg>
              <svg width="9.3" height="10.665" viewBox="0 0 9.3 10.665" xmlns="http://www.w3.org/2000/svg">
                <g id="svgGroup" stroke-linecap="round" fill-rule="evenodd" font-size="9pt" stroke="#61cba1"
                  stroke-width="0.25mm" fill="#03e9f4" style="stroke: #03e9f4; stroke-width: 0.25mm; fill: #03e9f4">
                  <path
                    d="M 5.46 10.665 L 3.855 10.665 L 0 0.54 L 1.395 0 L 4.695 9.105 L 8.01 0.045 L 9.3 0.54 L 5.46 10.665 Z"
                    vector-effect="non-scaling-stroke" />
                </g>
              </svg>

              <span class="ball"></span>
            </label>
          </div>
        </div>
      </div>
      <div class="flex items-center incdata" :style="{
        height: this.widgetHeight + 'px',
        width: this.widgetWidth + 'px',
      }" v-show="!incldatanull">
        <div class="dispop px-5 py-2">
          <div class="p-5">
            <h2 class="font-semibold pb-3 pt-2 text-xxs text-center color-red">
              Unable to Understand The Well Profile
            </h2>
            <div class="mb-2 px-4 flex justify-between items-center flex-col">
              <label class="block text-xxxxs font-medium textColorClass pb-3">
                Please Provide the Inclination Data
              </label>
              <input name=""  
                onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                v-model="inclination"
                class="ml-2 shadow appearance-none border rounded-sm text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                id="leak" type="text" placeholder="Enter value" :style="{
                  background:
                    darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                  color: darkDark !== 'white' ? '#fff' : '#222',
                  transition: 'var(--transition)',
                }" />
            </div>
            <div class="mb-2 px-1 pt-3 flex flex-col">
              <p class="text-xxxs">Inclination &lt; 10 : Vertical</p>
              <p class="text-xxxs">Inclination &gt;= 10 : Horizontal</p>
            </div>
            <div class="mb-2 px-4 flex justify-end items-center">
              <button v-on:click="dispok" class="dispokbtn text-xxs mr-5 font-semibold px-5 py-2">
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- modal for confirm box to capture screen starts -->
      <div class="sendMail_modal h-full" v-show="isSupportScreenshotShowing">
        <div class="Child_Of_sendMail_modal">
          <div class="p-4 py-2 flex justify-end items-center shadow" style="height: 42px">
            <button v-on:click="close" class="text-sm font-normal uppercase text-red_" style="color: #cf634b">
              <i class="fas fa-times"></i>
            </button>
          </div>
          <div class="w-full flex px-5 py-3 overflow-auto" style="gap: 30px; height: calc(100% - 42px)">
            <div style="width: 80%; margin: auto">
              <canvas id="image_to_show" class="hidden"></canvas>
              <img :src="capturedImage" v-if="capturedImage" style="max-height: 80%" />
              <div class="mb-2 px-4 pt-3 flex justify-end items-center">
                <button v-on:click="download" class="dispokbtn text-xxs mr-5 font-semibold px-5 py-2">
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- modal for confirm box to capture screen ends -->

      <!-- Vertical Kill Sheet Design -->
      <div class="grid grid-cols-3 gap-2 capture" v-show="!checked" :style="{
        background: 'var(--wdigetBg)',
        color: darkDark !== 'white' ? '#fff' : '#222',
        transition: 'var(--transition)',
      }">
        <div class="col-span-2">
          <div class="pl-2 grid grid-cols-2 gap-2">
            <div class="px-2 grid grid-cols-2">
              <div class="flex col-span-2 justify-between">
                <div class="flex text-xxs justify-between content-center">
                  <h6 class="font-medium pt-px">Well Name</h6>
                  <p class="text-xs px-3 font-semibold colr">
                    {{ this.wellboreId }}
                  </p>
                </div>
                <div class="flex text-xxs justify-between content-center">
                  <h6 class="font-medium pt-px">Date</h6>
                  <p class="text-xs px-3 font-semibold">{{ time_show }}</p>
                </div>
              </div>
              <div class="col-span-2">
                <div class="grid grid-cols-3">
                  <div class="grid grid-cols-1">
                    <div class="pt-3 px-1">
                      <h4 class="font-semibold pb-1 pl-1 text-xxs">
                        User Inputs
                      </h4>
                      <div class="mb-2 pl-2 flex justify-between items-center">
                        <label class="block text-xxxs font-medium" for="password">
                          LOT Pressure (psi)
                        </label>
                        <input v-model="vuserinp.vuserdata.vlotp" @change="lotpressure"
                          class="ml-2 shadow appearance-none border rounded-sm text-xxxs py-1 px-2 w-14 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                          id="leak" type="text" placeholder="Enter value" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" />
                      </div>
                      <div class="mb-2 pl-2 flex justify-between items-center">
                        <label class="block text-xxxs font-medium" for="password">
                          M.Wt At Test (ppg)
                        </label>
                        <input v-model="vuserinp.vuserdata.rho_test" @change="lotpressure"
                          class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-14 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                          id="" type="text" placeholder="Enter value" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" />
                      </div>
                    </div>
                    <div class="px-1">
                      <h4 class="font-semibold pb-1 text-xxs">
                        Casing & Shoe Data
                      </h4>
                      <div class="mb-2 pl-1 flex justify-between items-center">
                        <label class="block text-xxxs font-medium" for="password">
                          Size (in)
                        </label>
                        <input v-model="idia"
                          class="ml-2 shadow appearance-none border rounded-sm w-14 text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                          id="" type="text" placeholder="Enter value" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" />
                      </div>
                      <div class="mb-2 pl-1 flex justify-between items-center">
                        <label class="block text-xxxs font-medium" for="password">
                          Casing MD (ft)
                        </label>
                        <input v-model="md"
                          class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-14 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                          id="" type="text" placeholder="Enter value" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" />
                      </div>
                      <div class="mb-2 pl-1 flex justify-between items-center">
                        <label class="block text-xxxs font-medium" for="password">
                          Casing TVD (ft)
                        </label>
                        <input v-model="tvd" @change="lotpressure"
                          class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-14 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                          id="" type="text" placeholder="Enter value" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" />
                      </div>
                    </div>
                  </div>
                  <div class="grid grid-cols-1">
                    <div class="pt-3 px-1">
                      <h4 class="font-semibold pb-1 pl-1 text-xxs">
                        Drilling Fluid Data
                      </h4>
                      <div class="mb-2 pt-1 pl-1 flex justify-between items-center">
                        <label class="block text-xxxs font-medium" for="password">
                          Density (ppg)
                        </label>
                        <input v-model="vuserinp.vuserdata.rho" @change="lotpressure"
                          class="shadow appearance-none border rounded-sm w-14 text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                          id="density" type="text" placeholder="Enter value" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" />
                      </div>
                      <div class="mb-2 pl-1 flex justify-between items-center">
                        <label class="block text-xxxs font-medium" for="password">
                          Gradient (psi/ft)
                        </label>
                        <input :disabled="shouldDisable" v-model="vuserinp.vuserdata.vgradient"
                          class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-14 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                          id="" type="text" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" />
                      </div>
                    </div>
                    <div class="px-1">
                      <h4 class="font-semibold pb-1 text-xxs">Hole Data</h4>
                      <div class="mb-2 pl-1 flex justify-between items-center">
                        <label class="block text-xxxs font-medium" for="password">
                          Size (in)
                        </label>
                        <input v-model="bit_size"
                          class="shadow appearance-none border rounded-sm w-14 text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                          id="" type="text" placeholder="Enter value" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" />
                      </div>
                      <div class="mb-2 pl-1 flex justify-between items-center">
                        <label class="block text-xxxs font-medium" for="password">
                          Hole Depth (ft)
                        </label>
                        <input v-model="dmea"
                          class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-14 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                          id="" type="text" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" placeholder="Enter value" />
                      </div>
                      <div class="mb-2 pl-1 flex justify-between items-center">
                        <label class="block text-xxxs font-medium" for="password">
                          Hole TVD (ft)
                        </label>
                        <input v-model="vuserinp.vuserdata.vbittvd"
                          class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-14 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                          id="" type="text" placeholder="Enter value" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" />
                      </div>
                      <div class="mb-2 pl-1 flex justify-between items-center">
                        <label class="block text-xxxs font-medium" for="password">
                          Bit Depth (ft)
                        </label>
                        <input v-model="dbtm"
                          class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-14 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                          id="" type="text" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" placeholder="Enter value" />
                      </div>

                      <div class="mb-2 pl-1 flex justify-between items-center">
                        <label class="block text-xxxs font-medium" for="password">
                          Bit TVD (ft)
                        </label>
                        <input v-model="vuserinp.vuserdata.vholetvd"
                          class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-14 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                          id="" type="text" placeholder="Enter value" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" />
                      </div>
                    </div>
                  </div>
                  <div class="grid grid-cols-1">
                    <svg height="280" width="133">
                      <g>
                        <path fill="none" :stroke="'var(--textColor)'" stroke-width="1.5px"
                          d="M 50 45 l 0 180 l 45 0 l 0 -168"></path>
                      </g>
                      <g>
                        <path fill="#aaa" :stroke="'var(--textColor)'" stroke-width="1.5px"
                          d="M 60 200 l -10 25 l 45 0 l -10 -25 l -25 0"></path>
                      </g>
                      <g>
                        <path fill="#aaa" :stroke="'var(--textColor)'" stroke-width="1.5px"
                          d="M 60 169 l 0 30 l 25 0 l 0 -30 l -25 0"></path>
                      </g>
                      <g>
                        <path fill="#aaa" :stroke="'var(--textColor)'" stroke-width="1.5px"
                          d="M 67 40 l 0 130 l 10 0 l 0 -130 l -10 0"></path>
                      </g>
                      <g>
                        <path fill="none" :stroke="'var(--textColor)'" stroke-width="1.5px" d="M 72 30 l 0 10"></path>
                      </g>
                      <g>
                        <path fill="none" :stroke="'var(--textColor)'" stroke-width="1.5px"
                          d="M 65 23 a 7, 7 0 1, 0 14, 0 a 7, 7 0 1, 0 -14, 0 "></path>
                      </g>
                      <g>
                        <path fill="none" :stroke="'var(--textColor)'" stroke-width="1.5px"
                          d="M 50 30 l 0 20 l 45 0 l 0 -20"></path>
                      </g>
                      <g>
                        <path fill="none" :stroke="'var(--textColor)'" stroke-width="1.5px" d="M 95 50 l 15 0 "></path>
                      </g>
                      <g>
                        <path fill="none" :stroke="'var(--textColor)'" stroke-width="1.5px" d="M 95 57 l 20 0 "></path>
                      </g>
                      <g>
                        <path fill="none" :stroke="'var(--textColor)'" stroke-width="1.5px" d="M 110 30 l 0 20 "></path>
                      </g>
                      <g>
                        <path fill="none" :stroke="'var(--textColor)'" stroke-width="1.5px" d="M 115 30 l 0 28 "></path>
                      </g>
                      <g>
                        <path fill="none" :stroke="'var(--textColor)'" stroke-width="1.5px"
                          d="M 106 23 a 7, 7 0 1, 0 14, 0 a 7, 7 0 1, 0 -14, 0 "></path>
                      </g>
                      <g>
                        <text x="75" y="238" text-anchor="middle" fill="var(--textColor)" font-size="8">
                          Bit Depth {{ dbtm }} (ft)
                        </text>
                      </g>
                      <g>
                        <text x="40" y="10" text-anchor="middle" fill="var(--textColor)" font-size="8">
                          SIDP {{ vuserinp.vuserdata.sidpp }} (psi)
                        </text>
                      </g>
                      <g>
                        <text transform="rotate(-90)" x="-130" y="110" text-anchor="middle" fill="var(--textColor)"
                          font-size="8">
                          SICP {{ vuserinp.vuserdata.sicp }} (psi)
                        </text>
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
              <div class="pb-1 col-span-2">
                <h4 class="font-semibold pb-1 text-xxs">
                  Pre Recorded Volume Data (Drill String Volume)
                </h4>
                <div class="pt-0.5 pb-0.5 pumpdiv">
                  <table class="border-collapse border pump w-full rounded-sm"
                    :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <tr>
                      <th class="border text-xxxs px-1 font-medium" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        Component
                      </th>
                      <th class="border text-xxxs px-1 font-medium" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        Length (ft)
                      </th>
                      <th class="border text-xxxs px-1 font-medium" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        Capacity (bbl/ft)
                      </th>
                      <th class="border text-xxxs px-1 font-medium" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        Volume (bbls)
                      </th>
                      <th class="border text-xxxs px-1 font-medium" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        Pump Strokes (stks)
                      </th>
                      <th class="border text-xxxs px-1 font-medium" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        Time (min)
                      </th>
                    </tr>
                    <tr>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        Drill Pipe
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" placeholder="Enter value" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="dplength" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" placeholder="Enter value" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="dpcapacity" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="dpvolume" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="dpstrokes" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="dptime" />
                      </td>
                    </tr>
                    <tr>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        HWDP
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" placeholder="Enter value" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="hwdplength" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" placeholder="Enter value" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="hwdpcapacity" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="hwdpvolume" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="hwdpstrokes" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="hwdptime" />
                      </td>
                    </tr>
                    <tr>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        DC 2
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" placeholder="Enter value" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="dc2length" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" placeholder="Enter value" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="dc2capacity" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="dc2volume" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="dc2strokes" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="dc2time" />
                      </td>
                    </tr>
                    <tr>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        DC 1
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" placeholder="Enter value" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="dc1length" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" placeholder="Enter value" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="dc1capacity" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="dc1volume" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="dc1strokes" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="dc1time" />
                      </td>
                    </tr>

                    <tr>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        Total
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="totallength" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <!-- D: sum of volumes or drill string volume -->
                        <input :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="drillstringvolumeV" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="totalstrokes" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="totaltime" />
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div class="pb-1 col-span-2">
                <h4 class="font-semibold pb-1 text-xxs">
                  Annular Casing Volume
                </h4>
                <div class="pt-0.5 pb-2 pumpdiv">
                  <table class="border-collapse border pump w-full rounded-sm"
                    :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <tr>
                      <th class="border text-xxxs px-1 font-medium" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        Component
                      </th>
                      <th class="border text-xxxs px-1 font-medium" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        Length (ft)
                      </th>
                      <th class="border text-xxxs px-1 font-medium" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        Capacity (bbl/ft)
                      </th>
                      <th class="border text-xxxs px-1 font-medium" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        Volume (bbls)
                      </th>
                      <th class="border text-xxxs px-1 font-medium" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        Pump Strokes (stks)
                      </th>
                      <th class="border text-xxxs px-1 font-medium" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        Time (min)
                      </th>
                    </tr>
                    <tr>
                      <td class="border text-xxxs px-1 uppercase font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        DP X Casing
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" placeholder="Enter value" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="casingdplength" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" placeholder="Enter value" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="casingdpannularcapacity" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" placeholder="Enter value" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="cassed_hole_vol" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="casingdpstrokes" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="casingdptime" />
                      </td>
                    </tr>
                    <tr>
                      <td class="border text-xxxs px-1 uppercase font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        HWDP X Casing
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" placeholder="Enter value" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="casinghwdplength" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" placeholder="Enter value" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="casinghwdpannularcapacity" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="casinghwdpannularvolume" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" v-model="casinghwdpannularstrokes" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" v-model="casinghwdpannulartime" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" />
                      </td>
                    </tr>
                    <tr>
                      <td class="border text-xxxs px-1 uppercase font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        DC 2 X Casing
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" placeholder="Enter value" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="casingdc2length" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" placeholder="Enter value" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="casingdc2annularcapacity" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" placeholder="Enter value" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="dc2annularvoulume" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="dc2annularstrokes" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" v-model="dc2annulartime" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" />
                      </td>
                    </tr>
                    <tr>
                      <td class="border text-xxxs px-1 uppercase font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        DC 1 X Casing
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" placeholder="Enter value" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="casingdc1length" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" placeholder="Enter value" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="casingdc1annularcapacity" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" placeholder="Enter value" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="dcannularvoulume" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable" v-model="dcannularstrokes"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable" v-model="dcannulartime"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" />
                      </td>
                    </tr>
                    <tr>
                      <td class="border text-xxxs px-1 uppercase font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        Total
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="totalcasingdplength" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="totalcasingdpvolume" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="total_casingdpstrokes" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="total_casingdptime" />
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <div class="py-1">
              <h4 class="font-semibold pb-1 text-xxs">Open Hole Volume</h4>
              <div class="pt-0.5 pb-2 pumpdiv">
                <table class="border-collapse border pump w-full rounded-sm"
                  :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                  <tr>
                    <th class="border text-xxxs px-1 font-medium" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      Component
                    </th>
                    <th class="border text-xxxs px-1 font-medium" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      Length (ft)
                    </th>
                    <th class="border text-xxxs px-1 font-medium" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      Capacity (bbl/ft)
                    </th>
                    <th class="border text-xxxs px-1 font-medium" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      Volume (bbls)
                    </th>
                    <th class="border text-xxxs px-1 font-medium" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      Pump Strokes (stks)
                    </th>
                    <th class="border text-xxxs px-1 font-medium" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      Time (min)
                    </th>
                  </tr>
                  <tr>
                    <td class="border text-xxxs px-1 uppercase font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      DP X Open Hole
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" placeholder="Enter value" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="openholedplength" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" placeholder="Enter value" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="ohdpannularcapacity" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="dpannularvolume" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="dpannularstrokes" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="openholedptime" />
                    </td>
                  </tr>
                  <tr>
                    <td class="border text-xxxs px-1 uppercase font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      HWDP X Open Hole
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" placeholder="Enter value" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="openholehwdplength" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" placeholder="Enter value" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="hwdpannularcapacity" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="hwdpannularvolume" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="hwdpannularstrokes" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="openholehwdptime" />
                    </td>
                  </tr>
                  <tr>
                    <td class="border text-xxxs px-1 uppercase font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      DC 2 X Open Hole
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" placeholder="Enter value" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="openholedc2length" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" placeholder="Enter value" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="ohdc2annularcapacity" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="dc2annularvolume" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="ohdc2annularstrokes" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="openholedc2time" />
                    </td>
                  </tr>
                  <tr>
                    <td class="border text-xxxs px-1 uppercase font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      DC 1 X Open Hole
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" placeholder="Enter value" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="openholedc1length" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" placeholder="Enter value" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="ohdc1annularcapacity" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="dc1annularvolume" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="dc1annularstrokes" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="openholedc1time" />
                    </td>
                  </tr>
                  <tr>
                    <td class="border text-xxxs px-1 uppercase font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      Total
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="totopenholelength" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="openholevolume" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="totopenholestrokes" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" placeholder="Enter value" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="totalopenholetime" />
                    </td>
                  </tr>
                </table>
              </div>
              <h4 class="font-semibold pb-1 text-xxs">Total Annular Volume</h4>
              <div class="pt-0.5 pb-2 pumpdiv">
                <table class="border-collapse border pump w-full rounded-sm"
                  :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                  <tr>
                    <th class="border text-xxxs px-1 font-medium" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      Total Annular Volume (bbls)
                    </th>
                    <th class="border text-xxxs px-1 font-medium" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      Pump Strokes (stks)
                    </th>
                    <th class="border text-xxxs px-1 font-medium" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      Time (min)
                    </th>
                  </tr>
                  <tr>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="annularvolumeV" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="lagstrokes" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="lagtime" />
                    </td>
                  </tr>
                </table>
              </div>
              <h4 class="font-semibold pb-1 text-xxs">
                Total Well System Volume
              </h4>
              <div class="pt-0.5 pb-2 pumpdiv">
                <table class="border-collapse border pump w-full rounded-sm"
                  :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                  <tr>
                    <th class="border text-xxxs px-1 font-medium" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      Total Well System Volume (bbls)
                    </th>
                    <th class="border text-xxxs px-1 font-medium" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      Pump Strokes (stks)
                    </th>
                    <th class="border text-xxxs px-1 font-medium" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      Time (min)
                    </th>
                  </tr>
                  <tr>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="cycle_volume_v" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="cycle_strokes" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="cycle_time" />
                    </td>
                  </tr>
                </table>
              </div>
              <h4 class="font-semibold pb-1 text-xxs">Active Surface Volume</h4>
              <div class="pt-0.5 pb-2 pumpdiv">
                <table class="border-collapse border pump w-full rounded-sm"
                  :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                  <tr>
                    <th class="border text-xxxs px-1 font-medium" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      Active Surface Volume (bbls)
                    </th>
                    <th class="border text-xxxs px-1 font-medium" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      Pump Strokes (stks)
                    </th>
                  </tr>
                  <tr>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" placeholder="Enter value" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="active_surface_vol" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="active_surface_vol_pumpstrokes" />
                    </td>
                  </tr>
                </table>
              </div>
              <h4 class="font-semibold pb-1 text-xxs">
                Total Active Fluid System
              </h4>
              <div class="pt-0.5 pb-2 pumpdiv">
                <table class="border-collapse border pump w-full rounded-sm"
                  :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                  <tr>
                    <th class="border text-xxxs px-1 font-medium" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      Total Active Fluid System (bbls)
                    </th>
                    <th class="border text-xxxs px-1 font-medium" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      Pump Strokes (stks)
                    </th>
                  </tr>
                  <tr>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="tot_active_fluid_sys" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="tot_active_fluid_sys_pumpstrokes" />
                    </td>
                  </tr>
                </table>
              </div>
              <h4 class="font-semibold pb-1 text-xxs">Surface Line Volume</h4>
              <div class="mb-2 pl-2 flex justify-between items-center">
                <label class="block text-xxxs font-medium" for="password">
                  Surface Line Volume (bbls)
                </label>
                <input name="" v-model="surface_line_volume" @change="surflinvol"
                  class="ml-2 shadow appearance-none border rounded-sm text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                  id="leak" type="text" placeholder="Enter value" :style="{
                    background:
                      darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                    color: darkDark !== 'white' ? '#fff' : '#222',
                    transition: 'var(--transition)',
                  }" />
              </div>
              <div class="mb-2 pl-2 flex justify-between items-center">
                <label class="block text-xxxs font-medium" for="password">Surface Line Strokes (stks)
                </label>
                <input :disabled="shouldDisable" name="" v-model="surface_line_volume_strokes"
                  class="ml-2 shadow appearance-none border text-xxxs rounded-sm py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                  id="" type="text" :style="{
                    background:
                      darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                    color: darkDark !== 'white' ? '#fff' : '#222',
                    transition: 'var(--transition)',
                  }" />
              </div>
            </div>
          </div>
        </div>
        <div class="px-2">
          <div class="flex items-center justify-between py-3">
            <div class="flex items-center">
              <button class="dispcalcbtn text-xxxxxs font-semibold uppercase relative" v-on:click="dispcalc">
                Click Here For Pump Displacement Calc
              </button>
              <!-- PUMP DISPLACEMENT CALC INPUT MODEL STARTS -->
              <div class="dispcalcpopupcl" v-show="!dispcalcpopup">
                <div class="dispop" :style="{
                  height: this.widgetdisppopHeight + 'px',
                  width: this.widgetdisppopWidth + 'px',
                }">
                  <div class="p-5">
                    <h4 class="font-semibold pb-1 pl-1 text-xxs text_theme">
                      Pump Displacement Inputs
                    </h4>
                    <div class="mb-2 pl-4 pt-3 flex justify-between items-center" style="padding-right: 16px">
                      <label class="block text-xxxs font-medium text_theme">
                        Pump Number</label>
                      <select v-model="dispselected" @change="dispselectPumpno"
                        class="ml-2 pmpselc text-xxxs text_theme focus:outline-none focus-visible:outline-none" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }">
                        <option value="select">Please Select</option>
                        <option v-for="option in options" :value="option.text" :key="option.text">
                          {{ option.text }}
                        </option>
                      </select>
                    </div>
                    <div class="mb-2 px-4 flex justify-between items-center">
                      <label class="block text-xxxs font-medium text_theme">
                        Liner Diameter
                      </label>
                      <input name="" v-model="diameter"
                        class="ml-2 shadow appearance-none border rounded-sm text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                        id="leak" type="text" placeholder="Enter value" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" />
                    </div>
                    <div class="mb-2 px-4 flex justify-between items-center">
                      <label class="block text-xxxs font-medium text_theme">
                        Liner/Stroke Length
                      </label>
                      <input name="" v-model="strokelength"
                        class="ml-2 shadow appearance-none border text-xxxs rounded-sm py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                        id="" type="text" placeholder="Enter value" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" />
                    </div>
                    <div class="mb-2 px-4 flex justify-between items-center">
                      <label class="block text-xxxs font-medium text_theme" for="password" @input="intonly">
                        Pump Efficiency (in %)
                      </label>
                      <input name="" v-model="efficiency"
                        class="ml-2 shadow appearance-none border text-xxxs rounded-sm py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                        id="" type="text" placeholder="Enter value" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" />
                    </div>
                    <div class="mb-2 pr-5 pt-5 flex justify-end items-center">
                      <button v-on:click="dispopopn" class="dispcalcnclbtn text-xxs mr-5 font-semibold px-5 py-2">
                        Cancel
                      </button>
                      <button v-on:click="dispsave" class="dispcalcbtn text-xxs mr-5 font-semibold px-5 py-2">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- PUMP DISPLACEMENT CALC INPUT MODEL END -->
            </div>
            <div class="flex items-center" :class="{ dispdata: isdispdata }">
              <h4 class="font-semibold pb-1 text-xxs inline-block pt-1 pr-4">
                Pump No
              </h4>
              <select v-model="selected" @change="selectPumpno"
                class="pmpselc text-xxxs text-white focus:outline-none focus-visible:outline-none" :style="{
                  background:
                    darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                  color: darkDark !== 'white' ? '#fff' : '#222',
                  borderColor: darkDark !== 'white' ? '#fff' : '#444',
                  transition: 'var(--transition)',
                }">
                <option value="select">Please Select</option>
                <option v-for="option in options" :value="option.text" :key="option.text">
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="pumpdiv pt-2">
            <table class="border-collapse border pump w-full">
              <tr>
                <th class="border text-xxxs font-medium"
                  :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                  Pump No.
                </th>
                <th class="border text-xxxs font-medium"
                  :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                  Displacement (bbl/stk)
                </th>
              </tr>
              <tr>
                <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                  <input :disabled="shouldDisable"
                    class="pmpinp text-xxxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                    id="" placeholder="Enter value" :style="{
                      background:
                        darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      transition: 'var(--transition)',
                    }" value="Pump No 1" />
                </td>
                <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                  <input :disabled="shouldDisable"
                    class="pmpinp text-xxxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                    id="" :style="{
                      background:
                        darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      transition: 'var(--transition)',
                    }" v-model="pumpoutput1" />
                </td>
              </tr>
              <tr>
                <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                  <input :disabled="shouldDisable"
                    class="pmpinp text-xxxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                    id="" placeholder="Enter value" :style="{
                      background:
                        darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      transition: 'var(--transition)',
                    }" value="Pump No 2" />
                </td>
                <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                  <input :disabled="shouldDisable"
                    class="pmpinp text-xxxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                    id="" :style="{
                      background:
                        darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      transition: 'var(--transition)',
                    }" v-model="pumpoutput2" />
                </td>
              </tr>
              <tr>
                <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                  <input :disabled="shouldDisable"
                    class="pmpinp text-xxxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                    id="" placeholder="Enter value" :style="{
                      background:
                        darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      transition: 'var(--transition)',
                    }" value="Pump No 3" />
                </td>
                <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                  <input :disabled="shouldDisable"
                    class="pmpinp text-xxxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                    id="" :style="{
                      background:
                        darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      transition: 'var(--transition)',
                    }" v-model="pumpoutput3" />
                </td>
              </tr>
              <tr>
                <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                  <input :disabled="shouldDisable"
                    class="pmpinp text-xxxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                    id="" :style="{
                      background:
                        darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      transition: 'var(--transition)',
                    }" value="Pump No 4" />
                </td>
                <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                  <input :disabled="shouldDisable"
                    class="pmpinp text-xxxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                    id="" :style="{
                      background:
                        darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      transition: 'var(--transition)',
                    }" v-model="pumpoutput4" />
                </td>
              </tr>
            </table>
          </div>
          <div class="py-1">
            <h4 class="font-semibold pb-0 text-xxs">Slow Pump Rate Data</h4>
            <div class="py-1 pumpdiv">
              <table class="border-collapse border pump w-full"
                :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                <tr>
                  <th></th>
                  <th rowspan="2" class="border text-xxxs font-medium"
                    :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    Slow Pump Rate Data (SPM)
                  </th>
                  <th colspan="4" class="border text-xxxs font-medium"
                    :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    Dynamic Pressure Loss (psi)
                  </th>
                </tr>
                <tr>
                  <td></td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    Pump No.1
                  </td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    Pump No.2
                  </td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    Pump No.3
                  </td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    Pump No.4
                  </td>
                </tr>
                <tr>
                  <td class="border text-xxxs px-2 relative chec"
                    :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <label class="lbl" :class="{
                      'slpmprate2': isslpmprate2, 'slpmprate3' : isslpmprate3,
                    }"><input type="checkbox" v-model="checkbox_1" :checked="checkbox_1" class="chekbox 1" @click="slpmprate1" ref="theCheckbox1" /><span
                        class="checkmark"></span></label>
                        <!-- <label class="lbl" :class="{slpmprate2:slpmprate2}">
                          <input type="checkbox" class="chekbox 1" @click="slpmprate1(),checkbox1Clicked(1)" ref="theCheckbox1" /><span
                        class="checkmark"></span></label> -->
                  </td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id="" placeholder="Enter value" :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }" v-on:change="dplval" v-model="slopmprate25" />
                  </td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id="" placeholder="Enter value" :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }" v-on:change="dplval" v-model="slopmp1rate25" />
                  </td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id="" placeholder="Enter value" :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }" v-on:change="dplval" v-model="slopmp2rate25" />
                  </td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id="" placeholder="Enter value" :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }" v-on:change="dplval" v-model="slopmp3rate25" />
                  </td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id="" placeholder="Enter value" :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }" v-on:change="dplval" v-model="slopmp4rate25" />
                  </td>
                </tr>
                <tr>
                  <td class="border text-xxxs px-2 relative chec"
                    :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <label class="lbl" :class="{
                      'slpmprate1': isAddClass , 'slpmprate3' : isslpmprate3,
                    }"><input type="checkbox" v-model="checkbox_2" :checked="checkbox_2" class="chekbox 2" @click="slpmprate2" ref="theCheckbox2" /><span
                        class="checkmark"></span></label>
                  </td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id="" placeholder="Enter value" :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }" v-on:change="dplval" v-model="slopmprate35" />
                  </td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id="" placeholder="Enter value" :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }" v-on:change="dplval" v-model="slopmp1rate35" />
                  </td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id="" placeholder="Enter value" :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }" v-on:change="dplval" v-model="slopmp2rate35" />
                  </td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id="" placeholder="Enter value" :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }" v-on:change="dplval" v-model="slopmp3rate35" />
                  </td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id="" placeholder="Enter value" :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }" v-on:change="dplval" v-model="slopmp4rate35" />
                  </td>
                </tr>
                <tr>
                  <td class="border text-xxxs px-2 relative chec"
                    :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <label class="lbl" :class="{
                      'slpmprate1' : isAddClass,'slpmprate2' : isslpmprate2,
                    }"><input type="checkbox" v-model="checkbox_3" :checked="checkbox_3" class="chekbox 3" @click="slpmprate3" ref="theCheckbox3" /><span
                        class="checkmark"></span></label>
                  </td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id="" placeholder="Enter value" :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }" v-on:change="dplval" v-model="slopmprate45" />
                  </td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id="" placeholder="Enter value" :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }" v-on:change="dplval" v-model="slopmp1rate45" />
                  </td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id="" placeholder="Enter value" :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }" v-on:change="dplval" v-model="slopmp2rate45" />
                  </td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id="" placeholder="Enter value" :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }" v-on:change="dplval" v-model="slopmp3rate45" />
                  </td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id="" placeholder="Enter value" :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }" v-on:change="dplval" v-model="slopmp4rate45" />
                  </td>
                </tr>
              </table>
            </div>
            <div class="pt-3 px-1">
              <h4 class="font-semibold pb-1 pl-1 text-xxs">Kick Data</h4>
              <div class="mb-2 px-3 flex justify-between items-center">
                <label class="block text-xxxs font-medium" for="password">
                  Shut In Drill Pipe Pressure (psi)
                </label>
                <input v-model="vuserinp.vuserdata.sidpp"
                  class="ml-2 shadow appearance-none border rounded-sm text-xxxs py-1 px-2 w-32 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                  id="leak" type="text" placeholder="Enter value" :style="{
                    background:
                      darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                    color: darkDark !== 'white' ? '#fff' : '#222',
                    transition: 'var(--transition)',
                  }" />
              </div>
              <div class="mb-2 px-3 flex justify-between items-center">
                <label class="block text-xxxs font-medium" for="password">
                  Shut In Casing Pressure (psi)
                </label>
                <input v-model="vuserinp.vuserdata.sicp"
                  class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-32 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                  id="" type="text" placeholder="Enter value" :style="{
                    background:
                      darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                    color: darkDark !== 'white' ? '#fff' : '#222',
                    transition: 'var(--transition)',
                  }" />
              </div>
              <div class="mb-2 px-3 flex justify-between items-center">
                <label class="block text-xxxs font-medium" for="password">
                  Pit Gain (bbls)
                </label>
                <input v-model="pitgain"
                  class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-32 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                  id="" type="text" placeholder="Enter value" :style="{
                    background:
                      darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                    color: darkDark !== 'white' ? '#fff' : '#222',
                    transition: 'var(--transition)',
                  }" />
              </div>
              <div class="mb-2 px-3 flex justify-between items-center">
                <label class="block text-xxxs font-medium" for="password">
                  Max Allowable Mud Density (ppg)
                </label>
                <input :disabled="shouldDisable" v-model="mamd"
                  class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-32 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                  id="" type="text" :style="{
                    background:
                      darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                    color: darkDark !== 'white' ? '#fff' : '#222',
                    transition: 'var(--transition)',
                  }" />
              </div>
              <div class="mb-2 px-3 flex justify-between items-center">
                <label class="block text-xxxs font-medium" for="password">
                  MAASP (psi)
                </label>
                <input :disabled="shouldDisable" v-model="maasp"
                  class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-32 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                  id="" type="text" :style="{
                    background:
                      darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                    color: darkDark !== 'white' ? '#fff' : '#222',
                    transition: 'var(--transition)',
                  }" />
              </div>
            </div>
            <div class="conclusion px-2">
              <a href="#" class="execute-btn" v-on:click="execute">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                execute
              </a>
              <a href="#"
                class="minimizer flex items-center text-white text-xs font-bold leading-relaxed text_xs whitespace-nowrap uppercase dropdown z-40 menu_icons_bar"
                v-if="bottomMinimizer" @click="maximize()">
                <div class="tooltip">Maximize</div>
                <i class="fa fa-plus" aria-hidden="true"></i>
                <a class="icon_minimizer" href="#">
                  <p><!-- <i class="fa fa-eye" aria-hidden="true"></i> --><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M0 4H4V0H0V4ZM6 16H10V12H6V16ZM0 16H4V12H0V16ZM0 10H4V6H0V10ZM6 10H10V6H6V10ZM12 0V4H16V0H12ZM6 4H10V0H6V4ZM12 10H16V6H12V10ZM12 16H16V12H12V16Z" fill="white">

                    </path>
                  </svg>
                </p>
              </a>
              </a>
              <!-- !isHidden  horizantal-->
              <div class="poup text-white px-7 py-3" v-show="!isHidden" :style="{
                height: this.widgetpopHeight + 'px',
                width: this.widgetpopWidth + 'px',
              }" :class="{ minimize: isminimize }">
                <div class="flex justify-end p-1">
                  <a href="#"
                    class="flex items-center text-white text-xs font-bold leading-relaxed text_xs whitespace-nowrap uppercase dropdown z-40 menu_icons_bar px-4"
                    @click="minimize" v-if="!bottomMinimizer">
                    <div class="tooltip colorOfKillTool" >Minimize</div>
                    <i class="fas fa-window-minimize" style="color:var(--textColor)"></i>
                  </a>
                  <button  v-on:click="isHidden = !isHidden" class="pr-2 menu_icons_bar">
                    <div class="tooltip colorOfKillTool" >Close</div>
                    <svg width="15" height="15">
                      <path d="M 5,5 l 10,10 M 15,5 l -10,10" stroke="red" stroke-width="2" />
                    </svg>
                  </button>
                </div>
                <div class="flex justify-between p-3">
                  <div class="p-3">
                    <div class="flex">
                      <div class="firtPart-in-result border">
                        <div class="flex justify-between ">
                          <div class="flex text-xxs justify-around w-full  border-b content-center">
                            <h6 class="font-medium p-1 border-r w-11/12 pt-px">
                              Kill Fluid Density (ppg)
                            </h6>
                            <span class="text-xxs px-3 font-semibold  w-1/2 text-center flex justify-between items-center">{{
                              kfd
                            }}</span>
                          </div>
                        </div>
                        <div class="flex justify-between ">
                          <div class="flex text-xxs justify-around w-full border-b  content-center">
                            <h6 class="font-medium border-r p-1 w-11/12 pt-px">
                              Kill Fluid Gradient (psi/ft)
                            </h6>
                            <span class="text-xxs px-3 font-semibold  w-1/2 text-center flex justify-between items-center">{{
                              kfg
                            }}</span>
                          </div>
                        </div>
                        <div class="flex justify-between ">
                          <div class="flex text-xxs justify-around w-full border-b  content-center">
                            <h6 class="font-medium border-r pt-px p-1 w-11/12">
                              Initial Circulating Pressure (psi)
                            </h6>
                            <span class="text-xxs px-3 font-semibold  w-1/2 text-center flex justify-between items-center">{{
                              ICP
                            }}</span>
                          </div>
                        </div>
                        <div class="flex justify-between ">
                          <div class="flex text-xxs justify-around border-b w-full  content-center">
                            <h6 class="font-medium border-r pt-px p-1 w-11/12">
                              Final Circulating Pressure (psi)
                            </h6>
                            <span class="text-xxs px-3 font-semibold w-1/2 text-center flex justify-between items-center">{{
                              FCP
                            }}</span>
                          </div>
                        </div>
                        <div class="flex justify-between ">
                          <div class="flex text-xxs justify-around w-full  content-center">
                            <h6 class="font-medium border-r pt-px p-1  w-11/12">
                              K ( psi/100 strokes )
                            </h6>
                            <p class="text-xxs px-3 font-semibold w-1/2 text-center flex justify-between items-center">
                              {{ Kval }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <svg height="200" width="133">
                          <g>
                            <path fill="none" :stroke="'var(--textColor)'" stroke-width="1.5px"
                              d="M 50 30 l 0 160 l 45 0 l 0 -133"></path>
                          </g>
                          <g>
                            <path fill="#aaa" :stroke="'var(--textColor)'" stroke-width="1.5px"
                              d="M 60 165 l -10 25 l 45 0 l -10 -25 l -25 0"></path>
                          </g>
                          <g>
                            <path fill="#aaa" :stroke="'var(--textColor)'" stroke-width="1.5px"
                              d="M 60 135 l 0 30 l 25 0 l 0 -30 l -25 0"></path>
                          </g>
                          <g>
                            <path fill="#aaa" :stroke="'var(--textColor)'" stroke-width="1.5px"
                              d="M 67 40 l 0 95 l 10 0 l 0 -95 l -10 0"></path>
                          </g>
                          <g>
                            <path fill="none" :stroke="'var(--textColor)'" stroke-width="1.5px" d="M 72 30 l 0 10"></path>
                          </g>
                          <g>
                            <path fill="none" :stroke="'var(--textColor)'" stroke-width="1.5px"
                              d="M 65 23 a 7, 7 0 1, 0 14, 0 a 7, 7 0 1, 0 -14, 0 "></path>
                          </g>
                          <g>
                            <path fill="none" :stroke="'var(--textColor)'" stroke-width="1.5px"
                              d="M 50 30 l 0 20 l 45 0 l 0 -20"></path>
                          </g>
                          <g>
                            <path fill="none" :stroke="'var(--textColor)'" stroke-width="1.5px" d="M 95 50 l 15 0 ">
                            </path>
                          </g>
                          <g>
                            <path fill="none" :stroke="'var(--textColor)'" stroke-width="1.5px" d="M 95 57 l 20 0 ">
                            </path>
                          </g>
                          <g>
                            <path fill="none" :stroke="'var(--textColor)'" stroke-width="1.5px" d="M 110 30 l 0 20 ">
                            </path>
                          </g>
                          <g>
                            <path fill="none" :stroke="'var(--textColor)'" stroke-width="1.5px" d="M 115 30 l 0 28 ">
                            </path>
                          </g>
                          <g>
                            <path fill="none" :stroke="'var(--textColor)'" stroke-width="1.5px"
                              d="M 106 23 a 7, 7 0 1, 0 14, 0 a 7, 7 0 1, 0 -14, 0 "></path>
                          </g>
                          <g>
                            <text x="75" y="199" text-anchor="middle" fill="var(--textColor)" font-size="8">
                              Bit Depth {{ dmea }} (ft)
                            </text>
                          </g>
                          <g>
                            <text x="33" y="15" text-anchor="middle" fill="var(--textColor)" font-size="8">
                              SIDP {{ vuserinp.vuserdata.sidpp }} (psi)
                            </text>
                          </g>
                          <g>
                            <text transform="rotate(-90)" x="-130" y="110" text-anchor="middle" fill="var(--textColor)"
                              font-size="8">
                              SICP {{ vuserinp.vuserdata.sicp }} (psi)
                            </text>
                          </g>
                        </svg>
                      </div>
                    </div>
                    <div class="pb-4">
                      <h4 class="font-semibold pb-1 text-xxs">Final Volume</h4>
                      <div class="pt-0.5 pumpdiv">
                        <table class="border-collapse border pump w-full rounded-sm">
                          <tr>
                            <th class="border text-xxxs px-1 font-medium">
                              Volume
                            </th>
                            <th class="border text-xxxs px-1 font-medium">
                              Cumm. Volume (bbls)
                            </th>
                            <th class="border text-xxxs px-1 font-medium">
                              Pump Strokes (stks)
                            </th>
                            <th class="border text-xxxs px-1 font-medium">
                              Time (mins)
                            </th>
                          </tr>
                          <tr>
                            <td class="border text-xxxs px-1 font-light text-sx">
                              Drill String
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx">
                              <input disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id="" placeholder="Enter value" v-model="drillstringvolumeV" />
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx">
                              <input disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id="" placeholder="Enter value" v-model="totalstrokes" />
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx">
                              <input disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id="" placeholder="Enter value" v-model="totaltime" />
                            </td>
                          </tr>
                          <tr>
                            <td class="border text-xxxs px-1 font-light text-sx">
                              Open Hole
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx">
                              <input disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id="" placeholder="Enter value" v-model="openholevolume" />
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx">
                              <input disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id="" placeholder="Enter value" v-model="totopenholestrokes" />
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx">
                              <input disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id="" placeholder="Enter value" v-model="totalopenholetime" />
                            </td>
                          </tr>
                          <tr>
                            <td class="border text-xxxs px-1 font-light text-sx">
                              Annular Casing
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx">
                              <input disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id="" placeholder="Enter value" v-model="cassed_hole_vol" />
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx">
                              <input disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id="" placeholder="Enter value" v-model="casingdpstrokes" />
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx">
                              <input disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id="" placeholder="Enter value" v-model="casingdptime" />
                            </td>
                          </tr>
                          <tr>
                            <td class="border text-xxxs px-1 font-light text-sx">
                              Total Annular
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx">
                              <input disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id="" placeholder="Enter value" v-model="annularvolumeV" />
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx">
                              <input disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id="" placeholder="Enter value" v-model="lagstrokes" />
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx">
                              <input disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id="" placeholder="Enter value" v-model="lagtime" />
                            </td>
                          </tr>
                          <tr>
                            <td class="border text-xxxs px-1 font-light text-sx">
                              Total Well System
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx">
                              <input disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id="" placeholder="Enter value" v-model="cycle_volume_v" />
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx">
                              <input disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id="" placeholder="Enter value" v-model="cycle_strokes" />
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx">
                              <input disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id="" placeholder="Enter value" v-model="cycle_time" />
                            </td>
                          </tr>
                          <tr>
                            <td class="border text-xxxs px-1 font-light text-sx">
                              Active Surface Volume
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx">
                              <input disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id="" placeholder="Enter value" v-model="active_surface_vol" />
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx">
                              <input disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id="" placeholder="Enter value" v-model="active_surface_vol_pumpstrokes" />
                            </td>
                          </tr>
                          <tr>
                            <td class="border text-xxxs px-1 font-light text-sx">
                              Total Active Fluid System
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx">
                              <input disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id="" placeholder="Enter value" v-model="tot_active_fluid_sys" />
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx">
                              <input disabled
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                                id="" placeholder="Enter value" v-model="tot_active_fluid_sys_pumpstrokes" />
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="p-3">
                    <div class="pb-4">
                      <h4 class="font-semibold pb-1 text-xxs">Strokes</h4>
                      <div class="pt-0.5 pumpdiv">
                        <table class="border-collapse border pump w-full rounded-sm scroll">
                          <thead>
                            <tr>
                              <th class="conclusion_input border text-xxxs px-1 font-medium" :style="{
                                borderColor:
                                  darkDark !== 'white' ? '#fff' : '#444',
                              }">
                                Strokes (stks)
                              </th>
                              <th class="conclusion_input border text-xxxs px-1 font-medium" :style="{
                                borderColor:
                                  darkDark !== 'white' ? '#fff' : '#444',
                              }">
                                Pressure (psi)
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(val, id, index) in Pressure" :key="index">
                              <td class="conclusion_input border text-xxxs px-1 uppercase font-light text-sx">
                                {{ Strokes[id] }}
                              </td>
                              <td class="conclusion_input border text-xxxs px-1 font-light text-sx">
                                {{ val }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="cid mt-5">
                    <div class="" :id="'verticalKillsheet' + this.widgetId"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--End Vertical Kill Sheet  -->

      <!-- Horizontal Kill Sheet Code  -->
      <div class="grid grid-cols-3 gap-2 horizontal" v-show="checked">
        <div class="col-span-2">
          <div class="pl-2 grid grid-cols-2 gap-2 items-start">
            <div class="px-2 grid grid-cols-2 items-start">
              <div class="flex col-span-2 justify-between">
                <div class="flex text-xxs justify-between content-center">
                  <h6 class="font-medium pt-px">Well Name</h6>
                  <p class="text-xs px-3 font-semibold colr">
                    {{ this.wellboreId }}
                  </p>
                </div>
                <div class="flex text-xxs justify-between content-center">
                  <h6 class="font-medium pt-px">Date</h6>
                  <p class="text-xs px-3 font-semibold">{{ time_show }}</p>
                </div>
              </div>
              <div class="col-span-2">
                <div class="grid grid-cols-3">
                  <div class="grid col-span-2">
                    <div class="">
                      <h4 class="font-semibold pb-2 pt-2 text-xxs">
                        User Inputs
                      </h4>
                      <div class="flex justify-between">
                        <div class="w-1/2">
                          <div class="mb-2 pl-2 flex justify-between items-center">
                            <label class="block text-xxxs font-medium" for="password">LOT Pressure (psi)</label>
                            <input v-model="vuserinp.vuserdata.vlotp" @change="lotpressure"
                              class="shadow appearance-none border rounded-sm w-14 text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                              id="" type="text" placeholder="Enter value" :style="{
                                background:
                                  darkDark !== 'white'
                                    ? this.cardBackground
                                    : '#FFFFF7',
                                color: darkDark !== 'white' ? '#fff' : '#222',
                                transition: 'var(--transition)',
                              }" />
                          </div>
                          <div class="mb-2 pl-2 flex justify-between items-center">
                            <label class="block text-xxxs font-medium" for="password">MW At LOT Test (ppg)
                            </label>
                            <input v-model="vuserinp.vuserdata.rho_test" @change="lotpressure"
                              class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-14 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                              id="" type="text" placeholder="Enter value" :style="{
                                background:
                                  darkDark !== 'white'
                                    ? this.cardBackground
                                    : '#FFFFF7',
                                color: darkDark !== 'white' ? '#fff' : '#222',
                                transition: 'var(--transition)',
                              }" />
                          </div>
                          <div class="mb-2 pl-2 flex justify-between items-center">
                            <label class="block text-xxxs font-medium" for="password">
                              Current MW (ppg)
                            </label>
                            <input v-model="vuserinp.vuserdata.rho" @change="lotpressure"
                              class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-14 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                              id="" type="text" placeholder="Enter value" :style="{
                                background:
                                  darkDark !== 'white'
                                    ? this.cardBackground
                                    : '#FFFFF7',
                                color: darkDark !== 'white' ? '#fff' : '#222',
                                transition: 'var(--transition)',
                              }" />
                          </div>
                          <div class="mb-2 pl-2 flex justify-between items-center">
                            <label class="block text-xxxs font-medium" for="password">
                              Gradient (psi/ft)
                            </label>
                            <input v-model="vuserinp.vuserdata.vgradient"
                              class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-14 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                              type="text" readonly :style="{
                                background:
                                  darkDark !== 'white'
                                    ? this.cardBackground
                                    : '#FFFFF7',
                                color: darkDark !== 'white' ? '#fff' : '#222',
                                transition: 'var(--transition)',
                              }" />
                          </div>
                        </div>
                        <div class="w-1/2">
                          <div class="mb-2 pl-2 flex justify-between items-center">
                            <label class="block text-xxxs font-medium pl-2" for="password">
                              MAMD (ppg)</label>
                            <input :disabled="shouldDisable" v-model="mamd"
                              class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-14 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                              id="" type="text" :style="{
                                background:
                                  darkDark !== 'white'
                                    ? this.cardBackground
                                    : '#FFFFF7',
                                color: darkDark !== 'white' ? '#fff' : '#222',
                                transition: 'var(--transition)',
                              }" />
                          </div>
                          <div class="mb-2 pl-2 flex justify-between items-center">
                            <label class="block text-xxxs font-medium pl-2" for="password">
                              MAMG (psi/ft)</label>
                            <input :disabled="shouldDisable" v-model="mamg"
                              class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-14 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                              id="" type="text" :style="{
                                background:
                                  darkDark !== 'white'
                                    ? this.cardBackground
                                    : '#FFFFF7',
                                color: darkDark !== 'white' ? '#fff' : '#222',
                                transition: 'var(--transition)',
                              }" />
                          </div>
                          <div class="mb-2 pl-2 flex justify-between items-center">
                            <label class="block text-xxxs font-medium pl-2" for="password">
                              MAASP (psi)
                            </label>
                            <input :disabled="shouldDisable" v-model="maasp"
                              class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-14 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                              id="" type="text" :style="{
                                background:
                                  darkDark !== 'white'
                                    ? this.cardBackground
                                    : '#FFFFF7',
                                color: darkDark !== 'white' ? '#fff' : '#222',
                                transition: 'var(--transition)',
                              }" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="grid col-span-1">
                    <h4 class="font-semibold pb-2 pl-2 pt-2 text-xxs">
                      Deviation Data
                    </h4>
                    <div class="mb-2 pl-2 flex justify-between items-center">
                      <label class="block text-xxxs font-medium pl-2" for="password">
                        KOP1 MD (ft)
                      </label>
                      <input v-model="kop1md"
                        class="shadow appearance-none border rounded-sm w-14 text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                        id="density" type="text" placeholder="Enter value" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: 'var(--textColor)',
                          transition: 'var(--transition)',
                        }" />
                    </div>
                    <div class="mb-2 pl-2 flex justify-between items-center">
                      <label class="block text-xxxs font-medium pl-2" for="password">
                        KOP1 TVD (ft)
                      </label>
                      <input v-model="kop1tvd"
                        class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-14 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                        id="" type="text" placeholder="Enter value" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                            color: 'var(--textColor)',
                          transition: 'var(--transition)',
                        }" />
                    </div>
                    <div class="mb-2 pl-2 flex justify-between items-center">
                      <label class="block text-xxxs font-medium pl-2" for="password">
                        EOB MD (ft)
                      </label>
                      <input v-model="eobmd"
                        class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-14 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                        id="" type="text" placeholder="Enter value" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                            color: 'var(--textColor)',
                          transition: 'var(--transition)',
                        }" />
                    </div>
                    <div class="mb-2 pl-2 flex justify-between items-center">
                      <label class="block text-xxxs font-medium pl-2" for="password">
                        EOB TVD (ft)
                      </label>
                      <input v-model="eobtvd"
                        class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-14 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                        id="" type="text" placeholder="Enter value" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                            color: 'var(--textColor)',
                          transition: 'var(--transition)',
                        }" />
                    </div>
                  </div>
                </div>
                <div class="">
                  <div class="grid grid-cols-3">
                    <div class="">
                      <h4 class="font-semibold pb-2 text-xxs">
                        Casing Shoe Data
                      </h4>
                      <div class="">
                        <div class="">
                          <div class="mb-2 pl-2 flex justify-between items-center">
                            <label class="block text-xxxs font-medium" for="password">
                              ID (in)
                            </label>
                            <input v-model="idia"
                              class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-14 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                              id="" type="text" placeholder="Enter value" :style="{
                                background:
                                  darkDark !== 'white'
                                    ? this.cardBackground
                                    : '#FFFFF7',
                                color: darkDark !== 'white' ? '#fff' : '#222',
                                transition: 'var(--transition)',
                              }" />
                          </div>
                          <div class="mb-2 pl-2 flex justify-between items-center">
                            <label class="block text-xxxs font-medium" for="password">
                              Casing MD (ft)
                            </label>
                            <input v-model="md"
                              class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-14 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                              id="" type="text" placeholder="Enter value" :style="{
                                background:
                                  darkDark !== 'white'
                                    ? this.cardBackground
                                    : '#FFFFF7',
                                color: darkDark !== 'white' ? '#fff' : '#222',
                                transition: 'var(--transition)',
                              }" />
                          </div>
                          <div class="mb-2 pl-2 flex justify-between items-center">
                            <label class="block text-xxxs font-medium" for="password">
                              Shoe TVD (ft)
                            </label>
                            <input v-model="tvd"
                              class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-14 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                              id="" type="text" placeholder="Enter value" :style="{
                                background:
                                  darkDark !== 'white'
                                    ? this.cardBackground
                                    : '#FFFFF7',
                                color: darkDark !== 'white' ? '#fff' : '#222',
                                transition: 'var(--transition)',
                              }" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="pl-1">
                      <h4 class="font-semibold pb-2 text-xxs">Hole Data</h4>
                      <div class="w-full justify-around">
                        <div class="mb-2 pl-2 flex justify-between items-center">
                          <label class="block text-xxxs font-medium" for="password">
                            Size (in)
                          </label>
                          <input v-model="bit_size"
                            class="ml-2 shadow appearance-none border rounded-sm w-14 text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                            id="" type="text" placeholder="Enter value" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              color: darkDark !== 'white' ? '#fff' : '#222',
                              transition: 'var(--transition)',
                            }" />
                        </div>

                        <div class="mb-2 pl-2 flex justify-between items-center">
                          <label class="block text-xxxs font-medium" for="password">
                            Hole Depth (ft)
                          </label>
                          <input v-model="dmea"
                            class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-14 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                            id="" type="text" placeholder="Enter value" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              color: darkDark !== 'white' ? '#fff' : '#222',
                              transition: 'var(--transition)',
                            }" />
                        </div>
                        <div class="mb-2 pl-2 flex justify-between items-center">
                          <label class="block text-xxxs font-medium" for="password">
                            Hole TVD (ft)
                          </label>
                          <input v-model="vuserinp.vuserdata.vbittvd"
                            class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-14 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                            id="" type="text" placeholder="Enter value" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              color: darkDark !== 'white' ? '#fff' : '#222',
                              transition: 'var(--transition)',
                            }" />
                        </div>
                        <div class="mb-2 pl-2 flex justify-between items-center">
                          <label class="block text-xxxs font-medium" for="password">
                            Bit Depth (ft)
                          </label>
                          <input v-model="dbtm"
                            class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-14 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                            id="" type="text" placeholder="Enter value" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              color: darkDark !== 'white' ? '#fff' : '#222',
                              transition: 'var(--transition)',
                            }" />
                        </div>
                        <div class="mb-2 pl-2 flex justify-between items-center">
                          <label class="block text-xxxs font-medium" for="password">
                            Bit TVD (ft)
                          </label>
                          <input v-model="vuserinp.vuserdata.vholetvd"
                            class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-14 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                            id="" type="text" placeholder="Enter value" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              color: darkDark !== 'white' ? '#fff' : '#222',
                              transition: 'var(--transition)',
                            }" />
                        </div>
                      </div>
                    </div>
                    <div class="pl-1">
                      <h4 class="font-semibold pb-2 text-xxs">Kick Data</h4>
                      <div class="w-full justify-around">
                        <div class="mb-2 pl-2 flex justify-between items-center">
                          <label class="block text-xxxs font-medium" for="password">
                            SIDP (psi)
                          </label>
                          <input v-model="vuserinp.vuserdata.sidpp"
                            class="ml-2 shadow appearance-none border rounded-sm w-14 text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                            id="" type="text" placeholder="Enter value" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              color: darkDark !== 'white' ? '#fff' : '#222',
                              transition: 'var(--transition)',
                            }" />
                        </div>
                        <div class="mb-2 pl-2 flex justify-between items-center">
                          <label class="block text-xxxs font-medium" for="password">
                            SICP (psi)
                          </label>
                          <input v-model="vuserinp.vuserdata.sicp"
                            class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-14 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                            id="" type="text" placeholder="Enter value" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              color: darkDark !== 'white' ? '#fff' : '#222',
                              transition: 'var(--transition)',
                            }" />
                        </div>
                        <div class="mb-2 pl-2 flex justify-between items-center">
                          <label class="block text-xxxs font-medium" for="password">
                            Pit Gain (bbl)
                          </label>
                          <input v-model="pitgain"
                            class="ml-2 shadow appearance-none border text-xxxs rounded-sm w-14 py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                            id="" type="text" placeholder="Enter value" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              color: darkDark !== 'white' ? '#fff' : '#222',
                              transition: 'var(--transition)',
                            }" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-span-2">
               
                <div class="grid col-span-2">
                  <div class="flex justify-between">
                    <h4 class="font-semibold pb-1 text-xxs">
                      Pre Recorded Volume Data
                    </h4>
                  </div>
                  <div class="pt-0.5 pb-1 pumpdiv">
                    <table class="border-collapse border pump w-full rounded-sm" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <tr>
                        <th class="border text-xxxs px-1 font-medium" style="min-width: 15px" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          SR
                        </th>
                        <th class="border text-xxxs px-1 font-medium" style="min-width: 80px" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          Component
                        </th>
                        <th class="border text-xxxs px-1 font-medium" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          Length (ft)
                        </th>
                        <th class="border text-xxxs px-1 font-medium" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          Capacity (bbl/ft)
                        </th>
                        <th class="border text-xxxs px-1 font-medium" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          Volume (bbls)
                        </th>
                        <th class="border text-xxxs px-1 font-medium" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          Pump Strokes (stks)
                        </th>
                        <th class="border text-xxxs px-1 font-medium" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          Time (min)
                        </th>
                      </tr>
                      <tr>
                        <td class="border text-xxxs px-1 font-light text-sx color-green" style="min-width: 15px" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          A
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx color-green" style="min-width: 80px" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          DP-Surface to KOP1
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx color-green" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input
                            class="color-green pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" placeholder="Enter value" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="predplength" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx color-green" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input
                            class="color-green pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" placeholder="Enter value" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="dpcapacity" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx color-green" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="color-green pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="predpvolume" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx color-green" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="color-green pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="predpstrokes" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx color-green" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="color-green pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="predptime" />
                        </td>
                      </tr>

                      <tr>
                        <td class="border text-xxxs px-1 font-light text-sx color-green" style="min-width: 15px" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          B
                        </td>
                        <td class="border text-xxxs pr-1 font-light text-sx color-green" style="min-width: 80px" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <!-- <select
                            @change="preRecordedSelectHandler"
                            v-model="preseccell"
                            class="ml-2 precellslct text-xxxs text-white focus:outline-none focus-visible:outline-none"
                            :style="{
                              background:
                                darkDark  !== 'white'  
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              color: darkDark  !== 'white'  ?  '#fff' : '#222',
                            }"
                          >
                            <option value="select">Please Select</option>
                            <option
                              v-for="preData in preDatas"
                              :value="preData.text"
                              :key="preData.text"
                            >
                              {{ preData.text }}
                            </option>
                          </select> -->
                          HWDP to KOP 1
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx color-green" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input
                            class="color-green pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            placeholder="Enter value" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="prehwlength" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx color-green" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input
                            class="color-green pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            placeholder="Enter value" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="hwcapacity" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx color-green" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="color-green pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="prehwvolume" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx color-green" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="color-green pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="prehwstrokes" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx color-green" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="color-green pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="prehwtime" />
                        </td>
                      </tr>
                      <tr>
                        <td class="border text-xxxs px-1 font-light text-sx color-green" style="min-width: 15px" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          C
                        </td>
                        <td class="border text-xxxs pr-1 font-light text-sx color-green" style="min-width: 80px" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <!-- <select
                            @change="preRecordedSelectHandler"
                            v-model="preseccell"
                            class="ml-2 precellslct text-xxxs text-white focus:outline-none focus-visible:outline-none"
                            :style="{
                              background:
                                darkDark  !== 'white'  
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              color: darkDark  !== 'white'  ?  '#fff' : '#222',
                            }"
                          >
                            <option value="select">Please Select</option>
                            <option
                              v-for="preData in preDatas"
                              :value="preData.text"
                              :key="preData.text"
                            >
                              {{ preData.text }}
                            </option>
                          </select> -->
                          DC2 to KOP 1
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx color-green" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input
                            class="color-green pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            placeholder="Enter value" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="predc2tokoplength" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx color-green" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input
                            class="color-green pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            placeholder="Enter value" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="dc2tokopcapacity" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx color-green" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="color-green pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="predc2tokopvolume" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx color-green" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="color-green pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="predc2tokopstrokes" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx color-green" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="color-green pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="predc2tokoptime" />
                        </td>
                      </tr>
                      <tr>
                        <td class="border text-xxxs px-1 font-light text-sx color-green" style="min-width: 15px" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          D
                        </td>
                        <td class="border text-xxxs pr-1 font-light text-sx color-green" style="min-width: 80px" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <!-- <select
                            @change="preRecordedSelectHandler"
                            v-model="preseccell"
                            class="ml-2 precellslct text-xxxs text-white focus:outline-none focus-visible:outline-none"
                            :style="{
                              background:
                                darkDark  !== 'white'  
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              color: darkDark  !== 'white'  ?  '#fff' : '#222',
                            }"
                          >
                            <option value="select">Please Select</option>
                            <option
                              v-for="preData in preDatas"
                              :value="preData.text"
                              :key="preData.text"
                            >
                              {{ preData.text }}
                            </option>
                          </select> -->
                          DC1 to KOP 1
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx color-green" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input
                            class="color-green pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            placeholder="Enter value" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="predc1tokoplength" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx color-green" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input
                            class="color-green pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            placeholder="Enter value" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="dc1tokopcapacity" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx color-green" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="color-green pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="predc1tokopvolume" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx color-green" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="color-green pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="predc1tokopstrokes" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx color-green" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="color-green pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="predc1tokoptime" />
                        </td>
                      </tr>
                      <tr>
                        <td class="border text-xxxs px-1 font-light text-sx color-red" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          E
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx color-red" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          DP-KOP1 TO EOB
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input
                            class="color-red pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            placeholder="Enter value" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="preeoblength" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input
                            class="color-red pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" placeholder="Enter value" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="dpcapacity" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="color-red pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="preeobvolume" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="color-red pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="preeobstrokes" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="color-red pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="preeobtime" />
                        </td>
                      </tr>
                      <tr>
                        <td class="border text-xxxs px-1 font-light text-sx color-red" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          F
                        </td>
                        <td class="border text-xxxs pr-1 font-light text-sx color-red" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <!-- <select
                            @change="preRecordedSecondSelectHandler"
                            v-model="preforcell"
                            class="ml-2 precellslct color-red text-xxxs text-white focus:outline-none focus-visible:outline-none"
                            :style="{
                              background:
                                darkDark  !== 'white'  
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              color: darkDark  !== 'white'  ?  '#fff' : '#222',
                            }"
                          >
                            <option value="select">Please Select</option>
                            <option
                              v-for="prerecData in prerecDatas"
                              :value="prerecData.text"
                              :key="prerecData.text"
                            >
                              {{ prerecData.text }}
                            </option>
                          </select> -->
                          HWDP KOP1 to EOB
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input
                            class="color-red pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" placeholder="Enter value" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="prehweoblength" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input
                            class="color-red pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" placeholder="Enter value" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="eobhwcapacity" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="color-red pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="prehweobvolume" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="color-red pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="prehweobsstrokes" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="color-red pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="prehweobtime" />
                        </td>
                      </tr>
                      <tr>
                        <td class="border text-xxxs px-1 font-light text-sx color-red" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          G
                        </td>
                        <td class="border text-xxxs pr-1 font-light text-sx color-red" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <!-- <select
                            @change="preRecordedSecondSelectHandler"
                            v-model="preforcell"
                            class="ml-2 precellslct color-red text-xxxs text-white focus:outline-none focus-visible:outline-none"
                            :style="{
                              background:
                                darkDark  !== 'white'  
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              color: darkDark  !== 'white'  ?  '#fff' : '#222',
                            }"
                          >
                            <option value="select">Please Select</option>
                            <option
                              v-for="prerecData in prerecDatas"
                              :value="prerecData.text"
                              :key="prerecData.text"
                            >
                              {{ prerecData.text }}
                            </option>
                          </select> -->
                          DC2 KOP1 to EOB
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input
                            class="color-red pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" placeholder="Enter value" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="predc2toeoblength" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input
                            class="color-red pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" placeholder="Enter value" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="eobdc2tocapacity" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="color-red pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="predc2toeobvolume" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="color-red pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="predc2toeobsstrokes" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="color-red pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="predc2toeobtime" />
                        </td>
                      </tr>
                      <tr>
                        <td class="border text-xxxs px-1 font-light text-sx color-red" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          H
                        </td>
                        <td class="border text-xxxs pr-1 font-light text-sx color-red" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <!-- <select
                            @change="preRecordedSecondSelectHandler"
                            v-model="preforcell"
                            class="ml-2 precellslct color-red text-xxxs text-white focus:outline-none focus-visible:outline-none"
                            :style="{
                              background:
                                darkDark  !== 'white'  
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              color: darkDark  !== 'white'  ?  '#fff' : '#222',
                            }"
                          >
                            <option value="select">Please Select</option>
                            <option
                              v-for="prerecData in prerecDatas"
                              :value="prerecData.text"
                              :key="prerecData.text"
                            >
                              {{ prerecData.text }}
                            </option>
                          </select> -->
                          DC1 KOP1 to EOB
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input
                            class="color-red pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" placeholder="Enter value" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="predc1toeoblength" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input
                            class="color-red pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" placeholder="Enter value" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="eobdc1tocapacity" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="color-red pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="predc1toeobvolume" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="color-red pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="predc1toeobsstrokes" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="color-red pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="predc1toeobtime" />
                        </td>
                      </tr>
                      <tr>
                        <td class="border text-xxxs px-1 font-light text-sx color-orange" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          I
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx color-orange" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          DP-EOB TO BHA
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input
                            class="color-orange pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" placeholder="Enter value" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="prebhalength" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input
                            class="color-orange pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" placeholder="Enter value" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="dpcapacity" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="color-orange pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="prebhavolume" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="color-orange pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="prebhastrokes" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="color-orange pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="prebhatime" />
                        </td>
                      </tr>
                      <tr>
                        <td class="border text-xxxs px-1 font-light text-sx color-orange" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          J
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx color-orange" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          HWDP
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input
                            class="color-orange pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" placeholder="Enter value" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="prehwdplength" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input
                            class="color-orange pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" placeholder="Enter value" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="hwdpcapacity" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="color-orange pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="prehwdpvolume" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="color-orange pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="prehwdpstrokes" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="color-orange pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="prehwdptime" />
                        </td>
                      </tr>
                      <tr>
                        <td class="border text-xxxs px-1 font-light text-sx color-orange" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          K
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx color-orange" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          Drill Collar 2
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input
                            class="color-orange pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" placeholder="Enter value" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="predc2length" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input
                            class="color-orange pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" placeholder="Enter value" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="dc2capacity" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="color-orange pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="predc2volume" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="color-orange pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="predc2strokes" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="color-orange pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="predc2time" />
                        </td>
                      </tr>
                      <tr>
                        <td class="border text-xxxs px-1 font-light text-sx color-orange" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          L
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx color-orange" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          Drill Collar 1
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input
                            class="color-orange pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" placeholder="Enter value" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="prehwdc1length" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input
                            class="color-orange pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" placeholder="Enter value" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="dc1capacity" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="color-orange pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="predc1volume" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="color-orange pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="prehwdc1strokes" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="color-orange pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              transition: 'var(--transition)',
                            }" v-model="prehwdc1time" />
                        </td>
                      </tr>
                      <tr>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          M
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          Total
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          background:
                            darkDark !== 'white'
                              ? this.cardBackground
                              : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <input :disabled="shouldDisable"
                            class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              color: darkDark !== 'white' ? '#fff' : '#222',
                              borderColor: darkDark !== 'white' ? '#fff' : '#444',
                              transition: 'var(--transition)',
                            }" v-model="pretotatallength" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          background:
                            darkDark !== 'white'
                              ? this.cardBackground
                              : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                          transition: 'var(--transition)',
                        }">
                          <input :disabled="shouldDisable"
                            class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              color: darkDark !== 'white' ? '#fff' : '#222',
                              borderColor: darkDark !== 'white' ? '#fff' : '#444',
                              transition: 'var(--transition)',
                            }" v-model="pretotalcapacity" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          background:
                            darkDark !== 'white'
                              ? this.cardBackground
                              : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                          transition: 'var(--transition)',
                        }">
                          <input :disabled="shouldDisable"
                            class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              color: darkDark !== 'white' ? '#fff' : '#222',
                              borderColor: darkDark !== 'white' ? '#fff' : '#444',
                              transition: 'var(--transition)',
                            }" v-model="pretotalvolume" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          background:
                            darkDark !== 'white'
                              ? this.cardBackground
                              : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                          transition: 'var(--transition)',
                        }">
                          <input :disabled="shouldDisable"
                            class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              color: darkDark !== 'white' ? '#fff' : '#222',
                              borderColor: darkDark !== 'white' ? '#fff' : '#444',
                              transition: 'var(--transition)',
                            }" v-model="pretotalstrokes" />
                        </td>
                        <td class="border text-xxxs px-1 font-light text-sx" :style="{
                          background:
                            darkDark !== 'white'
                              ? this.cardBackground
                              : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                          transition: 'var(--transition)',
                        }">
                          <input
                            class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                            id="" placeholder="Enter value" :style="{
                              background:
                                darkDark !== 'white'
                                  ? this.cardBackground
                                  : '#FFFFF7',
                              color: darkDark !== 'white' ? '#fff' : '#222',
                              borderColor: darkDark !== 'white' ? '#fff' : '#444',
                              transition: 'var(--transition)',
                            }" v-model="pretotaltime" readonly />
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="pb-1">
              <h4 class="font-semibold pb-1 text-xxs">Casing Annular Volume</h4>
              <div class="pt-0.5 pb-1 pumpdiv">
                <table class="border-collapse border pump w-full rounded-sm"
                  :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                  <tr>
                    <th class="border text-xxxs px-1 font-medium" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }" style="min-width: 60px">
                      Component
                    </th>
                    <th class="border text-xxxs px-1 font-medium" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      Length (ft)
                    </th>
                    <th class="border text-xxxs px-1 font-medium" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      Capacity (bbl/ft)
                    </th>
                    <th class="border text-xxxs px-1 font-medium" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      Volume (bbls)
                    </th>
                    <th class="border text-xxxs px-1 font-medium" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      Pump Strokes (stks)
                    </th>
                    <th class="border text-xxxs px-1 font-medium" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      Time (min)
                    </th>
                  </tr>
                  <tr>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }" style="min-width: 60px">
                      DP X CSG
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" placeholder="Enter value" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="casingdplength" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" placeholder="Enter value" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="casingdpannularcapacity" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="cassed_hole_vol" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="casingdpstrokes" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="casingdptime" />
                    </td>
                  </tr>
                  <tr>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }" style="min-width: 60px">
                      HWDP X CSG
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" placeholder="Enter value" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="casinghwdplength" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" placeholder="Enter value" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="casinghwdpannularcapacity" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="casinghwdpannularvolume" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="casinghwdpannularstrokes" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="casinghwdpannulartime" />
                    </td>
                  </tr>
                  <tr>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }" style="min-width: 60px">
                      DC 2 X CSG
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" placeholder="Enter value" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="casingdc2length" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" placeholder="Enter value" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="casingdc2annularcapacity" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="dc2annularvoulume" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="dc2annularstrokes" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="dc2annulartime" />
                    </td>
                  </tr>
                  <tr>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }" style="min-width: 60px">
                      DC 1 X CSG
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" placeholder="Enter value" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="casingdc1length" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" placeholder="Enter value" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="casingdc1annularcapacity" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="dcannularvoulume" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="dcannularstrokes" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="dcannulartime" />
                    </td>
                  </tr>
                  <tr>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }" style="min-width: 60px">
                      Total
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="totalcasingdplength" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="totalcasingdpvolume" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="total_casingdpstrokes" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="total_casingdptime" />
                    </td>
                  </tr>
                </table>
              </div>
              <h4 class="font-semibold pb-1 text-xxs">Open Hole Volume</h4>
              <div class="pt-0.5 pb-1 pumpdiv">
                <table class="border-collapse border pump w-full rounded-sm"
                  :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                  <tr>
                    <th class="border text-xxxs px-1 font-medium" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }" style="min-width: 60px">
                      Component
                    </th>
                    <th class="border text-xxxs px-1 font-medium" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      Length (ft)
                    </th>
                    <th class="border text-xxxs px-1 font-medium" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      Capacity (bbl/ft)
                    </th>
                    <th class="border text-xxxs px-1 font-medium" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      Volume (bbls)
                    </th>
                    <th class="border text-xxxs px-1 font-medium" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      Pump Strokes (stks)
                    </th>
                    <th class="border text-xxxs px-1 font-medium" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      Time (min)
                    </th>
                  </tr>
                  <tr>
                    <td class="border text-xxxs px-1 uppercase font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }" style="min-width: 60px">
                      DP X OH
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" placeholder="Enter value" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="openholedplength" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" placeholder="Enter value" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="ohdpannularcapacity" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="dpannularvolume" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="dpannularstrokes" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="openholedptime" />
                    </td>
                  </tr>
                  <tr>
                    <td class="border text-xxxs px-1 uppercase font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }" style="min-width: 60px">
                      HWDP X OH
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" placeholder="Enter value" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="openholehwdplength" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" placeholder="Enter value" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="hwdpannularcapacity" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="hwdpannularvolume" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="hwdpannularstrokes" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="openholehwdptime" />
                    </td>
                  </tr>
                  <tr>
                    <td class="border text-xxxs px-1 uppercase font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }" style="min-width: 60px">
                      DC 2 X OH
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" placeholder="Enter value" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="openholedc2length" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" placeholder="Enter value" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="ohdc2annularcapacity" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="dc2annularvolume" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="ohdc2annularstrokes" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="openholedc2time" />
                    </td>
                  </tr>
                  <tr>
                    <td class="border text-xxxs px-1 uppercase font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }" style="min-width: 60px">
                      DC 1 X OH
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" placeholder="Enter value" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="openholedc1length" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" placeholder="Enter value" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="ohdc1annularcapacity" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="dc1annularvolume" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="dc1annularstrokes" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="openholedc1time" />
                    </td>
                  </tr>
                  <tr>
                    <td class="border text-xxxs px-1 uppercase font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }" style="min-width: 60px">
                      Total
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="totopenholelength" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="openholevolume" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="totopenholestrokes" />
                    </td>
                    <td class="border text-xxxs px-1 font-light text-sx" :style="{
                      borderColor: darkDark !== 'white' ? '#fff' : '#444',
                    }">
                      <input :disabled="shouldDisable"
                        class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                        id="" :style="{
                          background:
                            darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                          color: darkDark !== 'white' ? '#fff' : '#222',
                          transition: 'var(--transition)',
                        }" v-model="totalopenholetime" />
                    </td>
                  </tr>
                </table>
              </div>
              <div class="">
                <h4 class="font-semibold pb-1 text-xxs">
                  Total Annular Volume
                </h4>
                <div class="pt-0.5 pb-1 pumpdiv">
                  <table class="border-collapse border pump w-full rounded-sm"
                    :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <tr>
                      <th class="border text-xxxs px-1 font-medium" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        Total Annular Volume (bbls)
                      </th>
                      <th class="border text-xxxs px-1 font-medium" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        Pump Strokes (stks)
                      </th>
                      <th class="border text-xxxs px-1 font-medium" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        Time (min)
                      </th>
                    </tr>
                    <tr>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="annularvolumeV" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="lagstrokes" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="lagtime" />
                      </td>
                    </tr>
                  </table>
                </div>
                <h4 class="font-semibold pb-1 text-xxs">
                  Total Well System Volume
                </h4>
                <div class="pt-0.5 pb-1 pumpdiv">
                  <table class="border-collapse border pump w-full rounded-sm"
                    :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <tr>
                      <th class="border text-xxxs px-1 font-medium" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        Total Well System Volume (bbls)
                      </th>
                      <th class="border text-xxxs px-1 font-medium" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        Pump Strokes (stks)
                      </th>
                      <th class="border text-xxxs px-1 font-medium" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        Time (min)
                      </th>
                    </tr>
                    <tr>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="totalwellvolume" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="cyclestrokes" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input :disabled="shouldDisable"
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="totalwelltime" />
                      </td>
                    </tr>
                  </table>
                </div>
                <h4 class="font-semibold pb-1 text-xxs">
                  Active Surface Volume
                </h4>
                <div class="pt-0.5 pb-1 pumpdiv">
                  <table class="border-collapse border pump w-full rounded-sm"
                    :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <tr>
                      <th class="border text-xxxs px-1 font-medium" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        Active Surface Volume (bbls)
                      </th>
                      <th class="border text-xxxs px-1 font-medium" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        Pump Strokes (stks)
                      </th>
                    </tr>
                    <tr>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" placeholder="Enter value" v-model="active_surface_vol" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" :disabled="shouldDisable" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="active_surface_vol_pumpstrokes" />
                      </td>
                    </tr>
                  </table>
                </div>
                <h4 class="font-semibold text-xxs">
                  Total Active Fluid System
                </h4>
                <div class="pt-0.5 pb-1 pumpdiv">
                  <table class="border-collapse border pump w-full rounded-sm"
                    :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <tr>
                      <th class="border text-xxxs px-1 font-medium" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        Total Active Fluid System (bbls)
                      </th>
                      <th class="border text-xxxs px-1 font-medium" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        Pump Strokes (stks)
                      </th>
                    </tr>
                    <tr>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" :disabled="shouldDisable" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="tot_active_fluid_system" />
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" :disabled="shouldDisable" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#222',
                            transition: 'var(--transition)',
                          }" v-model="tot_active_fluid_system_pumpstrokes" />
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="px-2 pt-2">
          <div class="flex items-center justify-between">
            <button class="dispcalcbtn text-xxxxxs font-semibold uppercase relative" v-on:click="dispcalc">
              Click Here For Pump Displacement Calc
            </button>
            <div class="dispcalcpopupcl" v-show="!dispcalcpopup">
              <div class="dispop" :style="{
                height: this.widgetdisppopHeight + 'px',
                width: this.widgetdisppopWidth + 'px',
              }">
                <div class="p-5">
                  <h4 class="font-semibold pb-1 pl-1 text-xxs text_theme">
                    Pump Displacement Inputs
                  </h4>
                  <div class="mb-2 pl-4 pt-3 flex justify-between items-center" style="padding-right: 16px">
                    <label class="block text-xxxs font-medium text_theme">
                      Pump Number</label>
                    <select v-model="dispselected" @change="dispselectPumpno"
                      class="ml-2 pmpselc text-xxxs focus:outline-none focus-visible:outline-none" :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }">
                      <option value="select">Please Select</option>
                      <option v-for="option in options" :value="option.text" :key="option.text">
                        {{ option.text }}
                      </option>
                    </select>
                  </div>
                  <div class="mb-2 px-4 flex justify-between items-center">
                    <label class="block text-xxxs font-medium text_theme">
                      Liner Diameter
                    </label>
                    <input name="" v-model="diameter"
                      class="ml-2 shadow appearance-none border rounded-sm text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                      id="leak" type="text" placeholder="Enter value" :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }" />
                  </div>
                  <div class="mb-2 px-4 flex justify-between items-center">
                    <label class="block text-xxxs font-medium text_theme">
                      Liner/Stroke Length
                    </label>
                    <input name="" v-model="strokelength"
                      class="ml-2 shadow appearance-none border text-xxxs rounded-sm py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                      id="" type="text" placeholder="Enter value" :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }" />
                  </div>
                  <div class="mb-2 px-4 flex justify-between items-center">
                    <label class="block text-xxxs font-medium text_theme" for="password" @input="intonly">
                      Pump Efficiency (in %)
                    </label>
                    <input name="" v-model="efficiency"
                      class="ml-2 shadow appearance-none border text-xxxs rounded-sm py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                      id="" type="text" placeholder="Enter value" :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }" />
                  </div>
                  <div class="mb-2 pr-5 pt-5 flex justify-end items-center">
                    <button v-on:click="dispopopn" class="dispcalcnclbtn text-xxs mr-5 font-semibold px-5 py-2">
                      Cancel
                    </button>
                    <button v-on:click="dispsave" class="dispcalcbtn text-xxs mr-5 font-semibold px-5 py-2">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex items-center" :class="{ dispdata: isdispdata }">
              <h4 class="font-semibold pb-1 text-xxs inline-block pt-1 pr-4">
                Pump No
              </h4>
              <select v-model="selected" @change="selectPumpno"
                class="pmpselc text-xxxs text-white focus:outline-none focus-visible:outline-none" :style="{
                  background:
                    darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                  color: darkDark !== 'white' ? '#fff' : '#222',
                  borderColor: darkDark !== 'white' ? '#fff' : '#444',
                  transition: 'var(--transition)',
                }">
                <option value="select">Please Select</option>
                <option v-for="option in options" :key="option.text" :value="option.text">
                  {{ option.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="pumpdiv pt-2">
            <table class="border-collapse border pump w-full">
              <tr>
                <th class="border text-xxxs font-medium"
                  :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                  Pump No.
                </th>
                <th class="border text-xxxs font-medium"
                  :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                  Displacement (bbl/stk)
                </th>
              </tr>
              <tr>
                <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                  <input
                    class="pmpinp text-xxxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                    id="" :disabled="shouldDisable" :style="{
                      background:
                        darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      transition: 'var(--transition)',
                    }" value="Pump No 1" />
                </td>
                <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                  <input
                    class="pmpinp text-xxxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                    id="" :disabled="shouldDisable" :style="{
                      background:
                        darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      transition: 'var(--transition)',
                    }" v-model="pumpoutput1" />
                </td>
              </tr>
              <tr>
                <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                  <input
                    class="pmpinp text-xxxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                    id="" :disabled="shouldDisable" :style="{
                      background:
                        darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      transition: 'var(--transition)',
                    }" value="Pump No 2" />
                </td>
                <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                  <input
                    class="pmpinp text-xxxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                    id="" :disabled="shouldDisable" :style="{
                      background:
                        darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      transition: 'var(--transition)',
                    }" v-model="pumpoutput2" />
                </td>
              </tr>
              <tr>
                <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                  <input
                    class="pmpinp text-xxxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                    id="" :disabled="shouldDisable" :style="{
                      background:
                        darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      transition: 'var(--transition)',
                    }" value="Pump No 3" />
                </td>
                <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                  <input
                    class="pmpinp text-xxxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                    id="" :disabled="shouldDisable" :style="{
                      background:
                        darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      transition: 'var(--transition)',
                    }" v-model="pumpoutput3" />
                </td>
              </tr>
              <tr>
                <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                  <input
                    class="pmpinp text-xxxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                    id="" :disabled="shouldDisable" :style="{
                      background:
                        darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      transition: 'var(--transition)',
                    }" value="Pump No 4" />
                </td>
                <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                  <input
                    class="pmpinp text-xxxs w-full py-1 px-2 text-white focus:outline-none focus-visible:outline-none"
                    id="" :disabled="shouldDisable" :style="{
                      background:
                        darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      transition: 'var(--transition)',
                    }" v-model="pumpoutput4" />
                </td>
              </tr>
            </table>
          </div>
          <div class="py-2">
            <h4 class="font-semibold pb-1 text-xxs">Slow Pump Rate Data</h4>
            <div class="py-1 pumpdiv">
              <table class="border-collapse border pump w-full"
                :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                <tr>
                  <th></th>
                  <th rowspan="2" class="border text-xxxs font-medium"
                    :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    Slow Pump Rate Data (SPM)
                  </th>
                  <th colspan="4" class="border text-xxxs font-medium"
                    :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    Dynamic Pressure Loss (psi)
                  </th>
                </tr>
                <tr>
                  <td></td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    Pump No.1
                  </td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    Pump No.2
                  </td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    Pump No.3
                  </td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    Pump No.4
                  </td>
                </tr>
                <tr>
                  <td class="border text-xxxs px-2 relative chec"
                    :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <label class="lbl" :class="{
                      'slpmprate2': isslpmprate2 , 'slpmprate3' : isslpmprate3,
                    }"><input type="checkbox" v-model="checkbox_1" :checked="checkbox_1" class="chekbox 1a" @click="slpmprate1" ref="theCheckbox4" /><span
                        class="checkmark"></span></label>
                  </td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id="" placeholder="Enter value" :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }" v-on:change="dplval" v-model="slopmprate25" />
                  </td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id="" placeholder="Enter value" :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }" v-on:change="dplval" v-model="slopmp1rate25" />
                  </td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id="" placeholder="Enter value" :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }" v-on:change="dplval" v-model="slopmp2rate25" />
                  </td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id="" placeholder="Enter value" :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }" v-on:change="dplval" v-model="slopmp3rate25" />
                  </td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id="" placeholder="Enter value" :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }" v-on:change="dplval" v-model="slopmp4rate25" />
                  </td>
                </tr>
                <tr>
                  <td class="border text-xxxs px-2 relative chec"
                    :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <label class="lbl" :class="{
                      'slpmprate1': isAddClass , 'slpmprate3' : isslpmprate3,
                    }"><input type="checkbox" v-model="checkbox_2" :checked="checkbox_2" class="chekbox 2a" @click="slpmprate2" ref="theCheckbox5" /><span
                        class="checkmark"></span></label>
                  </td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id="" placeholder="Enter value" :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }" v-on:change="dplval" v-model="slopmprate35" />
                  </td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id="" placeholder="Enter value" :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }" v-on:change="dplval" v-model="slopmp1rate35" />
                  </td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id="" placeholder="Enter value" :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }" v-on:change="dplval" v-model="slopmp2rate35" />
                  </td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id="" placeholder="Enter value" :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }" v-on:change="dplval" v-model="slopmp3rate35" />
                  </td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id="" placeholder="Enter value" :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }" v-on:change="dplval" v-model="slopmp4rate35" />
                  </td>
                </tr>
                <tr>
                  <td class="border text-xxxs px-2 relative chec"
                    :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <label class="lbl" :class="{
                      'slpmprate1': isAddClass , 'slpmprate2' : isslpmprate2,
                    }"><input type="checkbox" v-model="checkbox_3" :checked="checkbox_3" class="chekbox 3a" @click="slpmprate3" ref="theCheckbox6" /><span
                        class="checkmark"></span></label>
                  </td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id="" placeholder="Enter value" :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }" v-on:change="dplval" v-model="slopmprate45" />
                  </td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id="" placeholder="Enter value" :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }" v-on:change="dplval" v-model="slopmp1rate45" />
                  </td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id="" placeholder="Enter value" :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }" v-on:change="dplval" v-model="slopmp2rate45" />
                  </td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id="" placeholder="Enter value" :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }" v-on:change="dplval" v-model="slopmp3rate45" />
                  </td>
                  <td class="border text-xxxs px-2" :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <input
                      class="text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none bg-black"
                      id="" placeholder="Enter value" :style="{
                        background:
                          darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                        color: darkDark !== 'white' ? '#fff' : '#222',
                        transition: 'var(--transition)',
                      }" v-on:change="dplval" v-model="slopmp4rate45" />
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="pb-2 px-1">
            <div class="flex justify-between">
              <div class="">
                <h4 class="font-semibold pb-3 text-xxs">Surface Line Volume</h4>
                <div class="mb-2 pl-2 flex justify-between items-center">
                  <label class="block text-xxxs font-medium" for="password">
                    Surface Line Volume (bbl)
                  </label>
                  <input name="" v-model="surface_line_volume"
                    class="ml-2 shadow appearance-none border rounded-sm text-xxxs py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100 w-14"
                    id="leak" type="text" placeholder="Enter value" :style="{
                      background:
                        darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      transition: 'var(--transition)',
                    }" />
                </div>
                <div class="mb-2 pl-2 flex justify-between items-center">
                  <label class="block text-xxxs font-medium" for="password">Surface Line Strokes (stk)
                  </label>
                  <input name="" v-model="surface_line_volume_strokes"
                    class="ml-2 shadow appearance-none border text-xxxs rounded-sm py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100 w-14"
                    id="" type="text" :disabled="shouldDisable" :style="{
                      background:
                        darkDark !== 'white' ? this.cardBack : '#FFFFF7',
                      color: darkDark !== 'white' ? '#fff' : '#222',
                      transition: 'var(--transition)',
                    }" />
                </div>
                <div class="mb-2 ml-3 pl-5 pt-5 mt-5">
                  <a href="#" class="execute-btn" v-on:click="executeHorizontal">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    execute
                  </a>
                  <a href="#"
                    class="minimizer flex items-center text-white text-xs font-bold leading-relaxed text_xs whitespace-nowrap uppercase dropdown z-40 menu_icons_bar"
                    v-if="bottomMinimizer" @click="maximize()">
                    <div class="tooltip">Maximize</div>
                    <i class="fa fa-plus" aria-hidden="true"></i>
                    <a class="icon_minimizer" href="#">
                      <p><i class="fa fa-eye" aria-hidden="true"></i>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M0 4H4V0H0V4ZM6 16H10V12H6V16ZM0 16H4V12H0V16ZM0 10H4V6H0V10ZM6 10H10V6H6V10ZM12 0V4H16V0H12ZM6 4H10V0H6V4ZM12 10H16V6H12V10ZM12 16H16V12H12V16Z" fill="white">

                        </path>
                      </svg>
                    </p>
                  </a>
                  </a>
                </div>
              </div>
              <div class="pl-2">
                <h4 class="font-semibold text-xxs">
                  Pumping Strokes Information
                </h4>
                <div class="pt-0.5 pb-3 pumpdiv">
                  <table class="border-collapse border pump w-full rounded-sm"
                    :style="{ borderColor: darkDark !== 'white' ? '#fff' : '#444' }">
                    <tr>
                      <td class="border text-xxxs px-1 font-light text-sx color-green" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }" style="min-width: 90px">
                        Surface to KOP 1
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input
                          class="color-green pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" placeholder="Enter value" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            transition: 'var(--transition)',
                          }" v-model="surfacetokop" readonly />
                      </td>
                    </tr>
                    <tr>
                      <td class="border text-xxxs px-1 font-light text-sx color-red" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }" style="min-width: 90px">
                        KOP1 to EOB
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input
                          class="color-red pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" placeholder="Enter value" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            transition: 'var(--transition)',
                          }" v-model="koptoeob" readonly />
                      </td>
                    </tr>
                    <tr>
                      <td class="border text-xxxs px-1 font-light text-sx color-red" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }" style="min-width: 90px">
                        Surface to EOB
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input
                          class="color-red pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" placeholder="Enter value" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            transition: 'var(--transition)',
                          }" v-model="surfacetoeob" readonly />
                      </td>
                    </tr>
                    <tr>
                      <td class="border text-xxxs px-1 font-light text-sx color-purple" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }" style="min-width: 90px">
                        Surface to Top of BHA
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input
                          class="color-purple pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" placeholder="Enter value" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            transition: 'var(--transition)',
                          }" v-model="surfacetobha" readonly />
                      </td>
                    </tr>
                    <tr>
                      <td class="border text-xxxs px-1 font-light text-sx color-blue" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }" style="min-width: 90px">
                        Surface to Top of DC
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input
                          class="color-blue pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" placeholder="Enter value" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            transition: 'var(--transition)',
                          }" v-model="surfacetodc" readonly />
                      </td>
                    </tr>
                    <tr>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }" style="min-width: 90px">
                        Surface to Bit
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input
                          class="pmpinp text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" placeholder="Enter value" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            color: darkDark !== 'white' ? '#fff' : '#444',
                            transition: 'var(--transition)',
                          }" v-model="surfacetobit" readonly />
                      </td>
                    </tr>
                    <tr>
                      <td class="border text-xxxs px-1 font-light text-sx color-pink" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }" style="min-width: 90px">
                        Lag Strokes
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input
                          class="pmpinp color-pink text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" placeholder="Enter value" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            transition: 'var(--transition)',
                          }" v-model="lagstrokes" readonly />
                      </td>
                    </tr>
                    <tr>
                      <td class="border text-xxxs px-1 font-light text-sx color-drkorange" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }" style="min-width: 90px">
                        Cycle Strokes
                      </td>
                      <td class="border text-xxxs px-1 font-light text-sx" :style="{
                        borderColor: darkDark !== 'white' ? '#fff' : '#444',
                      }">
                        <input
                          class="pmpinp color-drkorange text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                          id="" placeholder="Enter value" :style="{
                            background:
                              darkDark !== 'white'
                                ? this.cardBackground
                                : '#FFFFF7',
                            transition: 'var(--transition)',
                          }" v-model="cyclestrokes" readonly />
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
            <!-- !isHidd vertical -->
            <div class="conclusion horizantalKillsheet px-2">
              <div class="pt-3 px-1 hpoup" v-show="!isHidd" :class="{ minimize: isminimize }">
                <div class="flex justify-end">
                  <a href="#"
                    class="flex items-center text-white text-xs font-bold leading-relaxed text_xs whitespace-nowrap uppercase dropdown z-40 menu_icons_bar px-4"
                    @click="minimize" v-if="!bottomMinimizer">
                    <div class="tooltip colorOfKillTool">Minimize</div>
                    <i class="fas fa-window-minimize" style="color:var(--textColor)"></i>
                  </a>
                  <button  v-on:click="isHidd = !isHidd" class="float-right pr-5 menu_icons_bar">
                    <div class="tooltip colorOfKillTool">Close</div>
                    <svg width="15" height="15">
                      <path d="M 5,5 l 10,10 M 15,5 l -10,10" stroke="red" stroke-width="2" />
                    </svg>
                  </button>
                </div>
                <div class="flex justify-between p-3">
                  <div class="p-3 w-full">
                    <div class="flex">
                      <div class="w-1/2 firtPart-in-result border">
                        <div class="flex justify-between">
                          <div class="flex text-xxs justify-between w-full  border-b content-center">
                            <h6 class="font-medium border-r w-11/12 p-1 pt-px">
                              Kill Fluid Density (ppg)
                            </h6>
                            <span class="text-xxs px-3 font-semibold w-1/2 flex justify-between items-center">{{
                              kfd
                            }}</span>
                          </div>
                        </div>
                        <div class="flex justify-between">
                          <div class="flex text-xxs justify-between w-full border-b content-center">
                            <h6 class="font-medium pt-px border-r p-1 w-11/12">
                              Kill Fluid Gradient (psi/ft)
                            </h6>
                            <span class="text-xxs px-3 font-semibold w-1/2 flex justify-between items-center ">{{
                              kfg
                            }}</span>
                          </div>
                        </div>
                        <div class="flex justify-between ">
                          <div class="flex text-xxs justify-between w-full border-b content-center">
                            <h6 class="font-medium pt-px border-r p-1 w-11/12">
                              Initial Circulating Pressure (psi)
                            </h6>
                            <span class="text-xxs px-3 font-semibold w-1/2 flex justify-between items-center">{{
                              ICP
                            }}</span>
                          </div>
                        </div>
                        <div class="flex justify-between">
                          <div class="flex text-xxs justify-between w-full border-b content-center">
                            <h6 class="font-medium pt-px border-r p-1 w-11/12">
                              Final Circulating Pressure (psi)
                            </h6>
                            <span class="text-xxs px-3 font-semibold w-1/2 flex justify-between items-center">{{
                              FCP
                            }}</span>
                          </div>
                        </div>
                        <div class="flex justify-between">
                          <div class="flex text-xxs justify-between w-full border-b content-center">
                            <h6 class="font-medium pt-px border-r p-1 w-11/12">
                              KOP1 Circulating Pressure (KCP1) (psi)
                            </h6>
                            <span class="text-xxs px-3 font-semibold w-1/2 flex justify-between items-center">{{
                              kcp1
                            }}</span>
                          </div>
                        </div>
                        <div class="flex justify-between">
                          <div class="flex text-xxs justify-between w-full border-b content-center">
                            <h6 class="font-medium pt-px border-r p-1 w-11/12">
                              EOB Circulating Pressure (KCP2) (psi)
                            </h6>
                            <span class="text-xxs px-3 font-semibold w-1/2 flex justify-between items-center">{{
                              kcp2
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="w-1/2" style="padding-left: 50px">
                        <svg height="210" width="133" style="padding-top: 10px">
                          <g>
                            <path fill="none" :stroke="'var(--textColor)'" stroke-width="1.5px" d="M 10 35 l 0 80"></path>
                          </g>
                          <g>
                            <path fill="none" :stroke="'var(--textColor)'" stroke-width="1.5px" d="M 10 115 l 94 74">
                            </path>
                          </g>
                          <g>
                            <path fill="#aaa" :stroke="'var(--textColor)'" stroke-width="1.5px"
                              d="M 18 25 l 0 80 l 7 0 l 0 -80 l -7 0"></path>
                          </g>
                          <g>
                            <path fill="#aaa" :stroke="'var(--textColor)'" stroke-width="1.5px"
                              d="M 188 47 l 0 20 l 12 0 l 0 -20 l -12 0 " style="
                                transform: rotate(42deg);
                                border-radius: 20px;
                              " stroke-linejoin="round"></path>
                          </g>
                          <g>
                            <path fill="#aaa" :stroke="'var(--textColor)'" stroke-width="1.5px"
                              d="M 175 50 l 0 14 l 12 0 l 0 -14 l -12 0 " style="
                                transform: rotate(42deg);
                                border-radius: 20px;
                              " ry="35" stroke-linejoin="round"></path>
                          </g>
                          <g>
                            <path fill="#aaa" :stroke="'var(--textColor)'" stroke-width="1.5px"
                              d="M -73 80 l 00 90 l 7 0 l 0 -90 l -7 0" style="transform: rotate(-52deg)"></path>
                          </g>
                          <g>
                            <path fill="none" :stroke="'var(--textColor)'" stroke-width="1.5px"
                              d="M 15 13 a 7, 7 0 1, 0 14, 0 a 7, 7 0 1, 0 -14, 0 "></path>
                          </g>
                          <g>
                            <path fill="none" :stroke="'var(--textColor)'" stroke-width="1.5px" d="M 22 19 l 0 6"></path>
                          </g>
                          <g>
                            <path fill="none" :stroke="'var(--textColor)'" stroke-width="1.5px" d="M 35 35 l 0 15"></path>
                          </g>
                          <g>
                            <path fill="none" :stroke="'var(--textColor)'" stroke-width="1.5px" d="M 35 57 l 0 40"></path>
                          </g>
                          <g>
                            <path fill="none" :stroke="'var(--textColor)'" stroke-width="1.5px" d="M 35 50 l 15 0 ">
                            </path>
                          </g>
                          <g>
                            <path fill="none" :stroke="'var(--textColor)'" stroke-width="1.5px" d="M 35 57 l 22 0 ">
                            </path>
                          </g>
                          <g>
                            <path fill="none" :stroke="'var(--textColor)'" stroke-width="1.5px" d="M 50 30 l 8 0"></path>
                          </g>
                          <g>
                            <path fill="none" :stroke="'var(--textColor)'" stroke-width="1.5px" d="M 35 97 l 89 70">
                            </path>
                          </g>
                          <g>
                            <path fill="none" :stroke="'var(--textColor)'" stroke-width="1.5px" d="M 50 30 l 0 20 ">
                            </path>
                          </g>
                          <g>
                            <path fill="none" :stroke="'var(--textColor)'" stroke-width="1.5px" d="M 58 30 l 0 28 ">
                            </path>
                          </g>
                          <g>
                            <path fill="none" :stroke="'var(--textColor)'" stroke-width="1.5px" d="M 125 167 l -21 23">
                            </path>
                          </g>
                          <g>
                            <path fill="none" :stroke="'var(--textColor)'" stroke-width="1.5px"
                              d="M 47 23 a 7, 7 0 1, 0 14, 0 a 7, 7 0 1, 0 -14, 0 "></path>
                          </g>
                          <g>
                            <path fill="#aaa" stroke="" stroke-width="" d="M 19 100 l 0 6 l 6 0 l 0 -6 l -6 0"></path>
                          </g>
                        </svg>
                      </div>
                    </div>
                    <div class="pb-2">
                      <div class="flex justify-between py-2">
                        <div class="flex justify-between">
                          <div class="flex text-xxs justify-between content-center">
                            <h6 class="font-medium pt-px">K @Bit</h6>
                            <p class="text-xxs px-3 font-semibold">
                              {{ kval_bit }} (psi/100 strokes)
                            </p>
                          </div>
                        </div>
                        <div class="flex justify-between">
                          <div class="flex text-xxs justify-between content-center">
                            <h6 class="font-medium pt-px">K @eob_md</h6>
                            <p class="text-xxs px-3 font-semibold">
                              {{ kval_eob }} (psi/100 strokes)
                            </p>
                          </div>
                        </div>
                        <div class="flex justify-between">
                          <div class="flex text-xxs justify-between content-center">
                            <h6 class="font-medium pt-px">K @kop1</h6>
                            <p class="text-xxs px-3 font-semibold">
                              {{ kval_kop1 }} (psi/100 strokes)
                            </p>
                          </div>
                        </div>
                      </div>
                      <h4 class="font-semibold pb-1 text-xxs">Final Volume1</h4>
                      <div class="pt-0.5 pumpdiv">
                        <table class="border-collapse border pump w-full rounded-sm" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <tr>
                            <th class="border text-xxxs px-1 font-medium" :style="{
                              borderColor: darkDark !== 'white' ? '#fff' : '#444',
                            }">
                              Volume
                            </th>
                            <th class="border text-xxxs px-1 font-medium" :style="{
                              borderColor: darkDark !== 'white' ? '#fff' : '#444',
                            }">
                              Cumm. Volume (bbls)
                            </th>
                            <th class="border text-xxxs px-1 font-medium" :style="{
                              borderColor: darkDark !== 'white' ? '#fff' : '#444',
                            }">
                              Pump Strokes (stks)
                            </th>
                            <th class="border text-xxxs px-1 font-medium" :style="{
                              borderColor: darkDark !== 'white' ? '#fff' : '#444',
                            }">
                              Time (mins)
                            </th>
                          </tr>
                          <tr>
                            <td class="border text-xxxs px-1 font-light text-sx" :style="{
                              borderColor: darkDark !== 'white' ? '#fff' : '#444',
                            }">
                              Drill String
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx" :style="{
                              borderColor: darkDark !== 'white' ? '#fff' : '#444',
                            }">
                              <input
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                                id="" placeholder="Enter value" v-model="pretotalvolume" readonly />
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx" :style="{
                              borderColor: darkDark !== 'white' ? '#fff' : '#444',
                            }">
                              <input
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                                id="" placeholder="Enter value" v-model="pretotalstrokes" readonly />
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx" :style="{
                              borderColor: darkDark !== 'white' ? '#fff' : '#444',
                            }">
                              <input
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                                id="" placeholder="Enter value" v-model="pretotaltime" readonly />
                            </td>
                          </tr>
                          <tr>
                            <td class="border text-xxxs px-1 font-light text-sx" :style="{
                              borderColor: darkDark !== 'white' ? '#fff' : '#444',
                            }">
                              Open Hole
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx" :style="{
                              borderColor: darkDark !== 'white' ? '#fff' : '#444',
                            }">
                              <input
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                                id="" placeholder="Enter value" v-model="openholevolume" readonly />
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx" :style="{
                              borderColor: darkDark !== 'white' ? '#fff' : '#444',
                            }">
                              <input
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                                id="" placeholder="Enter value" v-model="totopenholestrokes" readonly />
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx" :style="{
                              borderColor: darkDark !== 'white' ? '#fff' : '#444',
                            }">
                              <input
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                                id="" placeholder="Enter value" v-model="totalopenholetime" readonly />
                            </td>
                          </tr>
                          <tr>
                            <td class="border text-xxxs px-1 font-light text-sx" :style="{
                              borderColor: darkDark !== 'white' ? '#fff' : '#444',
                            }">
                              Cased Hole Annular
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx" :style="{
                              borderColor: darkDark !== 'white' ? '#fff' : '#444',
                            }">
                              <input
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                                id="" placeholder="Enter value" v-model="totalcasingdpvolume" readonly />
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx" :style="{
                              borderColor: darkDark !== 'white' ? '#fff' : '#444',
                            }">
                              <input
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                                id="" placeholder="Enter value" v-model="total_casingdpstrokes" readonly />
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx" :style="{
                              borderColor: darkDark !== 'white' ? '#fff' : '#444',
                            }">
                              <input
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                                id="" placeholder="Enter value" v-model="total_casingdptime" readonly />
                            </td>
                          </tr>
                          <tr>
                            <td class="border text-xxxs px-1 font-light text-sx" :style="{
                              borderColor: darkDark !== 'white' ? '#fff' : '#444',
                            }">
                              Total Annular
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx" :style="{
                              borderColor: darkDark !== 'white' ? '#fff' : '#444',
                            }">
                              <input
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                                id="" placeholder="Enter value" v-model="annularvolumeV" readonly />
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx" :style="{
                              borderColor: darkDark !== 'white' ? '#fff' : '#444',
                            }">
                              <input
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                                id="" placeholder="Enter value" v-model="lagstrokes" readonly />
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx" :style="{
                              borderColor: darkDark !== 'white' ? '#fff' : '#444',
                            }">
                              <input
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                                id="" placeholder="Enter value" v-model="lagtime" readonly />
                            </td>
                          </tr>
                          <tr>
                            <td class="border text-xxxs px-1 font-light text-sx" :style="{
                              borderColor: darkDark !== 'white' ? '#fff' : '#444',
                            }">
                              Total Well
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx" :style="{
                              borderColor: darkDark !== 'white' ? '#fff' : '#444',
                            }">
                              <input
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                                id="" placeholder="Enter value" v-model="totalwellvolume" readonly />
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx" :style="{
                              borderColor: darkDark !== 'white' ? '#fff' : '#444',
                            }">
                              <input
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                                id="" placeholder="Enter value" v-model="cyclestrokes" readonly />
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx" :style="{
                              borderColor: darkDark !== 'white' ? '#fff' : '#444',
                            }">
                              <input
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                                id="" placeholder="Enter value" v-model="totalwelltime" readonly />
                            </td>
                          </tr>
                          <tr>
                            <td class="border text-xxxs px-1 font-light text-sx" :style="{
                              borderColor: darkDark !== 'white' ? '#fff' : '#444',
                            }">
                              Active Surface Volume
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx" :style="{
                              borderColor: darkDark !== 'white' ? '#fff' : '#444',
                            }">
                              <input
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                                id="" placeholder="Enter value" v-model="active_surface_vol" readonly />
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx" :style="{
                              borderColor: darkDark !== 'white' ? '#fff' : '#444',
                            }">
                              <input
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                                id="" placeholder="Enter value" v-model="active_surface_vol_pumpstrokes" readonly />
                            </td>
                          </tr>
                          <tr>
                            <td class="border text-xxxs px-1 font-light text-sx" :style="{
                              borderColor: darkDark !== 'white' ? '#fff' : '#444',
                            }">
                              Total Active Fluid System
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx" :style="{
                              borderColor: darkDark !== 'white' ? '#fff' : '#444',
                            }">
                              <input
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                                id="" placeholder="Enter value" v-model="tot_active_fluid_system" readonly />
                            </td>
                            <td class="border text-xxxs px-1 font-light text-sx" :style="{
                              borderColor: darkDark !== 'white' ? '#fff' : '#444',
                            }">
                              <input
                                class="conclusion_input text-xxxs w-full py-1 px-1 text-white focus:outline-none focus-visible:outline-none"
                                id="" placeholder="Enter value" v-model="tot_active_fluid_system_pumpstrokes" readonly />
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="p-3">
                    <div class="pb-4">
                      <h4 class="font-semibold pb-1 text-xxs">Strokes</h4>
                      <div class="pt-0.5 pumpdiv">
                        <table class="border-collapse border pump w-full rounded-sm scroll" :style="{
                          borderColor: darkDark !== 'white' ? '#fff' : '#444',
                        }">
                          <thead>
                            <tr>
                              <th class="conclusion_input border text-xxxs px-1 font-medium">
                                Strokes (stks)
                              </th>
                              <th class="conclusion_input border text-xxxs px-1 font-medium">
                                Pressure (psi)
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(val, id, index) in Pressure" :key="index">
                              <td class="border text-xxxs px-1 uppercase font-light text-sx" :style="{
                                borderColor:
                                  darkDark !== 'white' ? '#fff' : '#444',
                              }">
                                {{ Strokes[id] }}
                              </td>
                              <td class="border text-xxxs px-1 font-light text-sx" :style="{
                                borderColor:
                                  darkDark !== 'white' ? '#fff' : '#444',
                              }">
                                {{ val }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="cid mt-5" >
                    <div class="" :id="'HoriKillsheet' + this.widgetId"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import axios from "axios";
import {
  select,
  scaleLinear,
  axisBottom,
  axisLeft,
  brush,
  symbol,
  symbolCircle,
  bisector,
  pointer,
  symbolDiamond,
  line,
  max,
} from "d3";
import { min } from "date-fns/esm";
var svg = "";
let id = null;
import URL from "../../../config";

export default {
  name: "killsheet-widget",
  components: {},
  props: {
    cardBackground: {
      type: String,
      default: "#3f3f3f",
    },
    widgetHeight: {
      type: Number,
      default: 200,
    },
    widgetWidth: {
      type: Number,
      default: 350,
    },
    widgetpopHeight: {
      type: Number,
      default: "550",
    },
    widgetpopWidth: {
      type: Number,
      default: "1080",
    },
    widgetdisppopHeight: {
      type: Number,
      default: "250",
    },
    widgetdisppopWidth: {
      type: Number,
      default: "350",
    },
    displayId: String,
    id: String,
    gridColor: {
      type: String,
      default: "",
    },
    gridBackground: {
      type: String,
      default: "",
    },
    xAxisLabel: {
      type: String,
      default: "white",
    },
    yAxisLabel: {
      type: String,
      default: "white",
    },
    xAxisLabelSize: {
      Number,
    },
    yAxisLabelSize: {
      type: Number,
    },
    backgroundColor: {
      type: String,
      default: "white",
    },
    widgetId: String,
    isSwitched: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checkbox_1:false,
      checkbox_2:false,
      checkbox_3:false,
      whichCheckboxClicked:'',
      eobhwcapacity: 0,
      hwcapacity: 0,
      shouldDisable: true,
      wId: null,
      tempWidgetId: null,
      rows: [],
      isHidden: true,
      dispcalcpopup: true,
      incldatanull: true,
      isHidd: true,
      checked: false,
      data: [],
      completeData: [],
      wellId: null,
      wellboreId: null,
      logId: null,
      wellboreState: null,
      logType: null,
      status: -1,
      isDis: false,
      date: "",
      list: [],
      options: [
        { text: "Pump No 1" },
        { text: "Pump No 2" },
        { text: "Pump No 3" },
        { text: "Pump No 4" },
      ],
      pumno: [{ text: "1" }, { text: "2" }, { text: "3" }],
      preDatas: [
        { text: "HWDP to KOP1" },
        { text: "DC2 to KOP1" },
        { text: "DC1 to KOP1" },
      ],
      prerecDatas: [
        { text: "HWDP KOP1 to EOB" },
        { text: "DC2 to KOP1 to EOB" },
        { text: "DC1 to KOP1 to EOB" },
      ],
      vuserinp: {
        vuserdata: {
          vlotp: "",
          rho_test: "",
          rho: "",
          vgradient: "",
          vbittvd: "",
          vholetvd: ""
        },
        kickdata: {
          sdipp: "",
          sicp: "",
          pit_gain: "",
        },
      },
      dplength: "",
      dpcapacity: "",

      hwdplength: "",
      hwdpcapacity: "",

      dc1length: "",
      dc1capacity: "",

      dc2length: "",
      dc2capacity: "",

      casingdplength: "",
      casingdpannularcapacity: "",
      casinghwdplength: "",
      casinghwdpannularcapacity: "",
      casingdc1length: "",
      casingdc2length: "",
      casingdc1annularcapacity: "",
      casingdc2annularcapacity: "",

      openholedplength: "",
      ohdpannularcapacity: "",
      ohdc1annularcapacity: "",

      openholehwdplength: "",
      hwdpannularcapacity: "",
      ohdc2annularcapacity: "",
      openholedc1length: "",
      dc1annularcapacity: "",
      openholedc2length: "",
      dc2annularcapacity: "",
      annularvolume: "",
      cycle_volume: "",
      active_surface_vol: "",
      string_strokes: "",
      totalcapacity: "",

      time: "",
      selectedArray: "",
      slopmprate25: "",
      slopmp1rate25: "",
      slopmp2rate25: "",
      slopmp3rate25: "",
      slopmp4rate25: "",
      slopmprate35: "",
      slopmp1rate35: "",
      slopmp2rate35: "",
      slopmp3rate35: "",
      slopmp4rate35: "",
      slopmprate45: "",
      slopmp1rate45: "",
      slopmp2rate45: "",
      slopmp3rate45: "",
      slopmp4rate45: "",
      slctpmprate: "t",
      slctpmp1: "",
      slctpmp2: "",
      slctpmp3: "",
      slctpmp4: "",
      incl_data: "",
      conv_factor: 0.052,
      diameter: "",
      strokelength: "",
      efficiency: "",
      Kval: "",
      FCP: "",
      ICP: "",
      kfg: "",
      kfd: "",
      dpl: "",
      bottomMinimizer: false,
      idia: "",
      md: "",
      tvd: "",
      vholetvd: "",
      dmea: "",
      dbtm: "",
      pitgain: "",
      pumpoutput1: "",
      pumpoutput2: "",
      pumpoutput3: "",
      pumpoutput4: "",
      spm1: "",
      spm2: "",
      spm3: "",
      spp: "",
      time: "",
      tot_stk: "",
      tot_spm: "",
      surface_line_volume: "",

      slop_test: "",
      Pressure: "",
      Strokes: "",
      result: [],
      minX: 0,
      maxX: 45000,
      minY: 0,
      maxY: 2000,
      width: this.widgetpopWidth,
      height: this.widgetpopHeight,
      rtColumn: [],
      rtData: [],
      isAddClass: false,
      isslpmprate2: false,
      isslpmprate3: false,
      isdispdata: true,
      isminimize: false,
      selected: "select",
      dispselected: "select",
      preseccell: "select",
      preforcell: "select",
      allSelected: false,
      userIds: [],
      select: [],
      agreement: false,
      displacement: "d",
      kop1md: "",
      kop1tvd: "",
      eobmd: "",
      eobtvd: "",
      predplength: "",
      prehwlength: "",
      predc2tokoplength: "",
      predc1tokoplength: "",
      eobdc1tocapacity: "",
      eobdc2tocapacity: "",
      dc1tokopcapacity: "",
      dc2tokopcapacity: "",
      preeoblength: "",
      prehweoblength: "",
      predc2toeoblength: "",
      predc1toeoblength: "",
      prebhalength: "",
      prehwdplength: "",
      predc2length: "",
      prehwdc1length: "",
      tot_active_fluid_system_pumpstrokes: "",
      surfacetoeob: "",
      surfacetobha: "",
      surfacetodc: "",
      kval_bit: "",
      kval_eob: "",
      kval_kop1: "",
      kcp1: "",
      kcp2: "",
      inclination: "",
      capture_screen_confirm_box: "",
      isSupportScreenshotShowing: false,
      capturedImage: "",
      diameter1: "",
      strokelength1: "",
      efficiency1: "",
      diameter2: "",
      strokelength2: "",
      efficiency2: "",
      diameter3: "",
      strokelength3: "",
      efficiency3: "",
      diameter4: "",
      strokelength4: "",
      efficiency4: "",
    };
  },
  beforeMount() {
    let id = "id" + Math.random().toString(16).slice(2);
    this.tempWidgetId = id;
  },
  mounted() {
    this.tempWidgetId = this.backgroundId || this.tempWidgetId;
  },
  computed: {
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
    axiosParams() {
      // const params = new URLSearchParams();
      // params.append("well_id", this.wellId);
      // // params.append('well_id', "609b3313-1db6-468c-85b9-c62a4a29df8c");
      // params.append("well_bore_id", this.wellboreId);
      // // params.append('well_bore_id', "609b3313-1db6-468c-85b9-c62a4a29df8c");
      // params.append("customer", this.customer);
      // // params.append('customer', "apollodart");
      const params = {
        well_id: this.wellId,
        wellbore_name: this.wellboreId,
        customer_name: this.customer,
      };
      return params;
    },
    // PRE RECORDED VOLVUME DATA (DRILL STRING VOLUME) CALCULATION
    dpvolume() {
      return !isNaN(this.dpcapacity) && !isNaN(this.dplength)
        ? Math.round(this.dpcapacity * this.dplength * 1e4) / 1e4
        : 0;
    },
    dpstrokes() {
      return Math.round(!isNaN(this.dpvolume) && !isNaN(this.displacement)
        ? Math.round((this.dpvolume / this.displacement) * 1e4) / 1e4
        : 0);
    },
    dptime() {
      return !isNaN(this.dpstrokes) && !isNaN(this.slctpmprate)
        ? Math.round((this.dpstrokes / this.slctpmprate) * 1e4) / 1e4
        : 0;
    },
    hwdpvolume() {
      return !isNaN(this.hwdpcapacity) && !isNaN(this.hwdplength)
        ? Math.round(this.hwdpcapacity * this.hwdplength * 1e4) / 1e4
        : 0;
    },
    hwdpstrokes() {
      return Math.round(!isNaN(this.hwdpvolume) && !isNaN(this.displacement)
        ? Math.round((this.hwdpvolume / this.displacement) * 1e4) / 1e4
        : 0);
    },
    hwdptime() {
      return !isNaN(this.hwdpstrokes) && !isNaN(this.slctpmprate)
        ? Math.round((this.hwdpstrokes / this.slctpmprate) * 1e4) / 1e4
        : 0;
    },
    dc2volume() {
      return !isNaN(this.dc2capacity) && !isNaN(this.dc2length)
        ? Math.round(this.dc2capacity * this.dc2length * 1e4) / 1e4
        : 0;
    },
    dc2strokes() {
      return Math.round(!isNaN(this.dc2volume) && !isNaN(this.displacement)
        ? Math.round((this.dc2volume / this.displacement) * 1e4) / 1e4
        : 0);
    },
    dc2time() {
      return !isNaN(this.dc2strokes) && !isNaN(this.slctpmprate)
        ? Math.round((this.dc2strokes / this.slctpmprate) * 1e4) / 1e4
        : 0;
    },
    dc1volume() {
      return !isNaN(this.dc1capacity) && !isNaN(this.dc1length)
        ? Math.round(this.dc1capacity * this.dc1length * 1e4) / 1e4
        : 0;
    },
    dc1strokes() {
      return Math.round(!isNaN(this.dc1volume) && !isNaN(this.displacement)
        ? Math.round((this.dc1volume / this.displacement) * 1e4) / 1e4
        : 0);
    },
    dc1time() {
      return !isNaN(this.dc1strokes) && !isNaN(this.slctpmprate)
        ? Math.round((this.dc1strokes / this.slctpmprate) * 1e4) / 1e4
        : 0;
    },
    totallength() {
      return (
        Math.round((Number(this.dplength) +
          Number(this.hwdplength) +
          Number(this.dc1length) +
          Number(this.dc2length)) * 1e4) / 1e4 || 0
      );
    },
    drillstringvolumeV() {
      return (
        Math.round((Number(this.dc1volume) +
          Number(this.dc2volume) +
          Number(this.hwdpvolume) +
          Number(this.dpvolume)) * 1e4) / 1e4
      );
    },
    totalstrokes() {
      return (Math.round(
        Number(this.dpstrokes) +
        Number(this.hwdpstrokes) +
        Number(this.dc1strokes) +
        Number(this.dc2strokes) || 0
      ));
    },
    totaltime() {
      return (
        Math.round(
          (Number(this.dptime) +
            Number(this.hwdptime) +
            Number(this.dc1time) +
            Number(this.dc2time)) *
          1e4
        ) / 1e4 || 0
      );
    },
    // ANNULAR CASING VOLUME CALCULATIONS
    cassed_hole_vol() {
      return (
        Math.round(
          Number(this.casingdpannularcapacity) *
          Number(this.casingdplength) *
          1e4
        ) / 1e4 || 0
      );
    },
    casingdpstrokes() {
      return Math.round(!isNaN(this.cassed_hole_vol) && !isNaN(this.displacement)
        ? Math.round((this.cassed_hole_vol / this.displacement) * 1e4) / 1e4
        : 0);
    },
    casingdptime() {
      return !isNaN(this.casingdpstrokes) && !isNaN(this.slctpmprate)
        ? Math.round((this.casingdpstrokes / this.slctpmprate) * 1e4) / 1e4
        : 0;
    },
    casinghwdpannularvolume() {
      return Number(this.casinghwdplength) &&
        Number(this.casinghwdpannularcapacity)
        ? Math.round(
          Number(this.casinghwdplength) *
          Number(this.casinghwdpannularcapacity) *
          1e4
        ) / 1e4
        : 0;
    },
    casinghwdpannularstrokes() {
      return Math.round(!isNaN(this.casinghwdpannularvolume) && !isNaN(this.displacement)
        ? Math.round((this.casinghwdpannularvolume / this.displacement) * 1e4) /
        1e4
        : 0);
    },
    casinghwdpannulartime() {
      return !isNaN(this.casinghwdpannularstrokes) && !isNaN(this.slctpmprate)
        ? Math.round((this.casinghwdpannularstrokes / this.slctpmprate) * 1e4) /
        1e4
        : 0;
    },
    dc2annularvoulume() {
      return (
        Math.round(
          Number(this.casingdc2annularcapacity) *
          Number(this.casingdc2length) *
          1e4
        ) / 1e4 || 0
      );
    },
    dc2annularstrokes() {
      return Math.round(!isNaN(this.dc2annularvoulume) && !isNaN(this.displacement)
        ? Math.round((this.dc2annularvoulume / this.displacement) * 1e4) / 1e4
        : 0);
    },
    dc2annulartime() {
      return !isNaN(this.dc2annularstrokes) && !isNaN(this.slctpmprate)
        ? Math.round((this.dc2annularstrokes / this.slctpmprate) * 1e4) / 1e4
        : 0;
    },
    dcannularvoulume() {
      return (
        Math.round(
          Number(this.casingdc1annularcapacity) *
          Number(this.casingdc1length) *
          1e4
        ) / 1e4 || 0
      );
    },
    dcannularstrokes() {
      return Math.round(!isNaN(this.dcannularvoulume) && !isNaN(this.displacement)
        ? Math.round((this.dcannularvoulume / this.displacement) * 1e4) / 1e4
        : 0);
    },
    dcannulartime() {
      return !isNaN(this.dcannularstrokes) && !isNaN(this.slctpmprate)
        ? Math.round((this.dcannularstrokes / this.slctpmprate) * 1e4) / 1e4
        : 0;
    },
    totalcasingdplength() {
      return (
        Math.round((Number(this.casingdplength) +
          Number(this.casinghwdplength) +
          Number(this.casingdc1length) +
          Number(this.casingdc2length)) * 1e4) / 1e4 || 0
      );
    },
    totalcasingdpvolume() {
      return (
        Math.round((Number(this.cassed_hole_vol) +
          Number(this.casinghwdpannularvolume) +
          Number(this.dc2annularvoulume) +
          Number(this.dcannularvoulume)) * 1e4) / 1e4 || 0
      );
    },
    totalcasingdpannularcapacity() {
      return (
        Math.round((Number(this.casingdpannularcapacity) +
          Number(this.casinghwdpannularcapacity) +
          Number(this.casingdc1annularcapacity) +
          Number(this.casingdc2annularcapacity)) * 1e4) / 1e4 || 0
      );
    },
    total_casingdpstrokes() {
      return (
        Math.round(
          (Number(this.dcannularstrokes) +
            Number(this.dc2annularstrokes) +
            Number(this.casinghwdpannularstrokes) +
            Number(this.casingdpstrokes)) *
          1e4
        ) / 1e4 || 0
      );
    },
    total_casingdptime() {
      return (
        Math.round(
          (Number(this.dc2annulartime) +
            Number(this.dcannulartime) +
            Number(this.casinghwdpannulartime) +
            Number(this.casingdptime)) *
          1e4
        ) / 1e4 || 0
      );
    },
    // OPEN HOLE VOLUME CALCULATIONS
    dpannularvolume() {
      return (
        Math.round(
          Number(this.openholedplength) * Number(this.ohdpannularcapacity) * 1e4
        ) / 1e4 || 0
      );
    },
    dpannularstrokes() {
      console.log(
        "!isNaN(this.dpannularvolume) && !isNaN(this.displacement)",
        this.dpannularvolume,
        " 88 ",
        this.displacement
      );
      return Math.round(!isNaN(this.dpannularvolume) && !isNaN(this.displacement)
        ? Math.round((this.dpannularvolume / this.displacement) * 1e4) / 1e4
        : 0);
    },
    openholedptime() {
      return !isNaN(this.dpannularstrokes) && !isNaN(this.slctpmprate)
        ? Math.round((this.dpannularstrokes / this.slctpmprate) * 1e4) / 1e4
        : 0;
    },
    hwdpannularvolume() {
      return Number(this.hwdpannularcapacity) && Number(this.openholehwdplength)
        ? Math.round(
          Number(this.hwdpannularcapacity) *
          Number(this.openholehwdplength) *
          1e4
        ) / 1e4
        : 0;
    },
    hwdpannularstrokes() {
      return Math.round(!isNaN(this.hwdpannularvolume) && !isNaN(this.displacement)
        ? Math.round((this.hwdpannularvolume / this.displacement) * 1e4) / 1e4
        : 0);
    },
    openholehwdptime() {
      return !isNaN(this.hwdpannularstrokes) && !isNaN(this.slctpmprate)
        ? Math.round((this.hwdpannularstrokes / this.slctpmprate) * 1e4) / 1e4
        : 0;
    },
    dc2annularvolume() {
      return (
        Math.round(
          Number(this.ohdc2annularcapacity) *
          Number(this.openholedc2length) *
          1e4
        ) / 1e4 || 0
      );
    },
    ohdc2annularstrokes() {
      return Math.round(!isNaN(this.dc2annularvolume) && !isNaN(this.displacement)
        ? Math.round((this.dc2annularvolume / this.displacement) * 1e4) / 1e4
        : 0);
    },
    openholedc2time() {
      return !isNaN(this.ohdc2annularstrokes) && !isNaN(this.slctpmprate)
        ? Math.round((this.ohdc2annularstrokes / this.slctpmprate) * 1e4) / 1e4
        : 0;
    },
    dc1annularvolume() {
      return (
        Math.round(
          Number(this.ohdc1annularcapacity) *
          Number(this.openholedc1length) *
          1e4
        ) / 1e4 || 0
      );
    },
    dc1annularstrokes() {
      return Math.round(!isNaN(this.dc1annularvolume) && !isNaN(this.displacement)
        ? Math.round((this.dc1annularvolume / this.displacement) * 1e4) / 1e4
        : 0);
    },
    openholedc1time() {
      return !isNaN(this.dc1annularstrokes) && !isNaN(this.slctpmprate)
        ? Math.round((this.dc1annularstrokes / this.slctpmprate) * 1e4) / 1e4
        : 0;
    },
    totopenholelength() {
      return (
        Math.round(
          (Number(this.openholedplength) +
            Number(this.openholehwdplength) +
            Number(this.openholedc1length) +
            Number(this.openholedc2length)) *
          1e4
        ) / 1e4 || 0
      );
    },
    totopenholecapacity() {
      return (
        Math.round(
          (Number(this.ohdpannularcapacity) +
            Number(this.hwdpannularcapacity) +
            Number(this.ohdc1annularcapacity) +
            Number(this.ohdc2annularcapacity)) *
          1e4
        ) / 1e4 || 0
      );
    },
    openholevolume() {
      return (
        Math.round(
          (Number(this.dpannularvolume) +
            Number(this.hwdpannularvolume) +
            Number(this.dc1annularvolume) +
            Number(this.dc2annularvolume)) *
          1e4
        ) / 1e4 || 0
      );
    },
    totopenholestrokes() {
      return (
        Math.round(
          (Number(this.dpannularstrokes) +
            Number(this.hwdpannularstrokes) +
            Number(this.dc1annularstrokes) +
            Number(this.ohdc2annularstrokes)) *
          1e4
        ) / 1e4 || 0
      );
    },
    totalopenholetime() {
      return (
        Math.round(
          (Number(this.openholedptime) +
            Number(this.openholehwdptime) +
            Number(this.openholedc1time) +
            Number(this.openholedc2time)) *
          1e4
        ) / 1e4 || 0
      );
    },
    // TOTAL ANNULAR VOLUME CALCULATION
    annularvolumeV() {
      return !isNaN(this.totalcasingdpvolume) && !isNaN(this.openholevolume)
        ? Math.round(
          (Number(this.totalcasingdpvolume) + Number(this.openholevolume)) *
          1e4
        ) / 1e4
        : "";
    },
    lagstrokes() {
      return Math.round(!isNaN(this.annularvolumeV) && !isNaN(this.displacement)
        ? Math.round((this.annularvolumeV / this.displacement) * 1e4) / 1e4
        : 0);
    },
    lagtime() {
      return !isNaN(this.lagstrokes) && !isNaN(this.slctpmprate)
        ? Math.round((this.lagstrokes / this.slctpmprate) * 1e4) / 1e4
        : 0;
    },
    // TOTAL WELL SYSTEM VOLUME CALCULATION
    cycle_volume_v() {
      return !isNaN(this.annularvolumeV) && !isNaN(this.drillstringvolumeV)
        ? Math.round(
          (Number(this.annularvolumeV) + Number(this.drillstringvolumeV)) *
          1e4
        ) / 1e4
        : "";
    },
    cycle_strokes() {
      return Math.round(!isNaN(this.cycle_volume_v) && !isNaN(this.displacement)
        ? Math.round((this.cycle_volume_v / this.displacement) * 1e4) / 1e4
        : 0);
    },
    cycle_time() {
      return !isNaN(this.cycle_strokes) && !isNaN(this.slctpmprate)
        ? Math.round((this.cycle_strokes / this.slctpmprate) * 1e4) / 1e4
        : 0;
    },
    // ACTIVE SURFACE VOLUME CALCULATION
    active_surface_vol_pumpstrokes() {
      return Math.round(!isNaN(this.active_surface_vol) && !isNaN(this.displacement)
        ? Math.round((this.active_surface_vol / this.displacement) * 1e4) / 1e4
        : 0);
    },
    // TOTAL ACTIVE FLUID SYSTEM CALCULATIONS
    tot_active_fluid_sys() {
      return (
        Math.round(
          (Number(this.active_surface_vol) + Number(this.cycle_volume_v)) * 1e4
        ) / 1e4 || 0
      );
    },
    tot_active_fluid_sys_pumpstrokes() {
      return Math.round(!isNaN(this.tot_active_fluid_sys) && !isNaN(this.displacement)
        ? Math.round((this.tot_active_fluid_sys / this.displacement) * 1e4) /
        1e4
        : 0);
    },
    // SURFACE LINE VOLUME CALCULATIONS
    surface_line_volume_strokes() {
      return Math.round(!isNaN(this.surface_line_volume) && !isNaN(this.displacement)
        ? Math.round((this.surface_line_volume / this.displacement) * 1e4) / 1e4
        : 0);
    },

    // HORIZONTAL CALCULATIONS
    // PRE RECORDED VOLUME DATA
    predpvolume() {
      return Math.round(this.predplength * this.dpcapacity * 1e4) / 1e4;
    },
    predpstrokes() {
      let result =
        Math.round((this.predpvolume / this.displacement) * 1e4) / 1e4;
      return Math.round(!isNaN(result) ? result : 0);
    },
    predptime() {
      let result =
        Math.round((this.predpstrokes / this.slctpmprate) * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },

    prehwvolume() {
      console.log("pre recorded select ", this.prehwlength, this.hwcapacity);
      let result = Math.round(this.prehwlength * this.hwcapacity * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    prehwstrokes() {
      let result =
        Math.round((this.prehwvolume / this.displacement) * 1e4) / 1e4;
      return Math.round(!isNaN(result) ? result : 0);
    },
    prehwtime() {
      let result =
        Math.round((this.prehwstrokes / this.slctpmprate) * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    //
    predc2tokopvolume() {
      console.log(
        "pre recorded select ",
        this.predc2tokoplength,
        this.dc2tokopcapacity
      );
      let result =
        Math.round(this.predc2tokoplength * this.dc2tokopcapacity * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    predc2tokopstrokes() {
      let result =
        Math.round((this.predc2tokopvolume / this.displacement) * 1e4) / 1e4;
      return Math.round(!isNaN(result) ? result : 0);
    },
    predc2tokoptime() {
      let result =
        Math.round((this.predc2tokopstrokes / this.slctpmprate) * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    //
    predc1tokopvolume() {
      console.log(
        "pre recorded select ",
        this.predc1tokoplength,
        this.dc1tokopcapacity
      );
      let result =
        Math.round(this.predc1tokoplength * this.dc1tokopcapacity * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    predc1tokopstrokes() {
      let result =
        Math.round((this.predc1tokopvolume / this.displacement) * 1e4) / 1e4;
      return Math.round(!isNaN(result) ? result : 0);
    },
    predc1tokoptime() {
      let result =
        Math.round((this.predc1tokopstrokes / this.slctpmprate) * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    preeobvolume() {
      let result = Math.round(this.preeoblength * this.dpcapacity * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    preeobstrokes() {
      let result =
        Math.round((this.preeobvolume / this.displacement) * 1e4) / 1e4;
      return Math.round(!isNaN(result) ? result : 0);
    },
    preeobtime() {
      let result =
        Math.round((this.preeobstrokes / this.slctpmprate) * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    prehweobvolume() {
      let result =
        Math.round(this.prehweoblength * this.eobhwcapacity * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    prehweobsstrokes() {
      let result =
        Math.round((this.prehweobvolume / this.displacement) * 1e4) / 1e4;
      return Math.round(!isNaN(result) ? result : 0);
    },
    prehweobtime() {
      let result =
        Math.round((this.prehweobsstrokes / this.slctpmprate) * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    //
    predc2toeobvolume() {
      let result =
        Math.round(this.predc2toeoblength * this.eobdc2tocapacity * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    predc2toeobsstrokes() {
      let result =
        Math.round((this.predc2toeobvolume / this.displacement) * 1e4) / 1e4;
      return Math.round(!isNaN(result) ? result : 0);
    },
    predc2toeobtime() {
      let result =
        Math.round((this.predc2toeobsstrokes / this.slctpmprate) * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    //
    predc1toeobvolume() {
      let result =
        Math.round(this.predc1toeoblength * this.eobdc1tocapacity * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    predc1toeobsstrokes() {
      let result =
        Math.round((this.predc1toeobvolume / this.displacement) * 1e4) / 1e4;
      return Math.round(!isNaN(result) ? result : 0);
    },
    predc1toeobtime() {
      let result =
        Math.round((this.predc1toeobsstrokes / this.slctpmprate) * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    prebhavolume() {
      let result = Math.round(this.prebhalength * this.dpcapacity * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    prebhastrokes() {
      let result =
        Math.round((this.prebhavolume / this.displacement) * 1e4) / 1e4;
      return Math.round(!isNaN(result) ? result : 0);
    },
    prebhatime() {
      let result =
        Math.round((this.prebhastrokes / this.slctpmprate) * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    prehwdpvolume() {
      let result =
        Math.round(this.prehwdplength * this.hwdpcapacity * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    prehwdpstrokes() {
      let result =
        Math.round((this.prehwdpvolume / this.displacement) * 1e4) / 1e4;
      return Math.round(!isNaN(result) ? result : 0);
    },
    prehwdptime() {
      let result =
        Math.round((this.prehwdpstrokes / this.slctpmprate) * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    predc2time() {
      let result =
        Math.round((this.predc2strokes / this.slctpmprate) * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    predc2volume() {
      let result = Math.round(this.predc2length * this.dc2capacity * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    predc2strokes() {
      let result =
        Math.round((this.predc2volume / this.displacement) * 1e4) / 1e4;
      return Math.round(!isNaN(result) ? result : 0);
    },
    predc1volume() {
      let result =
        Math.round(this.prehwdc1length * this.dc1capacity * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    prehwdc1strokes() {
      let result =
        Math.round((this.predc1volume / this.displacement) * 1e4) / 1e4;
      return Math.round(!isNaN(result) ? result : 0);
    },
    prehwdc1time() {
      let result =
        Math.round((this.prehwdc1strokes / this.slctpmprate) * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    pretotatallength() {
      return (
        Math.round(
          (Number(this.predplength) +
            Number(this.prehwlength) +
            Number(this.predc2tokoplength) +
            Number(this.predc1tokoplength) +
            Number(this.preeoblength) +
            Number(this.prehweoblength) +
            Number(this.predc2toeoblength) +
            Number(this.predc1toeoblength) +
            Number(this.prebhalength) +
            Number(this.prehwdplength) +
            Number(this.predc2length) +
            Number(this.prehwdc1length)) *
          1e4
        ) / 1e4
      );
    },
    pretotalcapacity() {
      // return Math.round((
      //   Number(this.dpcapacity) +
      //   Number(this.hwcapacity) +
      //   Number(this.dpcapacity) +
      //   Number(this.eobhwcapacity) +
      //   Number(this.dpcapacity) +
      //   Number(this.hwdpcapacity) +
      //   Number(this.dc2volume) +
      //   Number(this.dc1capacity)
      // ) * 1e4) / 1e4;
      return "";
    },
    pretotalvolume() {
      return (
        Math.round(
          (Number(this.predc1volume) +
            Number(this.predc2volume) +
            Number(this.prehwdpvolume) +
            Number(this.prebhavolume) +
            Number(this.preeobvolume) +
            Number(this.predc2toeobvolume) +
            Number(this.predc1toeobvolume) +
            Number(this.predpvolume) +
            Number(this.prehwvolume) +
            Number(this.predc2tokopvolume) +
            Number(this.predc1tokopvolume) +
            Number(this.prehweobvolume)) *
          1e4
        ) / 1e4
      );
    },
    pretotalstrokes() {
      return (
        Math.round(
          (Number(this.predpstrokes) +
            Number(this.prehwstrokes) +
            Number(this.predc2tokopstrokes) +
            Number(this.predc1tokopstrokes) +
            Number(this.preeobstrokes) +
            Number(this.prehweobsstrokes) +
            Number(this.predc2toeobsstrokes) +
            Number(this.predc1toeobsstrokes) +
            Number(this.prebhastrokes) +
            Number(this.prehwdpstrokes) +
            Number(this.predc2strokes) +
            Number(this.prehwdc1strokes)) *
          1e4
        ) / 1e4
      );
    },
    pretotaltime() {
      return (
        Math.round(
          (Number(this.predptime) +
            Number(this.prehwtime) +
            Number(this.preeobtime) +
            Number(this.prehweobtime) +
            Number(this.prebhatime) +
            Number(this.prehwdptime) +
            Number(this.predc2time) +
            Number(this.prehwdc1time)) *
          1e4
        ) / 1e4
      );
    },
    totalwellvolume() {
      return Math.round((Number(this.pretotalvolume) + Number(this.annularvolumeV)) *
        1e4
      ) / 1e4;
    },
    cyclestrokes() {
      return (
        Math.round(
          (Number(this.casingdpstrokes) +
            Number(this.totopenholestrokes) +
            Number(this.pretotalstrokes)) *
          1e4
        ) / 1e4
      );
    },
    totalwelltime() {
      let result =
        Math.round((this.cyclestrokes / this.slctpmprate) * 1e4) / 1e4;
      return !isNaN(result) ? result : 0;
    },
    tot_active_fluid_system() {
      return Math.round((Number(this.totalwellvolume) + Number(this.active_surface_vol)) *
        1e4
      ) / 1e4;
    },
    tot_active_fluid_system_pumpstrokes() {
      let result =
        Math.round((this.tot_active_fluid_system / this.displacement) * 1e4) /
        1e4;
      return Math.round(!isNaN(result) ? result : 0);
    },

    surfacetoeob() {
      return (
        // Math.round(
        //   (Number(this.predpstrokes) +
        //     Number(this.prehwstrokes) +
        //     Number(this.preeobstrokes) +
        //     Number(this.prehweobsstrokes)) *
        //   1e4
        // ) / 1e4
        Math.round((Number(this.surfacetokop ) + Number(this.koptoeob)) * 1e4) / 1e4
      );
    },
    surfacetodc() {
       // Math.round(
        //   (Number(this.predpstrokes) +
        //     Number(this.prehwstrokes) +
        //     Number(this.preeobstrokes) +
        //     Number(this.prehweobsstrokes) +
        //     Number(this.prebhastrokes) +
        //     Number(this.prehwdpstrokes)) *
        //   1e4
        // ) / 1e4
      return   Math.round((Number(this.surfacetokop) + Number(this.koptoeob) +Number(this.prebhastrokes) + Number(this.prehwdpstrokes))* 1e4) / 1e4
      
    },
    surfacetobha() {
      return (
        // Math.round(
        //   (Number(this.predpstrokes) +
        //     Number(this.prehwstrokes) +
        //     Number(this.preeobstrokes) +
        //     Number(this.prehweobsstrokes) +
        //     Number(this.prebhastrokes)) *
        //   1e4
        // ) / 1e4
        Math.round((Number(this.surfacetokop) + Number(this.koptoeob) + Number(this.prebhastrokes)) * 1e4) / 1e4
      );
    },
    koptoeob() {
      return Math.round((Number(this.preeobstrokes) + Number(this.prehweobsstrokes) + Number(this.predc2toeobsstrokes) + Number(this.predc1toeobsstrokes)) *
        1e4
      ) / 1e4;
    },
    surfacetokop() {
      return Math.round((Number(this.predpstrokes) + Number(this.prehwstrokes) + Number(this.predc2tokopstrokes) + Number(this.predc1tokopstrokes)) *
        1e4
      ) / 1e4;
    },
    surfacetobit() {
      // Math.round((this.pretotalstrokes) *
      //   1e4
      // ) / 1e4;
      // return Math.round((Number(this.surfacetodc) + Number(this.predc2strokes) + Number(this.prehwdc1strokes))  * 1e4)  / 1e4
      return Math.round(Number(this.pretotalstrokes)  * 1e4)  / 1e4
    },
    // lagstrokes() {
    //   return (
    //     Math.round(
    //       Number(this.casingdpstrokes) + Number(this.totopenholestrokes) * 1e4
    //     ) / 1e4
    //   );
    // },
    mamd() {
      return (
        Math.round(
          (Number(this.vuserinp.vuserdata.vlotp) /
            (Number(this.tvd) * Number(this.conv_factor)) +
            Number(this.vuserinp.vuserdata.rho_test)) *
          1e4
        ) / 1e4
      );
    },
    maasp() {
      return (
        Math.round(
          (Number(this.mamd) - Number(this.vuserinp.vuserdata.rho)) *
          this.conv_factor *
          Number(this.tvd) *
          1e4
        ) / 1e4
      );
    },
    mamg() {
      return Math.round(Number(this.mamd) * this.conv_factor * 1e4) / 1e4;
    },
  },
  sockets: {
    connect: function () {
      console.log("socket connected in trend");
    },
    disconnect() {
      try {
        let topic = this.topic;
        this.$socket.emit("unsubscribe", {
          logId: `${this.wellId}~seperation~${this.wellboreId}~seperation~${this.logType}`,
          topic: this.topic,
          id: this.widgetId,
          clientId: this.$clientId,
        });
      } catch (error) {
        console.error("disconnect unsubscribe error ", error);
      }
    },

    reconnect() {
      this.$socket.emit("susbcribe", {
        logId: `${this.wellId}~seperation~${this.wellboreId}~seperation~${this.logType}`,
        topic: this.topic,
        fields: this.fields,
        id: this.widgetId,
        clientId: this.$clientId,
      });
      console.log("----------------------------------------");
    },
  },

  methods: {
    checkbox1Clicked(whichCheckBox){
    //   this.whichCheckboxClicked = whichCheckBox
    //   if(whichCheckBox === 1){
    //     if(this.$refs.theCheckbox1.checked){
    //       this.$refs.theCheckbox4.checked = true
    //     }else {
    //       this.$refs.theCheckbox4.checked = false
    //     }
    //   }else if(whichCheckBox === 2) {
    //     if(this.$refs.theCheckbox2.checked){
    //       this.$refs.theCheckbox5.checked = true
    //     }else {
    //       this.$refs.theCheckbox5.checked = false
    //     }
    //   }else if(whichCheckBox === 3){
    //     if(this.$refs.theCheckbox3.checked){
    //       this.$refs.theCheckbox6.checked = true
    //     }else {
    //       this.$refs.theCheckbox6.checked = false
    //     }
    //   }
    },
    dispselectPumpno() {
      if (this.dispselected == "Pump No 1") {
        this.efficiency = this.efficiency1;
        this.diameter = this.diameter1;
        this.strokelength = this.strokelength1;
      } else if (this.dispselected == "Pump No 2") {
        this.efficiency = this.efficiency2;
        this.diameter = this.diameter2;
        this.strokelength = this.strokelength2;
      } else if (this.dispselected == "Pump No 3") {
        this.efficiency = this.efficiency3;
        this.diameter = this.diameter3;
        this.strokelength = this.strokelength3;
      } else if (this.dispselected == "Pump No 4") {
        this.efficiency = this.efficiency4;
        this.diameter = this.diameter4;
        this.strokelength = this.strokelength4;
      } else {
        this.efficiency = "";
        this.diameter = "";
        this.strokelength = "";
      }
    },
    preRecordedSelectHandler() {
      if (this.preseccell == "HWDP to KOP1") {
        this.hwcapacity = Number(this.hwdpcapacity);
      } else if (this.preseccell == "DC2 to KOP1") {
        this.hwcapacity = Number(this.dc2capacity);
      } else if (this.preseccell == "DC1 to KOP1") {
        this.hwcapacity = Number(this.dc1capacity);
      } else {
        this.hwcapacity = "";
      }
    },
    preRecordedSecondSelectHandler() {
      if (this.preforcell == "HWDP KOP1 to EOB") {
        this.eobhwcapacity = Number(this.hwdpcapacity);
      } else if (this.preforcell == "DC2 to KOP1 to EOB") {
        this.eobhwcapacity = Number(this.dc2capacity);
      } else if (this.preforcell == "DC1 to KOP1 to EOB") {
        this.eobhwcapacity = Number(this.dc1capacity);
      } else {
        this.eobhwcapacity = "";
      }
    },
    toggleInclinationDataNull() {
      this.incldatanull = !this.incldatanull;
    },
    // Vertical Functionality
    minimize() {
      this.isminimize = true;
      this.bottomMinimizer = true;
      console.log("this.bottomMinimizer minimize", this.bottomMinimizer);
    },
    maximize() {
      this.isminimize = false;
      this.bottomMinimizer = false;
    },
    refresh() {
      this.getapi("refresh");
    },
    async printData() {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        preferCurrentTab: true,
      });
      const videoTrack = stream.getVideoTracks()[0];
      const imageCapture = new ImageCapture(videoTrack);
      const bitmap = await imageCapture.grabFrame();
      videoTrack.stop();
      const canvas = document.getElementById("canvasOfSupport");
      const ctx = canvas.getContext("2d");
      canvas.width = bitmap.width;
      canvas.height = bitmap.height;
      ctx.drawImage(bitmap, 0, 0, canvas.width, canvas.height);
      const image = canvas.toDataURL();
      this.capturedImage = image;
      this.download();
      this.isSupportScreenshotShowing = true;
    },
    download() {
      const a = document.createElement("a");
      a.setAttribute("download", "my-image.png");
      a.setAttribute("href", this.capturedImage);
      a.click();
    },
    close() {
      this.isSupportScreenshotShowing = false;
    },
    lotpressure() {
      // if (
      //   this.vuserinp.vuserdata.rho_test != "" &&
      //   this.vuserinp.vuserdata.vlotp != ""
      // ) {
      //   this.mamd = (
      //     this.vuserinp.vuserdata.vlotp / (this.tvd * this.conv_factor) +
      //     parseInt(this.vuserinp.vuserdata.rho_test)
      //   ).toFixed(3);
      //   this.maasp = (
      //     (this.mamd - this.vuserinp.vuserdata.rho) *
      //     this.conv_factor *
      //     this.tvd
      //   ).toFixed(3);
      //   this.mamg = (this.mamd * this.conv_factor).toFixed(3);
      // }
      if (this.vuserinp.vuserdata.rho != "") {
        this.vuserinp.vuserdata.vgradient =
          this.vuserinp.vuserdata.rho * this.conv_factor;
      }
    },

    // This function is for the working of the displacement calculation
    dispcalc() {
      this.dispcalcpopup = !this.dispcalcpopup;
      this.diameter = "";
      this.strokelength = "";
      this.efficiency = "";
      this.dispselected = "select";
    },
    dispopopn() {
      this.dispcalcpopup = !this.dispcalcpopup;
      this.diameter = "";
      this.strokelength = "";
      this.efficiency = "";
      this.dispselected = "select";
    },
    toggleVerticalHorizontal() {
      this.checked = !this.checked;
    },
    dispok() {
      if (this.inclination != "") {
        this.incl_data = this.inclination;
        this.incl_data = parseFloat(this.incl_data);
        this.incldatanull = true;
        if (this.incl_data < 10 && this.incl_data >= 0) {
          this.checked = false;
        } else {
          this.checked = true;
        }
      } else {
        this.$toast.error("Input Fields Should not be blank", {
          position: "top",
          duration: 2000,
        });
      }
    },
    dispsave() {
      let intypefficiency = parseInt(this.efficiency);
      if (
        this.diameter != "" &&
        this.strokelength != "" &&
        intypefficiency != "" &&
        this.dispselected != "select"
      ) {
        let dispform = (
          0.000243 *
          this.diameter *
          this.diameter *
          this.strokelength *
          (intypefficiency / 100)
        ).toFixed(3);

        if (this.dispselected == "Pump No 1") {
          this.diameter1 = this.diameter;
          this.strokelength1 = this.strokelength;
          this.efficiency1 = intypefficiency;
          this.pumpoutput1 = parseFloat(dispform);
        } else if (this.dispselected == "Pump No 2") {
          this.diameter2 = this.diameter;
          this.strokelength2 = this.strokelength;
          this.efficiency2 = intypefficiency;
          this.pumpoutput2 = parseFloat(dispform);
        } else if (this.dispselected == "Pump No 3") {
          this.diameter3 = this.diameter;
          this.strokelength3 = this.strokelength;
          this.efficiency3 = intypefficiency;
          this.pumpoutput3 = parseFloat(dispform);
        } else if (this.dispselected == "Pump No 4") {
          this.diameter4 = this.diameter;
          this.strokelength4 = this.strokelength;
          this.efficiency4 = intypefficiency;
          this.pumpoutput4 = parseFloat(dispform);
        } else {
          this.pumpoutput1 = "";
          this.pumpoutput2 = "";
          this.pumpoutput3 = "";
          this.pumpoutput4 = "";
        }
        this.dispcalcpopup = !this.dispcalcpopup;
        this.diameter = "";
        this.strokelength = "";
        this.efficiency = "";
        this.dispselected = "select";
        this.isdispdata = false;
      } else {
        this.$toast.error("Input Fields Should not be blank", {
          position: "top",
          duration: 1500,
        });
      }
    },
    selectPumpno() {
      this.selectedArray = this.selected;

      // displacement of selected pump
      if (this.selected == "Pump No 1") {
        this.displacement = this.pumpoutput1;
        this.dpl = this.slctpmp1;
      } else if (this.selected == "Pump No 2") {
        this.displacement = this.pumpoutput2;
        this.dpl = this.slctpmp2;
      } else if (this.selected == "Pump No 3") {
        this.displacement = this.pumpoutput3;
        this.dpl = this.slctpmp3;
      } else if (this.selected == "Pump No 4") {
        this.displacement = this.pumpoutput4;
        this.dpl = this.slctpmp3;
      } else {
        this.displacement = "d";
        this.dpl = "";
      }

      // calculation for time

      // Strokes Calculation

      if (this.$refs.theCheckbox1.checked || this.$refs.theCheckbox4.checked) {
        this.isAddClass = false;
        this.slpmprate1();
      }
      if (this.$refs.theCheckbox2.checked || this.$refs.theCheckbox5.checked) {
        this.isslpmprate2 = false;
        this.slpmprate2();
      }
      if (this.$refs.theCheckbox3.checked || this.$refs.theCheckbox6.checked) {
        this.isslpmprate3 = false;
        this.slpmprate3();
      }

      if (this.selected == "select") {
        this.displacement = "d";
        this.dpstrokes = "";
        this.hwdpstrokes = "";
        this.dc1strokes = "";
        this.dc2strokes = "";
        this.dpannularstrokes = "";
        this.hwdpannularstrokes = "";
        this.dc1annularstrokes = "";
        this.dc2annularstrokes = "";
        this.ohdc2annularstrokes = "";
        this.lagstrokes = "";
        this.cycle_strokes = "";
        this.active_surface_vol_pumpstrokes = "";
        this.tot_active_fluid_sys_pumpstrokes = "";

        this.hwdptime = "";
        this.dc1time = "";
        this.dc2time = "";
        this.openholedptime = "";
        this.openholehwdptime = "";
        this.openholedc1time = "";
        this.openholedc2time = "";
        this.totalopenholetime = "";
        this.totaltime = "";

        (this.slctpmprate = "t"), (this.slctpmp1 = "");
        this.slctpmp2 = "";
        this.slctpmp3 = "";
        this.slctpmp4 = "";
        this.lagtime = "";
        this.cycle_time = "";
        this.surface_line_volume_stroke = "";
        this.isAddClass = false;
        this.isslpmprate2 = false;
        this.isslpmprate3 = false;
        this.preeobstrokes = "";
        this.prebhastrokes = "";

        this.predc1strokes = "";

        this.totopenholestrokes = "";

        this.lagstrokes = "";

        this.surface_line_volume_strokes = "";

        if (
          this.$refs.theCheckbox1.checked ||
          this.$refs.theCheckbox2.checked ||
          this.$refs.theCheckbox3.checked
        ) {
          this.$refs.theCheckbox1.checked = false;
          this.$refs.theCheckbox2.checked = false;
          this.$refs.theCheckbox3.checked = false;
        }
        if (
          this.$refs.theCheckbox4.checked ||
          this.$refs.theCheckbox5.checked ||
          this.$refs.theCheckbox6.checked
        ) {
          this.$refs.theCheckbox4.checked = false;
          this.$refs.theCheckbox5.checked = false;
          this.$refs.theCheckbox6.checked = false;
        }
      }
    },

    slpmprate1() {
      this.isAddClass = !this.isAddClass;
      this.slctpmprate = this.slopmprate25;
      this.slctpmp1 = this.slopmp1rate25;
      this.slctpmp2 = this.slopmp2rate25;
      this.slctpmp3 = this.slopmp3rate25;
      this.slctpmp4 = this.slopmp4rate25;
      if (this.selected == "Pump No 1") {
        this.displacement = this.pumpoutput1;
        this.dpl = this.slctpmp1;
      } else if (this.selected == "Pump No 2") {
        this.displacement = this.pumpoutput2;
        this.dpl = this.slctpmp2;
      } else if (this.selected == "Pump No 3") {
        this.displacement = this.pumpoutput3;
        this.dpl = this.slctpmp3;
      } else if (this.selected == "Pump No 4") {
        this.displacement = this.pumpoutput4;
        this.dpl = this.slctpmp3;
      } else {
        this.displacement = "d";
        this.dpl = "";
      }

      if (this.isAddClass == false) {
        this.isAddClass = false;
        this.dptime = "";
        this.hwdptime = "";
        this.dc1time = "";
        this.dc2time = "";
        this.openholedptime = "";
        this.openholehwdptime = "";
        this.openholedc1time = "";
        this.openholedc2time = "";
        this.totalopenholetime = "";
        this.totaltime = "";
        (this.slctpmprate = "t"), (this.slctpmp1 = "");
        this.slctpmp2 = "";
        this.slctpmp3 = "";
        this.slctpmp4 = "";
        this.lagtime = "";
      }
    },
    slpmprate2() {
      this.isslpmprate2 = !this.isslpmprate2;
      this.slctpmprate = this.slopmprate35;
      this.slctpmp1 = this.slopmp1rate35;
      this.slctpmp2 = this.slopmp2rate35;
      this.slctpmp3 = this.slopmp3rate35;
      this.slctpmp4 = this.slopmp4rate35;
      if (this.selected == "Pump No 1") {
        this.displacement = this.pumpoutput1;
        this.dpl = this.slctpmp1;
      } else if (this.selected == "Pump No 2") {
        this.displacement = this.pumpoutput2;
        this.dpl = this.slctpmp2;
      } else if (this.selected == "Pump No 3") {
        this.displacement = this.pumpoutput3;
        this.dpl = this.slctpmp3;
      } else if (this.selected == "Pump No 4") {
        this.displacement = this.pumpoutput4;
        this.dpl = this.slctpmp3;
      } else {
        this.displacement = "d";
        this.dpl = "";
      }

      if (this.isslpmprate2 == false) {
        this.dptime = "";
        this.hwdptime = "";
        this.dc1time = "";
        this.dc2time = "";
        this.openholedptime = "";
        this.openholehwdptime = "";
        this.openholedc1time = "";
        this.openholedc2time = "";
        this.totalopenholetime = "";
        this.totaltime = "";
        (this.slctpmprate = "t"), (this.slctpmp1 = "");
        this.slctpmp2 = "";
        this.slctpmp3 = "";
        this.slctpmp4 = "";
        this.lagtime = "";
        this.isslpmprate2 = false;
      }
    },
    slpmprate3() {
      this.isslpmprate3 = !this.isslpmprate3;
      this.slctpmprate = this.slopmprate45;
      this.slctpmp1 = this.slopmp1rate45;
      this.slctpmp2 = this.slopmp2rate45;
      this.slctpmp3 = this.slopmp3rate45;
      this.slctpmp4 = this.slopmp4rate45;
      if (this.selected == "Pump No 1") {
        this.displacement = this.pumpoutput1;
        this.dpl = this.slctpmp1;
      } else if (this.selected == "Pump No 2") {
        this.displacement = this.pumpoutput2;
        this.dpl = this.slctpmp2;
      } else if (this.selected == "Pump No 3") {
        this.displacement = this.pumpoutput3;
        this.dpl = this.slctpmp3;
      } else if (this.selected == "Pump No 4") {
        this.displacement = this.pumpoutput4;
        this.dpl = this.slctpmp3;
      } else {
        this.displacement = "d";
        this.dpl = "";
      }
      // if (this.isslpmprate3 == true) {
      // } else {
      //   this.dptime = "";
      //   this.hwdptime = "";
      //   this.dc1time = "";
      //   this.dc2time = "";
      //   this.openholedptime = "";
      //   this.openholehwdptime = "";
      //   this.openholedc1time = "";
      //   this.openholedc2time = "";
      //   this.totalopenholetime = "";
      //   this.totaltime = "";

      //   this.lagtime = "";

      //   (this.slctpmprate = "t"), (this.slctpmp1 = "");
      //   this.slctpmp2 = "";
      //   this.slctpmp3 = "";
      //   this.slctpmp4 = "";

      //   this.isslpmprate3 = false;
      // }

      if (!this.isslpmprate3) {
        this.dptime = "";
        this.hwdptime = "";
        this.dc1time = "";
        this.dc2time = "";
        this.openholedptime = "";
        this.openholehwdptime = "";
        this.openholedc1time = "";
        this.openholedc2time = "";
        this.totalopenholetime = "";
        this.totaltime = "";
        this.lagtime = "";
        (this.slctpmprate = "t"), (this.slctpmp1 = "");
        this.slctpmp2 = "";
        this.slctpmp3 = "";
        this.slctpmp4 = "";
        this.isslpmprate3 = false;
      }
    },
    dplval() {
      if (this.$refs.theCheckbox1.checked || this.$refs.theCheckbox4.checked) {
        this.isAddClass = false;
        this.slpmprate1();
      }
      if (this.$refs.theCheckbox2.checked || this.$refs.theCheckbox5.checked) {
        this.isslpmprate2 = false;
        this.slpmprate2();
      }
      if (this.$refs.theCheckbox3.checked || this.$refs.theCheckbox6.checked) {
        this.isslpmprate3 = false;
        this.slpmprate3();
      }
    },
    setContextIdWidget(e) {
      this.$store.dispatch("disp/setContextId", {
        contextId: this.displayId,
        contextWidgetId: this.widgetId,
        contextType: "widget",
      });
    },
    processResult(sResult) {
      console.log("processResult ", sResult);
      console.log("processResult widgetId", this.widgetId);
    },

    windowToggle(name) {
      this.classes = name;
    },
    dark() {
      document.querySelector("nav").classList.add("dark-mode");
      document.querySelector("div").classList.add("dark-mode");
      document.querySelector("button").classList.add("dark-mode");
      document.querySelector("a").classList.add("text-red-600");
      this.darkMode = true;
      this.$emit("dark");
    },
    light() {
      document.querySelector("nav").classList.remove("dark-mode");
      document.querySelector("div").classList.remove("dark-mode");
      document.querySelector("button").classList.remove("dark-mode");
      document.querySelector("a").classList.remove("text-red-600");
      this.darkMode = false;
      this.$emit("light");
    },
    // This function is for the working of the post api Onclick of the execute button Horizontal
    executeHorizontal() {
      if (
        this.vuserinp.vuserdata.sidpp != "" &&
        this.vuserinp.vuserdata.sicp != "" &&
        this.pitgain != "" &&
        this.idia != "" &&
        this.md != "" &&
        this.bit_size != "" &&
        this.tvd != "" &&
        this.surface_line_volume != "" &&
        this.vuserinp.vuserdata.rho_test != "" &&
        this.vuserinp.vuserdata.vlotp != "" &&
        this.displacement != "" &&
        this.vuserinp.vuserdata.rho != "" &&
        this.selected != "" &&
        this.dpl != "" &&
        this.slctpmprate != "" &&
        this.vuserinp.vuserdata.vholetvd != "" &&
        this.kop1md != "" &&
        this.kop1tvd != "" &&
        this.eobmd != "" &&
        this.eobtvd != "" &&
        this.surfacetokop != ""
      ) {
        // if (this.pretotatallength != "" && this.dbtm != "") {
        //   if (Math.round(this.pretotatallength) != Math.round(this.dbtm)) {
        //     this.$toast.error(
        //       "Bit Depth and Drill String Input Length, both are not equal",
        //       {
        //         position: "top",
        //         duration: 5000,
        //       }
        //     );
        //     return false;
        //   }
        // }

        let results = axios
          .post(URL.HOST_URL.SERVER_URL + "public/killsheet", {
            kick_data: {
              sidpp: Number(this.vuserinp.vuserdata.sidpp),
              sicp: Number(this.vuserinp.vuserdata.sicp),
              pit_gain: Number(this.pitgain),
            },
            geometrydata: {
              idia: Number(this.idia),
              md: Number(this.md),
              odia: Number(this.bit_size),
              section_type: this.section_type,
              tvd: Number(this.tvd),
            },
            surface_data: {
              surface_line_volume: Number(this.surface_line_volume),
              active_surface_vol: Number(this.active_surface_vol),
              formation_strength_test: Number(this.vuserinp.vuserdata.vlotp),
            },
            time_data: {
              dbtm: Number(this.dbtm),
              kop1_md: Number(this.kop1md),
              kop1_tvd: Number(this.kop1tvd),
              eob_md: Number(this.eobmd),
              eob_tvd: Number(this.eobtvd),
              surface_to_kop1_strokes: Number(this.surfacetokop),
              kop1_to_eob_strokes: Number(this.koptoeob),
              surface_to_bit_strokes: Number(this.surfacetobit),
              active_surface_vol_pumpstrokes: Number(
                this.active_surface_vol_pumpstrokes
              ),
              annular_casing_vol: Number(this.annular_casing_vol),
              annularvolume: Number(this.annularvolumeV),
              bit_size: Number(this.bit_size),
              casingdc1length: Number(this.casingdc1length),
              casingdpannularcapacity: Number(this.casingdpannularcapacity),
              casingdplength: Number(this.casingdplength),
              casinghwdpannularcapacity: Number(this.casinghwdpannularcapacity),
              casinghwdplength: Number(this.casinghwdplength),
              cassed_hole_vol: Number(this.cassed_hole_vol),
              cycle_strokes: Number(this.cyclestrokes),
              cycle_time: Number(this.totalwelltime),
              cycle_volume: Number(this.totalwellvolume),
              dc1annularcapacity: Number(this.dc1annularcapacity),
              dc1annularstrokes: Number(this.dc1annularstrokes),
              dc1annularvolume: Number(this.dc1annularvolume),
              dc1capacity: Number(this.dc1capacity),
              dc1length: Number(this.dc1length),
              dc1strokes: Number(this.dc1strokes),
              dc1volume: Number(this.dc1volume),
              dc2annularcapacity: Number(this.dc2annularcapacity),
              dc2annularstrokes: Number(this.dc2annularstrokes),
              dc2annularvolume: Number(this.dc2annularvolume),
              dc2capacity: Number(this.dc2capacity),
              dc2length: Number(this.dc2length),
              dc2strokes: Number(this.dc2strokes),
              dc2volume: this.dc2volume,
              dmea: Number(this.dmea),
              dpannularstrokes: Number(this.dpannularstrokes),
              dpannularvolume: Number(this.dpannularvolume),
              dpcapacity: Number(this.dpcapacity),
              dplength: Number(this.predplength),
              dpstrokes: Number(this.predpstrokes),
              dpvolume: Number(this.predpvolume),
              drillstringvolume: Number(this.pretotalvolume),
              hwdpannularcapacity: Number(this.hwdpannularcapacity),
              hwdpannularstrokes: Number(this.hwdpannularstrokes),
              hwdpannularvolume: this.hwdpannularvolume,
              hwdpcapacity: Number(this.hwdpcapacity),
              hwdplength: Number(this.prehwdplength),
              hwdpstrokes: Number(this.prehwdpstrokes),
              hwdpvolume: Number(this.prehwdpvolume),
              incl_data:
                this.incl_data != null || 0 ? this.incl_data : this.inclination,
              lagstrokes: this.lagstrokes,
              lagtime: Number(this.lagtime),
              maasp: Number(this.maasp),
              mamd: Number(this.mamd),
              ohdc1annularcapacity: Number(this.dc1annularcapacity),
              ohdc2annularcapacity: Number(this.dc2annularcapacity),
              ohdpannularcapacity: Number(this.ohdpannularcapacity),
              openholedc1length: Number(this.openholedc1length),
              openholedc2length: Number(this.openholedc2length),
              openholedplength: Number(this.openholedplength),
              openholehwdplength: Number(this.openholehwdplength),
              openholevolume: Number(this.openholevolume),
              pcas: Number(this.vuserinp.vuserdata.sicp),
              pitgain: Number(this.pitgain),
              pumpoutput: this.displacement,
              rho: Number(this.vuserinp.vuserdata.rho),
              spm1: Number(this.spm1),
              spm2: Number(this.spm2),
              spm3: Number(this.spm3),
              spp: Number(this.spm1),
              string_strokes: Number(this.pretotalstrokes),
              time: this.time,
              tot_active_fluid_sys: this.tot_active_fluid_system,
              tot_active_fluid_sys_pumpstrokes: Number(
                this.tot_active_fluid_system_pumpstrokes
              ),
              total_vol: 733.641,
              rho_test: Number(this.vuserinp.vuserdata.rho_test),
              tvd: Number(this.vuserinp.vuserdata.vholetvd),
            },
            pump_data: {
              pump_select: this.selected,
              displacement: this.displacement,
              dpl: Number(this.dpl),
              dpr: Number(this.slctpmprate),
            },
          })
          .then(
            (response) => {
              this.isHidd = !this.isHidd;
              console.log("response in kill sheeet:::::",response.data)
              this.result = response.data.result;
              this.Pressure = response.data.killsheetdata[0].pressure;
              this.Strokes = response.data.killsheetdata[0].strokes;
              this.FCP = response.data.killsheetdata[0].FCP;
              console.log("fcPPPP::::::::::",this.FCP)
              this.FCP = parseFloat(this.FCP.toFixed(3));
              this.ICP = response.data.killsheetdata[0].ICP;
              this.ICP = parseFloat(this.ICP.toFixed(3));
              this.kval_bit = response.data.killsheetdata[0].kval_bit;
              this.kval_bit = parseFloat(this.kval_bit.toFixed(3));
              this.kval_eob = response.data.killsheetdata[0].kval_eob;
              this.kval_eob = parseFloat(this.kval_eob.toFixed(3));
              this.kval_kop1 = response.data.killsheetdata[0].kval_kop1;
              this.kval_kop1 = parseFloat(this.kval_kop1.toFixed(3));
              this.kcp1 = response.data.killsheetdata[0].kcp1;
              this.kcp1 = parseFloat(this.kcp1.toFixed(3));
              this.kcp2 = response.data.killsheetdata[0].kcp2;
              this.kcp2 = parseFloat(this.kcp2.toFixed(3));
              this.kfg = response.data.killsheetdata[0].kfg;
              this.kfg = parseFloat(this.kfg.toFixed(3));
              this.kfd = response.data.killsheetdata[0].kfd;
              this.kfd = parseFloat(this.kfd.toFixed(3));
              this.StrokeLength = Object.keys(this.Pressure).length;

              this.buildHoriSVG();
            },
            (error) => {

              this.$toast.error("Something Wrong", {
                position: "top",
                duration: 1500,
              });
            }
          );
      } else {
        this.$toast.error("Fields Should Not be blank", {
          position: "top",
          duration: 1500,
        });
      }
    },
    // This function is for the working of the post api Onclick of the execute button Vertical
    execute() {
      if (
        this.vuserinp.vuserdata.sidpp != "" &&
        this.vuserinp.vuserdata.sicp != "" &&
        this.pitgain != "" &&
        this.idia != "" &&
        this.md != "" &&
        this.bit_size != "" &&
        this.tvd != "" &&
        this.surface_line_volume != "" &&
        this.vuserinp.vuserdata.rho_test != "" &&
        this.vuserinp.vuserdata.vlotp != "" &&
        this.displacement != "" &&
        this.vuserinp.vuserdata.rho != "" &&
        this.selected != "" &&
        this.dpl != "" &&
        this.slctpmprate != ""
      ) {
        // if (this.pretotatallength != "" && this.dbtm != "") {
        //   if (Math.round(this.pretotatallength) != Math.round(this.dbtm)) {
        //     this.$toast.error(
        //       "Bit Depth and Drill String Input Length, both are not equal",
        //       {
        //         position: "top",
        //         duration: 5000,
        //       }
        //     );
        //     return false;
        //   }
        // }
        axios
          .post(URL.HOST_URL.SERVER_URL + "public/killsheet", {
            inc_data: Number(this.incl_data),
            kick_data: {
              sidpp: parseFloat(this.vuserinp.vuserdata.sidpp),
              sicp: parseInt(this.vuserinp.vuserdata.sicp),
              pit_gain: parseFloat(this.pitgain),
            },
            geometrydata: {
              idia: parseFloat(this.idia),
              md: parseFloat(this.md),
              odia: parseFloat(this.bit_size),
              section_type: this.section_type,
              tvd: parseFloat(this.tvd),
            },
            surface_data: {
              surface_line_volume: parseFloat(this.surface_line_volume),
              active_surface_vol: Number(this.active_surface_vol),
              formation_strength_test: parseFloat(
                this.vuserinp.vuserdata.vlotp
              ),
            },
            time_data: {
              rho_test: parseFloat(this.vuserinp.vuserdata.rho_test),
              tvd: parseFloat(this.vuserinp.vuserdata.vholetvd),
              active_surface_vol: parseFloat(this.active_surface_vol),
              active_surface_vol_pumpstrokes: parseFloat(
                this.active_surface_vol_pumpstrokes
              ),
              annular_casing_vol: this.annular_casing_vol,
              annularvolume: this.annularvolumeV,
              bit_size: Number(this.bit_size),
              casingdc1length: Number(this.casingdc1length),
              casingdpannularcapacity: Number(this.casingdpannularcapacity),
              casingdplength: Number(this.casingdplength),
              casinghwdpannularcapacity: Number(this.casinghwdpannularcapacity),
              casinghwdplength: Number(this.casinghwdplength),
              cassed_hole_vol: Number(this.cassed_hole_vol),
              cycle_strokes: parseFloat(this.cycle_strokes),
              cycle_time: parseFloat(this.cycle_time),
              cycle_volume: Number(this.cycle_volume_v),
              dbtm: Number(this.dbtm),
              ohdc1annularcapacity: Number(this.dc1annularcapacity),
              dc1annularstrokes: parseFloat(this.dc1annularstrokes),
              dc1annularvolume: Number(this.dc1annularvolume),
              dc1capacity: Number(this.dc1capacity),
              dc1length: Number(this.dc1length),
              dc1strokes: parseFloat(this.dc1strokes),
              dc1volume: Number(this.dc1volume),
              ohdc2annularcapacity: Number(this.dc2annularcapacity),
              dc2annularstrokes: parseFloat(this.dc2annularstrokes),
              dc2annularvolume: Number(this.dc2annularvolume),
              dc2capacity: Number(this.dc2capacity),
              dc2length: Number(this.dc2length),
              dc2strokes: parseFloat(this.dc2strokes),
              dc2volume: Number(this.dc2volume),
              dmea: parseFloat(this.dmea),
              dpannularstrokes: parseFloat(this.dpannularstrokes),
              dpannularvolume: Number(this.dpannularvolume),
              dpcapacity: Number(this.dpcapacity),
              dplength: Number(this.dplength),
              dpstrokes: parseFloat(this.dpstrokes),
              dpvolume: Number(this.dpvolume),
              drillstringvolume: Number(this.drillstringvolumeV),
              hwdpannularcapacity: Number(this.hwdpannularcapacity),
              hwdpannularstrokes: parseFloat(this.hwdpannularstrokes),
              hwdpannularvolume: Number(this.hwdpannularvolume),
              hwdpcapacity: Number(this.hwdpcapacity),
              hwdplength: Number(this.hwdplength),
              hwdpstrokes: parseFloat(this.hwdpstrokes),
              hwdpvolume: Number(this.hwdpvolume),
              incl_data: Number(this.incl_data),
              lagstrokes: parseFloat(this.lagstrokes),
              lagtime: parseFloat(this.lagtime),
              maasp: Number(this.maasp),
              mamd: Number(this.mamd),
              ohdpannularcapacity: Number(this.ohdpannularcapacity),
              openholedc1length: Number(this.openholedc1length),
              openholedc2length: Number(this.openholedc2length),
              openholedplength: Number(this.openholedplength),
              openholehwdplength: Number(this.openholehwdplength),
              openholevolume: Number(this.openholevolume),
              pcas: parseInt(this.vuserinp.vuserdata.sicp),
              pitgain: parseFloat(this.pitgain),
              pumpoutput: this.displacement,
              rho: parseFloat(this.vuserinp.vuserdata.rho),
              spm1: Number(this.spm1),
              spm2: Number(this.spm2),
              spm3: Number(this.spm3),
              spp: Number(this.spm1),
              string_strokes: parseFloat(this.totalstrokes),
              time: Number(this.time),
              tot_active_fluid_sys: Number(this.tot_active_fluid_sys),
              tot_active_fluid_sys_pumpstrokes: parseFloat(
                this.tot_active_fluid_sys_pumpstrokes
              ),
            },
            pump_data: {
              pump_select: this.selected,
              displacement: this.displacement,
              dpl: parseFloat(this.dpl),
              dpr: parseFloat(this.slctpmprate),
            },
          })
          .then(
            (response) => {
              this.isHidden = !this.isHidden;
              console.log("response in vertical killsheet",response.data.killsheetdata[0].pressure)
              this.result = response.data.result;
              this.Pressure = response.data.killsheetdata[0].pressure;
              this.Strokes = response.data.killsheetdata[0].strokes;
              this.FCP = response.data.killsheetdata[0].FCP;
              this.FCP = parseFloat(this.FCP.toFixed(3));
              this.ICP = response.data.killsheetdata[0].ICP;
              this.ICP = parseFloat(this.ICP.toFixed(3));
              this.Kval = response.data.killsheetdata[0].pressure_reduction;
              this.Kval = parseFloat(this.Kval.toFixed(3));
              this.kfg = response.data.killsheetdata[0].kfg;
              this.kfg = parseFloat(this.kfg.toFixed(3));
              this.kfd = response.data.killsheetdata[0].kfd;
              this.kfd = parseFloat(this.kfd.toFixed(3));
              this.StrokeLength = Object.keys(this.Pressure).length;
              this.buildSVG();
            },
            (error) => {
              this.$toast.error("Something Wrong", {
                position: "top",
                duration: 1500,
              });
            }
          );
      } else {
        this.$toast.error("Fields Should Not be blank", {
          position: "top",
          duration: 1500,
        });
      }
    },
    async getapi(from) {
      await axios
        .post(URL.HOST_URL.SERVER_URL + "public/collect", this.axiosParams)
        .then(
          (resp) => {
            console.log("get api", resp.data);
            this.list = resp.data.time_data;

            this.vuserinp.vuserdata.vbittvd = !isNaN(resp.data.time_data.tvd)
              ? Math.round(resp.data.time_data.tvd * 1e4) / 1e4
              : 0;
            this.vuserinp.vuserdata.vholetvd = !isNaN(resp.data.time_data.btvd)
              ? Math.round(resp.data.time_data.btvd * 1e4) / 1e4
              : 0;
            this.bit_size = !isNaN(resp.data.time_data.bit_size)
              ? Math.round(resp.data.time_data.bit_size * 1e4) / 1e4
              : 0;
            this.vholetvd =
              Math.round(resp.data.time_data.vholetvd * 1e4) / 1e4;
            this.idia = !isNaN(resp.data.well_geometry_data.idia)
              ? Math.round(resp.data.well_geometry_data.idia * 1e4) / 1e4
              : 0;
            this.md = !isNaN(resp.data.well_geometry_data.md)
              ? Math.round(resp.data.well_geometry_data.md * 1e4) / 1e4
              : 0;
            this.tvd = !isNaN(resp.data.well_geometry_data.tvd)
              ? Math.round(resp.data.well_geometry_data.tvd * 1e4) / 1e4
              : 0;
            // from api values
            this.dplength = !isNaN(resp.data.time_data.dplength)
              ? Math.round(resp.data.time_data.dplength * 1e4) / 1e4
              : 0;
            this.dpcapacity = !isNaN(resp.data.time_data.dpcapacity)
              ? Math.round(resp.data.time_data.dpcapacity * 1e4) / 1e4
              : 0;
            // this.dpvolume = !isNaN(resp.data.time_data.dpvolume)
            //   ? Math.round(resp.data.time_data.dpvolume * 1e4) / 1e4
            //   : 0;
            this.dpvolume = this.dplength * this.dpcapacity;
            this.hwdplength = !isNaN(resp.data.time_data.hwdplength)
              ? Math.round(resp.data.time_data.hwdplength * 1e4) / 1e4
              : 0;
            this.hwdpcapacity = !isNaN(resp.data.time_data.hwdpcapacity)
              ? Math.round(resp.data.time_data.hwdpcapacity * 1e4) / 1e4
              : 0;
            // this.hwdpvolume = !isNaN(resp.data.time_data.hwdpvolume)
            //   ? Math.round(resp.data.time_data.hwdpvolume * 1e4) / 1e4
            //   : 0;
            this.hwdpvolume = this.hwdplength * this.hwdpcapacity;
            this.dc1length = !isNaN(resp.data.time_data.dc1length)
              ? Math.round(resp.data.time_data.dc1length * 1e4) / 1e4
              : 0;
            this.dc1capacity = !isNaN(resp.data.time_data.dc1capacity)
              ? Math.round(resp.data.time_data.dc1capacity * 1e4) / 1e4
              : 0;
            // this.dc1volume = !isNaN(resp.data.time_data.dc1volume)
            //   ? Math.round(resp.data.time_data.dc1volume * 1e4) / 1e4
            //   : 0;
            this.dc1volume = this.dc1length * this.dc1capacity;
            this.dc2length = !isNaN(resp.data.time_data.dc2length)
              ? Math.round(resp.data.time_data.dc2length * 1e4) / 1e4
              : 0;
            this.dc2capacity = !isNaN(resp.data.time_data.dc2capacity)
              ? Math.round(resp.data.time_data.dc2capacity * 1e4) / 1e4
              : 0;
            // this.dc2volume = !isNaN(resp.data.time_data.dc2volume)
            //   ? Math.round(resp.data.time_data.dc2volume * 1e4) / 1e4
            //   : 0;
            this.dc2volume = this.dc2length * this.dc2capacity;
            this.drillstringvolume = !isNaN(
              resp.data.time_data.drillstringvolume
            )
              ? Math.round(resp.data.time_data.drillstringvolume * 1e4) / 1e4
              : 0;
            this.casingdplength = !isNaN(resp.data.time_data.casingdplength)
              ? Math.round(resp.data.time_data.casingdplength * 1e4) / 1e4
              : 0;
            this.casingdpannularcapacity = !isNaN(
              resp.data.time_data.casingdpannularcapacity
            )
              ? Math.round(resp.data.time_data.casingdpannularcapacity * 1e4) /
              1e4
              : 0;
            this.casinghwdplength = !isNaN(resp.data.time_data.casinghwdplength)
              ? Math.round(resp.data.time_data.casinghwdplength * 1e4) / 1e4
              : 0;
            this.casinghwdpannularcapacity = !isNaN(
              resp.data.time_data.casinghwdpannularcapacity
            )
              ? Math.round(
                resp.data.time_data.casinghwdpannularcapacity * 1e4
              ) / 1e4
              : 0;
            this.casingdc1length = !isNaN(resp.data.time_data.casingdc1length)
              ? Math.round(resp.data.time_data.casingdc1length * 1e4) / 1e4
              : 0;
            this.casingdc2length = !isNaN(resp.data.time_data.casingdc2length)
              ? Math.round(resp.data.time_data.casingdc2length * 1e4) / 1e4
              : 0;
            this.casingdc1annularcapacity = !isNaN(
              resp.data.time_data.casingdc1annularcapacity
            )
              ? Math.round(resp.data.time_data.casingdc1annularcapacity * 1e4) /
              1e4
              : 0;
            this.casingdc2annularcapacity = !isNaN(
              resp.data.time_data.casingdc2annularcapacity
            )
              ? Math.round(resp.data.time_data.casingdc2annularcapacity * 1e4) /
              1e4
              : 0;
            this.openholedplength = !isNaN(resp.data.time_data.openholedplength)
              ? Math.round(resp.data.time_data.openholedplength * 1e4) / 1e4
              : 0;
            this.ohdpannularcapacity = !isNaN(
              resp.data.time_data.ohdpannularcapacity
            )
              ? Math.round(resp.data.time_data.ohdpannularcapacity * 1e4) / 1e4
              : 0;
            this.dpannularvolume =
              this.openholedplength * this.ohdpannularcapacity;
            this.openholehwdplength = !isNaN(
              resp.data.time_data.openholehwdplength
            )
              ? Math.round(resp.data.time_data.openholehwdplength * 1e4) / 1e4
              : 0;
            this.hwdpannularcapacity = !isNaN(
              resp.data.time_data.hwdpannularcapacity
            )
              ? Math.round(resp.data.time_data.hwdpannularcapacity * 1e4) / 1e4
              : 0;
            this.hwdpannularvolume = !isNaN(
              resp.data.time_data.hwdpannularvolume
            )
              ? Math.round(resp.data.time_data.hwdpannularvolume * 1e4) / 1e4
              : 0;
            this.openholedc1length = !isNaN(
              resp.data.time_data.openholedc1length
            )
              ? Math.round(resp.data.time_data.openholedc1length * 1e4) / 1e4
              : 0;
            this.dc1annularcapacity = !isNaN(
              resp.data.time_data.dc1annularcapacity
            )
              ? Math.round(resp.data.time_data.dc1annularcapacity * 1e4) / 1e4
              : 0;
            this.dc1annularvolume = !isNaN(resp.data.time_data.dc1annularvolume)
              ? Math.round(resp.data.time_data.dc1annularvolume * 1e4) / 1e4
              : 0;
            this.openholedc2length = !isNaN(
              resp.data.time_data.openholedc2length
            )
              ? Math.round(resp.data.time_data.openholedc2length * 1e4) / 1e4
              : 0;
            this.dc2annularcapacity = !isNaN(
              resp.data.time_data.dc2annularcapacity
            )
              ? Math.round(resp.data.time_data.dc2annularcapacity * 1e4) / 1e4
              : 0;
            this.ohdc2annularcapacity = !isNaN(
              resp.data.time_data.ohdc2annularcapacity
            )
              ? Math.round(resp.data.time_data.ohdc2annularcapacity * 1e4) / 1e4
              : 0;
            this.ohdc1annularcapacity = !isNaN(
              resp.data.time_data.ohdc1annularcapacity
            )
              ? Math.round(resp.data.time_data.ohdc1annularcapacity * 1e4) / 1e4
              : 0;

            this.dc2annularvolume = !isNaN(resp.data.time_data.dc2annularvolume)
              ? Math.round(resp.data.time_data.dc2annularvolume * 1e4) / 1e4
              : 0;
            this.annularvolume = !isNaN(resp.data.time_data.annularvolume)
              ? Math.round(resp.data.time_data.annularvolume * 1e4) / 1e4
              : 0;
            this.cycle_volume = !isNaN(resp.data.time_data.cycle_volume)
              ? Math.round(resp.data.time_data.cycle_volume * 1e4) / 1e4
              : 0;
            this.active_surface_vol = !isNaN(resp.data.time_data.total_vol)
              ? Math.round(resp.data.time_data.total_vol * 1e4) / 1e4
              : 0;
            this.spm1 = !isNaN(resp.data.time_data.spm1)
              ? Math.round(resp.data.time_data.spm1 * 1e4) / 1e4
              : 0;
            this.spm2 = !isNaN(resp.data.time_data.spm2)
              ? Math.round(resp.data.time_data.spm2 * 1e4) / 1e4
              : 0;
            this.spm3 = !isNaN(resp.data.time_data.spm3)
              ? Math.round(resp.data.time_data.spm3 * 1e4) / 1e4
              : 0;
            this.dmea = !isNaN(resp.data.time_data.dmea)
              ? Math.round(resp.data.time_data.dmea * 1e4) / 1e4
              : 0;
            this.dbtm = !isNaN(resp.data.time_data.dbtm)
              ? Math.round(resp.data.time_data.dbtm * 1e4) / 1e4
              : 0;
            this.time = !isNaN(resp.data.time_data.time)
              ? Math.round(resp.data.time_data.time * 1e4) / 1e4
              : 0;

            try {
              this.time_show = !isNaN(resp.data.time_data.time)
                ? moment(parseInt(resp.data.time_data.time)).format(
                  "MMM DD yyyy HH:MM:SS"
                )
                : 0;
            } catch (error) { }

            this.annular_casing_vol = !isNaN(
              resp.data.time_data.annular_casing_vol
            )
              ? Math.round(resp.data.time_data.annular_casing_vol * 1e4) / 1e4
              : 0;
            this.tot_active_fluid_sys = !isNaN(
              resp.data.time_data.tot_active_fluid_sys
            )
              ? Math.round(resp.data.time_data.tot_active_fluid_sys * 1e4) / 1e4
              : 0;
            this.pumpoutput = !isNaN(resp.data.time_data.pumpoutput)
              ? Math.round(resp.data.time_data.pumpoutput * 1e4) / 1e4
              : 0;
              
            this.incl_data = !isNaN(resp.data.time_data.pumpoutput)
              ? Math.round(resp.data.time_data.incl_data * 1e4) / 1e4
              : this.inclination;

            this.inclination = this.incl_data;
            this.section_type = !isNaN(
              resp.data.well_geometry_data.section_type
            )
              ? Math.round(resp.data.well_geometry_data.section_type * 1e4) /
              1e4
              : 0;

            // this.totallength =
            //   this.dplength + this.hwdplength + this.dc1length + this.dc2length;
            this.totalcapacity =
              this.dpcapacity +
              this.hwdpcapacity +
              this.dc1capacity +
              this.dc2capacity;
              if(this.inclination < 0){
                this.$toast.error('Inclination  is in negative',{duration:1000,position:"top"})
                this.incldatanull = false
                this.inclination =  ''
                this.incl_data = ''
              }
          },
          (error) => {
            this.$toast.error("Something Wrong", {
              position: "top",
              duration: 1500,
            });
            this.vuserinp.vuserdata.vbittvd = "";
            this.vuserinp.vuserdata.vholetvd = "";
            this.bit_size = "";
            this.vholetvd = "";
            this.vholetmd = "";
            this.vholesize = "";
            this.idia = "";
            this.md = "";
            this.tvd = "";
            this.dplength = "";
            this.dpcapacity = "";
            this.hwdplength = "";
            this.hwdpcapacity = "";
            this.dc1length = "";
            this.dc1capacity = "";
            this.dc1volume = "";
            this.dc2length = "";
            this.dc2capacity = "";
            this.dc2volume = "";
            this.drillstringvolume = "";
            this.casingdplength = "";
            this.casingdpannularcapacity = "";
            this.casinghwdplength = "";
            this.casinghwdpannularcapacity = "";
            this.casingdc1length = "";
            this.casingdc1annularcapacity = "";
            this.openholedplength = "";
            this.ohdpannularcapacity = "";
            this.dpannularvolume = "";
            this.openholehwdplength = "";
            this.hwdpannularcapacity = "";
            this.hwdpannularvolume = "";
            this.openholedc1length = "";
            this.dc1annularcapacity = "";
            this.dc1annularvolume = "";
            this.openholedc2length = "";
            this.dc2annularcapacity = "";
            this.dc2annularvolume = "";
            this.openholevolume = "";
            this.annularvolume = "";
            this.cycle_volume = "";
            this.active_surface_vol = "";
            this.spm1 = "";
            this.spm2 = "";
            this.spm3 = "";
            this.dmea = "";
            this.dbtm = "";
            this.time = "";
            this.string_strokes = "";
            this.annular_casing_vol = "";
            this.tot_active_fluid_sys = "";
            this.totallength = "";
            this.totalcapacity = "";
            this.totalcasingdplength = "";
            this.totalcasingdpannularcapacity = "";
            this.totopenholelength = "";
            this.totopenholecapacity = "";
          }
        );
      if (this.incl_data < 10 && this.incl_data >= 0) {
        this.checked = false;
      } else if (this.incl_data == "" || this.incl_data == null) {
        this.incldatanull = false;
      } else {
        this.checked = true;
      }
    },
    takeOffSocket() {
      this.liveDisabled = false;
      if (this.$socket) {
        this.$socket.emit("unsubscribe", {
          logId: this.logId,
          topic: this.topic,
          id: this.id,
          clientId: this.$clientId,
        });
        this.sockets.unsubscribe(this.$clientId);
      }
      this.stopUpdation = false;
    },
    // This is to create the SVG of the line chart graph for Vertical
    async buildSVG() {
      var margin = {
        top: 10,
        right: this.width * 0.3,
        bottom: 50,
        left: 85,
      },
        width = this.width - margin.left - margin.right - 370,
        height = this.height - margin.top - margin.bottom - 150;
      let labelFontSize = 12;
      let tooltipFontSize = 10;
      //create base svg
      svg = select("#verticalKillsheet" + this.widgetId);
      if (svg) {
        svg.selectAll("*").remove();
        svg = "";
      } else {
        let verKillSheet = document.createElement("div");
        verKillSheet.setAttribute("id", "verticalKillsheet" + this.widgetId);
      }
      svg = select("#verticalKillsheet" + this.widgetId)
        .append("svg")
        .attr("width", width / 2 + margin.left + margin.right - 60)
        .attr("height", height + margin.top + margin.bottom)
        .attr("id", "verticalKillsheetSVG")
        .append("g")
        .attr("id", "verticalKillsheet1")
        .attr("transform", `translate(${margin.left + 20}, ${margin.top + 9})`);

      //create axis
      var X_array = this.Strokes,
        Y_array = this.Pressure;
      this.maxX = max(Object.keys(X_array), function (o) {
        return X_array[o];
      });
      this.maxY = max(Object.keys(Y_array), function (o) {
        return Y_array[o];
      });
      var x = scaleLinear().domain([this.minX, this.maxX]).range([0, width]);
      var y = scaleLinear().domain([this.minY, this.maxY]).range([height, 0]);
      var xAxis = axisBottom(x).ticks();
      var yAxis = axisLeft(y).ticks();
      //create grid axis
      const xAxisGrid = axisBottom(x).tickSize(-height).tickFormat('').ticks();
      const yAxisGrid = axisLeft(y).tickSize(-width).tickFormat("").ticks();
      var clip = svg
        .append("defs")
        .append("svg:clipPath")
        .attr("id", "clip")
        .append("svg:rect")
        .attr("width", width)
        .attr("height", height)
        .attr("x", 0)
        .attr("y", 0);
      // color: var(--textColor)
      svg
        .append("g")
        .attr("class", "x axis-grid")
        .attr("transform", "translate(0," + height + ")")
        .style("color", "var(--textColor)")
        .style("fill", "")
        .attr("stroke-opacity", 0.6)
        .call(xAxisGrid);
      svg
        .append("g")
        .attr("class", "y axis-grid")
        .style("color", "var(--textColor)")
        .attr("stroke-opacity", 0.6)
        .call(yAxisGrid);
      svg
        .append("g")
        .attr("class", "xAxis")
        .attr("transform", "translate(0," + height + ")")
        .style("font-size", this.xAxisLabelSize)
        .call(xAxis);
      svg
        .selectAll(".xAxis")
        .selectAll("text")
        .call(function (t) {
          t.each(function (d) {
            var self = select(this);
            var num = +self.text().replaceAll(',', '');
            var lbTodisp = Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num);
            self.text(lbTodisp);
          })
        }),
        svg.selectAll(".xAxis").selectAll("text").attr("fill", this.yAxisLabel);
      svg
        .append("g")
        .attr("class", "yAxis")
        .style("font-size", this.yAxisLabelSize)
        .call(yAxis);
      svg.selectAll(".yAxis").selectAll("text").attr("fill", this.yAxisLabel);
      svg
        .append("text")
        .attr("class", "x label")
        .attr("text-anchor", "end")
        .attr("x", width / 2 + 30)
        .attr("y", height + 40)
        .style("fill", "var(--textColor)")
        .style("font-size", labelFontSize)
        .text("Strokes (stks)".toUpperCase());

      svg
        .append("text")
        .attr("class", "y label")
        .attr("text-anchor", "end")
        .attr("dy", "-3.9em")
        .attr("dx", -height / 2 + 30)
        .attr("transform", "rotate(-90)")
        .style("fill", "var(--textColor)")
        .style("font-size", labelFontSize)
        .text("Pressure (psi)".toUpperCase());

      var x_array = this.Strokes,
        y_array = this.Pressure;
      var lowest = max(Object.keys(x_array), function (o) {
        return x_array[o];
      });
      var xy = [];
      for (var i = 0; i < this.StrokeLength; i++) {
        xy.push({ x: x_array[i], y: y_array[i] });
      }
      var slice = line()
        .x(function (d, i) {
          return x(d.x);
        })
        .y(function (d, i) {
          return y(d.y);
        });
      svg
        .append("path")
        .attr("d", slice(xy))
        .attr("class", "line")
        .style("stroke", "var(--textColor)")
        .attr("fill", "none");
      console.log("XY ", xy);
    },
    async buildHoriSVG() {
      var margin = {
        top: 10,
        right: this.width * 0.3,
        bottom: 50,
        left: 85,
      },
        width = this.width - margin.left - margin.right - 370,
        height = this.height - margin.top - margin.bottom - 150;
      let labelFontSize = 12;
      let tooltipFontSize = 10;
      //create base svg
      svg = select("#HoriKillsheet" + this.widgetId);
      if (svg) {
        svg.selectAll("*").remove();
        svg = "";
      } else {
        let HoriKillsheet = document.createElement("div");
        HoriKillsheet.setAttribute("id", "HoriKillsheet" + this.widgetId);
      }
      svg = select("#HoriKillsheet" + this.widgetId)
        .append("svg")
        .attr("width", width / 2 + margin.left + margin.right - 60)
        .attr("height", height + margin.top + margin.bottom)
        .attr("id", "HoriKillsheetSVG")
        .append("g")
        .attr("id", "HoriKillsheet1")
        .attr("transform", `translate(${margin.left + 20}, ${margin.top + 9})`);

      //create axis
      var X_array = this.Strokes,
        Y_array = this.Pressure;
      this.maxX = max(Object.keys(X_array), function (o) {
        return X_array[o];
      });
      this.maxY = max(Object.keys(Y_array), function (o) {
        return Y_array[o];
      });
      var x = scaleLinear().domain([this.minX, this.maxX]).range([0, width]);
      var y = scaleLinear().domain([this.minY, this.maxY]).range([height, 0]);
      var xAxis = axisBottom(x).ticks();
      var yAxis = axisLeft(y).ticks();
      //create grid axis
      const xAxisGrid = axisBottom(x).tickSize(-height).tickFormat('').ticks();
      const yAxisGrid = axisLeft(y).tickSize(-width).tickFormat("").ticks();
      var clip = svg
        .append("defs")
        .append("svg:clipPath")
        .attr("id", "clip")
        .append("svg:rect")
        .attr("width", width)
        .attr("height", height)
        .attr("x", 0)
        .attr("y", 0);
      svg
        .append("g")
        .attr("class", "x axis-grid")
        .attr("transform", "translate(0," + height + ")")
        .style("color", "var(--textColor)")
        .style("fill", "")
        .attr("stroke-opacity", 0.6)
        .call(xAxisGrid);
      svg
        .append("g")
        .attr("class", "y axis-grid")
        .style("color", "var(--textColor)")
        .attr("stroke-opacity", 0.6)
        .call(yAxisGrid);
      svg
        .append("g")
        .attr("class", "xAxis")
        .attr("transform", "translate(0," + height + ")")
        .style("font-size", this.xAxisLabelSize)
        .call(xAxis);
      svg
        .selectAll(".xAxis")
        .selectAll("text")
        .call(function (t) {
          t.each(function (d) {
            var self = select(this);
            var num = +self.text().replaceAll(',', '');
            var lbTodisp = Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num);
            self.text(lbTodisp);
          })
        })
        .attr("fill", "var(--textColor)");
      svg
        .append("g")
        .attr("class", "yAxis")
        .style("font-size", this.yAxisLabelSize)
        .call(yAxis);
      svg
        .selectAll(".yAxis")
        .selectAll("text")
        .attr("fill", "var(--textColor)");
      svg
        .append("text")
        .attr("class", "x label")
        .attr("text-anchor", "end")
        .attr("x", width / 2 + 30)
        .attr("y", height + 40)
        .style("fill", "var(--textColor)")
        .style("font-size", labelFontSize)
        .text("Strokes (stks)".toUpperCase());
      svg
        .append("text")
        .attr("class", "y label")
        .attr("text-anchor", "end")
        .attr("dy", "-3.9em")
        .attr("dx", -height / 2 + 30)
        .attr("transform", "rotate(-90)")
        .style("fill", "var(--textColor)")
        .style("font-size", labelFontSize)
        .text("Pressure (psi)".toUpperCase());

      var x_array = this.Strokes,
        y_array = this.Pressure;
      var lowest = max(Object.keys(x_array), function (o) {
        return x_array[o];
      });
      var xy = [];
      for (var i = 0; i < this.StrokeLength; i++) {
        xy.push({ x: x_array[i], y: y_array[i] });
        console.log("xy vals ", xy);
      }
      var slice = line()
        .x(function (d, i) {
          return x(d.x);
        })
        .y(function (d, i) {
          return y(d.y);
        });

      svg
        .append("path")
        .attr("d", slice(xy))
        .attr("class", "line")
        .style("stroke", "var(--textColor)")
        .attr("fill", "none");
    },
  },
  beforeUnmount() {
    this.data = [];
    this.completeData = [];
    this.takeOffSocket();
  },
  async mounted() {
    this.customer = this.$store.state.data.customer;
    let dtls = this.$store.state.disp.displays[this.displayId];
    this.$store.subscribe(async (mutation, state) => {
      if (
        mutation.type == "disp/setDisplay" &&
        this.displayId == mutation.payload.display &&
        this.id
      ) {
        this.customer = this.$store.state.data.customer;
        this.takeOffSocket();
        this.data = [];
        this.completeData = [];
        console.log("well name", mutation.payload);
        this.wellId = mutation.payload.wellId;
        this.wellboreId = mutation.payload.wellboreId;
        this.logId = mutation.payload.logId;
        this.logType = mutation.payload.logType;
        this.logTypeId = mutation.payload.logTypeId;
        this.status = mutation.payload.status;
        this.time_zone = mutation.payload.time_zone || "CST6CDT";

        console.log("~~~ time zone alert : mounted ", this.time_zone);
        moment.tz.setDefault(this.time_zone);
        let current = new Date();
        this.date =
          current.getFullYear() +
          "-" +
          String(current.getMonth() + 1).padStart(2, "0") +
          "-" +
          String(current.getDate()).padStart(2, "0") +
          " " +
          String(current.getHours()).padStart(2, "0") +
          ":" +
          String(current.getMinutes()).padStart(2, "0") +
          ":" +
          String(current.getSeconds()).padStart(2, "0");
        this.max = mutation.payload.max;
        try {
          this.time_show = moment(this.max).format("MMM DD yyyy HH:MM:SS");
          // this.refresh();
        } catch (error) { }
        if (
          this.wellId !== null &&
          this.wellboreId !== null &&
          this.customer !== null
        ) {
          await this.getapi("subscribe");
        }
      }
    });

    console.log("well name", dtls);
    if (
      dtls != null &&
      dtls.wellId != null &&
      dtls.wellboreId != null &&
      dtls.logId != null &&
      dtls.wellboreState != null
    ) {
      this.max = dtls.max;
      this.wellId = dtls.wellId;
      this.wellboreId = dtls.wellboreId;
      try {
        this.time_show = moment(this.max).format("MMM DD yyyy HH:MM:SS");
      } catch (error) { }
      if (
        this.wellId !== null &&
        this.wellboreId !== null &&
        this.customer !== null
      ) {
        await this.getapi("mounted");
      }
    }
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Crimson+Text:ital@1&display=swap");

.firtPart-in-result{
  width: 80%;
    height: fit-content;
    /* padding: 1px; */
    margin-right: 5px;
    margin-top: 20px;
    margin-bottom: 5px;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.w-14 {
  width: 3.5rem;
}

h4 {
  text-align: left;
}

.pump .pmpinp {
  background-color: black;
  padding-top: 3px;
  padding-bottom: 3px;
}

.pump input:focus-visible {
  outline: none !important;
}

.pumpdiv::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.pumpdiv::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.pumpdiv::-webkit-scrollbar-thumb {
  background: #888;
}

.pumpdiv::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.text-xxxs {
  font-size: 7px;
}

.text-xxs {
  font-size: 9px;
  text-transform: uppercase;
}

.text-xxxxs {
  font-size: 9px;
}

.text-xxxxxs {
  font-size: 8px;
}

.text-sx {
  font-size: 7px;
}

/* Toggle Button Design Start */
.colr {
  color: #61cba1;
}

.checkbox-labels {
  background-color: #7f7d7d;
  width: 40px;
  height: 18px;
  border-radius: 50px;
  position: relative;
  padding: 7px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkbox-labels .ball {
  background-color: #fff;
  width: 14px;
  height: 14px;
  position: absolute;
  left: 3px;
  top: 2px;
  border-radius: 50%;
  transition: transform 0.2s linear;
}

.checkboxs:checked+.checkbox-labels .ball {
  transform: translateX(20px);
}

.poup button {
  padding-right: 13px;
}

/* Toggle Button Design End */
/* Conclusion Button Design Start */
.conclusion_input {
  /* background: var(--colorBoxPopUpBg); */
  background: var(--wdigetBg);
  color: var(--textColor);
}

.text_theme {
  color: var(--textColor);
}

a.execute-btn {
  position: relative;
  display: inline-block;
  padding: 3px 10px;
  color: #03e9f4;
  font-size: 13px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  letter-spacing: 2px;
}

a.execute-btn:hover {
  background: #03e9f4;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #03e9f400, 0 0 25px #03e9f400, 0 0 50px #03e9f445, 0 0 100px #03e9f400;
}

a.execute-btn span {
  position: absolute;
  display: block;
}

a.execute-btn span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #03e9f4);
  animation: btn-anim1 1s linear infinite;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }

  50%,
  100% {
    left: 100%;
  }
}

a.execute-btn span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #03e9f4);
  animation: btn-anim2 1s linear infinite;
  animation-delay: 0.25s;
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }

  50%,
  100% {
    top: 100%;
  }
}

a.execute-btn span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
  animation: btn-anim3 1s linear infinite;
  animation-delay: 0.5s;
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }

  50%,
  100% {
    right: 100%;
  }
}

a.execute-btn span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
  animation: btn-anim4 1s linear infinite;
  animation-delay: 0.75s;
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }

  50%,
  100% {
    bottom: 100%;
  }
}

/* Conclusion Button Design End */
/* Pop Up CSS Start*/

.poup,
.hpoup {
  z-index: 999;
  position: absolute;
  top: 35%;
  left: 50%;
  background: var(--wdigetBg);
  color: var(--textColor);
  width: 90%;
  height: 85%;
  transform: translate(-50%, -40%);
  box-shadow: 3px 0px 12px 1px #e1f5fe;
}

/* Pop Up CSS End*/
.scroll {
  max-height: 350px;
  height: 350px;
  display: inline-block;
  overflow: scroll;
}

.scroll thead tr {
  position: sticky;
  top: 0;
  border: 1px solid #fff;
  box-shadow: 0 0 2px 2px rgb(255 255 255 / 40%);
  background-color: #000;
}

.cid {
  height: 355px;
}

.pmpselc {
  width: 110px !important;
  height: 22px !important;
  padding: 0 !important;
  border-width: 1px !important;
  border-top: 1px solid !important;
  padding-left:4px !important;
  margin-left: 10px;
  display: flex;
  align-items: center;
  font-size: 10px;
}

.precellslct {
  width: 100% !important;
  height: 16px !important;
  padding: 0 !important;
  border: 0 !important;
  padding: 2px 0px !important;
  margin-left: 0px;
  display: flex;
  align-items: start;
  font-size: 7.5px;
  color: #11e111 !important;
}

.precellslct.color-red {
  color: #ff5353 !important;
}

.chekbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 15px;
  width: 11px;
}

.checkmark {
  position: absolute;
  top: 6px;
  left: 12px;
  height: 11px;
  cursor: pointer;
  width: 11px;
  background-color: #000;
  border: 1px solid #fff;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

input.chekbox:checked~.checkmark:after {
  display: block;
}

.checkmark:after {
  left: 3px;
  top: -1px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 1.5px 1.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  cursor: pointer;
}

.chec {
  width: 50px !important;
  padding-left: 3px !important;
}

.lbl {
  display: inline;
  padding: 1px 10px;
}

.color-red {
  color: #ff5353;
}

.color-green {
  color: #11e111;
}

.color-orange {
  color: orange;
}

.color-drkorange {
  color: orange;
}

.color-purple {
  color: #fc2bfc;
}

.color-blue {
  color: #f5cbfa;
}

.color-pink {
  color: pink;
}

.border-green-300 {
  border-color: #11e111;
}

.slpmprate1 .checkmark,
.slpmprate1,
.slpmprate2 .checkmark,
.slpmprate2,
.slpmprate3 .checkmark,
.slpmprate3 {
  opacity: 0.5;
  pointer-events: none;
}

.dispokbtn {
  padding: 3px 15px !important;
  border: 1px solid #03e9f4 !important;
  outline: none !important;
  color: #03e9f4 !important;
  /* 20a4ab */
  max-width: 125px;
}

/* Displacement claculation butn css */
.dispcalcbtn {
  padding: 3px 8px !important;
  border: 1px solid #03e9f4 !important;
  outline: none !important;
  color: #03e9f4 !important;
  max-width: 125px;
}

.refresh {
  padding: 2px 8px !important;
  border: 1px solid #03e9f4 !important;
  outline: none !important;
  color: #03e9f4 !important;
  max-width: 125px;
}

.incdata {
  z-index: 999;
  position: absolute;
  top: 0%;
  left: 0%;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
}

.dispcalcnclbtn {
  padding: 3px 8px !important;
  border: 1px solid #f40303 !important;
  outline: none !important;
  color: #f40303 !important;
  max-width: 135px;
  margin-right: 10px;
}

.dispcalcpopupcl {
  z-index: 999;
  position: absolute;
  top: 0%;
  left: 0%;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
}

.dispop {
  position: absolute;
  top: 30%;
  left: 50%;
  /* background: var(--colorBoxPopUpBg); */
  background:var(--wdigetBg);
  color: var(--textColor);
  transform: translate(-50%, -40%);
}

.dispdata,
.horiDispdata {
  opacity: 0.5;
  pointer-events: none;
}

.minimize {
  visibility: hidden;
}

.minimizer {
  /* padding: 13px 0px 0 10px; */
  width: 40px;
  height: 40px;
  text-align: center;
  background: var(--activeTextColor);
  color: white;
  /* border-radius: 100% 0% 100% 0% / 100% 100% 0% 0%; */
  position: fixed !important;
  bottom: 50px;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 999;
  overflow: hidden;
}

.tick text {
  /* font-size: 8px !important; */
}

.pump th {
  font-weight: 500 !important;
}

.Child_Of_sendMail_modal {
  width: 60%;
  height: 65%;
  margin: auto;
  border-radius: 2px;
  /* background: var(--colorBoxPopUpBg); */
  background: var(--sidebarbg);
  color: var(--textColor);
}

.sendMail_modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: rgba(28, 29, 31, 0.7000000000000001);
  display: flex;
  z-index: 999999;
  align-items: center;
}
td input {
  background-color: var(--wdigetBg) !important;
}
.killsheet_main label + input {
  background-color: transparent !important;
}
.killsheet_main select, option{
  background-color: var(--wdigetBg) !important;
}
.colorOfKillTool{
  color:var(--textColor) !important;
}
.textColorClass{
  color:var(--textColor)
}
</style>
