<template>
  <div
    class="shadow-lg"
    :style="{
      height: this.height + 'px',
      width: this.width + 'px',
      overflow: 'hidden',
      background: darkDark !== 'white' ? this.cardBackground || 'var(--wdigetBg)' : this.cardBackground || 'var(--wdigetBg)',
      transition:'var(--transition)'
    }"
    v-bind:id="tempWidgetId"
    @drop="onDrop($event, 1)"
    @contextmenu="this.setContextIdWidget"
  
  >
    <HideShow
      :onMinMaxBck="onMinMax"
      :isMinimize="isMinimize"
      :displayId="this.displayId"
      :widgetId="this.widgetId"
      :backgroundId="this.backgroundId"
      :isHideShowVisible="this.isHideShowFun"
    />
    <VueDragResize
      v-for="(rect, index) in rects"
      :snapToGrid="false" :gridX="20"
      :key="rect.bgId"
      :id="rect.bgId"
      :w="rect.name == 'gauge' ? rect.width : rect.width"
      :h="rect.name == 'gauge' ? rect.width : rect.height"
      :x="rect.left"
      :y="rect.top"
      :parentW="width"
      :parentH="height"
      :axis="rect.axis"
      :isActive="active"
      :minw="rect.minw"
      :minh="rect.minh"
      :isDraggable="rect.draggable"
      :isResizable="rect.resizable"
      :parentLimitation="rect.parentLim"
      :aspectRatio="rect.aspectRatio"
      :z="rect.zIndex"
      :contentClass="rect.class"
      v-on:activated="activateEv(rect.widgetId)"
      v-on:deactivated="deactivateEv(rect.widgetId)"
      v-on:dragging="changePosition($event, rect.widgetId)"
      v-on:resizing="changeSize($event, rect.widgetId)"
      :class="rect.name == 'data-table' ? 'overflow-auto' : ''"
      :style="
        this.isShow ? { 'margin-top': '1.7rem' } : { 'margin-top': '0rem' }
      "
    >
      <div
        class="filler"
        :style="{
          backgroundColor: rect.name == 'Background' ? rect.color : '',
          backgroundColor: rect.color,
        }"
      >
        <component
          :is="rect.name.replace('-', '')"
          :key="index"
          :top="rect.top"
          :left="rect.left"
          :displayId="rect.displayId"
          :widgetId="rect.widgetId"
          :title="rect.title"
          :fullName="rect.fullName"
          :id="index"
          :widgetUnitL="rect.widgetUnitL"
          :widgetHeight="rect.height"
          :widgetWidth="rect.width"
          :titleSize="
            rect.name == 'numeric'
              ? rect.titleSize
              : rect.height / 15 || rect.width / 5
          "
          :value="rect.value"
          :valueSize="
            rect.name == 'numeric'
              ? rect.valueSize
              : rect.height / 18 || rect.width / 6
          "
          :unit="rect.unit"
          :unitSize="
            rect.name == 'numeric'
              ? rect.unitSize
              : rect.height / 21 || rect.width / 7
          "
          :autoFit="rect.autoFit"
          :width="rect.width"
          :height="rect.height * 1.05"
          :barWidth="rect.width - 60"
          :barHeight="rect.height - rect.height * 0.3"
          :barValue="rect.barValue"
          :rangeStart="rect.rangeStart"
          :alertColor="rect.alertColor"
          :alertColo="rect.alertColor"
          :blinkAlert="rect.blinkAlert"
          :alertStartRange="rect.alertStartRange"
          :alertEndRange="rect.alertEndRange"
          :rangeEnd="rect.rangeEnd"
          :interval="rect.interval"
          :borderColor="rect.borderColor"
          :borderWidth="rect.borderWidth"
          :borderDisplay="rect.borderDisplay"
          :titleColor="rect.titleColor"
          :valueColor="rect.valueColor"
          :unitColor="rect.unitColor"
          :unitDisplay="rect.unitDisplay"
          :titleDisplay="rect.titleDisplay"
          :valueDisplay="rect.valueDisplay"
          :decimalValue="rect.decimalValue"
          :cardBackground="rect.cardBackground"
          :isChanged="rect.isChanged"
          :barBreakPoints="rect.breakPoints"
          :fontSize="rect.width * 0.08"
          :squareDisplay="rect.squareDisplay"
          :gaugeStartAngle="rect.startAngle"
          :gaugeEndAngle="rect.endAngle"
          :bgColor="rect.bgColor"
          :valueDecimals="rect.valueDecimals"
          :niddleAngle="rect.niddleAngle"
          :valueTextColor="rect.valueTextColor"
          :titleTextColor="rect.titleTextColor"
          :needleColor="rect.needleColor"
          :labelFontPercent="rect.labelFontPercent"
          :gaugeBreakPoints="rect.breakPoints"
          :arcCount="rect.arcCount"
          @dblclick="this.onDblClick($event, index, rect)"
        ></component>
      </div>
    </VueDragResize>
  </div>
</template>

<script>
import VueDragResize from "../../../components/DragAndDrop/vue-drag-resize.vue";
import label from "../Label/LabelWidget.vue";
import numeric from "../Numeric/NumericWidget.vue";
import verticalbar from "../VerticalBar/VerticalBarWidget.vue";
import gauge from "../Gauge/GaugeWidget.vue";

import HideShow from "../../Navbars/HideShow.vue";
var targetId = null;
document.addEventListener(
  "dragenter",
  function (event) {
    targetId = event.target.id;
    //
  },
  false
);
document.addEventListener(
  "dragend",
  function (event) {
    targetId = event.target.id;
    //
  },
  false
);
export default {
  name: "Background-widget",
  components: {
    VueDragResize,
    label,
    numeric,
    verticalbar,
    gauge,
    HideShow,
  },
  props: {
    onMinMax: Function,
    index: Number,
    displayId: [String, Number],
    height: {
      type: [String, Number],
      default: "200",
    },
    width: {
      type: [String, Number],
      default: "350",
    },
    left: {
      type: [String, Number],
      default: "200",
    },
    right: {
      type: [String, Number],
      default: "350",
    },
    cardBackground: {
      type: [String, Number],
      default: "",
    },
    displayId: {
      type: [String, Number],
      default: "",
    },
    widgetId: {
      type: [String, Number],
      default: "",
    },
    onMinMax: Function,
    isMinimize: {
      type: Boolean,
      default: false,
    },
    backgroundId: {
      type: [String, Number],
      default: "",
    },
    openPropertyFn: Function,
  },
  data() {
    return {
      wId: null,
      tempWidgetId: null,
      isShow: false,
      active: true,
    };
  },
  beforeMount() {
    let id = "id" + Math.random().toString(16).slice(2);
    this.tempWidgetId = id;
  },
  mounted() {
    this.tempWidgetId = this.backgroundId || this.tempWidgetId;
  },
  methods: {
    isHideShowFun(isShow) {
      this.isShow = isShow;
      return isShow;
    },
    dark() {
      this.$emit("dark");
    },

    light() {
      this.$emit("light");
    },
    onDblClick(e, index, rect) {
      e.stopPropagation();
      this.openPropertyFn(index, rect);
    },
    setContextIdWidget(e) {
      this.$store.dispatch("disp/setContextId", {
        contextId: this.displayId,
        contextWidgetId: this.widgetId,
        contextType: "widget",
      });
      // e.preventDefault()
      // e.stopPropagation();
    },
    onDrop(event) {
      let ondropTargetId = event.target.id;
      //
      if (targetId === ondropTargetId) {
        //
        //  const elProps = event.dataTransfer.getData('elProps');
        let elProps = JSON.parse(event.dataTransfer.getData("elProps"));
        //
        let id = "id" + Math.random().toString(16).slice(2);
        this.wId = id;
        if (elProps.name != "display") {
          // elProps.widgetId = this.tempWidgetId;
          elProps.widgetId = "bg" + Math.random().toString(16).slice(2);
          elProps.bgId = this.tempWidgetId;
          // elProps.bgId = "bg" + Math.random().toString(16).slice(2);
        }
        //
        elProps.displayId = this.displayId;
        //
        const height = elProps.height;
        const width = elProps.width;
        const offsetY = elProps.offsetY;
        const offsetX = elProps.offsetX;
        //
        //
        //
        let dragX = event.pageX;
        let dragY = event.pageY;
        //
        //
        //
        //
        //   "why inside dragend ",
        //   document.getElementById(event.target.id)
        // );
        let obj = document.getElementById(event.target.id);
        if (obj && obj.offsetParent) {
          do {
            //
            dragX = dragX - obj.offsetLeft;
            //
            dragY = dragY - obj.offsetTop;
            //
          } while ((obj = obj.offsetParent));
        }
        dragY = dragY - offsetY;
        dragX = dragX - offsetX;
        //
        //
        this.test3(dragX, dragY, elProps);
      } else {
        //
      }
    },
    test3(gx, gy, elProps) {
      //
      //

      elProps.displayId = this.displayId;
      this.$store.dispatch("rect/addNewRect", {
        x: gx,
        y: gy,
        elProps: elProps,
      });

      // this.$store.dispatch("prop/addNewProperty", {
      //   x: gx,
      //   y: gy,
      //   elProps: elProps,
      // });
    },
    activateEv(index) {
      //
      this.activatedIndex = index;
      this.$store.dispatch("rect/setActive", { id: index, type: "bg" });
    },
    deactivateEv(index) {
      //
      this.$store.dispatch("rect/unsetActive", { id: index, type: "bg" });
    },
    changePosition(newRect, index) {
      let payload = {};

      payload.widgetId = index;
      payload.type = "bg";
      payload.height = newRect.height;
      payload.width = newRect.width;
      payload.left = newRect.left;
      payload.top = newRect.top;
      //
      // this.$store.dispatch("disp/setTop", payload);
      // this.$store.dispatch("disp/setLeft", payload);
      // this.$store.dispatch("disp/setWidth", payload);
      // this.$store.dispatch("disp/setHeight", payload);
      this.$store.dispatch("rect/setDimensions", payload);
    },
    changeSize(newRect, index) {
      this.changePosition(newRect, index);
    },
    changeProperties(newRect, index) {
      this.changePosition(newRect, index);
    },
    noDisplays(rect) {
      return (
        rect.name != "display" &&
        rect.displayId == this.displayId &&
        rect.bgId == this.tempWidgetId
      );
    },
    openProperty(index, config) {
      this.$store.dispatch("rect/toggleRightSideBar", {
        id: index,
        value: config,
      });
    },
  },
  computed: {
    rects() {
      // return null;
      // return this.$store.state.rect.rects;
      let allRects = this.$store.state.rect.rects;
      let nonDisplays = allRects.filter(this.noDisplays);
      //
      return allRects.filter(this.noDisplays);
    },
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
  },
};
</script>
<style scoped>
.content-container {
  position: absolute !important;
}
</style>
