<template>
    <div :id="'vTrend' + widgetId" class="bg-white">

    </div>
</template>

<script>
import * as d3 from 'd3';
export default {
    name: 'TrendV',
    components: {

    },
    data() {
        return {
            selector: '#vTrend' + this.widgetId,
            dataSource: [],
            data: [],
            svg: null,
            mainGroup: null,
            scaleX: null,
            interval: null,
            options: {
                width: this.width,
                height: this.height,
                margins: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                },
                MAX_LENGTH: 100,
                duration: 500,
                color: d3.schemeCategory10
            }
        }
    },
    props: {
        height: {
            type: [Number, String],
            default: '50',
        },
        width: {
            type: [Number, String],
            default: '50',
        },
        widgetId: String,
        divid: String,
        displayId: String,
        plotConfigProps: {
            type: Object,
            default: {},
        },
    },
    mounted() {
        this.init()
    },
    methods: {
        init: function () {
            var el = d3.select(this.selector);
            if (el.empty()) {
                console.warn('init(): Element for "' + this.selector + '" selector not found');
                return;
            }

            

            this.seedData();

            this.draw();
            if (this.interval)
                clearInterval(this.interval);
            this.interval = setInterval(this.updateData, this.options.duration);
        },

        updateData: function () {
            var now = new Date();
            var lineData = {
                time: now,
                x: this.randomNumberBounds(0, 5),
                y: this.randomNumberBounds(0, 2.5),
                z: this.randomNumberBounds(0, 10)
            };
            this.dataSource.push(lineData);
            if (this.dataSource.length > 30) {
                this.dataSource.shift();
            }
            this.draw();
        },

        draw: function () {
            var self = this;

            // Based on https://bl.ocks.org/mbostock/3884955
            self.data = ["x", "y", "z"].map(function (c) {
                return {
                    label: c,
                    values: self.dataSource.map(function (d) {
                        return {
                            time: +d.time,
                            value: d[c]
                        };
                    })
                };
            });

            var transition = d3.transition().duration(this.options.duration).ease(d3.easeLinear),
                xScale = d3.scaleTime().rangeRound([0, this.width - this.options.margins.left - this.options.margins.right]),
                yScale = d3.scaleLinear().rangeRound([this.height - this.options.margins.top - this.options.margins.bottom, 0]),
                zScale = d3.scaleOrdinal(this.options.color);

            var xMin = d3.min(self.data, function (c) { return d3.min(c.values, function (d) { return d.time; }) });
            var xMax = new Date(new Date(d3.max(self.data, function (c) {
                return d3.max(c.values, function (d) { return d.time; })
            })).getTime() - 2 * this.options.duration);
            //})).getTime());

            xScale.domain([xMin, xMax]);
            yScale.domain([
                d3.min(self.data, function (c) { return d3.min(c.values, function (d) { return d.value; }) }),
                d3.max(self.data, function (c) { return d3.max(c.values, function (d) { return d.value; }) })
            ]);
            zScale.domain(self.data.map(function (c) { return c.label; }));

            var line = d3.line()
                .curve(d3.curveBasis)
                .x(function (d) { return xScale(d.time); })
                .y(function (d) { return yScale(d.value); });

            var svg = d3.select(this.selector).selectAll("svg").data([this.data]);
            var gEnter = svg.enter().append("svg")
                .attr('xmlns', 'http://www.w3.org/2000/svg')
                .attr("width", this.width)
                .attr("height", this.height)
                .append("g")
                .attr('transform', 'translate(' + this.options.margins.left + ',' + this.options.margins.top + ')');
            gEnter.append("g").attr("class", "axis x");
            gEnter.append("g").attr("class", "axis y");

            gEnter.append("defs").append("clipPath")
                .attr("id", "clip")
                .append("rect")
                .attr("width", this.width - this.options.margins.left - this.options.margins.right)
                .attr("height", this.height - this.options.margins.top - this.options.margins.bottom);

            gEnter.append("g")
                .attr("class", "lines")
                .attr("clip-path", "url(#clip)")
                .selectAll(".data")
                .data(this.data)
                .enter()
                .append("path")
                .attr("class", "data");

            var legendEnter = gEnter.append("g")
                .attr("class", "legend")
                .attr("transform", "translate(" + (this.width - this.options.margins.right - this.options.margins.left - 75) + ",25)");
            legendEnter.append("rect")
                .attr("width", 50)
                .attr("height", 75)
                .attr("fill", "#ffffff")
                .attr("fill-opacity", 0.7);
            legendEnter.selectAll("text")
                .data(this.data)
                .enter()
                .append("text")
                .attr("y", function (d, i) { return (i * 20) + 25; })
                .attr("x", 5)
                .attr("fill", function (d) { return zScale(d.label); });

            var g = svg.select("g");

            g.select("g.axis.x")
                .attr("transform", "translate(0," + (this.height - this.options.margins.bottom - this.options.margins.top) + ")")
                .transition(transition)
                .call(d3.axisBottom(xScale).ticks(5));

            g.select("g.axis.y")
                .transition(transition)
                .attr("class", "axis y")
                .call(d3.axisLeft(yScale));

            g.select("defs clipPath rect")
                .transition(transition)
                .attr("width", this.width - this.options.margins.left - this.options.margins.right)
                .attr("height", this.height - this.options.margins.top - this.options.margins.bottom);

            g.selectAll("g path.data")
                .data(this.data)
                .style("stroke", function (d) { return zScale(d.label); })
                .style("stroke-width", 1)
                .style("fill", "none")
                .transition()
                .duration(this.options.duration)
                .ease(d3.easeLinear)
                .on("start", tick);

            g.selectAll("g .legend text")
                .data(this.data)
                .text(function (d) {
                    return d.label.toUpperCase() + ": " + d.values[d.values.length - 1].value;
                });

            // For transitions https://bl.ocks.org/mbostock/1642874
            let duration = this.options.duration
            function tick() {
                // Redraw the line.
                d3.select(this)
                    .attr("d", function (d) { return line(d.values); })
                    .attr("transform", null);

                // Slide it to the left.
                var xMinLess = new Date(new Date(xMin).getTime() - duration);
                d3.active(this)
                    .attr("transform", "translate(" + xScale(xMinLess) + ",0)")
                    .transition();
            }
        },

        clearChart: function () {
            var el = d3.select(this.selector);
            if (el.empty()) {
                console.warn('clearChart(): Element for "' + this.selector + '" selector not found');
                return;
            }

            // clear element
            el.html("");
        },

        seedData: function () {
            var now = new Date();
            for (var i = 0; i < this.options.MAX_LENGTH; ++i) {
                this.dataSource.push({
                    time: new Date(now.getTime() - ((this.options.MAX_LENGTH - i) * this.options.duration)),
                    x: this.randomNumberBounds(0, 5),
                    y: this.randomNumberBounds(0, 2.5),
                    z: this.randomNumberBounds(0, 10)
                });
            }
        },

        randomNumberBounds: function (min, max) {
            return Math.floor(Math.random() * max) + min;
        }
    },
    watch: {
        width(newValue) {
            d3.select(this.selector).selectAll("svg")
        },
        height(newValue) {
            this.init()
        }
    }
}
</script>

<style>
</style>