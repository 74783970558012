<template>
  <nav
    class="right_bg close-right-sidebar-16rem z-40 md:right-0 md:block md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl flex flex-wrap items-center justify-between relative z-10"
     v-bind:style="{ right: toggle_right_Sidebar + 'rem' }">
    <!-- right_bg -->
    <div class="">
      <div class="flex bg-emerald-500 justify-between px-2 py-0 mneminics_mt">
        <div class="righ_side_head_">
          <svg class="twobarsRight" xmlns="http://www.w3.org/2000/svg" width="12" height="4" viewBox="0 0 12 4"
            fill="none" data-v-6a52651e="">
            <path d="M11.3333 0H0.666626V1.33333H11.3333V0ZM0.666626 4H11.3333V2.66667H0.666626V4Z" fill="#777777"
              data-v-6a52651e=""></path>
          </svg>
          <p class="sidebar-heading">Radial Gauge</p>
        </div>
        <div>
          <svg v-on:click="closeNavigation" class="cursor-pointer ml-auto mt-0 w-2 left_close_btn"
            style="text-align: right" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
            <path id="close_2_" data-name="close (2)"
              d="M8.874,7.636,14.715,1.8A.972.972,0,1,0,13.341.421L7.5,6.262,1.659.421A.972.972,0,0,0,.285,1.8L6.126,7.636.285,13.477a.972.972,0,1,0,1.374,1.374L7.5,9.01l5.841,5.841a.972.972,0,0,0,1.374-1.374Zm0,0"
              transform="translate(0 -0.136)" fill="#fff" />
          </svg>
        </div>
      </div>
    </div>

    <div class="pt-0_5 pr-0_5 pl-0_5 pb-0_5 prop_mx_height">
      <div v-on:click="toggle_height_handler_one" class="flex bg-right_ justify-between pt-4 pl-5 pb-4"
        >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/genaral_settings.png" alt="" />
            General Settings
          </p>
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureOne"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>
        </div>
      </div>

      <div v-bind:style="{ display: isExpandFeatureOne ? '' : 'none' }" class="expand-right-sidebar-feature-one">
        <div class="pb-3">
          <div class="custom-radio-wrap mb-4">
            <div class="flex flex-col mt-3">
              <p class="label_heading">Display Name</p>
              <input type="text" class="h-9 border-0 block w-5/12 fields font-color"
                @input="changeTitleHandler($event)"
                :value="fullName" />
            </div>

            <div class="flex flex-col mt-3">
              <p class="label_heading">Mnemonic</p>
              <select id="mnemonic" class="h-9 border-0 block w-5/12 fields font-color left-0"
                 @change="mnemonicChangeHandler($event)">
                <option>{{ title }}</option>
                <option :key="index" :value="tag" v-for="(tag, index) in tags">
                  {{ tag }}
                </option>
              </select>
              <span class="tooltip text-center" v-if="isHovering" :class="{ hovering: isHovering }">
                {{ title }}
              </span>
            </div>
            <div class="flex flex-col mt-3">
              <p class="label_heading">Unit</p>
              <input type="text" class="h-9 border-0 block w-5/12 fields font-color"
               :value="unit" />
            </div>
          </div>
        </div>
      </div>

      <div v-on:click="toggle_height_handler_two" class="flex bg-black justify-between bg-right_ pt-5 pl-5 pb-4"
       >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/note.png" alt="" />
            Appearance
          </p>
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureTwo"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>
        </div>
      </div>

      <div v-bind:style="{ display: isExpandFeatureTwo ? '' : 'none' }" class="expand-right-sidebar-feature-one">
        <div class="pb-5 pt-3">
          <div class="form-group-checkbox">
            <label class="w-100 flex font-thin">
              <span class="label_heading"> Square Gauge </span>
              <input class="ml-auto leading-tight" type="checkbox" @click="toggleDisplayHandler()"
                :checked="squareDisplay" />
            </label>

            <!-- <input
                class="checkbox"
                type="checkbox"
                id="squareDisplay"
                @click="toggleDisplayHandler()"
                :checked="squareDisplay"
              />
              <label class="check-label label_heading" for="squareDisplay"
                >Square Gauge</label
              > -->
          </div>
          <div class="flex flex-col mt-3">
            <p class="label_heading">Decimals</p>
            <input type="number" min="0" max="6" class="h-9 border-0 block w-5/12 fields font-color"
              @input="decimalValueHandler($event)" :value="valueDecimals"
              />
          </div>
          <div class="flex items-center justify-between mt-3">
            <p class="label_heading">Label Font % {{ labelFontPercent }}</p>
            <input type="range" min="1" max="10" class="h-9 border-0 block w-5/12" @input="labelFontValueHandler($event)"
              :value="labelFontPercent" />
          </div>
          <div class="flex items-center justify-between mt-3">
            <p class="label_heading">Needle</p>
            <input type="color" min="0" max="13" id="needleColor"
              class="h-9 border-0 input-bg block w-5/12 rounded-md font-color" @input="needleColorValueHandler($event)"
              :value="needleColor || '#ffffff'" />
          </div>
          <div class="flex items-center justify-between mt-3">
            <p class="label_heading">Title</p>
            <input type="color" min="0" max="13" id="titleColor"
              class="h-9 border-0 input-bg block w-5/12 rounded-md font-color" @input="colorValueHandler($event)"
              :value="titleTextColor || '#ffffff'" />
          </div>
          <div class="flex items-center justify-between mt-3">
            <p class="label_heading">Value</p>
            <input type="color" min="0" max="13" id="valueColor"
              class="h-9 border-0 input-bg block w-5/12 rounded-md font-color" @input="valueColorValueHandler($event)"
              :value="valueTextColor || '#ffffff'" />
          </div>
          <div class="flex items-center justify-between mt-3">
            <p class="label_heading">Background</p>
            <input type="color" id="bgColor" class="h-9 border-0 input-bg block w-5/12 rounded-md font-color"
              @input="bgColorValueHandler($event)" :value="bgColor" />
          </div>

          <div class="flex flex-col mt-3">
            <p class="label_heading">Start Angle</p>
            <input type="number" min="-30" max="90" class="h-9 border-0 block w-5/12 fields font-color"
              @input="changeStartAngleHandler($event)" :value="startAngle"
               />
          </div>
          <div class="flex flex-col mt-3">
            <p class="label_heading">End Angle</p>
            <input type="number" min="90" max="360" class="h-9 border-0 block w-5/12 fields font-color"
              @input="changeEndAngleHandler($event)" :value="endAngle"
              />
          </div>
        </div>
      </div>

      <div v-on:click="toggle_height_handler_three" class="flex bg-right_ bg-black justify-between pt-5 pl-5 pb-4"
       >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/shared_vision.png" alt="" />
            Visibility
          </p>
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureThree"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>
        </div>
      </div>

      <div v-bind:style="{ display: isExpandFeatureThree ? '' : 'none' }" class="expand-right-sidebar-feature-one">
        <div class="px-3 pt-3">
          <div>
            <div class="form-group-checkbox">
              <label class="w-100 flex font-thin">
                <span class="label_heading"> Title </span>
                <input class="ml-auto leading-tight" type="checkbox" @click="toggleTitleVisibiltyHandler()"
                  :checked="titleDisplay" />
              </label>
              <!-- <input
                class="checkbox"
                type="checkbox"
                id="menu1"
                @click="toggleTitleVisibiltyHandler()"
                :checked="titleDisplay"
              />
              <label class="check-label label_heading" for="menu1">Title</label> -->
            </div>

            <div class="form-group-checkbox">
              <label class="w-100 flex font-thin">
                <span class="label_heading"> Border </span>
                <input class="ml-auto leading-tight" type="checkbox" @click="toggleBorderVisibiltyHandler()"
                  :checked="borderDisplay" />
              </label>
            </div>
          </div>
        </div>
      </div>

      <div v-on:click="toggle_height_handler_four" class="flex bg-right_ justify-between pt-4 pl-5 pb-4"
       >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/range_.png" alt="" />
            Ranges
          </p>
          <!-- <p class="sidebar-label">Ranges</p> -->
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureFour"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>
        </div>
      </div>

      <div v-bind:style="{ display: isExpandFeatureFour ? '' : 'none' }" class="expand-right-sidebar-feature-one">
        <div class="pb-3">
          <div class="custom-radio-wrap mb-4">
            <div class="flex flex-col mt-3">
              <p class="label_heading">Start Range</p>
              <input type="number" min="-100"  class="h-9 border-0 block w-5/12 fields font-color"
                @input="changeStartRangeHandler($event)"
                :value="rangeStart" />
            </div>
            <div class="flex flex-col mt-3">
              <p class="label_heading">End Range</p>
              <input type="number" :min="rangeStart" class="h-9 border-0 block w-5/12 fields font-color"
                 @input="changeEndRangeHandler($event)"
                :value="rangeEnd" />
            </div>
            <!-- <div class="flex flex-col mt-3">
              <p class="label_heading">Interval</p>
              <input type="number" class="h-9 border-0 block w-5/12 fields font-color"
                :class="darkDark ? 'input-bg' : 'input-bg_light'" @input="intervalValueHandler($event)"
                :value="interval" />
            </div> -->
          </div>
        </div>
      </div>

      <div v-on:click="toggle_height_handler_five" class="flex bg-right_ bg-black justify-between pt-5 pl-5 pb-4"
        >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/alert.png" alt="" />
            Alarm
          </p>
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureFive"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>
        </div>
      </div>
      <div v-bind:style="{ display: isExpandFeatureFive ? '' : 'none' }" class="expand-right-sidebar-feature-one">
        <div class="pb-3 pt-3">
          <div class="flex items-center justify-between mt-3">
            <label for="blink" class="label_heading">Blink Alert</label>
            <input id="blink" type="checkbox" class="focus:ring-indigo-500 label_heading border-0 block"
              @input="changeBlinkAlert($event)" :checked="blinkAlert"  />
          </div>
          <div class="flex items-center justify-between mt-3">
            <p class="label_heading">Alert Color</p>
            <input type="color" id="alertColor" class="h-9 border-0 input-bg block w-5/12 rounded-md font-color"
              @input="changeAlertColor($event)" :value="alertColor || '#ffffff'" />
          </div>

          <div class="flex flex-col mt-3">
            <p class="label_heading">Start Range</p>
            <input type="number" class="h-9 border-0 block w-5/12 fields font-color" @input="changeStartRange($event)"
              :value="alertStartRange"  />
          </div>

          <div class="flex flex-col mt-3">
            <p class="label_heading">End Range</p>
            <input type="number" class="h-9 border-0 block w-5/12 fields font-color" @input="changeEndRange($event)"
              :value="alertEndRange" />
          </div>
          <br />

          <!-- <h5 class="label_heading">Add Breakpoint</h5> -->

          <div :key="index" v-for="(breakPoint, index) in breakPoints">
            <div class="flex items-center justify-between mt-3">
              <p class="label_heading">Breakpoint value</p>
              <input type="number" class="h-9 border-0 block w-5/12 rounded-md font-color"
                :class="darkDark ? 'input-bg' : 'input-bg_light'"
                @input="breakpointValueHandler($event, index ? index : 0)" :value="breakPoint.value" />
            </div>
            <!-- :value="breakPoint.value" -->

            <div class="flex items-center justify-between mt-3">
              <p class="label_heading">Breakpoint color</p>
              <input type="color" class="h-9 border-0 block w-5/12 rounded-md font-color"
                :class="darkDark ? 'input-bg' : 'input-bg_light'"
                @input="breakpointColorHandler($event, index ? index : 0)" :value="breakPoint.color || '#ffffff'" />
            </div>
            <!-- :value="breakPoint.color" -->
          </div>
          <!-- <button style="margintop: 5px"
            class="bg-green-500 text-white active:bg-pink-600 font-thin uppercase btn_txt rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150 m-auto flex justify-center px-4 py-0.5 w-9/12 mx-auto"
            type="button" @click="addBarRangeAndColor()">
            Add
          </button>

          <button v-if="breakPoints && breakPoints.length > 1" style="margin-top: 10px"
            class="bg-red-500 text-white active:bg-pink-600 font-thin uppercase btn_txt rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150 m-auto flex justify-center px-4 py-0.5 w-9/12 mx-auto"
            type="button" @click="removeBreakPoints()">
            Remove
          </button> -->
        </div>
      </div>
      <UpDownButtons :isExpandFeatureSix="isExpandFeatureSix" :toggle_height_handler_six="toggle_height_handler_six"
        :id="id" :displayId="properties.displayId" :widgetId="properties.widgetId" :closePropertyFile="closeNavigation" />
    </div>
  </nav>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/solid";

import api from "../../api/services";
import UpDownButtons from "../UpDownButtons/UpDownButtons";

export default {
  name: "circular-properties",
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    UpDownButtons,
  },
  props: {
    widgetId: String,
    displayId: String,
    toggle_right_Sidebar: {
      type: String,
      default: "",
    },
    toggle_right_margin: {
      type: String,
      default: "",
    },
    id: "",
    properties: {},
    closeNavigation: Function,
  },
  data() {
    return {
      idx: -1,
      breakPoint: "",
      breakPointColor: "",

      Dtags: [],
      isExpandFeatureOne: true,
      isExpandFeatureTwo: false,
      isExpandFeatureThree: false,
      isExpandFeatureFour: false,
      isExpandFeatureFive: false,
      isExpandFeatureSix: false,
    };
  },

  beforeMount() {
    let rects = this.$store.state.rect.rects;
    if (typeof rects != "undefined" && rects.length > 0) {
      for (let i = 0; i < rects.length; i++) {
        if (
          rects[i].widgetId == this.widgetId &&
          rects[i].displayId == this.displayId
        )
          this.idx = i;
      }
    }
  },
  computed: {
    tags() {
      // this.activeDisplay = this.$store.state.data.selectedDisplay;
      let display = this.$store.state.disp.selectedDisplay;
      //
      let displayobj = this.$store.state.disp.displays;
      //
      if (displayobj[display]) {
        this.Dtags = displayobj[display].tags;
        return displayobj[display].displayTags;
      } else {
        return [];
      }
      // return displayobj.tags;
    },
    title() {
      return this.$store.state.rect.rects[this.idx].title || "";
    },
    fullName() {
      return this.$store.state.rect.rects[this.idx].fullName || "";
    },
    unit() {
      return this.$store.state.rect.rects[this.idx].unit;
    },
    squareDisplay() {
      return this.$store.state.rect.rects[this.idx].squareDisplay;
    },
    valueDecimals() {
      return this.$store.state.rect.rects[this.idx].valueDecimals;
    },
    labelFontPercent() {
      return this.$store.state.rect.rects[this.idx].labelFontPercent;
    },
    needleColor() {
      return this.$store.state.rect.rects[this.idx].saveNeedleColor;
    },
    titleTextColor() {
      return this.$store.state.rect.rects[this.idx].saveTitleColor ||
      getComputedStyle(document.documentElement).getPropertyValue('--textColor')
    },
    valueTextColor() {
      return this.$store.state.rect.rects[this.idx].saveValueColor ||
      getComputedStyle(document.documentElement).getPropertyValue('--textColor')
    },
    bgColor() {
      return (
        this.$store.state.rect.rects[this.idx].saveColor ||
        getComputedStyle(document.documentElement).getPropertyValue('--defaultBgOfRadialWidget')
      );
    },
    titleDisplay() {
      return this.$store.state.rect.rects[this.idx].titleDisplay;
    },
    borderDisplay() {
      return this.$store.state.rect.rects[this.idx].borderDisplay;
    },
    startAngle() {
      return this.$store.state.rect.rects[this.idx].startAngle;
    },
    endAngle() {
      return this.$store.state.rect.rects[this.idx].endAngle;
    },
    rangeStart() {
      return this.$store.state.rect.rects[this.idx].rangeStart;
    },
    rangeEnd() {
      return this.$store.state.rect.rects[this.idx].rangeEnd;
    },
    interval() {
      return this.$store.state.rect.rects[this.idx].interval;
    },
    blinkAlert() {
      return this.$store.state.rect.rects[this.idx].blinkAlert;
    },
    alertColor() {
      return this.$store.state.rect.rects[this.idx].alertColor || "#ff0000";;
    },
    alertStartRange() {
      return this.$store.state.rect.rects[this.idx].alertStartRange;
    },
    alertEndRange() {
      return this.$store.state.rect.rects[this.idx].alertEndRange;
    },
    breakPoints() {
      return this.$store.state.rect.rects[this.idx].breakPoints;
    },
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
  },

  methods: {
    getIndex(state, widgetId, displayId) {
      let index = -1;
      for (let i = 0; i < state.rects.length; i++) {
        if (
          state.rects[i].widgetId == widgetId &&
          state.rects[i].displayId == displayId
        ) {
          index = i;
          return index;
        }
      }
    },
    addBarRangeAndColor() {
      let idx = this.getIndex(
        this.$store.state.rect,
        this.properties.widgetId,
        this.properties.displayId
      );
      let gaugeBreakPoints =
        this.$store.state.rect.rects[idx].breakPoints || [];

      gaugeBreakPoints = [
        ...gaugeBreakPoints,
        { value: parseInt(this.breakPoint), color: this.breakPointColor },
      ];
      // sorting
      function compare(a, b) {
        if (parseFloat(a.value) < parseFloat(b.value)) {
          return -1;
        }
        if (parseFloat(a.value) > parseFloat(b.value)) {
          return 1;
        }
        return 0;
      }
      gaugeBreakPoints = gaugeBreakPoints.sort(compare);

      this.$store.dispatch("rect/breakpointAdd", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        isVerticalBar: false,
        value: gaugeBreakPoints,
      });
    },
    removeBreakPoints() {
      this.$store.dispatch("rect/breakPointChange", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
      });

      this.$store.dispatch("rect/breakpointRemove", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
      });
    },

    breakpointValueHandler(e, index) {
      let id = 0;
      for (let i in this.$store.state.rect.rects) {
        if (this.id == this.$store.state.rect.rects[i].widgetId) {
          id = i;
          break;
        }
      }

      if (this.$store.state.rect.rects[id].breakPoints.length - 1 != index) {
        this.$store.dispatch("rect/breakPointChange", {
          id: this.widgetId,
          displayId: this.displayId,
        });
      }

      this.$store.dispatch("rect/breakpointValueHandler", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
        breakPointIndex: index,
      });
    },
    breakpointColorHandler(e, index) {
      let id = 0;
      for (let i in this.$store.state.rect.rects) {
        if (this.id == this.$store.state.rect.rects[i].widgetId) {
          id = i;
          break;
        }
      }
      if (this.$store.state.rect.rects[id].breakPoints.length - 1 != index) {
        this.$store.dispatch("rect/breakPointChange", {
          id: this.widgetId,
          displayId: this.displayId,
        });
      }

      this.$store.dispatch("rect/breakpointColorHandler", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
        breakPointIndex: index,
      });
    },

    async mnemonicChangeHandler(e) {
      this.$store.dispatch("rect/gaugeMemonicChange", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: this.Dtags[this.tags.indexOf(e.target.value)],
        fullName: e.target.value,
      });
      // let tagValue = await api.WellServices.getDataForTags({
      //   fields: e.target.value,
      // });

      // // alert(JSON.stringify(tagValue.data.rows[0][e.target.value]));
      // this.$store.dispatch("rect/gaugeValueUpdate", {
      //   displayId: this.properties.displayId,
      //   widgetId: this.properties.widgetId,
      //   value: tagValue.data.rows[0][e.target.value],
      // });
    },
    async getTags() {
      // let tagsResponse = await api.WellServices.getColumnsOfDepthLog();
      // this.tags = tagsResponse.data;
    },
    toggleTitleVisibiltyHandler() {
      this.$store.dispatch("rect/toggleTitleVisibility", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
      });
    },
    toggleUnitVisibiltyHandler() {
      this.$store.dispatch("rect/toggleUnitVisibility", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
      });
    },
    toggleValueVisibiltyHandler() {
      this.$store.dispatch("rect/toggleValueVisibility", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
      });
    },
    toggleBorderVisibiltyHandler() {
      this.$store.dispatch("rect/toggleBorderVisibility", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
      });
    },
    toggleDisplayHandler() {
      this.$store.dispatch("rect/toggleSquareGauge", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
      });
    },
    changeAlertColor(e) {
      this.$store.dispatch("rect/changeAlertColor", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },
    changeBlinkAlert(e) {
      this.$store.dispatch("rect/changeBlinkAlert", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.checked,
      });
    },
    bgColorValueHandler(e) {
      this.$store.dispatch("rect/changeGaugeSaveColor", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },
    decimalValueHandler(e) {
      if (e.target.value < 14 && e.target.value > -1) {
        this.$store.dispatch("rect/gaugeDecimalValue", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });
      }
    },
    intervalValueHandler(e) {
      if (this.intervalTimer)
        clearTimeout(this.intervalTimer)
      this.intervalTimer = setTimeout(() => {
        this.$store.dispatch("rect/gaugeIntervalValue", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });
      }, 200);

    },
    labelFontValueHandler(e) {
      this.$store.dispatch("rect/gaugeLabelFontValue", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value / 100,
      });
    },
    changeStartAngleHandler(e) {
      this.$store.dispatch("rect/changeGaugeStartAngle", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },
    changeEndAngleHandler(e) {
      this.$store.dispatch("rect/changeGaugeEndAngle", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },

    changeStartRange(e) {
      this.$store.dispatch("rect/circularAlarmStartRange", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },
    changeEndRange(e) {
      this.$store.dispatch("rect/circularAlarmEndRange", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },
    changeStartRangeHandler(e) {
      if (this.startRangeTimer)
        clearTimeout(this.startRangeTimer)
      this.startRangeTimer = setTimeout(() => {
        this.$store.dispatch("rect/changeGaugeStartRange", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });
      }, 200);
    },
    changeEndRangeHandler(e) {
      if (this.endRangeTimer)
        clearTimeout(this.endRangeTimer)
      this.endRangeTimer = setTimeout(() => {
        this.$store.dispatch("rect/changeGaugeEndRange", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });
      }, 200);

    },
    changeTitleHandler(e) {
      this.$store.dispatch("rect/changeGaugeTitle", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        fullName: e.target.value,
      });
    },
    colorValueHandler(e) {
      this.$store.dispatch("rect/changeTitleColorGauge", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },
    needleColorValueHandler(e) {
      this.$store.dispatch("rect/needleColorGauge", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },

    valueColorValueHandler(e) {
      this.$store.dispatch("rect/changeValueColorGauge", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },
    unitColorValueHandler(e) {
      this.$store.dispatch("rect/changeUnitColor", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },
    toggle_height_handler_one() {
      if (this.isExpandFeatureOne == true) {
        this.isExpandFeatureOne = false;
      } else {
        this.isExpandFeatureOne = true;
      }
    },
    toggle_height_handler_two() {
      if (this.isExpandFeatureTwo == true) {
        this.isExpandFeatureTwo = false;
      } else {
        this.isExpandFeatureTwo = true;
      }
    },
    toggle_height_handler_three() {
      if (this.isExpandFeatureThree == true) {
        this.isExpandFeatureThree = false;
      } else {
        this.isExpandFeatureThree = true;
      }
    },
    toggle_height_handler_four() {
      if (this.isExpandFeatureFour == true) {
        this.isExpandFeatureFour = false;
      } else {
        this.isExpandFeatureFour = true;
      }
    },
    toggle_height_handler_five() {
      if (this.isExpandFeatureFive == true) {
        this.isExpandFeatureFive = false;
      } else {
        this.isExpandFeatureFive = true;
      }
    },
    toggle_height_handler_six() {
      if (this.isExpandFeatureSix == true) {
        this.isExpandFeatureSix = false;
      } else {
        this.isExpandFeatureSix = true;
      }
    },
    dark() {
      this.darkMode = true;
      this.$emit("dark");
    },
    light() {
      this.darkMode = false;
      this.$emit("light");
    },
  },
  mounted() {
    this.getTags();
  },
};
</script>

<style scoped src="../../assets/styles/propstyle.css"></style>
