<template>
  <div
    class="shadow-lg"
    :id="widgetId"
    @contextmenu="this.setContextIdWidget"
    :style="{
      height: this.widgetHeight + 'px',
      width: this.widgetWidth + 'px',
      paxyAxisngTop: '2px',
      background: 'var(--wdigetBg)',
      transition:'var(--transition)',
      'border-radius': '10px',
    }"
  >
    <div
      class="block tool_group"
      :style="{
        top: this.mouse_y - 150 + 'px',
        left: this.mouse_x + 50 + 'px',
      }"
      :id="'tooltip' + this.widgetId"
    >
      <div v-if="isTvdTooltip" class="actc_tool text-white">
        <p :key="i" v-for="(msg, i) of tvdTooltipValues.split('</br>')">
          {{ msg }}
        </p>
      </div>
    </div>
    <div class=" text-white h-Full p-2" style="color: var(--textColor)">
      <div class="flex justify-between">
        <div></div>

        <div class="flex items-center justify-between __toggle__boxes">
          <div class="flex px-2">
            <div
              :style="{ fontSize: 9 + 'px' }"
              class="mt-1 settingPopColor mb-1"
            >
              Zoom
            </div>
            <div class="ml-0.5 tool_section">
              <input
                type="checkbox"
                id="zoomswitch"
                class="tool_toggle_input"
              />
              <label class="tool_toggle_label _zoom ml-1 mb-1" for="zoomswitch"
                >Zoom :</label
              >
            </div>
          </div>
          <div class="normalBg flex px-2">
            <div
              :style="{ fontSize: 9 + 'px' }"
              class="mt-1 settingPopColor mb-1"
            >
              Tooltip
            </div>
            <div class="ml-0.5 tool_section">
              <input
                v-model="isTooltipEnable"
                type="checkbox"
                id="tooltip"
                class="tool_toggle_input"
              />
              <label class="tool_toggle_label _zoom ml-1 mb-1" for="tooltip"
                >Tooltip :</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :id="'xyAxis' + widgetId"></div>
    <div id="d3Tooltip" class="tooltipd3"></div>
  </div>
</template>
<script>
import {
  select,
  scaleLinear,
  scaleTime,
  axisBottom,
  axisLeft,
  axisRight,
  schemeCategory10,
  scaleOrdinal,
  selectAll,
  brush,
  symbol,
  symbolCircle,
  bisector,
  pointer,
  symbolDiamond,
  line,
  max,
  min,
} from "d3";
var svg = "";
let id = null;
import apiService from "../../../api/services";
import moment from "moment/src/moment";
export default {
  name: "xyAxis",
  props: {
    cardBackground: {
      type: String,
      default: "var(navBar2Bg)",
    },
    widgetHeight: {
      type: Number,
      default: 200,
    },
    widgetWidth: {
      type: Number,
      default: 350,
    },
    widgetpopHeight: {
      type: Number,
      default: 480,
    },
    widgetpopWidth: {
      type: Number,
      default: 1080,
    },
    displayId: String,
    id: String,
    gridColor: {
      type: String,
      default: "",
    },
    gridBackground: {
      type: String,
      default: "",
    },
    axisLabelColor: {
      type: String,
      require: false,
      default: "var(--textColor)",
    },
    xAxisLabelSize: {
      Number,
    },
    yAxisLabelSize: {
      type: Number,
    },
    backgroundColor: {
      type: String,
      default: "white",
    },
    widgetId: String,
    isSwitched: {
      type: Boolean,
      default: false,
    },
    widgetUnitL: {
      type: String,
      default: "",
    },
    unitDisplay: {
      type: Boolean,
      default: true,
    },
    openConfig: Function,
    isSecondaryAxis: {
      type: Boolean,
      require: false,
      default: false,
    },
    graphScaleColor: {
      type: String,
      require: false,
      default: "",
    },
    secondaryAxisLabelColor: {
      type: String,
      require: false,
      default: "",
    },
    graphSecondaryScaleColor: {
      type: String,
      require: false,
      default: "",
    },
    yAxisSecondaryLabelSize: {
      type: Number,
      require: false,
      default: 10,
    },
    primaryXaxisFullName: {
      type: Object,
      require: false,
      default: { name: "time", value: "time" },
    },
    primaryXaxisUnit: {
      type: String,
      require: false,
      default: "",
    },
    primaryYaxisFullName: {
      type: String,
      require: false,
      default: { name: "mnemonic", value: "" },
    },
    primaryYaxisUnit: {
      type: String,
      require: false,
      default: "",
    },
    secondaryYaxisFullName: {
      type: String,
      require: false,
      default: { name: "mnemonic", value: "" },
    },
    secondryYaxisUnit: {
      type: String,
      require: false,
      default: "",
    },
  },
  data() {
    return {
      isTvdTooltip: false,
      tvdTooltipValues: "",
      mouse_x: 0,
      mouse_y: 0,
      minX: 0,
      maxX: 100,
      minY: 0,
      maxY: 3500,
      minY1: 0,
      maxY1: 1000,
      width: this.widgetWidth,
      height: this.widgetHeight,
      widgetUnit: this.widgetUnitL,
      widgetUnits: this.widgetUnitL,
      displayName: "ss",
      displayNames: "yy",
      wellId: null,
      wellboreId: null,
      logId: null,
      logType: null,
      mnemonic_groups: {},
      minMsxTime: {},
      completeData: {},
      isTooltipEnable: false,
    };
  },
  beforeMount() {
    let id = "id" + Math.random().toString(16).slice(2);
    this.tempWidgetId = id;
  },
  async mounted() {
    this.tempWidgetId = this.backgroundId || this.tempWidgetId;
    let dtls = this.$store.state.disp.displays[this.displayId];
    this.$store.subscribe(async (mutation, state) => {
      if (
        mutation.type == "disp/setDisplay" &&
        this.displayId == mutation.payload.display &&
        this.id
      ) {
        console.log("mutation.payload", mutation.payload);
        this.wellId = mutation.payload.wellId;
        this.wellboreId = mutation.payload.wellboreId;
        this.logId = mutation.payload.logId;

        this.logType =
          mutation.payload.logType == "trajectory"
            ? "time"
            : mutation.payload.logType;
        this.logTypeId = mutation.payload.logTypeId;
        this.minMsxTime = await this.getMinMaxRange();
        if (this.primaryYaxisFullName && this.primaryYaxisFullName.value) {
          await this.prepareGraphDetail(this.primaryYaxisFullName.value);
        }
      }
    });
    if (
      dtls != null &&
      dtls.wellId != null &&
      dtls.wellboreId != null &&
      dtls.logId != null &&
      dtls.wellboreState != null
    ) {
      this.wellId = this.wellId = dtls.wellId;
      this.wellboreId = this.wellboreId = dtls.wellboreId;
      this.logId = this.logId = dtls.logId;
      this.logType = dtls.logType;
      this.minMsxTime = await this.getMinMaxRange();
      if (this.primaryYaxisFullName && this.primaryYaxisFullName.value) {
        await this.prepareGraphDetail(this.primaryYaxisFullName.value);
      }
      if (this.primaryXaxisFullName && this.primaryXaxisFullName.value) {
        await this.prepareGraphDetail(this.primaryXaxisFullName.value);
      }
      // if (this.secondaryYaxisFullName && this.secondaryYaxisFullName.value) {
      //   await this.prepareGraphDetail(this.secondaryYaxisFullName.value);
      // }
    }
    this.buildSVG();
  },
  computed: {
    darkDark() {
      if (this.$store.state.rect.darkmode !== 'white') {
        selectAll(".yAxis").attr("color", "white");
        selectAll(".xAxis").attr("color", "white");
        selectAll(".yaxis-grid").style("color", "white");
        selectAll(".xlabel").style("fill", "white");
        selectAll(".ylabel").style("fill", "white");
      } else {
        selectAll(".yAxis").attr("color", "black");
        selectAll(".xAxis").attr("color", "black");
        selectAll(".yaxis-grid").style("color", "black");
        selectAll(".xlabel").style("fill", "black");
        selectAll(".ylabel").style("fill", "black");
      }
      return this.$store.state.rect.darkmode;
    },
    axiosParams() {
      const params = new URLSearchParams();
      params.append("well_id", this.wellId);
      params.append("well_bore_id", this.wellboreId);
      params.append("customer", this.customer);
      return params;
    },
  },
  sockets: {
    connect: function () {
      console.log("socket connected in trend");
    },
    disconnect() {
      try {
        let topic = this.topic;
        this.$socket.emit("unsubscribe", {
          logId: `${this.wellId}~seperation~${this.wellboreId}~seperation~${this.logType}`,
          topic: this.topic,
          id: this.widgetId,
          clientId: this.$clientId,
        });
      } catch (error) {
        console.error("disconnect unsubscribe error ", error);
      }
    },

    reconnect() {
      this.$socket.emit("susbcribe", {
        logId: `${this.wellId}~seperation~${this.wellboreId}~seperation~${this.logType}`,
        topic: this.topic,
        fields: this.fields,
        id: this.widgetId,
        clientId: this.$clientId,
      });
      console.log("----------------------------------------");
    },
  },
  methods: {
    setContextIdWidget(e) {
      this.$store.dispatch("disp/setContextId", {
        contextId: this.displayId,
        contextWidgetId: this.widgetId,
        contextType: "widget",
      });
    },
    processResult(sResult) {
      console.log("processResult ", sResult);
      console.log("processResult widgetId", this.widgetId);
    },

    windowToggle(name) {
      this.classes = name;
    },
    dark() {
      document.querySelector("nav").classList.add("dark-mode");
      document.querySelector("div").classList.add("dark-mode");
      document.querySelector("button").classList.add("dark-mode");
      document.querySelector("a").classList.add("text-red-600");
      this.darkMode = true;
      this.$emit("dark");
      console.log("Dark Mode");
    },
    light() {
      document.querySelector("nav").classList.remove("dark-mode");
      document.querySelector("div").classList.remove("dark-mode");
      document.querySelector("button").classList.remove("dark-mode");
      document.querySelector("a").classList.remove("text-red-600");
      this.darkMode = false;
      this.$emit("light");
    },
    async buildSVG() {
      var margin = {
          top: 10,
          right: 140,
          bottom: 110,
          left: 80,
        },
        width = this.width - margin.left - margin.right,
        height = this.height - margin.top - margin.bottom;

      let labelFontSize = 12;
      console.log("width,", width);
      console.log("height,", height);
      //create base svg

      svg = select("#xyAxis" + this.widgetId);
      if (svg) {
        console.log("in already div ", "#xyAxis" + this.widgetId);
        svg.selectAll("*").remove();
        svg = "";
      } else {
        let xyAxis = document
          .getElementById(this.widgetId)
          .createElement("div");
        console.log("after create div", xyAxis);
        xyAxis.setAttribute("id", "xyAxis" + this.widgetId);
      }
      svg = select("#xyAxis" + this.widgetId)
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .attr("id", "xyAxisSVG")
        .append("g")
        .attr("id", "xyAxis1")
        .attr("transform", `translate(${margin.left + 20}, ${margin.top + 9})`);

      if (
        this.primaryYaxisFullName &&
        this.primaryYaxisFullName.value &&
        this.completeData[this.primaryYaxisFullName.value]
      ) {
        this.minY = min(
          this.completeData[this.primaryYaxisFullName.value],
          (d) => d
        );
        this.maxY = max(
          this.completeData[this.primaryYaxisFullName.value],
          (d) => d
        );
        if (this.logType === "time") {
          this.minX = min(this.completeData["time"], (d) => d);
          this.maxX = max(this.completeData["time"], (d) => d);
        } else {
        }
      }
      if (
        this.secondaryYaxisFullName &&
        this.secondaryYaxisFullName.value &&
        this.completeData[this.secondaryYaxisFullName.value]
      ) {
        this.minY1 = min(
          this.completeData[this.secondaryYaxisFullName.value],
          (d) => d
        );
        this.maxY1 = max(
          this.completeData[this.secondaryYaxisFullName.value],
          (d) => d
        );
      }

      var x =
        this.logType === "time"
          ? scaleTime().domain([this.minX, this.maxX]).range([0, width])
          : scaleLinear().domain([this.minX, this.maxX]).range([0, width]);
      var y = scaleLinear().domain([this.maxY, this.minY]).range([height, 0]);
      var y1 = scaleLinear()
        .domain([this.maxY1, this.minY1])
        .range([height, 0]);
      var xAxis = axisBottom(x).ticks();
      var yAxis = axisLeft(y).ticks();
      var yAxis1 = axisRight(y1).ticks();
      //create grid axis
      const xAxisGrid = axisBottom(x).tickFormat("").ticks();
      const yAxisGrid = axisLeft(y).tickSize(-width).tickFormat("").ticks();

      var clip = svg
        .append("defs")
        .append("svg:clipPath")
        .attr("id", "clip")
        .append("svg:rect")
        .attr("width", width)
        .attr("height", height)
        .attr("x", 0)
        .attr("y", 0);
      svg
        .append("g")
        .attr("class", "xaxis-grid")
        .attr("transform", "translate(0," + height + ")")
        .style("color", this.gridColor)
        .style("fill", this.gridBackground)
        .attr("stroke-opacity", 0.6)
        .call(xAxisGrid);
      svg
        .append("g")
        .attr("class", "yaxis-grid")
        .style("color", this.gridColor)
        .attr("stroke-opacity", 0.6)
        .call(yAxisGrid);
      svg
        .append("g")
        .attr("class", "xAxis")
        .attr("transform", "translate(0," + height + ")")
        .style("font-size", this.xAxisLabelSize)
        // .attr("color", this.graphScaleColor)
        .call(xAxis);
      svg.selectAll(".xAxis").selectAll("text").attr("fill", this.yAxisLabel);
      svg
        .append("g")
        .attr("class", "yAxis")
        .style("font-size", this.yAxisLabelSize)
        // .attr("color", this.graphScaleColor)
        .call(yAxis);
      svg.selectAll(".yAxis").selectAll("text").attr("fill", this.yAxisLabel);
      //Check for secondar axis
      if (this.isSecondaryAxis) {
        svg
          .append("g")
          .attr("class", "yAxis")
          .attr("transform", `translate(${width}, 0)`)
          .style("font-size", this.yAxisSecondaryLabelSize)
          // .attr("color", this.graphSecondaryScaleColor)
          .call(yAxis1);
      }
      svg.selectAll(".yAxis").selectAll("text").attr("fill", this.yAxisLabel);
      let unitXAxis = "";
      try {
        unitXAxis = ` (${
          this.$store.state.disp.displays[this.displayId].units[
            this.$store.state.disp.displays[this.displayId].tags.indexOf(
              this.primaryXaxisFullName.value
            )
          ]
        })`;
        if (unitXAxis.includes("undefined")) unitXAxis = "";
      } catch (error) {
        unitXAxis = "";
      }
      svg
        .append("text")
        .attr("class", "primary_xlabel")
        .attr("text-anchor", "middle")
        .attr("transform", "translate(" + width / 2 + "," + (height + 35) + ")")
        .style("fill", this.axisLabelColor)
        .style("font-size", labelFontSize)
        .text(this.primaryXaxisFullName.name.toUpperCase() + unitXAxis);
      let unitYAxis = "";
      try {
        console.log(this.displayId);
        unitYAxis = ` (${
          this.$store.state.disp.displays[this.displayId].units[
            this.$store.state.disp.displays[this.displayId].tags.indexOf(
              this.primaryYaxisFullName.value
            )
          ]
        })`;
        if (unitYAxis.includes("undefined")) unitYAxis = "";
      } catch (error) {
        unitYAxis = "";
      }

      svg
        .append("text")
        .attr("class", "primary_xlabel")
        .attr("text-anchor", "middle")
        .attr(
          "transform",
          "translate(" + -60 + "," + height / 2 + ")rotate(-90)"
        )
        .style("fill", this.axisLabelColor)
        .style("font-size", labelFontSize)
        .text(this.primaryYaxisFullName.name.toUpperCase() + unitYAxis);
      console.log(
        "🚀 ➽ file: xyaxis.vue:540  ➽ buildSVG  ➽ this.primaryYaxisFullName.name.toUpperCase() ⏩",
        this.primaryYaxisFullName.name.toUpperCase()
      );
      //check for secondar axis
      if (this.isSecondaryAxis) {
        let unitYAxis = "";
        try {
          unitYAxis = ` (${
            this.$store.state.disp.displays[this.displayId].units[
              this.$store.state.disp.displays[this.displayId].tags.indexOf(
                this.secondaryYaxisFullName.value
              )
            ]
          })`;
          if (unitYAxis.includes("undefined")) unitYAxis = "";
        } catch (error) {}
        svg
          .append("text")
          .attr("class", "secondary_ylabel")
          .attr("text-anchor", "middle")
          .attr(
            "transform",
            "translate(" + (width + 70) + "," + height / 2 + ")rotate(-90)"
          )
          .style("fill", this.secondaryAxisLabelColor)
          .style("font-size", labelFontSize)
          .text(this.secondaryYaxisFullName.name.toUpperCase() + unitYAxis);
      }

      let xy = [];
      let xy1 = [];
      // if(this.primaryXaxisFullName && this.primaryXaxisFullName.value && this.completeData[this.primaryXaxisFullName.value]){
      //   for (let i = 0; i < this.completeData[this.primaryXaxisFullName.value].length; i++) {
      //     xy.push({ x: this.completeData[this.primaryXaxisFullName.value][i], y:null });
      //     xy1.push({ x: this.completeData[this.primaryXaxisFullName.value][i], y:null });
      //   }
      // }
      if (
        this.primaryYaxisFullName &&
        this.primaryYaxisFullName.value &&
        this.completeData[this.primaryYaxisFullName.value]
      ) {
        for (
          let i = 0;
          i < this.completeData[this.primaryYaxisFullName.value].length;
          i++
        ) {
          // xy[i].y = this.completeData[this.primaryYaxisFullName.value][i];
          // xy[i].x = this.completeData['dmea'][i];
          if (this.logType === "time") {
            xy.push({
              x: this.completeData["time"][i],
              y: this.completeData[this.primaryYaxisFullName.value][i],
            });
            xy1.push({ x: this.completeData["time"][i], y: null });
          } else {
            xy.push({
              x: this.completeData["dmea"][i],
              y: this.completeData[this.primaryYaxisFullName.value][i],
            });
            xy1.push({ x: this.completeData["dmea"][i], y: null });
          }
        }
      }
      const tooltip = select(`#d3Tooltip`).attr("class", "tooltipd3");
      const circle = svg
        .append("circle")
        .attr("r", 0)
        .attr("fill", "steelblue")
        .style("stroke", "white")
        .attr("opacity", 0.7)
        .style("pointer-events", "none");
      const circleY1 = svg
        .append("circle")
        .attr("r", 0)
        .attr("fill", "red")
        .style("stroke", "white")
        .attr("opacity", 0.7)
        .style("pointer-events", "none");
      // create a listening rectangle
      const listeningRect = svg
        .append("rect")
        .attr("width", width)
        .attr("height", height)
        .attr("fill-opacity", 0.0001);
      var slice = line()
        .x(function (d, i) {
          return x(d.x);
        })
        .y(function (d, i) {
          return y(d.y);
        });

      let primaryX = this.primaryXaxisFullName.name;
      let primaryY = this.primaryYaxisFullName.name;
      let realTimeTooltip = (event, data, isShow, dataFor) => {
        //
        this.mouse_x = event.clientX + 20;
        this.mouse_y = event.clientY;
        if (this.isSecondaryAxis) {
          circleY1.attr("cx", x(event.clientX)).attr("cy", y1(event.clientY));
          // Add transition for the circle radius
          circleY1.transition().duration(50).attr("r", 5);
        }
        let text = "";
        text = `</br>${primaryX.toUpperCase()} : ${data[0][
          "x"
        ].toFixed(2)} </br>${primaryY.toUpperCase()} : ${data[0]["y"].toFixed(
          2
        )} `;

        this.tvdTooltipValues = text;
        //
        this.isTvdTooltip = isShow;
        //
      };
      let hideRealTimeTooltip = (event, text, isShow) => {
        if (this.tooltipTimeOut) {
          clearTimeout(this.tooltipTimeOut);
        }
        this.tooltipTimeOut = setTimeout(() => {
          this.mouse_x = 0;
          this.mouse_y = 0;
          this.tvdTooltipValues = "";
          this.isTvdTooltip = isShow;
        }, 1000);
      };
      svg
        .append("path")
        .datum(xy)
        .attr(
          "d",
          line()
            .x(function (d, i) {
              return x(d.x);
            })
            .y(function (d, i) {
              return y(d.y);
            })
        )
        .attr("class", "primary_line")
        .attr("stroke", "red")
        .attr("fill", "none")
        .on("mouseover", function (e) {
          let data = select(this).data()[0];
          console.log(select(this));
          var bisect = bisector(function (d) {
            return d.x;
          }).left;
          var bisectY = bisector(function (d) {
            return d.y;
          }).left;
          let x0 = x.invert(pointer(e)[0]);
          let y0 = y.invert(pointer(e)[1]);

          let i = bisect(data, x0, 1);
          let j = bisectY(data, y0, 1);
          console.log("🚀 ➽ file: xyaxis.vue:723  ➽ j ⏩ over", i, j, data);

          let diff1 = Math.abs(y0 - data[i].x);
          let diff2 = Math.abs(y0 - data[j].x);
          let d0 = diff1 > diff2 ? data[i] : data[j];
          console.log("x invert pointer : ", diff1, diff2, d0);

          if (d0) {
            let xPos = x(d0.x);
            let yPos = y(d0.y);

            // Update the circle position
            circle.attr("cx", xPos).attr("cy", yPos);
            // Add transition for the circle radius
            circle.transition().duration(50).attr("r", 5);

            // add in  our tooltip
            // tooltip
            //   .style("display", "block")
            //   .style("left", `${xPos}px`)
            //   .style("top", `${yPos}px`)
            //   .html(
            //     `<strong>${primaryX.toUpperCase()} :</strong> ${d0.x}<br><strong>${primaryY.toUpperCase()} :</strong> ${d0.y}`
            //   );
            realTimeTooltip(e, [d0], true, "Plan");
          }
        })
        .on("mousemove", function (e) {
          let data = select(this).data()[0];
          console.log(select(this));
          var bisect = bisector(function (d) {
            return d.x;
          }).left;
          var bisectY = bisector(function (d) {
            return d.y;
          }).left;
          let x0 = x.invert(pointer(e)[0]);
          let y0 = y.invert(pointer(e)[1]);

          let i = bisect(data, x0, 1);
          let j = bisectY(data, y0, 1);
          console.log("🚀 ➽ file: xyaxis.vue:723  ➽ j ⏩ move", i, j);
          // let diff1 = Math.abs(y0 - data[i].x);
          // let diff2 = Math.abs(y0 - data[j].x);
          // let d0 = diff1 > diff2 ? data[i] : data[j];
          let d0 = data[i];
          if (d0) {
            let xPos = x(d0.x);
            let yPos = y(d0.y);
            // Update the circle position
            circle.attr("cx", xPos).attr("cy", yPos);
            // Add transition for the circle radius
            circle.transition().duration(50).attr("r", 5);
            verticalLine.attr('x1',xPos);
          verticalLine.attr('x2',xPos);
          verticalLine.attr('stroke','white');
            // add in  our tooltip
            // tooltip
            //   .style("display", "block")
            //   .style("left", `${xPos}px`)
            //   .style("top", `${yPos}px`)
            //   .html(
            //     `<strong>${primaryX.toUpperCase()} :</strong> ${d0.x}<br><strong>${primaryY.toUpperCase()} :</strong> ${d0.y}`
            //   );
            realTimeTooltip(e, [d0], true, "Plan");
          }
        })
        .on("mouseleave", function (e) {
          hideRealTimeTooltip(e, "", false);
        });
      //vertical line in graph
      const verticalLine = svg.append('line')
          .attr('y1',0)
          .attr('y2',height)
          .attr('stroke-width',1)
          .attr('stroke-dasharray',5,5)


        svg.on('mouseleave',()=>{
          verticalLine.attr('stroke','');
        })
      //check for secondaryY-axis
      if (this.isSecondaryAxis) {
        if (
          this.secondaryYaxisFullName &&
          this.secondaryYaxisFullName.value &&
          this.completeData[this.secondaryYaxisFullName.value]
        ) {
          for (
            let i = 0;
            i < this.completeData[this.secondaryYaxisFullName.value].length;
            i++
          ) {
            xy1[i].y = this.completeData[this.secondaryYaxisFullName.value][i];
          }
        }
        var secondaryLine = line()
          .x(function (d, i) {
            return x(d.x);
          })
          .y(function (d, i) {
            return y1(d.y);
          });

        svg
          .append("path")
          .attr("d", secondaryLine(xy1))
          .attr("class", "secondary_line")
          .attr("stroke", "blue")
          .attr("fill", "none");
      }

      // create tooltip div

      // Add a circle element

      // create the mouse move function
      // svg.on("mousemove", function (e) {
      //   // let [xCoord] = pointer(event, this);
      //   // let bisectDate = bisector((d) => d.x).left;
      //   // let x0 = x.invert(xCoord);

      //   // let i = bisectDate(xy, x0, 1);

      //   // let d0 = xy[i - 1];
      //   // let d1 = xy[i];
      //   // console.log(" mouse move : x0 : ", d0, d1,  x0);
      //   // if (d0 && d1) {
      //   //   let d = x0 - d0.x > d1.x - x0 ? d1 : d0;
      //   //   let xPos = x(d.x);
      //   //   let yPos = y(d.y);
      //   //   // Update the circle position
      //   //   circle.attr("cx", xPos).attr("cy", yPos);
      //   //   // Add transition for the circle radius
      //   //   circle.transition().duration(50).attr("r", 5);
      //   //   // add in  our tooltip
      //   //   tooltip
      //   //     .style("display", "block")
      //   //     .style("left", `${xPos}px`)
      //   //     .style("top", `${yPos}px`)
      //   //     .html(
      //   //       `<strong>${this.primaryXaxisFullName.name} :</strong> ${d.x}<br><strong>${this.primaryYaxisFullName.name} :</strong> ${d.y}`
      //   //     );
      //   // }
      //   let data = select(this).data()[0];
      //   console.log(data);
      //   var bisect = bisector(function (d) {
      //     return d.x;
      //   }).left;
      //   var bisectY = bisector(function (d) {
      //     return d.y;
      //   }).left;
      //   let x0 = x.invert(pointer(e)[0]);
      //   let y0 = y.invert(pointer(e)[1]);
      //   console.log(data, x0, y0);
      //   let i = bisect(data, x0, 1);
      //   let j = bisectY(data, y0, 1);
      //   let diff1 = Math.abs(y0 - data[i].y);
      //   let diff2 = Math.abs(y0 - data[j].y);
      //   let d0 = diff1 > diff2 ? data[j] : data[i];
      //   console.log("x invert pointer : ", diff1, diff2, d0);
      //   // realTimeTooltip(e, [d0], true, "Plan");
      // });
      // // listening rectangle mouse leave function
      // svg.on("mouseleave", function () {
      //   circle.transition().duration(50).attr("r", 0);
      //   tooltip.style("display", "none");
      // });
    },
    getRealtimeData(title) {
      let test = this.widgetId;
      let topic = `${this.wellId}-${this.wellboreId}-${this.logTypeId}-${this.$store.state.data.customer}`;
      let wells = this.$store.state.data.wells;
      let well = wells[this.wellId];
      if (well) {
        let wellbore = well.wellbores[this.wellboreId];
        let log = wellbore["logs"][this.logType];
        this.logId = log.id;
        let fields = [title || this.title];
        this.fields = fields;
        let topicExists = this.$store.state.live.isSubscribed[topic];
        if (this.logId) {
          this.$socket.emit("susbcribe", {
            logId: this.logId,
            topic: topic,
            fields,
            id: this.widgetId,
            clientId: this.$clientId,
            logType: this.logType,
          });
          if (!topicExists) {
            this.$store.dispatch("live/setSubscriptionFlag", {
              topic,
              flag: true,
            });
          }
          this.sockets.subscribe(this.$clientId, (sResult) => {
            let common = sResult["common"];
            let result = sResult[this.widgetId];
            if (result) {
              if (this.logType == "depth") {
                this.tooltipValue = common["dmea"];
              }
              if (this.logType == "time") {
                this.tooltipValue = new Date(
                  parseInt(common["timestamp"])
                ).toLocaleString("en-US", {
                  timeZone: this.time_zone,
                });
              }
              this.numericValue = result[title] || result[this.title];
            }
          });
        }
        topicExists = this.$store.state.live.isSubscribed[topic];
      }
    },
    onDropOnXWidgets(event) {
      event.stopPropagation();
      let onDropDtls = JSON.parse(event.dataTransfer.getData("elProps"));
      this.displayName = onDropDtls.FullName;
      this.defaultTitle = onDropDtls.title;
      this.$store.dispatch("rect/changeTitle", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: onDropDtls.title,
        FullName: onDropDtls.FullName,
      });
      let displayDtls = this.$store.state.disp.displays[this.displayId];
      // this.widgetTitle = onDropDtls.title;
      this.widgetUnit = onDropDtls.units || "";
      this.numericValue = "";
      this.tooltipValue = "";
      console.log("widgetUnit onDropOnWidgets", this.widgetUnit);
      if (this.status == "UPDATING") {
        this.sockets.unsubscribe(this.$clientId);
      }
      this.getTagValue(displayDtls, onDropDtls.title);
      this.buildSVG();
    },
    onDropOnYWidgets(event) {
      event.stopPropagation();
      let onDropDtls = JSON.parse(event.dataTransfer.getData("elProps"));
      this.displayNames = onDropDtls.FullName;
      this.defaultTitle = onDropDtls.title;
      this.$store.dispatch("rect/changeTitle", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: onDropDtls.title,
        FullName: onDropDtls.FullName,
      });
      let displayDtls = this.$store.state.disp.displays[this.displayId];
      // this.widgetTitle = onDropDtls.title;
      this.widgetUnits = onDropDtls.units || "";
      this.numericValue = "";
      this.tooltipValue = "";
      console.log("widgetUnits onDropOnWidgets", this.widgetUnits);
      if (this.status == "UPDATING") {
        this.sockets.unsubscribe(this.$clientId);
      }
      this.getTagValue(displayDtls, onDropDtls.title);
      this.buildSVG();
    },
    async getTagValue(displayDtls, title) {
      if (this.table) {
        let valueAt = "end";
        let dconfig = {};
        let wells = this.$store.state.data.wells;
        let well = wells[this.wellId];
        if (well) {
          let wellbore = well.wellbores[this.wellboreId];
          let log = wellbore["logs"][this.logType];
          this.logId = log.id;
          dconfig.field = title || this.title;
          dconfig.logId = this.logId;
          dconfig.table = this.table;
          if (this.logType === "depth") {
            dconfig.logName = "depthlog";
          }
          if (this.logType === "time") {
            dconfig.logName = "timelog";
          }
          let url = await getLatestValueUrl(dconfig);
          let result = await executeQuery(url);
          if (result != null) {
            this.numericValue = result.data[0]
              ? result.data[0][dconfig.field]
              : "";
            if (this.logType === "depth") {
              this.tooltipValue = result.data[0]["dept"];
            }
            if (this.logType === "time") {
              this.tooltipValue = new Date(
                result.data[0]["time"]
              ).toLocaleString("en-US", {
                timeZone: this.time_zone,
              });
            }
          }
        } else {
          this.numericValue = "";
        }
        return this.numericValue;
      }
    },
    getNumericValue(displayDtls) {
      let value;
      let valueAt = "end";
      let titleValues = this.$store.state.data.tagValues[this.title];
      if (titleValues != null) {
        for (let i = 0; i < titleValues.length; i++) {
          if (
            titleValues[i].wellId == displayDtls.wellId &&
            titleValues[i].wellboreId == displayDtls.wellboreId
          ) {
            let logs = titleValues[i].logs;
            for (let j = 0; j < logs.length; j++) {
              if ((logs[j].logId = displayDtls.logId)) {
                let logValues = logs[j].logValues;
                value = logValues[valueAt];
                break;
              }
            }
          }
        }
      }
      return value;
    },
    async getMinMaxRange() {
      try {
        let result = null;
        if (this.logType == "time") {
          result = await apiService.WellServices.getMinMaxTime({
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
          });
          result.data.min = moment(result.data.min).unix() * 1000;
          result.data.max = moment(result.data.max).unix() * 1000;
        } else {
          result = await apiService.WellServices.getMinMaxDepth({
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
          });
          result.data.min = result.data.min;
          result.data.max = result.data.max;
        }

        return result.data;
      } catch (error) {
        console.error(error);
        return { min: 0, max: 1000 };
      }
    },
    async getdateFromDb(range, mnemonicType, mnemonicValue) {
      console.log(range);
      // this.$store.dispatch("data/startLoading");
      let payload = {
        fields: [mnemonicValue],
        well_id: this.wellId,
        wellbore_name: this.wellboreId,
        type: mnemonicType,
      };
      if (this.logType === "time") {
        payload["from"] = parseInt(range[0]);
        payload["to"] = parseInt(range[1]);
      } else {
        payload["end"] = parseInt(range[0]);
        payload["start"] = parseInt(range[1]);
      }
      let result = await apiService.WellServices.getTimeLogs(payload);
      this.completeData = { ...this.completeData, ...result.data.data };
    },
    getMnemonicType(mnemonic) {
      // console.time("get mnemonic type", mnemonic)
      let mnemonic_groups = {};
      let all_mnemonics =
        this.$store.state.disp.displays[this.displayId].mnemonics;
      let mnemonic_type = "UNPREFERRED";
      for (let i in all_mnemonics) {
        if (all_mnemonics[i].find((o) => o.std_mnemonic === mnemonic)) {
          mnemonic_type = i;
          mnemonic_groups[i] = [];
          break;
        }
      }
      if (!this.mnemonic_groups[mnemonic_type])
        this.mnemonic_groups[mnemonic_type] = [];
      if (this.mnemonic_groups[mnemonic_type].indexOf(mnemonic) == -1)
        this.mnemonic_groups[mnemonic_type] = [
          ...this.mnemonic_groups[mnemonic_type],
          mnemonic,
        ];
      // console.timeEnd("get mnemonic type", mnemonic)

      return mnemonic_type;
    },
    async prepareGraphDetail(mnemonic) {
      let nemonicsType = await this.getMnemonicType(mnemonic);
      let min = 0;
      let max = this.minMsxTime.max;
      if (this.logType == "time") {
        min = this.minMsxTime.max - 1 * 1000 * 60 * 60;
      } else {
        min = this.minMsxTime.min;
      }
      let range = [max, min];
      await this.getdateFromDb(range, nemonicsType, mnemonic);
      this.buildSVG();
    },
  },
  watch: {
    isSecondaryAxis() {
      this.buildSVG();
    },
    gridColor(val) {
      // this.buildSVG();
      selectAll('.yaxis-grid').style("color", val);
    },
    gridBackground() {
      this.buildSVG();
    },
    axisLabelColor(val) {
      // this.buildSVG();
      selectAll(".primary_xlabel").style("fill",val);
    },
    graphScaleColor(val) {
      // this.buildSVG();
      select(".primary_line").attr("stroke",val);
    },
    xAxisLabelSize() {
      this.buildSVG();
    },
    yAxisLabelSize() {
      this.buildSVG();
    },
    secondaryAxisLabelColor(val) {
      // this.buildSVG();
      select(".secondary_ylabel").style("fill",val);
    },
    graphSecondaryScaleColor(val) {
      // this.buildSVG();
      select(".secondary_line").attr("stroke",val);
    },
    yAxisSecondaryLabelSize() {
      this.buildSVG();
    },
    primaryYaxisFullName(val) {
      // this.buildSVG();
      if (val) {
        this.prepareGraphDetail(val.value);
      }
    },
    primaryYaxisUnit() {
      this.buildSVG();
    },
    primaryXaxisFullName(val) {
      // this.buildSVG();
      if (val) {
        this.prepareGraphDetail(val.value);
      }
    },
    primaryXaxisUnit() {
      this.buildSVG();
    },
    secondaryYaxisFullName(val) {
      // this.buildSVG();
      if (val) {
        this.prepareGraphDetail(val.value);
      }
    },
    secondryYaxisUnit() {
      this.buildSVG();
    },
    widgetHeight(val) {
      this.height = val;
      this.buildSVG();
    },
    widgetWidth(val) {
      this.width = val;
      this.buildSVG();
    },
  },
};
</script>
<style scoped>
h1 {
  font-size: 20px;
}
.axis-grids .tick {
  display: none !important;
}
.text-xxs {
  font-size: 9px;
}
.tool_section {
  display: flex;
  justify-content: center;
  align-self: center;
  margin-top: 4px;
}
.tool_toggle_label {
  cursor: pointer;
  text-indent: -9999px;
  width: 23px;
  height: 12px;
  display: block;
  border-radius: 100px;
  position: relative;
}
.tool_toggle_input:checked + ._tooltip {
  /* background: #5aba81; */
  background: var(--activeTextColor);
}

.tool_toggle_input:checked + ._alert {
  /* background: #ff2200; */
  background: var(--activeTextColor);
}

.tool_toggle_input:checked + ._zoom {
  /* background: #00bcd4; */
  background: var(--activeTextColor);
}
.tool_toggle_input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
}

.tool_toggle_label {
  cursor: pointer;
  text-indent: -9999px;
  width: 23px;
  height: 12px;
  display: block;
  border-radius: 100px;
  position: relative;
}

.tool_toggle_label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 2px;
  width: 10px;
  height: 10px;
  background: var(--sidebarbg);
  border-radius: 90px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.tool_toggle_label {
  /* background: #5aba81; */
  background: var(--toggleSliderBg);
}

.tool_toggle_input:checked + .tool_toggle_label:after {
  /* left: calc(100% - 2px); */
  -webkit-transform: translateX(9px);
  transform: translateX(9px);
}

.tooltipd3 {
  position: absolute;
  padding: 10px;
  background-color: steelblue;
  color: white;
  border: 1px solid white;
  border-radius: 10px;
  display: none;
  opacity: 0.75;
}
.tool_group {
  position: fixed;
  display: inline-grid;
  /* left: 221px; */
  right: 162px;
  z-index: 99999;
}

.actc_tool {
  font-size: 10px;
  background: #000000;
  width: auto;
  position: absolute;
  padding: 2px 5px;
  /* border-radius: 20px; */
}
</style>
