import axios from "axios";
import constant from "../constant";

class UploadLwd {
  upload(file, body_data, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);
    formData.append('token', body_data.token);
    formData.append('well_borename', body_data.well_borename);
    formData.append('entity_id', body_data.entity_id);
    formData.append('feed_type', body_data.feed_type);
    formData.append('section', body_data.section);
    formData.append('alias_name', body_data.alias_name);
    formData.append('solution_type', body_data.solution_type);
    formData.append('log_id', body_data.log_id);

    return axios.post(constant.LWD_URL + 'lwd/upload', formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress,
    });
  }
}

export default new UploadLwd();
