import well from "./well"
import auth from "./auth"
import dashboard from "./dashboard"
import alerts from "./alert"
import support from "./support"
import datagaps from "./datagaps"
import LWD from "./lwd"
export default {
    well,
    auth,
    dashboard,
    alerts,
    support,
    datagaps,
    LWD
}