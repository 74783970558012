
<template>
  <nav
    class="right_bg close-right-sidebar-16rem mt-20 z-40 md:right-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl flex flex-wrap items-center justify-between relative md:w-w-2/12 z-10">

    <div class="right_bg">
      <div class="flex bg-emerald-500 justify-between px-2 py-0 mneminics_mt">
        <div class="righ_side_head_">
          <p class="sidebar-heading">Image</p>
        </div>
        <div>
          <svg v-on:click="closeNavigation" class="cursor-pointer ml-auto mt-0 w-2 left_close_btn"
            style="text-align: right" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
            <path id="close_2_" data-name="close (2)"
              d="M8.874,7.636,14.715,1.8A.972.972,0,1,0,13.341.421L7.5,6.262,1.659.421A.972.972,0,0,0,.285,1.8L6.126,7.636.285,13.477a.972.972,0,1,0,1.374,1.374L7.5,9.01l5.841,5.841a.972.972,0,0,0,1.374-1.374Zm0,0"
              transform="translate(0 -0.136)" fill="#fff" />
          </svg>
        </div>
      </div>
    </div>

    <div class="pt-0_5 pr-0_5 pl-0_5 pb-0_5">
      <div v-on:click="toggle_height_handler_one" class="flex bg-right_ justify-between pt-4 pl-5 pb-4">
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/genaral_settings.png" alt="">
            General Settings
          </p>
        </div>
        <div>
          <svg class="cursor-pointer ml-auto mr-3 mt-1" xmlns="http://www.w3.org/2000/svg" width="9" height="6"
            viewBox="0 0 11 9.565">
            <g id="down-arrow_1_" data-name="down-arrow (1)" transform="translate(0.001 -32.006)">
              <g id="Group_9479" data-name="Group 9479" transform="translate(-0.001 32.006)">
                <path id="Path_10344" data-name="Path 10344"
                  d="M10.968,32.126a.24.24,0,0,0-.208-.12H.239a.239.239,0,0,0-.207.359l5.261,9.087a.239.239,0,0,0,.414,0l5.261-9.087A.241.241,0,0,0,10.968,32.126Z"
                  transform="translate(0.001 -32.006)" fill="rgba(255,255,255,0.7)" />
              </g>
            </g>
          </svg>
        </div>
      </div>
      <div v-on:click="toggle_height_handler_two" class="flex bg-black justify-between bg-right_ pt-5 pl-5 pb-4">
        <div>
          <p class="sidebar-label">Tags</p>
        </div>
        <div>
          <svg class="cursor-pointer ml-auto mr-3 mt-1" xmlns="http://www.w3.org/2000/svg" width="9" height="6"
            viewBox="0 0 11 9.565">
            <g id="down-arrow_1_" data-name="down-arrow (1)" transform="translate(0.001 -32.006)">
              <g id="Group_9479" data-name="Group 9479" transform="translate(-0.001 32.006)">
                <path id="Path_10344" data-name="Path 10344"
                  d="M10.968,32.126a.24.24,0,0,0-.208-.12H.239a.239.239,0,0,0-.207.359l5.261,9.087a.239.239,0,0,0,.414,0l5.261-9.087A.241.241,0,0,0,10.968,32.126Z"
                  transform="translate(0.001 -32.006)" fill="rgba(255,255,255,0.7)" />
              </g>
            </g>
          </svg>
        </div>
      </div>

      <div v-bind:style="{ height: isExpandFeatureTwo ? '100%' : '0px' }" class="expand-right-sidebar-feature-one">
        <div class="px-3">

        </div>
      </div>

      <div v-bind:style="{ height: isExpandFeatureThree ? '100%' : '0px' }" class="expand-right-sidebar-feature-one">
        <div class="pb-5 pt-3">
        </div>
      </div>
    </div>

  </nav>
</template>


<script>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/solid";
import api from "../../api/services";

export default {
  name: "Maindash",
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon
  },
  props: {
    toggle_right_Sidebar: {
      type: String,
      default: ""
    },
    toggle_right_margin: {
      type: String,
      default: ""
    },
    id: "",
    properties: {},
    closeNavigation: Function
  },
  data() {
    return {
      isExpandFeatureOne: true,
      isExpandFeatureTwo: false,
      isExpandFeatureThree: false,
      isExpandFeatureFour: false,
      isExpandFeatureFive: false
    };
  },
  methods: {
    toggleTitleVisibiltyHandler() {
      this.$store.dispatch("rect/toggleTitleVisibility", { id: this.id });
    },
    toggleUnitVisibiltyHandler() {
      this.$store.dispatch("rect/toggleUnitVisibility", { id: this.id });
    },
    toggleValueVisibiltyHandler() {
      this.$store.dispatch("rect/toggleValueVisibility", { id: this.id });
    },
    toggleBorderVisibiltyHandler() {
      this.$store.dispatch("rect/toggleBorderVisibility", { id: this.id });
    },
    changeTitleColorHandler(event) {
      this.$store.dispatch("rect/changeTitleColor", {
        id: this.id,
        value: event.target.value
      });
    },
    changeValueColorHandler() {
      this.$store.dispatch("rect/changeValueColor", {
        id: this.id,
        value: event.target.value
      });
    },
    changeUnitColorHandler() {
      this.$store.dispatch("rect/changeUnitColor", {
        id: this.id,
        value: event.target.value
      });
    },

    startRangeBarGaugeHandler(event) {
      this.$store.dispatch("rect/startRangeBarGauge", {
        id: this.id,
        value: event.target.value
      });
    },
    endRangeBarGaugeHandler(event) {
      this.$store.dispatch("rect/endRangeBarGauge", {
        id: this.id,
        value: event.target.value
      });
    },
    intervalBarGaugeHandler(event) {
      this.$store.dispatch("rect/intervalBarGauge", {
        id: this.id,
        value: event.target.value
      });
    },
    changeBorderColorHandler(event) {
      this.$store.dispatch("rect/changeBorderColor", {
        id: this.id,
        value: event.target.value
      });
    },

    changeBorderWidthHandler(event) {
      this.$store.dispatch("rect/changeBorderWidth", {
        id: this.id,
        value: event.target.value
      });
    },

    changeStartRange(e) {
      this.$store.dispatch("rect/circularAlarmStartRange", {
        id: this.id,
        value: e.target.value
      });
    },
    changeEndRange(e) {
      this.$store.dispatch("rect/circularAlarmEndRange", {
        id: this.id,
        value: e.target.value
      });
    },

    decimalValueHandler(e) {
      if (e.target.value < 14 && e.target.value > -1) {
        this.$store.dispatch("rect/decimalValue", {
          id: this.id,
          value: e.target.value
        });
      }
    },

    changeTitleHandler(e) {
      this.$store.dispatch("rect/changeTitle", {
        id: this.id,
        value: e.target.value
      });
    },
    toggle_height_handler_one() {
      if (this.isExpandFeatureOne == true) {
        this.isExpandFeatureOne = false;
      } else {
        this.isExpandFeatureOne = true;
      }
    },
    toggle_height_handler_two() {
      if (this.isExpandFeatureTwo == true) {
        this.isExpandFeatureTwo = false;
      } else {
        this.isExpandFeatureTwo = true;
      }
    },
    toggle_height_handler_three() {
      if (this.isExpandFeatureThree == true) {
        this.isExpandFeatureThree = false;
      } else {
        this.isExpandFeatureThree = true;
      }
    },
    toggle_height_handler_four() {
      if (this.isExpandFeatureFour == true) {
        this.isExpandFeatureFour = false;
      } else {
        this.isExpandFeatureFour = true;
      }
    },
    toggle_height_handler_five() {
      if (this.isExpandFeatureFive == true) {
        this.isExpandFeatureFive = false;
      } else {
        this.isExpandFeatureFive = true;
      }
    },
    async mnemonicChangeHandler(e) {
      this.$store.dispatch("rect/barMemonicChange", {
        id: this.id,
        value: e.target.value
      });
      let tagValue = await api.WellServices.getDataForTags({
        fields: e.target.value
      });

      // alert(JSON.stringify(tagValue.data.rows[0][e.target.value]))
      this.$store.dispatch("rect/barValueUpdate", {
        id: this.id,
        value: tagValue.data.rows[0][e.target.value]
      });
    },
    async getTags() {
      let tagsResponse = await api.WellServices.getColumnsOfDepthLog();
      this.tags = tagsResponse.data;
    }
  },
  mounted() {
    this.getTags();
  }
};
</script>

<style scoped>
.right_bg {
  background: #1b1a1f;
}

.bg_gray {
  width: 12%;
}

.bg-right_ {
  background: #28282f;
  margin: 5px 5px;
  padding: 5px;
  border-radius: 5px;
}

.sidebar-heading {
  font-size: 9px;
  padding: 2px;
}

.sidebar-label {
  font-size: 10px;
  /* font-weight: 400; */
  /* font-family: 'Poppins', sans-serif; */
  display: flex;
  height: 100%;
  align-items: flex-end;

  /* "Lato", sans-serif; */
}

.label_heading {
  /* color: white !important; */
  font-size: 8px;
  letter-spacing: 0.5px;
}

.custom-radio-wrap form .form-group-radio .label-text {
  color: white;
}

.font-color {
  /* color: white; */
  /* font-size: 0.95rem; */
  font-size: 10px;
  padding: 0px 0px;
  height: 20px;
  width: 53%;
  text-align: center;
}

button {
  font-size: 10px;
}
</style>
