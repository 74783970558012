<template>
  <nav
    class="right_bg close-right-sidebar-16rem md:right-0 md:block md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl flex flex-wrap items-center justify-between relative md:w-w-2/12 z-10"
    v-bind:style="{ right: toggle_right_Sidebar + 'rem' }"
  >
    <div class="">
      <div class="flex bg-emerald-500 justify-between px-2 py-0 mneminics_mt">
        <div class="righ_side_head_">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="4"
            viewBox="0 0 12 4"
            fill="none"
          >
            <path
              d="M11.3333 0H0.666626V1.33333H11.3333V0ZM0.666626 4H11.3333V2.66667H0.666626V4Z"
              fill="#777777"
            ></path>
          </svg>
          <p class="sidebar-heading">XY Axis</p>
        </div>
        <div>
          <svg
            v-on:click="closeNavigation"
            class="cursor-pointer ml-auto mt-0 w-2 left_close_btn"
            style="text-align: right"
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
          >
            <path
              id="close_2_"
              data-name="close (2)"
              d="M8.874,7.636,14.715,1.8A.972.972,0,1,0,13.341.421L7.5,6.262,1.659.421A.972.972,0,0,0,.285,1.8L6.126,7.636.285,13.477a.972.972,0,1,0,1.374,1.374L7.5,9.01l5.841,5.841a.972.972,0,0,0,1.374-1.374Zm0,0"
              transform="translate(0 -0.136)"
              fill="#b4b5ba"
            />
          </svg>
        </div>
      </div>
    </div>

    <div class="pt-0_5 pr-0_5 pl-0_5 pb-0_5 prop_mx_height">
      <div
        v-on:click="toggle_height_handler_one"
        class="flex justify-between pt-4 pl-5 pb-4 bg-right_"
      >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/genaral_settings.png" alt="" />
            General Settings
          </p>
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureOne"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>
        </div>
      </div>

      <div
        v-bind:style="{ display: isExpandFeatureOne ? '' : 'none' }"
        class="expand-right-sidebar-feature-one"
      >
        <div class="">
          <div class="custom-radio-wrap mb-4 mt-3">
            <div class="form-group-checkbox">
              <label class="w-100 flex font-thin">
                <span class="label_heading"> Secondary Axis </span>
                <input
                  class="ml-auto leading-tight"
                  type="checkbox"
                  @click="secondarAxisHandler($event)"
                  :value="isSecondaryAxis"
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pt-0_5 pr-0_5 pl-0_5 pb-0_5 prop_mx_height">
      <div
        v-on:click="toggle_height_handler_two"
        class="flex bg-black justify-between pt-5 pl-5 pb-4 bg-right_"
      >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/note.png" alt="" />
            Appearance
          </p>
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureTwo"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>
        </div>
      </div>

      <div
        v-bind:style="{ display: isExpandFeatureTwo ? '' : 'none' }"
        class="expand-right-sidebar-feature-one"
      >
        <div class="pb-5">
          <div class="form-group-checkbox"></div>
          <p class="font-thin text-sm textColorClass">Primary Axis</p>
          <!-- <div class="border rounded-md border-dashed p-2"> -->
            <div class="flex items-center justify-between mt-3">
              <p class="label_heading">Label Color</p>
              <input
                type="color"
                id="titleColor"
                class="focus:ring-indigo-500 label_heading h-9 border-0 input-bg block w-5/12 rounded-md"
                @input="axisLabelColorHandler($event)"
                :value="axisLabelColor"
              />
            </div>

            <div class="flex items-center justify-between mt-3">
              <p class="label_heading">Graph Color</p>
              <input
                type="color"
                id="titleColor"
                class="focus:ring-indigo-500 label_heading h-9 border-0 input-bg block w-5/12 rounded-md"
                @input="graphColorHandler($event)"
                :value="graphScaleColor"
              />
            </div>

            <div class="flex items-center justify-between mt-3">
              <p class="label_heading">Grid Color</p>
              <input
                type="color"
                id="titleColor"
                class="focus:ring-indigo-500 label_heading h-9 border-0 input-bg block w-5/12 rounded-md"
                @input="gridColorHandler($event)"
                :value="gridColor"
              />
            </div>
            <div class="flex items-center justify-between mt-3">
              <p class="label_heading">Background Color</p>
              <input
                type="color"
                id="titleColor"
                class="focus:ring-indigo-500 label_heading h-9 border-0 input-bg block w-5/12 rounded-md"
                @input="graphBackgroundHandler($event)"
                :value="graphBackgroundColor"
              />
            </div>
            <div class="flex flex-col mt-3">
              <p class="label_heading">X-axis label size</p>
              <input
                type="number"
                min="7"
                max="16"
                id="valueColor"
                class="focus:ring-indigo-500 label_heading fields p-3 border-0 block"
                @input="xAxisLabelSizeHandler($event)"
                :value="xAxisLabelSize"
              />
            </div>
            <div class="flex flex-col mt-3">
              <p class="label_heading">Y-axis label size</p>
              <input
                type="number"
                min="7"
                max="16"
                id="valueColor"
                class="focus:ring-indigo-500 label_heading fields p-3 border-0 block"
                @input="yAxisLabelSizeHandler($event)"
                :value="yAxisLabelSize"
              />
            </div>
          <!-- </div> -->
          <div v-if="isSecondaryAxis">
            <p class="font-thin text-sm">Secondary Axis</p>
            <!-- <div class="border rounded-md border-dashed p-2"> -->
              <div class="flex items-center justify-between mt-3">
                <p class="label_heading">Label Color</p>
                <input
                  type="color"
                  id="titleColor"
                  class="focus:ring-indigo-500 label_heading h-9 border-0 input-bg block w-5/12 rounded-md"
                  @input="secondaryAxisLabelColorHandler($event)"
                  :value="sevondaryAxisLabelColor"
                />
              </div>

              <div class="flex items-center justify-between mt-3">
                <p class="label_heading">Graph Color</p>
                <input
                  type="color"
                  id="titleColor"
                  class="focus:ring-indigo-500 label_heading h-9 border-0 input-bg block w-5/12 rounded-md"
                  @input="graphSecondaryColorHandler($event)"
                  :value="graphSecondaryScaleColor"
                />
              </div>
              <div class="flex flex-col mt-3">
                <p class="label_heading">Y-axis label size</p>
                <input
                  type="number"
                  min="7"
                  max="16"
                  id="valueColor"
                  class="focus:ring-indigo-500 label_heading fields p-3 border-0 block"
                  @input="yAxisSecondaryLabelSizeHandler($event)"
                  :value="yAxisSecondaryLabelSize"
                />
              </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="pt-0_5 pr-0_5 pl-0_5 pb-0_5 prop_mx_height">
      <div
        v-on:click="toggle_height_handler_four"
        class="flex justify-between pt-4 pl-5 pb-4 bg-right_"
      >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/range_.png" alt="" />
            Mnemonics
          </p>
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureFour"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>
        </div>
      </div>

      <div
        v-bind:style="{ display: isExpandFeatureFour ? '' : 'none' }"
        class="expand-right-sidebar-feature-one"
      >
      <!-- <p class="font-thin text-sm">Primary X-axis</p>
        <div>
          <div class="custom-radio-wrap mb-4">
            <div class="flex flex-col mt-3">
              <p class="label_heading">Display Name</p>
              <input
                :id="widgetId + '1'"
                type="text"
                class="fields border-0 block font-color"
                @input="changeTitleHandler($event)"
                :value="primaryXaxisFullName?.name"
                :class="darkDark ? 'input-bg' : 'input-bg_light'"
              />
            </div>

            <div class="flex flex-col mt-3">
              <p class="label_heading">Mnemonic</p>

              <select
                :id="widgetId + '2'"
                class="fields focus:ring-indigo-500 border-0 block left-0 font-color leading-none"
                @change="mnemonicPrimaryXaxisChangeHandler($event)"
                :value="primaryXaxisFullName?.name"
                :class="darkDark ? 'input-bg' : 'input-bg_light'"
              >
                <option>{{ title }}</option>
                <option :key="index" v-for="(tag, index) in tags">
                
                  {{ tag }}
                </option>
              </select>
            </div>
            <div class="flex flex-col mt-3">
              <p class="label_heading">Unit</p>
              <input
                :id="widgetId + '3'"
                disabled
                type="text"
                class="fields border-0 block font-color"
                :value="primaryXaxisUnit"
                :class="darkDark ? 'input-bg' : 'input-bg_light'"
              />
            </div>
          </div>
        </div> -->
        <p class="font-thin text-sm">Primary Y-axis</p>
        <div>
          <div class="custom-radio-wrap mb-4">
            <div class="flex flex-col mt-3">
              <p class="label_heading">Display Name</p>
              <input
                :id="widgetId + '1'"
                type="text"
                class="fields border-0 block font-color"
                @input="changeTitleHandler($event)"
                :value="primaryYaxisFullName?.name"
              />
            </div>

            <div class="flex flex-col mt-3">
              <p class="label_heading">Mnemonic</p>

              <select
                :id="widgetId + '2'"
                class="fields focus:ring-indigo-500 border-0 block left-0 font-color leading-none"
                @change="mnemonicPrimaryYaxisChangeHandler($event)"
                :value="primaryYaxisFullName?.name"
              >
                <option>{{ title }}</option>
                <option :key="index" v-for="(tag, index) in tags">
                  <!--  -->
                  {{ tag }}
                </option>
              </select>
            </div>
            <div class="flex flex-col mt-3">
              <p class="label_heading">Unit</p>
              <input
                :id="widgetId + '3'"
                disabled
                type="text"
                class="fields border-0 block font-color"
                :value="primaryYaxisUnit"
              />
            </div>
          </div>
        </div>

        <div v-if="isSecondaryAxis">
          <p class="font-thin text-sm">Secondary Y-axis</p>

          <div class="custom-radio-wrap mb-4">
            <div class="flex flex-col mt-3">
              <p class="label_heading">Display Name</p>
              <input
                :id="widgetId + '1'"
                type="text"
                class="fields border-0 block font-color"
                @input="changeTitleHandler($event)"
                :value="secondaryYaxisFullName?.name"
              />
            </div>

            <div class="flex flex-col mt-3">
              <p class="label_heading">Mnemonic</p>

              <select
                :id="widgetId + '2'"
                class="fields focus:ring-indigo-500 border-0 block left-0 font-color leading-none"
                @change="mnemonicSecondaryYaxisChangeHandler($event)"
                :value="secondaryYaxisFullName?.name"
              >
                <option>{{ title }}</option>
                <option :key="index" v-for="(tag, index) in tags">
                  <!--  -->
                  {{ tag }}
                </option>
              </select>
            </div>
            <div class="flex flex-col mt-3">
              <p class="label_heading">Unit</p>
              <input
                :id="widgetId + '3'"
                disabled
                type="text"
                class="fields border-0 block font-color"
                :value="secondryYaxisUnit"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <UpDownButtons
      :isExpandFeatureSix="isExpandFeatureSix"
      :toggle_height_handler_six="toggle_height_handler_six"
      :id="id"
      :displayId="properties.displayId"
      :widgetId="properties.widgetId"
      :closePropertyFile="closeNavigation"
    />
  </nav>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import UpDownButtons from "../UpDownButtons/UpDownButtons";
import { ChevronDownIcon } from "@heroicons/vue/solid";
import api from "../../api/services";

export default {
  name: "XYAxisProperties",
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    UpDownButtons,
  },
  props: {
    // blinkAlert: Boolean,
    upDownHandler: Function,
    toggle_right_Sidebar: {
      type: String,
      default: "",
    },
    toggle_right_margin: {
      type: String,
      default: "",
    },
    id: {
      type: [Number, String],
    },
    widgetId: String,
    displayId: String,
    properties: {},
    closeNavigation: Function,
  },
  data() {
    return {
      isExpandFeatureOne: true,
      isExpandFeatureTwo: false,
      isExpandFeatureThree: false,
      isExpandFeatureFour: false,
      isExpandFeatureFive: false,
      isExpandFeatureSix: false,
      tag1: "",
      tag2: "",
      // tags: [],
      idx: -1,
      Dtags: [],
    };
  },
  beforeMount() {
    let rects = this.$store.state.rect.rects;
    if (typeof rects != "undefined" && rects.length > 0) {
      for (let i = 0; i < rects.length; i++) {
        if (
          rects[i].widgetId == this.widgetId &&
          rects[i].displayId == this.displayId
        )
          this.idx = i;
      }
    }
  },
  mounted() {
    this.tag1 = "tvd";
    this.tag2 = "dispew";
    let rects = this.$store.state.rect.rects;
    if (typeof rects != "undefined" && rects.length > 0) {
      for (let i = 0; i < rects.length; i++) {
        if (
          rects[i].widgetId == this.widgetId &&
          rects[i].displayId == this.displayId
        )
          this.idx = i;
      }
    }
  },
  computed: {
    isSecondaryAxis() {
      return this.$store.state.rect.rects[this.idx].isSecondaryAxis;
    },
    axisLabelColor() {
      return this.$store.state.rect.rects[this.idx].axisLabelColor;
    },
    graphScaleColor() {
      return this.$store.state.rect.rects[this.idx].graphScaleColor;
    },
    gridColor() {
      return this.$store.state.rect.rects[this.idx].gridColor;
    },
    graphBackgroundColor() {
      return this.$store.state.rect.rects[this.idx].graphBackgroundColor;
    },
    xAxisLabelSize() {
      return this.$store.state.rect.rects[this.idx].xAxisLabelSize;
    },
    yAxisLabelSize() {
      return this.$store.state.rect.rects[this.idx].yAxisLabelSize;
    },
    sevondaryAxisLabelColor() {
      return this.$store.state.rect.rects[this.idx].sevondaryAxisLabelColor;
    },
    graphSecondaryScaleColor() {
      return this.$store.state.rect.rects[this.idx].graphSecondaryScaleColor;
    },
    yAxisSecondaryLabelSize() {
      return this.$store.state.rect.rects[this.idx].yAxisSecondaryLabelSize;
    },
    tags() {
      let display = this.$store.state.disp.selectedDisplay;
      let displayobj = this.$store.state.disp.displays;
      if (displayobj[display]) {
        this.Dtags = displayobj[display].tags;
        return displayobj[display].displayTags;
      } else {
        return [];
      }
    },
    primaryXaxisFullName(){
      return this.$store.state.rect.rects[this.idx].primaryXaxisFullName;
    },
    primaryXaxisUnit(){
      return this.$store.state.rect.rects[this.idx].primaryXaxisUnit;
    },
    primaryYaxisFullName(){
      return this.$store.state.rect.rects[this.idx].primaryYaxisFullName;
    },
    primaryYaxisUnit(){
      return this.$store.state.rect.rects[this.idx].primaryYaxisUnit;
    },
    secondaryYaxisFullName(){
      return this.$store.state.rect.rects[this.idx].secondaryYaxisFullName;
    },
    secondryYaxisUnit(){
      return this.$store.state.rect.rects[this.idx].secondryYaxisUnit;
    },
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
  },
  methods: {
    secondarAxisHandler(e) {
      setTimeout(() => {
        this.$store.dispatch("rect/isEnableSecondayAxis", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.checked,
        });
      }, 300);
    },
    axisLabelColorHandler(e) {
      setTimeout(() => {
        this.$store.dispatch("rect/changeAxisLabelColor", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });
      }, 300);
    },
    secondaryAxisLabelColorHandler(e) {
      setTimeout(() => {
        this.$store.dispatch("rect/changedSecondaryAxisLabelColor", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });
      }, 300);
    },
    graphColorHandler(e) {
      setTimeout(() => {
        this.$store.dispatch("rect/changeGraphColor", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });
      }, 300);
    },
    graphSecondaryColorHandler(e) {
      setTimeout(() => {
        this.$store.dispatch("rect/changedSecondaryAxisScaleColor", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });
      }, 300);
    },
    gridColorHandler(e) {
      setTimeout(() => {
        this.$store.dispatch("rect/changeGridColor", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });
      }, 300);
    },
    graphBackgroundHandler(e) {
      setTimeout(() => {
        this.$store.dispatch("rect/changeBackgroundGridColor", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: e.target.value,
        });
      }, 300);
    },
    xAxisLabelSizeHandler(e) {
      setTimeout(() => {
        this.$store.dispatch("rect/changedGraphXaxisLabel", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: +e.target.value,
        });
      }, 300);
    },
    yAxisLabelSizeHandler(e) {
      setTimeout(() => {
        this.$store.dispatch("rect/changedGraphYaxisLabel", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: +e.target.value,
        });
      }, 300);
    },
    yAxisSecondaryLabelSizeHandler(e) {
      setTimeout(() => {
        this.$store.dispatch("rect/changedSecondaryAxisScaleLabelSize", {
          displayId: this.properties.displayId,
          widgetId: this.properties.widgetId,
          value: +e.target.value,
        });
      }, 300);
    },

    async mnemonicPrimaryXaxisChangeHandler(e) {
      this.$store.dispatch("rect/changePrimaryXsxisNemonice", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: this.Dtags[this.tags.indexOf(e.target.value)],
        fullName: e.target.value,
      });
    },
    async mnemonicPrimaryYaxisChangeHandler(e) {
      this.$store.dispatch("rect/changePrimaryYsxisNemonice", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: this.Dtags[this.tags.indexOf(e.target.value)],
        fullName: e.target.value,
      });
    },
    async mnemonicSecondaryYaxisChangeHandler(e) {
      this.$store.dispatch("rect/changeSecondaryYsxisNemonice", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: this.Dtags[this.tags.indexOf(e.target.value)],
        fullName: e.target.value,
      });
    },
    toggle_height_handler_one() {
      if (this.isExpandFeatureOne == true) {
        this.isExpandFeatureOne = false;
      } else {
        this.isExpandFeatureOne = true;
      }
    },
    toggle_height_handler_two() {
      if (this.isExpandFeatureTwo == true) {
        this.isExpandFeatureTwo = false;
      } else {
        this.isExpandFeatureTwo = true;
      }
    },
    toggle_height_handler_three() {
      if (this.isExpandFeatureThree == true) {
        this.isExpandFeatureThree = false;
      } else {
        this.isExpandFeatureThree = true;
      }
    },
    toggle_height_handler_four() {
      if (this.isExpandFeatureFour == true) {
        this.isExpandFeatureFour = false;
      } else {
        this.isExpandFeatureFour = true;
      }
    },
    toggle_height_handler_five() {
      if (this.isExpandFeatureFive == true) {
        this.isExpandFeatureFive = false;
      } else {
        this.isExpandFeatureFive = true;
      }
    },
    toggle_height_handler_six() {
      if (this.isExpandFeatureSix == true) {
        this.isExpandFeatureSix = false;
      } else {
        this.isExpandFeatureSix = true;
      }
    },
    async getTags() {
      // let tagsResponse = await api.WellServices.getColumnsOfDepthLog();
      // this.tags = tagsResponse.data;
    },
    dark() {
      this.darkMode = true;
      this.$emit("dark");
    },
    light() {
      this.darkMode = false;
      this.$emit("light");
    },
  },
  watch: {
    widgetId(newVal) {
      let rects = this.$store.state.rect.rects;
      if (typeof rects != "undefined" && rects.length > 0) {
        for (let i = 0; i < rects.length; i++) {
          if (
            rects[i].widgetId == this.widgetId &&
            rects[i].displayId == this.displayId
          )
            this.idx = i;
        }
      }
    },
  },
};
</script>

<style scoped src="../../assets/styles/propstyle.css">
/* .right_bg {
  background: #1b1a1f;
}
.bg_gray {
  width: 12%;
}
.bg-right_ {
  background: #28282f;
  margin: 5px 5px;
  padding: 5px;
  border-radius: 5px;
}
.sidebar-heading {
  font-size: 9px;
  padding: 2px;
}
.sidebar-label {
  font-size: 10px;
  font-weight: 400;
  display: flex;
  height: 100%;
  align-items: flex-end;

}
.label_heading {
 
  letter-spacing: 0.5px;
}
.custom-radio-wrap form .form-group-radio .label-text {
  color: white;
} */
</style>
