import Axios from "axios";
import constant from "../constant";
import api from "../dictionary/index";

export default {
    get_mnemonics: async (payload) => {
        try {
            let response = await Axios.get(constant.BASE_URL + 'public/' + api.LWD.GET_MNEMONICS(),{
                                params: { 
                                    well_id: payload.wellId, 
                                    wellbore_name:payload.wellBoreName, 
                                    type: payload.type
                                }
                            })
            return response
        } catch (error) {
            return error
        }
    },
    data_points: async (payload) => {
        try {
            let response = await Axios.post(constant.BASE_URL + 'public/' + api.LWD.DATA_POINTS(), payload)
            return response
        } catch (error) {
            return error
        }
    },
    data_points1: async (payload) => {
        try {
            let response = await Axios.post(constant.BASE_URL + 'public/' + api.LWD.DATA_POINTS1(), payload)
            return response
        } catch (error) {
            return error
        }
    },
    consolidate_lithology_icons_search: async (payload)=>{
        try {
            let response = await Axios.post(constant.BASE_URL + 'public/' + api.LWD.CONSOLIDATE_LITHO_ICONS(), payload) 
            return response
         } catch (error) {
             return error
         }
    },
    save_file_with_selected_data: async (payload)=>{
        try {
            let response = await Axios.post(constant.BASE_URL + 'public/' + api.LWD.SAVE_NEW_RUN(), payload) 
            return response
         } catch (error) {
             return error
         }
    },
    getRunSavedList: async (payload)=>{
        try {
            let response = await Axios.get(constant.BASE_URL + 'public/' + api.LWD.GET_SAVED_RUNS(),{params : payload}) 
            return response
         } catch (error) {
             return error
         }
    },
    deleteSavedRun: async (payload)=>{
        try {
            let response = await Axios.delete(constant.BASE_URL + 'public/' + api.LWD.DELETE_SAVED_RUN(),{data : payload}) 
            return response
         } catch (error) {
             return error
         }
    },

    // JAVA SERVICES
    well_info_search: async (payload) => {
        try {
            let response = await Axios.post(constant.DATASERVICES_URL + api.LWD.WELL_INFO_SEARCH(), payload)
            return response
        } catch (error) {
            return error
        }
    },
    source_log_search: async (payload) => {
        try {
            let response = await Axios.post(constant.DATASERVICES_URL + api.LWD.SOURCE_LOG_SEARCH(), payload)
            return response
        } catch (error) {
            return error
        }
    },
    source_well_data: async (payload) => {
        try {
            let response = await Axios.post(constant.DATASERVICES_URL + api.LWD.SOURCE_WELL_DATA(), payload)
            return response
        } catch (error) {
            return error
        }
    },
    witsml_source_search: async (payload) => {
        try {
            let response = await Axios.post(constant.DATASERVICES_URL + api.LWD.WITSML_INFO_SEARCH(), payload)
            return response
        } catch (error) {
            return error
        }


    },
    lwd_mapping_search: async (payload) => {
        try {
            let response = await Axios.post(constant.DATASERVICES_URL + api.LWD.LWD_MAPPING_SEARCH(), payload)
            return response
        } catch (error) {
            return error
        }
    },
    lwd_mapping_save_update: async (payload) => {
        try {
            return Axios.post(constant.DATASERVICES_URL + api.LWD.LWD_MAPPING_SAVE_UPDATE(), payload)
        } catch (error) {
            return error
        }
    },
    lwd_mapping__update: async (payload) => {
        try {
            return Axios.post(constant.DATASERVICES_URL + api.LWD.LWD_MAPPING__UPDATE(), payload)
        } catch (error) {
            return error
        }
    },
    // LITHOLOGY DATA
    lithology_save_update: async (payload) => {
        try {
            let response = await Axios.post(constant.DATASERVICES_URL + api.LWD.LITHOLOGY_SAVE_UPDATE(), payload, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            })
            return response
        } catch (error) {
            return error
        }
    },
    lithology_lists: async (payload) => {
        try {
            let response = await Axios.get(constant.DATASERVICES_URL + api.LWD.LITHOLOGY_LIST(), payload)
            return response
        } catch (error) {
            return error
        }
    },
    lithology_well_save_update: async (payload) => {
        try {
            let response = await Axios.post(constant.DATASERVICES_URL + api.LWD.LITHOLOGY_WELL_SAVE_UPDATE(), payload)
            return response
        } catch (error) {
            return error
        }
    },
    lithology_well_search: async (payload) => {
        try {
            let response = await Axios.post(constant.DATASERVICES_URL + api.LWD.LITHOLOGY_WELL_SEARCH(), payload)
            return response
        } catch (error) {
            return error
        }
    },
    lwd_icons_list: async () => {
        try {
            let response = await Axios.get(constant.DATASERVICES_URL + api.LWD.ICONS_LIST())
            return response
        } catch (error) {
            return error
        }
    },
    lwd_icons_search: async (payload) => {
        try {
            let response = await Axios.post(constant.DATASERVICES_URL + api.LWD.WELL_BASED_LITHOLOGY_ICONS(), payload)
            return response
        } catch (error) {
            return error
        }
    },
    consolidatedList: async (payload)=>{
        try {
            let response = await Axios.post(constant.DATASERVICES_URL + api.LWD.CONSOLIDATED_LIST(), payload) 
            return response
         } catch (error) {
             return error
         }
    },
    consolidated_data_save: async (payload)=>{
        try {
            let response = await Axios.post(constant.DATASERVICES_URL + api.LWD.CONSOLIDATED_DATA_SAVE(), payload) 
            return response
         } catch (error) {
             return error
         }
    },
    consolidated_data_list_save: async (payload)=>{
        try {
            let response = await Axios.post(constant.DATASERVICES_URL + api.LWD.CONSOLIDATED_DATA_LIST_SAVE(), payload) 
            return response
         } catch (error) {
             return error
         }
    },
    consolidated_data_list_delete: async (payload)=>{
        try {
            let response = await Axios.delete(constant.DATASERVICES_URL + api.LWD.CONSOLIDATED_DATA_LIST_DELETE(), payload) 
            return response
         } catch (error) {
             return error
         }
    },
    add_icon_to_file: async (payload)=>{
        try {
            let response = await Axios.post(constant.DATASERVICES_URL + api.LWD.ADD_ICON_TO_FILE(), payload) 
            return response
         } catch (error) {
             return error
         }
    },
    delete_lithology: async (payload)=>{
        try {
            let response = await Axios.delete(constant.DATASERVICES_URL + api.LWD.DELETE_LITHOLOGY(), {data: payload})  
            return response
         } catch (error) {
             return error
         }
    },
}