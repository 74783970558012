<template>
  <div :id="displayId" :style="{
    height: this.height + 'px',
    width: this.width + 'px',
    overflow: 'hidden',
    background: this.$store.state.rect.darkmode == true ? this.cardBackground : '#FFFFF7',
  }" @contextmenu="this.setContextId" @click="foreground()"
    :class="'bg_purple bg shadow-lg cust_shadow container_'">
    <!-- <CorrelationBar :onMinMaxBck="onMinMax" :displayId="this.displayId" :isMinimize="isMinimize" :width="width"
      :displayIndex="displayIndex" :height="height" :isCorrelation="this.isCorrelation" /> -->
    <AdminSubNavbar :onMinMaxBck="onMinMax" :displayId="this.displayId" :isMinimize="isMinimize" :width="width"
      :height="height" :isTaD="this.isTaD" :isLwd="this.isLwd" :isAlertScreen="this.isAlertScreen" :displayIndex="displayIndex" :isCorrelation="this.isCorrelation" :isreplay="isreplay" :isCorrelationMap="isCorrelationMap"
      @clickedFullScreen="clickedFullScreen"/>

    <Maindash  :width="this.width" :height="this.height" :displayId="this.displayId" :isMinimize="this.isMinimize"
      :isTaD="this.isTaD" :isLwd="this.isLwd" :isCorrelation="this.isCorrelation" :displayIndex="displayIndex"
      v-if="width > width ? width - 10 : width" :isScreenMaximized="isScreenMaximized"></Maindash>

  </div>
</template>

<script>
import Maindash from "../../Maindash.vue";
import AdminSubNavbar from "../../Navbars/AdminSubNavbar.vue";
import fore from "../../../assets/img/foreground.png";
import back from "../../../assets/img/background.png";
import CorrelationBar from "../../Navbars/CorrelationBar.vue";
var data = {
  soundurl: "http://soundbible.com/mp3/analog-watch-alarm_daniel-simion.mp3",
};
export default {
  name: "display-widget",

  components: {
    CorrelationBar,
    Maindash,
    AdminSubNavbar,
  },

  props: {
    displayIndex: Number,
    index: Number,
    displayId: String,

    height: {
      type: Number,
      default: 200,
    },
    width: {
      type: Number,
      default: 350,
    },
    left: {
      type: Number,
      default: 200,
    },
    right: {
      type: String,
      default: "350",
    },
    cardBackground: {
      type: String,
      default: "#3f3f3f",
    },
    onMinMax: Function,
    isMinimize: {
      type: Boolean,
      default: false,
    },
    isTaD: {
      type: Boolean,
      default: false,
    },
    isCorrelation: {
      type: Boolean,
      default: false,
    },
    isCorrelationMap:{
      type:Boolean,
    },
    isLwd: {
      type: Boolean,
      default: false,
    },
    isAlertScreen: {
      type: Boolean,
      default: false,
    },
    isreplay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cardWidgetConfig: this.numericWidgetConfig,
      isPlayed: false,
      fore,
      back,
      selectedWell:'',
      selectedWellBore:'',
      isScreenMaximized: false,
    };
  },
  mounted() {
    try {
      let listEl = document.getElementById(this.dashId);
      this.listWidth = listEl.clientWidth;
      this.listHeight = listEl.clientHeight;
      window.addEventListener("keypress", (e) => {
        //
        // if (e.key == "Delete" && this.activatedIndex > -1) {
        //   this.$store.dispatch("disp/deleteWidget", { id: this.activatedIndex });
        //   this.activatedIndex = -1
        // }
      });
      window.addEventListener("resize", () => {
        this.listWidth = listEl.clientWidth;
        this.listHeight = listEl.clientHeight;

      });
    } catch (error) {
      //
    }

      // setTimeout(() => {
      //   this.rerender("darkDark");

      // }, 0)
  },
  methods: {
    clickedFullScreen(){
      if(!this.$store.state.data.main_nav_show){
        this.isScreenMaximized = true;
      }
      else{
        this.isScreenMaximized = false;
      }
    },
    foreground() {
      this.$store.dispatch("disp/changeZToTop", {
        displayId: this.displayId,
      });
    },
    setContextId() {
      if (!this.$store.state.disp.contextId) {
        this.$store.dispatch("disp/setContextId", {
          contextId: this.displayId,
          contextType: "display",
        });
      }
    },
    resizable() {
      this.toggleResizable();
      // console.log('_______rect_colors___', this.$store.state.rect.darkmode)
      //   return this.$store.state.rect.darkmode;
    },
    change() {
      this.toggleDraggable();

    },

  },
  computed: {
    // width() {
    //
    // },
  },
};
</script>

<style >

 .bg{
  background-color: var(--sidebarbg) !important;
  box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.15);
  transition: var(--transition);
 }
.container_ {
  /* margin: 0 0 20px;
  position: relative;

  background: azure;

  box-shadow: 0px 6px 20px #001;
  display: flex;
  justify-content: center;
  align-items: center; */
}

/* // Context Menu */
.menu {
  height: 90px;
  width: 120px;

  visibility: hidden;

  position: absolute;
  z-index: 9999;

  /* border: 1px solid #04070e; */
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
  /* background: #04070e; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--deleteDragRizeWindowBg);
}

.item {
  flex: 1;
  width: 100%;

  /* background: #04070e; */
  font-size: 10px;
  letter-spacing: 1px;
  /* color: #5aba81; */
font-weight: 400;
  /* border-bottom: 1px solid #0e1016; */
  color: var(--deleteDragRizeWindowBgColor);
  box-sizing: border-box;
  padding: 5px 12px;

  display: flex;
  align-items: center;

  transition: background 0.2s, color 0.1s;
}

.item:hover {
  cursor: pointer;
  background: var(--activeTextColor);
  color: white;
  transition: background 0.2s, color 0.1s;
}

.context_img {
  width: 15px;
  margin-right: 8px;
}

.delete {
  /* color: red !important; */
  --tw-bg-opacity: 1;
  /* background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important; */
  background-color: var(--colorBoxPopUpSaveBg);
  color: white !important;
  font-weight: 600;
  /* background: red !important; */
}

.delete:hover {
  color: white !important;
  background: red !important;
  /* background: red !important; */
}

.checkbox {
  position: relative;
  right: 9px;
}

.label_heading {
  /* color: #5aba81 !important; */
  font-size: calc(0.3vw + 0.3vh + 0.35vmin) !important;
}

.label_heading:hover {
  /* color: white !important; */
}

[type="checkbox"]:checked,
[type="radio"]:checked {
  border-color: transparent;
  content: "";
  /* background-color: #1b1a1f !important; */
}

[type="checkbox"],
[type="radio"] {
  content: "";
  /* background-color: #28282f !important; */
}

[type="checkbox"]:checked {
  background-image: url("../../../assets/img/check_ico.svg") !important;
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
}

.dynamic {
  z-index: 999999;
}
</style>