import { AES, enc } from 'crypto-js';

export default {
    setDetails: (key, data) => {
        let stringToEncrypt = ""
        let enc_data = ""
        try {
            if (typeof data !== 'string')
                stringToEncrypt = JSON.stringify(data)
            enc_data = AES.encrypt(stringToEncrypt, 'encryptionKey').toString();
        } catch (error) {
            console.error('error in ', error)
        }


        localStorage.setItem(key, enc_data)
        return 'OK'
    },
    getDetails: (key) => {
        try {
            let data = localStorage.getItem(key)
            const decryptedBytes = AES.decrypt(data, 'encryptionKey');
            const decryptedString = decryptedBytes.toString(enc.Utf8);
            return JSON.parse(decryptedString);
        } catch (error) {
            return false
        }

        // return data
    },
    uuidv4: () => {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
}