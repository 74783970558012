<template>
  <nav
    class="right_bg close-right-sidebar-16rem z-40 md:right-0 md:block md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl flex flex-wrap items-center justify-between relative z-10"
    v-bind:style="{ right: toggle_right_Sidebar + 'rem' }"
    
  >
    <div class="">
      <div class="flex bg-emerald-500 justify-between px-2 py-0 mneminics_mt">
        <div class="righ_side_head_">
          <svg
            class="twobarsRight"
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="4"
            viewBox="0 0 12 4"
            fill="none"
            data-v-6a52651e=""
          >
            <path
              d="M11.3333 0H0.666626V1.33333H11.3333V0ZM0.666626 4H11.3333V2.66667H0.666626V4Z"
              fill="#777777"
              data-v-6a52651e=""
            ></path>
          </svg>
          <p class="sidebar-heading">Survey Table</p>
        </div>
        <div>
          <svg
            v-on:click="closeNavigation"
            class="w-2 left_close_btn"
            style="text-align: right"
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
          >
            <path
              id="close_2_"
              data-name="close (2)"
              d="M8.874,7.636,14.715,1.8A.972.972,0,1,0,13.341.421L7.5,6.262,1.659.421A.972.972,0,0,0,.285,1.8L6.126,7.636.285,13.477a.972.972,0,1,0,1.374,1.374L7.5,9.01l5.841,5.841a.972.972,0,0,0,1.374-1.374Zm0,0"
              transform="translate(0 -0.136)"
              fill="#fff"
            />
          </svg>
        </div>
      </div>
    </div>

    <div class="pt-0_5 pr-0_5 pl-0_5 pb-0_5 prop_mx_height">
      <div
        v-on:click="toggle_height_handler_two"
        class="flex bg-black justify-between bg-right_ pt-5 pl-5 pb-4"
       
      >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/note.png" alt="" />
            Appearance
          </p>
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureTwo"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>
        </div>
      </div>

      <div
        v-bind:style="{ display: isExpandFeatureTwo ? '' : 'none' }"
        class="expand-right-sidebar-feature-one"
      >
        <div class="pb-5 pt-3">
          <div class="flex items-center justify-between mt-3">
            <p class="label_heading">Text Color</p>
            <input
              type="color"
              min="0"
              max="13"
              id="color1"
              class="h-9 border-0 input-bg block w-5/12 rounded-md font-color"
              @input="changeColor($event)"
              :value="gridColor"
            />
          </div>
          <div class="flex items-center justify-between mt-3">
            <p class="label_heading">Background Color</p>
            <input
              type="color"
              min="0"
              max="13"
              id="color1"
              class="h-9 border-0 input-bg block w-5/12 rounded-md font-color"
              @input="changeBackgroundColor($event)"
              :value="backgroundColor"
            />
          </div>
          <div class="flex items-center justify-between mt-3">
            <p class="label_heading">Header Color</p>
            <input
              type="color"
              min="0"
              max="13"
              id="color1"
              class="h-9 border-0 block w-5/12 rounded-md font-color"
              @input="changeHeadColor($event)"
              :value="headColor"
            />
          </div>

          <div class="flex items-center justify-between mt-3">
            <p class="label_heading">Header Background Color</p>
            <input
              type="color"
              min="0"
              max="13"
              id="color1"
              class="h-9 border-0 input-bg block w-5/12 rounded-md font-color"
              @input="changeHeadBackgroundColor($event)"
              :value="headBackground"
            />
          </div>

          <div class="flex flex-col mt-3">
            <p class="label_heading">Header Font Size</p>
            <input
              type="number"
              min="0"
              max="16"
              class="h-9 border-0 block w-5/12 fields font-color"
              @input="changeHeadFontSize($event)"
              :value="headFontSize"
            />
          </div>
        </div>
      </div>

      <UpDownButtons
        :isExpandFeatureSix="isExpandFeatureSix"
        :toggle_height_handler_six="toggle_height_handler_six"
        :id="id"
        :displayId="properties.displayId"
        :widgetId="properties.widgetId"
        :closePropertyFile="closeNavigation"
      />
    </div>
  </nav>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/solid";
import UpDownButtons from "../UpDownButtons/UpDownButtons";

export default {
  name: "Maindash",
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    UpDownButtons,
  },
  props: {
    widgetId: String,
    displayId: String,
    toggle_right_Sidebar: {
      type: String,
      default: "",
    },
    toggle_right_margin: {
      type: String,
      default: "",
    },
    id: "",
    properties: {},

    closeNavigation: Function,
  },
  data() {
    return {
      columns: [
        {
          label: "Type",
          field: "type",
          // width: `${100 / this.columns.length}`,
          // sortable: true,
          isKey: true,
        },
        {
          label: "RT",
          field: "rt",
          // width: `${100 / this.columns.length}`,
          sortable: true,
          isKey: true,
        },
        {
          label: "Log Name",
          field: "logName",
        },
        {
          label: "Run Number",
          field: "run",
          // width: `${100 / this.columns.length}`,
          sortable: true,
        },
        {
          label: "Service Company",
          field: "serviceCompany",
          // width: `${100 / this.columns.length}`,
          sortable: true,
        },
        {
          label: "Last Updated",
          field: "lastUpdated",
          // width: `${100 / this.columns.length}`,
          sortable: true,
        },
        {
          label: "Start Index",
          field: "startIndex",
          // width: `${100 / this.columns.length}`,
          sortable: true,
        },
        {
          label: " Log UUID",
          field: "logId",
          sortable: true,
        },
      ],
      isExpandFeatureOne: false,
      isExpandFeatureTwo: true,
      isExpandFeatureThree: false,
      isExpandFeatureFour: false,
      isExpandFeatureFive: false,
      isExpandFeatureSix: false,
      tags: ["waaqq", "adpt"],
      idx: -1,
    };
  },
  beforeMount() {
    let rects = this.$store.state.rect.rects;
    if (typeof rects != "undefined" && rects.length > 0) {
      for (let i = 0; i < rects.length; i++) {
        if (
          rects[i].widgetId == this.widgetId &&
          rects[i].displayId == this.displayId
        )
          this.idx = i;
      }
    }
  },
  computed: {
    gridColor() {
      return this.$store.state.rect.rects[this.idx].gridColor;
    },
    backgroundColor() {
      return this.$store.state.rect.rects[this.idx].backgroundColor;
    },
    headColor() {
      return this.$store.state.rect.rects[this.idx].headColor;
    },
    headBackground() {
      return this.$store.state.rect.rects[this.idx].headBackground;
    },
    headFontSize() {
      return this.$store.state.rect.rects[this.idx].headFontSize;
    },
    blinkAlert() {
      return this.$store.state.rect.rects[this.idx].blinkAlert;
    },
    alertColor() {
      return this.$store.state.rect.rects[this.idx].alertColor;
    },
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
  },
  methods: {
    changeAlertColor(e) {
      this.$store.dispatch("rect/changeAlertColor", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: e.target.value,
      });
    },
    changeBlinkAlert(e) {
      this.$store.dispatch("rect/changeBlinkAlert", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: e.target.checked,
      });
    },
    changeStartRange(e, tag) {
      this.$store.dispatch("rect/changeStartRange", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        tag: tag,
        value: e.target.value,
      });
    },
    changeEndRange(e, tag) {
      this.$store.dispatch("rect/changeEndRange", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        tag: tag,
        value: e.target.value,
      });
    },
    changeTag(e) {
      this.$store.dispatch("rect/changeTag", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: e.target.value,
      });
    },
    changeColor(e) {
      this.$store.dispatch("rect/changeColor", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: e.target.value,
      });
    },
    changeBackgroundColor(e) {
      this.$store.dispatch("rect/changeBackgroundColor", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: e.target.value,
      });
    },
    changeHeadColor(e) {
      this.$store.dispatch("rect/changeHeadColor", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: e.target.value,
      });
    },
    changeHeadBackgroundColor(e) {
      this.$store.dispatch("rect/changeHeadBackgroundColor", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: e.target.value,
      });
    },

    changeHeadFontSize(e) {
      if (e.target.value < 17 && e.target.value > -1) {
        this.$store.dispatch("rect/changeHeadFontSize", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          value: e.target.value,
        });
      } else {

      }
    },
    toggleBorderVisibiltyHandler() {
      this.$store.dispatch("rect/toggleBorderVisibility", {
        widgetId: this.widgetId,
        displayId: this.displayId,
      });
    },
    decimalValueHandler(e) {
      if (e.target.value < 14 && e.target.value > -1) {
        this.$store.dispatch("rect/decimalValue", {
          displayId: this.displayId,
          widgetId: this.widgetId,
          value: e.target.value,
        });
      }
    },

    toggle_height_handler_one() {
      if (this.isExpandFeatureOne == true) {
        this.isExpandFeatureOne = false;
      } else {
        this.isExpandFeatureOne = true;
      }
    },
    toggle_height_handler_two() {
      if (this.isExpandFeatureTwo == true) {
        this.isExpandFeatureTwo = false;
      } else {
        this.isExpandFeatureTwo = true;
      }
    },
    toggle_height_handler_three() {
      if (this.isExpandFeatureThree == true) {
        this.isExpandFeatureThree = false;
      } else {
        this.isExpandFeatureThree = true;
      }
    },
    toggle_height_handler_four() {
      if (this.isExpandFeatureFour == true) {
        this.isExpandFeatureFour = false;
      } else {
        this.isExpandFeatureFour = true;
      }
    },
    toggle_height_handler_five() {
      if (this.isExpandFeatureFive == true) {
        this.isExpandFeatureFive = false;
      } else {
        this.isExpandFeatureFive = true;
      }
    },
    toggle_height_handler_six() {
      if (this.isExpandFeatureSix == true) {
        this.isExpandFeatureSix = false;
      } else {
        this.isExpandFeatureSix = true;
      }
    },
    dark() {
      this.darkMode = true;
      this.$emit("dark");
    },
    light() {
      this.darkMode = false;
      this.$emit("light");
    },
  },
};
</script>

<style scoped src="../../assets/styles/propstyle.css"></style>
