<template>
  <div
    class="fixed z-10 overflow-y-auto top-0 w-full left-0 model_height model_bg userProfile"
  >
    <div
      class="flex items-center justify-center min-height-100vh pt-3 px-4 pb-20 text-center sm:block sm:p-0 model_height"
    >
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-900 opacity-75" />
      </div>
      <div class="container mx-auto px-4 h-full tbl_mdl">
        <div class="flex content-center items-center justify-center h-full">
          <div class="w-full px-4 h-full">
            <div
              class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg_model border-0"
            >
              <div class="rounded-t mb-0 px-6 py-6"></div>

              <section>
                <div class="w-full max-w-xs">
                  <div class="flex items-center justify-between">
                    <button
                      class="text-red-500 hover:text-red-700 w-1/3 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline absolute top-0 right-0"
                      type="button"
                      @click="toggleProfile()"
                    >
                      <i class="fas fa-times"></i>
                    </button>
                  </div>

                  <div class="flex flex-wrap">
                    <div class="w-full mx-auto px-4">
                      <div
                        class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg dark_background border-0"
                      >
                        <div class="flex-auto py-10 dark_background">
                          <div class="container mx-auto text-white textColorClass">
                            <div class="flex justify-center">
                              <div
                                class="px-1 w-4/12 mx-auto border-r-4 border-red-500 border-solid"
                                style="border-right: 1px solid lightgray"
                              >
                                <img
                                  src="../../assets/img/profile.png"
                                  class="w-6/12 mx-auto"
                                  alt=""
                                />
                                <h5 class="flex justify-center my-4">
                                  <b class="mx-3 primary_text">{{
                                    data.entityName
                                  }}</b>
                                </h5>
                              </div>
                              <div class="w-8/12 px-4">
                                <ul
                                  class="pb-4"
                                  style="border-bottom: 1px solid lightgray"
                                >
                                  <li>
                                    <b> Email: </b>
                                    <span>
                                      {{ users_list.userName }}
                                    </span>
                                  </li>
                                </ul>

                                <div class="py-4">
                                  <b> Address: </b> <br />
                                  {{ user_address && user_address.address1 }},
                                  {{ user_address && user_address.address2 }},
                                  {{ user_address && user_address.address3 }},
                                  {{ user_address && user_address.city }},
                                  {{ user_address && user_address.state }},
                                  {{ user_address && user_address.pincode }},
                                  {{ user_address && user_address.country }}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="mx-4">
                            <div class="grid-item shadow-md textColorClass">
                              <div class="rounded-t mb-0 px-4 py-1 border-0">
                                <div class="flex flex-wrap items-center">
                                  <div
                                    class="relative flex justify-around px-4 max-w-full flex-grow flex-1 mr-auto"
                                  >
                                    <ul
                                      class="flex mb-0 list-none flex-wrap pt-3 flex-row w-full"
                                    >
                                      <li
                                        class="-mb-px mr-2 last:mr-0 flex-auto text-center"
                                      >
                                        <a
                                          class="text-xs font-bold uppercase px-5 py-3 border-b-2 block leading-normal"
                                          v-on:click="
                                            open_profileToggle(
                                              'user communication'
                                            )
                                          "
                                          v-bind:class="open_profileTab === 'user communication' ? 'activeTextColor ' : 'white'"
                                        >
                                          Communication
                                        </a>
                                      </li>

                                      <li
                                        class="-mb-px mr-2 last:mr-0 flex-auto text-center"
                                      >
                                        <a
                                          class="text-xs font-bold uppercase px-5 py-3 border-b-2 block leading-normal"
                                          v-on:click="
                                            open_profileToggle('user roles')
                                          "
                                          @click="getRolesById()"
                                          v-bind:class="open_profileTab === 'user roles' ? 'activeTextColor' : 'white'"
                                        >
                                          Roles
                                        </a>
                                      </li>
                                      <li
                                        class="-mb-px mr-2 last:mr-0 flex-auto text-center"
                                      >
                                        <a
                                          class="text-xs font-bold uppercase px-5 py-3 border-b-2 block leading-normal"
                                          v-on:click="
                                            open_profileToggle(
                                              'user rolepolicy'
                                            )
                                          "
                                          @click="getRolePoliccyById()"
                                          v-bind:class="open_profileTab === 'user rolepolicy' ? 'activeTextColor' : 'white'"
                                        >
                                          Role Policy
                                        </a>
                                      </li>

                                      <li
                                        class="-mb-px mr-2 last:mr-0 flex-auto text-center"
                                      >
                                        <a
                                          class="text-xs font-bold uppercase px-5 py-3 border-b-2 block leading-normal"
                                          v-on:click="
                                            open_profileToggle('user accesses')
                                          "
                                          @click="getRolePoliccyById()"
                                          v-bind:class="open_profileTab === 'user accesses' ? 'activeTextColor' : 'white'"
                                        >
                                          Access
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="block w-full overflow-x-auto table_font"
                              >
                                <div class="tab-content tab-space">
                                  <div
                                    v-bind:class="{
                                      hidden:
                                        open_profileTab !==
                                        'user communication',
                                      block:
                                        open_profileTab ===
                                        'user communication',
                                    }"
                                  >
                                    <table
                                      class="items-center w-full bg-transparent border-collapse"
                                    >
                                      <thead>
                                        <tr>
                                          <th
                                            class="px-4 align-middle border border-solid py-3 text-xs uppercase font-semibold text-left"
                                          >
                                            Email Id
                                          </th>
                                          <th
                                            class="px-4 align-middle border border-solid py-3 text-xs uppercase font-semibold text-left"
                                          >
                                            Mobile Number
                                          </th>
                                          <th
                                            class="px-4 align-middle border border-solid py-3 text-xs uppercase font-semibold text-left"
                                          >
                                            Landline Number
                                          </th>
                                          <th
                                            class="px-4 align-middle border border-solid py-3 text-xs uppercase font-semibold text-left"
                                          >
                                            socialMedia
                                          </th>
                                          <th
                                            class="px-4 align-middle border border-solid py-3 text-xs uppercase font-semibold text-left"
                                          >
                                            status
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td
                                            class="border-t-0 px-4 align-middle border-l-0 border-r-0 table_font p-4 text-left items-center"
                                          >
                                            {{ communication__.email_id }}
                                          </td>
                                          <td
                                            class="border-t-0 px-4 align-middle border-l-0 border-r-0 table_font p-4 text-left items-center"
                                          >
                                            {{ communication__.mobile_number }}
                                          </td>
                                          <td
                                            class="border-t-0 px-4 align-middle border-l-0 border-r-0 table_font p-4 text-left items-center"
                                          >
                                            {{ communication__.landline_number }}
                                          </td>
                                          <td
                                            class="border-t-0 px-4 align-middle border-l-0 border-r-0 table_font p-4 text-left items-center"
                                          >
                                            {{ communication__.social_media }}
                                          </td>
                                          <td
                                            class="border-t-0 px-4 align-middle border-l-0 border-r-0 table_font p-4 text-left items-center"
                                          >
                                            {{
                                              communication__.status === "A"
                                                ? "Active"
                                                : "In active"
                                            }}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div
                                    v-bind:class="{
                                      hidden: open_profileTab !== 'user roles',
                                      block: open_profileTab === 'user roles',
                                    }"
                                  >
                                    <table
                                      class="items-center w-full bg-transparent border-collapse"
                                    >
                                      <thead>
                                        <tr>
                                          <th
                                            class="px-4 align-middle border border-solid py-3 text-xs uppercase font-semibold text-left"
                                          >
                                            Role Name
                                          </th>
                                          <th
                                            class="px-4 align-middle border border-solid py-3 text-xs uppercase font-semibold text-left"
                                          >
                                            Role Type
                                          </th>
                                          <th
                                            class="px-4 align-middle border border-solid py-3 text-xs uppercase font-semibold text-left"
                                          >
                                            Status
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <th
                                            class="border-t-0 px-4 align-middle border-l-0 border-r-0 table_font p-4 text-left items-center"
                                          >
                                            {{ userRole.roleName }}
                                          </th>
                                          <td
                                            class="border-t-0 px-4 align-middle border-l-0 border-r-0 table_font p-4"
                                          >
                                            {{ userRole.roleType }}
                                          </td>
                                          <td
                                            class="border-t-0 px-4 align-middle border-l-0 border-r-0 table_font p-4"
                                          >
                                            {{
                                              userRole.status === "A"
                                                ? "Active"
                                                : "In active"
                                            }}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div
                                    v-bind:class="{
                                      hidden:
                                        open_profileTab !== 'user rolepolicy',
                                      block:
                                        open_profileTab === 'user rolepolicy',
                                    }"
                                  >
                                    <table
                                      class="items-center w-full bg-transparent border-collapse"
                                    >
                                      <thead>
                                        <tr>
                                          <th
                                            class="px-4 align-middle border border-solid py-3 text-xs uppercase font-semibold text-left"
                                          >
                                            Policy Name
                                          </th>
                                          <th
                                            class="px-4 align-middle border border-solid py-3 text-xs uppercase font-semibold text-left"
                                          >
                                            Policies
                                          </th>
                                          <th
                                            class="px-4 align-middle border border-solid py-3 text-xs uppercase font-semibold text-left"
                                          >
                                            Status
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <th
                                            class="border-t-0 px-4 align-middle border-l-0 border-r-0 table_font p-4 text-left items-center"
                                          >
                                            {{ rolePolicys.policyName }}
                                          </th>
                                          <td
                                            class="border-t-0 px-4 align-middle border-l-0 border-r-0 w-8/12 cursor-pointer table_font p-4"
                                          >
                                            <span class="w-8/12">
                                              {{ rolePolicys.policies }}
                                            </span>
                                          </td>
                                          <td
                                            class="border-t-0 px-4 align-middle border-l-0 border-r-0 table_font p-4"
                                          >
                                            {{
                                              rolePolicys.status === "A"
                                                ? "Active"
                                                : "In active"
                                            }}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div
                                    v-bind:class="{
                                      hidden:
                                        open_profileTab !== 'user accesses',
                                      block:
                                        open_profileTab === 'user accesses',
                                    }"
                                  >
                                    <table
                                      class="items-center w-full bg-transparent border-collapse"
                                    >
                                      <thead>
                                        <tr>
                                          <th
                                            class="px-4 align-middle border border-solid py-3 text-xs uppercase font-semibold text-left"
                                          >
                                            Access
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <th
                                            class="border-t-0 px-4 align-middle border-l-0 border-r-0 table_font p-4 text-left items-center"
                                          >
                                            {{ rolePolicys.access }}
                                          </th>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import URLS from "../../config";
import axios from "axios";
import api from "../../api/services";
export default {
  props: {
    toggleProfile: { type: Function },
  },
  data() {
    return {
      userRole: {},
      users_list: {
        userTypeId: this.userTypeId || null,
        userName: this.userName || "",
        userId: this.userId || null,
        userBranch: this.userBranch || "",
        subEntityId: this.subEntityId || null,
        status: this.status || "",
        roleId: this.roleId || null,
        previousLogin: this.previousLogin || "",
        password: this.password || "",
        lastLogin: this.lastLogin || "",
        createdBy: this.createdBy || "",
        createdAt: this.createdAt || "",
        groupId: this.groupId || null,
        entityId: this.entityId || null,
        communicationId: this.communicationId || null,
        addressId: this.addressId || null,
      },
      showPassword: false,
      updatePage: this.userId,
      data: [],
      open_profileTab: "user communication",
      userLogged: [],
      rolePolicys: [],
      rolePloicyId: null,
      communication__: {},
      user_address: [],
      newpassword: "",
      oldpassword: "",
      isLoading: false,
      pass_showHide: false,
      oldpass_showHide: false,
    };
  },
  methods: {
    updatepassword() {
      try {
        console.log("this.resetemail usermail", this.users_list.password);
        if (
          (this.newpassword == "" && this.oldpassword == "") ||
          (this.newpassword == null) & (this.oldpassword == null)
        ) {
          this.$toast.error("Password should not be blank");
        } else if (this.newpassword == this.oldpassword) {
          this.$toast.error("Password should not be same");
        } else if (this.users_list.password != this.oldpassword) {
          this.$toast.error("Old Password is wrong");
        } else if (
          this.newpassword != this.oldpassword &&
          this.users_list.password == this.oldpassword
        ) {
          axios
            .post(t_d_main_Apu.T_D_main_Apu + "password/change", {
              password: this.users_list.password,
              username: this.users_list.userName,
              changedPassword: this.newpassword,
            })
            .then(
              (result) => {
                this.response = result.data;
                console.log(result.data);
                this.$toast.success("Password Update Successfully");
              },
              (error) => {
                console.error(error);
              }
            );
        }
      } catch (error) {
        error;
      }
    },
    toggleEye() {
      this.pass_showHide = !this.pass_showHide;
    },
    toggleOldEye() {
      this.oldpass_showHide = !this.oldpass_showHide;
    },
    async getSubEntity() {
      api.SupportServices.getSubEntity()
        .then((response) => {
          this.data = response.data;
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },
    async getSingleEntity_(id) {
      DataService.getSingleEntity(id).then(() => {});
    },

    async getSingle(id) {
      try {
        let user_single = await api.SupportServices.getSingleUser(id);
        console.log("single user : ", user_single);
        if (user_single && user_single.data) {
          this.users_list = { ...user_single.data[0] };
          console.log("single user 1: ", user_single.data[0]);
          this.communication__ = this.users_list.communication;
        }
      } catch (error) {
        console.error("error in getting single user : ", error);
      }
    },
    async getTutorial(id) {
      try {
        let response = await api.SupportServices.getSingleSubEntity(id);
        this.data = response;
        console.log("get tutorial", response);
        this.user_address = this.data.address;

        console.log("user_address useradd 1", this.user_address);
        // if (response && response.data) {
        //   this.communication__ = this.data.communication

        // }
      } catch (error) {
        console.error(error);
      }
    },

    async getRolesById() {
      try {
        let response = await axios.post(
          URLS.HOST_URL.DATASERVICES_URL + "roles/search",
          {
            roleId: this.userLogged.role_id,
          }
        );
        // this.users_list = response.data;
        this.userRole = response.data;
        this.rolePloicyId = response.data.rolePolicyIds;
      } catch (error) {
        console.error("error in getting roles by id", error);
      }
    },
    async getRolePoliccyById() {
      try {
        console.log(this.userLogged.user_roles);
        let rolePolicyId = JSON.parse(
          this.userLogged.user_roles.role_policy_ids
        )[0];

        let response = await axios.post(
          URLS.HOST_URL.DATASERVICES_URL + "rolepolicy/search",
          {
            rolePolicyId: rolePolicyId,
          }
        );
        this.rolePolicys = response.data;
      } catch (error) {
        console.error("error in get role policy by Id : ", error);
      }
    },
    open_profileToggle(data) {
      this.open_profileTab = data;
      this.$router.replace({ query: { ...this.$route.query, profile: data } });
    },
    updateCommn(data) {
      this.$router.push(`/admin/update/user/${data}`);
      // this.$router.push(`/admin/entity/update/${data}`);
    },
    updateRoleAdmin(id) {
      this.$router.push(`/admin/update/role/${id}`);
    },
  },
  async mounted() {
    try {
      this.userLogged = this.$serviceHelpers.getDetails("apollodart").user;

      await this.getRolesById();
      await this.getSubEntity();

      // await this.getTutorial(this.users_list.entityId)
      console.log("user_address 1", this.userLogged);

      if (this.userLogged.user_id) {
        await this.getSingle(this.userLogged.user_id);
        await this.getTutorial(this.userLogged.entity_id);
      }
      this.communication__ = this.userLogged.communication;
    } catch (error) {}
  },
};
</script>
<style scoped>
.grid-container {
  display: grid;
  /* grid-template-columns: auto auto auto; */
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(2, auto);
  padding: 10px;
  grid-gap: 10px;
}

.grid-item {
  border: 1px solid rgba(230, 230, 230, 0.8);
  padding: 0;
  font-size: 10px;
  text-align: center;
  overflow: auto;
  color: var(--textColor);
}

@media only screen and (max-width: 600px) {
  .grid-container {
    grid-template-columns: repeat(2, auto);
  }
}

.updateUser_btn {
  position: absolute;
  right: 28px;
  top: 13px;
}

.icon_profile {
  right: -91px;
  position: relative;
  z-index: 1;
  top: -30px;
  color: black;
}
.userProfile .bg_model{
  background-color: var(--wdigetBg);
}
/* ul li:not(:first-child) a{
  color: var(--activeTextColor) !important;
} */
</style>
