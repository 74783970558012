<template>
  <div>
    <ArrowNarrowDownIcon />
  </div>
</template>

<script>
import { ArrowNarrowDownIcon } from "@heroicons/vue/solid";

export default {
  components: { ArrowNarrowDownIcon },
  computed: {
    activeRect() {
      return this.$store.getters["rect/getActive"];
    },
    zIndex() {
      if (this.activeRect === null) {
        return null;
      }

      return this.$store.state.rect.rects[this.activeRect].zIndex === 1
        ? "isFirst"
        : this.$store.state.rect.rects[this.activeRect].zIndex ===
          this.$store.state.rect.rects.length
        ? "isLast"
        : "normal";
    }
  },
  methods: {
    toTop() {
      this.$store.dispatch("rect/changeZToTop", { id: this.activeRect });
    },

    toBottom() {
      this.$store.dispatch("rect/changeZToBottom", { id: this.activeRect });
    }
  }
};
</script>

<style >
.icon {
  width: 10px;
  height: 20px;
  right: 2px;
  position: relative;
  z-index: 1;
  top: 2px;
}
</style>